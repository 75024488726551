import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import useModel from "../../hooks/use-model.hook";
import { useToast } from "../../hooks/use-toast.hook";
import { Group } from "../../types/group/group.type";
import BronzeStar from "../../_assets/vectors/bronze-star.svg";
import GoldStar from "../../_assets/vectors/gold-star.svg";
import SilverStar from "../../_assets/vectors/silver-star.svg";
import { CommunityService, GroupService } from "../../_service";
import { formatNumber } from "../../_utils/extensions/typescript-utils";
import I18 from "../atoms/i18";
import TextButton from "../atoms/text-button";
import { Avatar } from "../avatar.component";
import LeaderBoardComponent from "../leaderboard-component";
import RightSideModal from "../right-side-modal.component";
import MainUtil from "../../_utils/main.util";

interface LeaderBoardWidgetProps {
  group?: Group;
  memberPreviewLimit: number;
  isCommunityLeaderBoard: Boolean;
}

/**
 * Widget to display  top contributors of  community | group.
 * @param {int} memberPreviewLimit - No. of members to be displayed.
 * @param {Boolean} isCommunityLeaderBoard - Whether to display leader board for community or group. Default is community.
 */
export default function LeaderBoardWidget({
  group,
  memberPreviewLimit = 5,
  isCommunityLeaderBoard = true,
}: LeaderBoardWidgetProps) {
  const { analyticsService } = useAppService();

  // load the subscriber list once, or per community or group change

  const [isLoading, setIsLoading] = useState(false);
  const [contributors, setContributors] = useState<any>();
  const [groupContributors, setGroupContributors] = useState<any>();
  const [isContributorsListVisible, setIsContributorsListVisible] =
    useState(false);

  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();

  const { addToast } = useToast();

  const { community, user } = useAppSelector((state) => {
    return {
      community: state.community,
      user: state.auth,
    };
  });

  const communityId = community?.id;
  const groupId = group?.id;

  const getCommunityLeaderboardData = async (communityId: string) => {
    const leaderboardData = await CommunityService.getLeaderboardV2Paginated(
      communityId,
      1,
      memberPreviewLimit
    );
    setContributors(leaderboardData.leaderboard);
  };

  const getGroupLeaderboardData = async (groupId: string) => {
    const leaderboardData = await GroupService.getLeaderboardV2Paginated(
      groupId,
      1,
      memberPreviewLimit
    );
    setGroupContributors(leaderboardData.leaderboard);
  };
  // Get leaderboard data for community
  useEffect(() => {
    if (communityId) {
      try {
        setIsLoading(true);
        getCommunityLeaderboardData(communityId);
        setIsLoading(false);
      } catch (error) {
        console.log({ error });
        setIsLoading(false);
      }
    }
    if (groupId) {
      try {
        setIsLoading(true);
        getGroupLeaderboardData(groupId);
        setIsLoading(false);
      } catch (error) {
        console.log({ error });
        setIsLoading(false);
        if (MainUtil.checkIfBlocked(error)) {
          console.log("[Block] Unauthorize error ");
        }
      }
    }
  }, [communityId, groupId, isCommunityLeaderBoard]);

  // Hide widget if there is no contributors available
  if (!isLoading && (contributors === undefined || contributors.length === 0)) {
    return <></>;
  }

  return (
    <>
      <div className="rounded-xl bg-card p-5">
        <button
          className="flex w-full cursor-pointer select-none border-b border-border pb-5 text-secondary-foreground"
          onClick={() => {
            setIsContributorsListVisible(true);
            analyticsService.track("widget-leaderboard", {
              clickOn: "header",
              widgetType: "leaderboard",
            });
          }}>
          <h4 className="text-sm font-bold uppercase">
            {isCommunityLeaderBoard ? (
              <I18>Top Members</I18>
            ) : (
              <I18>Group Top Contributors</I18>
            )}
          </h4>
        </button>
        {/* members section */}
        <div className="">
          {/* group leaderboard */}
          {!isCommunityLeaderBoard && groupContributors && (
            <div className="">
              {groupContributors
                .slice(0, memberPreviewLimit)
                .map((contributor: any, index: number) => (
                  <div
                    onClick={() => {
                      const eventData = {
                        clickOn: "user",
                        widgetType: "leaderboard",
                      };

                      analyticsService.track("widget-leaderboard", eventData);
                      // open the user page
                      // window.open(
                      //   createUserWallPageRoute(contributor.user.id, "activity")
                      // );
                      setShowProfileModel(true);
                      setIdToViewProfile(contributor.user.id);
                    }}
                    className="flex cursor-pointer items-center justify-between pt-4 text-secondary-foreground"
                    key={index}>
                    <div
                      className="flex items-center space-x-1"
                      style={{ maxWidth: "calc(100% - 75px)" }}>
                      <div className="flex items-center gap-2.5">
                        {index === 0 && <img src={GoldStar} />}
                        {index === 1 && <img src={SilverStar} />}
                        {index === 2 && <img src={BronzeStar} />}
                        {index >= 3 && (
                          <span className="w-6 text-xs font-semibold">
                            #{index < 10 ? 0 : ""}
                            {index + 1}
                          </span>
                        )}
                        <Avatar
                          user={contributor.user}
                          noName
                          size={30}
                          className="w-8"
                          extraInfo={undefined}
                        />
                      </div>
                      <span className="word-breaker text-xs font-semibold">
                        {contributor.user.name}
                      </span>
                    </div>
                    <span className="text-xxs rounded bg-accent px-1.5 py-1 text-secondary-foreground">
                      {formatNumber(contributor.karmaPoints?.grandTotal || 0) +
                        (community?.configurables?.COMMUNITY_BASE_POINT_KEY ||
                          0)}{" "}
                      <I18>points</I18>
                    </span>
                  </div>
                ))}
            </div>
          )}

          {/* community leaderboard */}
          {isCommunityLeaderBoard && contributors && (
            <div className="">
              {contributors
                .slice(0, memberPreviewLimit)
                .map((contributor: any, index: number) => (
                  <div
                    onClick={() => {
                      const eventData = {
                        clickOn: "user",
                        widgetType: "leaderboard",
                      };

                      analyticsService.track("widget-leaderboard", eventData);
                      // open the user page
                      // window.open(
                      //   createUserWallPageRoute(contributor.user.id, "activity")
                      // );
                      setShowProfileModel(true);
                      setIdToViewProfile(contributor.user.id);
                    }}
                    className="flex cursor-pointer items-center justify-between pt-4 text-secondary-foreground"
                    key={index}>
                    <div
                      className="flex items-center space-x-1"
                      style={{ maxWidth: "calc(100% - 75px)" }}>
                      <div className="flex items-center gap-2.5">
                        {index === 0 && <img src={GoldStar} />}
                        {index === 1 && <img src={SilverStar} />}
                        {index === 2 && <img src={BronzeStar} />}
                        {index >= 3 && (
                          <span className="w-6 text-xs font-semibold">
                            #{index < 10 ? 0 : ""}
                            {index + 1}
                          </span>
                        )}
                        <Avatar
                          user={contributor.user}
                          noName
                          size={30}
                          className="w-8"
                          extraInfo={undefined}
                        />
                      </div>
                      <span className="word-breaker text-xs font-semibold">
                        {contributor.user.name}
                      </span>
                    </div>
                    <span className="text-xxs rounded bg-accent px-1.5 py-1">
                      {formatNumber(
                        (contributor.karmaPoints?.grandTotal || 0) +
                          (community?.configurables?.COMMUNITY_BASE_POINT_KEY ||
                            0)
                      )}{" "}
                      <I18>points</I18>
                    </span>
                  </div>
                ))}
            </div>
          )}

          {/* show all link */}

          <div className="text-xxs pt-4">
            <TextButton
              label="View All"
              onClick={(e: any) => {
                setIsContributorsListVisible(true);
                const eventData = {
                  clickOn: "show-all",
                  widgetType: "leaderboard",
                };

                analyticsService.track("widget-leaderboard", eventData);
              }}
            />
          </div>
          <RightSideModal
            active={isContributorsListVisible}
            setActive={setIsContributorsListVisible}
            width={500}>
            <LeaderBoardComponent
              addToast={addToast}
              user={user}
              community={community}
              contributors={
                isCommunityLeaderBoard ? contributors : groupContributors
              }
              group={group}
              isCommunityLeaderBoard={isCommunityLeaderBoard}
              onUpdateContributors={function (e: any): void {
                setContributors(e);
              }}
            />
          </RightSideModal>
        </div>
      </div>
    </>
  );
}
