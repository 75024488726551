import { InvoicePreference } from "../../types/invoice-preference/invoice-preference.type";
import { PaymentPreference } from "../../types/payment-preference/payment-preference.type";
import { PaymentPreferenceService } from "../../_service";
import { Status } from "./preference.reducer";

export const SET_PAYMENT_PREFERENCE = "SET_PAYMENT_PREFERENCE";
export const SET_PAYMENT_PREFERENCE_LOADING_STATUS =
  "SET_PAYMENT_PREFERENCE_LOADING_STATUS";

export const SET_INVOICE_PREFERENCE = "SET_INVOICE_PREFERENCE";
export const SET_INVOICE_PREFERENCE_LOADING_STATUS =
  "SET_INVOICE_PREFERENCE_LOADING_STATUS";

/**
 * Set Course plans for users
 * @param { PaymentPreference} paymentPreference
 * @returns
 */
function setPaymentPreference(
  paymentPreference: PaymentPreference,
  status?: Status
) {
  return {
    type: SET_PAYMENT_PREFERENCE,
    paymentPreference: paymentPreference,
    status: status,
  };
}

/**
 * Set Invoice preference for community
 * @param { InvoicePreference} invoicePreference
 * @returns
 */
export function setInvoicePreference(
  invoicePreference: InvoicePreference,
  status?: Status
) {
  return {
    type: SET_INVOICE_PREFERENCE,
    invoicePreference: invoicePreference,
    status: status,
  };
}

/**
 * Set payment preference loading status
 * @param { 'idle' | 'loading' | 'succeeded' | 'failed' } status
 */
export function setPaymentPreferenceLoadingStatus(status: Status) {
  return {
    type: SET_PAYMENT_PREFERENCE_LOADING_STATUS,
    status: status,
  };
}

/**
 * Set invoice preference loading status
 * @param { 'idle' | 'loading' | 'succeeded' | 'failed' } status
 * @returns
 */
export function setInvoicePreferenceLoadingStatus(status: Status) {
  return {
    type: SET_INVOICE_PREFERENCE_LOADING_STATUS,
    status: status,
  };
}

/**
 * Get payment preference for community
 */
export function getPaymentPreference() {
  return async (dispatch: any, getState: any) => {
    try {
      const { community } = getState();
      if (!community) {
        return;
      }
      dispatch(setPaymentPreferenceLoadingStatus("loading"));
      PaymentPreferenceService.getPreference(community.id)
        .then(({ paymentPreference }) => {
          if (!paymentPreference) {
            console.log("No payment preference found");
            dispatch(setPaymentPreferenceLoadingStatus("success"));
            return;
          }
          dispatch(setPaymentPreference(paymentPreference, "success"));
        })
        .catch((err) => {
          console.error(err);
          dispatch(setPaymentPreferenceLoadingStatus("error"));
        });
    } catch (error) {
      console.log(error);
      dispatch(setPaymentPreferenceLoadingStatus("error"));
    }
  };
}

/**
 * Get invoice preference for community
 */
export function getInvoicePreference() {
  return async (dispatch: any, getState: any) => {
    try {
      const { community } = getState();
      if (!community) {
        return;
      }
      dispatch(setInvoicePreferenceLoadingStatus("loading"));
      PaymentPreferenceService.getInvoicePreference(community.id)
        .then(({ invoicePreference }) => {
          if (!invoicePreference) {
            console.log("No invoice preference found");
            dispatch(setInvoicePreferenceLoadingStatus("success"));
            return;
          }
          dispatch(setInvoicePreference(invoicePreference, "success"));
        })
        .catch((err) => {
          console.error(err);
          dispatch(setInvoicePreferenceLoadingStatus("error"));
        });
    } catch (error) {
      console.log(error);
      dispatch(setInvoicePreferenceLoadingStatus("error"));
    }
  };
}

/**
 * Update payment preference for community
 * @param { any} payload
 * @param {Function} onSuccess
 * @param {Function} onError
 * @returns
 */
export function updatePaymentPreference(
  payload: any,
  onSuccess = (_: PaymentPreference) => { },
  onFailed = (_: any) => { }
) {
  return async (dispatch: any, getState: any) => {
    const { community } = getState();
    if (!community) {
      return;
    }
    dispatch(setPaymentPreferenceLoadingStatus("loading"));
    PaymentPreferenceService.updatePreference(community.id, payload)
      .then(({ paymentPreference }) => {
        onSuccess(paymentPreference);
        dispatch(setPaymentPreference(paymentPreference, "success"));
      })
      .catch((err) => {
        console.error(err);
        onFailed(err);
        dispatch(setPaymentPreferenceLoadingStatus("error"));
        console.log("Error in update payment preference", err);
      });
  };
}

export interface UpdatePreferenceProps<T> {
  payload: any;
  onSuccess: (invoicePreference: T) => void;
  onFailed: (error: any) => void;
}
/**
 * Update invoice preference for community
 * @param { any} payload
 * @param {Function} onSuccess
 * @param {Function} onError
 * @returns
 */
export function updateInvoicePreference(
  payload: any,
  onSuccess = (e: InvoicePreference) => { },
  onFailed = (e: any) => { }
) {
  return async (dispatch: any, getState: any) => {
    try {
      const { community } = getState();
      if (!community) {
        return;
      }
      dispatch(setInvoicePreferenceLoadingStatus("loading"));
      PaymentPreferenceService.updateInvoicePreference(community.id, payload)
        .then(({ invoicePreference }: any) => {
          onSuccess(invoicePreference);
          dispatch(setInvoicePreference(invoicePreference, "success"));
        })
        .catch((err) => {
          console.error(err);
          dispatch(setInvoicePreferenceLoadingStatus("error"));
          onFailed(err);
        });
    } catch (error) {
      console.log(error);
      dispatch(setInvoicePreferenceLoadingStatus("error"));
    }
  };
}
