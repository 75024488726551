import cx from "classnames";
import { cn } from "../button.component";
export function ProgressBar({
  value = 0,
  max = 100,
  className,
}: {
  value?: number;
  max?: number;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "h-1 w-full overflow-hidden rounded-full bg-secondary-foreground/20",
        className
      )}>
      <div
        style={{
          width: value && max ? (value / max) * 100 + "%" : "0%",
          animation: "progress 1s ease-in-out",
        }}
        className={cx(
          "h-full rounded-full bg-info transition-all duration-150 ease-in-expo"
        )}
      />
    </div>
  );
}
