import cx from "classnames";
import React from "react";

export function CourseViewSidebar({
  children,
  rightSidebar,
  leftSidebar,
  visible = true,
}: any) {
  return (
    <div
      style={{ zIndex: 1 }}
      className={cx("relative hidden w-72", {
        "lg:block": leftSidebar && visible,
        "xl:block": rightSidebar && visible,
      })}>
      <div className="fixed bottom-[10px] top-[75px] w-72 overflow-y-auto rounded bg-card">
        {children}
      </div>
    </div>
  );
}

export function CourseViewContent({ children }: { children: React.ReactNode }) {
  return (
    <div className="mt-5 flex max-w-[740px] flex-1">
      <div className="w-full lg:px-2">{children}</div>
    </div>
  );
}

export const CourseViewNavbar = ({ children }: any) => {
  return (
    <div className="fixed left-0 right-0 top-0 z-0 h-[54px] bg-card">
      <div className="flex h-full items-center">
        <div className="mx-auto w-full max-w-[1340px] px-2">{children}</div>
      </div>
    </div>
  );
};

export function CourseViewFooter({ children, className }: any) {
  return (
    <footer
      className={cx(
        "fixed bottom-0 left-0 right-0 z-0 h-[54px] shadow-2xl",
        className
      )}>
      <div className="mx-auto flex h-full w-full max-w-[1040px] items-center px-2 lg:px-2">
        <div className="flex h-full w-full place-content-between items-center border-t border-border bg-card px-4 shadow-2xl">
          {children}
        </div>
      </div>
    </footer>
  );
}
