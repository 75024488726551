import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux.hook";
import mainLogo from "../../_assets/vectors/main-logo.svg";

export default function CommunityLogo({
  community = null,
  height = 54,
  width = null,
  maxSize = false,
  className = "",
}) {
  // check if the image has error
  const [imageHasError, setImageHasError] = useState(false);
  const [logoSrc, setLogoSrc] = useState();
  const user = useAppSelector((state) => state.auth);
  const preference = user?.preferences?.themeChoice;

  useEffect(() => {
    if (community) {
      const isDarkModeEnabled =
        document.documentElement.classList.contains("dark") ??
        preference === "dark";
      if (isDarkModeEnabled && typeof community.darkModeLogo === "string") {
        setLogoSrc(community.darkModeLogo);
      } else if (isDarkModeEnabled && !community.darkModeLogo) {
        setLogoSrc(community.logo);
      } else if (community.logo) {
        setLogoSrc(community.logo);
      } else {
        setLogoSrc(mainLogo);
      }
    } else {
      setLogoSrc(mainLogo);
    }
  }, [community, preference]);

  return logoSrc && !imageHasError ? (
    <img
      src={logoSrc}
      className={"flex-shrink-0 rounded " + className}
      alt={community ? community?.name + " Logo" : "Pensil Logo"}
      style={{
        width: "auto", //maxSize ? "auto" : width ? width + "px" : height + "px",
        height: maxSize ? "auto" : height + "px",
        maxWidth: "260px", //!maxSize ? "auto" : width ? width + "px" : height + "px",
        maxHeight: !maxSize ? "auto" : height + "px",
      }}
      onError={(e) => {
        setImageHasError(true);
      }}
    />
  ) : (
    <svg
      className={"flex-shrink-0 rounded " + className}
      style={{
        backgroundColor: "hsl(var(--primary))",
        width: "36px", //maxSize ? "auto" : width ? width + "px" : height + "px",
        height: "36px",
      }}>
      <text
        x="50%"
        y="55%"
        fontSize="21"
        fontWeight="bold"
        textAnchor="middle"
        fill="hsl(var(--primary-foreground))"
        dominantBaseline="middle">
        {getCommunityInitials(community?.name ?? "Pensil")}
      </text>
    </svg>
  );
}

/**
 * get community initials from community name
 */
function getCommunityInitials(communityName) {
  return communityName
    .split(" ")
    .map((word) => word[0])
    .slice(0, 2)
    .join("")
    .toUpperCase();
}
