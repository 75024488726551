
/**
 * Clip a long link by keeping the protocol and domain, clipping the middle part, and keeping the last segment.
 * @param {string} link The link to clip
 * @param {number} maxLength The maximum length before and after the clipped portion
 */
export function shortenLink(link?: string, maxLength = 60): string {
  if (!link) {
    return '';
  }


  if (link.length <= maxLength) {
    return link; // Return the original link if it's shorter than or equal to maxLength
  }

  // Find the protocol (http:// or https://)
  const protocolEndIndex = link.indexOf('://');
  const protocol = link.substring(0, protocolEndIndex);

  // Remove the protocol part from the link for easier manipulation
  const linkWithoutProtocol = link.substring(protocolEndIndex);

  // Find the last segment of the path
  const lastSlashIndex = linkWithoutProtocol.lastIndexOf('/');
  let lastSegment = linkWithoutProtocol.substring(lastSlashIndex);

  if (maxLength <= protocol.length + lastSegment.length) {
    lastSegment = lastSegment.substring(lastSegment.length - maxLength / 2);
  }

  // Calculate the length of the middle part
  const middlePartLength = maxLength - protocol.length - lastSegment.length;

  if (middlePartLength <= 0) {
    // throw new Error("Max length too short to accommodate the shortened link.");
    return `${protocol}/.../${lastSegment}`;
  }

  // Get the middle part of the link
  const middlePart = linkWithoutProtocol.substring(0, middlePartLength);

  // Construct the shortened link
  const shortenedLink = `${protocol}${middlePart}/.../${lastSegment}`;
  return shortenedLink;
}
