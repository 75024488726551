import { useState } from "react";
import I18 from "../atoms/i18";
import TextButton from "../atoms/text-button";
import { Button } from "../button.component";
import { FileInput } from "../form-controls/file-input";
import UIcon from "../uicon-component";
import UnsplashImagePicker from "../unsplash-image-picker";
import ImageCropModal from "./image-crop-modal";

/**
 * @param {function} setPictureSelected function to set the picture selected. Returns a boolean value.
 * @param {function} setPicture function to set the picture. Returns a blob.
 * @param {string} picture the default picture to be display.
 * @param {function} setIsPictureRemoved function to set the picture removed. Returns a boolean value.
 * @param {double} aspectRatio the aspect ratio of the image. Default is 16:9.
 * @param {string} recommendedRatio the recommended ratio to be displayed on empty screen. Default is "16:9"
 * @param {string} cropRatioMessage the recommended ratio to be displayed on modal footer.
 * @param {Element} selectPictureComponent the component to select the picture.
 * @param {boolean} displayUnsplashSearch if true, the unsplash search will be hidden.
 * @param {String} initialPhotoSearchQuery the initial query to be searched on unsplash.
 */

interface CropImageComponentProps {
  picture: any;
  setPicture?: (picture: Blob | null) => void;
  setPictureSelected?: (selected: boolean) => void;
  setIsPictureRemoved?: (removed: boolean) => void;
  setIsPictureChanged?: (removed: boolean) => void;
  aspectRatio?: number;
  recommendedRatio?: string;
  cropRatioMessage?: string;
  selectPictureComponent?: JSX.Element;
  displayUnsplashSearch?: boolean;
  initialPhotoSearchQuery?: string;
}
function CropImageComponent({
  picture,
  setPicture = () => {},
  setPictureSelected = () => {},
  setIsPictureRemoved = (e) => {},
  setIsPictureChanged = (e) => {},
  aspectRatio = 16 / 9,
  recommendedRatio = "16:9",
  cropRatioMessage,
  selectPictureComponent,
  displayUnsplashSearch = false,
  initialPhotoSearchQuery = "Community",
}: CropImageComponentProps) {
  const [activeCrop, setActiveCrop] = useState(false);
  const [activeUnSplash, setActiveUnSplash] = useState(false);
  const [src, selectFile] = useState<string | null>(null);

  let imageUrl = "";
  if (!activeCrop) {
    try {
      if (
        picture &&
        typeof picture === "string" &&
        picture.startsWith("http")
      ) {
        imageUrl = picture;
      } else {
        imageUrl = URL.createObjectURL(picture);
      }
    } catch (error) {
      imageUrl = picture;
    }
  }
  const onFileSelect = async (e: any) => {
    if (e) {
      let image = URL.createObjectURL(e);
      selectFile(image);
    } else {
      selectFile(null);
    }
    setActiveCrop(true);
  };

  return (
    <>
      {src && activeCrop ? (
        <ImageCropModal
          src={src}
          setPicture={(picture: Blob | null) => {
            setPicture(picture);
            setIsPictureRemoved(false);
          }}
          cropRatioMessage={cropRatioMessage}
          setPictureSelected={setPictureSelected}
          activeCrop={activeCrop}
          setActiveCrop={setActiveCrop}
          aspectRatio={aspectRatio}
        />
      ) : null}
      <div
        className="relative cursor-pointer overflow-hidden rounded bg-card"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {picture && !activeCrop ? (
          <div className="theme-bg-disable relative m-auto flex flex-col place-content-center">
            <div className="" style={{ aspectRatio: `${aspectRatio}` }}>
              <img src={imageUrl} alt="" />
            </div>
            <div className="absolute bottom-0 right-0 mx-2 my-2">
              <div className="mx-auto flex items-center justify-between">
                {displayUnsplashSearch && (
                  <Button
                    onClick={(e) => {
                      setActiveUnSplash(true);
                      e.stopPropagation();
                    }}
                    className="mr-2"
                    label="Search"
                  />
                )}

                <FileInput
                  accept="image/*"
                  maxFileSize={10}
                  onChange={(e) => {
                    onFileSelect(e);
                  }}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPictureChanged(true);
                    }}
                    className="mr-2"
                    label="Change Cover"
                  />
                </FileInput>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    imageUrl = "";
                    setPicture(null);
                    // setPictureSelected(true);
                    setIsPictureRemoved(true);
                  }}
                  className=""
                  label="Remove"
                />
              </div>
            </div>
          </div>
        ) : selectPictureComponent ? (
          <FileInput
            accept="image/*"
            onChange={(e) => {
              onFileSelect(e);
            }}
            maxFileSize={10}>
            {selectPictureComponent}
          </FileInput>
        ) : (
          <div
            style={{ aspectRatio: `${aspectRatio}` }}
            className="relative flex place-content-center items-center rounded border border-dashed border-border">
            <FileInput
              className="mx-auto"
              accept="image/*"
              onChange={(e) => {
                onFileSelect(e);
              }}
              maxFileSize={10}>
              <div className="m-auto">
                <div className="set-logo flex justify-center text-sm font-semibold text-secondary-foreground opacity-100">
                  <I18>Upload cover picture</I18>
                </div>
                <p className="mt-4 text-center text-xs text-secondary-foreground/70">
                  {recommendedRatio} <I18>Aspect ratio (recommended)</I18>
                </p>
              </div>
            </FileInput>

            <div className="absolute bottom-1 right-0">
              {displayUnsplashSearch && (
                <TextButton
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setActiveUnSplash(true);
                  }}
                  className="mr-2 flex items-center space-x-4 p-2"
                  prefix={<UIcon icon="search" size="xs" className="mr-1" />}
                  label="Search image"
                />
              )}
            </div>
          </div>
        )}
      </div>
      {displayUnsplashSearch && (
        <UnsplashImagePicker
          active={activeUnSplash}
          setActive={setActiveUnSplash}
          initialPhotoSearchQuery={initialPhotoSearchQuery}
          onPhotoSelect={async (photo) => {
            setActiveUnSplash(false);
            selectFile(photo.blobData);
            setActiveCrop(true);
          }}
        />
      )}
    </>
  );
}

export default CropImageComponent;
