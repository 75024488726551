// import { DyteMeeting, Meeting } from "dyte-client";
import { useState } from "react";
import { Loader } from "../../_components";
import CreateSectionSidebarModal from "../../_components/group/create-section-modal-v2.component";
import IconMenu2 from "../../_components/icon-menu-2.component";
import RightSideModal from "../../_components/right-side-modal.component";
import UIcon from "../../_components/uicon-component";
import { history } from "../../_config";
import { getSectionPageRouteFromSlug } from "../group";

function GroupSectionComponent({
  onSubmit,
  group,
  user,
  tab,
  updateGroup = (e) => {},
  addToast = (e) => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onSubmitDelete = () => {
    onSubmit(tab.id);
  };
  return (
    <>
      <div
        onClick={() =>
          // history.push(getGroupPageRoute(group.id) + "?tab=" + tab.id)
          history.push(getSectionPageRouteFromSlug(group, tab))
        }
        className="GroupSection tab my-2 flex cursor-pointer items-center justify-between rounded-xl bg-card px-4 py-4">
        <div className="flex-col justify-center">
          <div className="mb-2.5 flex flex-grow items-center text-sm font-semibold text-secondary-foreground">
            {(tab.emoji !== "" && tab.emoji !== "#") ? tab.emoji : "#️⃣"}
            <div className="px-1 pl-1">{tab.name}</div>
          </div>
          <div className="flex items-center gap-2 text-secondary-foreground">
            <div className="text-xxs flex w-fit rounded bg-accent px-2.5 py-0.5">
              {tab.isPrivate ? (
                <div className="flex items-center gap-1.5">
                <UIcon icon="eye-crossed" size="xxs" />
                <span>Private</span>
              </div>
              ) : tab.isClosed ? (
                <div className="flex items-center gap-1.5">
                <UIcon icon="lock" size="xxs" />
                <span>Close</span>
                </div>
              ) : (
                <div className="flex items-center gap-1.5">
                  <UIcon icon="unlock" size="xxs" />
                  <span>Open</span>
                </div>
              )}
            </div>
            <div className="inline-block h-1 w-1 rounded-full bg-gray-400" />
            <div className="w-fit rounded bg-accent px-2.5 py-0.5">
              <div className="text-xxs flex items-center gap-1.5">
                <UIcon icon="user" size="xxs" />
                <span>{tab.isClosed ? tab.userCount : group.userCount}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {/* show menu if group by me */}
          {["admin", "moderator"].includes(group.myRole) ? (
            <IconMenu2
              className={isLoading ? "hidden" : "text-secondary-foreground"}
              actions={[
                {
                  icon: "edit",
                  label: "Edit",
                  onClick: (e) => {
                    // prevent it from opening the section
                    e.preventDefault();
                    setIsEditing(true);
                  },
                },
                {
                  icon: "trash",
                  label: "Delete",
                  labelClass: "text-alert",
                  onClick: (e) => {
                    // prevent it from opening the section
                    e.preventDefault();
                    // delete the section
                    onSubmitDelete();
                  },
                  actionType: "alert",
                },
              ]}
            />
          ) : (
            <></>
          )}
          {/* loader */}
          {isLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* edit tab model */}
      {/* <CreateSectionModal
        user={user}
        activeGroup={group}
        activeTab={tab}
        active={isEditing}
        setActive={setIsEditing}
        updateActiveGroup={updateGroup}
      /> */}
      <RightSideModal width={570} setActive={setIsEditing} active={isEditing}>
        <CreateSectionSidebarModal
          isEditingTabActive={isEditing}
          setIsCreateSectionModalVisible={setIsEditing}
          updateActiveGroup={updateGroup}
          selectedTabToEdit={tab}
        />
      </RightSideModal>
    </>
  );
}

const GroupSection = GroupSectionComponent;
export default GroupSection;
