import cx from "classnames";
import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import TurndownService from "turndown";
import { useAppSelector } from "../../../hooks/redux.hook";
import { useAppService } from "../../../hooks/use-app-service";
import { redirectToAuthUrl } from "../../../_config/helper.config";
import { MentionService } from "../../../_service/mention.service";
import { MessageService } from "../../../_service/message.service";
import checkForProfanity from "../../../_utils/restricted-words";
import { ActionModal } from "../../action-modal.component";
import I18 from "../../atoms/i18";
import Tooltip from "../../atoms/tooltip";
import { FileInput } from "../../form-controls/file-input";
import { SendButton } from "../../molecule/send-button";
import ModalPortal from "../../Portal";
import { MessageAttachments } from "../../realtime/message-card.component";
import TenorGifPicker from "../../tenor-gif-picker";
import UIcon from "../../uicon-component";
const RichTextEditor = React.lazy(
  () => import("../../form-controls/rich-text-editor.component")
);

const tds = new TurndownService();

/**
 * Component to create comment
 * @param {object} community - The community object
 * @param {object} user - The auth user object
 * @param {String} groupId - The group id
 * @param {String} tabId - The section id. Required for group chat.
 * @param {String} otherUserId - The other user id. Required for direct chat.
 * @param {String} parentMessage - The parent message. Required for reply on a message in group chat.
 * @param {function} addPost - Callback to add the new message in direct chat.
 * @returns
 */
function EditorComponent({
  community,
  user,
  groupId,
  group,
  activeTab,
  tabId,
  otherUserId,
  parentMessage = null,
  addPost = () => {},
}) {
  const [description, setDescription] = useState("");
  const [isPosting, setIsLoading] = useState(false);
  const { analyticsService } = useAppService();

  const [attachments, setAttachments] = useState([]);
  const [profanityWords, setProfanityWords] = useState([]);

  const { activeTabModel } = useAppSelector((state) => {
    return {
      activeTabModel: state.sections.activeSectionId
        ? state.sections.sections[state.sections.activeSectionId]
        : null,
    };
  });

  //removing toolbar
  const removeToolbar = `
  .ck.ck-balloon-panel.ck-balloon-panel_toolbar_west.ck-balloon-panel_visible {
    display: none;
  }
`;
  useEffect(() => {
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(removeToolbar));
    document.head.appendChild(style);

    // Return a function to remove the added style element
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const [errors, setErrors] = useState();

  // doc upload for sub comment
  const [isGifPickerVisible, setIsGifPickerVisible] = useState(false);

  const onSubmit = async (event) => {
    // const attachments = images;
    // try sending the message
    try {
      const restrictedWords = await checkForProfanity(description);
      if (restrictedWords.length > 0) {
        setProfanityWords(restrictedWords);
        return;
      }
      const embedlessMessage = await description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );
      let finalMessage = await tds.turndown(embedlessMessage);
      // create post
      const message = {
        description: finalMessage,
        groupId,
        tabId,
        attachments, // only used for validation here
        parentId: parentMessage?.id,
      };

      // check for validation on post
      const e = validatePost(message);

      setErrors(e);
      // create message only if no errors and description is not empty
      if (!e && description && description.trim().length > 0) {
        // start loading
        setIsLoading(true);
        // create post or message based on userId
        let createdMessageResponse = !otherUserId
          ? await MessageService.sendMessage(user, message)
          : await MessageService.sendDirectMessage(
              user,
              message.description,
              otherUserId
            );

        // add post
        addPost(createdMessageResponse.message);
        analyticsService.track("group-message", {
          sectionName: activeTab?.name,
          groupName: group?.name,
        });

        // reset the form
        setDescription("");
        // event.target.style.height = "auto";
        // event.target.style.height = event.target.scrollHeight + "px";

        // stop loading
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }

    /// try uploading the attachments
    try {
      // upload attachments
      if (attachments.length) {
        // stop loading
        setIsLoading(true);
        const createdMessageResponse = otherUserId
          ? await MessageService.uploadDirectAttachments(
              user,
              attachments,
              otherUserId
            )
          : await MessageService.uploadAttachments(
              user,
              {
                groupId,
                tabId,
                parentId: parentMessage?.id,
              },
              attachments
            );

        // add post
        addPost(createdMessageResponse.message);

        // stop loading
        setIsLoading(false);
        setAttachments([]);
        // setDocument(null);
        // setImages([]);
      } else {
        console.log("no attachments");
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  function validatePost(message) {
    const errors = {};
    // return errors
    // check if description empty
    if (!message.description && !message.attachments.length) {
      errors.description = "Description is required!";
    }

    // if no errors at all, delete post
    if (Object.keys(errors).length === 0) {
      return null;
    }
    // return the errors
    return errors;
  }
  // if no user, dont show the comment box
  if (!user) {
    return (
      <div>
        <MessageAttachments
          message={{ attachments: attachments }}
          removeAttachment={(index) => {
            setAttachments(attachments.filter((_, i) => i !== index));
          }}
        />
        <section className="border-b border-l border-r border-border bg-background p-4">
          <span
            className="cursor-pointer text-primary"
            onClick={(e) => {
              redirectToAuthUrl(community);
            }}>
            <I18>Sign in</I18>
          </span>{" "}
          <span>
            <I18>to add a comment</I18>
          </span>
        </section>
      </div>
    );
  }

  return (
    <div className="RealtimeSectionMessageEditor">
      <MessageAttachments
        message={{ attachments: attachments }}
        removeAttachment={(index) => {
          setAttachments(attachments.filter((_, i) => i !== index));
        }}
      />

      <div
        className={cx(
          "items-center rounded-lg border border-border bg-card p-2.5"
        )}>
        <div className="flex-col">
          <Suspense fallback={<></>}>
            <RichTextEditor
              className="noBorder minHeightDefault w-full max-w-[70vh] overflow-y-auto pb-2 text-sm text-secondary-foreground"
              text={description}
              setText={setDescription}
              onSubmit={onSubmit}
              placeholder={`Message in ${activeTabModel?.emoji} ${activeTabModel?.name}`}
              getMentionsFeed={async (query) => {
                const users = (
                  await MentionService.searchInCommunity(
                    user,
                    community.id,
                    query
                  )
                ).users.map((user, index) => {
                  const useruid = user.id;
                  user.id = "@" + user.userId;
                  user.userId = useruid;
                  return user;
                });
                return users;
              }}
            />
          </Suspense>
          <div className="flex items-center justify-between">
            <div className="flex gap-4">
              <Tooltip label="Attachment">
                <FileInput
                  accept="*"
                  maxFileSize={10}
                  disabled={attachments.length > 3}
                  onChange={(img) => {
                    setAttachments([...attachments, img]);
                  }}>
                  <div
                    className={cx(
                      "theme-bg-disable flex h-6 w-6 cursor-pointer place-content-center items-center rounded-full text-secondary-foreground/85 hover:scale-110",
                      {
                        "cursor-not-allowed text-secondary-foreground/70":
                          attachments.length > 3,
                      }
                    )}>
                    <UIcon size="xs" icon="plus" />
                  </div>
                </FileInput>
              </Tooltip>

              {/* GIF */}
              <Tooltip
                label="Gif"
                className={!setIsGifPickerVisible && "hidden"}>
                <button
                  className="p-.05 mt-0.5 hover:scale-110"
                  onClick={() => {
                    setIsGifPickerVisible(!isGifPickerVisible);
                  }}>
                  <span className="rounded border border-border px-2 text-xs text-secondary-foreground">
                    <I18>Gif</I18>
                  </span>
                </button>
              </Tooltip>
            </div>
            {/* opening Gif Modal */}
            <ModalPortal openPortal={isGifPickerVisible}>
              <TenorGifPicker
                active={isGifPickerVisible}
                setActive={setIsGifPickerVisible}
                initialSearchQuery=""
                onGifSelect={async (gif) => {
                  setIsGifPickerVisible(false);
                  // console.log(gif, "hello")
                  setAttachments([gif.blobData]);
                }}
              />
            </ModalPortal>
            {/* message input box */}

            <SendButton
              onClick={onSubmit}
              disabled={!description}
              isLoading={isPosting}
            />
          </div>
        </div>
      </div>
      <ActionModal
        active={profanityWords.length > 0}
        header="Message"
        buttons={[
          {
            label: "OK",
            type: "primary",
            onClick: () => {
              setProfanityWords([]);
            },
          },
        ]}
        children={
          <div className="flex flex-col px-4 pb-4">
            <p className="font-bold">
              The following links are restricted and cannot be used in the
              message
            </p>
            <ul className="ml-4 list-decimal">
              {profanityWords.map((word) => (
                <li key={word} className="text-secondary-foreground/80 text-sm">
                  {word}
                </li>
              ))}
            </ul>
          </div>
        }
        setActive={(e) => {
          setProfanityWords([]);
        }}
        onSubmit={() => {
          setProfanityWords([]);
        }}
      />
    </div>
  );
}

/**
 * Preview document component
 * @param {object} props
 *
 */
function SelectedDocumentPreview({
  document = null,
  setDocument = (e) => {},
  isSubComment,
}) {
  return (
    <>
      {!isSubComment && document && (
        <div className="SelectedDocumentPreview relative my-3">
          <a
            download
            target="_blank"
            rel="noreferrer"
            href={document}
            title="Open file"
            className="flex items-center space-x-2 rounded border-2 border-border px-4 py-2 font-semibold">
            <UIcon icon="document" className="h-9 text-3xl" />
            <div className="flex flex-grow items-center justify-between space-x-4">
              <div className="items-center">
                <div>{document.name}</div>
                <div className="text-xs uppercase">
                  {document.name.split(".").pop()}
                </div>
              </div>
              <span className="flex-none">
                <UIcon icon="download" className="text-xl" />
              </span>
            </div>
          </a>
          <div
            onClick={(e) => {
              // remove the videos
              setDocument(null);
            }}
            className="remove">
            &times;
          </div>
        </div>
      )}
    </>
  );
}

const RealtimeSectionMessageEditor = connect((s) => ({
  community: s.community,
}))(EditorComponent);
export default RealtimeSectionMessageEditor;
