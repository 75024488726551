import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import useModel from "../../hooks/use-model.hook";
import { useToast } from "../../hooks/use-toast.hook";
import TextButton from "../../_components/atoms/text-button";
import { Avatar } from "../../_components/avatar.component";
import { GroupMembersComponent } from "../../_components/group/group-members.component";
import AcceptRejectSectionMember from "../../_components/group/members/accept-reject-section.component";
import IconMenu2 from "../../_components/icon-menu-2.component";
import RightSideModal from "../../_components/right-side-modal.component";
import UIcon from "../../_components/uicon-component";
import { GroupService } from "../../_service";
import { Fold } from "../../_utils/extensions/typescript-utils";

type Props = {
  group: any;
  updateGroupMember: (e: any) => void;
  removeGroupMember: (e: any) => void;
  updateClosedSectionMember: (e: any) => void;
  removeClosedSectionMember: any;
  closedSectionMembersCount: any;
  closedSectionMembers: any;
  members: any;
};

const SectionSecondarySidebar = ({
  group,
  updateGroupMember = (e: any) => {},
  removeGroupMember = (e: any) => {},
  updateClosedSectionMember = (e: any) => {},
  removeClosedSectionMember,
  closedSectionMembersCount,
  closedSectionMembers,
  members,
}: Props) => {
  const [displayMore, setDisplayMore] = useState(false);
  const [showOnlyChannelMembers, setShowOnlyChannelMembers] = useState(false);

  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();
  const toast = useToast();
  const { user, activeTabModel, community } = useAppSelector((state) => {
    return {
      user: state.auth,
      community: state.community,
      activeTabModel: state.sections.activeSectionId
        ? state.sections.sections[state.sections.activeSectionId]
        : null,
    };
  });

  const { analyticsService } = useAppService();

  const joinedMembers =
    activeTabModel && activeTabModel.isClosed
      ? closedSectionMembers.filter(
          (m: any) => m.tabStatus === "joined" || m.tabStatus === "requested"
        ) // show only joined members to closed group
      : members.filter(
          (m: any) => m.groupStatus === "joined" || m.groupStatus === "requested"
        );

  return (
    <div
      className="secondary-sidebar h-full border border-border bg-card p-5"
      style={{ minWidth: "320px" }}>
      <div className="flex items-center justify-between border-b border-border pb-5 text-secondary-foreground">
        <h4 className="text-sm font-bold uppercase">
          {closedSectionMembersCount ?? group.userCount} Members
        </h4>
        <div className="flex items-center">
          {user &&
            (group?.myRole === "admin" || community?.myRole === "admin") &&
            (activeTabModel.isClosed || activeTabModel.isPrivate) && (
              <div
                className="mr-3 cursor-pointer"
                onClick={(e) => {
                  setDisplayMore(true);
                  setShowOnlyChannelMembers(false);
                  analyticsService.track("widget-groupmember", {
                    widgetType: "group-member",
                  });
                }}>
                <UIcon icon="user-add" />
              </div>
            )}
          <div
            className="cursor-pointer"
            onClick={(e) => {
              setDisplayMore(true);
              setShowOnlyChannelMembers(true);
              analyticsService.track("widget-groupmember", {
                widgetType: "group-member",
              });
            }}>
            <UIcon icon="search" />
          </div>
        </div>
      </div>
      {/* members section */}
      <div className="absolute h-full w-[90%] overflow-y-auto pt-5">
        {/* members list */}
        <div className="GroupMemberList">
          {joinedMembers?.map((member: any) => (
            <div
              className="group-member-list-item mb-2.5 flex cursor-pointer items-center justify-between"
              key={member.id}>
              <Avatar
                user={member}
                extraInfo={member.userId ? "@" + member.userId : ""}
                onClick={() => {
                  // open the user page
                  // window.open(createUserWallPageRoute(member.id, "activity"));
                  setShowProfileModel(true);
                  setIdToViewProfile(member.id);
                  analyticsService.track("widget-groupmember", {
                    clickOn: "user",
                    widgetType: "group-member",
                  });
                }}
                tag={
                  member.groupRole === "admin" ? (
                    <div className="text-xxs mx-2 rounded bg-accent px-1 font-semibold text-primary">
                      Admin
                    </div>
                  ) : member.groupRole === "moderator" ? (
                    <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                      Moderator
                    </div>
                  ) : member.groupRole === "user" ? (
                    <></>
                  ) : (
                    <></>
                  )
                }
              />

              {/* show request to admin */}
              {!activeTabModel || !activeTabModel.isClosed ? (
                user && group.myRole === "admin" ? (
                  <div className="flex items-center">
                    {/* group requests */}
                    {member.groupStatus === "requested" && (
                      <div className="flex items-center">
                        <span
                          onClick={(e) => {
                            // call api to accept user in group
                            GroupService.acceptUserRequest(
                              user,
                              group.id,
                              member.id
                            ).then(({ group }) => {
                              // update the current member
                              updateGroupMember({
                                ...member,
                                groupStatus: "joined",
                              });
                              toast.addToast("User added to group");
                            });
                          }}
                          className="px-1 text-primary hover:scale-105">
                          Accept
                        </span>
                        <span
                          onClick={(e) => {
                            // call api to accept user in group
                            GroupService.rejectJoinRequest(
                              user,
                              group.id,
                              member.id
                            ).then(({ group }) => {
                              // update the current member
                              removeGroupMember(member);
                              toast.addToast(
                                "User's join request removed from the group"
                              );
                            });
                          }}
                          className="px-1 text-alert hover:scale-105">
                          Reject
                        </span>
                      </div>
                    )}
                    {/* group admin operations */}
                    <Fold
                      value={
                        member.groupStatus === "joined" ||
                        ["admin", "moderator"].includes(member.groupRole)
                      }
                      ifPresent={() => (
                        <IconMenu2
                          dropdownClassName="w-56"
                          actions={[
                            {
                              // change user role
                              icon: "edit",
                              label:
                                member.groupRole === "admin"
                                  ? ""
                                  : member.groupRole === "moderator"
                                    ? "Make User"
                                    : "Make Moderator",
                              //@ts-ignore
                              onClick: (e, closeMenu) => {
                                // update the user role
                                GroupService.updateUserRole(
                                  user,
                                  group.id,
                                  member.id,
                                  member.groupRole === "moderator"
                                    ? "user"
                                    : "moderator"
                                )
                                  .then(({ group }) => {
                                    // update the current memeber
                                    updateGroupMember({
                                      ...member,
                                      groupRole:
                                        member.groupRole === "moderator"
                                          ? "user"
                                          : "moderator",
                                    });
                                    // update email
                                    closeMenu();
                                  })
                                  .catch((err) => {
                                    console.log({
                                      message:
                                        "Error while updating the group memeber role",
                                      err,
                                    });
                                  });
                              },
                            },
                            {
                              // button to remove group
                              icon: "cross-circle",
                              label:
                                member.groupRole === "admin"
                                  ? ""
                                  : "Remove from group", // cant remove admin
                              onClick: (e) => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to remove the user?"
                                  )
                                ) {
                                  // call user remove api
                                  GroupService.removeUser(
                                    user,
                                    group.id,
                                    member.id
                                  )
                                    .then(() => {
                                      // remove the current memeber
                                      removeGroupMember(member);
                                    })
                                    .catch((err) => {
                                      console.log({ err });
                                    });
                                }
                              },
                              actionType: "alert",
                            },
                          ]}
                          hideOnEmpty
                        />
                      )}
                    />
                  </div>
                ) : (
                  <></>
                )
              ) : (
                activeTabModel &&
                activeTabModel.isClosed && (
                  <AcceptRejectSectionMember
                    //@ts-ignore
                    member={member}
                    groupId={group.id}
                    activeTabId={activeTabModel ? activeTabModel.id : null}
                    user={user}
                    updateGroupMember={updateClosedSectionMember}
                  />
                )
              )}
            </div>
          ))}
          {(closedSectionMembersCount || group.userCount) > 15 && (
            <div className="text-xxs mb-2">
              <TextButton
                label="View All"
                onClick={(e: any) => {
                  setDisplayMore(true);
                  setShowOnlyChannelMembers(true);
                  analyticsService.track("widget-groupmember", {
                    widgetType: "group-member",
                  });
                }}
              />
            </div>
          )}
        </div>
        {/* Display all group and active tab Joined members  */}
        <RightSideModal
          active={displayMore}
          width="400px"
          setActive={setDisplayMore}>
          <GroupMembersComponent
            user={user}
            group={group}
            displayOnlyJoinedMembers={showOnlyChannelMembers}
            memberCount={closedSectionMembersCount}
            activeTabModel={activeTabModel}
            updateGroupMember={updateGroupMember}
            updateClosedSectionMember={updateClosedSectionMember}
            removeGroupMember={removeGroupMember}
            removeClosedSectionMember={removeClosedSectionMember}
            setActive={setDisplayMore}
          />
        </RightSideModal>
      </div>
    </div>
  );
};

export default SectionSecondarySidebar;
