import { useState } from "react";
import { withToast } from "../contexts/toastr.context";
import I18 from "./atoms/i18";
import IconButton2 from "./icon-button-2-component";
import { Modal } from "./modal.component";
import { TextInput } from "./text-input.component";
import UIcon from "./uicon-component";

function EmbedLinkModal({
  link = "",
  active = false,
  setActive = (e) => {},
  addToast,
}) {
  const [height, setHeight] = useState("200");

  return (
    <Modal
      active={active}
      setActive={setActive}
      padding={false}
      className="EmbedLinkModal">
      {/* headers */}
      <div className="py- flex items-center justify-between bg-card pl-4 pr-2">
        <span className="font-bold text-secondary-foreground/85">
          <I18>Embed</I18>
        </span>
        <IconButton2
          icon="cross"
          size="md"
          onClick={() => {
            setActive(false);
          }}
        />
      </div>
      {/* body */}
      <div className="p-5">
        <div className="mb-2">
          <I18>
            Just copy paste this code wherever you want it to be embedded
          </I18>
          :
        </div>
        <div className="flex items-center">
          <span className="font-semibold">
            <I18>Height</I18>:
          </span>
          <div className="flex-grow pl-3 pr-1">
            <TextInput
              placeholder="Height"
              defaultValue={height}
              className="bg-card"
              validator={(v) =>
                /^(\d)+$/g.test(v) ? "" : "Height can only be a number"
              }
              onChange={(height) => {
                if (/^(\d)+$/g.test(height)) {
                  setHeight(height);
                }
              }}
            />
          </div>
          <span className="font-semibold">px</span>
        </div>
        <pre className="relative mb-2 overflow-y-auto border border-border bg-card p-2 text-primary">
          <code>
            {`<embed
    height="${height}px"
    width="100%"
    src="${link}"
    type="text/html">
</embed>`}
          </code>
          <div
            onClick={() => {
              const elem = document.createElement("textarea");
              elem.value = `<embed
                                         height="${height}px"
                                         width="100%"
                                         src="${link}"
                                         type="text/html">
                                     </embed>`;
              document.body.appendChild(elem);
              elem.select();
              document.execCommand("copy");
              document.body.removeChild(elem);
              addToast("Copied to clipboard");
            }}
            className="theme-bg-disable t absolute right-2 top-2 cursor-pointer rounded px-2 py-1 text-secondary-foreground">
            <UIcon icon="copy" size="sm" />
          </div>
        </pre>
        {/* preview */}
        <div className="mb-2 font-semibold">
          <I18>Preview</I18>:
        </div>
        <embed
          height={`${height}px`}
          width="100%"
          src={link}
          className="w-full"
          type="text/html"></embed>
      </div>
    </Modal>
  );
}

export default withToast(EmbedLinkModal);
