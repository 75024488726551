import { UserService } from "../../_service";

export const SET_USER_COUNTERS = "SET_USER_COUNTERS";

/**
 * Set the notification list
 * @param {*} notifications
 * @returns
 */
export function setUserCounters(
  unreadNotificationsCount = 0,
  unreadMessagesCount = 0,
  unreadSectionNotificationsCount = {}
) {
  return {
    type: SET_USER_COUNTERS,
    unreadNotificationsCount,
    unreadMessagesCount,
    unreadSectionNotificationsCount,
  };
}

// thunk related

export function getUserCounters() {
  return (dispatch, getState) => {
    // get the user
    // get the counters of notification and messages
    UserService.getUserCounters().then((response) => {
      dispatch(
        setUserCounters(
          response.unreadNotificationsCount,
          response.unreadMessagesCount,
          response.unreadSectionNotificationsCount
        )
      );
    });
  };
}
