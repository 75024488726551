import React, { MouseEventHandler } from "react";
import I18 from "./atoms/i18";
import { Loader } from "./loader.component";

type Props = {
  buttonName: string;
  disabled?: boolean;
  buttonType?: any;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
};

const SidebarButtonComponent: React.FC<Props> = ({
  buttonName,
  disabled,
  buttonType,
  onClick,
  isLoading,
}) => {
  let loaderColor = "var(--theme-on-primary-color)";
  return (
    <button
      className={`flex min-w-[140px] max-w-[140px] cursor-pointer justify-center rounded border border-border px-4 py-2 ${
        buttonType === "success" && "bg-primary text-[#ffffff]"
      } ${buttonType === "cancel" && "bg-card"}`}
      onClick={onClick}>
      <span className="flex place-content-center items-center space-x-1 whitespace-normal">
        <span>
          {isLoading ? <Loader spinnerColor={loaderColor} size={20} /> : null}
        </span>
        <span>
          <I18>{buttonName}</I18>
        </span>
      </span>
    </button>
  );
};

export default SidebarButtonComponent;
