import { useAppService } from "../../hooks/use-app-service";

function CustomWidget(widget: any) {
  return (
    <div className="rounded-xl bg-card">
      <CustomWidgetComponent prop={widget} />
    </div>
  );
}

function CustomWidgetComponent({ prop }: any) {
  const { widget } = prop;

  const { analyticsService } = useAppService();

  return (
    <div className="">
      <div className="">
        {widget.details.picture && (
          <img
            className="w-full rounded-t-xl"
            src={widget.details.picture}
            alt="event"
            loading="lazy"
          />
        )}

        <div className="flex-col p-5 text-secondary-foreground">
          <div className="text-sm font-bold">{widget.details.title}</div>
          <div className="mt-2.5 text-xs">{widget.details.description}</div>
          <div className="mt-2.5">
            <button
              className="rounded border-2 border-primary bg-primary px-5 py-1 text-xs font-semibold text-primary-foreground focus:outline-none"
              onClick={(e) => {
                e.preventDefault();
                analyticsService.track("custom-widget-button-clicked");
                window.open(widget.details.buttonURL);
              }}>
              <span>{`${
                widget.details.buttonLabel &&
                widget.details.buttonLabel !== null
                  ? widget.details.buttonLabel
                  : "Click Here"
              }`}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CustomWidget;
