export function UserProfileHeaderShimmer() {
  return (
    <div className="animate-pulse rounded-2xl bg-card">
      {/* User Tile */}
      <div className="flex place-items-center p-4">
        {/* User Avatar */}
        <p className="h-[75px] w-[75px] flex-shrink-0 rounded-full border bg-background bg-gray-300 object-cover"></p>
        <div className="ml-2 w-max space-y-3">
          <p className="h-2 w-28 rounded bg-gray-300"></p>
          <p className="mt-2 h-2 w-24 rounded bg-gray-300" />
          <p className="mt-2 h-2 w-20 rounded bg-gray-300" />
        </div>
      </div>
    </div>
  );
}
