import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { useAppService } from "../../hooks/use-app-service";
import { CommunityService } from "../../_service";
import { setCommunity } from "../../_store/_actions/community.actions";
import MainUtil from "../../_utils/main.util";
import { ProfileUpdate } from "../profile/profile-update.component";

function CommunityVerificationPromptComponent({
  user,
  community,
  updateCommunity,
  addToast,
  web3Logout,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const communityId = community?.id;
  const { analyticsService } = useAppService();

  // load the community join status and join if required, when user has set his name
  useEffect(() => {
    if (
      community &&
      !("joined" in community) &&
      user &&
      user.name !== "New User"
    ) {
      setIsLoading(true);
      CommunityService.getCommunityById(user, community.id)
        .then(({ community }) => {
          // if we want to auto sub and user is not already subbed
          if (!community.joined) {
            // subscribe to community
            CommunityService.joinCommunity(user, community.id)
              .then(({ community }) => {
                setIsLoading(false);
                analyticsService.track("subscribe-community", {
                  communityPlan: community.plan
                    ? community.plan.planType
                    : "free",
                });
                // update community
                updateCommunity(community);
                // show message
                addToast("Subscribed to " + community.name);
              })
              .catch((error) => {
                setIsLoading(false);
                console.log({
                  error,
                  message: "Subscribe to community failed!",
                });
                addToast(
                  "Could not subscribe to " + community.name,
                  "",
                  ToastTypes.danger
                );
              });
          } else {
            // just update the community
            setIsLoading(false);
            updateCommunity(community);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log({ error, message: "Loading community by id failed!" });
          addToast(
            "Could not load community by id " + community.name,
            "",
            ToastTypes.danger
          );
        });
    }
  }, [communityId, user]); // no need to add addToast, community and setCommunity as it will break the code

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-background">
      <ProfileUpdate />
      {community.joined ? (
        <div className="flex flex-col items-center rounded bg-card p-12 pb-8">
          <img src={community.logo} alt="" className="w-72 rounded" />
          <div className="my-4 text-center text-lg font-bold text-secondary-foreground/85">
            Your membership is pending
          </div>
          <div className="w-72 text-center text-secondary-foreground/80">
            You'll be notified if your request to join has been approved.
          </div>

          {/* Logout */}
          <div className="my-8 h-0.5 w-full border-b border-border" />
          <div className="theme-border-danger mt-4 flex w-64 cursor-pointer place-content-center border">
            <button
              className="w-full rounded-full px-4 py-2 text-alert"
              onClick={() => {
                MainUtil.logout(community, web3Logout);
              }}>
              Logout
            </button>
          </div>
        </div>
      ) : (
        "Joining Community..."
      )}
    </div>
  );
}

const CommunityVerificationPrompt = withToast(
  connect(
    (s) => ({
      user: s.auth,
      community: s.community,
    }),
    (d) =>
      bindActionCreators(
        {
          updateCommunity: setCommunity,
        },
        d
      )
  )(CommunityVerificationPromptComponent)
);

export { CommunityVerificationPrompt };
