import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { BaseConfigContext } from "../../contexts/base-config.context";
import { withToast } from "../../contexts/toastr.context";
import { Community } from "../../types/community/community.type";
import { Group } from "../../types/group/group.type";
import { Section } from "../../types/group/section.type";
import { User } from "../../types/user/minimal-user.type";
import { Widget } from "../../types/widget/widget.type";
import { ActionModal } from "../../_components/action-modal.component";
import I18 from "../../_components/atoms/i18";
import GroupHeaderComponent from "../../_components/group-header.component";
import { GroupInfo } from "../../_components/group/group-info.component";
import RealtimeSectionDetail from "../../_components/group/realtime-section-detail.component";
import SectionDetail from "../../_components/group/section-detail";
import { MaxWidthWrapper } from "../../_components/max-width-wrapper";
import { ListView } from "../../_components/molecule/listview.component";
import SectionPostListShimmer from "../../_components/shimmers/section-post-list-shimmer.component";
import UIcon from "../../_components/uicon-component";
import { history } from "../../_config";
import { GroupService, WidgetService } from "../../_service";
import { RootState } from "../../_store";
import { activateSectionAction } from "../../_store/sections.store";
import { setActiveGroup } from "../../_store/_actions/group.actions";
import { Fold } from "../../_utils/extensions/typescript-utils";
import Validator from "../../_utils/validator";
import { COMMUNITY_HOMEPAGE_ROUTE } from "../community-home/community-home.page";
import GroupSecondarySidebar from "./group-secondary-sidebar.component";
import GroupSection from "./group-section.component";
import SectionSecondarySidebar from "./section-secondary-sidebar.component";
export const GROUPPAGE_ROUTE = "/group/:id";
export const GROUPPAGE_SLUG_ROUTE = "/g/:slug";
export const SECTIONPAGE_SLUG_ROUTE = "/g/:slug/s/:activeTabSlug";

export const getGroupPageRoute = (id: string | undefined) => "/group/" + id;
export const getGroupPageRouteFromSlug = (group: Group) => {
  if (Validator.hasValue(group.meta) && group?.meta?.slug != null) {
    return "/g/" + group.meta.slug;
  } else {
    return "/group/" + group.id;
  }
};
export const getSectionPageRouteFromSlug = (group: Group, section: Section) => {
  if (
    Validator.hasValue(group.meta) &&
    group?.meta?.slug != null &&
    Validator.hasValue(section.meta) &&
    section.meta.slug != null
  ) {
    return getGroupPageRouteFromSlug(group) + "/s/" + section.meta.slug;
  } else {
    return getGroupPageRoute(group.id) + "?tab=" + section.id;
  }
};

/**
 * @deprecated Since getSectionPageRoute should not be used. Use getSectionPageRouteFromSlug instead.
 */
export const getSectionPageRoute = (groupId: string, sectionId: string) =>
  "/group/" + groupId + "?tab=" + sectionId;

export const createGroupPageUrl = (id: any) => {
  return window.location.origin + getGroupPageRoute(id);
};

export const createGroupPageUrlFromSlug = (group: Group) => {
  return window.location.origin + getGroupPageRouteFromSlug(group);
};

export function createGroupSectionPageUrl(group: any, section: any) {
  return window.location.origin + getSectionPageRouteFromSlug(group, section);
}

interface GroupPageComponentProps {
  user: User;
  group: Group;
  community: Community;
  setGroup: (group: Group) => void;
  addToast: any;
  setIsCreateSectionModalVisible: (isVisible: boolean) => void;
  setIsPersonalSpace: (isPersonalSpace: boolean) => void;
  // state
  activeTabModel: Section;
  // dispatch
  setActiveTabModel: (section: Section | null) => void;
}
/**
 * @description - Component to display the group page / section page
 */
function GroupPageComponent({
  user,
  group,
  community,
  setGroup,
  addToast,
  setIsCreateSectionModalVisible,
  setIsPersonalSpace,
  // state
  activeTabModel,
  // dispatch
  setActiveTabModel,
}: GroupPageComponentProps) {
  const {
    // @ts-ignore
    id: _urlGroupId,
    // @ts-ignore
    slug: _groupSlug,
    // @ts-ignore
    activeTabSlug: _activeTabSlug,
  } = useParams();

  // get selected tab id from url
  const _activeSectionId = new URLSearchParams(window.location.search).get(
    "tab"
  );

  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);

  const [isLoadingClosedTabMembers, setIsLoadingClosedTabMembers] =
    useState(false);
  // const [activeSectionId, setActiveSectionId] = useState(spTabID);
  const [members, setMembers] = useState<any[]>([]);
  const [membersPage, setMembersPage] = useState(1);

  // closed tab members
  const [closedSectionMembers, setClosedSectionMembers] = useState<Array<any>>(
    []
  );
  const [closedSectionMembersCount, setClosedSectionMembersCount] = useState<
    Number | undefined
  >(undefined);

  const [widgets, setWidgets] = useState<Array<Widget>>();

  const [showShareModal, setShowShareModal] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeletingSection, setIsDeletingSection] = useState(false); //

  const [sectionToDeleteId, setSectionToDeleteId] = useState();

  const abortController = useMemo(() => new AbortController(), []);

  const { setBaseLayout } = useContext(BaseConfigContext);
  setBaseLayout();

  // Update current active tab only if new tab is part of the group
  // else set it to null, tab will be updated when group fetched from server
  useEffect(() => {
    // If new groupId if found in url, fetch group from server and update active tab
    if (group && Validator.hasValue(_urlGroupId) && group.id !== _urlGroupId) {
      return;
    }
    // If new group slug if found in url, fetch group from server and update active tab
    else if (
      group &&
      Validator.hasValue(_groupSlug) &&
      group?.meta?.slug !== _groupSlug
    ) {
      return;
    }

    const tab =
      group &&
      group.tabs?.find(
        (tab: Section) =>
          tab?.id === _activeSectionId ||
          (_activeTabSlug && tab.meta && tab.meta.slug === _activeTabSlug)
      );
    if (tab) {
      setActiveTabModel(tab);
    } else {
      setActiveTabModel(null);
    }
  }, [_activeSectionId, _activeTabSlug]);

  // Update current section if group's  tabs are changed
  useEffect(() => {
    if (
      group &&
      _urlGroupId &&
      _urlGroupId !== null &&
      group.id === _urlGroupId
    ) {
      updateSectionFromGroup(group);
    } else if (
      group &&
      _groupSlug &&
      _groupSlug !== null &&
      group?.meta?.slug === _groupSlug
    ) {
      updateSectionFromGroup(group);
    }
  }, [group?.tabs]);

  // load group
  useEffect(() => {
    // Load group detail using group id
    if (_urlGroupId !== null && _urlGroupId !== undefined) {
      if (group && _urlGroupId && group.id === _urlGroupId) {
        // console.log("_urlGroupId", _urlGroupId);
        // console.log("Group already loaded", group);
        return;
      }

      if (group && _groupSlug && group?.meta?.slug === _groupSlug) {
        // console.log("_groupSlug", _groupSlug);
        // console.log("Group already loaded", group);
        return;
      }
      setActiveTabModel(null);
      setIsLoadingDetail(true);

      // get the group for the current id
      GroupService.groupDetail(_urlGroupId)
        .then(({ groups: g }) => {
          updateSectionFromGroup(g);
          setGroup(g);
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          setIsLoadingDetail(false);
        });
    }
    // Load group detail using group slug
    else if (_groupSlug !== null && _groupSlug !== undefined) {
      if (
        group &&
        (group.id === _urlGroupId || group.meta?.slug === _groupSlug)
      ) {
        return;
      }
      setActiveTabModel(null);
      setIsLoadingDetail(true);
      // get the group for the current id
      GroupService.groupDetailFromSlug(community.id, _groupSlug)
        .then(({ groups: g }) => {
          updateSectionFromGroup(g);
          setGroup(g);
          // setIsLoadingDetail(false);
          // if active tab is not already selected, select it incase of action is tour
          // if (
          //   !activeTab &&
          //   community &&
          //   user &&
          //   community.myRole === "admin" &&
          //   !localStorage.getItem("pensil.tour-completed")
          // ) {
          //   // redirect user to active tab
          //   // history.push(getGroupPageRouteFromSlug(g));
          // }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          setIsLoadingDetail(false);
        });
    }
  }, [_urlGroupId, _groupSlug, user?.id, _activeSectionId, _activeTabSlug]);

  function updateSectionFromGroup(group: any) {
    if (group.tabs != null) {
      let section;

      if (_activeTabSlug && _activeTabSlug !== null) {
        section = group.tabs.find(
          (tab: Section) => tab.meta && tab.meta.slug === _activeTabSlug
        );
      } else if (_activeSectionId && _activeSectionId !== null) {
        section = group.tabs.find(
          (tab: Section) => tab.id === _activeSectionId
        );
      }
      setActiveTabModel(section);
    }
  }

  // load members
  useEffect(() => {
    try {
      if (group && !isLoadingDetail) {
        // Get members for the current group
        console.log("group", { group });

        // load members and posts only if group is open or joined
        if (group.groupType === "open" || group.joinStatus === "joined") {
          setIsLoadingMembers(true);
          // get all the group members
          GroupService.groupMembersPaginated(
            group.id,
            activeTabModel?.id
          )
            .then(({ groupMembers: users, page }) => {
              setMembers(users);
              setIsLoadingMembers(false);
              setMembersPage(1);
            })
            .catch((error) => {
              console.log({ error });
              setIsLoadingMembers(false);
              console.error("Error", error);
              // TODO: show error
            });
        }
        // Get members list of closed section
        if (
          activeTabModel &&
          activeTabModel.isClosed &&
          activeTabModel.status === "joined"
        ) {
          // load members only if group is open or joined
          if (group.groupType === "open" || group.joinStatus === "joined") {
            setIsLoadingClosedTabMembers(true);
            // get all the group members
            GroupService.groupClosedSectionMembersPaginated(
              group.id,
              activeTabModel.id,
              1,
              15
            )
              .then(({ members, joinedUserCount }) => {
                setClosedSectionMembers(members);
                setIsLoadingClosedTabMembers(false);
                setClosedSectionMembersCount(joinedUserCount);
              })
              .catch((error) => {
                console.log({ error });
                setIsLoadingClosedTabMembers(false);
                // TODO: show error
              });
          } else {
            // else clear the member
            setClosedSectionMembers([]);
            setClosedSectionMembersCount(undefined);
          }
        } else {
          setClosedSectionMembers([]);
          setClosedSectionMembersCount(undefined);
        }
      }
    } catch (e) {
      console.log("Error while loading", e);
    }
  }, [activeTabModel?.id, group?.id, isLoadingDetail]);

  // Get widgets for group
  useEffect(() => {
    if (group && group.id) {
      WidgetService.getWidgets(community.id!, group.id)
        .then(({ widgets: w }) => {
          let widList: Widget[] = [];
          if (w) {
            widList = w.filter((w: Widget) => w.type !== "community-members");
          }
          setWidgets(widList);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [group?.id]);

  // Abort all request on unmount
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [
    _urlGroupId,
    _groupSlug,
    _activeSectionId,
    _activeTabSlug,
    abortController,
  ]);

  // update group member
  const addGroupMember = (member: any) => {
    const newMembers = [...members];
    // @ts-ignore
    newMembers.push(member);
    setMembers(newMembers);
  };

  // update group member
  const updateGroupMember = (member: any) => {
    const newMembers = [...members] as Array<any>;
    const memberIndex = newMembers.findIndex((m) => m?.id === member.id);
    if (memberIndex > -1) {
      // @ts-ignore
      newMembers.splice(memberIndex, 1, { ...members[memberIndex], ...member });
    } else {
      newMembers.push(member);
    }
    // @ts-ignore
    setMembers(newMembers);
  };

  // update closed section members
  const updateClosedSectionMember = (member: { id: any }) => {
    const newMembers = [...closedSectionMembers] as Array<any>;
    const memberIndex = newMembers.findIndex((m) => m.id === member.id);
    if (memberIndex > -1) {
      // @ts-ignore
      newMembers.splice(memberIndex, 1, { ...members[memberIndex], ...member });
    } else {
      newMembers.push(member);
    }
    setClosedSectionMembers(newMembers);
  };

  // delete group member
  const removeGroupMember = (member: any) => {
    const newMembers = [...members] as Array<any>;
    const memberIndex = newMembers.findIndex((m) => m.id === member.id);
    newMembers.splice(memberIndex, 1);
    // @ts-ignore
    setMembers(newMembers);
  };

  // delete closed section members
  const removeClosedSectionMember = (member: { id: any }) => {
    const newMembers = [...closedSectionMembers];
    const memberIndex = newMembers.findIndex((m) => m.id === member.id);
    newMembers.splice(memberIndex, 1);
    setClosedSectionMembers(newMembers);
    setClosedSectionMembersCount(closedSectionMembersCount ?? 1 - 1);
  };

  if (!isLoadingDetail && !group) {
    return (
      <div className="GroupPage w-full py-20 text-center">
        <div className="my-2">
          <I18> The resource you are looking for could not be found,</I18>{" "}
          <br /> <I18>it may have been removed by admin!</I18>
        </div>
        <Link to={COMMUNITY_HOMEPAGE_ROUTE}>
          <I18>Go back to home</I18>
        </Link>
      </div>
    );
  }

  if (
    isLoadingDetail ||
    !group ||
    (group.id !== _urlGroupId && group.meta?.slug !== _groupSlug)
  ) {
    return (
      <MaxWidthWrapper className="max-w-[640px]">
        {/* <Loader /> */}
        <SectionPostListShimmer length={10} />
      </MaxWidthWrapper>
    );
  }

  const onClickDelete = (id: React.SetStateAction<any>) => {
    setIsDeleteModalVisible(true);
    setSectionToDeleteId(id);
  };

  const joinedMembers =
    activeTabModel && activeTabModel.isClosed
      ? closedSectionMembers.filter(
          (m) => m.tabStatus === "joined" || m.tabStatus === "requested"
        ) // show only joined members to closed group
      : members.filter(
          (m) => m.groupStatus === "joined" || m.groupStatus === "requested"
        );

  const onSubmitDelete = () => {
    if (!sectionToDeleteId) {
      addToast("Unable to delete channel!", "", "danger");
      console.error("sectionToDeleteId is not available");
      return;
    }
    setIsDeletingSection(true);
    GroupService.deleteGroupTab(user, group.id, sectionToDeleteId)
      .then(({ group }) => {
        // update the group
        addToast("Channel deleted!", "", "success");
        setGroup(group);
        setSectionToDeleteId(undefined);
      })
      .catch((error) => {
        console.log({ error });
        addToast("Unable to delete channel!", "", "danger");
      })
      .finally(() => {
        setIsDeleteModalVisible(false);
        setIsDeletingSection(false);
      });
  };
  return (
    <>
      <div className="flex flex-col md:grow">
        {activeTabModel && (
          <div
            className={`sticky top-[48px] z-[2] w-full border-b border-border bg-card px-5 py-3 md:h-16 lg:w-auto ${
              activeTabModel?.sectionType !== "realtime" &&
              "mb-4 lg:left-[300px] lg:right-0"
            } `}>
            <GroupHeaderComponent
              closedSectionMembersCount={closedSectionMembersCount}
              group={group}
              setGroup={setGroup}
              addToast={addToast}
              setShowShareModal={setShowShareModal}
              updateActiveGroup={setGroup}
              showShareModal={showShareModal}
              joinedMembers={joinedMembers}
            />
          </div>
        )}
        <div
          className={`mx-auto ${activeTabModel?.sectionType !== "realtime" && "min-h-screen"} flex w-full gap-0`}>
          <div
            className={`GroupPage ${
              activeTabModel?.sectionType !== "realtime"
                ? "mx-auto w-full lg:mx-10"
                : "w-full lg:w-auto lg:grow"
            } `}>
            <div className="flex w-full flex-col">
              <Fold
                value={activeTabModel}
                ifPresent={() => (
                  <SectionPage
                    user={user}
                    group={group}
                    activeTabModel={activeTabModel}
                    setGroup={setGroup}
                    showShareModal={showShareModal}
                    setShowShareModal={setShowShareModal}
                  />
                )}
                ifAbsent={() => (
                  <div className="">
                    {/* show group info if realtime not selected */}
                    <GroupInfo
                      group={group}
                      setGroup={(newGroup) => {
                        setGroup(newGroup);
                        history.push(getGroupPageRouteFromSlug(newGroup));
                      }}
                      activeTab={_activeSectionId}
                      // @ts-ignore
                      activeTabModel={activeTabModel}
                      showShareModal={showShareModal}
                      setShowShareModal={setShowShareModal}
                    />
                    {/* tabs list */}
                    <MaxWidthWrapper className="max-w-4xl pb-40">
                      <ListView
                        items={group?.tabs}
                        renderItem={(tab, index) => (
                          <>
                            {!["admin", "moderator"].includes(
                              group.myRole || ""
                            ) &&
                            !tab?.isJoined &&
                            tab?.isPrivate ? null : (
                              <GroupSection
                                onSubmit={onClickDelete}
                                user={user}
                                updateGroup={setGroup}
                                addToast={addToast}
                                tab={tab}
                                key={index}
                                group={group}
                              />
                            )}
                          </>
                        )}
                        noItemsElement={
                          <>
                            {/* create tab button */}
                            {group && ["admin"].includes(group.myRole ?? "") ? (
                              <div
                                onClick={() => {
                                  setIsCreateSectionModalVisible(true);
                                  setIsPersonalSpace(false);
                                }}
                                className="my-4 flex cursor-pointer items-center justify-center rounded-xl border border-dashed border-primary py-3">
                                <UIcon icon="plus" className="text-primary" />
                                <div className="bg-primary pl-2 text-xs uppercase text-primary">
                                  <I18>Create new channel</I18>
                                </div>
                              </div>
                            ) : (
                              <div className="no-post my-10 p-2 text-center font-bold">
                                <I18> Looks like it's lonely here.</I18>
                              </div>
                            )}
                          </>
                        }
                        footerElement={
                          <>
                            {/* create tab button */}
                            {group &&
                              ["admin"].includes(group.myRole ?? "") && (
                                <div
                                  onClick={() => {
                                    setIsCreateSectionModalVisible(true);
                                    setIsPersonalSpace(false);
                                  }}
                                  className="my-4 flex cursor-pointer items-center justify-center rounded-xl border border-dashed border-primary bg-card py-3">
                                  <UIcon icon="plus" className="text-primary" />
                                  <div className="pl-2 text-xs uppercase text-primary">
                                    {" "}
                                    <I18>Create new channel</I18>
                                  </div>
                                </div>
                              )}
                          </>
                        }
                      />
                    </MaxWidthWrapper>
                  </div>
                )}
              />
            </div>
          </div>

          {activeTabModel?.sectionType === "generic" && (
            <div className={"mx-auto flex justify-center xl:mr-10 xl:w-[35%]"}>
              {group.groupType !== "open" && group.joinStatus !== "joined" ? (
                <></>
              ) : (
                <GroupSecondarySidebar
                  widgets={widgets}
                  onWidgetListUpdate={(list) => {
                    setWidgets(list);
                  }}
                  addGroupMember={addGroupMember}
                  updateGroupMember={updateGroupMember}
                  updateClosedSectionMember={updateClosedSectionMember}
                  removeGroupMember={removeGroupMember}
                  removeClosedSectionMember={removeClosedSectionMember}
                  closedSectionMembers={closedSectionMembers}
                  closedSectionMembersCount={closedSectionMembersCount}
                  members={members}
                />
              )}
            </div>
          )}
          {activeTabModel?.sectionType === "realtime" && (
            <div className="flex justify-end lg:mr-1">
              <SectionSecondarySidebar
                group={group}
                updateGroupMember={updateGroupMember}
                removeGroupMember={removeGroupMember}
                updateClosedSectionMember={updateClosedSectionMember}
                removeClosedSectionMember={removeClosedSectionMember}
                closedSectionMembers={closedSectionMembers}
                closedSectionMembersCount={closedSectionMembersCount}
                members={members}
              />
            </div>
          )}
        </div>
      </div>

      {/* Delete section */}
      <ActionModal
        active={isDeleteModalVisible}
        setActive={setIsDeleteModalVisible}
        onSubmit={onSubmitDelete}
        header="Delete Channel"
        btnColor="red"
        title="Are you sure you want to delete the channel?"
        isLoading={isDeletingSection}
        children={undefined}
      />
    </>
  );
}

/**
 * Section Page
 */
function SectionPage({
  user,
  group,
  setGroup,
  activeTabModel,
  showShareModal,
  setShowShareModal,
}: any) {
  if (activeTabModel?.sectionType === "realtime") {
    return (
      <RealtimeSectionDetail
        user={user}
        group={group}
        activeTabModel={activeTabModel}
        setGroup={setGroup}
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
      />
    );
  }

  return (
    <SectionDetail
      user={user}
      group={group}
      activeTabModel={activeTabModel}
      sectionId={activeTabModel.id}
      setGroup={setGroup}
      showShareModal={showShareModal}
      setShowShareModal={setShowShareModal}
      updateActiveGroup={setGroup}
    />
  );
}

const stp = (state: RootState) => ({
  user: state.auth,
  group: state.activeGroup,
  community: state.community,
  activeTabModel: state.sections.activeSectionId
    ? state.sections.sections[state.sections.activeSectionId]
    : null,
});
const dtp = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setGroup: setActiveGroup,
      setActiveTabModel: activateSectionAction,
    },
    dispatch
  );

const GroupPage = withToast(connect(stp, dtp)(GroupPageComponent));

export default GroupPage;
