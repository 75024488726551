export type BillingCycle = "monthly" | "annually";
export interface Plan {
  key: string;
  keys?: {
    monthly?: string,
    yearly?: string
  }
  name: string;
  pricing?: {
    monthly?: number;
    yearly?: number;
  };
  limits: PlanLimits;
  tagline?: string;
  rank?: number;
}
export interface PlanLimits {
  members: string | number;
  liveSession: string | number;
  transactionFees: string;
  sso: boolean;
  apiAccess?: string | boolean;
}

export const BillingPlans: Plan[] = [
  {
    key: "basic",
    name: "Basic",
    rank: 1,
    keys: {
      monthly: 'basic',
      yearly: 'basicAnnual'
    },
    pricing: {
      monthly: 90,
      yearly: 972,
    },
    limits: {
      members: 1000,
      liveSession: 10,
      sso: false,
      apiAccess: false,
      transactionFees: "7.5%",
    },
  },
  {
    key: "pro",
    name: "pro",
    rank: 2,
    keys: {
      monthly: 'pro',
      yearly: 'proAnnual'
    },
    pricing: {
      monthly: 200,
      yearly: 2040,
    },
    limits: {
      members: 10000,
      liveSession: 10,
      sso: true,
      apiAccess: false,
      transactionFees: "3%",
    },
  },
  {
    key: "business",
    name: "Business",
    rank: 3,
    keys: {
      monthly: 'business',
      yearly: 'businessAnnual'
    },
    pricing: {
      monthly: 350,
      yearly: 3360,
    },
    limits: {
      members: 25000,
      liveSession: 10,
      sso: true,
      apiAccess: true,
      transactionFees: "0%",
    },
  },
  {
    key: "enterprise",
    name: "Enterprise",
    tagline: "Self hosting, custom features development, with SDKs.",
    limits: {
      members: "Unlimited",
      liveSession: "Unlimited",
      sso: true,
      apiAccess: "Custom API",
      transactionFees: "Custom Payment Gateway",
    },
  },
];

// function to get plan using keys
export function getPlanUsingKey(key: string) {
  if (key) {
    const plan = BillingPlans.find((p) => [p.key, p.keys?.monthly, p.keys?.yearly].includes(key));
    return plan;
  }
}

// Get plan name using key
export function getPlanNameByKey(key: string) {
  const plan = getPlanUsingKey(key);
  return plan?.name;
}

// Get Billing cycle using key
export function getBillingCycleByKey(key: string) {
  const plan = getPlanUsingKey(key);
  return plan?.keys?.monthly === key ? "monthly" : "annually";
}