import cx from "classnames";
import React from "react";
import { useAppService } from "../hooks/use-app-service";
import I18 from "./atoms/i18";
import UIcon from "./uicon-component";

/**
 * Filter component.
 *
 * @param {string} selectedOption - The currently selected option.
 * @param {Function} setSelectedOption - A function to set the selected option.
 * @param {Array} options - An array of option objects with keys and labels.
 */
interface Props {
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  options: {
    key: string;
    label: string;
    icon: any;
    isCommunityTopic?: boolean;
    bgColor?: string;
    textColor?: string;
  }[];
  removeSelectedCommunityTopicFilter: () => void;
}

const Filter: React.FC<Props> = ({
  selectedOption,
  setSelectedOption,
  options,
  removeSelectedCommunityTopicFilter,
}) => {
  const { analyticsService } = useAppService();

  return (
    <div className="flex gap-2.5 overflow-x-auto w-fit" style={{ scrollbarWidth: "none" }}>
      {options.map((option) => (
        <button
          key={option.key}
          className={cx(
            "flex cursor-pointer items-center justify-center gap-1.5 rounded-full border border-border px-2 py-1 text-xs transition-all duration-200 ease-in-out",
            {
              "cursor-pointer bg-primary text-primary-foreground":
                selectedOption === option.key,
              "text-secondary-foreground/70 focus-within:scale-[.95] hover:border-secondary-foreground/50 hover:bg-accent/90 hover:text-secondary-foreground/90":
                selectedOption !== option.key,
            }
          )}
          style={{
            minWidth: "112px",
            backgroundColor: option.bgColor,
            color: option.textColor,
          }}
          onClick={() => {
            if (selectedOption !== option.key) {
              setSelectedOption(option.key);
              analyticsService.track("post-filter", {
                filterName: option.key,
              });
            }
          }}>
          {option.icon && <UIcon icon={option.icon} className="mr-1" />}
          <I18>{option.label}</I18>
          {option.isCommunityTopic && (
            <div onClick={removeSelectedCommunityTopicFilter}>
              <UIcon icon="cross" className="ml-2" />
            </div>
          )}{" "}
        </button>
      ))}
    </div>
  );
};

export default Filter;
