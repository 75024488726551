import { SVGProps } from "react";

export function VideoCameraIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14343_14582)">
        <path
          d="M1.62695 4.1997C0.861328 4.41064 0.220703 5.08642 0.0605469 5.84814C-0.0253906 6.24267 -0.0253906 13.7583 0.0605469 14.1528C0.224609 14.9263 0.865234 15.5942 1.64258 15.8013C1.97461 15.8911 11.5176 15.8872 11.8535 15.8013C12.5723 15.6138 13.166 15.0474 13.4043 14.3325L13.498 14.0434V10.0005C13.498 6.19579 13.4941 5.94189 13.4277 5.72314C13.1973 4.96923 12.5996 4.3872 11.8574 4.1997C11.6582 4.14892 10.9863 4.1411 6.7207 4.14501C2.72852 4.14892 1.7832 4.15673 1.62695 4.1997Z"
          fill="currentColor"
        />
        <path
          d="M18.2578 5.36448C18.1875 5.39183 17.3398 5.86839 16.3711 6.42698L14.6094 7.44261L14.6172 9.99339L14.6289 12.5481L16.3477 13.5325C17.293 14.0754 18.1367 14.5442 18.2227 14.5793C18.4883 14.6848 18.7148 14.7004 18.9766 14.634C19.3867 14.5285 19.7227 14.2512 19.8984 13.8723C19.9805 13.6926 19.9805 13.677 19.9922 10.1028C20.0039 6.15355 20.0078 6.23167 19.7656 5.88011C19.5039 5.5012 19.1328 5.3098 18.6602 5.31761C18.5039 5.31761 18.3242 5.34105 18.2578 5.36448Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14343_14582">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
