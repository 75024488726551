import React from "react";
import LazyImage from "../atoms/lazy-image";

/**
 * @description PhotoCard component for UnsplashImagePicker
 * @param {Object} photo - The photo to display.
 * @param {funtion} onPhotoSelect - Function to call when a photo is selected.
 */
export default function UnsplashPhotoCard({
  photo,
  onPhotoSelect = (_: any) => {},
}: any) {
  const [zoom, setZoom] = React.useState(false);
  return (
    <div
      className="group relative h-60 w-full cursor-pointer place-items-center border border-border object-cover sm:h-44 md:h-32"
      key={photo.id}
      onClick={() => onPhotoSelect(photo)}>
      <LazyImage
        className="card-img h-full w-full place-items-center rounded object-cover"
        src={photo.urls.thumb}
        alt={photo.alt_description}
      />
      <div
        className="invisible absolute bottom-0 left-0 right-0 top-0 group-hover:visible group-hover:bg-black/20"
        style={{ color: "white" }}>
        <div className="m-2 flex place-content-center items-center justify-between space-x-1">
          <div className="flex items-center space-x-1">
            <LazyImage
              className="h-6 w-6 rounded-full"
              src={photo.user.profile_image.small}
              alt={photo.user.username}
            />
            <h6 className="word-breaker text-xs">{photo.user.name}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
