import { useContext } from "react";
import { ModalContext, ModalContextType } from "../contexts/model-provider";

/**
 * Hook to get the language translator
 */

export default function useModel(): ModalContextType {
  return useContext(ModalContext);
}
