import cx from "classnames";
import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import TurndownService from "turndown";
import { withToast } from "../../contexts/toastr.context";
import { useAppService } from "../../hooks/use-app-service";
import { MentionService } from "../../_service/mention.service";
import { MessageService } from "../../_service/message.service";
import I18 from "../atoms/i18";
import Tooltip from "../atoms/tooltip";
import { FileInput } from "../form-controls/file-input";
import { SendButton } from "../molecule/send-button";
import ModalPortal from "../Portal";
import TenorGifPicker from "../tenor-gif-picker";
import UIcon from "../uicon-component";
import { MessageAttachments } from "./message-card.component";
const RichTextEditor = React.lazy(
  () => import("../form-controls/rich-text-editor.component")
);

const tds = new TurndownService();

function MessageBoxComponent({
  groupId = null,
  group = null,
  tabId = null,
  parentMessage = null,
  user,
  community,
  otherUserId = null,
  addPost = (post) => {},
  addToast,
  attachments = [],
  setAttachments = null,
  smoothScrollToBottom = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const removeToolbar = `
  .ck.ck-balloon-panel.ck-balloon-panel_toolbar_west.ck-balloon-panel_visible {
    display: none;
  }
`;

  const { analyticsService } = useAppService();

  useEffect(() => {
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(removeToolbar));
    document.head.appendChild(style);

    // Return a function to remove the added style element
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const [description, setDescription] = useState("");
  const [isGifPickerVisible, setIsGifPickerVisible] = useState(false);

  const [errors, setErrors] = useState(null);

  const sendMessage = async (event) => {
    // try sending the message
    try {
      const embedlessMessage = await description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );
      let finalMessage = await tds.turndown(embedlessMessage);
      // create post
      const message = {
        description: finalMessage,
        groupId,
        tabId,
        attachments, // only used for validation here
        parentId: parentMessage?.id,
      };

      // check for validation on post
      const e = validatePost(message);

      setErrors(e);
      // create message only if no errors and description is not empty
      if (!e && description && description.trim().length > 0) {
        // start loading
        setIsLoading(true);
        // create post or message based on userId
        let createdMessageResponse = !otherUserId
          ? await MessageService.sendMessage(user, message)
          : await MessageService.sendDirectMessage(
              user,
              message.description,
              otherUserId
            );

        // add post
        addPost(createdMessageResponse.message);

        // reset the form
        setDescription("");
        event.target.style.height = "auto";
        event.target.style.height = event.target.scrollHeight + "px";

        // stop loading
        setIsLoading(false);
      }
    } catch (error) {
      //
      setIsLoading(false);
      console.log({ error });
    }
    // try uploading the attachments
    try {
      // upload attachments
      if (attachments.length) {
        // stop loading
        setIsLoading(true);
        const createdMessageResponse = otherUserId
          ? await MessageService.uploadDirectAttachments(
              user,
              attachments,
              otherUserId
            )
          : await MessageService.uploadAttachments(
              user,
              {
                groupId,
                tabId,
                parentId: parentMessage?.id,
              },
              attachments
            );

        // add post
        addPost(createdMessageResponse.message);

        // stop loading
        setIsLoading(false);
        // reset the attachments
        setAttachments([]);
      }
    } catch (error) {
      //
      setIsLoading(false);
      console.log({ error });
    }

    analyticsService.track("send-dm");
  };

  let rows = description.split("\n").length;
  rows = rows > 0 ? rows : 1;

  const addAttachment = (file) => {
    if (setAttachments) {
      setAttachments([...attachments, file]);
    }
  };

  const removeAttachment = (index) => {
    if (setAttachments) {
      setAttachments(attachments.filter((_, i) => i !== index));
    }
  };

  const CreatePostButton1 = () => {
    return (
      <SendButton
        onClick={sendMessage}
        disabled={!description}
        isLoading={isLoading}
        className="disabled:bg-secondary-foreground/20 disabled:text-secondary-foreground/70"
      />
    );
  };

  return (
    <>
      <div className="RealtimeCreatePostCard rounded bg-card px-4 py-2">
        <div className="px-4">
          <MessageAttachments
            message={{ attachments: attachments }}
            removeAttachment={removeAttachment}
          />
        </div>
        {/* user avatar and text area */}
        <div
          className={cx(
            "flex flex-row items-center rounded border border-border bg-background px-2"
          )}>
          <div className="Toolbar flex items-center gap-4">
            <Tooltip label="Attachment">
              <FileInput
                accept="*"
                disabled={attachments.length > 3}
                onChange={(img) => {
                  setAttachments([...attachments, img]);
                }}>
                <div
                  className={cx(
                    "cursor-pointe theme-bg-disable ml-1 flex h-6 w-6 place-content-center items-center rounded-full text-secondary-foreground/85 hover:scale-110",
                    {
                      "cursor-not-allowed text-secondary-foreground/70":
                        attachments.length > 3,
                    }
                  )}>
                  <UIcon size="xs" icon="plus" />
                </div>
              </FileInput>
            </Tooltip>

            {/* GIF */}
            <Tooltip label="Gif" className={!setIsGifPickerVisible && "hidden"}>
              <button
                className="p-.05 hover:scale-110"
                onClick={() => {
                  setIsGifPickerVisible(!isGifPickerVisible);
                }}>
                <span className="rounded border border-secondary-foreground px-2 text-xs">
                  <I18>Gif</I18>
                </span>
              </button>
            </Tooltip>
            {/* opening Gif Modal */}
            <ModalPortal openPortal={isGifPickerVisible}>
              <TenorGifPicker
                active={isGifPickerVisible}
                setActive={setIsGifPickerVisible}
                initialSearchQuery=""
                onGifSelect={async (gif) => {
                  setIsGifPickerVisible(false);
                  // console.log(gif, "hello")
                  setAttachments([gif.blobData]);
                }}
              />
            </ModalPortal>
          </div>

          <Suspense
            fallback={
              <div className="h-14 w-full rounded border border-border px-2"></div>
            }>
            <RichTextEditor
              className="noBorder minHeightDefault w-full max-w-[70vh] overflow-y-auto px-2"
              text={description}
              setText={setDescription}
              onSubmit={sendMessage}
              placeholder={"Write here..."}
              getMentionsFeed={async (query) => {
                const users = (
                  await MentionService.searchInCommunity(
                    user,
                    community.id,
                    query
                  )
                ).users.map((user, index) => {
                  const useruid = user.id;
                  user.id = "@" + user.userId;
                  user.userId = useruid;
                  return user;
                });
                return users;
              }}
            />
          </Suspense>

          <CreatePostButton1 />
        </div>
      </div>
    </>
  );
}

// function AttachmentButton({
//   attachments = [],
//   addAttachment = (attachment) => {},
// }) {
//   return (
//     <>
//       <input
//         className="hidden"
//         type="file"
//         onChange={(e) => {
//           const file = e.target.files[0];
//           addAttachment(file);
//           e.target.value = null;
//         }}
//       />
//       {/* attachment button */}
//       <IconButton
//         icon={"img"}
//         iconSmall
//         img={"/assets/images/create-post/attachment.png"}
//         hoverable={false}
//         onClick={(e) => {
//           // trigger file upload
//           if (e.target.previousSibling) {
//             e.target.previousSibling.click();
//           } else {
//             e.target.parentElement.previousSibling.click();
//           }
//         }}
//       />
//     </>
//   );
// }

function validatePost(message) {
  const errors = {};
  // return errors
  // check if description empty
  if (!message.description && !message.attachments.length) {
    errors.description = "Description is required!";
  }

  // if no errors at all, delete post
  if (Object.keys(errors).length === 0) {
    return null;
  }
  // return the errors
  return errors;
}

const MessageBox = withToast(
  connect((s) => ({
    user: s.auth,
    community: s.community,
  }))(MessageBoxComponent)
);

export { MessageBox };
