import cx from "classnames";
import React, { useState } from "react";
import useLang from "../hooks/use-lang.hook";

export const ToastContext = React.createContext(null);

// a toast can have following things, title = '', message = '', type = ''
export const ToastTypes = {
  danger: "danger",
  success: "success",
  warning: "warning",
  info: "info",
};

export const ToastProvider = (props) => {
  const [toasts, setToasts] = useState([]);

  const lang = useLang();

  const removeToast = (toast) => {
    const newToast = [...toasts];
    newToast.splice(
      newToast.find((t) => t.timestamp === toast.timestamp),
      1
    );
    setToasts(newToast);
  };

  const addToast = (title = "", message = "", type = "", time = 3000) => {
    title = lang.trans(title);
    message = lang.trans(message);
    const toast = { title, message, type, timestamp: new Date().getTime() };
    setToasts([...toasts, toast]);
    setTimeout(() => {
      if (toasts.findIndex((t) => t.timestamp === toast.timestamp)) {
        removeToast(toast);
      }
    }, time);
  };

  const addToastWithAction = ({
    title = "",
    message = "",
    type = "",
    time = 3000,
    onClick = () => {},
  }) => {
    const toast = {
      title,
      message,
      type,
      timestamp: new Date().getTime(),
      onClick,
    };
    setToasts([...toasts, toast]);
    setTimeout(() => {
      if (toasts.findIndex((t) => t.timestamp === toast.timestamp)) {
        removeToast(toast);
      }
    }, time);
  };

  return (
    <ToastContext.Provider
      value={{
        toasts,
        addToast,
        addToastWithAction,
        removeToast,
      }}>
      {props.children}
    </ToastContext.Provider>
  );
};

export const withToast = (Component) => (props) => (
  <ToastContext.Consumer>
    {(toast) => <Component {...toast} {...props} />}
  </ToastContext.Consumer>
);

export const ToastNotifier = withToast(({ toasts, removeToast }) => {
  const getStyle = (type) => {
    switch (type) {
      case "danger":
        return "bg-red-300 text-white";
      case "success":
        return "bg-green-300 text-green-900";
      case "info":
        return "bg-blue-300 text-blue-900";
      case "warning":
        return "bg-orange-300 text-orange-900";
      default:
        return "bg-green-300 text-green-900";
    }
  };

  return (
    <div className="ToastNotifier">
      {toasts.map((toast) => {
        return (
          <div
            className={cx(
              "toast mt-2 flex rounded px-4 py-2",
              getStyle(toast.type),
              {
                "cursor-pointer": toast.onClick,
              }
            )}
            onClick={(e) => {
              if (toast.onClick) {
                toast.onClick((e) => {
                  removeToast(toast);
                });
              }
            }}
            key={toast.timestamp}>
            <div className="toast-body flex-grow">
              <div className="toast-title">{toast.title}</div>
              <div className="toast-message">{toast.message}</div>
            </div>
            <div
              className="toast-delete flex-shrink-0"
              onClick={() => {
                removeToast(toast);
              }}>
              &times;
            </div>
          </div>
        );
      })}
    </div>
  );
});
