import { useAppDispatch } from "../../../../hooks/redux.hook";
import { Post } from "../../../../types/post/post.type";
import {
  setCurrentSectionPosts,
  updateSectionPinnedPost,
} from "../../../../_store/sections.store";
import I18 from "../../../atoms/i18";
import { MinimizedPostCard } from "../../../minimised-post-card";
import GridPostsList from "../../../post/grid-post-list-component";
import { PostCardList } from "../../../post/post-card-list.component";
import PostListShimmer, {
  PostTileType,
} from "../../../shimmers/post/post-list-shimmer.component";

interface Props {
  user: any;
  activeTabModel: any;
  posts: Array<Post>;
  setPosts: (posts: Array<Post>) => void;
  pinnedPosts: Array<Post>;
  isLoadingPosts: boolean;
  noMorePosts: boolean;
  isLoadingMorePosts: boolean;
  onTopicSelect?: (topic: any) => {};
}
/**
 * @description - Display list of posts in section detail page
 * @param {any} user - user object
 * @param {any} activeTabModel - active section object
 * @param {Array<any>} posts - list of posts in section
 * @param {Function} setPosts - set list of posts in section
 * @param {Array<any>} pinnedPosts - list of pinned posts in section
 * @param {boolean} isLoadingPosts - Flag to check if posts are loading
 * @param {boolean} noMorePosts - Flag to check if there are more posts left to load
 * @param {boolean} isLoadingMorePosts - Flag to check if more posts are loading
 */
export default function SectionPostList({
  user,
  activeTabModel,
  posts,
  setPosts,
  pinnedPosts,
  noMorePosts = false,
  onTopicSelect,
  isLoadingPosts = false,
  isLoadingMorePosts = false,
}: Props) {
  const dispatch = useAppDispatch();
  if (activeTabModel.sectionView === "grid") {
    return (
      <>
        <GridPostsList user={user} isLoading={isLoadingPosts} posts={posts} />
        <>
          {isLoadingMorePosts ? (
            <div className="PostList my-2 grid grid-cols-1 gap-4 rounded p-2 px-2 sm:grid-cols-2 sm:px-0">
              {Array.from({ length: 6 }).map((_, i) => (
                <PostListShimmer
                  key={i}
                  length={1}
                  type={PostTileType.compact}
                  className="rounded-md bg-card"
                />
              ))}
            </div>
          ) : (
            <div className="h-96"></div>
          )}
        </>
      </>
    );
  }

  if (activeTabModel.sectionView === "compact") {
    return (
      <>
        {posts?.map((post: Post) => (
          <MinimizedPostCard
            post={post}
            user={user}
            showChannelName={false}
            updatePost={(post: Post) => {
              // get post index
              const postIndex = posts.findIndex((p: Post) => p.id === post.id);
              const oldPosts = [...posts];
              oldPosts.splice(postIndex, 1, post);
              setPosts(oldPosts);
            }}
            showFullPost={posts.length === 1}
            deletePost={(post: Post) => {
              // get post index
              const postIndex = posts.findIndex((p: Post) => p.id === post.id);
              const oldPosts = [...posts];
              oldPosts.splice(postIndex, 1);
              setPosts(oldPosts);
            }}
            key={post.id}
            displayLikeCommentIcons={
              !(post.preferences?.hideLikes && post.preferences?.hideComment)
            }
          />
        ))}
      </>
    );
  }
  return (
    <div>
      {/* pinned posts */}
      <PostCardList
        posts={pinnedPosts}
        allPosts={posts}
        onTopicSelect={onTopicSelect}
        onPostUpdate={(post, list) => {
          if (post.isPinned) {
            dispatch(updateSectionPinnedPost(post));
          }
          if (list) {
            dispatch(setCurrentSectionPosts(list));
          }
        }}
      />
      {/* normal posts */}
      <PostCardList
        posts={posts.filter((p: Post) => !p.isPinned)}
        allPosts={posts}
        isLoading={isLoadingPosts}
        onTopicSelect={onTopicSelect}
        placeholder={
          <PostListShimmer
            length={6}
            type={PostTileType.comfortable}
            className="rounded-md bg-card"
          />
        }
        footerElement={
          <>
            {isLoadingMorePosts && (
              <div className="PostList my-2 rounded bg-card p-2">
                <PostListShimmer
                  length={3}
                  type={PostTileType.compact}
                  className="rounded-md bg-card"
                />
              </div>
            )}
            {noMorePosts && (
              <div className="flex h-32 place-content-center items-center text-xs text-secondary-foreground/80">
                <I18>
                  Looks like you have reached the end of the list, No more post
                  to display
                </I18>
              </div>
            )}
          </>
        }
        onPostUpdate={(post, list) => {
          if (post.isPinned) {
            dispatch(updateSectionPinnedPost(post));
          }
          if (list) {
            dispatch(setCurrentSectionPosts(list));
          }
        }}
      />
    </div>
  );
}
