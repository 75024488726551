import { User } from "../../types/user/minimal-user.type";
import { Avatar } from "../../_components/avatar.component";
import UIcon from "../../_components/uicon-component";

interface Props {
  blockedUsersList: Array<User>;
  blockedUsersCount: number;
  blockedUsersListVisibility: boolean;
  onClose: () => void;
  onUnblockUser: (userId: string) => void;
}

const BlockedUsersList = ({
  blockedUsersList,
  blockedUsersCount,
  blockedUsersListVisibility,
  onClose,
  onUnblockUser,
}: Props) => {
  return (
    <div className="absolute left-0 right-0 z-10 mx-auto -mt-12 max-h-[40vh] w-[360px] overflow-y-auto rounded-md bg-background shadow-lg">
      <div className="theme-default-text-color flex justify-between gap-1 p-2 px-5 py-4 text-sm font-extrabold">
        <span
          className={`${blockedUsersListVisibility ? "visible" : "hidden"}`}>
          {`${blockedUsersCount > 0 ? blockedUsersCount : "NO"} BLOCKED ${
            blockedUsersCount > 1 ? "USERS" : "USER"
          }`}
        </span>
        <button onClick={onClose}>
          <UIcon icon="cross" className="cursor-pointer" w-12 />
        </button>
      </div>
      <div className="h-px w-full bg-zinc-800"></div>
      {blockedUsersList.map((user: any) => (
        <div className="flex items-center gap-2 px-4 py-3">
          <div className="flex items-center justify-center">
            <Avatar
              className="cursor-pointer"
              user={user}
              noName={true}
              extraInfo=""
              onClick={(e) => {}}
            />
          </div>
          <div className="flex items-center">
            <p className="theme-default-text-color text-sm font-bold">
              {user.name}
            </p>
          </div>
          <div className="flex flex-1 place-content-end">
            <button
              className="text-alert"
              onClick={() => {
                onUnblockUser(user.id);
              }}>
              Unblock
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlockedUsersList;
