function MessageTileShimmer() {
  return (
    <div className="my-3 flex">
      <p className="h-8 w-8 rounded-full bg-gray-300"></p>
      <div className="ml-2 w-max space-y-1">
        <p className="h-2 w-64 rounded bg-gray-300"></p>
        <div className="mt-2 h-2 w-6/12 rounded bg-gray-300" />
        <div className="mt-2 h-2 w-8/12 rounded bg-gray-300" />
      </div>
    </div>
  );
}

export default MessageTileShimmer;
