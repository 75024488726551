function HeaderShimmer() {
  return (
    <div
      className="Header animate-pulse border-b border-border bg-card py-1"
      style={{ height: "73px" }}>
      <div className="header-container mg:px-6 px-6 pb-2 pt-3 sm:px-0 xl:px-0">
        <div className="flex w-full items-center space-x-2">
          <p className="h-10 w-10 rounded bg-gray-300"></p>
          <p className="h-4 w-12 rounded bg-gray-300"></p>
        </div>

        <div className="OtherOptions flex place-content-end items-center space-x-2">
          <p className="h-6 w-6 rounded bg-gray-300"></p>
          <p className="h-6 w-6 rounded bg-gray-300"></p>
          <p className="h-6 w-6 rounded bg-gray-300"></p>
          <p className="h-10 w-10 rounded-full bg-gray-300"></p>
        </div>
      </div>
    </div>
  );
}

export default HeaderShimmer;
