function SidebarGroupsListShimmerComponent() {
  return (
    <div className="animate-pulse pt-0">
      <GroupTileShimmer />
      <GroupTileShimmer />
      <GroupTileShimmer />
    </div>
  );
}

function GroupTileShimmer() {
  const list = [];
  for (let i = 0; i < 3 ?? 1; i++) {
    list.push(
      <div key={i} className="ml-2 mt-2 h-4 w-5/6 rounded bg-gray-300" />
    );
  }
  return (
    <div className="container mb-2 flex flex-grow animate-pulse flex-col py-2 pt-0">
      <div className="h-6 w-3/6 rounded bg-gray-300" />
      {list}
    </div>
  );
}

export default SidebarGroupsListShimmerComponent;
