import React, { Suspense, useEffect } from "react";
import { Modal } from "../modal.component";
const Picker = React.lazy(() => import("emoji-picker-react"));

export default function EmojiPickerV2({
  setEmoji,
  visible = false,
  setVisible = (e) => {},
}) {
  const [theme, setTheme] = React.useState("light");
  useEffect(() => {
    // get theme
    const theme = localStorage.getItem("pensil.theme");
    setTheme(theme ?? "light");
  }, []);
  return (
    <Modal
      className="picker-container cursor-pointer select-none"
      width="278px"
      padding={false}
      active={visible}
      setActive={(e) => {
        setVisible(false);
      }}>
      <div className="relative">
        {/* <div
            className="text-red-500 p-1 rounded-full bg-background flex w-8 h-8 justify-center items-center cursor-pointer absolute -top-5 -right-5 z-20 hover:shadow"
            onClick={() => {
              setVisible(false);
            }}>
            <UIcon icon="cross" />
          </div> */}
        <Suspense fallback={<></>}>
          <Picker
            onEmojiClick={(emojiObject, event) => {
              setEmoji(emojiObject.emoji);
              setVisible(false);
            }}
            disableAutoFocus={true}
            theme={theme}
            groupNames={{ smileys_people: "PEOPLE" }}
            native
          />
        </Suspense>
      </div>
    </Modal>
  );
}
