import cx from "classnames";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { Remarkable } from "remarkable";
import { history } from "../../_config";
import { createPostDetailPageRouteFromSlug } from "../../_pages/post-detail.page";
import I18 from "../atoms/i18";
import { Avatar } from "../avatar.component";
import { Loader } from "../loader.component";
import { PostSubCommentDocumentPreview } from "../post/comment";

// TODO: Handle Event Type post

const md = new Remarkable();

function ActivityCommentCardComponent({
  post,
  comment,
  reply,
  user,
  group,
  noMargin = false,
  activityType,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [areCommentsLoading, setAreCommentsLoading] = useState(false);

  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);

  const [imageToBePreviewed, setImageToBePreviewed] = useState(null);

  if (!post) return <></>;

  return (
    <div
      id={"PostCard-" + post.id}
      className={cx("PostCard my-3 rounded-2xl bg-card shadow", {
        "my-3": !noMargin,
        "cursor-pointer": comment !== null,
      })}
      onClick={(e) => {
        if (comment !== null) {
          history.push(createPostDetailPageRouteFromSlug(post));
        }
      }}>
      <PostTitle
        post={post}
        comment={comment}
        user={user}
        activityType={activityType}
      />
      {
        comment !== null ? (
          <PostComment
            user={user}
            post={post}
            comment={activityType === "post-comment" ? comment : reply}
            areCommentsLoading={areCommentsLoading}
            setCommentsLoading={setAreCommentsLoading}
            setAreCommentsLoading={setAreCommentsLoading}
            activityType={activityType}
          />
        ) : null
        // <div className="PostComment my-4 pl-4">
        //     <div className="py-1 pl-12 text-sm font-light">
        //         Looks like the resource may have been removed.
        //     </div>
        // </div>
      }
      <PostProcessing isProcessing={isProcessing} />
    </div>
  );
}

/**
 * Post title
 * @param {*} param0
 * @returns
 */
function PostTitle({ post, comment, user, activityType }) {
  let title;
  if (activityType === "post-comment") {
    title = post.title;
  } else {
    title = comment !== null ? comment.description : "";
  }

  if (!title) return <></>;

  return (
    <div className="mx-4">
      <span className="flex cursor-pointer border-b border-border p-2 text-sm focus:outline-none">
        {activityType === "post-comment" ? (
          <span className="line-clamp-1 break-words">
            💬 &nbsp; <I18>Commented on</I18>
            <span className="ml-1 text-sm font-semibold">{title}</span>
          </span>
        ) : (
          <span>
            💬 &nbsp;<I18>Replied to</I18>
            <span
              dangerouslySetInnerHTML={{
                __html: md.render(
                  title && title.length > 50
                    ? title.substring(0, 48) + "..."
                    : title
                ),
              }}
              className="ml-1 line-clamp-1 break-words text-sm font-semibold"></span>
          </span>
        )}
      </span>
    </div>
  );
}

/**
 * Component to show a post comment
 * @param {*} param0
 * @returns
 */
function PostComment({ post, user, comment, postId, activityType }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const sample = moment
    .duration(moment(new Date()).diff(moment(comment.createdAt)))
    .asDays();
  return (
    <>
      <div key={comment.id} className="PostComment my-4 pb-1 pl-4 pt-2">
        <div className="flex items-center justify-between">
          <div className="text-sm">
            <Avatar
              user={comment.createdBy}
              extraInfo={
                sample < 1
                  ? moment(comment.createdAt).fromNow()
                  : moment(comment.createdAt).format("DD MMM YYYY")
              }
              size={36}
              activityAvatar
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="py-1 pl-12 text-sm font-light">
          {/* <NL2BR text={comment.description} /> */}
          <div
            className="NL2BR headings"
            dangerouslySetInnerHTML={{
              __html: md.render(comment.description),
            }}
          />
        </div>
        {/* activity cards with document/image preview */}
        <PostSubCommentDocumentPreview
          reply={comment}
          activityType={activityType}
          isActivity
        />

        {isProcessing ? (
          <div className="processing">
            <Loader />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

/**
 * Show post is processing
 * @param {*} param0
 * @returns
 */
function PostProcessing({ isProcessing }) {
  if (!isProcessing) return <></>;

  return (
    <div className="PostProcessing">
      <Loader />
    </div>
  );
}

const ActivityCommentCard = connect((s) => ({
  user: s.auth,
  group: s.activeGroup,
}))(ActivityCommentCardComponent);

export { ActivityCommentCard };
