import { LiveCall } from "../../types/live-call/live-call.type";
import {
  ADD_LIVE_CALL,
  END_LIVE_CALL,
  SET_LIVE_CALLS,
} from "./live-call.action";

export interface LiveCallState {
  liveCalls: LiveCall[] | null;
}

const INITIAL_STATE: LiveCallState = {
  liveCalls: null,
};

export function liveCallReducer(
  state = INITIAL_STATE,
  action: any
): LiveCallState {
  switch (action.type) {
    case SET_LIVE_CALLS:
      return {
        liveCalls: action.payload,
      };
    case ADD_LIVE_CALL:
      const isExistingCall = state.liveCalls?.some(
        (live: LiveCall) => live.id === action.payload.id
      );
      if (!isExistingCall) {
        return {
          ...state,
          liveCalls: state.liveCalls
            ? [...state.liveCalls, action.payload]
            : [action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case END_LIVE_CALL:
      return {
        ...state,
        liveCalls: state.liveCalls
          ? state.liveCalls.filter(
              (live: LiveCall) => live.id !== action.payload.id
            )
          : state.liveCalls,
      };

    default:
      return state;
  }
}
