// import { DyteMeeting, Meeting } from "dyte-client";
import cx from "classnames";
import { useState } from "react";
import { useAppService } from "../../hooks/use-app-service";
import useModel from "../../hooks/use-model.hook";
import { useToast } from "../../hooks/use-toast.hook";
import TextButton from "../../_components/atoms/text-button";
import Tooltip from "../../_components/atoms/tooltip";
import { Avatar } from "../../_components/avatar.component";
import { GroupMembersComponent } from "../../_components/group/group-members.component";
import AcceptRejectSectionMember from "../../_components/group/members/accept-reject-section.component";
import IconButton2 from "../../_components/icon-button-2-component";
import IconMenu2 from "../../_components/icon-menu-2.component";
import RightSideModal from "../../_components/right-side-modal.component";
import { GroupService } from "../../_service";
import { Fold } from "../../_utils/extensions/typescript-utils";

/**
 * @param {Array<Object>} members - The List of members who are joined to the group.
 * @param {Array<Object>} joinedMembers - The List of members who are joined to the group and activeTab
 * @param {Number} closedSectionMembersCount - Closed section members count
 * @param {boolean} addMembersVisible - If the add members sidebar is visible
 * @param {Function} setAddMembersVisible - Set the add members sidebar visibility
 * @param {Object} user - The Auth user
 * @param {Object} group - The active group object
 * @param {Function} updateGroupMember - Update the group member
 * @param {Function} updateClosedSectionMember - update the closed section member
 * @param {Function} removeGroupMember - Remove the group member
 * @param {Function} removeClosedSectionMember - Remove the closed section member
 * @param {Object} activeTabModel - The active tab model
 * @param {Function} setActiveTabModel - Set the active tab model
 * @param {Number} memberPreviewLimit - The number of members to show in the preview
 */
export default function SidebarGroupMembersCard({
  members = [],
  onLazyLoad = (e) => {},
  onLazyLoadClosedSection = (e) => {},
  addMembersVisible,
  setAddMembersVisible,
  joinedMembers,
  closedSectionMembersCount,
  user,
  group,
  updateGroupMember,
  updateClosedSectionMember,
  removeGroupMember,
  removeClosedSectionMember,
  activeTabModel,
  memberPreviewLimit = 5,
}) {
  const { analyticsService } = useAppService();

  const hasMore = memberPreviewLimit < members.length;
  const [displayMore, setDisplayMore] = useState(false);
  const [isSettingsMenuVisible, setIsSettingsMenuVisible] = useState(false);
  const closedSectionAddIsAccessible =
    activeTabModel &&
    activeTabModel.isClosed &&
    group &&
    group.myRole === "admin";

  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();
  const finalMembers = members?.length > 0 ? members : joinedMembers
  const toast = useToast();
  return finalMembers?.length > 0 ? (
    <div className="mt-1 rounded-xl bg-card p-5">
      <div
        className={cx(
          "flex items-center justify-between rounded border-b border-border pb-5",
          {
            "cursor-pointer": !closedSectionAddIsAccessible,
          }
        )}
        onClick={(e) => {
          if (!user) {
            return;
          }
          if (!closedSectionAddIsAccessible) {
            setAddMembersVisible(true);
          }
          setAddMembersVisible(true);
          analyticsService.track("widget-groupmember", {
            clickOn: "header",
            widgetType: "group-member",
          });
        }}>
        <h4 className="font-bold">
          {closedSectionMembersCount ?? group.userCount} Members
        </h4>
        {closedSectionAddIsAccessible && (
          <Tooltip label="Add members">
            <IconButton2
              icon="user-add"
              size="lg"
              hoverable
              onClick={(e) => {
                setAddMembersVisible(true);
                analyticsService.track("widget-groupmember", {
                  clickOn: "header",
                  widgetType: "group-member",
                });
              }}
            />
          </Tooltip>
        )}
      </div>

      {/* members section */}
      <div className="pt-5">
        {/* members list */}
        <div className="GroupMemberList">
          {joinedMembers.slice(0, memberPreviewLimit).map((member) => (
            <div
              className="group-member-list-item mb-2.5 flex h-10 cursor-pointer items-center justify-between"
              key={member.id}
              onMouseEnter={() => setIsSettingsMenuVisible(true)}
              onMouseLeave={() => setIsSettingsMenuVisible(false)}>
              <Avatar
                user={member}
                extraInfo={member.userId ? "@" + member.userId : ""}
                onClick={() => {
                  // open the user page
                  // window.open(createUserWallPageRoute(member.id, "activity"));
                  setIdToViewProfile(member.id);
                  setShowProfileModel(true);
                  analyticsService.track("widget-groupmember", {
                    clickOn: "user",
                    widgetType: "group-member",
                  });
                }}
                tag={
                  member.groupRole === "admin" ? (
                    <div className="text-xxs mx-2 rounded bg-accent px-1 font-semibold text-primary">
                      Admin
                    </div>
                  ) : member.groupRole === "moderator" ? (
                    <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                      Moderator
                    </div>
                  ) : member.groupRole === "user" ? (
                    <></>
                  ) : (
                    <></>
                  )
                }
              />

              {/* show request to admin */}
              {!activeTabModel || !activeTabModel.isClosed ? (
                user && group.myRole === "admin" ? (
                  <div className="flex items-center">
                    {/* group requests */}
                    {member.groupStatus === "requested" && (
                      <div className="flex items-center">
                        <span
                          onClick={(e) => {
                            // call api to accept user in group
                            GroupService.acceptUserRequest(
                              user,
                              group.id,
                              member.id
                            ).then(({ group }) => {
                              // update the current member
                              updateGroupMember({
                                ...member,
                                groupStatus: "joined",
                              });
                              toast.addToast("User added to group");
                            });
                          }}
                          className="px-1 text-primary hover:scale-105">
                          Accept
                        </span>
                        <span
                          onClick={(e) => {
                            // call api to accept user in group
                            GroupService.rejectJoinRequest(
                              user,
                              group.id,
                              member.id
                            ).then(({ group }) => {
                              // update the current member
                              removeGroupMember(member);
                              toast.addToast(
                                "User's join request removed from the group"
                              );
                            });
                          }}
                          className="px-1 text-alert hover:scale-105">
                          Reject
                        </span>
                      </div>
                    )}
                    {/* group admin operations */}
                    <Fold
                      value={
                        member.groupStatus === "joined" ||
                        ["admin", "moderator"].includes(member.groupRole)
                      }
                      ifPresent={() => (
                        <IconMenu2
                          dropdownClassName="w-56"
                          className={`${!isSettingsMenuVisible && "hidden"}`}
                          actions={[
                            {
                              // change user role
                              icon: "edit",
                              label:
                                member.groupRole === "admin"
                                  ? ""
                                  : member.groupRole === "moderator"
                                    ? "Make User"
                                    : "Make Moderator",
                              onClick: (e, closeMenu) => {
                                // update the user role
                                GroupService.updateUserRole(
                                  user,
                                  group.id,
                                  member.id,
                                  member.groupRole === "moderator"
                                    ? "user"
                                    : "moderator"
                                )
                                  .then(({ group }) => {
                                    // update the current memeber
                                    updateGroupMember({
                                      ...member,
                                      groupRole:
                                        member.groupRole === "moderator"
                                          ? "user"
                                          : "moderator",
                                    });
                                    // update email
                                    closeMenu();
                                  })
                                  .catch((err) => {
                                    console.log({
                                      message:
                                        "Error while updating the group memeber role",
                                      err,
                                    });
                                  });
                              },
                            },
                            {
                              // button to remove group
                              icon: "cross-circle",
                              label:
                                member.groupRole === "admin"
                                  ? ""
                                  : "Remove from group", // cant remove admin
                              onClick: (e) => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to remove the user?"
                                  )
                                ) {
                                  // call user remove api
                                  GroupService.removeUser(
                                    user,
                                    group.id,
                                    member.id
                                  )
                                    .then(() => {
                                      // remove the current memeber
                                      removeGroupMember(member);
                                    })
                                    .catch((err) => {
                                      console.log({ err });
                                    });
                                }
                              },
                              actionType: "alert",
                            },
                          ]}
                          hideOnEmpty
                        />
                      )}
                    />
                  </div>
                ) : (
                  <></>
                )
              ) : (
                activeTabModel &&
                activeTabModel.isClosed &&
                user &&
                group.myRole === "admin" && (
                  <AcceptRejectSectionMember
                    member={member}
                    groupId={group.id}
                    activeTabId={activeTabModel ? activeTabModel.id : null}
                    user={user}
                    updateGroupMember={updateClosedSectionMember}
                  />
                )
              )}
            </div>
          ))}
        </div>
        {/* show all link */}
        {hasMore && user && (
          <div className="text-xxs">
            <TextButton
              label="View all members"
              onClick={(e) => {
                setDisplayMore(true);
                analyticsService.track("widget-groupmember", {
                  widgetType: "group-member",
                  clickOn: "show-all",
                });
              }}
            />
          </div>
        )}
        {/* Display all group members  */}
        <RightSideModal
          active={addMembersVisible}
          width="400px"
          setActive={setAddMembersVisible}>
          <GroupMembersComponent
            user={user}
            group={group}
            displayOnlyJoinedMembers={false}
            activeTabModel={activeTabModel}
            updateGroupMember={updateGroupMember}
            updateClosedSectionMember={updateClosedSectionMember}
            removeGroupMember={removeGroupMember}
            setActive={setAddMembersVisible}
          />
        </RightSideModal>
        {/* Display all group and active tab Joined members  */}
        <RightSideModal
          active={displayMore}
          width="400px"
          setActive={setDisplayMore}>
          <GroupMembersComponent
            user={user}
            group={group}
            displayOnlyJoinedMembers={true}
            memberCount={closedSectionMembersCount}
            activeTabModel={activeTabModel}
            updateGroupMember={updateGroupMember}
            updateClosedSectionMember={updateClosedSectionMember}
            removeGroupMember={removeGroupMember}
            removeClosedSectionMember={removeClosedSectionMember}
            setActive={setDisplayMore}
            onLazyLoad={
              activeTabModel?.isClosed ? onLazyLoadClosedSection : onLazyLoad
            }
          />
        </RightSideModal>
      </div>
    </div>
  ) : null;
}
