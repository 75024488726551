import I18 from "../atoms/i18";
import {
  FacebookIcon,
  LinkedInIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "../atoms/icons";
import Tooltip from "../atoms/tooltip";
import UIcon from "../uicon-component";

interface Props {
  text: string;
  className?: string;
}

/**
 * @description Display a share on social media buttons ex. Facebook, Twitter, LinkedIn, WhatsApp, etc.
 * @property {string} text - Text to share
 */
export default function ShareOnSocialMedia({ text, className }: Props) {
  const socialShare = [
    {
      name: "Twitter",
      icon: TwitterIcon,
      onClick: (e: any) => {
        window.open(`https://twitter.com/intent/tweet?text=${text}`);
      },
    },
    {
      name: "Facebook",
      icon: FacebookIcon,
      onClick: (e: any) => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${text}`);
      },
    },
    {
      name: "LinkedIn",
      icon: LinkedInIcon,
      onClick: (e: any) => {
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${text}`
        );
      },
    },
    {
      name: "WhatsApp",
      icon: WhatsappIcon,
      onClick: (e: any) => {
        window.open(`https://wa.me/?text=${text}`);
      },
    },
    {
      name: "Telegram",
      icon: TelegramIcon,
      onClick: (e: any) => {
        window.open(`https://telegram.me/share/url?url=${text}`);
      },
    },
  ];
  return (
    <div className={`flex flex-col space-y-2 px-4 py-2 ${className}`}>
      <div className="font-semibold text-secondary-foreground/85">
        <I18>Share on social media</I18>
      </div>
      <div className="flex place-content-center items-center gap-4 bg-card px-8 py-2 text-secondary-foreground/80">
        {socialShare.map((social, index) => (
          <Tooltip key={index} label={social.name}>
            <button
              onClick={social.onClick}
              className="rounded-full border border-border p-2 transition-all duration-150 ease-in-expo hover:scale-105 hover:border-secondary-foreground hover:bg-accent hover:fill-current hover:text-secondary-foreground">
              <social.icon className="h-4 w-4" />
            </button>
          </Tooltip>
        ))}
        {/* Share on email */}
        <Tooltip label={"Share on mail"}>
          <button
            onClick={(e) => {
              window.open(`mailto:?body=${text}`);
            }}
            className="rounded-full border border-border p-2 transition-all duration-150 ease-in-expo focus-within:scale-[.95] hover:scale-105 hover:border-secondary-foreground hover:bg-accent hover:fill-current hover:text-secondary-foreground">
            <span className="flex h-4 w-4 items-center justify-center">
              <UIcon icon="envelope" className="h-4 w-4" />
            </span>
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
