import { PostHighlightService } from "../../_service/post-highlight.service";

// actions
export const GET_HIGHLIGHTED_POSTS = "GET_HIGHLIGHTED_POSTS";
export const SET_HIGHLIGHTED_POSTS = "SET_HIGHLIGHTED_POSTS";

export const ADD_HIGHLIGHTED_POST = "ADD_HIGHLIGHTED_POST";
export const ADD_HIGHLIGHTED_POST_SUCCESS = "ADD_HIGHLIGHTED_POST_SUCCESS";
export const REMOVE_HIGHLIGHTED_POST = "REMOVE_HIGHLIGHTED_POST";

export function getHighlightedPostsAction() {
  return {
    type: GET_HIGHLIGHTED_POSTS,
  };
}

export function setHighlightedPosts(posts) {
  return {
    type: SET_HIGHLIGHTED_POSTS,
    posts,
  };
}

export function addHighlightedPostAction(postId) {
  return {
    type: ADD_HIGHLIGHTED_POST,
    postId,
  };
}

export function addHighlightedPostSuccess(postId) {
  return {
    type: ADD_HIGHLIGHTED_POST_SUCCESS,
    postId,
  };
}

export function removeHighlightedPostAction(postId) {
  return {
    type: REMOVE_HIGHLIGHTED_POST,
    postId,
  };
}

// thunk
export function getHighlightedPosts(communityId) {
  return (dispatch) => {
    dispatch(getHighlightedPostsAction());

    PostHighlightService.getHighlightedPostsInCommunity(communityId).then(
      (response) => {
        const { highlightedPosts } = response;
        const { posts } = highlightedPosts;
        dispatch(setHighlightedPosts(posts));
      }
    );
  };
}

export function highlightPost(postId, callback) {
  return (dispatch, getState) => {
    const { community } = getState();

    dispatch(addHighlightedPostAction(postId));
    PostHighlightService.highlightCommunityPost(community?.id, postId).then(
      (response) => {
        const { highlightedPosts } = response;
        const { posts } = highlightedPosts;
        dispatch(setHighlightedPosts(posts));
        dispatch(addHighlightedPostSuccess(postId));
        callback();
      }
    );
  };
}

export function unHighlightPost(postId, callback) {
  return (dispatch, getState) => {
    const { community } = getState();

    dispatch(removeHighlightedPostAction(postId));
    PostHighlightService.unHighlightCommunityPost(community?.id, postId).then(
      (response) => {
        const { highlightedPosts } = response;
        const { posts } = highlightedPosts;
        dispatch(setHighlightedPosts(posts));
        // dispatch(addHighlightedPostSuccess(postId));
        callback();
      }
    );
  };
}

// reducer
const INITIAL_STATE = {
  isLoadingList: false,
  posts: [],
  postIds: [],
  errorMessage: null,
  updatingPostId: null,
};

export function highlightedPostsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_HIGHLIGHTED_POSTS:
      return {
        ...state,
        isLoadingList: true,
        posts: [],
        postIds: [],
        errorMessage: null,
      };
    case SET_HIGHLIGHTED_POSTS:
      return {
        ...state,
        isLoadingList: false,
        posts: action.posts,
        postIds: action.posts?.map((p) => p.postData?.id),
        errorMessage: null,
      };
    case ADD_HIGHLIGHTED_POST:
      return {
        ...state,
        updatingPostId: action.postId,
      };
    case ADD_HIGHLIGHTED_POST_SUCCESS:
      return {
        ...state,
        updatingPostId: null,
      };
    default:
      return state;
  }
}
