import cx from "classnames";
import { twMerge } from "tailwind-merge";
import I18 from "./atoms/i18";
import { Loader } from "./loader.component";

export function cn(...inputs: any[]): string {
  return twMerge(cx(inputs));
}

interface ButtonProps {
  icon?: React.ReactNode;
  label?: string;
  disabled?: boolean;
  isLoading?: boolean;
  outlined?: boolean;
  large?: boolean;
  className?: string;
  isCancelType?: boolean;
  flat?: boolean;
  btnColor?: string;
  type?: "button" | "submit" | "reset";
  onClick?: (value: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function Button({
  icon = null,
  label = "Button",
  disabled = false,
  isLoading = false,
  outlined = false,
  large = false,
  className = "",
  isCancelType = false,
  flat = false,
  btnColor = "",
  type,
  onClick = (value) => {},
}: ButtonProps) {
  let loaderColor = "var(--theme-on-primary-color)";

  let defaultState = "bg-primary border border-primary text-primary-foreground";
  if (flat) {
    defaultState = "border border-border text-primary hover:border-primary";
  }
  if (btnColor === "red") {
    defaultState =
      "bg-alert border-alert text-alert-foreground hover:bg-alert-hover";
  }
  if (isCancelType) {
    defaultState = "bg-card border-border border";
  }

  return (
    <button
      type={type}
      disabled={disabled}
      className={cn(
        cx(
          "word-breaker select-none border border-border font-semibold hover:shadow focus:outline-none",

          {
            "cursor-not-allowed border-primary text-primary hover:shadow-none":
              disabled && outlined,
            "border-primary text-primary": !disabled && outlined,
            "theme-bg-disable cursor-not-allowed text-secondary-foreground/70 hover:shadow-none":
              disabled && !outlined,
            [defaultState]: !disabled && !outlined,
            "rounded px-3 py-1": !large,
            "rounded px-6 py-2": large,
            "px-4 py-2": isCancelType,
            "cursor-progress": isLoading && !disabled,
          }
        ),
        className
      )}
      onClick={(e) => {
        if (isLoading || disabled) {
          return null;
        } else {
          onClick(e);
        }
      }}>
      <span className="flex place-content-center items-center whitespace-normal">
        {isLoading ? (
          <span className="mr-1">
            <Loader spinnerColor={loaderColor} size={20} />
          </span>
        ) : null}

        {icon && <span className="mr-2">{icon}</span>}
        <span>
          <I18>{label}</I18>
        </span>
      </span>
    </button>
  );
}

export { Button };
