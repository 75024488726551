import { useEffect, useState } from "react";
import I18 from "../../atoms/i18";
import { Avatar } from "../../avatar.component";

function AttendeeSection({ attendeeList = [], eventToUpdate = {} }) {
  const [subscriberList, setSubscriberList] = useState([]);

  useEffect(() => {
    setSubscriberList(attendeeList);
  }, []);

  return (
    <div className="w-full cursor-pointer overflow-hidden">
      <div className="flex justify-between px-3 py-1">
        <div className="pl-2 font-semibold">
          {eventToUpdate !== {} ? eventToUpdate.participantCount : 0}{" "}
          <I18>Attendees</I18>
        </div>
        {/* <div className="font-bold text-primary">Download CSV</div> */}
      </div>
      <div className="mt-2 flex justify-between rounded-t-md bg-card px-4 py-3">
        <div className="font-semibold">Member Details</div>
        <div className="font-semibold">RSVP</div>
      </div>
      <div className="h-80 overflow-scroll">
        {subscriberList.map((user, index) => {
          let status = "";
          switch (true) {
            case user.status === "going":
              status = "Yes";
              break;
            case user.status === "invited":
              status = "Invited";
              return;
            case user.status === "not-going":
              status = "No";
              break;
            case user.status === "maybe":
              status = "Maybe";
              break;
            default:
              break;
          }
          return (
            <div
              key={index}
              className="flex justify-between border border-border px-3 py-3">
              <div className="flex">
                <Avatar user={user.user} noName />
                <div className="">
                  <div className="flex items-center">
                    <div className="text-xs font-semibold">
                      {user.user.name}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="text-xs font-semibold">
                      {user.user.email ? user.user.email : ""}
                    </div>
                    {user.user.email && user.user.mobile ? (
                      <div className="px-2 font-semibold">|</div>
                    ) : null}
                    <div className="text-xs font-semibold">
                      {user.user.mobile ? user.user.mobile : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="font-semibold">{<I18>{status}</I18>}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AttendeeSection;
