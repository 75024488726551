import moment from "moment";
import { ListView } from "../molecule/listview.component";
import { MessageCard } from "./message-card.component";

export function MessageListList({
  messages,
  setMessages,
  updateMessage,
  deleteMessage,
  highlightsPinned,
  hideUserDetails = false,
  group,
  activeTabModel,
  isUserAllowedToPost = false,
}) {
  return (
    <div className="flex w-full flex-1 flex-col justify-end pb-4">
      <ListView
        items={messages}
        renderItem={(message, index) => (
          <MessageCard
            highlightsPinned={highlightsPinned}
            message={message}
            key={index}
            updateMessage={updateMessage}
            setMessages={(message) => {
              // get post index
              const messageIndex = messages.findIndex(
                (p) => p.id === message.id
              );
              const oldMessages = [...messages];
              oldMessages.splice(messageIndex, 1);
              setMessages(oldMessages);
            }}
            deleteMessage={deleteMessage}
            hideUserDetails={
              index > 0 &&
              messages[index - 1].createdBy.id === message.createdBy.id &&
              moment(message.createdAt).diff(
                messages[index - 1].createdAt,
                "minutes"
              ) < 1
            }
            nextMessageIsBySameUser={
              index < messages.length - 1 &&
              messages[index + 1].createdBy.id === message.createdBy.id
            }
            isOnSameDay={
              (index > 0 &&
                moment(messages[index - 1].createdAt).isSame(
                  message.createdAt,
                  "day"
                )) ||
              hideUserDetails
            }
            isNotContinued={
              index === 0 ||
              moment(messages[index - 1].createdAt)
                .add(1, "minute")
                .isBefore(moment(message.createdAt))
            }
            group={group}
            activeTabModel={activeTabModel}
            isUserAllowedToPost={isUserAllowedToPost}
          />
        )}
      />
    </div>
  );
}
