import cx from "classnames";
import React, { useEffect, useState } from "react";
import useInView from "../../hooks/use-in-view";
import { LinkMeta, useMeta } from "../../hooks/use-meta";
import { Fold } from "../../_utils/extensions/typescript-utils";
import Validator from "../../_utils/validator";
import LazyImage from "../atoms/lazy-image";

interface Props {
  url: string;
  type?: "small" | "large";
  className?: string;
}

/**
 * Component to display a link preview card. it uses the the useMeta hook to fetch the link preview data
 * @param {string} url - The url to preview
 */

export const LinkMetaCard: React.FC<Props> = ({
  url,
  type = "small",
  className,
}) => {
  const [map, setMap] = useState({} as LinkMeta);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [siteName, setSiteName] = useState("");
  const [description, setDescription] = useState("");

  const divRef = React.useRef<HTMLDivElement>(null);
  const isInView = useInView(divRef);
  const { getMetaMap } = useMeta();

  const isValidMeta =
    Validator.hasValue(map) &&
    Validator.hasValue(image) &&
    Validator.hasValue(title) &&
    Validator.hasValue(description);

  useEffect(() => {
    if (!url || !isInView) return;

    getMetaMap(url)?.then((data: LinkMeta | undefined) => {
      if (!data) return;
      setMap(data);
      if (data["og:image"]) {
        setImage(data["og:image"]);
      }
      if (data["og:title"]) {
        setTitle(data["og:title"]);
      } else if (data["title"]) {
        setTitle(data["title"]);
      }
      if (data["og:description"]) {
        setDescription(data["og:description"]);
      }
      if (data["og:site_name"]) {
        setSiteName(data["og:site_name"]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, isInView]);

  return (
    <div ref={divRef}>
      <Fold
        value={isValidMeta}
        ifPresent={(value: unknown) => (
          <div
            className={cx(
              "cursor-pointer overflow-hidden border border-border",
              className,
              {
                flex: type === "small",
              }
            )}
            onClick={() => {
              window.open(url, "_blank");
            }}>
            {image && (
              <LazyImage
                src={image}
                alt={"Preview"}
                className={cx(
                  "w-full border-b border-border object-cover blur-0",
                  {
                    "max-w-[150px]": type === "small",
                    "h-[250px]": type === "large",
                  }
                )}
              />
            )}
            <div className="grid flex-grow gap-1 bg-background p-3">
              {siteName && (
                <p className="text-[0.8rem] uppercase text-secondary-foreground">
                  {siteName}
                </p>
              )}
              {title && (
                <h3 className="text-secondary-foreground/85 truncate font-semibold">
                  {title}
                </h3>
              )}
              {description && (
                <p className="line-clamp-2 text-sm text-secondary-foreground/80">
                  {description.substring(0, 100)}
                </p>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};
