import { useContext, useEffect } from "react";
import { BaseConfigContext } from "../contexts/base-config.context";

export function AppLayoutWrapper({
  children,
  layout,
}: {
  children: React.ReactNode;
  layout: string;
}) {
  const { baseColumnClass, setAppLayout } = useContext(BaseConfigContext);
  useEffect(() => {
    setAppLayout(layout);
  }, [baseColumnClass, layout, setAppLayout]);

  return <div id="AppLayoutWrapper">{children}</div>;
}
