import cx from "classnames";
import { isBefore } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Remarkable } from "remarkable";
import { ToastTypes } from "../../contexts/toastr.context";
import { useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import useModel from "../../hooks/use-model.hook";
import { useToast } from "../../hooks/use-toast.hook";
import { history } from "../../_config";
import { redirectToAuthUrl } from "../../_config/helper.config";
import { createMeetingLinkWithToken } from "../../_pages";
import {
  createEventDetailSlugPageRoute,
  formatEventTime,
} from "../../_pages/event-detail.page";
import { createPostDetailPageRouteFromSlug } from "../../_pages/post-detail.page";
import { showTagPageRoute } from "../../_pages/tag-details.page";
import { PostService } from "../../_service";
import {
  highlightPost,
  unHighlightPost,
} from "../../_store/highlighted-posts.store";
import {
  addSectionPinnedPost,
  removeSectionPinnedPosts,
} from "../../_store/sections.store";
import { shortenLink } from "../../_utils/functions";
import MainUtil from "../../_utils/main.util";
import Validator from "../../_utils/validator";
import { ActionModal } from "../action-modal.component";
import I18 from "../atoms/i18";
import { ThumbUpIcon } from "../atoms/icons/thumb-up.icon";
import LazyImage from "../atoms/lazy-image";
import TextButton from "../atoms/text-button";
import { Avatar } from "../avatar.component";
import { Button } from "../button.component";
import EmbedLinkModal from "../embed-link-modal.component";
import ErrorBoundary from "../error-boundry.component";
import IconButton from "../icon-button.component";
import IconMenu2 from "../icon-menu-2.component";
import { Loader } from "../loader.component";
import { NL2BR } from "../nl2br.component";
import RImage from "../responsive-image-holder.component";
import RightSideModal from "../right-side-modal.component";
import { SharePostModal } from "../share-post-modal.component";
import UIcon from "../uicon-component";
import { PostComment } from "./comment";
import CreateComment from "./comment/create-comment";
import PollResult from "./components/poll-result";
import { PollView } from "./components/poll/poll-view";
import PostHeader from "./components/post-header.component";
import PostMenuActions from "./components/post-menu-actions.component";
import { PostQuestionView } from "./components/question/post-question-view";
import { LinkMetaCard } from "./link-meta-card";
import PostLikes from "./post-likes.component";

export function PostCard({
  post,
  updatePost = (post) => {},
  deletePost = (post) => {},
  onTopicSelect = (topic) => {},
  noMargin = false,
  showFullPost = false,
  isCommunityPost = false,
  displayPollResult = false,
}) {
  const isSharedPost = post.sharedId !== undefined;
  const [isProcessing, setIsProcessing] = useState(false);
  const { addToast } = useToast();
  const { user, group } = useAppSelector((s) => ({
    user: s.auth,
    group: s.activeGroup,
  }));
  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();

  function deletePostHandler() {
    setIsProcessing(true);
    PostService.deletePost(user, post.id)
      .then(() => {
        addToast("Post deleted");
        deletePost(post);
      })
      .catch((error) => {
        console.log(error);
        addToast(error.message, ToastTypes.error);
        if (MainUtil.checkIfBlocked(error)) {
          console.log("[Block] Unauthorize error ");
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }
  return (
    <ErrorBoundary>
      <div
        className={cx("PostCard mb-3", {
          shadow: isSharedPost,
          "mb-3": !isSharedPost,
        })}>
        {isSharedPost && (
          // Shared post info
          <>
            <div className="mt-2 flex cursor-pointer items-center justify-between px-2 pb-1 pt-2">
              <div className="flex">
                <Avatar
                  user={post.createdBy}
                  size={20}
                  bold
                  onClick={(e) => {
                    // history.push(createUserWallPageRoute(post.createdBy.id));
                    setShowProfileModel(true);
                    setIdToViewProfile(post.createdBy.id);
                  }}
                />{" "}
                <span>&nbsp; shared</span>
              </div>
              {post.canDelete && (
                <button
                  onClick={deletePostHandler}
                  className="text-xs text-alert">
                  <I18>Delete</I18>
                </button>
              )}
            </div>
            <PostProcessing isProcessing={isProcessing} />
          </>
        )}
        {/* Post */}
        <PostBody
          post={post} // If post is shared then show original shared post in post card
          user={user}
          group={group}
          onTopicSelect={onTopicSelect}
          deletePostHandler={deletePostHandler}
          updatePost={(newPost) => {
            if (post.sharedId === newPost.id) {
              updatePost({ ...post, shared: newPost });
            } else {
              updatePost(newPost);
            }
          }}
          deletePost={(deletedPost) => {
            // If deleted post is original shared post
            if (post.sharedId === deletedPost.id) {
              updatePost({ ...post, shared: null });
            } else {
              // If deleted post is shared post
              deletePost(post);
            }

            addToast("Post deleted");
          }}
          noMargin={noMargin}
          showFullPost={showFullPost}
          isCommunityPost={isCommunityPost}
          addToast={addToast}
          isSharedPost={isSharedPost}
          displayPollResult={displayPollResult}
        />
      </div>
    </ErrorBoundary>
  );
}

function PostBody({
  post,
  user,
  group,
  updatePost = (post) => {},
  deletePost = (post) => {},
  onTopicSelect = (topic) => {},
  noMargin = false,
  showFullPost = false,
  isCommunityPost = false,
  addToast,
  deletePostHandler,
  isSharedPost = false,
  displayPollResult,
}) {
  const { hideComment } = post.preferences ?? {};

  const imageFormat = ["png", "jpg", "jpeg", "gif"];

  const [isProcessing, setIsProcessing] = useState(false);
  const [areCommentsLoading, setAreCommentsLoading] = useState(false);
  const [displayCreateCommentField, setDisplayCreateCommentField] =
    useState(false);

  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);

  const [imageToBePreviewed, setImageToBePreviewed] = useState(null);

  useEffect(() => {
    const cb = (e) => {
      setImageToBePreviewed(e.target.src);
    };
    document
      .querySelectorAll(`#PostCard-${post.id} .PostImages img`)
      .forEach((img) => {
        img.addEventListener("click", cb);
      });

    document
      .querySelectorAll(`#PostCard-${post.id} .PostDescription img`)
      .forEach((img) => {
        img.addEventListener("click", cb);
      });
    return () => {
      document
        .querySelectorAll(`#PostCard-${post.id} .PostImages img`)
        .forEach((img) => {
          img.removeEventListener("click", cb);
        });

      document
        .querySelectorAll(`#PostCard-${post.id} .PostDescription img`)
        .forEach((img) => {
          img.removeEventListener("click", cb);
        });
    };
  }, [post?.id]);

  if (post === null || (isSharedPost && post.shared === null)) {
    return (
      <div className="theme-bg-disable mt-2 rounded border p-4 text-xs text-secondary-foreground/70">
        <I18>Post not available. It may have been deleted.</I18>
      </div>
    );
  }
  const postImage = post?.banner ?? post?.shared?.banner;
  return (
    <div
      id={"PostCard-" + post.id}
      className={cx("PostCard rounded-xl bg-card", {
        "sm:my-3": !noMargin && !isSharedPost,
        shadow: !isSharedPost,
        // Add top padding if no cover picture is available and post author profile picture is not available
        "pt-[1px]": !post.banner && !post?.preferences?.hideCreator,
      })}>
      <div className="PostImages">
        <PostImage
          image={postImage}
          className="w-full rounded-t-xl object-contain object-center"
          wrapperClassName=""
        />
      </div>
      <div className="px-7 pt-7">
        {imageToBePreviewed &&
        imageFormat.includes(imageToBePreviewed.split(".").pop()) ? (
          <div
            className="PostImageFullPreview"
            onClick={(e) => {
              setImageToBePreviewed(null);
            }}>
            <div className="holder">
              <LazyImage src={imageToBePreviewed} alt="Preview" />
              <IconButton
                icon="plus"
                rotate={45}
                className="close-button"
                onClick={(e) => {
                  setImageToBePreviewed(null);
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {(isSharedPost && post.shared.event) || post.event ? (
          <EventInfo
            event={isSharedPost ? post.shared.event : post.event}
            user={user}
            deletePostHandler={deletePostHandler}
            setIsProcessing={setIsProcessing}
            post={post}
            addToast={addToast}
            group={group}
            updatePost={updatePost}
          />
        ) : (
          <>
            <PostHeader
              post={post}
              user={user}
              group={group}
              addToast={addToast}
              updatePost={updatePost}
              deletePost={deletePost}
              setEmbedModalVisible={setEmbedModalVisible}
              isCommunityPost={isCommunityPost}
              setIsProcessing={setIsProcessing}
            />
            <PostTitle
              post={post}
              user={user}
              group={group}
              addToast={addToast}
              updatePost={updatePost}
              deletePost={deletePost}
              setEmbedModalVisible={setEmbedModalVisible}
              isCommunityPost={isCommunityPost}
              setIsProcessing={setIsProcessing}
            />
            <PostImages post={post} />
            <PostVideo post={post} />
            {post.liveCall || (isSharedPost && post.shared.liveCall) ? (
              <PostLiveDescription
                post={post}
                user={user}
                addToast={addToast}
              />
            ) : (
              ""
            )}
            <PostCustomButtons post={post} show="top" />
            <PostDescription
              post={post}
              user={user}
              showFullPost={showFullPost}
              addToast={addToast}
            />
            <PostTopics post={post} onTopicSelect={onTopicSelect} />
            <PostCustomButtons post={post} show="bottom" />
            <PostTags post={post} user={user} />
            <PostQuiz post={post} />
            <PostQuestionView post={post} user={user} updatePost={updatePost} />
            <PollView
              post={post}
              user={user}
              updatePost={updatePost}
              displayPollResult={displayPollResult}
            />
            <PostDocument post={post} />
            <PostRecordings post={post} />
            <PostEmbedCodeViewer post={post} />
          </>
        )}
        <PostLinkMetaCard post={post} />
        <PostLikesPreview
          post={post}
          areCommentsLoading={areCommentsLoading}
          setCommentsLoading={setAreCommentsLoading}
          setDisplayCreateCommentField={setDisplayCreateCommentField}
          updatePost={updatePost}
        />
        <PostActions
          post={post}
          updatePost={updatePost}
          areCommentsLoading={areCommentsLoading}
          setCommentsLoading={setAreCommentsLoading}
          setDisplayCreateCommentField={setDisplayCreateCommentField}
        />
        {!hideComment && (displayCreateCommentField || showFullPost) && (
          <CreateComment post={post} user={user} updatePost={updatePost} />
        )}

        <PostComments
          user={user}
          post={post}
          group={group}
          updatePost={updatePost}
          areCommentsLoading={areCommentsLoading}
          setCommentsLoading={setAreCommentsLoading}
          addToast={addToast}
        />
        <PostProcessing isProcessing={isProcessing} />
        {/* embed modal */}
        <EmbedLinkModal
          link={
            process.env.REACT_APP_EMBED_URL +
            (window.location.port ? ":" + window.location.port : "") +
            "/post/" +
            post.id
          }
          active={isEmbedModalVisible}
          setActive={setEmbedModalVisible}
        />
      </div>
    </div>
  );
}

/**
 * Show event infp
 * @param {*} event
 * @returns
 */
const EventInfo = connect((s) => ({
  community: s.community,
  highlightedPostIds: s.highlightedPosts.postIds,
}))(({
  event,
  user,
  community,
  highlightedPostIds,
  deletePostHandler,
  setIsProcessing,
  post,
  addToast,
  group,
  updatePost,
}) => {
  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();
  if (event == null) {
    return null;
  }
  const dispatch = useDispatch();
  const isHighlighted = highlightedPostIds?.includes(post.id);

  const sample = moment
    .duration(moment(new Date()).diff(moment(event.createdAt)))
    .asDays();

  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <div>
      {/* event banner */}
      {event.banner && (
        <div className="theme-bg-disable eventBannerImage relative flex place-content-center">
          <div className="absolute left-0 top-0 h-full w-full">
            <img
              src={event.banner}
              className="absolute left-0 top-0 h-full w-full object-cover"
              alt=""
              loading="lazy"
              // style={{maxWidth:"100%", maxHeight:"56.49%", height:'auto',width:'auto'}}
            />
          </div>
        </div>
      )}
      {/* Post Header */}
      <div className="PostHeader mt-2 flex items-center justify-between">
        <Avatar
          user={event.createdBy}
          extraInfo={
            sample < 1
              ? moment(event.createdAt).fromNow()
              : moment(event.createdAt).format("DD MMM YYYY")
          }
          size={42}
          className="cursor-pointer"
          tag={
            event.createdBy.groupRole === "admin" ? (
              <div className="text-xxs mx-2 rounded bg-primary-foreground px-1 font-semibold text-primary">
                <I18>Admin</I18>
              </div>
            ) : event.createdBy.groupRole === "moderator" ? (
              <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                <I18>Moderator</I18>
              </div>
            ) : event.createdBy.groupRole === "user" ? (
              <></>
            ) : (
              <></>
            )
          }
          onClick={(e) => {
            // open the user page
            // history.push(createUserWallPageRoute(event.createdBy.id, ""));
            setShowProfileModel(true);
            setIdToViewProfile(event.createdBy.id);
          }}
        />
        <div className="flex items-center">
          {post?.isPinned ? (
            <IconButton
              icon="img"
              img="/assets/vectors/pin-light-icon.svg"
              small
              className="h-8 w-8 bg-primary"
              onClick={(e) => {
                // check if admin or moderator
                if (group && ["admin", "moderator"].includes(group.myRole)) {
                  // unpin the post
                  setIsProcessing(true);
                  PostService.unpinPost(user, post.id)
                    .then(({ post }) => {
                      setIsProcessing(false);
                      updatePost(post);
                      dispatch(removeSectionPinnedPosts(post));
                    })
                    .catch((err) => {
                      setIsProcessing(false);
                      console.log({ err });
                    });
                }
              }}
            />
          ) : (
            <></>
          )}
          {/* show more options only if user logged in */}
          {user ? (
            <>
              {/* more options */}
              <IconMenu2
                dropdownClassName={
                  event.isBookmarkedByMe || isHighlighted ? "w-56" : "w-48"
                }
                icon="menu-dots-vertical"
                hideOnEmpty
                actions={[
                  {
                    // Toggle bookmark
                    icon: "",
                    solidIcon: event.isBookmarkedByMe,
                    label: "Go to event",
                    onClick: () => {
                      history.push(createEventDetailSlugPageRoute(event));
                    },
                  },
                  {
                    // show remove highlight button
                    icon: "",
                    solidIcon: isHighlighted,
                    label:
                      community?.myRole === "admin"
                        ? isHighlighted
                          ? "Remove from featured post"
                          : "Add to featured post"
                        : "",
                    onClick: (e) => {
                      // unpin the post
                      setIsProcessing(true);
                      if (isHighlighted) {
                        // remove highlight
                        dispatch(
                          unHighlightPost(post.id, (error) => {
                            if (!error) {
                              setIsProcessing(false);
                              addToast("Post removed from featured!");
                            }
                          })
                        );
                      } else {
                        // add highlight
                        dispatch(
                          highlightPost(post.id, (error) => {
                            if (!error) {
                              setIsProcessing(false);
                              addToast("Post set as featured!");
                            }
                          })
                        );
                      }
                    },
                  },
                  {
                    // show remove pin button
                    icon: "",
                    solidIcon: post.isPinned,
                    label:
                      group && ["admin", "moderator"].includes(group.myRole)
                        ? post.isPinned
                          ? "Remove Pin"
                          : "Pin Post"
                        : "",
                    onClick: (e) => {
                      console.log(post);
                      // unpin the post
                      setIsProcessing(true);
                      (post.isPinned
                        ? PostService.unpinPost(user, post.id)
                        : PostService.pinPost(user, post.id)
                      )
                        .then(({ post }) => {
                          setIsProcessing(false);
                          if (post.isPinned) {
                            dispatch(addSectionPinnedPost(post));
                          } else {
                            dispatch(removeSectionPinnedPosts(post));
                          }
                          updatePost(post);
                        })
                        .catch((err) => {
                          setIsProcessing(false);
                          console.log({ err });
                        });
                    },
                  },
                  {
                    // Toggle bookmark
                    icon: "",
                    solidIcon: event.isBookmarkedByMe,
                    label: <div className="text-alert">Remove</div>,
                    onClick: () => {
                      setDeleteModal(true);
                    },
                  },
                ]}
              />
              <ActionModal
                active={deleteModal}
                onSubmit={deletePostHandler}
                setActive={setDeleteModal}
                header="Remove event"
                title="Are you sure you want to remove event from here ?"
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* event title */}
      <div
        className="PostTitle my-4 w-full cursor-pointer break-words text-xl font-semibold focus:outline-none"
        onClick={(e) => {
          history.push(createEventDetailSlugPageRoute(event));
        }}>
        {event.title ? event.title : ""}
      </div>
      {/* event time */}
      <div className="flex">
        <i className="fi fi-rr-calendar pr-2 text-sm font-semibold" />
        <div className="font-semibold">
          {formatEventTime(event.startTime, event.endTime)}
        </div>
      </div>
      {/* Timezone */}
      {community && community.timezone && (
        <div className="py-2 text-xs text-secondary-foreground/85">
          <span className="font-semibold">
            <I18>Timezone</I18>:&nbsp;
          </span>
          <span>{community.timezone}</span>
        </div>
      )}
      <PostDescription post={event} user={user} isEvent={true} />
      {/* Event badge */}
      <div className="mb-2 mt-4 flex flex-row items-center">
        {user ? (
          <Link
            className="flex justify-center rounded bg-primary px-4 py-2"
            to={user ? createEventDetailSlugPageRoute(event) : undefined}>
            <p className="text-sm text-primary-foreground">
              <I18>View this event</I18>
            </p>
          </Link>
        ) : (
          <span
            className="flex h-4 w-14 justify-center space-x-1 rounded bg-indigo-400 px-2 text-xs"
            to={user ? createEventDetailSlugPageRoute(event) : undefined}>
            <img
              className="inline"
              src="/assets/vectors/calender-outlined-icon.svg"
              alt="event"
              loading="lazy"
            />
            <p className="text-xs text-primary-foreground">
              <I18>Event</I18>
            </p>
          </span>
        )}
      </div>
      <PostRecordings post={{ event }} />
    </div>
  );
});

// Post live description
function PostLiveDescription({ post, user, addToast }) {
  const isSharedPost = Validator.hasValue(post.shared);
  const { liveCall, scheduledTime } = isSharedPost ? post.shared : post;
  const isMeetingScheduled = scheduledTime;
  const isScheduleMeetingStarted = isMeetingScheduled
    ? isBefore(new Date(scheduledTime), Date.now())
    : false;

  const joinScheduledMeeting = (e) => {
    // prevent default action
    e.preventDefault();
    // open the meeting page with user token
    if (user) {
      // check if scheduled time is passed
      if (isScheduleMeetingStarted) {
        window.open(createMeetingLinkWithToken(liveCall.meetingId, user.token));
      } else {
        addToast(
          "You can join the meeting after scheduled time",
          "",
          ToastTypes.info
        );
      }
    }
  };

  return liveCall.isLive && !liveCall.isEnded ? (
    <>
      {isMeetingScheduled && !liveCall.isLive && !liveCall.isEnded ? (
        <div className="mb-5">
          {moment(scheduledTime).format("ddd D, MMM Y [at] h:mm a")}
          <div className="my-2">
            {/* <a
              href={
                user ? createMeetingLink(liveCall.meetingId, user.token) : null
              }
              target="_blank"
              onClick={joinScheduledMeeting}
              rel="noreferrer">
              {isScheduleMeetingStarted && user && (
                <img
                  src={liveThumbnail}
                  alt="Going Live"
                  width="100%"
                  loading="lazy"
                />
              )}
            </a> */}
            <Button
              label="Join Meeting"
              className="mt-2"
              onClick={() => {
                let link = user
                  ? createMeetingLinkWithToken(liveCall.meetingId, user.token)
                  : null;
                if (link) {
                  window.open(link);
                }
              }}
            />
          </div>
          {/* <div className="mb-3">
            {post.description &&
            post.description.startsWith("I am going live: https://api")
              ? null
              : post.description}
          </div> */}
        </div>
      ) : (
        <div className="PostDescriptionLive my-1 flex flex-col items-start space-y-2">
          {/* <a
            href={
              user ? createMeetingLink(liveCall.meetingId, user.token) : null
            }
            target="_blank"
            onClick={(e) => {
              // prevent default action
              e.preventDefault();
              // open the meeting page with user token
              if (user) {
                window.open(
                  createMeetingLinkWithToken(liveCall.meetingId, user.token)
                );
              }
            }}
            rel="noreferrer">
            <img src={liveThumbnail} alt="Going Live" width="100%" />
          </a> */}
          <Button
            label="Join Meeting"
            onClick={() => {
              let link = user
                ? createMeetingLinkWithToken(liveCall.meetingId, user.token)
                : null;
              if (link) {
                window.open(link);
              }
            }}
          />
        </div>
      )}
    </>
  ) : (
    <div className="PostDescription my-4 rounded bg-background p-4 text-secondary-foreground/70">
      <I18>The live meeting has ended</I18>
    </div>
  );
}

/**
 * Post title
 * @param {*} param0
 * @returns
 */
function PostTitle({
  post,
  user,
  addToast,
  deletePost,
  group,
  setEmbedModalVisible,
  setIsProcessing,
  updatePost,
}) {
  const isSharedPost = Validator.hasValue(post.shared);
  let { title } = isSharedPost ? post.shared : post;
  // const { preferences } = isSharedPost ? post : post.shared;
  const { hideCreator } = post.preferences ?? {};
  const { analyticsService } = useAppService();

  return (
    <div className="flex items-start justify-between">
      {title && (
        <h1
          className="my-2 cursor-pointer scroll-m-20 text-base font-semibold tracking-tight text-secondary-foreground focus:outline-none"
          onClick={(e) => {
            history.push(
              createPostDetailPageRouteFromSlug(
                isSharedPost ? post.shared : post
              )
            );
            //mix panel event
            analyticsService.track("post-clicked", {
              groupName: post?.group.name,
              sectionName: post?.tab.name,
              postUrl:
                window.location.origin +
                createPostDetailPageRouteFromSlug(post),
            });
          }}>
          {title}
        </h1>
      )}
      {/* Hide Post actions of post Author profile picture is hidden */}
      {hideCreator && (
        <PostMenuActions
          addToast={addToast}
          deletePost={deletePost}
          group={group}
          post={post}
          setEmbedModalVisible={setEmbedModalVisible}
          setIsProcessing={setIsProcessing}
          updatePost={updatePost}
          user={user}
        />
      )}
    </div>
  );
}

/**
 * Post description
 * @param {*} param0
 * @returns
 */
export function PostDescription({
  post,
  user,
  addToast = (e) => {},
  showFullPost,
  isEvent = false,
}) {
  const isSharedPost = Validator.hasValue(post.shared);

  const [fullView, setFullView] = useState(false);

  let { description } = isSharedPost ? post.shared : post ?? "";

  const hasMore = description.split("\n").length > 9 ? true : false;

  const md = new Remarkable();
  // convert all available [Youtube url](Youtube url) to plain urls
  const plainText = convertYouTubeLinksToPlainText(description);
  const renderedDescription = md.render(plainText);

  let descriptionWithMentions = renderedDescription;
  try {
    descriptionWithMentions = renderedDescription.replace(
      /\B\@([\w\-]+)/gim,
      (match) =>
        `<span class="text-primary cursor-pointer bg-primary-foreground">${match}</span>`
    );
  } catch (error) {}

  // Search for anchor tags using regex and add target="_blank" to them
  const descriptionWithLinks = descriptionWithMentions.replace(
    /<a href/gim, // regex to find anchor tags
    '<a target="_blank" href' // replace with anchor tag with target="_blank"
  );

  const descriptionWithEmbeds =
    getVideoEmbedFromDescription(descriptionWithLinks);

  const postDomId = post.id ? "PostDescription_" + post.id : null;

  /**
   * Enable copy button on pre code parent
   */
  useEffect(() => {
    if (!Validator.hasValue(description)) {
      return;
    }
    try {
      if (postDomId) {
        const codeElements = document.querySelectorAll(
          `#${postDomId} pre>code`
        );
        codeElements.forEach((codeElement) => {
          const preElement = codeElement.parentElement;
          preElement.style.position = "relative";
          const copyButtonElement = document.createElement("span");
          copyButtonElement.style.position = "absolute";
          copyButtonElement.style.top = "0px";
          copyButtonElement.style.right = "5px";
          copyButtonElement.style.cursor = "pointer";
          copyButtonElement.innerText = "Copy";
          copyButtonElement.onclick = async () => {
            try {
              await navigator.clipboard.writeText(codeElement.innerText);
              return;
            } catch (error) {
              console.error("Cannot copy to clipboard, trying legacy");
            }
            const copyElem = document.createElement("textarea");
            copyElem.value = codeElement.innerText;
            document.body.appendChild(copyElem);
            copyElem.select();
            document.execCommand("copy");
            document.body.removeChild(copyElem);
            addToast("Copied to clipboard");
          };
          preElement.appendChild(copyButtonElement);
        });
      }
    } catch (error) {
      console.error("PostDescription", error);
    }
  }, [addToast, description, descriptionWithEmbeds, postDomId]);

  if (!description) return <></>;

  return (
    <div
      id={postDomId}
      className={cx("PostDescription text-secondary-foreground", {
        "has-more": hasMore && !fullView && !showFullPost,
        "mb-4": !isEvent,
      })}>
      <div
        className="NL2BR headings text-xs"
        dangerouslySetInnerHTML={{ __html: descriptionWithEmbeds }}></div>
      {hasMore && !fullView && !showFullPost ? (
        // Display read more button if showFullPost is false
        <span
          onClick={() => {
            setFullView(true);
          }}
          className="read-more text-primary-default text-xs font-bold uppercase">
          <I18>read more...</I18>
        </span>
      ) : hasMore && fullView && !showFullPost ? (
        // Display read less button if showFullPost is false and fullView is true
        <span
          onClick={() => {
            setFullView(false);
          }}
          className="read-more text-primary-default cursor-pointer text-sm font-bold uppercase">
          <I18>show less</I18>
        </span>
      ) : (
        <></>
      )}
      {/* <PostYoutubeEmbedFromDescription description={post.description} /> */}
      {/* <PostVimeoEmbedFromDescription description={post.description} /> */}
    </div>
  );
}

export function PostTopics({ post, onTopicSelect = (topic) => {} }) {
  return (
    <div className="flex h-fit w-full flex-wrap items-center p-0.5">
      {post?.topics?.map((topic) => {
        return (
          <button
            key={topic.id}
            onClick={(e) => {
              e.stopPropagation();
              onTopicSelect(topic);
            }}
            className="my-1 mr-1 flex justify-center rounded-2xl px-4 py-0.5 hover:cursor-pointer"
            style={{ backgroundColor: topic.bgColor, color: topic.textColor }}>
            <span>{topic.name}</span>
          </button>
        );
      })}
    </div>
  );
}

/**
 * Post link meta card
 * @param {*} post
 * @returns
 */
export function PostLinkMetaCard({ post }) {
  const isSharedPost = Validator.hasValue(post.shared);
  const description = isSharedPost ? post.shared.description : post.description;

  // Check if the url is a web youtube link or mobile youtube link or any youtube playlist link
  const isYoutube = (url) => {
    const regex = new RegExp(
      "^(https?://)?(www.youtube.com|youtu.?be)(?!/shorts|/channel)/.+$"
    );
    return regex.test(url);
  };

  if (!Validator.hasValue(description)) {
    return <></>;
  }

  // Extract links from description using regex
  const links = description.match(/\[(.*?)\]\((.*?)\)/gim);

  if (!Validator.hasValue(links)) {
    return null;
  }

  // Get first link from description
  const link = links[0];

  // convert [some text](url) to url
  const url = link.replace(/.*\((.*)\)/, "$1");

  if (!Validator.hasValue(url) || isYoutube(url)) {
    return null;
  }

  return <LinkMetaCard url={url} className={"mb-2"} />;
}

function PostCustomButtons({ post, show = "top" }) {
  const isSharedPost = Validator.hasValue(post.shared);
  let { buttons } = isSharedPost ? post.shared : post ?? [];

  if (!Validator.hasValue(buttons)) {
    return <></>;
  }
  const filteredButtons = buttons.filter((b) => b.position === show);

  if (!Validator.hasValue(filteredButtons)) {
    return <></>;
  }
  // we have filtered buttons, show them to user
  return (
    <div className="PostCustomButtons my-4 break-all">
      {filteredButtons.map((button, index) => (
        <Button
          key={index}
          label={button.label ?? "Click here"}
          onClick={() => {
            window.open(button.url, "_blank");
          }}
        />
      ))}
    </div>
  );
}

/**
 * Post tags
 * @param {*} param0
 * @returns
 */
function PostTags({ post, user }) {
  const isSharedPost = Validator.hasValue(post.shared);

  let { tags } = isSharedPost ? post.shared : post;

  if (tags.length === 0) return <></>;

  return (
    <div className="tags-container items-center">
      {tags.map((tag, index) => (
        <div
          key={index}
          className="theme-bg-disable tag-body ml-2 flex cursor-pointer border border-border text-xs hover:bg-primary-light"
          onClick={(e) => {
            history.push(showTagPageRoute(tag));
          }}>
          <span className="tag-title">
            {tag.length > 30 ? tag.substr(0, 30) + "..." : tag}
          </span>
        </div>
      ))}
    </div>
  );
}

/**
 * Convert youtube links to plain text from markdown.
 * This is required to convert youtube links to plain text before converting description in HTML format
 * @param {string} markdownText
 */
export function convertYouTubeLinksToPlainText(markdownText) {
  const reg2 =
    /(\[.*?\]\()?(https?:\/\/)?(www\.)?youtu(be\.com|\.be)\/(?!.*channel|short|playlist)(?:watch\?v=)?([a-zA-Z0-9\-_]+)([^\s]*?)?\)/g;
  const updatedText = markdownText.replace(
    reg2,
    `https://youtube.com/watch?v=$5`
  );
  return updatedText;
}

export function getVideoEmbedFromDescription(description = "") {
  // check for youtube video // for link
  let newDescription = description.replace(
    /(<a href=")(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:youtube.com|youtu.be)\/(?:watch)?(?:\?v=)?([^&<">()[\] \n]+)([^"]+)(">)([^<]+)(<\/a>)/g,
    '<p><iframe title="Youtube Embed" src="https://www.youtube.com/embed/$2" width="100%" height="315" className="my-2" allowFullScreen></iframe></p>'
  );

  // for text link
  newDescription = description.replace(
    /(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:youtube.com|youtu.be)\/(?!.*channel|short|playlist)(?:watch)?(?:\?v=)?([^&<">()[\] \n]+)([^" \n]+)/g,
    '<p><iframe title="Youtube Embed" src="https://www.youtube.com/embed/$1" width="100%" height="315" className="my-2" allowFullScreen></iframe></p>'
  );
  // check for vimeo video
  newDescription = newDescription.replace(
    /(?:[^"'https://])(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:player\.)?(?:vimeo.com)\/(?:video\/)?([^<">()[\] \n]+)/g,
    '<p><iframe title="Vimeo Embed" src="https://player.vimeo.com/video/$1" width="100%" height="360" className="my-2" frameBorder="0" allow="fullscreen" allowFullScreen></iframe></p>'
  );

  return newDescription;
}

function PostQuiz({ post }) {
  const isSharedPost = Validator.hasValue(post.shared);
  const poll = isSharedPost ? post.shared.poll : post.poll;
  return poll && poll.isQuiz ? (
    <div className="my-4 text-sm text-yellow-600">
      <I18>
        Quiz can be only viewed and attempted from mobile device right now!
      </I18>
    </div>
  ) : (
    <></>
  );
}

function PostQuestion({ post, user, updatePost }) {
  const [userSelectedAnswer, setUserSelectedAnswer] = useState(null);
  const [displayStatics, setDisplayStatics] = useState(false);

  const isSharedPost = Validator.hasValue(post.shared);
  const poll = isSharedPost ? post.shared.poll : post.poll;

  const question = poll && poll.isQuestion && poll.questions[0];

  useEffect(() => {
    // check if we have answer by user
    if (poll && poll.isAnsweredByMe)
      setUserSelectedAnswer(poll.myAnswer[0].option);
  }, [poll, post]);

  if (!question) return <></>;

  return (
    <div className="PostQuestion my-4">
      {/* question statement */}
      <NL2BR text={question.statement} />
      {/* question options */}
      {question.options.map((option, index) => (
        <div
          key={index}
          onClick={() => {
            // check if we don't have answered
            if (!poll.myAnswer) {
              // attempt this question
              PostService.voteOnPoll(
                user,
                isSharedPost ? post.sharedId : post.id,
                option
              )
                .then((response) => {
                  // update post
                  updatePost(response.post);
                })
                .catch((error) => {
                  if (MainUtil.checkIfBlocked(error)) {
                    console.log("[Block] Unauthorize error ");
                  }
                });
            }
          }}
          className={cx("option rounded border border-border", {
            correct: userSelectedAnswer && option === question.answer,
            incorrect:
              userSelectedAnswer &&
              question.answer !== userSelectedAnswer &&
              userSelectedAnswer === option,
          })}>
          <span className="theme-bg-disable mr-2 flex h-6 w-6 place-content-center rounded-full text-sm">
            {String.fromCharCode(65 + index)}
          </span>
          <NL2BR text={option} />
        </div>
      ))}
      {/* Uncomment to display question result button */}
      {/* {post.canDelete && (
        <TextButton
          label="View Result"
          onClick={() => {
            setDisplayStatics(true);
          }}
        />
      )} */}

      {/* <RightSideModal
        active={displayStatics}
        setActive={setDisplayStatics}
        width={700}>
        <PollResult post={post} setDisplayStatics={setDisplayStatics} />
      </RightSideModal> */}
    </div>
  );
}

function PostPoll({ post, user, updatePost, displayPollResult }) {
  const [userSelectedAnswer, setUserSelectedAnswer] = useState(null);
  const isSharedPost = Validator.hasValue(post.shared);

  const [displayStatics, setDisplayStatics] = useState();

  const { poll } = isSharedPost ? post.shared : post;

  const question =
    poll && !poll.isQuestion && !poll.isQuiz && poll.questions[0];

  useEffect(() => {
    // check if we have answer by user
    if (poll && poll.isAnsweredByMe)
      setUserSelectedAnswer(poll.myAnswer[0].option);
  }, [poll]);

  if (!question) return <></>;

  return (
    <div className="PostQuestion my-4">
      {/* question statement */}
      <NL2BR text={question.statement} />
      {/* question options */}
      {question.options.map((option, index) => (
        <div
          key={index}
          onClick={() => {
            // check if we dont have answered
            if (!poll.myAnswer) {
              // attempt this question
              PostService.voteOnPoll(
                user,
                isSharedPost ? post.sharedId : post.id,
                option
              )
                .then((response) => {
                  // update post
                  updatePost(response.post);
                })
                .catch((error) => {
                  if (MainUtil.checkIfBlocked(error)) {
                    console.log("[Block] Unauthorize error ");
                  }
                });
            }
          }}
          className={cx(
            "option gap-1 rounded border border-border hover:bg-primary-light",
            {
              "theme-bg-disable hover:theme-bg-disable":
                userSelectedAnswer && userSelectedAnswer === option,
            }
          )}>
          <span className="theme-bg-disable flex h-6 w-6 place-content-center items-center rounded-full text-sm">
            {String.fromCharCode(65 + index)}
          </span>
          <div className="flex-1">
            <NL2BR text={option} />
          </div>
          {/* vote count */}
          <div className="text-secondary-foreground/70">
            {poll.voteCount > 0 ? (poll.votes[option] * 100).toFixed(2) : 0}% |
            {Math.floor(poll.votes[option] * poll.voteCount)}
          </div>
        </div>
      ))}

      {/* View poll result button */}
      {displayPollResult && post.canDelete && (
        <TextButton
          label="View Result"
          onClick={() => {
            setDisplayStatics(true);
          }}
        />
      )}

      <RightSideModal
        active={displayStatics}
        setActive={setDisplayStatics}
        width={700}>
        <PollResult post={post} setDisplayStatics={setDisplayStatics} />
      </RightSideModal>
    </div>
  );
}

function PostVideo({ post }) {
  const isSharedPost = Validator.hasValue(post.shared);
  const videos = isSharedPost ? post.shared?.videos : post.videos;
  return videos && videos.length > 0 ? (
    <div className="my-4 text-sm text-yellow-600">
      {videos.map((video, index) => (
        <div key={index} className="mb-2 h-80 w-full">
          <video
            src={video}
            key={index}
            controls
            controlsList="nodownload"
            className="h-full w-full bg-black"
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
}

function PostRecordings({ post }) {
  const liveMeeting = post.event?.liveCall
    ? post.event.liveCall
    : post.shared
      ? post.shared?.liveCall || post.shared?.liveCall
      : post.liveCall || post.liveCall;
  if (
    !post ||
    !liveMeeting ||
    !liveMeeting.recordings ||
    !Array.isArray(liveMeeting.recordings)
  )
    return <></>;

  const { recordings } = liveMeeting;

  return (
    <div>
      {recordings?.map((video, index) => (
        <video src={video} key={index} controls controlsList="nodownload" />
      ))}
    </div>
  );
}

function PostEmbedCodeViewer({ post }) {
  const isSharedPost = Validator.hasValue(post.shared);
  const embedCode = isSharedPost ? post.shared?.embedCode : post.embedCode;
  const embedCodeHeight = isSharedPost
    ? post.shared?.embedCodeHeight
    : post.embedCodeHeight;
  if (!embedCode) return <></>;

  return (
    <iframe
      srcDoc={embedCode}
      title={isSharedPost ? post.shared.title : post.title}
      className="PostEmbedCodeViewer"
      style={
        embedCodeHeight
          ? {
              height: embedCodeHeight + "px",
            }
          : {
              minHeight: "340px",
            }
      }
    />
  );
}

/**
 * Show all the post images
 * @param {*} param0
 * @returns
 */
function PostImages({ post }) {
  const isSharedPost = Validator.hasValue(post.shared);
  const images = isSharedPost ? post.shared.images : post.images;

  // 0 images
  if (!Array.isArray(images) || images.length < 1) {
    return <></>;
  }

  // 1 image
  if (images.length === 1) {
    return (
      <div className="PostImages align-center my-2 flex justify-center">
        <PostImage
          image={images[0]}
          className="w-full rounded object-contain object-center"
          wrapperClassName="p-0"
        />
      </div>
    );
  }
  // 2 images
  if (images.length === 2) {
    return (
      <div className="PostImages my-4 flex justify-between">
        {images.map((image) => (
          <PostImage
            key={image}
            image={image}
            className="object-cover object-center"
          />
        ))}
      </div>
    );
  }
  // 3 images
  if (images.length === 3) {
    return (
      <div className="PostImages my-4">
        <PostImage
          className="flex h-full w-full justify-center object-cover object-center"
          image={images[0]}
        />
        <div className="mt-2 flex">
          <PostImage
            className="w-full object-cover object-center"
            image={images[1]}
          />
          <PostImage
            className="w-full object-cover object-center"
            image={images[2]}
          />
        </div>
      </div>
    );
  }
  // 4 images
  if (images.length === 4) {
    return (
      <div className="PostImages my-4">
        <PostImage
          className="flex h-full w-full justify-center object-cover object-center"
          image={images[0]}
        />
        <div className="mt-2 flex">
          <PostImage
            className="h-52 w-full object-cover object-center"
            image={images[1]}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            image={images[2]}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            image={images[3]}
          />
        </div>
      </div>
    );
  }

  // 5 or more than 5 images (right now showing 5 images only)
  if (images.length >= 5) {
    // n images
    return (
      <div className="PostImages my-4">
        <div className="flex">
          <PostImage
            className="flex h-full w-full justify-center object-cover object-center"
            image={images[0]}
          />
          <PostImage
            className="h-full w-full object-cover object-center"
            image={images[1]}
          />
        </div>
        <div className="mt-2 flex">
          <PostImage
            className="h-52 w-full object-cover object-center"
            image={images[2]}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            image={images[3]}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            image={images[4]}
            more={images.length - 5}
          />
        </div>
      </div>
    );
  }

  // // n images
  // // print first 3 and show 4th as more
  // console.log('length', post.images.length)
  // return (
  //     <div className="PostImages my-4 flex-wrap">
  //         <div className="flex">
  //             <PostImage className="w-full h-52"  image={post.images[0]} />
  //             <PostImage className="w-full h-52"  image={post.images[1]} />
  //         </div>
  //         <div className="flex mt-2">
  //             <PostImage className="w-full h-52"  image={post.images[2]} />
  //             <PostImage className="w-full h-52" image={post.images[3]} more={post.images.length - 1} />
  //         </div>

  //     </div>
  // )
}

export function PostImage({
  image,
  more = 0,
  className = "",
  wrapperClassName = "p-1",
}) {
  if (!image) return <></>;
  return (
    <div className={"PostImage relative " + wrapperClassName}>
      {more > 0 ? (
        <div className="more h-full rounded-md">
          <span>+{more}</span>
        </div>
      ) : (
        <></>
      )}
      <LazyImage
        src={image}
        className={cx(
          "h-full max-h-[70vh] w-full border border-border",
          className
        )}
        alt="PostImage"
      />
    </div>
  );
}

/**
 * Show post document if necessary
 * @param {*} param0
 * @returns
 */
export function PostDocument({ post, smallMargin = false }) {
  const videoFormat = ["mp4", "mov", "webm", "ogg"];
  const audioFormat = ["wav", "mp3", "aac", "amr"];
  const imageFormat = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "webp",
    "svg",
    "bmp",
    "ico",
  ];
  const isSharedPost = Validator.hasValue(post.shared);
  const document = isSharedPost ? post.shared?.document : post.document;
  const documentName = isSharedPost
    ? post.shared?.documentName
    : post.documentName;

  return document && videoFormat.includes(document.split(".").pop()) ? (
    <div
      className={cx("relative", {
        "my-8": !smallMargin,
        "my-2": smallMargin,
      })}>
      <div className="mr-3 mt-2 py-1">
        <video className="m-0 rounded-md" controls controlsList="nodownload">
          <source src={document} />
        </video>
      </div>
    </div>
  ) : document && audioFormat.includes(document.split(".").pop()) ? (
    <div
      className={cx("relative", {
        "my-8": !smallMargin,
        "my-2": smallMargin,
      })}>
      <div className="mr-3 mt-2 py-1">
        <audio
          className="m-0 w-full rounded-md"
          controls
          controlsList="nodownload">
          <source src={document} />
        </audio>
      </div>
    </div>
  ) : document && imageFormat.includes(document.split(".").pop()) ? (
    <PostImages post={{ images: [document], shared: undefined }} />
  ) : document ? (
    <div
      className={cx("relative", {
        "my-8": !smallMargin,
        "my-2": smallMargin,
      })}>
      <a
        download
        target="_blank"
        rel="noreferrer"
        href={document}
        title="Open file"
        className="flex items-center space-x-2 rounded border-2 border-border px-4 py-2 font-semibold text-secondary-foreground/80">
        <UIcon icon="document" className="h-9 text-3xl" />
        <div className="flex flex-grow items-center justify-between space-x-4">
          <div className="items-center">
            <div>
              {documentName ? documentName : shortenLink(post.document)}
            </div>
            <div className="text-xs uppercase">
              {(documentName ? documentName : post.document).split(".").pop()}
            </div>
          </div>
          <span className="flex-none">
            <UIcon icon="download" className="text-xl" />
          </span>
        </div>
      </a>
    </div>
  ) : (
    <></>
  );
}

function PostLikesPreview({
  post,
  updatePost = (post) => {},
  areCommentsLoading = false,
  setCommentsLoading = (e) => {},
  setDisplayCreateCommentField = (e) => {},
}) {
  const { latestLikes } = post;
  const [isUpvotingUsersListVisible, setIsUpvotingUsersListVisible] =
    useState(false);
  const { user, community } = useAppSelector((state) => {
    return {
      community: state.community,
      user: state.auth,
    };
  });

  return (
    <div className="PostLikesPreview text-xs text-secondary-foreground/70">
      <div className="relative mb-1 flex h-8 w-full items-center justify-between">
        {Array.isArray(latestLikes) && latestLikes.length > 0 && (
          <div
            className="flex cursor-pointer items-center"
            onClick={(e) => {
              setIsUpvotingUsersListVisible(true);
            }}>
            {
              // like pictures
              latestLikes?.map((liker, index) => (
                <RImage
                  src={liker.picture}
                  key={index}
                  className="absolute h-6 w-6 rounded-full border border-border"
                  style={{
                    left: index * 10 + "px",
                  }}
                />
              ))
            }
            {/* like names */}
            <PostLikes
              post={post}
              isUpvotingUsersListVisible={isUpvotingUsersListVisible}
              setIsUpvotingUsersListVisible={setIsUpvotingUsersListVisible}
            />
          </div>
        )}

        {post.commentCount > 0 && areCommentsLoading && <Loader />}
        {post.commentCount > 0 && !areCommentsLoading && (
          <div
            className="flex-end cursor-pointer"
            onClick={() => {
              // if user not logged in redirect to login screen
              if (!user) {
                // return addToast(
                //   "You need to login to enable interaction!",
                //   "",
                //   ToastTypes.info
                // );
                window.alert("Hey, there! You need to log in to add comment.");
                return redirectToAuthUrl(community);
              }
              setDisplayCreateCommentField(true);
              // get post comments if not already loaded
              if (!post.comments && !areCommentsLoading) {
                // load comments
                setCommentsLoading(true);
                PostService.getPostDetail(user, post.id)
                  .then((response) => {
                    // add the comments in post
                    updatePost(response.post);
                    setCommentsLoading(false);
                  })
                  .catch((err) => {
                    console.log({ err });
                    setCommentsLoading(false);
                  });
              }
            }}>
            {post.commentCount === 1
              ? "1 Comment"
              : `${post.commentCount} Comments`}
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Post Actions
 * @param {*} param0
 * @returns
 */
const PostActions = connect((s) => ({
  user: s.auth,
  community: s.community,
}))(({
  post,
  user,
  community,
  updatePost = (post) => {},
  areCommentsLoading = false,
  setCommentsLoading = (e) => {},
  setDisplayCreateCommentField = (e) => {},
}) => {
  const [inProgress, setInProgress] = useState(false);
  const { hideLikes, hideComment, hideDate } = post.preferences ?? {};
  const [isCopyUrlModalVisible, setCopyUrlModalVisible] = useState(false);
  const { addToast } = useToast();
  const { analyticsService } = useAppService();

  return (
    <div
      className={cx(
        "flex items-center justify-around text-xs text-secondary-foreground",
        {
          "border-t border-border": !(hideComment && hideLikes),
        }
      )}>
      <div
        className={cx("px-5 py-2", { hidden: hideLikes, "mr-2": !hideLikes })}>
        <button
          className={`flex items-center ${
            post.isLikedByMe ? "text-primary" : ""
          }`}
          onClick={() => {
            // if already loading, do nothing
            if (inProgress) {
              return;
            }
            // if user not logged in redirect to login screen
            if (!user) {
              // return addToast(
              //   "Hey, there! You need to log in to like posts.",
              //   "",
              //   ToastTypes.info
              // );
              window.alert("Hey, there! You need to log in to like posts.");
              return redirectToAuthUrl(community);
            }
            // if post is liked by me, unlike it, else, like it
            if (post.isLikedByMe) {
              // dislike the post
              setInProgress(true); // setLoading
              PostService.dislikePost(user, post.id)
                .then(({ post }) => {
                  updatePost(post);
                })
                .catch((err) => {
                  console.error({ err });
                  if (MainUtil.checkIfBlocked(err)) {
                    console.log("[Block] Unauthorize error ");
                  }
                })
                .finally(() => {
                  setInProgress(false);
                });
            } else {
              setInProgress(true); // setLoading
              PostService.likePost(user, post.id)
                .then(({ post }) => {
                  updatePost(post);
                  // AnalyticsService.logEvent(
                  //   EventType.Post.name,
                  //   EventType.Post.like
                  // );
                  // mixpanel event
                  analyticsService.track("like-post", {
                    userType: community.myRole,
                    postId: post.id,
                    groupName: post?.group?.name,
                    sectionName: post?.tab?.name,
                    postUrl:
                      window.location.origin +
                      createPostDetailPageRouteFromSlug(post),
                  });
                })
                .catch((err) => {
                  console.error({ err });
                  if (MainUtil.checkIfBlocked(err)) {
                    console.log("[Block] Unauthorize error ");
                  }
                })
                .finally(() => {
                  setInProgress(false);
                });
            }
          }}>
          <ThumbUpIcon
            fill={!post.isLikedByMe ? "none" : ""}
            className={cx(`flex items-center text-base`, {
              "text-primary": post.isLikedByMe,
              hidden: hideLikes,
            })}
          />

          <span className={`${post.isLikedByMe && "text-primary"} ml-2`}>
            Like
          </span>
        </button>
      </div>

      <div className="px-5 py-2">
        {areCommentsLoading ? (
          <Loader />
        ) : (
          <div className="flex items-center">
            <button
              className={cx("flex items-center", {
                invisible: hideComment,
              })}
              onClick={() => {
                // if user not logged in redirect to login screen
                if (!user) {
                  // return addToast(
                  //   "You need to login to enable interaction!",
                  //   "",
                  //   ToastTypes.info
                  // );
                  window.alert(
                    "Hey, there! You need to log in to add comment."
                  );
                  return redirectToAuthUrl(community);
                }
                setDisplayCreateCommentField(true);
                // get post comments if not already loaded
                if (!post.comments && !areCommentsLoading) {
                  // load comments
                  setCommentsLoading(true);
                  PostService.getPostDetail(user, post.id)
                    .then((response) => {
                      // add the comments in post
                      updatePost(response.post);
                      setCommentsLoading(false);
                    })
                    .catch((err) => {
                      console.log({ err });
                      setCommentsLoading(false);
                    });
                }
              }}>
              <UIcon
                icon="comment-alt"
                size="sm"
                className={`${post.isCommentedOnByMe && "text-primary"} mt-1`}
                solid={post.isCommentedOnByMe}
                // label={
                //   post.commentCount > 0
                //     ? post.commentCount === 1
                //       ? "1"
                //       : post.commentCount
                //     : ""
                // }
              />

              <span
                className={`${
                  post.isCommentedOnByMe && "text-primary"
                } ml-2.5`}>
                Comment
              </span>
            </button>
          </div>
        )}
      </div>
      <div
        className={cx("px-5 py-2", {
          invisible: hideComment,
        })}>
        {user && !post.sharedId && (
          <button
            className="items"
            onClick={(e) => {
              setCopyUrlModalVisible(true);
            }}>
            <UIcon icon="redo" size="sm" className="mt-1" />
            <span className="ml-2.5">Share</span>
          </button>
        )}
      </div>
      {/* Copy post url modal*/}
      {!post.sharedId && (
        <SharePostModal
          post={post}
          heading="Share"
          text={
            window.location.origin + createPostDetailPageRouteFromSlug(post)
          }
          // text={createPostDetailPageRouteFromSlug(post)}
          active={isCopyUrlModalVisible}
          setActive={setCopyUrlModalVisible}
          onClose={() => {
            //  set is copy url modal visible to false
            setCopyUrlModalVisible(false);
          }}
        />
      )}
    </div>
  );
});

/**
 * Component to show post comments list
 * @param {*} param0
 * @returns
 */
function PostComments({
  user,
  post,
  group,
  updatePost,
  areCommentsLoading = false,
  setCommentsLoading = (e) => {},

  addToast = (e) => {},
}) {
  const { hideComment } = post.preferences ?? {};
  if (hideComment) {
    return <></>;
  }

  const updateComment = (comment, isLatestComment = false) => {
    // update one comment by if in array of comments
    if (Validator.hasValue(post.comments)) {
      const newComments = [...post.comments];
      const index = newComments.findIndex((c) => c.id === comment.id);
      if (index > -1) {
        newComments[index] = comment;
        updatePost({ ...post, comments: newComments });
      }
    } else {
      updatePost({ ...post, latestComment: comment });
    }
  };

  // if no comments but we have latest comment, show it
  if (!Validator.hasValue(post.comments) && post.latestComment) {
    return (
      <div className="PostComments border-t border-border pb-2 text-secondary-foreground">
        {/* show latest comment */}
        <PostComment
          post={post}
          group={group}
          user={user}
          postId={post.id}
          comment={post.latestComment}
          key={post.latestComment.id}
          isLatestComment={true}
          updatePost={updatePost}
          updateComment={(comment) => {
            updateComment(comment, true);
          }}
          addToast={addToast}
        />
        {/* show load more screen, if comment more than one */}
        {post.commentCount > 1 ? (
          <div className="my-2">
            <span
              className="cursor-pointer text-xs text-primary"
              onClick={(e) => {
                // get post comments if not already loaded
                if (!Validator.hasValue(post.comments) && !areCommentsLoading) {
                  // load comments
                  setCommentsLoading(true);
                  PostService.getPostDetail(user, post.id)
                    .then((response) => {
                      // add the comments in post
                      updatePost(response.post);
                      setCommentsLoading(false);
                    })
                    .catch((err) => {
                      setCommentsLoading(false);
                    });
                }
              }}>
              <I18>Load more comments</I18>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  // if no comments, show nothing
  if (!post.comments || post.comments.length === 0) {
    return <></>;
  }

  return (
    <div className="mt-1 pb-3">
      {/* show all comments */}
      {post.comments.map((comment, index) => (
        <PostComment
          post={post}
          user={user}
          postId={post.id}
          comment={comment}
          key={comment.id}
          updatePost={updatePost}
          updateComment={updateComment}
          addToast={addToast}
        />
      ))}
    </div>
  );
}

/**
 * Show post is processing
 * @param {*} param0
 * @returns
 */
export function PostProcessing({ isProcessing }) {
  if (!isProcessing) return <></>;

  return (
    <div className="PostProcessing">
      <Loader />
    </div>
  );
}
