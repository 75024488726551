import cx from "classnames";
import I18 from "./atoms/i18";
import { Loader } from "./loader.component";
import { Modal } from "./modal.component";

function ActionEventModalComponent({
  onSubmit = () => {},
  onCancelSubmit = () => {},
  title,
  subTitle = "",
  header,
  isLoading,
  active = false,
  labelCancel = "Cancel",
  labelSubmit = "Yes, Go Ahead",
  btnColor,
  disabled = false,
  setActive = (e) => {},
  large = false,
  className = "",
}) {
  // if (!activeGroup || !active) {
  //     return <></>;
  // }

  return (
    <Modal
      className="CreateGroupModal"
      active={active}
      width="390px"
      padding={false}
      setActive={setActive}>
      {/* body */}
      <div className="px-5 py-8 text-secondary-foreground/85">
        <div className="text-center">
          <I18>{title}</I18>
        </div>
        {subTitle ? (
          <div className="mt-2 text-center text-xs font-semibold text-primary">
            {subTitle}
          </div>
        ) : null}
        <div className="mt-5">
          <div className="mx-auto">
            {isLoading ? (
              <div className="flex justify-center">
                <Loader />
              </div>
            ) : (
              <div className="flex justify-between">
                <button
                  disabled={disabled}
                  className={cx(
                    "theme-bg-disable border-2 border-border font-semibold focus:outline-none" +
                      className,
                    {
                      "rounded px-6 py-2": large,
                      "rounded px-3 py-1": !large,
                    }
                  )}
                  onClick={onCancelSubmit}>
                  <I18>{labelCancel}</I18>
                </button>

                <button
                  disabled={disabled}
                  className={cx(
                    "border-2 border-primary bg-primary font-semibold text-primary-foreground focus:outline-none" +
                      className,
                    {
                      "rounded px-6 py-2": large,
                      "rounded px-3 py-1": !large,
                    }
                  )}
                  onClick={onSubmit}>
                  <I18>{labelSubmit}</I18>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

const ActionEventModal = ActionEventModalComponent;

export { ActionEventModal };
