import cx from "classnames";
import bellIcon from "../_assets/vectors/bell-icon.svg";
import bookmarkActiveIcon from "../_assets/vectors/bookmark-active-icon.svg";
import bookmarkIcon from "../_assets/vectors/bookmark-icon.svg";
import commentBlueIcon from "../_assets/vectors/comment-blue-icon.svg";
import commentIcon from "../_assets/vectors/comment-icon.svg";
import ellipsisIcon from "../_assets/vectors/ellipsis-icon.svg";
import leftArrowIcon from "../_assets/vectors/left-arrow-icon.svg";
import likeBlueIcon from "../_assets/vectors/like-blue-icon.svg";
import likeIcon from "../_assets/vectors/like-icon.svg";
import messageIcon from "../_assets/vectors/message-icon.svg";
import plusGrayIcon from "../_assets/vectors/plus-gray-icon.svg";
import plusIcon from "../_assets/vectors/plus-icon.svg";
import sendMessageIcon from "../_assets/vectors/send-message-icon.svg";
import setting2Icon from "../_assets/vectors/setting-2-icon.svg";
import settingIcon from "../_assets/vectors/setting-icon.svg";
import shareIcon from "../_assets/vectors/share-icon.svg";

export default function IconButton({
  icon,
  iconSmall = false,
  hoverIcon = null,
  title = "",
  label = "",
  active = false,
  hoverable = true,
  small = false,
  alt,
  onClick = (e) => {},
  large = false,
  img = null,
  rotate = 0,
  className = "",
}) {
  switch (icon) {
    case "bell":
      alt = icon;
      icon = bellIcon;
      break;
    case "ellipsis":
      alt = icon;
      icon = ellipsisIcon;
      break;
    case "message":
      alt = icon;
      icon = messageIcon;
      break;
    case "setting":
      alt = icon;
      icon = settingIcon;
      break;
    case "setting2":
      alt = icon;
      icon = setting2Icon;
      break;
    case "like":
      alt = icon;
      icon = likeIcon;
      break;
    case "like-blue":
      alt = icon;
      icon = likeBlueIcon;
      break;
    case "comment":
      alt = icon;
      icon = commentIcon;
      break;
    case "comment-blue":
      alt = icon;
      icon = commentBlueIcon;
      break;
    case "share":
      alt = icon;
      icon = shareIcon;
      break;
    case "bookmark":
      alt = icon;
      icon = bookmarkIcon;
      break;
    case "bookmark-active":
      alt = icon;
      icon = bookmarkActiveIcon;
      break;
    case "send-message":
      alt = icon;
      icon = sendMessageIcon;
      break;
    case "plus":
      alt = icon;
      icon = plusIcon;
      break;
    case "plus-gray":
      alt = icon;
      icon = plusGrayIcon;
      break;
    case "left-arrow":
      alt = icon;
      icon = leftArrowIcon;
      break;
    case "img":
      alt = "";
      icon = img;
      break;
    default:
      break;
  }

  if (!large && !small) {
    className += " p-2 h-10";
  }

  if (small) {
    className += " p-1 h-8 w-8";
  }

  if (!small) {
    if (!label) {
      className += "  w-10";
    } else {
      if (!iconSmall) {
        className += "  pl-3";
      }
    }
  }

  if (hoverable) {
    className += " hover:bg-accent";
  }

  return (
    <div
      className={cx(
        "IconButton flex flex-shrink-0 cursor-pointer items-center justify-center rounded-full",
        className,
        {
          "has-hover-icon": hoverIcon ? true : false,
        }
      )}
      onClick={onClick}
      title={title ? title : label}>
      <img
        src={icon}
        alt={alt}
        style={{
          transform: "rotate(" + rotate + "deg)",
        }}
        className={cx("default-icon", {
          "w-4": iconSmall,
        })}
      />
      <img
        src={hoverIcon}
        alt={alt}
        style={{
          transform: "rotate(" + rotate + "deg)",
        }}
        className={cx("hover-icon", {
          "w-4": iconSmall,
        })}
      />
      {label ? (
        <div
          className={cx("label mt-1 font-semibold", {
            "text-primary": active,
            "text-secondary-foreground/80": !active,
            "mx-3": !iconSmall,
            "mx-1": iconSmall,
          })}>
          {label}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
