import { lazy, Suspense, useEffect, useState } from "react";
import { Group } from "../../types/group/group.type";
import SecureLoop from "../../_assets/lottie/secure-check-loader.json";
import SuccessCheck from "../../_assets/lottie/success-check-filled.json";
import ModalForm from "../../_components/model2";
import Label from "../../_components/typography/label.typography";
import { GroupSubscriptionCheckoutModel } from "./group-subscription-checkout-modal";
const LottiePlayer = lazy(
  () => import("../../_components/molecule/lottie-player")
);

interface Props {
  group?: Group;
  community: any;
  user: any;
  plans: any;
  header: any;
  active: boolean;
  setActive: (active: boolean) => void;
  socket: any;
  mySubscription: any;
}
export const GroupCheckoutComponents = ({
  group,
  community,
  header,
  user,
  active,
  setActive,
  mySubscription,
  socket,
  plans,
}: Props) => {
  const [isWaitingForPaymentProcessing, setIsWaitingForPaymentProcessing] =
    useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket.on("SUBSCRIPTION_UPDATED", (data: any) => {
      console.log("SUBSCRIPTION_UPDATED", data);
      setIsWaitingForPaymentProcessing(false);
      setIsPaymentSuccessful(true);
    });

    return () => {
      socket.off("SUBSCRIPTION_UPDATED");
    };
  }, [socket]);

  if (!plans) return <></>;
  return (
    <div>
      <GroupSubscriptionCheckoutModel
        group={group}
        community={community}
        user={user}
        plans={plans}
        header={header}
        setActive={setActive}
        active={active}
        labelSubmit="Checkout"
        mySubscription={mySubscription}
        onPaymentSuccess={(data) => {
          console.log("[Group Join]", "", data);
          setIsWaitingForPaymentProcessing(true);
        }}
      />

      {/* DISPLAY PAYMENT PROCESSING SIMULATION */}
      <ModalForm
        title={"Add new course"}
        className="GroupSubscriptionCheckoutModel w-80"
        visible={isWaitingForPaymentProcessing}
        hideHeader
        setVisible={setIsWaitingForPaymentProcessing}>
        <div className="flex flex-col items-center justify-center">
          <Label size="body1" className="text-center">
            Please wait while we are processing your payment
          </Label>
          <Suspense fallback={<></>}>
            <LottiePlayer
              //   @ts-ignore
              src={SecureLoop}
              loop={true}
              className="h-36"
              onEvent={(event) => {
                // if (event === "complete") {
                //   setIsWaitingForPaymentProcessing(false);
                //   setIsPaymentSuccessful(true);
                // }
              }}
              controlVisible={false}
            />
          </Suspense>
        </div>
      </ModalForm>
      {/* DISPLAY PAYMENT PROCESSING COMPLETED MESSAGE */}
      <ModalForm
        title={"Add new course"}
        className="GroupSubscriptionCheckoutModel first-letter:w-80"
        visible={isPaymentSuccessful}
        hideHeader
        setVisible={setIsPaymentSuccessful}>
        <div className="flex flex-col items-center justify-center">
          <Label size="body1" className="text-center">
            Your payment is successful
          </Label>
          <Suspense fallback={<></>}>
            <LottiePlayer
              //   @ts-ignore
              src={SuccessCheck}
              className="h-36"
              onEvent={(event) => {
                if (event === "complete") {
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              }}
              controlVisible={false}
            />
          </Suspense>
        </div>
      </ModalForm>
    </div>
  );
};
