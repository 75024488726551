import cx from "classnames";
import { useMemo, useState } from "react";
import { ToastTypes } from "../../../../../contexts/toastr.context";
import { useToast } from "../../../../../hooks/use-toast.hook";
import { PrettiFy } from "../../../../../types/course/course.type";
import { SubscriptionPlan } from "../../../../../types/subscription/subscription-plan";
import { Loader } from "../../../../../_components";
import IconMenu2 from "../../../../../_components/icon-menu-2.component";
import ModalForm from "../../../../../_components/model2";
import Label from "../../../../../_components/typography/label.typography";
import { apiEndpointBase } from "../../../../../_config/endpoint.config/endpoint.config";
import { SubscriptionService } from "../../../../../_service";
import { Fold } from "../../../../../_utils/extensions/typescript-utils";
import { ActiveSubscriptionMember } from "../subscription-detail-component";
import EditInvoice from "./edit-invoice-view";

interface Props {
  communityId: string;
  plan: PrettiFy<ActiveSubscriptionMember>;
  subscription: PrettiFy<SubscriptionPlan>;
  onPlanUpdate: (plan: ActiveSubscriptionMember) => void;
}

export function ActiveMembersListTile({
  plan,
  subscription,
  communityId,
  onPlanUpdate,
}: Props) {
  const subscriber = plan.user;
  const subscriptionInfo = plan.billingDetails;
  const isRecurring = subscription.isRecurring;
  const purchasedDate = new Date(plan.createdAt);
  const interval = subscription.recurringInterval;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditInvoices, setIsEditInvoices] = useState(false);

  // Find the plan renewal date
  var renewalDate = new Date(purchasedDate);
  if (isRecurring && interval) {
    renewalDate.setMonth(renewalDate.getMonth() + interval);
  }

  const locale = useMemo(() => getLang(), []) ?? "en-US";
  const toast = useToast();

  function getLang() {
    if (navigator.languages !== undefined) return navigator.languages[0];
    return navigator.language;
  }

  // Resend invoice
  function resendInvoice() {
    setIsLoading(true);
    SubscriptionService.resendInvoice(communityId, plan.id)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        toast.addToast("Invoice sent successfully", "");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.addToast("Unable to resend invoice", "", ToastTypes.danger);
      });
  }

  return (
    <tr className="cursor-pointer border border-border text-sm text-secondary-foreground/80 hover:bg-background">
      <td className="p-1 py-6 md:px-4">
        {plan.invoiceDetails.invoicePrefix}
        {plan.invoiceDetails.invoiceNumber}
      </td>
      <td className="p-1 md:px-4">
        <Label variant="t1">{subscriber.name ?? "N/A"}</Label>
        <br />
        <Label
          size="caption"
          className={cx("", {
            hidden: !subscriber.email && !subscriber.mobile,
          })}>{`${subscriber.email} | ${subscriber.mobile}`}</Label>
      </td>
      <td className="p-1 md:px-4">{subscriptionInfo.state ?? "N/A"}</td>

      <td className="p-1 md:px-4">{subscriptionInfo.country ?? "N/A"}</td>

      <td className="p-1 md:px-4">
        {purchasedDate
          ? purchasedDate.toLocaleDateString(locale, {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "N/A"}
      </td>

      <td className="p-1 md:px-4">
        {subscription.isRecurring ? (
          <>
            {renewalDate.toLocaleDateString(locale, {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </>
        ) : (
          "N/A"
        )}
      </td>

      <td className="p-1">
        <Fold
          value={!isLoading}
          ifPresent={(): JSX.Element => {
            return (
              <IconMenu2
                actions={[
                  {
                    label: "Download Invoice",
                    icon: "download",
                    onClick: () => {
                      window.open(
                        `${apiEndpointBase}community/${communityId}/invoice-download/${plan.id}`,
                        "_blank"
                      );
                    },
                  },
                  {
                    label: "Edit Invoice no.",
                    icon: "edit",
                    onClick: () => {
                      setIsEditInvoices(true);
                    },
                  },
                  {
                    label: "Send Invoice",
                    icon: "paper-plane",
                    onClick: () => resendInvoice(),
                  },
                ]}
              />
            );
          }}
          ifAbsent={() => <Loader />}
        />
      </td>
      <ModalForm
        title={"Update Invoice no."}
        className="w-full max-w-sm"
        childrenClassName="px-6 py-2 pb-6 "
        visible={isEditInvoices}
        setVisible={setIsEditInvoices}>
        <>
          <EditInvoice
            communityId={communityId}
            userSubscriptionId={plan.id}
            invoiceNumber={`${plan.invoiceDetails.invoiceNumber ?? ""}`}
            invoicePrefix={`${plan.invoiceDetails.invoicePrefix ?? ""}`}
            setVisible={setIsEditInvoices}
            onPlanUpdate={onPlanUpdate}
          />
        </>
      </ModalForm>
    </tr>
  );
}
export function LogsListTile({ plan, subscription, communityId }: Props) {
  const subscriber = plan.user;
  const subscriptionInfo = plan.billingDetails;
  const isRecurring = subscription.isRecurring;
  const purchasedDate = new Date(plan.createdAt);
  const interval = subscription.recurringInterval;
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  // Find the plan renewal date
  var renewalDate = new Date(purchasedDate);
  if (isRecurring && interval) {
    renewalDate.setMonth(renewalDate.getMonth() + interval);
  }

  const locale = useMemo(() => getLang(), []) ?? "en-US";

  function getLang() {
    if (navigator.languages !== undefined) return navigator.languages[0];
    return navigator.language;
  }
  // Resend invoice
  function resendInvoice() {
    setIsLoading(true);
    SubscriptionService.resendInvoice(communityId, plan.id)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        toast.addToast("Invoice sent successfully", "");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.addToast("Unable to resend invoice", "", ToastTypes.danger);
      });
  }

  return (
    <tr className="cursor-pointer border border-border text-sm text-secondary-foreground/80 hover:bg-background">
      <td className="p-1 py-6 md:px-4">
        {plan.invoiceDetails.invoicePrefix}
        {plan.invoiceDetails.invoiceNumber}
      </td>
      <td className="p-1 md:px-4">
        <Label variant="t1">{subscriber.name ?? "N/A"}</Label>
        <br />
        <Label
          size="caption"
          className={cx("", {
            hidden: !subscriber.email && !subscriber.mobile,
          })}>{`${subscriber.email} | ${subscriber.mobile}`}</Label>
      </td>
      <td className="p-1 md:px-4">{subscriptionInfo.state ?? "N/A"}</td>

      <td className="p-1 md:px-4">{subscriptionInfo.country ?? "N/A"}</td>

      <td className="p-1 md:px-4">
        {purchasedDate
          ? purchasedDate.toLocaleDateString(locale, {
              year: "numeric",
              month: "2-digit",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
          : "N/A"}
      </td>

      <td className="p-1">
        <Fold
          value={!isLoading}
          ifPresent={(): JSX.Element => {
            return (
              <IconMenu2
                actions={[
                  {
                    label: "Download Invoice",
                    icon: "download",
                    onClick: () => {
                      window.open(
                        `${apiEndpointBase}community/${communityId}/invoice-download/${plan.id}`,
                        "_blank"
                      );
                    },
                  },

                  {
                    label: "Re send Invoice",
                    icon: "paper-plane",
                    onClick: () => resendInvoice(),
                  },
                ]}
              />
            );
          }}
          ifAbsent={() => <Loader />}
        />
      </td>
    </tr>
  );
}
