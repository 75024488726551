import cx from "classnames";
import { useEffect, useState } from "react";
import useLang from "../hooks/use-lang.hook";
import { cn } from "./button.component";
import { FormError } from "./form-error.component";

function TextInput({
  name = "",
  placeholder = "",
  readOnly = false,
  disabled = false,
  noBorder = false,
  inputPattern = RegExp(""),
  prefix = null,
  noPrefixSeparator = false,
  postfix = null,
  defaultValue = "",
  noMargin = false,
  large = false,
  type = "text",
  minLength = 0,
  required = false,
  inputHolderClassName = "",
  containerClassName = "",
  className = "",
  errors = [],
  validator = (value) => {},
  validationOutsideBox = false,
  onChange = (value) => {},
  onFocus = (value) => {},
}) {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(null);
  const lang = useLang();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setError(errors[0]);
    }
  }, [errors]);

  return (
    <>
      <div
        className={cn(
          cx(
            "TextInput",
            {
              "my-3": !noMargin,
              "w-full": noBorder,
            },
            containerClassName
          )
        )}>
        <div
          className={cn(
            cx(
              "",
              {
                "flex items-center rounded border border-border": !noBorder,
                "flex items-center": noBorder,
              },
              inputHolderClassName
            )
          )}>
          {/* show divider and prefix in case of prefix */}
          {prefix ? (
            <>
              {prefix} {noPrefixSeparator ? "" : "|"}
            </>
          ) : (
            <></>
          )}
          <input
            name={name}
            type={type}
            disabled={disabled}
            minLength={minLength}
            onChange={(event) => {
              // validate the values
              setError(lang.trans(validator(event.target.value)));
              // set the value in local state
              if (!inputPattern || inputPattern.test(event.target.value)) {
                setValue(event.target.value);
              }
              // trigger onchange
              onChange(event.target.value);
            }}
            onFocus={(event) => {
              // trigger onfocus
              onFocus(event.target.value);
            }}
            placeholder={lang.trans(placeholder)}
            value={value}
            readOnly={readOnly}
            required={required}
            className={cn(
              cx(
                "w-full rounded bg-transparent focus:outline-none",
                {
                  "px-2 py-2": large,
                  "px-2 py-1": !large,
                },
                className
              )
            )}
          />
          {/* show postfix in case of postfix */}
          {postfix ? postfix : <></>}
        </div>
        {validationOutsideBox ? <></> : <FormError error={error} />}
      </div>
      {!validationOutsideBox ? <></> : <FormError error={error} />}
    </>
  );
}

export { TextInput };
