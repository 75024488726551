import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

const liveCallEndpoints = {
  getAllByCommunity: (communityId) =>
    base + `community/${communityId}/live-call`,
  createLiveCall: (communityId) => base + `community/${communityId}/live-call`,
};

export default liveCallEndpoints;
