import JoinButton from "../../../../_pages/group/group-join-button.component";
import I18 from "../../../atoms/i18";

/**
 * @description - Display join group button and message
 * @param(object) user - user object
 * @param(object) group - group object
 * @param(function) setGroup - Callback function to set group
 */
export default function JoinGroupView({ group, setGroup, user }) {
  return (
    <div className="no-post my-16 p-2 text-center">
      {group.groupType === "open" || group.joinStatus === "joined" ? (
        // Open group or joined group
        <>
          <div className="my-3 font-bold">
            <I18>Looks like it's lonely here.</I18>
          </div>
          <div>
            <I18>Try exploring other Channels and groups.</I18>
          </div>
        </>
      ) : group.groupType === "closed-paid" ? (
        // Closed Paid Group
        <div className="flex flex-col items-center space-y-6">
          <div className="my-3 font-bold">
            Please proceed with the payment or contact admin to gain access to
            this group.
          </div>

          <JoinButton group={group} setGroup={setGroup} user={user} />
        </div>
      ) : (
        // Closed Free Group
        <div className="flex flex-col items-center space-y-6">
          <div className="my-3 font-bold">
            <I18>This is a closed group You'll need access to this group.</I18>
          </div>
          <div className="my-4">
            <JoinButton group={group} setGroup={setGroup} user={user} />
          </div>
        </div>
      )}
    </div>
  );
}
