import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  getAllEvents: (communityId, date) =>
    base + `community/${communityId}/events/${date}`,
  getEventDetail: (communityId, eventId) =>
    base + `community/${communityId}/event/${eventId}`,
  getEventDetailBySlug: (communityId, slug) =>
    base + `community/${communityId}/event/slug/${slug}`,
  updateEvent: (communityId, eventId, notifyUser, deleteAll) =>
    base +
    `community/${communityId}/event/${eventId}?notifyUser=${notifyUser}&deleteAll=${deleteAll}`,
  acceptInvitation: (communityId, eventId) =>
    base + `community/${communityId}/event/${eventId}/accept-invitation`,
  updateEventBanner: (communityId, eventId) =>
    base + `community/${communityId}/event/${eventId}/banner`,
  createEvent: (communityId) => base + `community/${communityId}/event`,
  createRecurringEvent: (
    communityId,
    recurringType = null,
    reccurringCount = null,
    recurringEndDate = null
  ) =>
    base +
    `community/${communityId}/event/recurring?recurringType=${recurringType}&recurringCount=${reccurringCount}&recurringEndDate=${recurringEndDate}`,
  updateRSVP: (communityId, eventId) =>
    base + `community/${communityId}/event/${eventId}/update-rsvp`,
  inviteSections: (communityId, eventId) =>
    base + `community/${communityId}/event/${eventId}/invite`,
  upcomingEvents: (communityId, timestamp) =>
    base + `community/${communityId}/upcoming-events?timestamp=${timestamp}`,
  latestGroupEvents: (communityId, groupId) =>
    base + `community/${communityId}/latest-events/${groupId}`,
  previousEvents: (communityId, timestamp) =>
    base + `community/${communityId}/previous-events?timestamp=${timestamp}`,
};
