const constants = {
  COMMUNITY_DEFAULT_COUNTRY_CODE: "COMMUNITY_DEFAULT_COUNTRY_CODE",
  COMMUNITY_DEFAULT_LANGUAGE: "COMMUNITY_DEFAULT_LANGUAGE",
  COMMUNITY_DEFAULT_FONT_LINK: "COMMUNITY_DEFAULT_FONT_LINK",
  COMMUNITY_DEFAULT_FONT_NAME: "COMMUNITY_DEFAULT_FONT_NAME",
  COMMUNITY_PREVENT_USER_PROFILE_UPDATE: "COMMUNITY_PREVENT_USER_PROFILE_UPDATE",
  COMMUNITY_POST_TIMER_LIMIT: "COMMUNITY_POST_TIMER_LIMIT",
  COMMUNITY_REPORT_EMAIL: "COMMUNITY_REPORT_EMAIL",
  COMMUNITY_SHOW_ONBOARDING_LIST: "COMMUNITY_SHOW_ONBOARDING_LIST"
};

export default constants;
