import { CommunityTopic } from "../../types/community-topic/community-topic";
import {
  ADD_TOPIC,
  ARCHIVE_TOPIC,
  DELETE_TOPIC,
  EDIT_TOPIC,
  SET_TOPICS,
} from "./topics.action";

export interface TopicsState {
  topics: CommunityTopic[];
}

const INITIAL_STATE = {
  topics: [],
} as TopicsState;

export function communityTopicsReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_TOPICS:
      return {
        ...state,
        topics: action.obj,
      };
    case ADD_TOPIC:
      return {
        ...state,
        topics: [...state.topics, action.topic],
      };
    case EDIT_TOPIC:
      return {
        topics: state.topics.map((topic: CommunityTopic) =>
          topic.id === action.topic.id ? { ...topic, ...action.topic } : topic
        ),
      };
      case ARCHIVE_TOPIC:
        case ARCHIVE_TOPIC:
          const archivedTopic = state.topics.find(
            (topic: CommunityTopic) => topic.id.toString() === action.topicId.toString()
          );

          if (archivedTopic) {
            archivedTopic.status = archivedTopic.status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED";

            const updatedTopics = state.topics
              .filter((topic: CommunityTopic) => topic.id.toString() !== action.topicId.toString())
              .concat(archivedTopic)
              .sort((a, b) => {
                // Sort by status first ("ACTIVE" before "ARCHIVED")
                if (a.status === "ACTIVE" && b.status === "ARCHIVED") return -1;
                if (a.status === "ARCHIVED" && b.status === "ACTIVE") return 1;
                // If statuses are the same, sort by postsCount in descending order
                return b.postCount - a.postCount;
              });

            return {
              ...state,
              topics: updatedTopics,
            };
          }

          return state;
    case DELETE_TOPIC:
      return {
        topics: state.topics
          ? state.topics.filter(
              (topic: CommunityTopic) =>
                topic.id?.toString() !== action.topicId?.toString()
            )
          : state.topics,
      };
    default:
      return state;
  }
}