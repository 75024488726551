import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ToastTypes } from "../contexts/toastr.context";
import { useAppDispatch } from "../hooks/redux.hook";
import useModel from "../hooks/use-model.hook";
import { useToast } from "../hooks/use-toast.hook";
import { CloseButton } from "../_components/activity/close-button.component";
import { BackArrowIcon } from "../_components/atoms/icons/back-arrow-icon";
import { Avatar } from "../_components/avatar.component";
import { Loader } from "../_components/loader.component";
import { MessageBox } from "../_components/realtime/message-box.component";
import { MessageCard } from "../_components/realtime/message-card.component";
import { MessageListList } from "../_components/realtime/message-list.component";
import MessageListShimmer from "../_components/shimmers/message/message-list-shimmer.component";
import { MessageService } from "../_service/message.service";
import {
  addChatIfNeeded,
  markChatAsRead,
} from "../_store/_actions/direct-message.actions";
import { setUserCounters } from "../_store/_actions/notification.actions";
import { createUserWallPageRoute } from "./user-wall.page";

export const DIRECT_MESSAGE_SIDEBAR = (userId) => "/direct-message/users";

function DirectChatPageComponent({
  user = null,
  socket = null,
  isDirectChatListInitialLoadComplete = false,
  markChatAsRead,
  addChatIfNeeded,
  userCounters,
  userId,
}) {
  // get userId from useParams
  // const { userId } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const { DirectMessageModelState, messageListModelState } = useModel();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1201);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { addToast } = useToast();
  const dispatch = useAppDispatch();

  // post related states
  const [messages, setMessages] = useState([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);

  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const [otherUser, setOtherUser] = useState(false);

  // helps with pagination
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const [noMoreMessages, setNoMoreMessages] = useState(false);

  // socket implementation
  useEffect(() => {
    dispatch(
      setUserCounters(
        userCounters.unreadNotificationsCount,
        0,
        userCounters.unreadSectionNotificationsCount
      )
    );
    if (socket) {
      const messageReceivedListener = (data) => {
        console.log("Socket: message received listener called!", data);
        data.message.createdBy = data.message.sender;
        data.message.description = data.message.message;
        setMessages([...messages, data.message]);
        // smoothscroll
        smoothScroll();

        // reset the notification count to 0, as we are already on chat
        MessageService.markAllMessagesInChatAsRead(user, userId)
          .then(() => {
            // do nothing work, done
            markChatAsRead({ id: userId });
            dispatch(
              setUserCounters(
                userCounters.unreadNotificationsCount,
                0,
                userCounters.unreadSectionNotificationsCount
              )
            );
            console.log("calling mark chat as read", { id: userId });
          })
          .catch((err) => {
            console.log("could not mark user chat as read", err);
          });
      };
      const messageDeletedListener = (data) => {
        // removed the message with data.messageId == message.id
        const newMessages = messages.filter(
          (message) => message.id !== data.messageId
        );
        setMessages(newMessages);
      };
      const messageEditedListener = (data) => {
        console.log("Socket: message edited listener called!", data);
        const newMessages = [...messages];
        const messageIndex = newMessages.findIndex(
          (m) => m.id === data.message.id
        );
        if (messageIndex > -1) {
          newMessages[messageIndex] = data.message;
          newMessages[messageIndex].createdBy = data.message.sender;
          newMessages[messageIndex].description = data.message.message;
          console.log({ newMessages });
          setMessages(newMessages);
          // smoothscroll
          smoothScroll();
        }
      };

      socket.on(
        `direct-message:${userId}:message-received`,
        messageReceivedListener
      );
      socket.on(
        `direct-message:${userId}:message-deleted`,
        messageDeletedListener
      );
      socket.on(
        `direct-message:${userId}:message-edited`,
        messageEditedListener
      );

      // remove all listeners
      return () => {
        socket.off(
          `direct-message:${userId}:message-received`,
          messageReceivedListener
        );
      };
    }
  }, [socket, userId, messages]);

  // to load messages of section
  useEffect(() => {
    // get all the section posts paginated
    if (userId) {
      setIsLoadingMessages(true);
      MessageService.getUserChatTimestampPaginated(user, userId)
        .then(({ messages, otherUser }) => {
          // set post reverse
          setMessages(
            messages
              .map((m) => {
                m.createdBy = m.sender;
                m.description = m.message;
                return m;
              })
              .reverse()
          );
          markChatAsRead({ id: userId });
          setOtherUser(otherUser);
          setIsLoadingMessages(false);
          // messages are less then 10, we have no more messages
          if (messages.length < 10) {
            setNoMoreMessages(true);
          }
          // scroll to bottom
          smoothScroll();
        })
        .catch((error) => {
          console.log({ error });
          setIsLoadingMessages(false);
          addToast("Messages Could not be loaded!", "", ToastTypes.danger);
        });
    }
  }, [user, userId]);

  // isDirectChatListInitialLoadComplete
  useEffect(() => {
    if (otherUser && isDirectChatListInitialLoadComplete) {
      addChatIfNeeded(otherUser);
    }
  }, [otherUser, isDirectChatListInitialLoadComplete]);

  const loadPreviousMessages = () => {
    if (!isLoadingMoreMessages && !noMoreMessages) {
      // if we are not already loading more messages, load more messages
      setIsLoadingMoreMessages(true);
      MessageService.getUserChatTimestampPaginated(
        user,
        userId,
        messages.length > 0 ? messages[0].id : null
      ).then((response) => {
        // if there are no more posts, set so
        if (response.message.length === 0) {
          setNoMoreMessages(true);
        } else {
          // messages are less then 10, we have no more messages
          if (response.messages.length < 10) {
            setNoMoreMessages(true);
          }
          setMessages([
            ...response.messages
              .map((m) => {
                m.createdBy = m.sender;
                m.description = m.message;
                return m;
              })
              .reverse(),
            ...messages,
          ]);
        }
        setIsLoadingMoreMessages(false);
      });
    }
  };

  if (isLoadingMessages) {
    return <MessageListShimmer length={6} />;
  }

  return (
    <div>
      <div className="sticky top-0 z-10 flex items-center justify-between border-b border-border bg-card px-4 py-2">
        <span className="flex items-center gap-2 font-bold">
          <div
            className="group flex cursor-pointer flex-row items-center gap-1 font-semibold"
            onClick={() => {
              messageListModelState.setShowMessageListModel(true);
              DirectMessageModelState.setIdToViewMessage("");
              DirectMessageModelState.setShowDirectMessageModel(false);
            }}>
            <BackArrowIcon className="transition-all group-hover:-translate-x-1" />
            {otherUser && (
              <Link
                to={createUserWallPageRoute(otherUser.id)}
                className="font-semibold text-secondary-foreground">
                <Avatar
                  user={otherUser}
                  size={24}
                  textClassName="hover:text-primary-foreground"
                />
              </Link>
            )}
          </div>
        </span>
        <CloseButton
          onClick={() =>
            DirectMessageModelState.setShowDirectMessageModel(false)
          }
          type="primary"
        />
      </div>

      {/* chat info */}
      {/* chat details */}
      <div
        id="direct-chat-page"
        className="relative flex h-[calc(100vh-110px)] flex-col overflow-y-auto rounded border border-border bg-card pt-14">
        {!isLoadingMoreMessages ? (
          noMoreMessages ? (
            <></>
          ) : (
            <div className="m-3 flex justify-center">
              <button
                onClick={loadPreviousMessages}
                className="rounded-xl border border-border px-4 py-1 text-secondary-foreground/85">
                Load more
              </button>
            </div>
          )
        ) : (
          <div className="m-3 flex justify-center">
            <Loader />
          </div>
        )}
        {!isLoadingMessages && messages.length === 0 ? (
          <>
            <div className="no-post flex flex-grow flex-col items-center justify-center py-2 text-center">
              <div className="flex flex-col items-center justify-center">
                <span className="mb-3 font-semibold">Chat with</span>
                <span className="text-xl font-semibold">
                  {otherUser ? otherUser.name : "this user"}
                </span>
              </div>
            </div>
            <MessageCard
              message={{
                description: "This is a new conversation",
                createdAt: new Date(),
                createdBy: user.name,
              }}
            />
          </>
        ) : isLoadingMessages ? (
          <div className="my-10 flex flex-grow justify-center">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-grow flex-col justify-end pb-14">
            {/* normal posts */}
            <MessageListList
              messages={messages}
              setMessages={(messages) => {
                setMessages(messages);
              }}
              updateMessage={(message) => {
                // get post index
                const messageIndex = messages.findIndex(
                  (p) => p.id === message.id
                );
                const oldMessages = [...messages];
                message.description = message.message;
                message.createdBy = message.sender;
                oldMessages.splice(messageIndex, 1, message);
                setMessages(oldMessages);
              }}
              deleteMessage={(message) => {
                // call the message delete api
                return MessageService.deleteDirectMessage(user, message).then(
                  ({ message }) => {
                    // remove the message from the list
                    const newMessages = messages.filter(
                      (m) => m.id !== message.id
                    );
                    setMessages(newMessages);
                  }
                );
              }}
            />
          </div>
        )}
        <MessageBox
          groupId={null}
          group={null}
          tabId={null}
          otherUserId={userId}
          attachments={selectedAttachments}
          setAttachments={setSelectedAttachments}
          smoothScrollToBottom={smoothScroll}
          addPost={(message) => {
            if (message) {
              // no need to add post as it will be added using event
              // update posts list with new post
              // setPosts([...posts, post]);
              message.createdBy = message.sender;
              message.description = message.message;
              setMessages([...messages, message]);
              smoothScroll();
            }
          }}
        />
      </div>
    </div>
  );
}

function smoothScroll() {
  const sectionDetail = document.getElementById("direct-chat-page");
  if (sectionDetail) sectionDetail.scrollTop = sectionDetail.scrollHeight;
}

const DirectChatPage = connect(
  (s) => ({
    user: s.auth,
    socket: s.socket,
    isDirectChatListInitialLoadComplete: s.directMessages.isInitialLoadComplete,
    userCounters: s.notifications,
  }),
  (d) =>
    bindActionCreators(
      {
        markChatAsRead,
        addChatIfNeeded,
      },
      d
    )
)(DirectChatPageComponent);

export default DirectChatPage;
