import { LiveCall } from "../../types/live-call/live-call.type";
import { LiveCallService } from "../../_service/live-call-service";

export const ADD_LIVE_CALL = "ADD_LIVE_CALL";
export const END_LIVE_CALL = "END_LIVE_CALL";
export const SET_LIVE_CALLS = "SET_LIVE_CALLS";

export const addLiveCall = (liveCall: LiveCall) => ({
  type: ADD_LIVE_CALL,
  payload: liveCall,
});

export const endLiveCall = (liveCall: LiveCall) => ({
  type: END_LIVE_CALL,
  payload: liveCall,
});

export const setLiveCalls = (liveCalls: LiveCall[]) => ({
  type: SET_LIVE_CALLS,
  payload: liveCalls,
});

/**
 * Get all current live calls in the community
 */
export const getAllLiveCalls = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }

      const response = await LiveCallService.getLiveCallsByCommunity(
        community.id
      );
      if (response) {
        dispatch(setLiveCalls(response.liveCalls));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
