import { MaxWidthWrapper } from "../max-width-wrapper";
import HeaderShimmer from "./header/header-shimmer.component";
import SidebarShimmer from "./sidebar/sidebar-empty-shimmer.component";

export default function AuthAppShimmer() {
  return (
    <div className="h-screen w-full animate-pulse">
      <div>
        <HeaderShimmer />
      </div>
      <MaxWidthWrapper>
        <div className="AppContainer two-column h-screen w-full">
          <div className="Sidebar">
            <SidebarShimmer />
          </div>
          <div className="flex flex-col space-y-2 px-4 sm:p-0">
            <div className="h-96 w-full bg-card" />
            <div className="flex h-full space-x-2">
              <div className="CommunityPostTab h-full w-full bg-card" />
              <div className="hidden h-full w-1/3 bg-card xl:flex" />
            </div>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}
