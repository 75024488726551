import { createMeetingLink, createMeetingLinkWithToken } from "../../../_pages";
import LiveButton from "../../live-button.component";

/**
 * Show post live status
 * @param {*} param0
 * @returns
 */
export default function PostLiveStatus({ post, user }) {
  const isSharedPost = post.shared != null && post.shared != undefined;
  const { liveCall, scheduledTime } = isSharedPost ? post.shared : post;
  // if meeting is not available, or time is yet to start, return
  if (!liveCall || (scheduledTime && new Date(scheduledTime) > new Date())) {
    return <></>;
  }

  // get meetingId
  const { meetingId } = liveCall;

  // create the link, if live
  if (liveCall.isLive) {
    return (
      <a
        className="text-primary-foreground"
        href={user ? createMeetingLink(meetingId, user.token) : null}
        onClick={(e) => {
          // prevent default action
          e.preventDefault();
          // open the meeting page with user token
          if (user) {
            window.open(createMeetingLinkWithToken(meetingId, user.token));
          }
        }}
        target="_blank"
        rel="noreferrer">
        <LiveButton />
      </a>
    );
  } else {
    return <LiveButton ended={true} />;
  }
}
