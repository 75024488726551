import cx from "classnames";
import UIcon from "../uicon-component";
import I18 from "./i18";

interface TooltipProps {
  children: any;
  label: string;
  align?: "left" | "right" | "top" | "bottom";
  className?: string;
}
function Tooltip({
  children,
  label,
  align = "top",
  className = "",
}: TooltipProps) {
  const rotate = {
    left: "rotate(360deg)",
    right: "rotate(180deg)",
    top: "rotate(90deg)",
    bottom: "rotate(272deg)",
  };

  return (
    <div
      className={`tooltip relative transition duration-150 ease-in-out ${className}`}>
      <div
        className="transition duration-150 ease-in-out"
        aria-haspopup="true"
        aria-expanded="true">
        {children}
      </div>

      <div
        className={cx(
          "label invisible absolute flex transform flex-col items-center opacity-0 transition-all duration-700",
          {
            "bottom-0 left-0 right-0 mb-10 -translate-y-2": align === "top",
            "left-0 right-0 top-0 mt-10 -translate-y-0": align === "bottom",
            "-translate-x-24 -translate-y-9": align === "left",
            "-translate-x-0": align === "right",
          }
        )}>
        <span className="theme-bg-heading-1 theme-text-background whitespace-no-wrap relative z-10 m-2 min-w-max rounded p-2 text-xs leading-none shadow-md delay-1000 ease-linear">
          <I18>{label}</I18>
          <div
            className={cx("absolute flex w-full items-center", {
              "-top-2 left-0 place-content-center": align === "bottom",
              "-bottom-2 left-0 place-content-center": align === "top",
              "-left-1.5 top-0 h-full place-content-start": align === "right",
              "top-0 h-full place-content-end": align === "left",
            })}>
            <div style={{ transform: `${rotate[align]}` }}>
              <UIcon
                icon="play"
                solid={true}
                className="text-secondary-foreground"
              />
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}
export default Tooltip;
