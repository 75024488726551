import { combineReducers } from "redux";
import { communityKarmaPointsReducer } from "./community-karma-points.store/karma-points.reducer";
import { communityLinksReducer } from "./community-links.store";
import { coursesReducer } from "./courses/courses-reducer";
import { highlightedPostsReducer } from "./highlighted-posts.store";
import { liveCallReducer } from "./live-call.store/live-call.reducer";
import { messagesReducer } from "./messages.store";
import { postDetailReducer } from "./post-detail.store";
import { preferenceReducer } from "./preference/preference.reducer";
import { realTimeSectionCountReducer } from "./realtime-section-notification.store/realtime-section-notification.reducer";
import { searchReducer } from "./search.store";
import { sectionsReducer } from "./sections.store";
import { mySubscriptionReducer } from "./subscription/my-subscription/my-subscription.reducer";
import { subscriptions } from "./subscription/subscription-plan.reducer";
import { authReducer } from "./_reducers/auth.reducer";
import { communityReducer } from "./_reducers/community.reducer";
import { communityPacReducer } from "./_reducers/community_pac.reducer";
import { directMessageReducer } from "./_reducers/direct-message.reducer";
import {
  activeGroupReducer,
  groupReducer,
  isLoadingGroupReducer,
} from "./_reducers/group.reducer";
import { notificationCentreReducer } from "./_reducers/notification-centre.reducer";
import { notificationReducer } from "./_reducers/notification.reducer";
import { personalGroupReducer } from "./_reducers/personal-group.reducer";
import { socketReducer } from "./_reducers/socket.reducer";
import { postReducer } from "./_reducers/post.reducer";
import { communityTopicsReducer } from "./community-topics.store/topics.reducer";
import { onboardingChecklistReducer } from "./onboarding-checklist.store/onboarding-checklist.reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  groups: groupReducer,
  isLoadingGroups: isLoadingGroupReducer,
  activeGroup: activeGroupReducer,
  community: communityReducer,
  notifications: notificationReducer,
  personalGroup: personalGroupReducer,
  post: postReducer,
  socket: socketReducer,
  directMessages: directMessageReducer,
  notificationCentre: notificationCentreReducer,
  communityPac: communityPacReducer,
  sectionMessages: messagesReducer,
  search: searchReducer,
  highlightedPosts: highlightedPostsReducer,
  liveCall: liveCallReducer,
  sections: sectionsReducer,
  postDetail: postDetailReducer,
  communityKarmaPoints: communityKarmaPointsReducer,
  onboardingChecklist: onboardingChecklistReducer,
  communityTopics: communityTopicsReducer,
  subscriptionsPlan: subscriptions,
  mySubscription: mySubscriptionReducer,
  realTimeSectionCountReducer: realTimeSectionCountReducer,
  courses: coursesReducer,
  preferenceReducer: preferenceReducer,
  communityLinks: communityLinksReducer,
});
