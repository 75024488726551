import React from "react";
import LazyImage from "../atoms/lazy-image";

/**
 * @description PhotoCard component for UnsplashImagePicker
 * @param {Object} photo - The photo to display.
 * @param {funtion} onGifSelect - Function to call when a photo is selected.
 */
export default function TinorGifCard({
  photo,
  onGifSelect = (_: any) => {},
}: any) {
  const [zoom, setZoom] = React.useState(false);
  return (
    <div
      className="group relative h-60 w-full cursor-pointer place-items-center border border-border object-cover sm:h-44 md:h-32"
      key={photo.id}
      onClick={() => onGifSelect(photo)}>
      <LazyImage
        className="card-img h-full w-full place-items-center rounded object-cover"
        src={photo.media[0].tinygif.url}
        alt={photo.content_description}
      />
      {/* <div
        className="absolute top-0 right-0 left-0 bottom-0 invisible group-hover:visible group-hover:bg-black/20"
        style={{ color: "white" }}>
        <div className="flex space-x-1 items-center place-content-center justify-between m-2">
          <div className="flex items-center space-x-1">
            <img
              className="rounded-full h-6 w-6"
              src={photo.user.profile_image.small}
              alt={photo.user.username}
            />
            <h6 className="text-xs word-breaker">{photo.user.name}</h6>
          </div>
        </div>
      </div> */}
    </div>
  );
}
