import { useCallback, useMemo, useState } from "react";
import { ProfilePageComponent } from "../../_pages/user-wall.page";
import { CloseButton } from "../activity/close-button.component";
import I18 from "../atoms/i18";
import RightSideModal from "../right-side-modal.component";

export function useUserProfileModel() {
  const [showUserProfileSidebar, setShowProfileModel] = useState(false);
  const [userId, setIdToViewProfile] = useState("");

  const UserProfileSidebarCallback = useCallback(() => {
    if (showUserProfileSidebar && !userId) {
      console.error("User Id is required to show user profile sidebar");
      setShowProfileModel(false);
      return <></>;
    }
    return (
      <RightSideModal
        active={showUserProfileSidebar}
        setActive={setShowProfileModel}
        className="mt-2 max-w-2xl rounded-t-2xl bg-card md:mt-0 md:rounded-l-2xl md:rounded-tr-none">
        <div className="py-1-4 sticky top-0 z-10 flex items-center justify-between border-b border-border bg-card px-4">
          <span className="font-bold text-secondary-foreground/80">
            <I18>Profile</I18>
          </span>
          <CloseButton
            onClick={() => setShowProfileModel(false)}
            type="primary"
          />
        </div>
        <div className="h-full flex-1 bg-card">
          <ProfilePageComponent userId={userId} displayedInModel={true} />
        </div>
      </RightSideModal>
    );
  }, [userId, showUserProfileSidebar, setShowProfileModel]);

  return useMemo(
    () => ({
      profileModelState: {
        setShowProfileModel,
        setIdToViewProfile: setIdToViewProfile,
      },
      ProfileSidebarModel: UserProfileSidebarCallback,
      setIdToViewProfile: setIdToViewProfile,
    }),
    [setShowProfileModel, UserProfileSidebarCallback]
  );
}
