import I18 from "../../../_components/atoms/i18";
import { ListView } from "../../../_components/molecule/listview.component";
import ExploreGroupCard from "./explore-group-card";
interface ExploreGroupComponentProps {
  user: any;
  groups: any[];
  setGroup: (group: any) => void;
  isLoadingGroups: boolean;
}
function ExploreGroup({
  user,
  groups = [],
  setGroup = (e) => {},
  isLoadingGroups = false,
}: ExploreGroupComponentProps) {
  return (
    <ListView
      items={groups}
      loading={isLoadingGroups}
      className="m-4 grid grid-cols-2 gap-4 sm:grid-cols-3 xl:grid-cols-4"
      renderItem={(group, index) => (
        <ExploreGroupCard
          key={index}
          group={group}
          setGroup={setGroup}
          user={user}
        />
      )}
      placeholder={
        <div className="m-4 grid grid-cols-2 gap-4 sm:grid-cols-3 xl:grid-cols-4">
          {Array.from({ length: 12 }).map((_, index) => (
            <div
              key={index}
              className="flex aspect-square h-full w-full flex-grow animate-pulse flex-col rounded-lg bg-card">
              <div className="">
                <div className="aspect-video w-full overflow-hidden rounded-t bg-primary-light">
                  <div className="aspect-video rounded-t object-cover" />
                </div>
                <div className="flex flex-col gap-2 p-4">
                  <div className="h-4 w-full rounded bg-accent" />
                  <div className="flex w-full place-content-between items-center">
                    <div className="h-4 w-10 rounded bg-accent" />
                    <div className="h-4 w-10 rounded bg-accent" />
                  </div>
                  <div className="mt-4 h-6 w-full rounded border border-border bg-accent" />
                </div>
              </div>
            </div>
          ))}
        </div>
      }
      noItemsElement={
        <div
          className="no-post my-10 p-2 text-center"
          style={{ minWidth: "476px" }}>
          <div className="my-3 font-bold">
            <I18>Its empty here</I18>
          </div>
          <div>
            <I18>
              Create a group if you are admin, or wait for a group to be added.
            </I18>
          </div>
        </div>
      }
    />
  );
}

export default ExploreGroup;
