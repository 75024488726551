import { SVGProps } from "react";

export function ThumbUpIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.5"
      {...props}>
      <path
        fill={props.fill || "currentColor"}
        d="M2 9h3v12H2a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1m5.293-1.293l6.4-6.4a.5.5 0 0 1 .654-.047l.853.64a1.5 1.5 0 0 1 .553 1.57L14.6 8H21a2 2 0 0 1 2 2v2.104a2 2 0 0 1-.15.762l-3.095 7.515a1 1 0 0 1-.925.619H8a1 1 0 0 1-1-1V8.414a1 1 0 0 1 .293-.707"></path>
    </svg>
  );
}
