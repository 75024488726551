import { useState } from "react";
import { useAppService } from "../../hooks/use-app-service";
import { useToast } from "../../hooks/use-toast.hook";
import { Button, FormError, TextInput } from "../../_components";
import I18 from "../../_components/atoms/i18";
import CropImageComponent from "../../_components/image-crop/crop-image-component";
import UploadProgressBar from "../../_components/upload-progress-bar.component";
import { CourseService } from "../../_service";

/**
 * Create new course
 * @param {import("../../types/course/course.type").Course} course  course to be updated. If not provided, a new course will be created
 * @param {object} community the community
 * @param {Function} setVisible Callback to set the visibility of the modal
 * @param {Function} onCourseCreated Callback to be called when the course is created
 * @param {Function} onCourseUpdated Callback to be called when the course is updated
 */
export default function CreateCourse({
  course: courseToUpdate,
  community,
  setVisible = () => {},
  onCourseCreated = (_) => {},
  onCourseUpdated = (_) => {},
}) {
  const { analyticsService } = useAppService();

  const [title, setTitle] = useState(courseToUpdate?.title || undefined);

  const [error, setError] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const { addToast, ToastTypes } = useToast();

  const [image, setImage] = useState(courseToUpdate?.banner || undefined);

  const [coverPictureUploadPercent, setCoverPictureUpload] = useState(0);

  const [isPictureRemoved, setIsPictureRemoved] = useState(false);

  const handleCreateCourse = (e) => {
    e.preventDefault();

    if (!title || title.length === 0) {
      setError({ title: "Course title is required" });
      return;
    }

    // Use faker.js to generate random data
    const payload = {
      title: title,
    };
    setIsLoading(true);
    console.log("payload", payload);
    CourseService.createCourse(community.id, payload, courseToUpdate?.id)
      .then(async ({ course }) => {
        if (courseToUpdate) {
          await handleUploadBanner(course, (course) => onCourseUpdated(course));
          addToast("Course updated successfully");
        } else {
          await handleUploadBanner(course, (course) => onCourseCreated(course));
          addToast("Course created successfully");
        }
      })
      .catch((error) => {
        if (error?.errors?.title && Array.isArray(error.errors.title)) {
          setError({ title: error.errors.title[0] });
        }
        addToast("Error creating course", "", ToastTypes.danger);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  async function handleUploadBanner(course, onCourseUpdated = (_) => {}) {
    // Restrict image to upload if
    // 1. Image is not removed
    // 2. Image is not defined
    // 3. Image is a string | url
    if ((!image || typeof image === "string") && !isPictureRemoved) {
      onCourseUpdated(course);
      return;
    }
    CourseService.uploadCourseBanner(community.id, course.id, image, (e) => {
      setCoverPictureUpload(e);
    })
      .then(({ course }) => {
        // addToast("Course banner uploaded successfully");
        onCourseUpdated(course);
      })
      .catch(({ errors }) => {
        addToast("Error", "Unable to upload banner", ToastTypes.danger);
        console.log(errors);
      });
  }

  return (
    <div className="min-w-full max-w-lg">
      <div id="content-4a" className="flex flex-col space-y-12">
        <div className="flex flex-col space-y-2">
          <CropImageComponent
            picture={image}
            setPicture={setImage}
            aspectRatio={16 / 6}
            recommendedRatio={"16:6"}
            setIsPictureRemoved={setIsPictureRemoved}
            cropRatioMessage="Picture ratio: 16:6"
            displayUnsplashSearch
            initialPhotoSearchQuery="Course"
          />
          <UploadProgressBar percentComplete={coverPictureUploadPercent} />
          {/* <!-- Title --> */}

          <form
            onSubmit={handleCreateCourse}
            className="flex flex-col space-y-1">
            <h3 className="pt-5 after:ml-0.5 after:text-red-500 after:content-['*']">
              <I18>Course Name</I18>
            </h3>
            <TextInput
              placeholder="Enter course name"
              className="bg-background"
              containerClassName="bg-background"
              defaultValue={title}
              required={true}
              onChange={(value) => {
                setTitle(value);
                if (value.length > 60) {
                  setError({
                    ...error,
                    title: "Course title should be less then 80 characters",
                  });
                } else {
                  setError({
                    ...error,
                    title: "",
                  });
                }
              }}
              large
              noMargin
            />
            <FormError error={error.title} />
          </form>
        </div>

        {/* <!-- Add course button --> */}

        <div className="flex place-content-between">
          <Button
            label="Cancel"
            flat
            onClick={(e) => {
              e.preventDefault();
              setVisible(false);
            }}
          />
          <Button
            label={courseToUpdate ? "Update" : "Create"}
            onClick={(e) => {
              analyticsService.track("new-course-created");
              handleCreateCourse(e);
            }}
            disabled={error.title || error.description}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
