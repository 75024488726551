import moment from "moment";

/**
 * @param {Date} createdAt - input format: 2024-04-09T13:29:25.447+00:00
 * @return in shorter time stamps like 1s ago 2m ago 2d ago 1y ago....
 */
export const formatTime = (createdAt: moment.MomentInput) => {
  const now = moment();
  const createdMoment = moment(createdAt);

  const diffInSeconds = now.diff(createdMoment, "seconds");
  const diffInMinutes = now.diff(createdMoment, "minutes");
  const diffInHours = now.diff(createdMoment, "hours");
  const diffInDays = now.diff(createdMoment, "days");
  const diffInWeeks = now.diff(createdMoment, "weeks");
  const diffInMonths = now.diff(createdMoment, "months");
  const diffInYears = now.diff(createdMoment, "years");

  if (diffInYears >= 1) {
    return `${diffInYears}y ago`;
  } else if (diffInMonths >= 1) {
    return `${diffInMonths}mo ago`;
  } else if (diffInWeeks >= 1) {
    return `${diffInWeeks}w ago`;
  } else if (diffInDays >= 1) {
    return `${diffInDays}d ago`;
  } else if (diffInHours >= 1) {
    return `${diffInHours}h ago`;
  } else if (diffInMinutes >= 1) {
    return `${diffInMinutes}m ago`;
  } else {
    return `${diffInSeconds}s ago`;
  }
};

export function formatDate(createdAt: moment.MomentInput) {
  if (!createdAt) return "";

  return moment(createdAt)
    .fromNow()
    .replace(" days ago", "d ago")
    .replace("an hour ago", "1h ago")
    .replace(" hours ago", "h ago")
    .replace(" weeks ago", "w ago")
    .replace(" minutes ago", "m ago")
    .replace("a minute ago", "1m ago")
    .replace(" seconds ago", "s ago")
    .replace(" months ago", "mo ago")
    .replace(" years ago", "y ago");
}
