import React, { useState } from "react";
import { connect } from "react-redux";
import { history } from "../../_config";
// import { COMMUNITY_SUBSCRIPTION_PAGE_ROUTE } from "../../_pages/community-settings/community-subscription.page/index";
import { COMMUNITY_SUBSCRIPTION_PAGE_ROUTE } from "../../_pages/community-settings/community-subscription.page/billing-page-v2";
import { CommunityService } from "../../_service";
import { ActionModal } from "../action-modal.component";

function ExpiredActionPreventorComponent({
  community,
  checkForNonAdmin = false,
  children,
}) {
  const actionNeedsToBePrevented =
    CommunityService.checkIfCommunityActionShouldBeDisabled(
      community,
      checkForNonAdmin
    );

  const [showPlanSelector, setShowPlanSelector] = useState(false);

  if (!actionNeedsToBePrevented) return children;

  // remove onclick from children prop
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      onClick: () => {
        setShowPlanSelector(true);
      },
    });
  });

  return actionNeedsToBePrevented ? (
    <span className="ExpiredActionPreventor">
      {/* <div className="preventor-overlay" onClick={e => {
                // check if user is community admin and the community is expired
            }}></div> */}
      {childrenWithProps}
      <ActionModal
        active={showPlanSelector}
        setActive={setShowPlanSelector}
        title="Your plan has expired! Please Upgrade your community to paid plan."
        header="Plan Expired!"
        onSubmit={(e) => {
          history.push(COMMUNITY_SUBSCRIPTION_PAGE_ROUTE);
        }}
      />
    </span>
  ) : (
    children
  );
}

const ExpiredActionPreventor = connect((s) => ({
  community: s.community,
}))(ExpiredActionPreventorComponent);

export default ExpiredActionPreventor;
