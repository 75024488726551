export function eventTimeV2(dateString) {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM or PM
  const period = hours < 12 ? "am" : "pm";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Pad single digit minutes with leading zero
  const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export function formatEventDate(dateString) {
  const date = new Date(dateString);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const dayOfMonth = date.getDate();

  return `${dayOfWeek}, ${month} ${dayOfMonth}`;
}

export function formatMonthAndDay(dateString) {
  const date = new Date(dateString);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthAbbreviation = months[date.getMonth()];
  const dayOfMonth = date.getDate();

  return { monthAbbreviation, dayOfMonth };
}

export function formatEventDetailsDate(dateString) {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  let diffInMilliseconds = currentDate - givenDate;

  // If the given date is in the future, reverse the subtraction
  if (diffInMilliseconds < 0) {
    diffInMilliseconds = givenDate - currentDate;
  }

  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  const remainingHours = diffInHours % 24;
  const remainingMinutes = diffInMinutes % 60;

  return `${diffInDays}d ${remainingHours}h ${remainingMinutes}m`;
}
