import cx from "classnames";
import { SendIcon } from "../atoms/icons/send-icon";
import { cn } from "../button.component";
import { Loader } from "../loader.component";

interface Props {
  isLoading: boolean;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    Props {}

/**
 * SendButton Component
 */

export function SendButton(props: ButtonProps) {
  return (
    <button
      {...props}
      onClick={(e) => {
        if (props.disabled || props.isLoading) return;
        props.onClick && props.onClick(e);
      }}
      className={cn(
        cx(
          "group peer rounded-full border border-border bg-primary text-primary-foreground transition-all duration-200 ease-in-out active:scale-95",
          "disabled:scale-100 disabled:cursor-not-allowed disabled:border-transparent disabled:bg-secondary-foreground/20 disabled:text-secondary-foreground/60 disabled:hover:scale-100",
          {
            "border-primary text-primary-foreground": props.disabled,
            "text-primary-foreground": !props.disabled,
            "animate-spin": props.isLoading,
          }
        ),
        props.className
      )}>
      {props.isLoading ? (
        <Loader spinnerColor="var(--theme-on-primary-color)" size={20} />
      ) : (
        <SendIcon
          className={cx(
            "transition-all duration-200 ease-in-out group-hover:scale-125 group-active:scale-95",
            "group-disabled:hover:scale-100",
            {
              "animate-spin": props.isLoading,
            }
          )}
        />
      )}
    </button>
  );
}
