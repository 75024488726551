import cx from "classnames";
const SAMPLE_VALUE_FORMAT = {
  label: "Options",
  disabled: false,
  value: "option1",
};
/**
 * @description Drop down component
 * @param {Array<Object>} options - List of options. Each option should be an object with label. for ex: {label: "Option 1" }
 * @param {string} value - The default value of the drop down. Should be a object with label. for ex: {value: "option1"}
 * @param {Function} onChange - Callback function to handle change event
 * @param {boolean} disabled - Boolean to determine if the drop down is disabled
 * @param {string} className - The class name of the drop down
 */
function DropDown({
  options = [],
  placeHolder = "Please select an option",
  value = SAMPLE_VALUE_FORMAT,
  className = "",
  disabled = false,
  onChange = () => {},
}) {
  return (
    <div
      className={`customDropdown relative z-10 inline-block text-left ${className}`}>
      <button
        className={cx(
          "inline-flex w-full items-center justify-between space-x-1 rounded-md border border-border px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out",
          {
            "theme-bg-disable cursor-not-allowed text-secondary-foreground/80":
              disabled,
            "focus:shadow-outline-blue bg-card text-secondary-foreground hover:text-secondary-foreground/85 focus:border-primary focus:outline-none":
              !disabled,
          }
        )}
        type="button"
        disabled={disabled}
        aria-haspopup="true"
        aria-expanded="true">
        <span className="text-left">{value?.label ?? placeHolder}</span>
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"></path>
        </svg>
      </button>

      <div className="dropdown-menu invisible origin-top-right -translate-y-2 scale-95 transform opacity-0 transition-all duration-300">
        <div
          className="absolute right-0 z-50 mt-2 origin-top-right divide-y rounded-md border border-border bg-card shadow-lg outline-none"
          role="menu">
          {options.map((option, index) => {
            return (
              <div
                key={index}
                className={cx("flex border-border", {
                  "cursor-not-allowed": option.disabled,
                })}
                role={"menuitem"}>
                <button
                  //   key={index}
                  disabled={option.disabled}
                  onClick={(e) => {
                    onChange(option);
                    e.currentTarget.blur();
                  }}
                  className={cx(
                    "flex flex-grow items-center px-4 py-2 text-left",
                    {
                      "theme-bg-disabled cursor-not-allowed text-secondary-foreground/70":
                        option.disabled,
                      "hover:bg-theme-primary-lightest cursor-pointer hover:bg-background":
                        !option.disabled,
                    }
                  )}>
                  <span className="text-sm font-medium">{option.label}</span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default DropDown;
