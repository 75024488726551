import { SET_ONBOARDING_CHECKLIST_DETAILS, SET_ONBOARDING_CHECKLIST_DISMISS, SET_SELECTED_PARENT_ONBOARDING_SECTION } from "./onboarding-checklist.action";

export interface OnboardingChecklistState {
  showOnboardingList: boolean | null;
  selectedOnboardingSection: string;
  onboardingChecklistDetails: any;
  showAllDonePopup: boolean;
}

type CommunitySettings = {
  communityLogo?: boolean;
  communityTheme?: boolean;
  communityFont?: boolean;
  communityBanner?: boolean;
};

type GroupSettings = {
  createGroup?: boolean;
  createChannel?: boolean;
  createQuickLinks?: boolean;
};

type InviteSettings = {
  inviteUsersWithCustomMail?: boolean;
};

// Define a type for the sections using a union of the specific types
export type SectionData = CommunitySettings | GroupSettings | InviteSettings;

// Define a type for the main SUB_SECTIONS_DATA object
export type SubSectionsData = {
  [key: string]: SectionData[];
};


export const PARENT_ONBOARDING_SECTIONS = [
  {
    key: "customiseCommunity",
    label: "Customise Community",
  },
  { key: "setGroupsAndChannels", label: "Set Up Groups & Channels" },
  { key: "inviteUsers", label: "Invite Users" },
];

// Define the data using the SubSectionsData type
export const SUB_SECTIONS_DATA: SubSectionsData = {
  [PARENT_ONBOARDING_SECTIONS[0].key]: [
    { "communityLogo": false },
    { "communityTheme": false },
    { "communityFont": false },
    { "communityBanner": false },
  ],

  [PARENT_ONBOARDING_SECTIONS[1].key]: [
    { "createGroup": false },
    { "createChannel": false },
    { "createQuickLinks": false },
  ],

  [PARENT_ONBOARDING_SECTIONS[2].key]: [{ "inviteUsersWithCustomMail": false }],
};


const INITIAL_STATE = {
  showOnboardingList: null,
  selectedOnboardingSection: PARENT_ONBOARDING_SECTIONS[0].key,
  onboardingChecklistDetails: SUB_SECTIONS_DATA,
  showAllDonePopup: false
} as OnboardingChecklistState;

export function onboardingChecklistReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_ONBOARDING_CHECKLIST_DISMISS:
      return {
        ...state,
        showOnboardingList: action.isDismiss
      };
    case SET_SELECTED_PARENT_ONBOARDING_SECTION:
      return {
        ...state,
        selectedOnboardingSection: action.sectionKey
      }
      case SET_ONBOARDING_CHECKLIST_DETAILS:
        return {
          ...state,
          onboardingChecklistDetails: action.details,
          showAllDonePopup: action.allTrue
        }
    default:
      return state;
  }
}