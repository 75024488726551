import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withToast } from "../../contexts/toastr.context";
import { MessageService } from "../../_service/message.service";
import { store } from "../../_store";
import {
  addSectionMessage,
  getSectionMessages,
  removeSectionMessage,
  updateSectionMessage,
  updateSectionMessageReaction,
} from "../../_store/messages.store";
import { markAsReadRealtimeSectionCount } from "../../_store/realtime-section-notification.store/realtime-section-notification.action";
import { Fold } from "../../_utils/extensions/typescript-utils";
import { Button } from "../button.component";
import { Loader } from "../loader.component";
import { MessageCard } from "../realtime/message-card.component";
import { MessageListList } from "../realtime/message-list.component";
import { RealtimeSectionInfo } from "../realtime/realtime-section-info.component";
import MessageListShimmer from "../shimmers/message/message-list-shimmer.component";
import RealtimeSectionMessageEditor from "./realtime-section-detail/realtime-section-message-editor";
import JoinGroupView from "./section-detail/component/join-group-view.component";
import JoinSectionView from "./section-detail/component/join-section-view.component";

/**
 * @description RealtimeSectionDetail component
 * @param {Object} user - The auth user object
 * @param {Object} group - The active group object
 * @param {Object} socket - The socket object
 * @param {Object} activeTabModel - The active section
 * @param {function} setGroup - Callback to set the active group object
 * @param {function} addToast - Callback to display a toast message
 */
function RealtimeSectionDetailComponent({
  user = null,
  group = null,
  socket = null,
  community,
  activeTabModel = null,
  addToast = (e) => {},
  setGroup = (e) => {},
  // state
  sectionMessages,
  showShareModal,
  // actions
  setShowShareModal,
  addSectionMessage,
  removeSectionMessage,
  updateSectionMessage,
  getSectionMessages,
  updateSectionMessageReaction,
}) {
  const activeTabId = activeTabModel.id;

  //reset to 0 realtime section notification count active tab.
  useEffect(() => {
    store.dispatch(markAsReadRealtimeSectionCount(activeTabId));
  }, [activeTabId]);

  // post related states

  const [selectedAttachments, setSelectedAttachments] = useState([]);

  // helps with pagination
  const {
    messages,
    isLoading: isLoadingMoreMessages,
    isFirstLoading,
    error,
    noMoreMessages,
  } = sectionMessages;

  const haPrimaryPermissions = community?.myRole === "admin";
  const hasSecondaryPermissions = ["admin", "moderator"].includes(group.myRole);
  // socket implementation
  useEffect(() => {
    const messageReceivedListener = (data) => {
      addSectionMessage(data.message);
      if (!data.message.parent) {
        smoothScroll();
      }
    };

    const messageDeletedListener = (data) => {
      removeSectionMessage(data.messageId);
      // smoothscroll
      // smoothScroll();
    };

    const messageEditedListener = (data) => {
      updateSectionMessage(data.message);
    };

    const messageReactionListener = (data) => {
      updateSectionMessageReaction(
        data,
        JSON.parse(localStorage.getItem("pensil.user"))
      );
    };

    socket.on(
      `section:${activeTabId}:message-received`,
      messageReceivedListener
    );
    socket.on(`section:${activeTabId}:message-deleted`, messageDeletedListener);
    socket.on(`section:${activeTabId}:message-edited`, messageEditedListener);
    socket.on(`section:${activeTabId}:message-edited`, messageEditedListener);
    socket.on(
      `section:${activeTabId}:message-reaction`,
      messageReactionListener
    );

    // remove all listeners
    return () => {
      socket.off(
        `section:${activeTabId}:message-received`,
        messageReceivedListener
      );
      socket.off(
        `section:${activeTabId}:message-deleted`,
        messageDeletedListener
      );
      socket.off(
        `section:${activeTabId}:message-edited`,
        messageEditedListener
      );
      socket.off(
        `section:${activeTabId}:message-reaction`,
        messageReactionListener
      );
    };
  }, [socket, activeTabId]);

  useEffect(() => {
    if (!isFirstLoading) {
      smoothScroll();
    }
  }, [isFirstLoading]);

  // to load posts of section
  useEffect(() => {
    // get all the section posts paginated
    if (
      activeTabId &&
      group.joinStatus === "joined" &&
      (!activeTabModel.isClosed || activeTabModel.isJoined)
    ) {
      getSectionMessages(group.id, activeTabId, true);
      // addToast("Messages Could not be loaded!", "", ToastTypes.danger);
    }
  }, [user, group.id, activeTabId]);

  let isUserAllowedToPost =
    group.joinStatus === "joined" &&
    (activeTabModel
      ? activeTabModel.isClosed && !activeTabModel.isJoined
        ? false
        : activeTabModel.postLevel === "admin"
          ? ["admin", "moderator"].includes(group.myRole)
            ? true
            : false
          : true
      : true);

  const loadPreviousMessages = () => {
    if (!isLoadingMoreMessages && !noMoreMessages) {
      // if we are not already loading more messages, load more messages
      getSectionMessages(group.id, activeTabId);
    }
  };

  if (isFirstLoading) {
    return (
      <div className="order border-border bg-card">
        <MessageListShimmer length={6} />
      </div>
    );
  }

  return (
    <div className="RealtimeSectionDetailComponent flex flex-col">
      <RealtimeSectionInfo
        group={group}
        setGroup={setGroup}
        messages={sectionMessages}
        user={user}
        activeTabModel={activeTabModel}
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
      />
      <div
        id="realtime-section-detail"
        className="relative flex h-[calc(100vh-110px)] flex-col overflow-y-auto border-t border-border bg-card">
        <Fold
          value={isLoadingMoreMessages ? null : true}
          ifPresent={() => (
            <>
              <Fold
                value={
                  !noMoreMessages &&
                  (haPrimaryPermissions ||
                    hasSecondaryPermissions ||
                    (user &&
                      activeTabModel.isClosed &&
                      activeTabModel.isJoined))
                    ? true
                    : false
                }
                ifPresent={() => (
                  <div className="m-3 flex justify-center">
                    <Button
                      flat
                      label="Load more"
                      onClick={loadPreviousMessages}
                    />
                  </div>
                )}
                ifAbsent={() => <></>}
              />
            </>
          )}
          ifAbsent={() => (
            <div className="m-3 flex justify-center">
              <Loader />
            </div>
          )}
        />

        {!isFirstLoading && messages.length === 0 ? (
          activeTabModel.isClosed && !activeTabModel.isJoined ? (
            // Section is closed && user is not joined
            <JoinSectionView
              user={user}
              group={group}
              setGroup={setGroup}
              activeTabModel={activeTabModel}
            />
          ) : isUserAllowedToPost ? (
            <>
              <div className="no-post flex flex-grow flex-col items-center justify-center py-2 text-center">
                <div className="flex flex-col items-center justify-center">
                  <span className="mb-3 font-semibold">Welcome to</span>
                  <span className="text-xl font-semibold">
                    {activeTabModel.emoji} {activeTabModel.name}
                  </span>
                </div>
              </div>
              <MessageCard
                message={{
                  description: "Created this channel",
                  createdAt: group.createdAt,
                  createdBy: group.createdBy,
                }}
                activeTabModel={activeTabModel}
              />
            </>
          ) : (
            <JoinGroupView user={user} group={group} setGroup={setGroup} />
          )
        ) : isFirstLoading ? (
          <div className="my-10 flex justify-center">
            <Loader />
          </div>
        ) : (
          <MessageListList
            messages={messages}
            group={group}
            activeTabModel={activeTabModel}
            isUserAllowedToPost
            setMessages={(posts) => {}}
            deleteMessage={(message) => {
              return MessageService.deleteMessage(user, message);
            }}
          />
        )}
        {isUserAllowedToPost && (
          <div className="RealtimeCreatePostCard absolute bg-card px-5 py-2.5">
            <RealtimeSectionMessageEditor
              community={community}
              user={user}
              groupId={group.id}
              group={group}
              activeTab={activeTabModel}
              tabId={activeTabId ?? activeTabModel.id}
              attachments={selectedAttachments}
              setAttachments={setSelectedAttachments}
              updateMessage={(message) => {
                updateSectionMessage(message);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function smoothScroll() {
  const sectionDetail = document.getElementById("realtime-section-detail");
  sectionDetail.scrollTop = sectionDetail.scrollHeight;
}

const mapStateToProps = (state) => ({
  socket: state.socket,
  sectionMessages: state.sectionMessages,
  community: state.community,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSectionMessages: getSectionMessages,
      addSectionMessage: addSectionMessage,
      updateSectionMessage: updateSectionMessage,
      removeSectionMessage: removeSectionMessage,
      updateSectionMessageReaction: updateSectionMessageReaction,
    },
    dispatch
  );

const RealtimeSectionDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(withToast(RealtimeSectionDetailComponent));

export default RealtimeSectionDetail;
