import JoinButton from "../../../../_pages/group/group-join-button.component";
import Tooltip from "../../../atoms/tooltip";
import JoinGroupView from "./join-group-view.component";

/**
 * @description - Display join group button and message
 * @param(object) user - user object
 * @param(object) group - group object
 * @param(object) activeTabModel - active section object
 */

export default function JoinSectionView({
  group,
  activeTabModel,
  setGroup,
  user,
}) {
  return (
    <div className="no-post my-10 p-2 text-center">
      {/* If group is closed and not joined */}
      {["closed-free", "closed-paid"].includes(group.groupType) &&
      group.joinStatus !== "joined" &&
      !activeTabModel.isPrivate ? (
        <>
          <JoinGroupView user={user} group={group} setGroup={setGroup} />
        </>
      ) : (
        <>
          {/* user is not joined in the closed group */}

          {(activeTabModel.status === null &&
            // If section is closed and private
            activeTabModel.isClosed &&
            activeTabModel.isPrivate && (
              <div className="my-3">
                <p className="font-bold">This is a private channel</p>
                <p>You&apos;ll need access to this channel</p>
              </div>
            )) ||
            // If section is closed, not joined nor requested
            (activeTabModel.isClosed &&
              !activeTabModel.isJoined &&
              activeTabModel.status !== "requested" && (
                <div>
                  <div className="no-post my-3 flex flex-col items-center">
                    <p className="font-bold">This is a closed channel</p>
                    <p className="mb-10">
                      You&apos;ll need access to this channel
                    </p>
                    {/* Hide Join group button if group status is joined */}
                    {group.joinStatus !== "joined" && (
                      <Tooltip label="Join group">
                        <JoinButton
                          group={group}
                          setGroup={setGroup}
                          user={user}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}

          {/*  User requested to access closed group*/}
          {activeTabModel.status === "requested" && (
            <div className="no-post my-3 text-secondary-foreground">
              <p className="font-bold">Your request is waiting approval.</p>
              <p>
                Once your request is approved, you will be able to interact
                here.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
