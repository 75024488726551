import React, { Suspense, useEffect } from "react";
const Picker = React.lazy(() => import("emoji-picker-react"));

export default function EmojiPicker({
  placeholder = "#",
  hidePlaceholder = false,
  emoji,
  setEmoji,
  visible = false,
  setVisible = (e) => {},
}) {
  const [theme, setTheme] = React.useState("light");
  useEffect(() => {
    // get theme
    const theme = localStorage.getItem("pensil.theme");
    setTheme(theme ?? "light");
  }, []);

  return (
    <div
      className="EmojiPicker relative"
      onBlur={(e) => {
        // hide on blur
        // if (visible) {
        //     setVisible(false);
        // }
      }}>
      {hidePlaceholder ? null : (
        <span
          onClick={(e) => {
            // toggle visiblity of picker
            if (!visible) {
              setVisible(true);
            }
          }}
          className="cursor-pointer px-2 py-1 text-center">
          {emoji ? emoji : placeholder}
        </span>
      )}
      {/* actual picker container */}
      {visible ? (
        <div
          className="picker-container"
          style={{
            position: "absolute",
            zIndex: "100",
          }}>
          <Suspense fallback={<></>}>
            <Picker
              onEmojiClick={(emojiObject, event) => {
                setEmoji(emojiObject.emoji);
                console.log(emojiObject.emoji);
                setVisible(false);
              }}
              disableAutoFocus={true}
              theme={theme}
              skinTonesDisabled={true}
              groupNames={{ smileys_people: "PEOPLE" }}
              native
            />
          </Suspense>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
