import cx from "classnames";
import { cn } from "../../button.component";
import MessageTileShimmer from "./user-tile.shimmer";

/**
 * UserListShimmer
 * @param {*} length
 */
function UserListShimmer({
  length,
  className,
  tileClassName,
}: {
  length?: number;
  className?: string;
  tileClassName?: string;
}) {
  const list = [];

  for (let i = 0; i < (length ?? 1); i++) {
    list.push(<MessageTileShimmer key={i} className={tileClassName} />);
  }
  return (
    <div className={cn(cx("mx-auto w-full animate-pulse pt-0", className))}>
      <div className="UserListShimmer flex flex-grow flex-col justify-end py-2">
        {list}
      </div>
    </div>
  );
}

export default UserListShimmer;
