import { useState } from "react";

import plusGrayIcon from "../../_assets/vectors/plus-gray-icon.svg";
import { GroupService } from "../../_service";
import { Button } from "../button.component";
import IconButton2 from "../icon-button-2-component";
import IconButton from "../icon-button.component";
import { Loader } from "../loader.component";
import { Modal } from "../modal.component";
import RazorPay from "../razor-pay.component";
import { TextInput } from "../text-input.component";

/**
 * Popup to trigger payment and use coupons
 * @param {*} param0
 * @returns
 */
export default function PaymentPopup({
  active,
  setActive,
  group,
  user,
  setGroup,
}) {
  const [showCouponSelector, setShowCouponSelector] = useState(false);
  const [code, setCode] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [error, setError] = useState("");
  const [isVerifyingCoupon, setIsVerifyingCoupon] = useState(false);
  // show razor pay
  const [isPaying, setIsPaying] = useState(false);

  const resetFormAndClose = () => {
    setActive(false);
  };

  return (
    <Modal
      active={active}
      setActive={setActive}
      padding={false}
      width="390px"
      className="GroupPaymentPopup text-left text-secondary-foreground/85">
      {/* headers */}
      <div className="flex items-center justify-between bg-card py-2 pl-4 pr-2">
        <span className="font-bold text-secondary-foreground/80">
          Access to {group.name}
        </span>
        <IconButton2
          icon="cross"
          size="md"
          onClick={() => {
            resetFormAndClose();
          }}
        />
      </div>
      {/* body */}
      <div className="p-5">
        {/* payable calculator */}
        {!coupon ? (
          <div className="theme-bg-disable mb-10 rounded-xl px-5 py-3 text-left">
            <div className="mb-2 text-secondary-foreground/80">
              Total Payable
            </div>
            <div className="text-2xl font-bold text-secondary-foreground/85">
              {group.currency ? group.currency : "₹"}{" "}
              {new Intl.NumberFormat("en-IN", {
                maximumSignificatDigits: 3,
              }).format(group.price ? group.price / 100 : 0)}
            </div>
          </div>
        ) : (
          <div className="theme-bg-disable mb-10 rounded-xl text-right">
            <div className="px-5 py-3">
              <div className="flex items-center justify-end">
                <div className="pr-3 text-lg font-semibold text-secondary-foreground/80">
                  Total Payable
                </div>
                <div className="text-xl font-bold text-secondary-foreground/85">
                  {group.currency ? group.currency : "₹"}{" "}
                  {new Intl.NumberFormat("en-IN", {
                    maximumSignificatDigits: 3,
                  }).format(group.price ? group.price / 100 : 0)}
                </div>
              </div>
              <div className="my-2 flex items-center justify-end">
                <div className="pr-3 font-semibold text-secondary-foreground/80">
                  (CODE: {coupon.code})
                </div>
                <div className="text-xl font-bold text-secondary-foreground/85">
                  - {group.currency ? group.currency : "₹"}{" "}
                  {new Intl.NumberFormat("en-IN", {
                    maximumSignificatDigits: 3,
                  }).format(coupon.discount / 100)}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end border-t-4 border-border px-5 py-3">
              <div className="pr-3 text-lg font-semibold text-secondary-foreground/80">
                Net Payable
              </div>
              <div className="text-xl font-bold text-secondary-foreground/85">
                {group.currency ? group.currency : "₹"}{" "}
                {new Intl.NumberFormat("en-IN", {
                  maximumSignificatDigits: 3,
                }).format((group.price - coupon.discount) / 100)}
              </div>
            </div>
          </div>
        )}
        {/* coupon selector */}
        {showCouponSelector ? (
          <div className="mb-10 flex flex-col">
            <div>Enter Coupon Code</div>
            <div className="flex w-full items-center">
              <TextInput
                placeholder="Coupon Code"
                className="py-3"
                defaultValue={code}
                onChange={(value) => {
                  if (error) {
                    setError("");
                  }
                  setCode(value);
                }}
                containerClassName="flex-grow bg-background"
                validator={(value) => {
                  if (/^([a-zA-Z0-9])+$/.test(value)) {
                    return "";
                  } else {
                    return "Coupon invalid!";
                  }
                }}
                postfix={
                  isVerifyingCoupon ? (
                    <div className="mr-3">
                      {" "}
                      <Loader />
                    </div>
                  ) : (
                    <div
                      className="mr-3 cursor-pointer"
                      onClick={(e) => {
                        if (/^([a-zA-Z0-9])+$/.test(code)) {
                          setIsVerifyingCoupon(true);
                          GroupService.verifyToken(user, group.id, code)
                            .then(({ coupon }) => {
                              // check if price oK ?
                              if (group.price - coupon.discount > 99) {
                                // 100 is for 1
                                setCoupon(coupon);
                                setError("");
                              } else {
                                setError("Coupon not applicable here!");
                              }
                              setIsVerifyingCoupon(false);
                            })
                            .catch((error) => {
                              if (
                                error &&
                                error.response &&
                                error.response.data &&
                                error.response.data.message
                              ) {
                                setError(error.response.data.message);
                              } else {
                                setError("Could not apply coupon");
                              }
                              setCoupon(null);
                              setIsVerifyingCoupon(false);
                            });
                        }
                      }}>
                      Apply
                    </div>
                  )
                }
              />
              <IconButton
                icon={plusGrayIcon}
                rotate={45}
                className="ml-2"
                onClick={() => {
                  setShowCouponSelector(false);
                  setCoupon(null);
                  setCode("");
                }}
              />
            </div>
            {/* show coupon */}
            {coupon ? (
              <div className="text-xs font-normal text-green-500">
                Coupon applied!
              </div>
            ) : (
              <></>
            )}
            {/* show error */}
            <div className="text-xs font-normal text-alert">{error}</div>
          </div>
        ) : (
          <></>
        )}
        {/* proceed button */}
        {isPaying ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : (
          <Button
            onClick={(e) => {
              setIsPaying(true);
            }}
            className="w-full"
            label="Proceed"
            large
          />
        )}
        {/* coupon trigger button */}
        {showCouponSelector ? (
          <></>
        ) : (
          <div
            className="mb-3 mt-5 cursor-pointer text-primary"
            onClick={(e) => {
              setShowCouponSelector(true);
            }}>
            Have a coupon?
          </div>
        )}
        {isPaying ? (
          <>
            <RazorPay
              group={group}
              coupon={coupon}
              user={user}
              onCancel={(e) => {
                setIsPaying(false);
              }}
              onError={(error) => {
                console.log({ error });
                setIsPaying(false);
              }}
              onSuccess={(group) => {
                setIsPaying(false);
                setGroup(group);
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
}
