interface FoldProps<T, K> {
  value: T | null | undefined;
  ifPresent: (value: T) => K;
  ifAbsent?: () => K;
}

/**
 * Fold is a utility component that has two callbacks,
 * one that returns actual values if the given value is not null or undefined.
 * The other callback is called if the value is null or undefined.
 * @param value The value to fold
 * @param ifPresent The callback to call if the value is not null or undefined
 * @param ifAbsent The callback to call if the value is null or undefined
 * @returns The result inside the ```ifPresent``` callback
 * @example to use the fold as component
 * <Fold value={null} ifPresent={v => <div>{v}</div>} ifAbsent={() => <div>Nothing</div>} />
 * @example to use the fold as a function
 * Fold(42, v => console.log(v), () => console.log("Nothing"))
 */
export const Fold = <T, K>({ value, ifPresent, ifAbsent }: FoldProps<T, K>) => {
  if (!value || value == null) {
    return ifAbsent?.() ?? null;
  }
  return ifPresent(value);
};

/**
 * FoldRaw is a utility component that has two callbacks,
 * one that returns actual values if the given value is not null or undefined.
 * The other callback is called if the value is null or undefined.
 * @param value The value to fold
 * @param ifPresent The first callback to call if the value is not null or undefined
 * @param ifAbsent The second callback to call if the value is null or undefined
 * @returns The result inside the first ```ifPresent``` callback
 * @example to use the fold as a function
 * FoldRaw(42, v => console.log(v), () => console.log("Nothing"))
 */
export const FoldRaw = <T, K>(
  value: T | null | undefined,
  ifPresent: (value: T) => K,
  ifAbsent: () => K | undefined
) => Fold({ value, ifPresent, ifAbsent });

/**
 * Generate a uuid v4.
 * @example
 * const uuid = Utils.uuidv4();
 * console.log(uuid); // 110ec58a-a0f2-4ac4-8393-c866d813b8d1
 * @returns {string} uuid v4
 */
export function uuidv4(): string {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    (c: string) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r && 0x3) || 0x8).toString(16);
    }
  );
  return uuid;
}

/**
 * Function which takes an list and performs a search on the basis of key and value.
 * @param {Array} list - List of items to be searched.
 * @param {any} searchKey - key on which search will be performed.
 * @param {any} compareValue - return the item if the value of searchKey matches with compareValue.
 * @example
 * const list = [{id: 1, name: 'John'}, {id: 2, name: 'Doe'}];
 * const searchResult = Utils.findInArray(list, 'id', 1);
 * console.log(searchResult); // {id: 1, name: 'John'}
 */
export function findInArray<T extends any>(
  list: T[] | undefined | null,
  searchKey: any,
  compareValue: any
): T | undefined {
  if (!list || list === null) return;
  const searchResult = list.find(
    (item: any) => item[searchKey] === compareValue
  );
  return searchResult;
}

/**
 * Remove an item from an array.
 * @param {Array} list - List of items to be searched.
 * @param {any} searchKey - key on which search will be performed.
 * @param {any} compareValue - return the item if the value of searchKey matches with compareValue.
 * @example
 * const list = [{id: 1, name: 'John'}, {id: 2, name: 'Doe'}];
 * const updatedList = Utils.removeFromArray(list, 'id', 1);
 * console.log(updatedList); // [{id: 2, name: 'Doe'}]
 */
export function removeFromArray<T>(
  list: T[],
  searchKey: any,
  compareValue: any
): T[] {
  if (!list || list === null) {
    return list;
  }
  const updatedList = list.filter(
    (item: any) => item[searchKey] !== compareValue
  );
  return updatedList;
}

/**
 * Update an item from an array.
 * @param {Array} list - List of items to be searched.
 * @param {any} searchKey - key on which search will be performed.
 * @param {any} compareValue - return the item if the value of searchKey matches with compareValue.
 * @param {any} updatedItem - updated item to be replaced with the old item.
 * @example
 * const list = [{id: 1, name: 'John'}, {id: 2, name: 'Doe'}];
 * const updatedList = Utils.updateInArray(list, 'id', 1, {id: 1, name: 'John Doe'});
 * console.log(updatedList); // [{id: 1, name: 'John Doe'}, {id: 2, name: 'Doe'}]
 * @returns {Array} updated list
 */
export function updateInArray<T>(
  list: T[],
  searchKey: any,
  compareValue: any,
  updatedItem: T
): T[] {
  if (!list || list === null) {
    return list;
  }
  const updatedList = list.map((item: any) =>
    item[searchKey] === compareValue ? updatedItem : item
  );
  return updatedList;
}

/**
 * Function to shorten the string. If the string length is greater than the limit, it will be shortened and '...' will be appended in the end or middle.
 * @param {string} text - The text to be shortened.
 * @param {number} limit - The limit to which the text will be shortened.
 * @param {boolean} useEllipsis - If true, '...' will be appended in the end. If false, '...' will be appended in the middle.
 * @example
 * const text = 'This is sentence a very long text';
 * const shortenedText = Utils.shortenText(text, 10, true);
 * console.log(shortenedText); // This is se...
 *
 * @example - 2
 * const text = 'This is sentence a very long text';
 * const shortenedText = Utils.shortenText(text, 14, false);
 * console.log(shortenedText); // This is ... text
 * @returns {string} shortened text
 */
export function shortenText(
  text?: string,
  limit?: number,
  useEllipsis: boolean = true
): string | undefined {
  if (!text) {
    return text;
  }
  if (!limit) {
    return text;
  }
  if (text.length <= limit) {
    return text;
  }
  if (useEllipsis) {
    return text.slice(0, limit) + "...";
  }
  const start = text.slice(0, limit / 2 - 1);
  const end = text.slice(text.length - limit / 2 + 2);
  return start + "[...]" + end;
}

// Function to return points in the format of 1K, 1M, 1B etc.
// @param {Number} points - Points to be formatted.
export const formatNumber = (points: number) => {
  if (points >= 1000000000) {
    return `${(points / 1000000000).toFixed(1)}B`;
  } else if (points >= 1000000) {
    return `${(points / 1000000).toFixed(1)}M`;
  } else if (points >= 1000) {
    return `${(points / 1000).toFixed(1)}K`;
  } else {
    return points;
  }
};
