import { ComponentDisplay } from "../../../component-switcher/component-switcher.component";
import { TextInput } from "../../../text-input.component";

/**
 * Component to display embed code editor
 * @param {boolean} isEmbedEditorVisible - boolean to show embed code editor
 * @param {string} embedCode - embed code to display
 * @param {Function} setEmbedCode - function to set embed code
 * @param {boolean} isVisible - boolean to show embed code editor
 */
export default function PostEmbedCode({
  isEmbedEditorVisible,
  embedCode,
  setEmbedCode,
  embedCodeHeight,
  setEmbedCodeHeight,
  isVisible,
}) {
  if (!isVisible) {
    return null;
  }

  return (
    <ComponentDisplay IsDisplay={isEmbedEditorVisible}>
      <div className="PostEmbedCode pt-4">
        <div className="mb-1 text-secondary-foreground/80">
          Write embeddable code
        </div>
        <textarea
          rows={4}
          type="text"
          value={embedCode}
          onChange={(e) => {
            setEmbedCode(e.target.value);
          }}
          placeholder="Paste your embed code here!"
          className="mt-2 w-full flex-grow rounded border border-border bg-background p-2 focus:outline-none"
        />
        <div className="flex items-center">
          <div className="mb-1 pr-2 text-secondary-foreground/80">
            Embed code height
          </div>
          <TextInput
            containerClassName="flex items-center"
            defaultValue={embedCodeHeight}
            validator={(value) => {
              if (!value.match(/\d/g)) {
                return "Height should be numerical";
              } else {
              }
            }}
            onChange={(value) => {
              setEmbedCodeHeight(value);
            }}
            postfix={<span className="pr-2">px</span>}
          />
        </div>
      </div>
    </ComponentDisplay>
  );
}
