import PostListShimmer, {
  PostTileType,
} from "./post/post-list-shimmer.component";
export { PostTileType } from "./post/post-list-shimmer.component";

/**
 * SectionPostListShimmer
 * @param {*} length
 */
function SectionPostListShimmer({ length }) {
  return (
    //   Header
    <div className="mx-auto flex w-full animate-pulse flex-col gap-4 pt-0">
      <div className="flex justify-between rounded-md border-b bg-card p-6">
        <div className="h-6 w-12 rounded bg-muted/70" />
        <div className="h-6 w-12 rounded bg-muted/70" />
      </div>

      {/* Post Shimmer list */}
      <PostListShimmer
        length={length}
        type={PostTileType.comfortable}
        className={"rounded-md bg-card"}
      />
    </div>
  );
}

export default SectionPostListShimmer;
