import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

const LiveCallService = {
  getLiveCallsByCommunity: async (communityId: string) => {
    const response = await axios.get(
      endpoints.liveCall.getAllByCommunity(communityId),
      {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      }
    );
    return response.data;
  },

  createLiveCall: async (
    communityId: string,
    name: string,
    type: string,
    description: string,
    invitedSections: any[],
    scheduledTime: string,
    banner: Blob | null
  ): Promise<any> => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    formData.append("description", description);
    formData.append("invitedSections", JSON.stringify(invitedSections));
    formData.append("scheduledTime", scheduledTime);
    banner && formData.append("banner", banner);

    const response = await axios.post(
      endpoints.liveCall.createLiveCall(communityId),
      formData,
      {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  },
};

export { LiveCallService };
