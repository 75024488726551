import { useEffect, useState } from "react";
import { ToastTypes } from "../contexts/toastr.context";
import useLang from "../hooks/use-lang.hook";
import { UserService } from "../_service";
import { Button } from "./button.component";
import ModalForm from "./model2";

/**
 * Post create modal
 * @param {object} community
 * @param {Object} user - user object
 * @param {Object} group - Group in which post is being created. If post is being created from community home page, group is undefined.
 * @param {Array<Object>} groups - List of groups of community.
 * @param {string} sectionId - section id of section in which post is being created. If post is being created from community home page, sectionId is undefined.
 * @param {Function} addToast - function to add toast
 * @param {Object} activeProps - object to control visibility of modal
 * @param {Object} displayProps - object to control display of close prompt
 */
export default function BlockModal({
  community = "",
  addToast = null,
  activeProps = null,
  displayProps = null,
  createdById,
  blockCallback,
}) {
  const lang = useLang();

  // Display Blocking modal
  const { active, setActive } = activeProps;
  // Display close prompt
  const { displayClosePrompt, setDisplayClosePrompt } = displayProps;

  const [blockDescription, setBlockDescription] = useState("");

  const blockUser = async () => {
    try {
      const response = await UserService.blockPeer(community, createdById);

      console.log(typeof(response))
      if (response !== null) {
        blockCallback();
        addToast("Blocked successfully", "", ToastTypes.success);
        setBlockDescription("");
        setActive(false);
      } else
        throw new Error("Blocking failed - Contact admin" + response.message);
    } catch (error) {
      console.error("Blocking-error", error);
      addToast("Blocking failed - Contact admin", "", ToastTypes.danger);
    }
  };

  const closeModal = (_) => {
    if (
      blockDescription !== "" &&
      !window.confirm(lang.trans("Are you sure you want to discard Block?"))
    ) {
      return;
    }
    setBlockDescription("");
    setActive(false);
  };

  // Preserve the content if user clicked outside the modal
  // Display close prompt if cancelled or sent
  useEffect(() => {
    if (displayClosePrompt) {
      closeModal(true);
      setDisplayClosePrompt(false);
    }
  }, [displayClosePrompt]);

  return (
    <>
      <ModalForm
        visible={active}
        setVisible={setActive}
        hideHeader
        className="CreatePost max-w-lg w-half flex flex-col bg-card max-h-[95vh] rounded p-5">
        <div className={"flex flex-col mb-3 align-center justify-center w-half"}>
          <p className="flex justify-center text-sm">
            Are you sure you want to block the user?
          </p>
          <p className="flex justify-center text-xs text-gray-500 w-half">
            You’ll not be able to see any post, comment, reply or messages from
            this user. And they will also not be able to see your content.
          </p>
        </div>

        <div className="flex justify-around">
          <Button
            className="my-4 flex-grow mr-2"
            label={"Cancel"}
            btnColor="gray"
            onClick={() => {
              setDisplayClosePrompt(true);
            }}
          />
          <Button
            className="my-4 flex-grow ml-2"
            label={"Yes, Block"}
            btnColor="red"
            onClick={() => {
              blockUser();
            }}
          />
        </div>
      </ModalForm>
    </>
  );
}