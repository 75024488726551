import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withToast } from "../../../contexts/toastr.context";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { useAppService } from "../../../hooks/use-app-service";
import { redirectToAuthUrl } from "../../../_config/helper.config";
import {
  getSectionPinnedPosts,
  getSectionPosts,
  resetNewPostCount,
  setCurrentSectionPosts,
} from "../../../_store/sections.store";
import { Fold } from "../../../_utils/extensions/typescript-utils";
import Validator from "../../../_utils/validator";
import I18 from "../../atoms/i18";
import TextButton from "../../atoms/text-button";
import { Avatar } from "../../avatar.component";
import Filter from "../../filter.component";
import { MaxWidthWrapper } from "../../max-width-wrapper";
import CreatePost from "../../post/create";
import { SelectTopicModal } from "../../post/create/component";
import UIcon from "../../uicon-component";
import JoinGroupView from "./component/join-group-view.component";
import JoinSectionView from "./component/join-section-view.component";
import { SectionInfo } from "./component/section-info.component";
import SectionNewPostMarker from "./component/section-new-post-marker.component";
import SectionPostList from "./component/section-post-list";
import StartDiscussionViewComponent from "./component/start-discussion-view.component";

function SectionDetailComponent({
  user = null,
  group = null,
  community,
  setGroup = (e) => {},
  activeTabModel = null,
  showShareModal = false,
  setShowShareModal = (e) => {},
  addToast = (e) => {},
  updateActiveGroup = (e) => {},
  // state
  section,
  // dispatch
  setPosts,
  getSectionPinnedPosts,
  resetNewPostCount,
}) {
  // pinned posts related
  const {
    pinnedPosts,
    posts,
    currentPage,
    isLoadingPosts,
    isLoadingMorePosts,
    noMorePosts,
    newPostCount,
  } = section;

  const callbackRef = useRef();
  const dispatch = useAppDispatch();
  const { analyticsService } = useAppService();

  const [sortOrder, setSortOrder] = useState("");

  const { topics } = useAppSelector((state) => {
    return {
      topics: state.communityTopics.topics,
    };
  });

  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const [selectedTopicFilter, setSelectedTopicFilter] = useState(null);

  const [filterOptions, setFilterOptions] = useState([
    { label: "All", key: "", icon: "" },
  ]);
  const [sectionTopics, setSectionTopics] = useState([]);

  const removeSelectedCommunityTopicFilter = () => {
    if (selectedTopicFilter) {
      const updatedOptions = filterOptions.filter(
        (option) => option.key !== selectedTopicFilter.key
      );
      setFilterOptions(updatedOptions);
    }
    setSelectedTopicFilter(null);
    setSortOrder(filterOptions[0].key);
  };
  useEffect(() => {
    if (selectedTopicFilter) {
      const optionsWithoutTopic = filterOptions.filter(
        (op) => !op.isCommunityTopic
      );
      setFilterOptions([...optionsWithoutTopic, selectedTopicFilter]);
      setSortOrder(selectedTopicFilter.key);
    }
  }, [selectedTopicFilter]);

  // get all the section posts paginated
  useEffect(() => {
    if (activeTabModel && activeTabModel.id) {
      if (
        (group.groupType === "open" || group.joinStatus === "joined") &&
        (!activeTabModel.isClosed || activeTabModel.isJoined)
      ) {
        setSectionTopics(activeTabModel.topics);
        dispatch(getSectionPosts(group.id, activeTabModel.id, sortOrder));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, group?.id, sortOrder, activeTabModel?.id]);

  // get all the pinned posts
  const groupId = group?.id;
  const activeTabId = activeTabModel?.id;
  const hasSectionAccess =
    (group.groupType === "open" || group.joinStatus === "joined") &&
    (!activeTabModel.isClosed || activeTabModel.isJoined);
  useEffect(() => {
    if (activeTabId && hasSectionAccess) {
      getSectionPinnedPosts(groupId, activeTabId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, hasSectionAccess, activeTabId]);

  let isUserAllowedToPost =
    group.joinStatus === "joined" &&
    (activeTabModel
      ? activeTabModel.isClosed && !activeTabModel.isJoined
        ? false
        : activeTabModel.postLevel === "admin"
          ? group.myRole === "admin" || group.myRole === "moderator"
            ? true
            : false
          : true
      : true);

  // post lazy loading
  useEffect(() => {
    // create callback
    const callBack = () => {
      if (
        window.innerHeight + window.scrollY + 100 >=
        document.body.offsetHeight
      ) {
        // you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMorePosts && !noMorePosts) {
          // if we are not already loading more posts, load more posts
          if (activeTabModel && activeTabModel.id) {
            dispatch(
              getSectionPosts(
                group.id,
                activeTabModel.id,
                sortOrder,
                currentPage + 1
              )
            );
          }
        }
      }
    };

    callbackRef.current = callBack;

    // load posts only if group open or joined by user
    if (
      (group.groupType === "open" || group.joinStatus === "joined") &&
      (!activeTabModel.isClosed || activeTabModel.isJoined)
    ) {
      window.addEventListener("scroll", callBack);

      return () => {
        window.removeEventListener("scroll", callBack);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingMorePosts,
    user,
    currentPage,
    posts,
    group,
    noMorePosts,
    activeTabModel,
  ]);

  if (activeTabModel.isClosed && !activeTabModel.isJoined) {
    return (
      <JoinSectionView
        user={user}
        group={group}
        setGroup={setGroup}
        activeTabModel={activeTabModel}
      />
    );
  }

  const topicOptions = topics?.filter(
    (topic) =>
      topic.postCount > 0 &&
      topic.status === "ACTIVE" &&
      sectionTopics?.includes(topic.id)
  );

  return (
    <main className="SectionDetail">
      <SectionInfo
        group={group}
        setGroup={setGroup}
        user={user}
        sectionId={activeTabModel.id}
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        addPost={(post) => {
          if (post) {
            // update posts list with new post
            setPosts([post, ...posts]);
          }
        }}
        updateActiveGroup={updateActiveGroup}
      />

      <MaxWidthWrapper className="SectionDetail relative max-w-[640px]">
        {user && activeTabModel && isUserAllowedToPost && (
          <CreatePost
            user={user}
            group={group}
            groups={undefined}
            sectionId={activeTabModel.id}
            addPost={(post) => {
              setPosts([post, ...posts]);
            }}
            addToast={addToast}
            community={community}
            inlineDivComponent={
              <>
                <div className="PostList mb-3 w-full cursor-pointer rounded-xl bg-card p-3 shadow-sm">
                  <div className="flex items-center justify-between">
                    <div className="flex w-full items-center">
                      <Avatar
                        user={user}
                        noName
                        className="flex-none"
                        size={45}
                        extraInfo={null}
                      />
                      <div
                        className="flex w-full flex-grow justify-between rounded-full border border-border bg-background px-5 py-2 font-semibold text-secondary-foreground/80"
                        onClick={() => {
                          analyticsService.track(
                            "create-post-input-box-on-homefeed"
                          );
                        }}>
                        <I18>What's on your mind</I18>,{" "}
                        {user.name.split(" ")[0]}?
                        <UIcon icon="pencil" />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        )}
        {/* New Post available nudge */}
        <SectionNewPostMarker
          newPostCount={newPostCount}
          onClick={() => {
            window.removeEventListener("scroll", callbackRef.current);
            // timeout to scroll first
            setTimeout(() => {
              // scroll to top
              window.scrollTo({
                top: 0,
              });
              // when at 0, not call the refresh apis
              setTimeout(() => {
                // reload posts
                getSectionPinnedPosts(groupId, activeTabId);
                getSectionPosts(group.id, activeTabModel.id, sortOrder);
                // reset new posts
                resetNewPostCount(activeTabModel.id);
              }, [500]);
            }, [500]);
          }}
        />
        <Fold
          value={isLoadingPosts || Validator.hasValue(posts)}
          // When there are posts available
          ifPresent={() => (
            <div className="">
              <div className="mb-2 flex items-center gap-2.5 overflow-x-auto md:min-w-[640px] md:max-w-[640px]">
                {topicOptions?.length > 0 && (
                  <Filter
                    selectedOption={sortOrder}
                    setSelectedOption={setSortOrder}
                    options={filterOptions}
                    removeSelectedCommunityTopicFilter={
                      removeSelectedCommunityTopicFilter
                    }
                  />
                )}
                {topicOptions?.length > 0 && !selectedTopicFilter && (
                  <span
                    className="my-1.5 flex w-fit items-center rounded-full border border-border bg-accent px-2 py-1 text-xs hover:cursor-pointer"
                    onClick={() => {
                      setIsTopicModalOpen(true);
                    }}>
                    <span className="text-xs font-semibold text-secondary-foreground">
                      Select Topic
                    </span>
                    <UIcon icon="caret-down" className="ml-1 mt-0.5" />
                    <SelectTopicModal
                      active={isTopicModalOpen}
                      setActive={setIsTopicModalOpen}
                      availableTopics={topicOptions}
                      selectedTopics={[]}
                      setSelectedFilteredTopic={setSelectedTopicFilter}
                    />
                  </span>
                )}
              </div>
              <SectionPostList
                user={user}
                activeTabModel={activeTabModel}
                pinnedPosts={pinnedPosts}
                posts={posts}
                setPosts={setPosts}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                isLoadingPosts={isLoadingPosts}
                isLoadingMorePosts={isLoadingMorePosts}
                noMorePosts={noMorePosts}
                onTopicSelect={(topic) => {
                  for (let i = 0; i < topicOptions?.length; i++) {
                    if (topicOptions[i].id === topic?.id) {
                      setSelectedTopicFilter({
                        key: topicOptions[i].id,
                        label: topicOptions[i].name,
                        bgColor: topicOptions[i].bgColor,
                        textColor: topicOptions[i].textColor,
                        isCommunityTopic: true,
                      });
                      setSortOrder(topicOptions[i].id);
                      break;
                    }
                  }
                }}
              />
            </div>
          )}
          // When there are no posts in the section
          ifAbsent={() =>
            !user ? (
              // If user is not logged in
              // For closed groups, show login button
              ["closed-free", "closed-paid"].includes(group.groupType) ? (
                <>
                  <div className="mt-6 flex flex-col items-center gap-6">
                    <div className="font-bold">
                      <I18>Login to view this section</I18>
                    </div>

                    <TextButton
                      label="Login"
                      onClick={() => {
                        redirectToAuthUrl(community);
                      }}
                    />
                  </div>{" "}
                </>
              ) : (
                // For open groups, show join button
                <JoinGroupView user={user} group={group} setGroup={setGroup} />
              )
            ) : (
              // If user is logged in
              <>
                {/* Display message when no post available */}
                {activeTabModel.isClosed && !activeTabModel.isJoined ? (
                  //If section is closed && user is not joined
                  <JoinSectionView
                    user={user}
                    group={group}
                    setGroup={setGroup}
                    activeTabModel={activeTabModel}
                  />
                ) : isUserAllowedToPost ? (
                  <StartDiscussionViewComponent
                    isUserAllowedToPost={isUserAllowedToPost}
                    user={user}
                    group={group}
                    sectionId={activeTabModel.id}
                    posts={posts}
                    setShowShareModal={setShowShareModal}
                  />
                ) : (
                  <JoinGroupView
                    user={user}
                    group={group}
                    setGroup={setGroup}
                  />
                )}
              </>
            )
          }
        />
      </MaxWidthWrapper>
    </main>
  );
}

const stp = (s) => ({
  section: s.sections.activeSectionId
    ? s.sections.sections[s.sections.activeSectionId]
    : null,
  community: s.community,
});

const dtp = {
  getSectionPinnedPosts,
  setPosts: setCurrentSectionPosts,
  resetNewPostCount,
};

const SectionDetail = connect(stp, dtp)(withToast(SectionDetailComponent));

/**
 * @description - To display the section detail view
 */
export default SectionDetail;
