import cx from "classnames";
import { cn } from "../../button.component";
import UIcon from "../../uicon-component";

function UserTileShimmer({ className }: { className?: string }) {
  return (
    <div
      className={cn(cx("UserTileShimmer my-3 flex items-center", className))}>
      <p className="h-8 w-8 rounded-full bg-gray-300" />
      <div className="ml-2 w-max flex-grow space-y-1">
        <div className="mt-2 h-2 w-8/12 rounded bg-gray-300" />
        <p className="h-2 w-32 rounded bg-gray-300"></p>
      </div>
      <UIcon
        icon="menu-dots-vertical"
        className="text-secondary-foreground/70"
      />
    </div>
  );
}

export default UserTileShimmer;
