import { useAppDispatch } from "../../../../hooks/redux.hook";
import { setCurrentSectionPosts } from "../../../../_store/sections.store";
import I18 from "../../../atoms/i18";
import CreatePost from "../../../post/create";
interface Props {
  group: any;
  sectionId: string;
  posts: any[];
  isUserAllowedToPost: boolean;
  setShowShareModal: (value: boolean) => void;
}
/**
 * @description - Display create post message and Invite audience message in section detail page. When no post is available in section.
 * @param(object) group - Active group object
 * @param(string) sectionId - Active section id
 * @param(Array<Object>) posts - List of posts in section
 * @param(boolean) isUserAllowedToPost -Weather user is allowed to post in section
 * @param(function) setShowShareModal - Callback function to show share modal
 */

export default function StartDiscussionViewComponent({
  group,
  sectionId,
  posts,
  isUserAllowedToPost,
  setShowShareModal = (_: boolean) => {},
}: Props) {
  const dispatch = useAppDispatch();

  return (
    <div>
      <div className="no-post my-10 p-2 text-center text-secondary-foreground">
        <div className="my-3 font-bold">
          <I18>Start a discussion</I18>
        </div>
        <div>
          {isUserAllowedToPost && (
            <span className="select-none text-left">
              <CreatePost
                group={group}
                sectionId={sectionId}
                addPost={(post: any) => {
                  if (post && post.tab && post.tab?.id === sectionId) {
                    // update posts list with new post
                    dispatch(setCurrentSectionPosts([post, ...posts]));
                  }
                }}
                inlineButtonComponent={
                  <span className="cursor-pointer text-primary">
                    <I18>Post something</I18>
                  </span>
                }
              />
            </span>
          )}
          <span className="px-2">
            <I18>or</I18>
          </span>
          <span
            onClick={(e) => {
              setShowShareModal(true);
            }}
            className="cursor-pointer select-none text-primary">
            <I18>Invite your audience</I18>
          </span>
        </div>
      </div>
    </div>
  );
}
