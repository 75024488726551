import { SVGProps } from "react";

export function SendIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={`fill-current ${props.className}`}
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12855_11372)">
        <path
          d="M8.74216 8.2963C8.62653 8.31505 8.54528 8.38693 8.51559 8.49786C8.47653 8.64318 8.42028 8.5588 9.75934 10.3447L11 11.9994L9.75934 13.6541C8.40778 15.4557 8.47341 15.3588 8.51403 15.5104C8.53434 15.5838 8.63122 15.6807 8.69841 15.6963C8.72341 15.701 8.75153 15.7088 8.76403 15.7119C8.77497 15.7151 8.81403 15.7104 8.84841 15.701C8.91091 15.6838 16.139 12.3541 16.3203 12.2604C16.3734 12.2322 16.4281 12.1885 16.4468 12.1588C16.5234 12.0447 16.5062 11.8885 16.4078 11.7979C16.3656 11.7588 15.4 11.3072 12.6172 10.0229C10.564 9.07599 8.86559 8.29786 8.84372 8.29318C8.82184 8.28849 8.77653 8.29005 8.74216 8.2963Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_12855_11372">
          <rect
            width="8"
            height="8"
            fill="currentColor"
            transform="translate(8.5 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
