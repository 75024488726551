import cx from "classnames";
import { useEffect, useState } from "react";
import { useToast } from "../../../../hooks/use-toast.hook";
import { Post } from "../../../../types/post/post.type";
import { User } from "../../../../types/user/minimal-user.type";
import { PostService } from "../../../../_service";
import Validator from "../../../../_utils/validator";
import { cn } from "../../../button.component";
import { PostProcessing } from "../../post-card.component";

interface Props {
  post: Post;
  user: User;
  updatePost: (post: Post) => void;
  displayPollResult: boolean;
}

export function PostQuestionView({ post, user, updatePost }: Props) {
  const [userSelectedAnswer, setUserSelectedAnswer] = useState<
    string | undefined
  >();
  const [loading, setIsLoading] = useState<boolean>();

  const isSharedPost = Validator.hasValue(post.shared);
  const poll = isSharedPost ? post?.shared?.poll : post.poll;

  const { addToast } = useToast();

  const question = poll && poll.isQuestion && poll.questions[0];

  useEffect(() => {
    // check if we have answer by user
    if (poll && poll.isAnsweredByMe)
      setUserSelectedAnswer(poll.myAnswer[0].option);
  }, [poll, post]);

  if (!question) return <></>;

  return (
    <div className="my-4 rounded-xl bg-secondary/40 p-4 text-sm text-card-foreground">
      {/* question statement */}
      <p className="mb-2.5 scroll-m-20 font-semibold tracking-tight text-secondary-foreground">
        {question.statement}
      </p>
      {/* question options */}
      {question.options.map((option, index) => {
        const correctAnswer = userSelectedAnswer && option === question.answer;
        const inCorrectAnswer =
          userSelectedAnswer &&
          question.answer !== userSelectedAnswer &&
          userSelectedAnswer === option;
        return (
          <div
            key={index}
            onClick={() => {
              if (!user) {
                addToast("Please login to record your response", "", "warning");
                return;
              } else if (correctAnswer || inCorrectAnswer) {
                // addToast("", "", "warning");
                return;
              }
              // check if we don't have answered
              if (!poll.myAnswer) {
                setIsLoading(true);
                // attempt this question
                PostService.voteOnPoll(
                  user,
                  isSharedPost ? post.sharedId : post.id,
                  option
                )
                  .then((response) => {
                    // update post
                    updatePost(response.post);
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }
            }}
            className={cn(
              cx(
                "my-2 flex cursor-pointer flex-col gap-2 rounded-md border border-border bg-card p-2 text-xs transition-all duration-150 ease-in-expo hover:bg-accent",
                {
                  "bg-success text-success-foreground hover:bg-success":
                    correctAnswer,
                  "bg-alert text-alert-foreground hover:bg-alert":
                    inCorrectAnswer,
                  "cursor-default hover:scale-100": userSelectedAnswer,
                  "hover:scale-[1.01]": !userSelectedAnswer,
                }
              )
            )}>
            <div className="flex w-full flex-row items-center gap-3">
              <span
                className={cx(
                  "flex h-6 w-6 place-content-center items-center rounded-full border border-border text-xs text-secondary-foreground"
                )}>
                {String.fromCharCode(65 + index)}
              </span>
              <div className="flex-1">
                <p className="font-light">{option}</p>
              </div>
              {/* vote count */}
              {/* Display percentage to only creator */}
              {post.isByMe && (
                <div
                  className={cx("", {
                    "text-success-foreground": correctAnswer,
                    "text-alert-foreground": inCorrectAnswer,
                  })}>
                  {poll.voteCount > 0
                    ? (poll.votes[option] * 100).toFixed(2)
                    : 0}
                  %
                  {post.canDelete && (
                    <>
                      &nbsp; | &nbsp;
                      {Math.floor(poll.votes[option] * poll.voteCount)}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
      <PostProcessing isProcessing={loading} />
      {/* Uncomment to display question result button */}
      {/* {post.canDelete && (
        <TextButton
          label="View Result"
          onClick={() => {
            setDisplayStatics(true);
          }}
        />
      )} */}

      {/* <RightSideModal
        active={displayStatics}
        setActive={setDisplayStatics}
        width={700}>
        <PollResult post={post} setDisplayStatics={setDisplayStatics} />
      </RightSideModal> */}
    </div>
  );
}
