import React from "react";
import MEDIA_TYPES from "../../../../_constants/mediaType";
import { PostImages } from "../../post-images.component";

/**
 * Component to show selected images
 * @param {Array} images - Array of images
 * @param {Function} setImages - Function to set images
 * @param {Function} onDelete - Function to signal delete image
 */
const ImagesPreview = function ImagePreview({
  images = [],
  setImages = () => {},
  onDelete = (image) => {},
  uploadedMedia,
  setUploadedMedia = () => {},
  setRemovedMediaUploadsOnEdit = () => {},
}) {
  const image = React.useMemo(() => {
    if (!images || images.length === 0) {
      return <></>;
    } else {
      return (
        <Images
          images={images}
          setImages={setImages}
          onDelete={onDelete}
          uploadedMedia={uploadedMedia}
          setUploadedMedia={setUploadedMedia}
          setRemovedMediaUploadsOnEdit={setRemovedMediaUploadsOnEdit}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images?.length]);
  if (!images || images.length === 0) {
    return <></>;
  }
  return <div className="SelectedImagesPreview">{image}</div>;
};

/**
 * Component to show selected images
 * @param {Array} images - Array of images
 * @param {Function} setImages - Function to set images
 * @param {Function} onDelete - Function to signal delete image
 */
const Images = React.memo(function Images({
  images = [],
  setImages = () => {},
  onDelete = (image) => {},
  uploadedMedia,
  setUploadedMedia = () => {},
  setRemovedMediaUploadsOnEdit = () => {},
}) {
  return (
    <PostImages
      images={images.map((image) => {
        return typeof image !== "string" ? URL.createObjectURL(image) : image;
      })}
      onDelete={(index) => {
        // remove the deleted image from state
        const existingImages = [...uploadedMedia.images];
        const removedImage = existingImages[index];
        const newImages = existingImages.filter((_, idx) => idx !== index);

        setImages(newImages);
        setUploadedMedia((prevUploadedMedia) => ({
          ...prevUploadedMedia,
          images: newImages,
        }));
        setRemovedMediaUploadsOnEdit((prevMediaItems) => [
          ...prevMediaItems,
          { mediaItem: removedImage, type: MEDIA_TYPES.IMAGE },
        ]);

        onDelete(images[index]);
      }}
    />
  );
});

export { ImagesPreview };
