const CHANNEL = {
  OPEN: "open",
  CLOSED: "closed",
  PRIVATE: "private",
};

const GROUP = {
  OPEN: "open",
  CLOSED: "closed-free",
  PRIVATE: "closed-private",
};

const GROUP_TOOLTIPS = {
  OPEN: {
    id: GROUP.OPEN,
    tooltips: [
      {
        label: "Visibility",
        description: "All user can see this group",
      },
      {
        label: "Access",
        description: "Any user can join this group",
      },
    ],
  },
  CLOSED: {
    id: GROUP.CLOSED,
    tooltips: [
      {
        label: "Visibility",
        description: "All user can see this group",
      },
      {
        label: "Access",
        description:
          "They will have to send a request to join the group. Or Add them manually. Or Add this to paid subscription",
      },
    ],
  },
  PRIVATE: {
    id: GROUP.PRIVATE,
    tooltips: [
      {
        label: "Visibility",
        description: "Only members of the group can see this group.",
      },
      {
        label: "Access",
        description:
          "Invite or Add user manually. Or Add this to paid subscription",
      },
    ],
  },
};

const CHANNEL_TOOLTIPS = {
  OPEN: {
    id: CHANNEL.OPEN,
    tooltips: [
      {
        label: "Visibility",
        description: "All group members can see this channel",
      },
      {
        label: "Access",
        description: "All group member will be auto added to this channel",
      },
    ],
  },
  CLOSED: {
    id: CHANNEL.CLOSED,
    tooltips: [
      {
        label: "Visibility",
        description: "All user can see this channel",
      },
      {
        label: "Access",
        description: "They will have to send a request to join the channel.",
      },
    ],
  },
  PRIVATE: {
    id: CHANNEL.PRIVATE,
    tooltips: [
      {
        label: "Visibility",
        description: "Only members of the channel can see this channel.",
      },
      {
        label: "Access",
        description: "Invite or Add user manually.",
      },
    ],
  },
};

export default { CHANNEL, GROUP, GROUP_TOOLTIPS, CHANNEL_TOOLTIPS };
