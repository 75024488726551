// import { DyteMeeting, Meeting } from "dyte-client";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import { useToast } from "../../hooks/use-toast.hook";
import { User } from "../../types/user/minimal-user.type";
import { Widget } from "../../types/widget/widget.type";
import I18 from "../../_components/atoms/i18";
import SidebarCommunityCard from "../../_components/community/sidebar-community-card.component";
import SelectWidgetModalV2 from "../../_components/group/select-widget-modal-v2.component";
import RightSideModal from "../../_components/right-side-modal.component";
import CustomWidget from "../../_components/widgets/custom-widget.components";
import LeaderBoardWidget from "../../_components/widgets/leaderboard-widget-component";
import TrendingPostWidget from "../../_components/widgets/trending-post-widget.component";
import Validator from "../../_utils/validator";
import SidebarGroupMembersCard from "./group-members-card.component";
import { GroupService } from "../../_service";
export const GROUPPAGE_ROUTE = "/group/:id";
export const GROUPPAGE_SLUG_ROUTE = "/g/:slug";
export const SECTIONPAGE_SLUG_ROUTE = "/g/:slug/s/:activeTabSlug";

/**
 * Generate Secondary Sidebar
 * @param {Object} user - The user object
 * @param {Object} group - The group object
 * @param {Object} community - The current community object
 * @param {Object} section - The current section object
 * @param {Array} members - List of group members
 * @param {Array} widgets - List of group widgets
 * @param {Array} closedSectionMembers - List of closed section members
 * @param {Number} closedSectionMembersCount - Count of closed section members
 * @param {Object} activeTabModel - The active section object
 * @param {function} addGroupMember - Callback to add a group member
 * @param {function} removeGroupMember - Callback to remove a group member
 * @param {function} removeClosedSectionMember - Callback to remove a closed section member
 * @param {function} updateGroupMember - Callback to update a group member
 * @param {function} updateClosedSectionMember - Callback to update a closed section member
 * @param {function} onWidgetListUpdate - Callback to update a group widget
 * @param {Function} addToast - Callback to add a toast
 * @returns
 */

interface Props {
  widgets?: Array<Widget>;
  addGroupMember: (e: any) => void;
  updateGroupMember: (e: any) => void;
  updateClosedSectionMember: (e: any) => void;
  removeGroupMember: (e: any) => void;
  removeClosedSectionMember: (e: any) => void;
  onWidgetListUpdate: (e: any) => void;
  closedSectionMembers: any;
  closedSectionMembersCount: any;
  members: any;
}

export default function GroupSecondarySidebar({
  widgets,
  addGroupMember = (e: any) => {},
  updateGroupMember = (e: any) => {},
  updateClosedSectionMember = (e: any) => {},
  removeGroupMember = (e: any) => {},
  removeClosedSectionMember = (e: any) => {},
  onWidgetListUpdate = (e: any) => {},
  closedSectionMembers,
  closedSectionMembersCount,
  members,
}: Props) {
  const { user, group, community, activeTabModel } = useAppSelector((state) => {
    return {
      user: state.auth,
      group: state.activeGroup,
      community: state.community,
      activeTabModel: state.sections.activeSectionId
        ? state.sections.sections[state.sections.activeSectionId]
        : null,
    };
  });
  // flag to control visibility of add members modal
  const [addMembersVisible, setAddMembersVisible] = useState(false);
  const [isSelectWidgetVisible, setIsSelectWidgetVisible] = useState(false);

  const hasGroupPrimaryPermission = ["admin", "moderator"].includes(
    group?.myRole ?? ""
  );

  const joinedMembers =
    activeTabModel && activeTabModel.isClosed
      ? closedSectionMembers.filter(
          (m: any) => m.tabStatus === "joined" || m.tabStatus === "requested"
        ) // show only joined members to closed group
      : members.filter(
          (m: any) => m.groupStatus === "joined" || m.groupStatus === "requested"
        );

  const { analyticsService } = useAppService();

  return (
    <div className="secondary-sidebar">
      <div className="secondary-sidebar-content mr-16">
        {/* subscribers card */}
        {!community?.configuration?.hideCommunitySubscribersList ? (
          <SidebarCommunityCard
            addGroupMember={addGroupMember}
            updateGroupMember={updateGroupMember}
            removeGroupMember={removeGroupMember}
          />
        ) : (
          <></>
        )}

        {Validator.hasValue(widgets) && (
          <div className="flex flex-col gap-2">
            {widgets!.map((w: Widget, index: number) => {
              if (!group) {
                return null;
              }
              if (!user || (!w.isVisible && ["user"].includes(group.myRole))) {
                return null;
              } else if (hasGroupPrimaryPermission && !w.isVisibleToAdmin) {
                return null;
              }

              switch (w.type) {
                case "custom":
                  return <CustomWidget key={index} widget={w} />;
                case "members":
                  return (
                    <SidebarGroupMembersCard
                      key={index}
                      // @ts-ignore
                      members={members}
                      addMembersVisible={addMembersVisible}
                      setAddMembersVisible={setAddMembersVisible}
                      joinedMembers={joinedMembers}
                      closedSectionMembersCount={closedSectionMembersCount}
                      user={user}
                      group={group}
                      updateGroupMember={updateGroupMember}
                      updateClosedSectionMember={updateClosedSectionMember}
                      removeGroupMember={removeGroupMember}
                      removeClosedSectionMember={removeClosedSectionMember}
                      activeTabModel={activeTabModel}
                    />
                  );
                case "top-posts":
                  return activeTabModel?.sectionType === "realtime" ? (
                    <></>
                  ) : (
                    <TrendingPostWidget
                      key={index}
                      group={group}
                      isCommunityPosts={undefined}
                    />
                  );
                // case "upcoming-events":
                //   return (
                //     <UpcomingEventWidget
                //       key={index}
                //       user={user}
                //       community={community}
                //       group={group}
                //       isCommunityEvent={false}
                //     />
                //   );
                // case WidgetType.communityMembers:
                //   return (
                //     <MemberWidget
                //       community={community}
                //       user={user}
                //       memberPreviewLimit={4}
                //     />
                //   );
                case "leaderboard":
                  return (
                    <LeaderBoardWidget
                      key={index}
                      memberPreviewLimit={4}
                      group={group}
                      isCommunityLeaderBoard={false}
                    />
                  );

                case "community-leaderboard":
                  return (
                    <LeaderBoardWidget
                      key={index}
                      memberPreviewLimit={4}
                      isCommunityLeaderBoard={true}
                      group={undefined}
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
        )}
        {hasGroupPrimaryPermission && (
          <>
            <div
              onClick={() => {
                analyticsService.track("click-select-widget", {
                  location: "group",
                });
                setIsSelectWidgetVisible(true);
              }}
              className="my-2 flex w-full cursor-pointer place-content-center rounded-lg border border-dashed border-border p-3 text-secondary-foreground">
              <I18>Select Widgets</I18>
            </div>
            {/* Add/Update customs group widgets pop-up */}

            {/* <ModalPortal openPortal={isSelectWidgetVisible}>
              <SelectWidgetModal
                // @ts-ignore
                community={community}
                user={user}
                widgets={widgets}
                group={group}
                forCommunity={false}
                active={isSelectWidgetVisible}
                setActive={setIsSelectWidgetVisible}
                addToast={addToast}
                onWidgetListUpdate={onWidgetListUpdate}
              />
            </ModalPortal> */}
            <RightSideModal
              width={570}
              setActive={setIsSelectWidgetVisible}
              active={isSelectWidgetVisible}>
              <SelectWidgetModalV2
                //@ts-ignore
                widgets={widgets}
                setActive={setIsSelectWidgetVisible}
                onWidgetListUpdate={onWidgetListUpdate}
              />
            </RightSideModal>
          </>
        )}
      </div>
    </div>
  );
}