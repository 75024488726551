import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { PostHighlightService } from "../../_service/post-highlight.service";
import { CloseButton } from "../activity/close-button.component";
import { Button } from "../button.component";
import { HighlightedPostTile } from "./featured-posts.component";
import { setHighlightedPosts } from "../../_store/highlighted-posts.store";
import I18 from "../atoms/i18";
import { Post } from "../../types/post/post.type";

type HighlightedPost = {
    postData: Post;
    sortOrder: number;
};

type Props = {
  highlightedPosts: HighlightedPost[];
  setOpenFeaturedPostsRightSideModal: (value: boolean) => void;
};

const FeaturedPostsRightSideModal = ({
  highlightedPosts,
  setOpenFeaturedPostsRightSideModal,
}: Props) => {
  const { community } = useAppSelector((state) => {
    return {
      community: state.community,
    };
  });
  const dispatch = useAppDispatch();

  const [draggingPostIndex, setDraggingPostIndex] = useState<number>();
  const [loading, setLoading] = useState(false);

  const updateFeaturedPostsSortOrder = async () => {
    const highLightedPostsSortOrder = highlightedPosts.map(
      (highlightedPost, index) => ({
        sortOrder: index,
        postId: highlightedPost.postData.id,
      })
    );
    setLoading(true)
    try {
      const updatedHighLightedPosts =
        await PostHighlightService.updateHighlightedPostsSortOrder(
          community.id,
          {
            sortOrder: highLightedPostsSortOrder,
          }
        );
        setLoading(false);
        setOpenFeaturedPostsRightSideModal(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <div className="h-max bg-card">
      <div className="sticky top-0 flex-col border-b border-border bg-card px-4 py-2">
        <div className="flex items-center justify-between">
          <span className="text-sm font-bold text-secondary-foreground/80">
            EDIT ORDER OF FEATURED POSTS
          </span>
          <CloseButton
            onClick={() => {
              setOpenFeaturedPostsRightSideModal(false);
            }}
          />
        </div>
      </div>
      <div className="p-4">
      <I18>Note: Drag the Post to change the order.</I18>
      <div className="flex flex-wrap justify-around gap-y-4 mt-4 mb-20">
        {highlightedPosts &&
          highlightedPosts.map((post, index) => {
            return (
              <div
                className="mb-2"
                key={index}
                onDrop={(e) => {
                  try {
                    // exchange the sortOrder of both of the indices
                    const sortOrder = highlightedPosts.map((post) => post);
                    console.log(sortOrder)

                    const oldIndex = sortOrder[index].sortOrder;
                    // set new sort order
                    sortOrder[index].sortOrder =
                      //@ts-ignore
                      sortOrder[draggingPostIndex].sortOrder;
                    // set old sort order
                    //@ts-ignore
                    sortOrder[draggingPostIndex].sortOrder = oldIndex;

                    // sort the sortOrder list according to sortOrder
                    sortOrder.sort(
                      (a, b) => a.sortOrder - b.sortOrder
                    );

                      dispatch(setHighlightedPosts(sortOrder))
                  } catch (e) {
                    console.error(e);
                  }
                }}>
                <HighlightedPostTile
                  key={post.postData.id}
                  post={post.postData}
                  onDragStart={() => {
                    setDraggingPostIndex(index);
                  }}
                />
              </div>
            );
          })}
      </div>
      </div>
      <div className="fixed bottom-0 w-full border-t border-border bg-card p-4 md:w-3/5 lg:w-[38%]">
        <div className="flex justify-between">
          <Button
            className="w-36 px-0 text-sm"
            isCancelType={true}
            label="Cancel"
            onClick={() => setOpenFeaturedPostsRightSideModal(false)}
          />
          <Button
            onClick={updateFeaturedPostsSortOrder}
            className="text-sm"
            isLoading={loading}
            label="Update Order"
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturedPostsRightSideModal;