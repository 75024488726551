import cx from "classnames";
import { format } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { APP_LAYOUT } from "../contexts/base-config.context";
import { ToastTypes, withToast } from "../contexts/toastr.context";
import { useAppService } from "../hooks/use-app-service";
import { AppLayoutWrapper } from "../_components/app-layout-wrapper";
import I18 from "../_components/atoms/i18";
import { Button } from "../_components/button.component";
import { EventCreateModel } from "../_components/event/create-event-modal-component";
import EventCardList from "../_components/event/event-card-list.component";
import IconButton2 from "../_components/icon-button-2-component";
import { history } from "../_config";
import { EventService } from "../_service/event.service";
import { createEventDetailSlugPageRoute } from "./event-detail.page";

export const COMMUNITY_EVENTS_PAGE_ROUTE = "/events";

const EventsTab = Object.freeze({ List: 1, Calendar: 2 });

function EventComponent({ user, groups, community, addToast }) {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [isEventAdded, setIsEventAdded] = useState([]);

  const [event, setEvent] = useState({});
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [isLoading, setIsLoading] = useState(false);
  const [displayCreateButton, setDisplayCreateButton] = useState(false);
  const [activeTab, setActiveTab] = React.useState(EventsTab.List);

  // Display create event modal
  const [activeModel, setActiveModel] = useState(false);
  // set default date start date for creating event
  const [startDate, setStartDate] = useState(new Date());

  const anyoneCanCreateEvent = community?.configuration?.anyoneCanCreateEvent;

  const { analyticsService } = useAppService();

  // // get events from server
  React.useEffect(() => {
    setIsLoading(true);
    if (community.id) {
      eventList();
    }
  }, [community.id, date, user]);

  const eventList = () => {
    let eveltList = [];
    EventService.getAllEvents(user, community.id, date)
      .then((response) => {
        if (response.events) {
          response.events.map((event, index) => {
            const newevent = {
              id: event.id,
              title:
                event.title && event.title.length > 15
                  ? event.title.substring(0, 14) + "..."
                  : event.title,
              // title: event.title,
              allDay: true,
              start: new Date(event.startTime),
              end: new Date(event.endTime),
            };
            eveltList.push(newevent);
          });
        }
        setEvents(eveltList);
        setIsLoading(false);
      })
      .catch((error) => {
        addToast("Error getting events", error);
        addToast("Events could not be fetched!", "", ToastTypes.danger);
        setIsLoading(false);
      });
  };

  // Check if user has create event permission
  React.useEffect(() => {
    if (groups !== null && groups !== undefined && groups.length > 0) {
      let displayCreate =
        groups.some((group) => ["admin", "moderator"].includes(group.myRole)) ||
        anyoneCanCreateEvent;
      setDisplayCreateButton(displayCreate);
    }
  }, [groups]);

  const onNewEventAdded = (event) => {
    // eventList();
    setEvent(event);
    setIsEventAdded(!isEventAdded);
  };

  const onClearModal = (event) => {
    setEvent({});
  };

  const myCustomMonthDateHeader = ({ label, date }) => {
    /// Check if the date is in the past
    const isPastDate = date < new Date();
    return (
      <span
        className={cx("", {
          "cursor-pointer text-secondary-foreground/85": !isPastDate,
          "text-secondary-foreground/80": isPastDate,
        })}
        onClick={() => {
          if (!isPastDate) {
            setStartDate(new Date(date));
            setActiveModel(true);
          }
        }}>
        {label}
      </span>
    );
  };

  const getCustomToolbar = (toolbar) => {
    const goToBack = () => {
      let mDate = new Date(toolbar.date);
      let newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      toolbar.onNavigate("prev", newDate);
      setDate(format(newDate, "yyyy-MM-dd"));
    };
    const goToNext = () => {
      let mDate = new Date(toolbar.date);
      let newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      toolbar.onNavigate("next", newDate);
      setDate(format(newDate, "yyyy-MM-dd"));
    };
    return (
      <div className="my-2 flex flex-row items-center justify-center">
        <button className="h-8 w-8 rounded text-primary" onClick={goToBack}>
          {/* <BackIcon className='prev-icon' /> */}
          {"<"}
        </button>
        <label className="px-4 font-bold">{toolbar.label}</label>
        <button className="h-8 w-8 rounded text-primary" onClick={goToNext}>
          {/* <NextIcon className='next-icon' /> */}
          {">"}
        </button>
      </div>
    );
  };

  if (community?.configuration?.isEventsTabDisabled) {
    return <Redirect to="/" />;
  }

  return (
    <AppLayoutWrapper layout={APP_LAYOUT.TWO_COLUMN}>
      <div className="EventPage Card flex min-h-screen flex-col rounded border border-border bg-card">
        <div className="rounded py-4 pl-4 pr-2 shadow">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4 font-bold text-secondary-foreground/85">
              <I18> Events</I18>
              <IconButton2
                className="ml-1.5"
                icon={`${activeTab === EventsTab.List ? "list" : "calendar"}`}
                onClick={() => {
                  switch (activeTab) {
                    case EventsTab.List:
                      analyticsService.track("event-view", {
                        viewType: "Calendar",
                      });
                      setActiveTab(EventsTab.Calendar);
                      break;

                    default:
                      analyticsService.track("event-view", {
                        viewType: "List",
                      });
                      setActiveTab(EventsTab.List);
                      break;
                  }
                }}
                hoverable
              />
            </div>
            {user && displayCreateButton ? (
              <Button
                className="mr-2"
                label="New Event"
                onClick={() => {
                  analyticsService.track("new-event-button-clicked");
                  setActiveModel(true);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        {activeTab === EventsTab.List ? (
          <></>
        ) : (
          <>
            <div className="" style={{ height: "500px" }}>
              <Calendar
                events={events}
                step={60}
                max={new Date(230, 12, 30)}
                defaultDate={Date.now()}
                onSelectEvent={(event) => {
                  history.push(createEventDetailSlugPageRoute(event));
                }}
                popup={true}
                components={{
                  toolbar: getCustomToolbar,
                  month: {
                    dateHeader: myCustomMonthDateHeader,
                  },
                }}
                selectable={false}
                localizer={localizer}
              />
            </div>
          </>
        )}
        <>
          <div className="event-list-container">
            <EventCardList
              user={user}
              addToast={addToast}
              isEventAdded={isEventAdded}
            />
          </div>
        </>
        <EventCreateModel
          active={activeModel}
          user={user}
          community={community}
          onClearModal={onClearModal}
          eventToUpdate={event}
          onNewEventAdded={onNewEventAdded}
          openModel={(val) => {
            setActiveModel(val);
          }}
          startDate={startDate}
        />
      </div>
      {/* <div className="relative Card">
        <SidebarEventCard user={user} addToast={addToast} />
      </div> */}
    </AppLayoutWrapper>
  );
}

export default withToast(
  connect((s) => ({ user: s.auth, community: s.community, groups: s.groups }))(
    EventComponent
  )
);
