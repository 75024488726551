import { FC } from "react";
import I18 from "./atoms/i18";
import { Checkbox } from "./form-controls/checkbox.component";

interface ButtonWithCheckboxProps {
  checkboxLabel: string;
  selectedOption: boolean;
  setSelectedOption: (value: boolean) => void;
}

const ButtonWithCheckbox: FC<ButtonWithCheckboxProps> = ({
  checkboxLabel,
  selectedOption,
  setSelectedOption,
}) => (
  <button
    className="h-10 justify-center rounded border border-border px-5 lg:min-w-40"
    style={{ minWidth: "160px" }}
    onClick={() => {
      setSelectedOption(!selectedOption);
    }}>
    <div className="flex items-center">
      <Checkbox className="-mt-4" selected={selectedOption} />
      <I18>{checkboxLabel}</I18>
    </div>
  </button>
);

export default ButtonWithCheckbox;
