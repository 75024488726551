import React from "react";
import { Course } from "../../../../types/course/course.type";
import TextButton from "../../../../_components/atoms/text-button";
import ModalForm from "../../../../_components/model2";
import AlertStrip from "../../../../_components/molecule/alert-strips";
import { ListView } from "../../../../_components/molecule/listview.component";
import { CourseJoinStatus, CourseStateType } from "../course-detail.page";
import CreateCourseSection from "../create-course-section";
import CourseSectionCard from "./course-section-card";

interface LessonsBodyProps {
  community: any;
  hasWritePermission: boolean;
  course: any;
  setCourse: (course: any) => void;
}
/**
 * Displays the lessons of the course
 * @param {Object} community - community object
 * @param {boolean} hasWritePermission - true if user has course write permission
 * @param {Object} course - course object
 * @param {function} setCourse - function to set course
 */
export function CourseLessonsView({
  community,
  hasWritePermission,
  course,
  setCourse,
}: LessonsBodyProps) {
  const [isAddSectionVisible, setIsAddSectionVisible] = React.useState(false);

  if (
    !hasWritePermission &&
    course.state === CourseStateType["closed-free"] &&
    course.joinStatus !== CourseJoinStatus.joined
  ) {
    return (
      <div className="pt-4">
        <AlertStrip
          warning
          className="bg-card"
          title="You have not enrolled in this course."
          message="Course content will be automatically unlocked once you enrolled in
            the course"
        />
      </div>
    );
  }
  return (
    <div className="mb-20 flex flex-col space-y-2 px-2 sm:px-0">
      <div className="flex flex-col space-y-4 pt-3">
        {/* ADD SECTION BUTTON */}
        {hasWritePermission && (
          <div className="flex">
            <ModalForm
              title={"Add new section"}
              className="w-full sm:w-2/3 md:w-2/4 lg:w-1/3"
              visible={isAddSectionVisible}
              setVisible={setIsAddSectionVisible}
              button={
                <TextButton
                  label="Add content"
                  className="outline outline-1"
                  onClick={() => setIsAddSectionVisible(true)}
                />
              }>
              <CreateCourseSection
                // @ts-ignore
                community={community}
                course={course}
                setCourse={(course: Course) => {
                  setCourse(course);
                  setIsAddSectionVisible(false);
                }}
                setVisible={setIsAddSectionVisible}
                onCourseUpdated={(e: any) => {
                  setCourse(e);
                  setIsAddSectionVisible(false);
                }}
              />
            </ModalForm>
          </div>
        )}

        {/* SECTIONS LIST */}
        <ListView
          items={course.sections}
          noItemsElement={
            hasWritePermission ? (
              <>
                <AlertStrip
                  className="bg-card"
                  warning
                  title={"No content added to this course"}
                  message={
                    "Add content to this course by clicking on the button above"
                  }
                />
              </>
            ) : (
              <div className="flex flex-col gap-2">
                <AlertStrip
                  className="bg-card"
                  info
                  title={"No content available"}
                  message={"Content will be available once the course starts"}
                />
              </div>
            )
          }
          renderItem={(section, index) => (
            <CourseSectionCard
              key={section.id}
              // @ts-ignore
              course={course}
              setCourse={setCourse}
              onSectionUpdate={(e: any) => {
                const sectionList = course.sections;
                sectionList[index] = e;
                setCourse({ ...course, sections: sectionList });
              }}
              onSectionDelete={(deletedSection: any) => {
                const sectionList = course.sections.filter(
                  (section: any) => section.id !== deletedSection.id
                );
                setCourse({ ...course, sections: sectionList });
              }}
              onLessonDelete={(deletedLesson: any) => {
                const sectionList = course.sections;
                sectionList[index].lessons = sectionList[index].lessons.filter(
                  (lesson: any) => lesson.id !== deletedLesson.id
                );
                setCourse({ ...course, sections: sectionList });
              }}
              onLessonAdded={(lesson: any) => {
                const sectionList = course.sections;
                const lessonList = sectionList[index].lessons ?? [];
                lessonList.push(lesson);
                sectionList[index].lessons = lessonList;
                setCourse({ ...course, sections: sectionList });
              }}
              section={section}
              community={community}
              hasWritePermission={hasWritePermission}
              className="border border-border bg-primary-light"
            />
          )}
        />
      </div>
    </div>
  );
}
