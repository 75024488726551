import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  syncOneSignalWebUserId: base + "user/save-one-signal-web-user-id",
  profile: base + "profile",
  checkProfileAvailability: (username) =>
    base + "profile/username-availability/" + username,
  profileById: (userId) => base + "user/" + userId,
  updateProfile: base + "profile",
  updateWalletAddress: base + "profile/wallet-address",
  updatePicture: base + "profile/picture",
  deleteProfilePicture: base + "profile/picture",
  getAllUsers: base + "user",
  followUser: (userId, communityId) => base + "community/" + communityId +  "/user/" + userId + "/follow",
  unfollowUser: (userId, communityId) => base + "community/" + communityId +  "/user/" + userId + "/unfollow",
  followFollowingCount: (userId, communityId) => base + "community/" + communityId +  "/user/" + userId + "/follow-following-count",
  followersList: (userId, communityId) => base + "community/" + communityId +  "/user/" + userId + "/followers",
  followedList: (userId, communityId) => base + "community/" + communityId +  "/user/" + userId + "/followeds",
  notifications: base + "user/notifications",
  markNotificationAsViewed: (notificationId) =>
    base + "user/notifications/" + notificationId + "/mark-as-read",
  markNotificationsAsReceived: base + "user/notifications/mark-as-received",
  markSelectedNotificationsAsReceived: (groupId, tabId) =>
    base +
    `user/notifications/mark-selected-as-received?group=${groupId}&tab=${tabId}`,
  markNotificationsAsViewed: base + "user/notifications/mark-as-read",
  counters: base + "user/counters",
  apiKeys: {
    get: base + "user/api-key",
    delete: (keyId) => base + "user/api-key/" + keyId,
  },
  verify: {
    emailSendOtp: base + "verify/email/send-otp",
    emailVerifyOtp: base + "verify/email/verify-otp",
    mobileSendOtp: base + "verify/mobile/send-otp",
    mobileVerifyOtp: base + "verify/mobile/verify-otp",
    checkExistingMobile: base + "verify/mobile/already-existing",
    checkExistingEmail: base + "verify/email/already-existing",
  },
  account: {
    unlinkMobileOrEmail: base + "account/unlink-mobile-or-email",
  },
  block: {
    blockPeer: (peerId, communityId) =>
      base + "community/" + communityId + "/block-peer/" + peerId,
    unblockPeer: (peerId, communityId) =>
      base + "community/" + communityId + "/unblock-peer/" + peerId,
    listBlockedPeers: (communityId) =>
      base + "community/" + communityId + "/blocked-peers",
  }
};
