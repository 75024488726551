export const GET_ALL_DIRECT_CHATS = "GET_ALL_DIRECT_CHATS";
export const GET_ALL_DIRECT_CHATS_SUCCESS = "GET_ALL_DIRECT_CHATS_SUCCESS";
export const GET_ALL_DIRECT_CHATS_FAILURE = "GET_ALL_DIRECT_CHATS_FAILURE";

export const GET_DIRECT_CHAT_BY_USER_ID = "GET_DIRECT_CHAT_BY_USER_ID";
export const GET_DIRECT_CHAT_BY_USER_ID_SUCCESS =
  "GET_DIRECT_CHAT_BY_USER_ID_SUCCESS";
export const GET_DIRECT_CHAT_BY_USER_ID_FAILURE =
  "GET_DIRECT_CHAT_BY_USER_ID_FAILURE";

export const ADD_NEW_MESSAGE_TO_DIRECT_CHAT = "ADD_NEW_MESSAGE_TO_DIRECT_CHAT";
export const EDIT_MESSAGE_IN_DIRECT_CHAT = "EDIT_MESSAGE_IN_DIRECT_CHAT";
export const DELETE_MESSAGE_IN_DIRECT_CHAT = "DELETE_MESSAGE_IN_DIRECT_CHAT";

export const ADD_CHAT_IF_NEEDED = "ADD_CHAT_IF_NEEDED";
export const MARK_CHAT_AS_READ = "MARK_CHAT_AS_READ";

// action creators

export function getAllDirectChatsAction() {
  return {
    type: GET_ALL_DIRECT_CHATS,
  };
}

export function getAllDirectChatsSuccessAction(users) {
  return {
    type: GET_ALL_DIRECT_CHATS_SUCCESS,
    users,
  };
}

export function getAllDirectChatsFailureAction(error) {
  return {
    type: GET_ALL_DIRECT_CHATS_FAILURE,
    error,
  };
}

export function addChatIfNeeded(user) {
  return {
    type: ADD_CHAT_IF_NEEDED,
    user,
  };
}

export function markChatAsRead(chat) {
  return {
    type: MARK_CHAT_AS_READ,
    chat,
  };
}

// thunk

export function getAllDirectChats() {
  return (dispatch, getState) => {
    dispatch(getAllDirectChatsAction());
    // get the user
    const { auth: user } = getState();
    // get all the groups
    // MessageService.getDirectChats(user)
    //   .then(({ users }) => {
    //     dispatch(getAllDirectChatsSuccessAction(users));
    //   })
    //   .catch((err) => {
    //     // check if axios response has message
    //     if (err.response && err.response.data && err.response.data.message) {
    //       dispatch(getAllDirectChatsFailureAction(err.response.data.message));
    //     } else {
    //       dispatch(getAllDirectChatsFailureAction("Something went wrong!"));
    //     }
    //   });
  };
}
