import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import useModel from "../../hooks/use-model.hook";
import { GroupService } from "../../_service";
import {
  removeFromArray,
  updateInArray,
} from "../../_utils/extensions/typescript-utils";
import Validator from "../../_utils/validator";
import { Avatar } from "../avatar.component";
import UpdateRoleModal from "../community/update-role-modal.component";
import IconButton2 from "../icon-button-2-component";
import IconMenu2 from "../icon-menu-2.component";
import { ListView } from "../molecule/listview.component";
import MakeAdminModal from "../roles/make-admin-modal.component";
import UpdateUserGroupRoleModal from "../roles/update-user-role-modal.component";
import UserListShimmer from "../shimmers/user/user-list.shimmer";
import Label from "../typography/label.typography";
import UIcon from "../uicon-component";
import AcceptRejectSectionMember from "./members/accept-reject-section.component";

/**
 * @param {Object} user - The auth user
 * @param {Object} group - The group to update
 * @param {Number} memberCount - The count of members
 * @param {Object} activeTabModel - The current active section
 * @param {Function} updateGroupMember - Function to update the group member
 * @param {Function} updateClosedSectionMember - Function to add the closed section member
 * @param {Function} removeGroupMember - Function to remove the group member
 * @param {Function} setActive - Callback function to trigger when close icon click
 * @param {boolean} displayOnlyJoinedMembers - Display only joined members if true otherwise display group members
 */
export function GroupMembersComponent({
  user = null,
  group = null,
  memberCount,
  activeTabModel = null,
  updateGroupMember = (e: any) => {},
  updateClosedSectionMember = (e: any) => {},
  removeGroupMember = (e: any) => {},
  setActive = (e: any) => {},
  displayOnlyJoinedMembers = false,
}: any) {
  type Status =
    | "loading"
    | "error"
    | "success"
    | "loadingMore"
    | "noMore"
    | "idle";

  const [status, setStatus] = useState<Status>("idle");
  const [page, setPage] = useState(0);
  const [members, setMembers] = useState<any[]>([]);

  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();

  const innerRef = React.useRef(null);
  const abortController = new AbortController();

  // calling lazy-load with query will trigger search
  const handleSearchDebounced = React.useCallback(
    debounce((query: any) => {
      setMembers([]);
      setStatus("loading");
      getMembersList(page, members, status, query);
    }, 600),
    [page, members, status, getMembersList]
  );

  // for admin role related changes
  const [isMakeAdminModalVisible, setIsMakeAdminModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState("user");
  const [isUpdateGroupRoleModalVisible, setIsUpdateGroupRoleModalVisible] =
    useState(false);
  const [isUpdateRoleModalVisible, setIsUpdateRoleModalVisible] =
    useState(false);

  useEffect(() => {
    if (status === "idle") {
      setStatus((prev) => "loading");
      getMembersList(
        page,
        members,
        // setStatus,
        //  setPage,
        //   setMembers,
        status
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onScroll = () => {
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;

      if (scrollTop + clientHeight === scrollHeight) {
        setStatus("loadingMore");
        getMembersList(page, members, status, undefined);
      }
    }
  };

  function getMembersList(page: any, members: any, status: any, query?: any) {
    const refresh = query;
    if (["loadingMore", "noMore"].includes(status) && members.length > 1) {
      // console.log("No more members", { members });
      setStatus("noMore");
      setPage(1);
      return;
    } else if (status === "loading") {
      // console.log("Already loading");
      return;
    }
    if (Validator.hasValue(query)) {
      setMembers([]);
    }

    // console.log("PRE:", { page, status, query });

    var promise;

    if (activeTabModel?.isClosed && displayOnlyJoinedMembers) {
      promise = GroupService.groupClosedSectionMembersPaginated(
        group.id,
        activeTabModel.id,
        refresh ? 1 : page,
        20,
        query
      );
    } else {
      promise = GroupService.groupMembersPaginated(
        group?.id! ?? "",
        activeTabModel?.id,
        refresh ? 1 : page,
        20,
        query
      );
    }

    promise
      .then(({ groupMembers, members: users, page }) => {
        const list = groupMembers ?? users;
        setMembers(refresh ? list : [...members, ...list]);

        // console.log("new members", { refresh, page, status, list });

        setPage(refresh ? 1 : page + 1);
        if (!list?.length || list.length < 20) {
          setStatus("noMore");
        } else {
          setStatus("success");
        }
      })
      .catch((error) => {
        console.log({ error });
        setStatus("error");
        // TODO: show error
      });
  }

  function onMemberUpdate(member: any) {
    const list = updateInArray(members, "id", member.id, member);
    setMembers(list);
    if (updateGroupMember) {
      updateGroupMember(member);
    }
    if (updateClosedSectionMember) {
      updateClosedSectionMember(member);
    }
  }

  return (
    <div className="GroupMembersComponent flex h-full flex-col">
      {/* headers */}
      <div className="flex items-center justify-between bg-card py-2 pl-4 pr-2">
        <div>
          <div className="font-bold text-secondary-foreground">
            {memberCount || group.userCount} Members
          </div>
          <div className="text-xs text-secondary-foreground/80">
            {group.name}
            {activeTabModel && activeTabModel.isClosed ? (
              " - " + activeTabModel.name
            ) : (
              <></>
            )}
          </div>
        </div>
        <IconButton2
          hoverable={true}
          icon="cross"
          size="xs"
          className="text-xs text-secondary-foreground/70"
          onClick={() => {
            setActive(false);
          }}
        />
      </div>
      {/* body */}
      <div
        className="h-[calc(100%-56px)] overflow-auto bg-background py-5 pb-24 text-secondary-foreground"
        ref={innerRef}
        onScroll={onScroll}>
        {/* search bar */}
        {user && (
          <div className="mx-5 mb-4 flex items-center rounded border border-border bg-card px-4 py-1">
            <UIcon icon="search" className="text-secondary-foreground/80" />
            <input
              type="text"
              placeholder={`Search by name${
                ["admin", "moderator"].includes(group.myRole)
                  ? ", mobile, email"
                  : ""
              }`}
              // value={query}
              onChange={(e: any) => {
                // setQuery(e.target.value);
                handleSearchDebounced(e.target.value);
              }}
              className="flex-grow bg-transparent px-4 py-1 focus:outline-none"
            />
          </div>
        )}
        {/* members list */}
        <ListView
          items={members}
          renderItem={(member, index) => (
            <div
              className="group-member-list-item mx-2 my-3 flex cursor-pointer items-center justify-between rounded px-2 py-1 hover:bg-primary-light"
              key={index}>
              <div className="flex items-center justify-center">
                <Avatar
                  user={member}
                  onClick={() => {
                    // open the user page
                    // window.open(createUserWallPageRoute(member.id, "activity"));
                    setIdToViewProfile(member.id);
                    setShowProfileModel(true);
                  }}
                  tag={
                    <>
                      {/* group tags */}
                      {member.groupRole === "admin" ? (
                        <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-blue-500">
                          Admin
                        </div>
                      ) : member.groupRole === "moderator" ? (
                        <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                          Moderator
                        </div>
                      ) : member.groupRole === "user" ? (
                        <></>
                      ) : (
                        <></>
                      )}
                    </>
                  }
                  extraInfo={[
                    member.userId ? "@" + member.userId : "",
                    member.mobile,
                    member.email,
                  ]
                    .filter((e: any) => e)
                    .join(" · ")}
                />
              </div>
              {/* show more options to admin */}
              {activeTabModel && activeTabModel.isClosed ? (
                <>
                  {/* closed section related */}
                  <ClosedSectionOperations
                    activeTabModel={activeTabModel}
                    member={member}
                    user={user}
                    group={group}
                    updateGroupMember={onMemberUpdate}
                  />
                </>
              ) : user && group.myRole === "admin" ? (
                <div className="flex items-center">
                  {/* group requests */}
                  {member.groupStatus === "requested" ? (
                    <>
                      <span
                        onClick={(e: any) => {
                          // call api to accept user in group
                          GroupService.acceptUserRequest(
                            user,
                            group.id,
                            member.id
                          ).then(({ group }) => {
                            // update the current memeber
                            onMemberUpdate({
                              ...member,
                              groupStatus: "joined",
                            });
                          });
                        }}
                        className="px-1 text-primary">
                        Accept
                      </span>
                      <span
                        onClick={(e: any) => {
                          // call api to accept user in group
                          GroupService.rejectJoinRequest(
                            user,
                            group.id,
                            member.id
                          ).then(({ group }) => {
                            // Remove the current member
                            const list = removeFromArray(
                              members,
                              "id",
                              member.id
                            );
                            setMembers(list);
                            removeGroupMember(member);
                          });
                        }}
                        className="px-1 text-alert">
                        Reject
                      </span>
                    </>
                  ) : null}
                  {member.tabStatus === "requested" && (
                    <AcceptRejectSectionMember
                      // @ts-ignore
                      member={member}
                      activeTabId={activeTabModel ? activeTabModel.id : null}
                      groupId={group.id}
                      user={user}
                      updateGroupMember={onMemberUpdate}
                    />
                  )}
                  {/* group admin operations */}
                  <IconMenu2
                    hideOnEmpty
                    dropdownClassName="w-52"
                    actions={[
                      {
                        icon: "edit",
                        label: "Change Role",
                        onClick: () => {
                          setSelectedUser(member);
                          setIsUpdateRoleModalVisible(true);
                        },
                      },
                      {
                        // button to remove group
                        icon: "user-delete",
                        label:
                          member.groupRole === "admin"
                            ? ""
                            : "Remove from group", // cant remove admin
                        onClick: (e: any) => {
                          if (
                            window.confirm(
                              "Are you sure you want to remove the user?"
                            )
                          ) {
                            // call user remove api
                            GroupService.removeUser(user, group.id, member.id)
                              .then(() => {
                                // Remove the current member
                                const list = removeFromArray(
                                  members,
                                  "id",
                                  member.id
                                );
                                setMembers(list);
                                removeGroupMember(member);
                              })
                              .catch((err) => {
                                console.log({ err });
                              });
                          }
                        },
                        actionType: "alert",
                      },
                    ]}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          loading={["loading", "idle"].includes(status)}
          placeholder={<UserListShimmer length={10} className="px-4" />}
          footerElement={
            status === "loadingMore" && (
              <UserListShimmer length={6} className="px-4" />
            )
          }
          noItemsElement={
            <div className="flex flex-col items-center justify-center">
              <Label variant="s2" className="mt-4">
                {"No member found"}
              </Label>
            </div>
          }
        />
      </div>
      {/* TODO: Handle updateClosedSectionMember */}
      {/* update role modal */}
      <UpdateRoleModal
        selectedUser={isUpdateRoleModalVisible && selectedUser}
        activeGroup={group}
        updateGroupMember={onMemberUpdate}
        unselectUser={(e: any) => {
          setIsUpdateRoleModalVisible(false);
          setSelectedUser(null);
        }}
      />
      {/* make admin modal */}
      <MakeAdminModal
        activeGroup={group}
        active={isMakeAdminModalVisible}
        setActive={setIsMakeAdminModalVisible}
        updateGroupMember={onMemberUpdate}
        selectedUser={selectedUser}
      />
      {/* update user group role */}
      {selectedUser ? (
        <UpdateUserGroupRoleModal
          role={selectedRole}
          activeGroup={group}
          active={isUpdateGroupRoleModalVisible}
          setActive={setIsUpdateGroupRoleModalVisible}
          updateGroupMember={onMemberUpdate}
          selectedUser={selectedUser}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

const ClosedSectionOperations = ({
  activeTabModel,
  member,
  user,
  group,
  updateGroupMember,
}: any) => {
  if (!group || group.myRole !== "admin") return <></>;

  return (
    <div className="ClosedSectionOperations">
      {activeTabModel && activeTabModel.isClosed ? (
        member.tabStatus === "requested" ? (
          <AcceptRejectSectionMember
            // @ts-ignore
            member={member}
            activeTabId={activeTabModel ? activeTabModel.id : null}
            groupId={group.id}
            user={user}
            updateGroupMember={updateGroupMember}
          />
        ) : member.tabStatus !== "joined" ? (
          <span
            onClick={(e: any) => {
              // call api to accept user in group
              GroupService.acceptJoinClosedSectionRequest(
                user,
                group.id,
                activeTabModel.id,
                member.id
              ).then(() => {
                // update the current memeber
                updateGroupMember({
                  ...member,
                  tabStatus: "joined",
                });
              });
            }}
            className="px-1 text-xs text-primary">
            Add to channel
          </span>
        ) : (
          <div className="flex">
            <span className="cursor-pointer text-xs text-green-300">
              joined
            </span>
            {member.groupRole !== "admin" ? (
              <span
                onClick={(e: any) => {
                  // call api to accept user in group
                  GroupService.rejectJoinClosedSectionRequest(
                    user,
                    group.id,
                    activeTabModel.id,
                    member.id
                  ).then(() => {
                    // update the current memeber
                    updateGroupMember({
                      ...member,
                      tabStatus: null,
                    });
                  });
                }}
                className="px-1 text-xs text-alert">
                {member.id !== user.id ? "Remove" : "Leave"}
              </span>
            ) : (
              <></>
            )}
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
