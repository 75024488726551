import { useState } from "react";
import { connect } from "react-redux";
import { withToast } from "../../../contexts/toastr.context";
import { Button } from "../../button.component";
import ModalForm from "../../model2";
import CreatePostComponent from "./component";

/**
 * Post create modal
 * @param {object} community
 * @param {Object} user - user object
 * @param {Object} group - Group in which post is being created. If post is being created from community home page, group is undefined.
 * @param {Array<Object>} groups - List of groups of community.
 * @param {string} sectionId - section id of section in which post is being created. If post is being created from community home page, sectionId is undefined.
 * @param {Function} addPost - function to add post.
 * @param {Function} addToast - function to add toast
 * @param {Element} inlineButtonComponent - inline button component
 * @param {Element} inlineDivComponent - Alternative to inlineButtonComponent. If inlineDivComponent is provided, inlineButtonComponent and default create post button will ignored.
 */
function CreatePostComponent1({
  community,
  user,
  group,
  groups,
  sectionId,
  addPost = (post: any) => {},
  addToast,
  inlineButtonComponent,
  inlineDivComponent,
}: any) {
  const [selectedGroup, setSelectedGroup] = useState(group);

  const [active, setActive] = useState(false);
  const [isModalFullScreen, setIsModalFullScreen] = useState(false);
  const [isAdvanceSettingsOpen, setIsAdvanceSettingsOpen] = useState(false);
  const [displayClosePrompt, setDisplayClosePrompt] = useState(false);

  if (!active && !inlineDivComponent) {
    if (inlineButtonComponent) {
      return (
        <button
          onClick={() => {
            setActive(true);
          }}>
          {inlineButtonComponent}
        </button>
      );
    }

    return (
      <Button
        label="Create Post"
        onClick={() => {
          if (window.innerWidth < 480) {
            setIsModalFullScreen(true);
          }
          setActive(true);
        }}
      />
    );
  }

  return (
    <>
      {inlineDivComponent && (
        <div
          onClick={() => {
            setActive(true);
          }}>
          {inlineDivComponent}
        </div>
      )}
      <ModalForm
        visible={active}
        setVisible={() => {
          setDisplayClosePrompt(true);
        }}
        className={`CreatePost flex w-full flex-col overflow-auto bg-card ${
          isModalFullScreen
            ? "max-h-[100vh] max-w-full"
            : "max-h-[95vh] max-w-[670px]"
        }`}
        hideHeader
        childrenClassName="p-0">
        <CreatePostComponent
          //@ts-ignore
          community={community}
          setActive={setActive}
          user={user}
          group={group}
          groups={groups}
          sectionId={sectionId}
          addPost={addPost}
          addToast={addToast}
          isModalFullScreen={isModalFullScreen}
          setIsModalFullScreen={setIsModalFullScreen}
          isAdvanceSettingsOpen={isAdvanceSettingsOpen}
          setIsAdvanceSettingsOpen={setIsAdvanceSettingsOpen}
          closePrompt={{ displayClosePrompt, setDisplayClosePrompt }}
        />
      </ModalForm>
    </>
  );
}

/**
 * Post create modal
 * @param {Object} group - Group in which post is being created. If post is being created from community home page, group is undefined.
 * @param {string} sectionId - section id of section in which post is being created. If post is being created from community home page, sectionId is undefined.
 * @param {Function} addPost - function to add post.
 * @param {Element} inlineButtonComponent - inline button component
 * @param {Element} inlineDivComponent - Alternative to inlineButtonComponent. If inlineDivComponent is provided, inlineButtonComponent and default create post button will ignored.
 */
const CreatePost = withToast(
  connect((s: any) => ({
    user: s.auth,
    groups: s.groups,
    community: s.community,
  }))(CreatePostComponent1)
);

// export default CreatePostComponent1;
export default CreatePost;
