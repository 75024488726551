import { useCallback, useMemo, useState } from "react";
import DirectChatPage from "../../_pages/direct-chat.page";
import RightSideModal from "../right-side-modal.component";

export function useDirectMessageModel() {
  const [showDirectMessageModel, setShowDirectMessageModel] = useState(false);
  const [userId, setIdToViewMessage] = useState("");

  const DirectMessageSidebarCallback = useCallback(() => {
    if (showDirectMessageModel && !userId) {
      console.error("User Id is required to view user dm");
      setShowDirectMessageModel(false);
      return <></>;
    }
    return (
      <RightSideModal
        active={showDirectMessageModel}
        setActive={setShowDirectMessageModel}
        className="mt-2 max-w-2xl rounded-t-2xl bg-card md:mt-0 md:rounded-l-2xl md:rounded-tr-none">
        <div className="h-full flex-1 bg-card">
          <DirectChatPage userId={userId} />
        </div>
      </RightSideModal>
    );
  }, [userId, showDirectMessageModel, setShowDirectMessageModel]);

  return useMemo(
    () => ({
      directMessageModelState: {
        setShowDirectMessageModel,
        setIdToViewMessage: setIdToViewMessage,
      },
      DirectMessageSidebarModel: DirectMessageSidebarCallback,
      setIdToViewMessage: setIdToViewMessage,
    }),
    [setShowDirectMessageModel, DirectMessageSidebarCallback]
  );
}
