import React from "react";
import { BillingPlans, PlanLimits } from "./plans";

interface LimitRowProps {
  label: string;
  values: React.ReactNode[];
}

const LimitRow: React.FC<LimitRowProps> = ({ label, values }) => (
  <div className="mt-2.5 min-w-[1000px] border-b border-border">
    <div className="flex justify-between">
      <div className="font-bold min-w-36">{label}</div>
      {values.map((value, index) => (
        <div key={index} className="text-left w-36">
          {value}
        </div>
      ))}
    </div>
  </div>
);

const PlanLimitsTable: React.FC = () => {
  const rows: Array<{ label: string; key: keyof PlanLimits; formatter?: (val: any) => React.ReactNode }> = [
    { label: "Members", key: "members" },
    { label: "Live Session", key: "liveSession" },
    { label: "Transaction Fees", key: "transactionFees" },
    { label: "SSO", key: "sso", formatter: (val: boolean) => (val ? "✔️" : "-") },
    { label: "API Access", key: "apiAccess", formatter: (val: boolean | string) => (typeof val === "string" ? val : val ? "✔️" : "-") },
  ];

  return (
    <div className="mt-5 w-full">
      {rows.map(({ label, key, formatter }) => (
        <LimitRow 
          key={key} 
          label={label} 
          values={BillingPlans.map(plan => formatter ? formatter(plan.limits[key]) : plan.limits[key])} 
        />
      ))}
    </div>
  );
};

export default PlanLimitsTable;
