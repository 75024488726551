import { useMemo } from "react";
import { useAppSelector } from "../../../hooks/redux.hook";
import { useAppService } from "../../../hooks/use-app-service";
import { Group } from "../../../types/group/group.type";
import LazyImage from "../../../_components/atoms/lazy-image";
import UIcon from "../../../_components/uicon-component";
import { history } from "../../../_config";
import { SubscriptionUtils } from "../../../_utils/subscription/subscription-util";
import { getGroupPageRouteFromSlug } from "../../group";
import JoinButton from "../../group/group-join-button.component";
interface GroupDetailsProps {
  user: any;
  group: Group;
  setGroup?: (e: any) => void;
}
export default function ExploreGroupCard({
  group,
  setGroup,
  user,
}: GroupDetailsProps) {
  const { analyticsService } = useAppService();

  const subscriptionsPlan = useAppSelector((state) => state.subscriptionsPlan);

  const plans = useMemo(
    () =>
      SubscriptionUtils.filterSubscriptionsForGroup(
        group?.id ?? "",
        subscriptionsPlan
      ),
    [subscriptionsPlan, group?.id]
  );

  // If any subscription plan contains the group id, then it is a paid group
  // Here if plan is not null and any of the subscription plan contains the group id, then it is a paid group
  const isPaidGroup = SubscriptionUtils.hasPaidSubscription(plans);

  return (
    <div
      key={group.id}
      onClick={(e) => {
        analyticsService.track("group-clicked-in-explore-groups", {
          joinStatus: group.joinStatus,
        });
        history.push(getGroupPageRouteFromSlug(group));
      }}
      className="flex aspect-square h-full w-full flex-grow flex-col rounded-lg bg-card text-secondary-foreground">
      {/* background image */}
      <div className="aspect-video w-full overflow-hidden rounded-t-lg bg-primary-light">
        {group.banner && (
          <LazyImage
            src={group.banner}
            alt="Group Banner"
            className="aspect-video h-full w-full rounded-t object-cover"
            width={325}
            height={180}
          />
        )}
      </div>
      {/* group name */}
      <div className="flex flex-grow flex-col place-content-around gap-1.5 p-4">
        <h2 className="line-clamp-1 w-full text-ellipsis text-sm font-semibold tracking-tight">
          {group.name}
        </h2>
        <div className="flex w-full items-center justify-between py-1">
          <span className="text-sec mt-1 flex items-center gap-2 rounded bg-accent px-3 py-0.5 text-xs">
            <UIcon icon="user" className={"text-secondary-foreground/70"} />
            <span className="font-semibold text-secondary-foreground">
              {group?.userCount}
            </span>
          </span>

          <span className="text-sec mt-1 flex items-center gap-2 rounded bg-accent px-3 py-0.5 text-xs">
            {group.groupType !== "open" && (
              <UIcon icon={"lock"} className={"text-secondary-foreground/70"} />
            )}
            <span className="font-semibold text-secondary-foreground">
              {[
                group.groupType === "open"
                  ? "Open"
                  : isPaidGroup
                    ? "Paid Group"
                    : "Private Group",
              ]}
            </span>
          </span>
        </div>

        <div className="flex w-full flex-col">
          <JoinButton
            group={group}
            setGroup={setGroup}
            user={user}
            openGroupOnJoined
          />
        </div>
      </div>
    </div>
  );
}
