import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { APP_LAYOUT } from "../../contexts/base-config.context";
import { withToast } from "../../contexts/toastr.context";
import { AppLayoutWrapper } from "../../_components/app-layout-wrapper";
import CommunityWelcomeMessages from "../../_components/community/community-welcome-messages.component";
import CreateGroupSidebarModal from "../../_components/group/create-group-modal-v2.component";
import RightSideModal from "../../_components/right-side-modal.component";
import { history } from "../../_config";
import { CommunityService, WidgetService } from "../../_service";
import { setCommunity } from "../../_store/_actions/community.actions";
import { setGroups } from "../../_store/_actions/group.actions";
import CommunityBannerFooterComponent from "./community-banner-footer.component";
import CommunityInfo from "./community-info.component";
import ExploreGroupComponent from "./explore-groups/explore-group";
import HomePagePostList from "./home-page-post-list.component";

export const COMMUNITY_HOMEPAGE_ROUTE = "/";

const availableCommunityTabs = [
  "Posts",
  "Groups",
  // "Events",
  // "Members",
  // "About"
];

function CommunityHomePageComponent({
  community,
  setCommunity,
  user,
  addToast,
  groups = [],
  isLoadingGroups = false,
  setGroups = (e) => {},
  provider,
  getWeb3Accounts = () => {},
}) {
  // holds the selected currentPage
  const [selectedTab, setSelectedTab] = useState(availableCommunityTabs[0]); // can have Posts/Events/Members/About

  // holds the selected currentPage
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [sortOrder, setSortOrder] = useState("recent");

  // helps with pagination
  const [isLoadingMorePosts, setIsLoadingMorePosts] = useState(false);
  const [noMorePosts, setNoMorePosts] = useState(false);

  const token = user ? user.token : null;

  const [widgets, setWidgets] = useState();

  const [createGroupModalVisible, setCreateGroupModalVisible] = useState(false);

  const hasPrimaryPermission = ["admin", "moderator"].includes(
    community?.myRole
  );

  const handleGroupModalVisibility = () => {
    setCreateGroupModalVisible(!createGroupModalVisible);
  };

  const red = async () => {
    console.log("Wallet address: ", await getWeb3Accounts());
  };

  useEffect(() => {
    if (provider) {
      red();
    }
  }, [provider]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname === COMMUNITY_HOMEPAGE_ROUTE) {
        setSelectedTab("Posts");
      }
    });

    // Call the function on component mount
    setSelectedTab(
      window.location.pathname === COMMUNITY_HOMEPAGE_ROUTE
        ? "Posts"
        : selectedTab
    );

    return () => {
      unlisten();
    };
  }, []);

  // check for tab change
  useEffect(() => {
    // selected tab onLoad
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    if (availableCommunityTabs.includes(tab)) {
      setSelectedTab(tab);
    } else {
      if (selectedTab !== "Posts") setSelectedTab("Posts");
    }
    const unListen = history.listen((location, action) => {
      // selected tab onLoad
      const urlParams = new URLSearchParams(window.location.search);
      const tab = urlParams.get("tab");
      if (availableCommunityTabs.includes(tab)) {
        setSelectedTab(tab);
      } else {
        if (selectedTab !== "Posts") setSelectedTab("Posts");
      }
    });

    return () => {
      unListen();
    };
  }, []);

  // check if user logged in
  useEffect(() => {
    if (
      !token &&
      community?.configuration?.setExploreGroupsAsLandingPageForNonLoggedinUsers
    ) {
      history.push("/?tab=Groups");
    }
  }, [token, community]);

  // fetch user posts and details
  useEffect(() => {
    if (community.id) {
      // get user posts
      setIsLoadingPosts(true);
      CommunityService.getCommunityPostDirectoryById(
        { token },
        community.id,
        sortOrder
      )
        .then(({ posts, page }) => {
          setPosts(posts);
          setCurrentPage(page);
        })
        .finally(() => {
          setIsLoadingPosts(false);
        });
    }
  }, [token, community.id, sortOrder]); // no need to track user

  // post lazy loading
  useEffect(() => {
    // create callback
    const callBack = (event) => {
      const window = event.currentTarget;
      let scrollTop = window.scrollY;
      let scrollHeight = document.body.scrollHeight;
      let clientHeight = window.innerHeight;
      if (
        scrollTop + clientHeight === scrollHeight ||
        scrollTop + clientHeight + 2 > scrollHeight
      ) {
        // you're at the bottom of the page
        // do this when we reach end
        // and are viewing posts and not bookmark
        // if we are not already loading more posts, load more posts
        if (
          !isLoadingPosts &&
          !isLoadingMorePosts &&
          !noMorePosts &&
          selectedTab === "Posts"
        ) {
          setIsLoadingMorePosts(true);
          CommunityService.getCommunityPostDirectoryById(
            { token },
            community.id,
            sortOrder,
            currentPage + 1
          )
            .then((response) => {
              // if there are no more posts, set so
              if (response.posts.length === 0) {
                setNoMorePosts(true);
              } else {
                const updatedPostsList = posts;
                for (let post of response.posts) {
                  if (
                    updatedPostsList.findIndex((p) => p.id === post.id) === -1
                  ) {
                    updatedPostsList.push(post);
                  }
                }
                setPosts(updatedPostsList);
                setCurrentPage(response.page);
              }
            })
            .finally(() => {
              setIsLoadingMorePosts(false);
            });
        }
      }
    };
    window.addEventListener("scroll", callBack);
    return () => {
      window.removeEventListener("scroll", callBack);
    };
  }, [
    isLoadingMorePosts,
    isLoadingPosts,
    token,
    currentPage,
    posts,
    noMorePosts,
    sortOrder,
    community.id,
    selectedTab,
  ]);

  // Get widgets for community
  useEffect(() => {
    if (community?.id) {
      WidgetService.getWidgets(community.id, "")
        .then(({ widgets: wid }) => {
          let widList;
          if (wid !== undefined) {
            // Hide Group leaderboard and group members from home widget
            widList = wid.filter(
              (w) => w.type !== "members" && w.type !== "leaderboard"
            );
          }
          setWidgets(widList);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [community?.id]);

  const updateGroup = (group) => {
    const oldGroups = [...groups];
    const groupIndex = oldGroups.findIndex((g) => g.id === group.id);
    if (groupIndex > -1) {
      oldGroups.splice(groupIndex, 1, group);
      setGroups(oldGroups);
    }
  };

  return (
    <AppLayoutWrapper layout={APP_LAYOUT.TWO_COLUMN}>
      <div className="CommunityHomePage flex">
        <div className="main-content">
          <CommunityInfo
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            community={community}
            availableCommunityTabs={availableCommunityTabs}
            user={user}
            addToast={addToast}
            setCommunity={setCommunity}
          />
          {selectedTab === "Posts" && (
            <div className="sticky top-[47px] z-[2]">
              <CommunityBannerFooterComponent
                name="Home"
                posts={posts}
                setPosts={setPosts}
                isCreatePostButton={true}
              />
            </div>
          )}
          {selectedTab === "Groups" && (
            <div className="sticky top-[47px] z-[2]">
              <CommunityBannerFooterComponent
                name="Explore Groups"
                buttonLabel={hasPrimaryPermission && "Create Group"}
                onClick={handleGroupModalVisibility}
              />
            </div>
          )}

          {/* TODO: Move this to explore group  page*/}
          <RightSideModal
            width={570}
            setActive={setCreateGroupModalVisible}
            active={createGroupModalVisible}>
            <CreateGroupSidebarModal
              isEditGroupModalVisible={createGroupModalVisible}
              setIsEditGroupModalVisible={setCreateGroupModalVisible}
            />
          </RightSideModal>
          <div className="main">
            <CommunityTab
              user={user}
              tab={selectedTab}
              groups={groups}
              isLoadingGroups={isLoadingGroups}
              community={community}
              posts={posts}
              setPosts={setPosts}
              isLoadingPosts={isLoadingPosts}
              isLoadingMorePosts={isLoadingMorePosts}
              updateGroup={updateGroup}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              addToast={addToast}
              widgets={widgets}
              setWidgets={setWidgets}
              hasMorePost={noMorePosts}
            />
          </div>
          <div className="secondary-sidebar pr-5"></div>
        </div>
        {/* community welcome messages */}
        <CommunityWelcomeMessages user={user} community={community} />
      </div>
    </AppLayoutWrapper>
  );
}

function CommunityTab({
  tab,
  user,
  groups,
  community,
  posts,
  setPosts,
  addToast,
  widgets,
  setWidgets,
  isLoadingPosts = false,
  isLoadingGroups = false,
  hasMorePost = false,
  isLoadingMorePosts = false,
  updateGroup = (e) => {},
  sortOrder = "recent",
  setSortOrder = (e) => {},
}) {
  switch (tab) {
    case "Posts":
      return (
        <HomePagePostList
          community={community}
          user={user}
          groups={groups}
          widgets={widgets}
          isLoadingMorePosts={isLoadingMorePosts}
          posts={posts}
          setPosts={setPosts}
          isLoadingPosts={isLoadingPosts}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          addToast={addToast}
          onWidgetListUpdate={(list) => {
            setWidgets(list);
          }}
          hasMorePost={hasMorePost}
        />
      );
    case "Groups":
      return (
        <div className="min-h-screen">
          <ExploreGroupComponent
            user={user}
            groups={groups}
            setGroup={updateGroup}
            isLoadingGroups={isLoadingGroups}
          />
        </div>
      );
    default:
      return <InvalidTab />;
  }
}

function InvalidTab({ tab }) {
  return (
    <div
      className="no-post my-10 p-2 text-center"
      style={{ minWidth: "476px" }}>
      <div className="my-3 font-bold">
        Looks like this resource is not available.
      </div>
      <div>Try exploring other tabs</div>
    </div>
  );
}

const stp = (state) => ({
  user: state.auth,
  groups: state.groups,
  isLoadingGroups: state.isLoadingGroups,
  community: state.community,
});

const dtp = (dispatch) =>
  bindActionCreators(
    { setGroups: setGroups, setCommunity: setCommunity },
    dispatch
  );

const CommunityHomePage = withToast(
  connect(stp, dtp)(CommunityHomePageComponent)
);

export default CommunityHomePage;
