import MEDIA_TYPES from "../../../../_constants/mediaType";
import { shortenText } from "../../../../_utils/extensions/typescript-utils";
import UIcon from "../../../uicon-component";

/**
 * Component to display documents preview
 * @param {Array<File>} documents - file to upload
 * @param {Function} setDocument - function to set documents
 */
export default function DocumentPreview({
  documents = null,
  setDocument = (e) => {},
  setUploadedMedia = () => {},
  setRemovedMediaUploadsOnEdit = () => {},
  areFilesUploading = false,
}) {
  if (!documents || documents.length === 0) {
    return null;
  }
  if (typeof documents !== "object") {
    documents = [documents];
  }

  const audioFormat = ["wav", "mp3", "aac", "amr"];

  const doc = documents[0];
  return doc ? (
    <div className="SelectedDocumentPreview relative my-0.5">
      {audioFormat.includes(doc.name.split(".").pop()) ? (
        <div className="flex items-center space-x-2 rounded-md pb-2">
          <audio
            className="m-0 w-full rounded-md"
            controls
            controlsList="nodownload">
            <source src={URL.createObjectURL(doc)} />
          </audio>
        </div>
      ) : (
        <div className="flex items-center space-x-2 rounded border-2 border-border px-4 py-2 font-semibold">
          <UIcon icon="document" className="h-9 text-3xl" />
          <div className="flex flex-grow flex-col justify-between">
            <div>{shortenText(doc.name, 60, false)}</div>
            <div className="text-xs uppercase">
              {doc?.name?.split(".")?.pop() ?? "file"}
            </div>
          </div>
        </div>
      )}

      <div
        onClick={(e) => {
          // remove the document
          setDocument([]);
          console.log(doc);
          setRemovedMediaUploadsOnEdit((prevMediaItems) => [
            ...prevMediaItems,
            { mediaItem: doc, type: MEDIA_TYPES.DOCUMENT },
          ]);
          setUploadedMedia((prevUploadedMedia) => ({
            ...prevUploadedMedia,
            document: [],
          }));
        }}
        className={`${areFilesUploading ? "hidden" : "remove"}`}>
        &times;
      </div>
    </div>
  ) : (
    <></>
  );
}
