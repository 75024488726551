import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import useLang from "../../hooks/use-lang.hook";
import useModel from "../../hooks/use-model.hook";
import plusGrayIcon from "../../_assets/vectors/plus-gray-icon.svg";
import questionIcon from "../../_assets/vectors/question-icon.svg";
import { CommunityService } from "../../_service";
import I18 from "../atoms/i18";
import { Avatar } from "../avatar.component";
import IconButton from "../icon-button.component";
import { Loader } from "../loader.component";
import { Modal } from "../modal.component";
import MainUtil from "../../_utils/main.util";

/**
 * Get community subscribers with group role
 * @param {*} param0
 * @returns
 */
function CommunityChatMemberListComponent({
  user,
  community,
  isSubscriberListVisible = false,
  setIsSubscriberListVisible = (e) => {},
}) {
  // get list of subscribers
  const [subscribers, setSubscribers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // helps with pagination
  const [isLoadingMoreSubscribers, setIsLoadingMoreSubscribers] =
    useState(false);
  const [noMoreSubscribers, setNoMoreSubscribers] = useState(false);
  const [page, setPage] = useState(1);

  // search filter
  const [query, setQuery] = useState("");

  const innerRef = useRef();
  const lang = useLang();
  const { DirectMessageModelState } = useModel();

  // load the subscriber list once, or per community or group change
  useEffect(() => {
    setIsLoading(true);
    // console.log([user, group, community.id, group.id]);
    CommunityService.getCommunitySubscribersPaginated(
      user,
      community.id,
      10,
      1,
      query
    )
      .then(({ users }) => {
        setSubscribers(users.filter((u) => u.id !== user.id));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error({ error });
        setIsLoading(false);
        if (MainUtil.checkIfBlocked(error)) {
          console.log("[Block] Unauthorize error ");
        }
      });
  }, [user, community.id]);

  const handleSearchDebounced = React.useCallback(
    debounce((query) => {
      setIsLoading(true);
      CommunityService.getCommunitySubscribersPaginated(
        user,
        community.id,
        10,
        1,
        query
      )
        .then(({ users }) => {
          setSubscribers(users.filter((u) => u.id !== user.id));
          setIsLoading(false);
          setNoMoreSubscribers(false);
        })
        .catch((error) => {
          console.error({ error });
          setIsLoading(false);
        });
    }, 1000),
    [community.myRole]
  );

  const subscribersList = query
    ? subscribers.filter((s) =>
        (s.name + "").toLowerCase().includes(query.toLowerCase())
      )
    : subscribers;

  const onScroll = React.useCallback(() => {
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // subscribers list lazy loading you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMoreSubscribers && !noMoreSubscribers) {
          const pageCount = page + 1;
          // if we are not already loading more subscribers, load more subscribers
          setIsLoadingMoreSubscribers(true);
          CommunityService.getCommunitySubscribersPaginated(
            user,
            community.id,
            10,
            pageCount,
            query
            // group.id
          )
            .then((response) => {
              if (response.users.length === 0) {
                setNoMoreSubscribers(true);
              } else {
                setSubscribers([...subscribers, ...response.users]);
                setPage(pageCount);
              }
              setIsLoadingMoreSubscribers(false);
            })
            .catch((error) => {
              console.error({ error });
              setIsLoading(false);
            });
        }
      }
    }
  }, [isLoadingMoreSubscribers, noMoreSubscribers, page, query, subscribers]);

  return (
    <Modal
      setActive={setIsSubscriberListVisible}
      active={isSubscriberListVisible}
      className="CommunityChatMemberList"
      padding={false}>
      {/* headers */}
      <div className="flex items-center justify-between bg-card py-2 pl-4 pr-2">
        <span className="font-bold text-secondary-foreground/85">
          {community.name} - {subscribers.length} <I18>Subscribers</I18>
        </span>
        <IconButton
          icon={plusGrayIcon}
          rotate={45}
          onClick={() => {
            setIsSubscriberListVisible(false);
          }}
        />
      </div>
      {/* body */}
      <div className="p-5 pb-24 text-secondary-foreground/85">
        {/* search bar */}
        <div className="mb-4 flex items-center rounded border border-border bg-card px-4 py-1">
          <img
            src={questionIcon}
            alt=""
            style={{ filter: "opacity(0.5)", width: "18px" }}
          />
          <input
            type="text"
            placeholder={lang.trans("Search")}
            value={query}
            onChange={(e) => {
              setIsLoading(true);
              setQuery(e.target.value);
              handleSearchDebounced(e.target.value);
            }}
            className="flex-grow bg-transparent px-4 py-1 focus:outline-none"
          />
          {query.length > 0 ? (
            <i
              onClick={() => {
                setIsLoading(true);
                setQuery("");
                handleSearchDebounced("");
              }}
              className="fi fi-rr-cross cursor-pointer pr-1 text-sm text-secondary-foreground/85"
            />
          ) : null}
        </div>
        {/* subscribers list Loading */}
        {isLoading ? (
          <div className="p-1">
            <Loader />
          </div>
        ) : (
          <></>
        )}
        {/* subscribers list */}
        <div
          onScroll={onScroll}
          ref={innerRef}
          className="h-96 overflow-y-scroll">
          {subscribersList.map((member) => (
            <div
              className="m-3 flex cursor-pointer items-center justify-between"
              key={member.id}>
              <div className="flex items-center justify-center">
                <Avatar
                  user={member}
                  onClick={() => {
                    // open the user chat page
                    setIsSubscriberListVisible(false);
                    // history.push(getDirectMessagePageRoute(member.id));
                    DirectMessageModelState.setIdToViewMessage(member.id);
                    DirectMessageModelState.setShowDirectMessageModel(true);
                  }}
                  tag={
                    <>
                      {member.communityRole === "admin" ? (
                        <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-blue-500">
                          <I18>Community Admin</I18>
                        </div>
                      ) : (
                        <></>
                      )}
                      {member.groupRole === "admin" ? (
                        <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-blue-500">
                          <I18>Admin</I18>
                        </div>
                      ) : member.groupRole === "moderator" ? (
                        <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                          <I18>Moderator</I18>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  }
                  // extraInfo={[member.mobile, member.email].filter(e => e).join(" · ")}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

const CommunityChatMemberList = connect((s) => ({
  user: s.auth,
  group: s.activeGroup,
  community: s.community,
}))(CommunityChatMemberListComponent);

export default CommunityChatMemberList;
