import constants from "../../_config/constants.config";
import { CommunityService } from "../../_service";

export const SET_ONBOARDING_CHECKLIST_DISMISS =
  "SET_ONBOARDING_CHECKLIST_DISMISS";
export const SET_SELECTED_PARENT_ONBOARDING_SECTION =
  "SET_SELECTED_PARENT_ONBOARDING_SECTION";
export const SET_ONBOARDING_CHECKLIST_DETAILS =
  "SET_ONBOARDING_CHECKLIST_DETAILS";

export const setOnboardingDismissState = (isDismiss: boolean) => ({
  type: SET_ONBOARDING_CHECKLIST_DISMISS,
  isDismiss,
});

export const setSelectedParentOnboardingSection = (sectionKey: string) => ({
  type: SET_SELECTED_PARENT_ONBOARDING_SECTION,
  sectionKey,
});

// Function to check if all values in an object are true
const allValuesTrue = (obj: any): boolean => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (!allValuesTrue(obj[key])) {
        return false;
      }
    } else if (obj[key] !== true) {
      return false;
    }
  }
  return true;
};

// Updated function
export const setOnboardingChecklistDetails = (details: any) => {
  // Determine if all values are true
  const allTrue = allValuesTrue(details);
  return {
    type: SET_ONBOARDING_CHECKLIST_DETAILS,
    details,
    allTrue
  };
};


/**
 * Get onboarding checklist dismiss state
 */
export function getOnboardingChecklistDimissStateAction() {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }
      const response = await CommunityService.getConfigurable(
        community.id,
        constants.COMMUNITY_SHOW_ONBOARDING_LIST
      );
      const showOnboardingList =
        response.configurable.configurableValue === "true" ||
        response.configurable.configurableValue === true;
      dispatch(setOnboardingDismissState(showOnboardingList));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getOnboardingChecklistData() {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }
      const response = await CommunityService.getCommunityOnboardingListData(
        community.id,
      );
      
    dispatch(setOnboardingChecklistDetails(response.onboardingChecklist));
    } catch (error) {
      console.log(error);
    }
  };
}