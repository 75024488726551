import cx from "classnames";
import React from "react";
import { cn } from "../button.component";
interface Props {
  className?: string;
  dropdownClassName?: string;
  button: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  alignment?: "left-1" | "right-1";
}
/**
 * custom Drop down component
 * @param {*} button - button component
 * @param {any} children - children component to be displayed in drop down
 * @param {string} className - The class name of the drop down
 * @param {boolean} disabled - Boolean to determine if the drop down is disabled
 * @param {string} alignment - The alignment of the dropdown
 */
export default function CustomDropDown({
  button = <h3>Drop Down</h3>,
  className = "",
  dropdownClassName = "",
  children = <></>,
  disabled = false,
  alignment = "right-1",
}: Props) {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      tabIndex={0}
      className={cn(`customDropdown relative z-[1] text-left ${className}`)}>
      <button
        className={cx(
          "inline-flex w-full items-center justify-between space-x-1 rounded-md text-sm font-medium leading-5 transition duration-150 ease-in-out",
          {
            "theme-bg-disable cursor-not-allowed text-secondary-foreground/80":
              disabled,
            "focus:shadow-outline-blue text-secondary-foreground hover:text-secondary-foreground/85 focus:border-primary focus:outline-none":
              !disabled,
          }
        )}
        type="button"
        disabled={disabled}
        aria-haspopup="true"
        aria-expanded="true">
        {button}
      </button>

      <div
        className={cx(
          "dropdown-menu invisible -translate-y-2 scale-95 transform opacity-0 transition-all duration-300",
          {
            "right-0 top-0 origin-top-right": alignment === "right-1",
            "left-0 top-0 origin-top-left": alignment === "left-1",
          }
        )}>
        <div
          className={cn(
            cx(
              "absolute z-50 mt-2 w-max origin-top-right divide-y divide-[var(--theme-border-color)] rounded-md border border-border bg-card shadow-lg outline-none",
              {
                "right-1": alignment === "right-1",
                "left-1": alignment === "left-1",
              }
            ),
            dropdownClassName
          )}
          onClick={() => {
            ref.current?.blur();
          }}
          role="menu">
          {children}
        </div>
      </div>
    </div>
  );
}
