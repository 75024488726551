import PostListShimmer, {
  PostTileType,
} from "./post/post-list-shimmer.component";

type Props = {};

const EventDetailShimmer = (props: Props) => {
  const list: any[] = [];
  return (
    <div>
      <div className="mx-auto w-full animate-pulse pt-0">
        {/* header */}
        <div className="flex justify-between rounded-md border-b bg-white p-6">
          <div className="h-6 w-12 rounded bg-gray-300" />
          <div className="h-6 w-12 rounded bg-gray-300" />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="mx-auto my-2 h-fit w-full rounded bg-card p-2 md:min-w-[670px] md:max-w-[670px]">
          <div className="theme-bg-disable h-64 w-full rounded-xl" />
          <div className="my-3 rounded px-6 pt-4">
            {/* title */}
            <div className="flex place-items-center">
              <p className="theme-bg-disable h-8 w-8 rounded-full"></p>
              <div className="ml-2 w-max space-y-1 rounded-xl">
                <p className="theme-bg-disable h-2 w-28"></p>
              </div>
            </div>
            {/* Space */}
            <div className="my-6" />

            {/* Description */}
            <div className="theme-bg-disable mt-2 h-2 w-1/2 rounded-xl" />
            <div className="theme-bg-disable mt-2 h-16 w-full rounded-xl" />
          </div>
        </div>
        {/* widgets */}
        <div className="PostList my-2 h-fit w-full rounded bg-card p-2 md:mx-auto md:min-w-[670px] md:max-w-[670px] lg:mr-6 lg:block lg:min-w-[364px] lg:max-w-[364px]">
          <PostListShimmer length={3} type={PostTileType.compact} />
        </div>
      </div>
    </div>
  );
};

export default EventDetailShimmer;
