import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Group } from "../../types/group/group.type";
import { MySubscription } from "../../types/subscription/my-subscription-plan";
import {
  GroupSubscriptionPlans,
  SubscriptionPlan,
} from "../../types/subscription/subscription-plan";
import { Button } from "../../_components";
import I18 from "../../_components/atoms/i18";
import { Modal } from "../../_components/modal.component";
import ModalForm from "../../_components/model2";
import { ListView } from "../../_components/molecule/listview.component";
import TabV2 from "../../_components/tab-component-v2";
import Label from "../../_components/typography/label.typography";
import UIcon from "../../_components/uicon-component";
import { loadScript } from "../../_config/helper.config";
import visibilityTypes from "../../_constants/visibilityTypes";
import { SubscriptionService } from "../../_service";
import { RazorPayCheckoutService } from "../../_service/payment-service/razor-pay/razor-pay-checkout-service";
import {
  OrderDetails,
  RazorpaySuccessResponse,
  SubscriptionDetails,
} from "../../_service/payment-service/types/razor-pay-type";
import { BillingDetails } from "../../_store/subscription/my-subscription/my-subscription.reducer";
import { Fold } from "../../_utils/extensions/typescript-utils";
import { SubscriptionUtils } from "../../_utils/subscription/subscription-util";
import { AddressCheckout } from "../user-management-settings/subscriptions/billing-address/billing-adress";
import { CurrencySymbol } from "../user-management-settings/subscriptions/component/subscription_tile.component";

interface Props {
  user: {
    name: string;
    email: string;
    mobile: string;
  } & { [key: string]: any };
  group?: Group;
  community: any;
  plans: GroupSubscriptionPlans;
  header?: string;
  active: boolean;
  labelCancel?: string;
  labelSubmit?: string;
  btnColor?: string;
  disabled?: boolean;
  mySubscription?: MySubscription;
  onPaymentSuccess?: (data: any) => void;
  setActive: (active: boolean) => void;
}

/**
 * Displays all the subscription plans for a group
 */
const GroupSubscriptionCheckoutModel: React.FC<Props> = ({
  group,
  community,
  user,
  plans,
  header,
  active = false,
  labelCancel = "Cancel",
  labelSubmit = "Yes, Go Ahead",
  btnColor,
  mySubscription,
  disabled = false,
  onPaymentSuccess = (e) => {},
  setActive = (e) => {},
}) => {
  type Tab = "Monthly" | "Quaterly" | "Half Yearly" | "Yearly" | "One Time";
  type Status = "idle" | "loading" | "success" | "error";

  const [selectedSubscription, setSelectedSubscription] =
    useState<SubscriptionPlan | null>(null);
  const [activeTab, setActiveTab] = useState<Tab>("Monthly");
  const [status, setStatus] = useState<Status>("idle");
  const [checkoutUrl, setCheckoutUrl] = useState<string | undefined>();
  const [isDisplayAddressCheckout, setIsDisplayAddressCheckout] =
    useState(false);

  var tabs = Array<Tab>();
  if (plans.oneMonthlySubscriptions?.length) tabs.push("Monthly");
  if (plans.threeMonthlySubscriptions?.length) tabs.push("Quaterly");
  if (plans.sixMonthlySubscriptions?.length) tabs.push("Half Yearly");
  if (plans.twelveMonthlySubscriptions?.length) tabs.push("Yearly");
  if (plans.oneTimeSubscriptions?.length) tabs.push("One Time");

  useEffect(() => {
    if (plans.oneMonthlySubscriptions?.length) setActiveTab("Monthly");
    else if (plans.threeMonthlySubscriptions?.length) setActiveTab("Quaterly");
    else if (plans.sixMonthlySubscriptions?.length) setActiveTab("Half Yearly");
    else if (plans.twelveMonthlySubscriptions?.length) setActiveTab("Yearly");
    else if (plans.oneTimeSubscriptions?.length) setActiveTab("One Time");
  }, [plans, mySubscription]);

  // load script
  useEffect(() => {
    // if razorpay is not already initialized
    // @ts-ignore
    if (!window.Razorpay) {
      // load razorpay
      loadScript("https://checkout.razorpay.com/v1/checkout.js").then(
        async (result) => {
          if (!result) {
            return alert("Could not load razorpay!");
          } else {
            console.log("razorpay loaded");
          }
          // add script
        }
      );
    }
  }, []);

  function filterSubscriptions() {
    switch (activeTab) {
      case "Monthly":
        return plans.oneMonthlySubscriptions ?? [];
      case "Quaterly":
        return plans.threeMonthlySubscriptions ?? [];
      case "Half Yearly":
        return plans.sixMonthlySubscriptions ?? [];
      case "Yearly":
        return plans.twelveMonthlySubscriptions ?? [];
      case "One Time":
        return plans.oneTimeSubscriptions ?? [];
      default:
        return [];
    }
  }

  // Subscribe to a free plan
  const handleFreeSubscriptions = (subscriptionId: string) => {
    if (!subscriptionId) return;
    setStatus("loading");
    SubscriptionService.subscribeToFreePlan(community.id, subscriptionId)
      .then(({ subscription }) => {
        setStatus("success");
        onPaymentSuccess(subscription);
      })
      .catch((err) => {
        setStatus("error");
        console.log(err);
      });
  };

  // Initiate subscription on click
  const initiateSubscription = (
    plan: SubscriptionPlan,
    billingDetails: BillingDetails
  ) => {
    if (!plan || !plan.id) return;
    setStatus("loading");
    SubscriptionService.initiateSubscription(
      community.id,
      plan.id!,
      billingDetails
    )
      .then(({ orderDetails, subscriptionDetails, paymentGateway }) => {
        setStatus("success");

        // If payment gateway is razorpay
        if (paymentGateway === "razorpay") {
          handleCheckoutRazorPay(
            orderDetails,
            subscriptionDetails,
            plan.isRecurring ?? false
          );
        }
      })
      .catch((err) => {
        setStatus("error");
        console.log(err);
      });
  };

  // Handle checkout with razorpay
  const handleCheckoutRazorPay = (
    oneTimeOrderDetails: OrderDetails,
    recurringOrderDetails: SubscriptionDetails,
    isRecurring: boolean
  ) => {
    // console.log("handleCheckoutRazorPay", {
    //   isRecurring,
    //   oneTimeOrderDetails,
    //   recurringOrderDetails,
    // });
    if (isRecurring && !recurringOrderDetails) {
      console.error("Order details not found");
      return;
    } else if (!isRecurring && !oneTimeOrderDetails) {
      console.error("Order details not found");
      return;
    }

    if (isRecurring) {
      const url = RazorPayCheckoutService.initiateRecurringPayment(
        community,
        recurringOrderDetails,
        user,
        onSuccess
      );
      setCheckoutUrl(url);
    } else {
      const url = RazorPayCheckoutService.initiateOneTimePayment(
        community,
        oneTimeOrderDetails!,
        user,
        onSuccess
      );
      setCheckoutUrl(url);
    }
  };

  function onSuccess(data: RazorpaySuccessResponse) {
    onPaymentSuccess(data);
    setActive(false);
    setCheckoutUrl(undefined);
  }
  if (checkoutUrl) {
    // Load Payment Gateway
    return (
      <ModalForm
        visible={checkoutUrl !== undefined}
        setVisible={() => {
          const isConfirmed = window.confirm(
            "Are you sure you want to cancel the payment?"
          );
          if (isConfirmed) {
            setCheckoutUrl(undefined);
          }
        }}
        hideHeader={true}
        childrenClassName="p-0 h-full border border-border bg-transparent w-full rounded"
        className="flex h-full max-h-screen w-full max-w-full place-content-center items-center overflow-y-auto bg-transparent sm:m-4 sm:max-h-[90vh] sm:max-w-[412px]">
        <iframe
          src={checkoutUrl}
          className="h-full w-full rounded"
          title="Checkout"></iframe>
      </ModalForm>
    );
  }
  return (
    <Modal
      className="SubscriptionCheckoutModel backdrop-blur-md"
      active={active}
      setActive={setActive}
      width="720px"
      padding={false}>
      <div className="flex flex-col items-center justify-between space-x-2 rounded-[20px] bg-card">
        <div className="flex justify-center px-6 pb-2.5 pt-5">
          <Label variant="t1" className="text-base font-bold">
            {header}
          </Label>
          <div className="text-xxs ml-2.5 rounded bg-accent px-2.5 py-0.5 text-secondary-foreground">
            {group?.groupType === visibilityTypes.GROUP.CLOSED ? (
              <div className="flex items-center gap-1.5">
                <UIcon icon="lock" size="xxs" />
                <span>Closed</span>
              </div>
            ) : group?.groupType === visibilityTypes.GROUP.CLOSED &&
              group?.isOnlyVisibleToMembers ? (
              <div className="flex items-center gap-1.5">
                <UIcon icon="eye-crossed" size="xxs" />
                <span>Private</span>
              </div>
            ) : (
              <div className="flex items-center gap-1.5">
                <UIcon icon="unlock" size="xxs" />
                <span>Open</span>
              </div>
            )}
          </div>
        </div>
        <p className="px-6 pb-5 text-xs font-semibold text-secondary-foreground/70">
          {group?.description}
        </p>
      </div>
      <div className="bg-card">
        <>
          <div className="flex justify-center whitespace-nowrap border-b border-t border-border bg-card py-2.5">
            {tabs.map((tab, index) => (
              <TabV2
                key={index}
                tab={tab}
                selected={activeTab === tab}
                onClick={() => {
                  setActiveTab(tab as Tab);
                  setSelectedSubscription(undefined as any);
                }}
              />
            ))}
          </div>

          <ListView
            className={`mx-4 mt-6 flex min-h-[60px] flex-col items-start gap-4 rounded p-5 lg:flex-row ${
              filterSubscriptions().length <= 1
                ? "lg:justify-center"
                : "lg:justify-around"
            } overflow-y-auto overflow-x-scroll`}
            items={filterSubscriptions()}
            renderItem={function (item: SubscriptionPlan): React.ReactNode {
              return (
                <div key={item.id}>
                  <Plan
                    plan={item}
                    mySubscription={mySubscription}
                    selected={selectedSubscription?.id === item.id}
                    handleFreeSubscriptions={handleFreeSubscriptions}
                    setIsDisplayAddressCheckout={setIsDisplayAddressCheckout}
                    setSelectedSubscription={setSelectedSubscription}
                  />
                </div>
              );
            }}
            noItemsElement={
              <div className="py-20 text-center">
                <Label variant="s1">No plans available</Label>
              </div>
            }
            footerElement={
              <div
                className={cx("", {
                  "h-20": filterSubscriptions().length < 3,
                })}
              />
            }
          />
        </>
      </div>
      <CurrentSubscription mySubscription={mySubscription} />

      <AddressCheckout
        plan={selectedSubscription}
        user={user}
        community={community}
        existingBillingAddress={mySubscription?.billingDetails}
        isVisible={isDisplayAddressCheckout}
        setIsVisible={setIsDisplayAddressCheckout}
        onProceed={(billingInfo: BillingDetails) => {
          if (Boolean(billingInfo)) {
            // console.log("billingInfo", billingInfo);
            setIsDisplayAddressCheckout(false);
            initiateSubscription(selectedSubscription!, billingInfo);
          }
        }}
      />
    </Modal>
  );
};

/**
 * Displays plan with a radio button
 */
export function Plan({
  plan,
  mySubscription,
  selected = false,
  handleFreeSubscriptions,
  setIsDisplayAddressCheckout,
  onSelect,
  setSelectedSubscription,
}: {
  plan: SubscriptionPlan;
  selected?: boolean;
  mySubscription?: MySubscription;
  handleFreeSubscriptions?: (subscriptionId: string) => void;
  setIsDisplayAddressCheckout?: (value: boolean) => void;
  onSelect?: (value: SubscriptionPlan) => void;
  setSelectedSubscription?: (selectedSubscription: any) => void;
}) {
  const baseAmount = SubscriptionUtils.convertAmount(plan?.subscriptionAmount);
  const taxAmount = baseAmount * ((plan?.taxPercentage ?? 0) / 100);
  const currency =
    CurrencySymbol[plan?.currency as keyof typeof CurrencySymbol] ||
    plan?.currency;

  const totalAmount = SubscriptionUtils.calculateSubscriptionTotal(plan);

  /* Base Amount = Total / (1 + Percent/100) */
  // const baseAmount = amount / (1 + (plan?.taxPercentage ?? 0) / 100) ?? 0;

  return (
    <div
      className={cx(
        "flex min-w-[220px] max-w-[280px] flex-wrap gap-4 break-words rounded border border-border p-5 hover:bg-background",
        {
          "border-primary": selected,
          "cursor-pointer": Boolean(onSelect),
        }
      )}>
      <div className="ml-2 flex-grow">
        <div className="flex flex-col place-content-between">
          <span className="mb-2.5 flex items-center text-sm font-semibold text-secondary-foreground">
            {plan?.name}
            <Fold
              value={
                mySubscription?.subscriptionPlan?._id === plan?.id ?? false
              }
              ifPresent={() => {
                return (
                  <span className="rounded bg-primary px-2 py-0.5 text-xs text-primary-foreground">
                    <I18>Current Plan</I18>
                  </span>
                );
              }}
            />
          </span>
          <span>
            {baseAmount ? (
              <div className="flex items-center gap-2.5 text-xs font-semibold">
                <span className="whitespace-nowrap text-2xl text-secondary-foreground">
                  {currency}
                  {baseAmount}
                </span>
                <span>
                  {(plan?.recurringInterval ?? 0) > 0 && (
                    <div className="flex flex-col items-start text-secondary-foreground">
                      <span>per</span>
                      <span>month</span>
                    </div>
                  )}
                </span>
              </div>
            ) : (
              <Label translate variant="t1">
                Free
              </Label>
            )}
          </span>
        </div>
        <div className="flex justify-start">
          <Button
            onClick={() => {
              if (!plan) return;
              if (plan?.subscriptionAmount === 0) {
                handleFreeSubscriptions && handleFreeSubscriptions(plan.id!);
                return;
              }
              setSelectedSubscription && setSelectedSubscription(plan);
              setIsDisplayAddressCheckout && setIsDisplayAddressCheckout(true);
            }}
            className="mt-3 h-8 w-full text-xs font-semibold"
            label="Subscribe"
          />
        </div>
        <div className="flex flex-row justify-center text-secondary-foreground">
          {plan?.description && (
            <div
              dangerouslySetInnerHTML={{ __html: plan?.description }}
              className="NL2BR headings mt-2 max-h-96 overflow-y-auto"></div>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Displays current subscription details
 */
export function CurrentSubscription({
  mySubscription,
}: {
  mySubscription?: MySubscription;
}) {
  if (!mySubscription || !mySubscription?.isActive) return null;

  const amount = SubscriptionUtils.convertAmount(
    mySubscription?.subscriptionPlan?.subscriptionAmount
  );
  const currency = mySubscription?.subscriptionPlan?.currency ?? "USD";
  const isRecurring = mySubscription?.subscriptionPlan?.isRecurring;

  return (
    <div className="p-4">
      <details className="group">
        <summary className="mt-2 flex cursor-pointer list-none items-center justify-between font-medium">
          <Label size="body1" variant="t1">
            Current Plan
          </Label>
          <span className="transition group-open:rotate-180">
            <UIcon icon="angle-down" />
          </span>
        </summary>
        <div className="select-none">
          <div className="my-2 flex flex-col gap-1 rounded border border-primary px-4 py-2">
            <Label variant="t2" className="text-primary-500">
              {mySubscription?.subscriptionPlan?.name}
            </Label>
            {amount ? (
              <span className="flex items-start gap-2 whitespace-nowrap">
                <Label>
                  {currency}
                  {amount}{" "}
                </Label>
                <Label variant="s1">
                  {(mySubscription.subscriptionPlan?.recurringInterval ?? 0) >
                    0 &&
                    `per ${mySubscription.subscriptionPlan?.recurringInterval} month`}
                </Label>
              </span>
            ) : (
              <Label translate variant="t1">
                Free
              </Label>
            )}
          </div>
          <div
            className={cx("flex items-center justify-between", {
              hidden: !isRecurring,
            })}>
            <Label variant="s1">
              If you change your plan, your current subscription will be
              canceled and a new subscription will be created.
            </Label>
          </div>
        </div>
      </details>
    </div>
  );
}

export { GroupSubscriptionCheckoutModel };
