import { SVGProps } from "react";

export function VirtualEventIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11085_6302)">
        <path
          d="M5.37233 3.81757C7.07962 3.81601 8.06381 3.8168 8.6411 3.82301C8.9306 3.82613 9.11122 3.83055 9.22706 3.83629C9.33028 3.84141 9.36118 3.8469 9.36121 3.8467C9.7769 3.95266 10.1241 4.28393 10.2593 4.72624L10.2594 4.72624C10.2594 4.72642 10.2595 4.72661 10.2596 4.72681C10.2634 4.73772 10.2784 4.7804 10.2856 5.22342C10.2929 5.67134 10.2937 6.47769 10.2937 8.00195V11.1573L10.2438 11.3112C10.1076 11.718 9.7724 12.0388 9.37816 12.1516C9.36439 12.1527 9.34475 12.154 9.31832 12.1554C9.25387 12.1588 9.16288 12.1622 9.04698 12.1655C8.81597 12.1719 8.49778 12.1773 8.1199 12.1817C7.36477 12.1905 6.37902 12.1951 5.39248 12.1953C4.40592 12.1955 3.42042 12.1913 2.66578 12.1826C2.28812 12.1782 1.97032 12.1728 1.7398 12.1662C1.62412 12.1629 1.53354 12.1595 1.46958 12.1559C1.4442 12.1545 1.42531 12.1532 1.41203 12.1521C0.983794 12.027 0.628718 11.649 0.535288 11.2312C0.535075 11.2277 0.533164 11.2099 0.530732 11.1701C0.527385 11.1154 0.524076 11.0398 0.520919 10.9442C0.514627 10.7536 0.509309 10.4952 0.505029 10.19C0.496479 9.58007 0.492188 8.79154 0.492188 8.00195C0.492188 7.21236 0.496479 6.42383 0.505029 5.81394C0.509309 5.50866 0.514627 5.2503 0.520919 5.05972C0.524076 4.96411 0.527385 4.88847 0.530732 4.83376C0.533206 4.79332 0.53514 4.77557 0.535298 4.77254C0.626751 4.35959 0.987589 3.97295 1.41232 3.8483C1.41737 3.84838 1.456 3.84402 1.55695 3.84003C1.68762 3.83487 1.88757 3.83082 2.18877 3.8277C2.78984 3.82148 3.77473 3.81913 5.37233 3.81757ZM5.37233 3.81757L5.37187 3.31757L5.37233 3.81757ZM9.39774 12.1497C9.39767 12.1497 9.39627 12.1498 9.39378 12.1502C9.39657 12.1499 9.39782 12.1497 9.39774 12.1497ZM1.39185 12.1501C1.39176 12.1501 1.39319 12.1504 1.39644 12.1507C1.39357 12.1503 1.39194 12.1501 1.39185 12.1501Z"
          stroke="currentColor"
        />
        <path
          d="M13.3466 5.57396L13.3466 5.57398L12.1884 6.24168L12.1937 7.99164L12.1937 7.9924L12.2018 9.74709L13.3266 10.3913L13.3272 10.3916C13.7036 10.6079 14.0596 10.8091 14.3294 10.9596C14.4644 11.0348 14.5769 11.0968 14.6592 11.1414C14.7073 11.1674 14.7377 11.1834 14.7546 11.1923C14.7669 11.1988 14.7719 11.2014 14.7713 11.2014C14.8851 11.2453 14.9577 11.2472 15.0575 11.222C15.2404 11.1747 15.3854 11.0541 15.4624 10.8925C15.4671 10.8563 15.473 10.7772 15.4775 10.5952C15.4867 10.2237 15.4891 9.51355 15.4938 8.07995C15.4938 8.07989 15.4938 8.07984 15.4938 8.07979L15.4941 7.96166C15.4986 6.45154 15.5008 5.72599 15.48 5.33989C15.4693 5.1404 15.4539 5.07731 15.448 5.05908C15.448 5.0589 15.4481 5.05889 15.4482 5.05888C15.4488 5.0588 15.4501 5.05866 15.401 4.9873C15.2879 4.8236 15.1494 4.74972 14.9364 4.75324L14.9364 4.75331H14.9281C14.8883 4.75331 14.8413 4.75645 14.8002 4.76142C14.7916 4.76246 14.7839 4.7635 14.7772 4.76449C14.7564 4.77552 14.7293 4.79019 14.6959 4.8085C14.6147 4.85299 14.5023 4.91561 14.3663 4.99215C14.0947 5.14505 13.7329 5.35121 13.3466 5.57396Z"
          stroke="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11085_6302">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
