// Returns profanity keywords from text if available
export default async function checkForProfanity(
  text?: string
): Promise<string[]> {
  if (!text) {
    return [];
  }
  const foundProfanityWords: string[] = [];
  console.log(
    "checking for profanity",
    new Date().toLocaleDateString("en-us", {
      minute: "2-digit",
      second: "2-digit",
    })
  );

  // Lazy load the json file
  const loadJson = (): Promise<any> => {
    return new Promise((res, rej) => {
      import(`../_assets/json/bad-words.json`).then((data) => {
        res(data?.words);
      });
    });
  };
  const BadWords = await loadJson();
  if (!BadWords || Array.isArray(BadWords) === false) {
    console.error("BadWords is not an array");
    return [];
  }

  for (let i = 0; i < BadWords.length; i++) {
    try {
      const pattern = new RegExp("\\b" + BadWords[i] + "\\b", "gi");
      if (pattern.test(text)) {
        foundProfanityWords.push(BadWords[i]);
      }
    } catch (error) {
      // console.error(error);
    }
  }
  if (foundProfanityWords) {
    console.log("foundProfanityWords:", foundProfanityWords);
  }
  console.log(
    "Profanity check complete",
    new Date().toLocaleDateString("en-us", {
      minute: "2-digit",
      second: "2-digit",
    })
  );
  return foundProfanityWords;
}