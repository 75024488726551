import { createContext, Dispatch, ReactNode, SetStateAction } from "react";
import { useDirectMessageModel } from "../_components/model/use-direct-mesage";
import { useUserMessageListModel } from "../_components/model/use-message-list";
import { useUserProfileModel } from "../_components/model/user-profile-sidebar";

export interface ModalContextType {
  profileModelState: {
    setShowProfileModel: Dispatch<SetStateAction<boolean>>;
    setIdToViewProfile: Dispatch<SetStateAction<string>>;
  };
  messageListModelState: {
    setShowMessageListModel: Dispatch<SetStateAction<boolean>>;
  };
  DirectMessageModelState: {
    setShowDirectMessageModel: Dispatch<SetStateAction<boolean>>;
    setIdToViewMessage: Dispatch<SetStateAction<string>>;
  };
}
export const ModalContext = createContext<ModalContextType>({
  profileModelState: {
    setShowProfileModel: () => {},
    setIdToViewProfile: () => {},
  },
  messageListModelState: {
    setShowMessageListModel: () => {},
  },
  DirectMessageModelState: {
    setShowDirectMessageModel: () => {},
    setIdToViewMessage: () => {},
  },
});

export default function ModalProvider({ children }: { children: ReactNode }) {
  const { profileModelState, ProfileSidebarModel } = useUserProfileModel();
  const { messageListModelState, MessageListSidebarModel } =
    useUserMessageListModel();
  const { DirectMessageSidebarModel, directMessageModelState } =
    useDirectMessageModel();

  return (
    <ModalContext.Provider
      value={{
        profileModelState: profileModelState,
        messageListModelState: messageListModelState,
        DirectMessageModelState: directMessageModelState,
      }}>
      <ProfileSidebarModel />
      <MessageListSidebarModel />
      <DirectMessageSidebarModel />
      {children}
    </ModalContext.Provider>
  );
}
