import { format } from "date-fns";
import { debounce } from "lodash";
import React, { Suspense, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Remarkable } from "remarkable";
import TurndownService from "turndown";
import { ToastTypes, withToast } from "../../../contexts/toastr.context";
import { useAppService } from "../../../hooks/use-app-service";
import useLang from "../../../hooks/use-lang.hook";
import questionIcon from "../../../_assets/vectors/question-icon.svg";
import Tab from "../../../_components/tab.component";
import { CALL_TYPES } from "../../../_constants/eventCallTypes";
import { CommunityService } from "../../../_service";
import { EventService } from "../../../_service/event.service";
import { ActionEventModal } from "../../action-event-modal.component";
import I18 from "../../atoms/i18";
import { Avatar } from "../../avatar.component";
import { Button } from "../../button.component";
import DropDownSelector from "../../compound/dopdown-selector";
import {
  ConvertToSlugString,
  CreateSlugComponent,
  limitMaxCharacterTo255,
} from "../../create-slug-modal";
import { Checkbox } from "../../form-controls/checkbox.component";
import IconButton2 from "../../icon-button-2-component";
import CropImageComponent from "../../image-crop/crop-image-component";
import { Loader } from "../../loader.component";
import { Modal } from "../../modal.component";
import { TextInput } from "../../text-input.component";
import AttendeeSection from "./event-attendee-component";
import InviteSection from "./event-invite-section-component";
const RichTextEditor = React.lazy(
  () => import("../../form-controls/rich-text-editor.component")
);

const tds = new TurndownService();

const TabButton = Object.freeze({
  details: 1,
  invite: 2,
  attendee: 3,
  Slug: 4,
});

/**
 * Create event component
 * @param {Object} props
 * @param {Date} startDate - date to set as start date
 */
function EventCreateModelComponent({
  user,
  community,
  active,
  groups,
  eventToUpdate,
  startDate,
  isShowAttendee = false,
  isShowInvite = false,
  isPastEventModal = false,
  setIsShowInvite = () => {},
  setIsShowAttendee = () => {},
  onUpdateEvent = (event) => {},
  cancelUpdate = (e) => {},
  openModel = (e) => {},
  addToast = (e) => {},
  onNewEventAdded = (e) => {},
  onClearModal = (e) => {},
}) {
  const md = new Remarkable();
  const lang = useLang();
  const recurringEventTypesList = [
    "Does not repeat",
    "Daily",
    "Weekly",
    "Bi Weekly",
  ];

  const isEditEvent = eventToUpdate && eventToUpdate.id;
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSubmitForm, setShouldSubmitForm] = useState(true); // to disable submit button
  const [title, setTitle] = useState(isEditEvent ? eventToUpdate.title : "");
  const [description, setDescription] = useState(
    isEditEvent ? md.render(eventToUpdate.description) : ""
  );
  const [callType, setCallType] = useState(
    isEditEvent ? eventToUpdate.callType : ""
  );
  const [isPrivateEvent, setIsPrivateEvent] = useState(
    false
  );
  const [location, setLocation] = useState(
    isEditEvent ? eventToUpdate.location : ""
  );
  const [createMeeting, setCreateMeeting] = useState(false);
  const [picture, setPicture] = useState(
    isEditEvent ? eventToUpdate.banner : null
  );
  const [pictureSelected, setPictureSelected] = useState(false);
  const [startTime, setStartTime] = useState(
    isEditEvent ? new Date(eventToUpdate.startTime) : startDate
  );
  const [endTime, setEndTime] = useState(
    isEditEvent ? new Date(eventToUpdate.endTime) : null
  );
  const [openTab, setOpenTab] = useState(TabButton.details);
  const [showUserList, setShowUserList] = useState(false);
  const [registrationUnlimited, setRegistrationUnlimited] = useState(
    isEditEvent ? false : true
  );
  const [notifyUser, setNotifyUser] = useState(false);
  const [isNotifyModalVisible, setIsNotifyModalVisible] = useState(false);
  const [maximumRegistrations, setMaximumRegistrations] = useState("");
  const [eventHost, setEventHost] = useState(
    isEditEvent ? eventToUpdate.host : {}
  );
  const [webURL, setWebURL] = useState(
    isEditEvent && eventToUpdate.webURL !== "" ? eventToUpdate.webURL : ""
  );
  const [query, setQuery] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [attendeeList, setAttendeeList] = useState([]);

  // helps with pagination
  const [isLoadingMoreSubscribers, setIsLoadingMoreSubscribers] =
    useState(false);
  const [noMoreSubscribers, setNoMoreSubscribers] = useState(false);
  const [page, setPage] = useState(1);

  const [recurringEventType, setRecurringEventType] = useState(
    recurringEventTypesList[0]
  );
  const [eventEndType, setEventEndType] = useState();
  const [occurencesCount, setOccurencesCount] = useState(null);
  const [recurringEventsEndDateAfter, setRecurringEventsEndDateAfter] =
    useState(null);

  const [errors, setErrors] = useState([]);
  // Slugs related
  const [slug, setSlug] = useState(
    isEditEvent !== undefined &&
      eventToUpdate.meta !== undefined &&
      eventToUpdate.meta.slug !== undefined
      ? eventToUpdate.meta.slug
      : null
  );
  const [slugTitle, setSlugTitle] = useState(
    isEditEvent && eventToUpdate.meta && eventToUpdate.meta.title
      ? eventToUpdate.meta.title
      : null
  );
  const [slugDescription, setSlugDescription] = useState(
    isEditEvent && eventToUpdate.meta && eventToUpdate.meta.description
      ? eventToUpdate.meta.description
      : null
  );
  const [OGTitle, setOGTitle] = useState(
    isEditEvent && eventToUpdate.meta && eventToUpdate.meta.openGraphTitle
      ? eventToUpdate.meta.openGraphTitle
      : null
  );
  const [OGDescription, setOGDescription] = useState(
    isEditEvent && eventToUpdate.meta && eventToUpdate.meta.openGraphDescription
      ? eventToUpdate.meta.openGraphDescription
      : null
  );
  const [OGImage, setOGImage] = useState(
    isEditEvent && eventToUpdate.meta && eventToUpdate.OGImage
      ? eventToUpdate.OGImage
      : null
  );

  const tempSlugUrl =
    isEditEvent && eventToUpdate.meta && eventToUpdate.meta.slug
      ? eventToUpdate.meta.slug
      : title;
  const tempSlugTitle =
    isEditEvent && eventToUpdate.meta && eventToUpdate.meta.title
      ? eventToUpdate.meta.title
      : title;
  // const tempSlugDescription = (isEditEvent && eventToUpdate.meta && eventToUpdate.meta.description  ? eventToUpdate.meta.description : embedlessDescription );
  const tempSlugOGTitle =
    isEditEvent && eventToUpdate.meta && eventToUpdate.meta.openGraphTitle
      ? eventToUpdate.meta.openGraphDescription
      : OGTitle;
  // const tempSlugOGDescription = (isEditEvent && eventToUpdate.meta && eventToUpdate.meta.openGraphDescription  ? eventToUpdate.meta.openGraphDescription: embedlessDescription );

  const { analyticsService } = useAppService();

  function prepareSlugDescription(description) {
    if (
      isEditEvent &&
      eventToUpdate.meta &&
      eventToUpdate.meta.openGraphTitle
    ) {
      return eventToUpdate.meta.description;
    } else if (description !== undefined) {
      return tds.turndown(description);
    } else {
      return "";
    }
  }

  function prepareSlugOGDescription(openGraphDescription) {
    if (
      isEditEvent &&
      eventToUpdate.meta &&
      eventToUpdate.meta.openGraphDescription
    ) {
      return eventToUpdate.meta.openGraphDescription;
    } else if (openGraphDescription !== undefined) {
      return tds.turndown(openGraphDescription);
    }
    return "";
  }

  const isFormSubmittable =
    title &&
    title.length > 2 &&
    startTime &&
    endTime &&
    (maximumRegistrations || registrationUnlimited) &&
    shouldSubmitForm;

  const wrapperRef = useRef();
  const startRef = useRef(null);
  const endRef = useRef(null);
  const innerRef = useRef();
  const recurringEndRef = useRef(null);

  useEffect(() => {
    if (isEditEvent) {
      if (isShowAttendee) {
        setOpenTab(TabButton.attendee);
      }
      if (isShowInvite) {
        setOpenTab(TabButton.invite);
      }
      if (eventToUpdate.maximumRegistrations === 0) {
        setRegistrationUnlimited(true);
      } else {
        setMaximumRegistrations(eventToUpdate.maximumRegistrations);
      }
      setTitle(eventToUpdate.title);
      setIsPrivateEvent(eventToUpdate.isPrivate);
      setEventHost(eventToUpdate.host);
      setPicture(eventToUpdate.banner);
      setDescription(md.render(eventToUpdate.description));
      setAttendeeList(eventToUpdate.participants);
      setStartTime(
        eventToUpdate.startTime ? new Date(eventToUpdate.startTime) : null
      );
      setEndTime(
        eventToUpdate.endTime ? new Date(eventToUpdate.endTime) : null
      );
      if (eventToUpdate.liveCall !== null) {
        setWebURL(eventToUpdate.liveCall.webUrl);
      } else {
        setWebURL(eventToUpdate.webURL);
      }
      setLocation(eventToUpdate.location);
      setCreateMeeting(eventToUpdate.liveCall !== null ? true : false);
    } else {
      if (startDate) {
        setStartTime(startDate);
      }
    }
  }, [isEditEvent, active]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the showUserList is open and the clicked target is not within the showUserList,
      // then close the showUserList
      if (
        showUserList &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setShowUserList(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showUserList]);

  useEffect(() => {
    if (["admin"].includes(community.myRole)) {
      CommunityService.getCommunitySubscribersPaginated(
        user,
        community.id,
        10,
        1,
        query
      )
        .then(({ users }) => {
          setSubscribers(users);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error({ error });
          setIsLoading(false);
        });
    }
    if (!isEditEvent) {
      setEventHost(user);
    }
  }, [user, community.id, community.myRole]);

  // Search Subscribers
  const handleSearchDebounced = React.useCallback(
    debounce((query) => {
      if (["admin"].includes(community.myRole)) {
        setIsLoading(true);
        CommunityService.getCommunitySubscribersPaginated(
          user,
          community.id,
          10,
          1,
          query
        )
          .then(({ users }) => {
            setSubscribers(users);
            setIsLoading(false);
            setNoMoreSubscribers(false);
          })
          .catch((error) => {
            console.error({ error });
            setIsLoading(false);
          });
      }
    }, 1000),
    [community.myRole]
  );

  const onScroll = React.useCallback(() => {
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // subscribers list lazy loading you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMoreSubscribers && !noMoreSubscribers) {
          const pageCount = page + 1;
          // if we are not already loading more subscribers, load more subscribers
          setIsLoadingMoreSubscribers(true);
          CommunityService.getCommunitySubscribersPaginated(
            user,
            community.id,
            10,
            pageCount,
            query
            // group.id
          )
            .then((response) => {
              if (response.users.length === 0) {
                setNoMoreSubscribers(true);
              } else {
                setSubscribers([...subscribers, ...response.users]);
                setPage(pageCount);
              }
              setIsLoadingMoreSubscribers(false);
            })
            .catch((error) => {
              console.error({ error });
              setIsLoading(false);
            });
        }
      }
    }
  }, [isLoadingMoreSubscribers, noMoreSubscribers, page, query, subscribers]);

  useEffect(() => {
    if (notifyUser) {
      updateEvent();
    }
  }, [notifyUser]);

  // create event
  const createEvent = async () => {
    try {
      setIsLoading(true);
      let registration = registrationUnlimited ? 0 : maximumRegistrations;
      let embedlessDescription = description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );
      embedlessDescription = tds.turndown(embedlessDescription);
      const meta = {
        slug: ConvertToSlugString(slug === null ? tempSlugUrl : slug),
        title: slugTitle === null ? tempSlugTitle : slugTitle,
        description:
          slugDescription === null
            ? prepareSlugDescription(embedlessDescription)
            : slugDescription,
        openGraphTitle: OGTitle === null ? tempSlugOGTitle : OGTitle,
        openGraphDescription:
          OGDescription === null
            ? prepareSlugOGDescription(embedlessDescription)
            : OGDescription,
        openGraphImage: OGImage,
      };
      const response = await EventService.createEvent(
        user,
        community.id,
        startTime,
        endTime,
        isPrivateEvent,
        title,
        embedlessDescription,
        callType,
        location,
        webURL,
        registration,
        recurringEventType,
        occurencesCount,
        recurringEventsEndDateAfter,
        eventHost ? eventHost.id : null,
        createMeeting,
        meta
      );

      analyticsService.track("new-event-created", {});
      if (response) {
        for (const event of response.events) await uploadBannerImage(event);

        addToast("Event created successfully", "", ToastTypes.success);

        analyticsService.track("new-event-created", {
          meetingURL: webURL,
        });

        for (const event of response.events) onNewEventAdded(event);

        setNotifyUser(false);
        setTimeout(() => {
          setOpenTab(TabButton.invite);
        }, 400);
      }

      setIsLoading(false);
      setErrors([]);
    } catch (e) {
      setIsLoading(false);
      setNotifyUser(false);
      addToast("Error in creating event", "", ToastTypes.danger);
      if (e.response && e.response.data && e.response.data.errors) {
        setErrors(e.response.data.errors);
      }
    }
  };

  async function updateEvent() {
    try {
      setIsLoading(true);
      let registration = registrationUnlimited ? 0 : maximumRegistrations;
      let embedlessDescription = description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );
      embedlessDescription = tds.turndown(embedlessDescription);

      const meta = {
        slug: ConvertToSlugString(slug === null ? tempSlugUrl : slug),
        title: slugTitle === null ? tempSlugTitle : slugTitle,
        description:
          slugDescription === null
            ? prepareSlugDescription(embedlessDescription)
            : slugDescription,
        openGraphTitle: OGTitle === null ? tempSlugOGTitle : OGTitle,
        openGraphDescription:
          OGDescription === null
            ? prepareSlugOGDescription(embedlessDescription)
            : OGDescription,
        openGraphImage: OGImage,
      };

      const response = await EventService.updateEvent(
        user,
        community.id,
        eventToUpdate.id,
        notifyUser,
        startTime,
        endTime,
        isPrivateEvent,
        title,
        embedlessDescription,
        location,
        webURL,
        registration,
        eventHost ? eventHost.id : "",
        createMeeting,
        meta
      );
      if (response) {
        if (pictureSelected) {
          await uploadBannerImage(response.event.id, (bannerUrl) => {
            response.event.banner = bannerUrl;
          });
        }
        addToast("Event updated successfully", "", ToastTypes.success);
        onUpdateEvent(response.event);
        openModel(false);
      }
      setIsLoading(false);
      setErrors([]);
    } catch (e) {
      setIsLoading(false);
      addToast("Error in updating event", "", ToastTypes.danger);
      if (e.response && e.response.data && e.response.data.errors) {
        setErrors(e.response.data.errors);
      }
    }
  }
  // should notifyUser
  const onSubmitNotify = () => {
    setNotifyUser(true);
    setIsNotifyModalVisible(false);
  };

  // dont notifyUser
  const onCancelNotify = () => {
    // setNotifyUser(false);
    setIsNotifyModalVisible(false);
    updateEvent();
  };

  function ShowError({ errors, errorKey }) {
    return errorKey in errors ? (
      <div>
        {errors[errorKey].map((item, index) => (
          <div key={index} className="text-xs text-alert">
            {item}
          </div>
        ))}
      </div>
    ) : (
      <></>
    );
  }

  const uploadBannerImage = async (
    eventId,
    onBannerUpload = (bannerUrl) => {}
  ) => {
    if (picture == null || picture == undefined) {
      return;
    }
    const response = await EventService.updateEventBanner(
      user,
      community.id,
      eventId,
      picture
    ).catch((error) => {
      addToast("Error in uploading banner", "", ToastTypes.danger);
    });
    if (response != null && response.event) {
      onBannerUpload(response.event.banner);
    }
  };

  if (!active) {
    return <></>;
  }
  const resetForm = () => {
    if (isEditEvent) {
      setTitle("");
      setEventHost({});
      setDescription("");
      setMaximumRegistrations("");
      setPicture(null);
      setStartTime(null);
      setEndTime(null);
      setCallType("");
      setIsPrivateEvent(false);
      setRecurringEventsEndDateAfter(null);
      setRecurringEventType(null);
      setEventEndType(null);
      setOccurencesCount(null);
      setWebURL("");
      setLocation("");
      setOpenTab(TabButton.details);
      setIsShowInvite(false);
      setIsShowAttendee(false);
      onClearModal();
    }
    setTitle("");
    setDescription("");
    setMaximumRegistrations("");
    setPicture(null);
    setStartTime(null);
    setEndTime(null);
    setCallType("");
    setIsPrivateEvent(false);
    setWebURL("");
    setLocation("");
    setOpenTab(TabButton.details);
    setIsShowInvite(false);
    setIsShowAttendee(false);
    clearSlugMeta();
  };
  function clearSlugMeta() {
    setSlug(null);
    setOGTitle(null);
    setOGDescription(null);
    setOGImage(null);
    setSlugTitle(null);
    setSlugDescription(null);
  }
  return (
    <>
      <Modal
        className="CreateGroupModal"
        active={active}
        width="590px"
        padding={false}
        setActive={(e) => {
          openModel(e);
          resetForm();
        }}>
        <div className="flex items-center justify-between bg-card py-2 pl-4 pr-2">
          <span className="font-bold text-secondary-foreground/85">
            {isEditEvent ? (
              title && title.length > 30 ? (
                title.substring(0, 28) + "..."
              ) : (
                title
              )
            ) : (
              <I18>Create Event</I18>
            )}
          </span>
          <IconButton2
            hoverable
            icon="cross"
            size="md"
            iconClass="text-secondary-foreground/70"
            onClick={() => {
              openModel(false);
              resetForm();
            }}
          />
        </div>

        <div className="tab-holder flex justify-start bg-card px-5">
          {!isPastEventModal && (
            <Tab
              onClick={(e) => {
                if (openTab !== TabButton.details) {
                  setOpenTab(TabButton.details);
                }
              }}
              tab="Event Details"
              selected={openTab === TabButton.details}
            />
          )}
          {!isPastEventModal && (
            <Tab
              onClick={(e) => {
                if (openTab !== TabButton.slug) {
                  setOpenTab(TabButton.slug);
                }
              }}
              tab="Settings"
              selected={openTab === TabButton.slug}
            />
          )}

          <Tab
            onClick={(e) => {
              if (openTab !== TabButton.invite) {
                setOpenTab(TabButton.invite);
              }
            }}
            tab="Invite"
            selected={openTab === TabButton.invite}
            disabled={!isEditEvent}
          />
          {!isPastEventModal && (
            <Tab
              onClick={(e) => {
                if (openTab !== TabButton.attendee) {
                  setOpenTab(TabButton.attendee);
                }
              }}
              tab="Attendee"
              selected={openTab === TabButton.attendee}
              disabled={!isEditEvent}
            />
          )}
        </div>
        {/* body */}
        <div
          className={`p-5 text-secondary-foreground/85 ${
            openTab === TabButton.details ? "block" : "hidden"
          }`}>
          {/* section Banner */}
          <CropImageComponent
            picture={picture}
            setPicture={setPicture}
            setPictureSelected={setPictureSelected}
            cropRatioMessage="Picture ratio: 16:9"
            displayUnsplashSearch={true}
            initialPhotoSearchQuery={"Event"}
          />
          {/* Event Title */}
          <div className="mb-5 mt-2">
            <div className="mb-2 font-bold after:ml-0.5 after:text-red-500 after:content-['*']">
              <I18>Event name</I18>
            </div>
            <TextInput
              placeholder="Enter event name"
              defaultValue={title}
              validator={(value) => {
                if (value && value.length > 2) {
                  return null;
                } else {
                  return lang.trans("Event name is invalid!");
                }
              }}
              onChange={(value) => {
                setTitle(value);
              }}
              large
              noMargin
              className="bg-card"
            />
            <ShowError errors={errors} errorKey="title" />
          </div>
          {/*Host Name */}
          <div className="relative mb-5 mt-2">
            <div className="mb-2 font-bold">
              <I18>Host name</I18>
            </div>
            <div
              onClick={() => setShowUserList(true)}
              className="mr-1 flex cursor-pointer items-center justify-between rounded border border-border bg-card px-2 py-2 outline-none">
              {Object.keys(eventHost).length > 0 ? (
                <>
                  <Avatar
                    user={eventHost}
                    tag={
                      <>
                        {eventHost.communityRole === "admin" ? (
                          <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-primary">
                            <I18>Community Admin</I18>
                          </div>
                        ) : (
                          <></>
                        )}
                        {eventHost.groupRole === "admin" ? (
                          <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-primary">
                            <I18>Admin</I18>
                          </div>
                        ) : eventHost.groupRole === "moderator" ? (
                          <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                            <I18>Moderator</I18>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEventHost({});
                    }}
                    className={`${
                      Object.keys(eventHost).length > 0 ? "" : "hidden"
                    }`}>
                    <i className="fi fi-rr-cross text-sm text-secondary-foreground/85"></i>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-1 py-1.5 text-sm text-secondary-foreground/70">
                    <I18>You will be made host if none selected</I18>
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowUserList(!showUserList);
                    }}>
                    <i className="fi fi-rr-angle-down text-sm text-secondary-foreground/85"></i>
                  </div>
                </>
              )}
            </div>
            <ShowError errors={errors} errorKey="eventHost" />

            {showUserList ? (
              <div
                className={
                  "absolute z-10 mt-1 h-60 w-full rounded border border-border bg-background shadow-lg"
                }
                ref={wrapperRef}>
                <div className="m-auto w-11/12">
                  <TextInput
                    prefix={
                      <img
                        src={questionIcon}
                        alt="Search Icon"
                        width="15"
                        className="ml-4 -rotate-90 transform pb-4"
                      />
                    }
                    noPrefixSeparator
                    placeholder="Search by name"
                    noBorder
                    validationOutsideBox
                    noMargin
                    value={query}
                    containerClassName="bg-card py-1 focus:outline-none border border-border rounded mt-4"
                    onChange={(value) => {
                      setIsLoading(true);
                      setQuery(value);
                      handleSearchDebounced(value);
                    }}
                    postfix={
                      query.length > 0 ? (
                        <i
                          onClick={() => {
                            setIsLoading(true);
                            setQuery("");
                            handleSearchDebounced("");
                          }}
                          className="fi fi-rr-cross cursor-pointer pr-1 text-sm text-secondary-foreground/85"
                        />
                      ) : null
                    }
                  />
                </div>
                {isLoading ? (
                  <div className="p-1">
                    <Loader />
                  </div>
                ) : (
                  <></>
                )}
                <div
                  onScroll={onScroll}
                  ref={innerRef}
                  className="z-30 h-44 w-full overflow-scroll">
                  {subscribers && subscribers.length > 0 ? (
                    subscribers.map((member) => {
                      return (
                        <div
                          className="m-3 ml-10 flex cursor-pointer items-center justify-between py-2"
                          key={member.id}>
                          <div
                            onClick={() => {
                              setShowUserList(false);
                              setEventHost(member);
                            }}
                            className="flex items-center justify-center">
                            <Avatar
                              user={member}
                              tag={
                                <>
                                  {member.communityRole === "admin" ? (
                                    <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-primary">
                                      Community Admin
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {member.groupRole === "admin" ? (
                                    <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-primary">
                                      Admin
                                    </div>
                                  ) : member.groupRole === "moderator" ? (
                                    <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                                      Moderator
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              }
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          {/* Event description */}
          {
            <div className="mb-5 mt-2">
              <div className="mb-2 font-bold">
                <I18>Description</I18>
              </div>
              {/* rich text editor */}
              <div>
                <Suspense fallback={<></>}>
                  <RichTextEditor
                    text={description}
                    setText={setDescription}
                    className="bg-card"
                  />
                </Suspense>
              </div>
              <ShowError errors={errors} errorKey="description" />
            </div>
          }
          {/* No of registrations */}
          {/* <div className="mb-5 mt-2">
            <div className="font-bold mb-2 after:content-['*'] after:text-red-500 after:ml-0.5 ">
              <I18>No. of registration for the event</I18>
            </div>
            <div className="">
              {registrationUnlimited ? (
                <TextInput
                  key="1"
                  placeholder={`${"Unlimited"}`}
                  defaultValue={""}
                  large
                  noMargin
                  postfix={
                    <Checkbox
                      className="mb-1 mr-2"
                      selected={registrationUnlimited}
                      onClick={(e) => {
                        setRegistrationUnlimited(!registrationUnlimited);
                        if (!registrationUnlimited) {
                          setMaximumRegistrations("0");
                        } else {
                          setMaximumRegistrations("");
                        }
                      }}>
                      <span className={"font-semibold"}>
                        <I18>Unlimited</I18>
                      </span>
                    </Checkbox>
                  }
                  readOnly={registrationUnlimited}
                  containerClassName="bg-card"
                />
              ) : (
                <TextInput
                  key="2"
                  placeholder={`${"Enter no. of slots"}`}
                  defaultValue={maximumRegistrations}
                  validator={(value) => {
                    if (!/^[0-9]*$/.test(value)) {
                      setShouldSubmitForm(false);
                      return "Only numbers are allowed";
                    }
                    if (value === "0") {
                      setShouldSubmitForm(false);
                      return "Please enter number greater than 0";
                    } else if (value && value.length > 0) {
                      setShouldSubmitForm(true);
                      return null;
                    }
                  }}
                  onChange={(value) => {
                    setMaximumRegistrations(value);
                  }}
                  large
                  noMargin
                  postfix={
                    <Checkbox
                      className="mb-1 mr-2"
                      selected={registrationUnlimited}
                      onClick={(e) => {
                        setRegistrationUnlimited(!registrationUnlimited);
                        if (!registrationUnlimited) {
                          setMaximumRegistrations("0");
                        } else {
                          setMaximumRegistrations("");
                        }
                      }}>
                      <span className={"font-semibold"}>
                        <I18>Unlimited</I18>
                      </span>
                    </Checkbox>
                  }
                  readOnly={registrationUnlimited}
                  inputHolderClassName="bg-card"
                />
              )}
            </div>
            <ShowError errors={errors} errorKey="maximumRegistrations" />
          </div> */}
          {/* Timings */}
          <div className="mb-2 font-bold">
            <I18>When & where</I18>
          </div>
          {
            <div className="rounded border bg-card p-5">
              {/* Start Time */}
              <div className="block md:flex">
                {
                  <div className="flex justify-start">
                    <div className="w-98">
                      <div className="mb-1 font-bold after:ml-0.5 after:text-red-500 after:content-['*']">
                        <I18>From</I18>
                      </div>
                      <div
                        onClick={() => {
                          startRef.current.deferFocusInput();
                        }}
                        className="mr-1 flex cursor-pointer rounded border border-border bg-background px-2 py-4 outline-none">
                        <DatePicker
                          ref={startRef}
                          className="w-24 bg-background"
                          selected={startTime}
                          minDate={new Date()}
                          onChange={(date) => {
                            setStartTime(date);
                          }}
                          shouldCloseOnSelect={true}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={2}
                          timeCaption="time"
                          placeholderText="Start date"
                          dateFormat="dd - MM - yyyy"
                        />
                        <div className="mx-1 border-r-2" />
                        <div className="md:w-18 w-20 select-none rounded bg-background">
                          <div className="text-center text-secondary-foreground/85">
                            {startTime && format(startTime, " h:mm a")}
                            {!startTime && (
                              <p className="text-secondary-foreground/70">
                                <I18>time</I18>
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <ShowError errors={errors} errorKey="startTime" />
                    </div>
                  </div>
                }
                {/* END TIME */}
                {
                  <div className="mt-4 flex md:ml-4 md:mt-0">
                    <div className="w-98">
                      <div className="mb-1 font-bold after:ml-0.5 after:text-red-500 after:content-['*']">
                        <I18>To</I18>
                      </div>
                      <div
                        onClick={() => {
                          if (startTime) {
                            endRef.current.deferFocusInput();
                          }
                        }}
                        className={`mr-1 flex rounded border border-border bg-background px-2 py-4 outline-none ${
                          !startTime
                            ? "theme-bg-disable cursor-not-allowed"
                            : "cursor-pointer bg-background"
                        }`}>
                        <DatePicker
                          ref={endRef}
                          className={`w-24 border-border ${
                            !startTime
                              ? "theme-bg-disable cursor-not-allowed"
                              : "cursor-pointer bg-background"
                          }`}
                          selected={endTime}
                          minDate={startTime}
                          onChange={(date) => {
                            setEndTime(date);
                            setErrors([]);
                          }}
                          showTimeSelect
                          disabled={!startTime}
                          timeFormat="HH:mm"
                          timeIntervals={2}
                          timeCaption="time"
                          placeholderText="End date"
                          dateFormat="dd - MM - yyyy"
                        />
                        <div className="mx-1 border-r-2" />
                        <div
                          className={`md:w-18 w-20 border-border ${
                            !startTime
                              ? "theme-bg-disable cursor-not-allowed"
                              : "cursor-pointer bg-background"
                          }`}>
                          <div
                            className={
                              "text-center text-secondary-foreground/85"
                            }>
                            {endTime && format(endTime, " h:mm a")}
                            {!endTime && (
                              <p className="text-secondary-foreground/70">
                                <I18>time</I18>
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <ShowError errors={errors} errorKey="endTime" />
                    </div>
                  </div>
                }
              </div>
              <span className="text-xs text-secondary-foreground/70">
                <I18>Current timezone:</I18>{" "}
                {community.timezone ? community.timezone : "Asia/Kolkata"} (
                <I18>can be updated from community settings</I18>)
              </span>
              {/* Recurring */}
              <div className="mb-3 mt-3 block md:flex">
                <div className="mb-2 flex flex-col justify-between">
                  <div className="mb-1 font-bold">
                    <I18>Repeats</I18>
                  </div>
                  <DropDownSelector
                    list={recurringEventTypesList}
                    value={recurringEventType}
                    required
                    className="rounded border border-border bg-background focus:outline-none"
                    onStateChange={(e) => {
                      console.log(e);
                      setRecurringEventType(e);
                    }}
                  />
                </div>
              </div>

              {/* Event Ends */}
              {(recurringEventType === "Daily" ||
                recurringEventType === "Weekly" ||
                recurringEventType === "Bi Weekly") && (
                <div className="mb-5 flex h-[70px] w-full items-baseline justify-between">
                  <div className="mb-2 flex w-[45%] flex-col justify-between">
                    <div className="mb-1 font-bold">
                      <I18>Event Ends</I18>
                    </div>
                    <DropDownSelector
                      list={["After", "On"]}
                      required
                      className="rounded border border-border bg-background focus:outline-none"
                      onStateChange={(e) => {
                        setEventEndType(e);
                      }}
                    />
                  </div>

                  {/* Occurences */}
                  {eventEndType === "After" && (
                    <div className="-mt-2 w-[45%]">
                      <div className="mb-1 font-bold">
                        <I18>Occurences</I18>
                      </div>
                      <TextInput
                        key="occurences"
                        noPrefixSeparator
                        validator={(value) => {
                          if (isNaN(value)) {
                            setShouldSubmitForm(false);
                            return "Invalid count of occurences";
                          } else {
                            setShouldSubmitForm(true);
                          }
                        }}
                        noBorder
                        validationOutsideBox
                        noMargin
                        containerClassName="flex py-2 focus:outline-none border border-border bg-background rounded h-10 items-center"
                        onChange={(value) => {
                          setOccurencesCount(value);
                        }}
                      />
                    </div>
                  )}

                  {/* On date */}
                  {eventEndType === "On" && (
                    <div className="-mt-2 w-[45%]">
                      <div className="mb-1 font-bold text-transparent">
                        <I18>null</I18>
                      </div>
                      <div
                        onClick={() => {
                          recurringEndRef.current.deferFocusInput();
                        }}
                        className="mb-10 flex h-10 cursor-pointer items-center justify-center rounded border border-border bg-background outline-none">
                        <DatePicker
                          ref={recurringEndRef}
                          className="w-24 bg-background"
                          selected={recurringEventsEndDateAfter}
                          minDate={new Date()}
                          onChange={(date) => {
                            setRecurringEventsEndDateAfter(date);
                          }}
                          shouldCloseOnSelect={true}
                          placeholderText="Ends On"
                          dateFormat="dd - MM - yyyy"
                        />
                      </div>
                      <ShowError errors={errors} errorKey="startTime" />
                    </div>
                  )}
                </div>
              )}

              {/* Event Location */}
              <div className="mb-5 mt-2">
                <div className="mb-2 flex flex-col">
                  <div className="font-bold">
                    <I18>Online event</I18>
                  </div>
                  {/* 
                    CTA to add meeting link
                    - if event is not yet created
                    - if community is not redeemed from AppSumos
                  */}
                  {!isEditEvent && !community.isDealRedeemed ? (
                    <div className="mt-2 flex w-full flex-col justify-between gap-1 md:flex-row md:gap-0">
                      <Checkbox
                        className=""
                        selected={callType === CALL_TYPES.VIDEO_CALL}
                        onClick={(e) => {
                          setCreateMeeting(true);
                          setCallType(CALL_TYPES.VIDEO_CALL);
                          setWebURL("");
                          setShouldSubmitForm(true);
                        }}>
                        <span
                          className={`${createMeeting && callType === CALL_TYPES.VIDEO_CALL ? "font-semibold text-blue-700" : "font-semibold"}`}>
                          <I18>Add Video Call link</I18>
                        </span>
                      </Checkbox>

                      <Checkbox
                        className=""
                        selected={callType === CALL_TYPES.WEBINAR}
                        onClick={(e) => {
                          setCreateMeeting(true);
                          setCallType(CALL_TYPES.WEBINAR);
                          setWebURL("");
                          setShouldSubmitForm(true);
                        }}>
                        <span
                          className={`${createMeeting && callType === CALL_TYPES.WEBINAR ? "font-semibold text-blue-700" : "font-semibold"}`}>
                          <I18>Add Webinar link</I18>
                        </span>
                      </Checkbox>
                      <Checkbox
                        className=""
                        selected={callType === ""}
                        onClick={(e) => {
                          setCallType("");
                        }}>
                        <span
                          className={`${createMeeting && callType === "" ? "font-semibold text-blue-700" : "font-semibold"}`}>
                          <I18>Add Custom Link</I18>
                        </span>
                      </Checkbox>
                    </div>
                  ) : null}
                </div>
                {callType === CALL_TYPES.VIDEO_CALL ||
                callType === CALL_TYPES.WEBINAR ? (
                  <TextInput
                    key="1"
                    prefix={
                      <i
                        className={
                          "fi fi-rr-video-camera px-4 text-base text-gray-500"
                        }></i>
                    }
                    noPrefixSeparator
                    placeholder={
                      "Meeting link will be generated once event is created"
                    }
                    noBorder
                    validationOutsideBox
                    defaultValue={webURL}
                    readOnly
                    noMargin
                    containerClassName="py-2 focus:outline-none border border-border bg-background rounded"
                  />
                ) : (
                  <TextInput
                    key="2"
                    prefix={
                      <i
                        className={
                          "fi fi-rr-video-camera px-4 text-base text-red-500"
                        }></i>
                    }
                    noPrefixSeparator
                    validator={(value) => {
                      if (
                        value.startsWith("http://") ||
                        value.startsWith("https://") ||
                        value.length === 0
                      ) {
                        setShouldSubmitForm(true);
                      } else {
                        setShouldSubmitForm(false);
                        return "Invalid meeting url!";
                      }
                    }}
                    placeholder={
                      isEditEvent && webURL
                        ? webURL
                        : isEditEvent
                          ? "You can not edit generated meeting link"
                          : "https://"
                    }
                    disabled={isEditEvent}
                    noBorder
                    validationOutsideBox
                    noMargin
                    defaultValue={webURL}
                    containerClassName="py-2 focus:outline-none border border-border bg-background rounded"
                    onChange={(value) => {
                      setWebURL(value);
                    }}
                  />
                )}
              </div>
              <div className="mb-5 mt-2">
                <div className="font-bold">
                  <I18>Event Type</I18>
                </div>
                <div className="mt-3 flex w-full gap-4 md:flex-row">
                  <Checkbox
                    className=""
                    selected={!isPrivateEvent}
                    onClick={(e) => {
                      setIsPrivateEvent(false);
                    }}>
                    <span
                      className={`${!isPrivateEvent ? "font-semibold text-blue-700" : "font-semibold"}`}>
                      <I18>Public</I18>
                    </span>
                  </Checkbox>
                  <Checkbox
                    className=""
                    selected={isPrivateEvent}
                    onClick={(e) => {
                      setIsPrivateEvent(true);
                    }}>
                    <span
                      className={`${isPrivateEvent ? "font-semibold text-blue-700" : "font-semibold"}`}>
                      <I18>Private</I18>
                    </span>
                  </Checkbox>
                </div>
              </div>
              {/* Event Location */}
              <div className="mb-5 mt-2">
                <div className="mb-2 font-bold">
                  <I18>Venue</I18>
                </div>
                <TextInput
                  prefix={
                    <i className="fi fi-rr-marker px-4 text-base text-blue-500"></i>
                  }
                  noPrefixSeparator
                  placeholder="Enter address"
                  noBorder
                  validationOutsideBox
                  noMargin
                  defaultValue={location}
                  containerClassName="py-2 focus:outline-none border border-border bg-background rounded"
                  onChange={(value) => {
                    setLocation(value);
                  }}
                />
              </div>
            </div>
          }

          {/* submit button */}
          <div className="mt-5">
            <div className="mx-auto flex items-center justify-between">
              <Button
                onClick={(e) => {
                  if (isEditEvent) {
                    resetForm();
                    openModel(false);
                  } else {
                    openModel(false);
                    resetForm();
                  }
                }}
                flat
                label="Cancel"
                large
              />

              <Button
                disabled={!isFormSubmittable}
                onClick={() => {
                  if (isEditEvent) {
                    setIsNotifyModalVisible(true);
                  } else {
                    createEvent();
                  }
                }}
                label={isEditEvent ? "Update" : "Create"}
                large
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
        {openTab === TabButton.slug && (
          <>
            <div className="mb-2 p-4">
              <CreateSlugComponent
                community={community}
                slug={ConvertToSlugString(slug === null ? tempSlugUrl : slug)}
                slugPrefix="event"
                setSlug={setSlug}
                title={limitMaxCharacterTo255(
                  slugTitle === null ? tempSlugTitle : slugTitle
                )}
                setTitle={setSlugTitle}
                description={limitMaxCharacterTo255(
                  slugDescription === null
                    ? prepareSlugDescription(description)
                    : slugDescription
                )}
                setDescription={setSlugDescription}
                OGTitle={limitMaxCharacterTo255(
                  OGTitle == null ? tempSlugOGTitle : OGTitle
                )}
                setOGTitle={(title) => {
                  setOGTitle(title);
                }}
                OGDescription={limitMaxCharacterTo255(
                  OGDescription === null
                    ? prepareSlugOGDescription(description)
                    : OGDescription
                )}
                setOGDescription={(value) => {
                  setOGDescription(value);
                }}
                OGImage={OGImage}
                OgImageSubtitle="By default it will take up the banner uploaded with the event"
                setOGImage={setOGImage}
                clearSlugMeta={clearSlugMeta}
              />
            </div>
          </>
        )}
        <div
          className={`h-1/2 text-secondary-foreground/85 ${
            openTab === TabButton.invite ? "block" : "hidden"
          }`}>
          <InviteSection
            user={user}
            community={community}
            groups={groups}
            eventToUpdate={eventToUpdate}
            resetForm={resetForm}
            openModel={openModel}
            addToast={addToast}
            onUpdateEvent={onUpdateEvent}
          />
        </div>
        <div
          className={`p-5 text-secondary-foreground/85 ${
            openTab === TabButton.attendee ? "block" : "hidden"
          }`}>
          <AttendeeSection
            attendeeList={attendeeList}
            eventToUpdate={eventToUpdate}
          />
        </div>
        <ActionEventModal
          active={isNotifyModalVisible}
          setActive={setIsNotifyModalVisible}
          onSubmit={onSubmitNotify}
          onCancelSubmit={onCancelNotify}
          labelCancel="No, its okay"
          labelSubmit="Yes, Notify them"
          title={"Do you wish to notify these changes to attendees?"}
          subTitle={`${
            isEditEvent && eventToUpdate.participantCount > 0
              ? `${eventToUpdate.participantCount} ${lang.trans(
                  "users wants to attend this event"
                )}`
              : ""
          } `}
          isLoading={isLoading}
          large
          btnColor="theme-bg-disabled"
        />
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  user: state.auth,
  community: state.community,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const EventCreateModel = withToast(
  connect(mapStateToProps, mapDispatchToProps)(EventCreateModelComponent)
);

export { EventCreateModel };
