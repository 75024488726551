import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withToast } from "../../contexts/toastr.context";
import { NotificationLevel } from "../../_pages/community-settings/notification-centre.page";
import { GroupService } from "../../_service";
import AnalyticsService, { EventType } from "../../_service/analytics-service";
import { RealtimeSectionPinnedMessageService } from "../../_service/realtime-section-pinned-message.service";
import { ActionModal } from "../action-modal.component";
import EmbedLinkModal from "../embed-link-modal.component";
import CreateGroupSidebarModal from "../group/create-group-modal-v2.component";
import RightSideModal from "../right-side-modal.component";

function RealtimeInfoComponent({
  group,
  setGroup,
  user,
  activeTabModel,
  activeTab,
  showShareModal,
  setShowShareModal,
  notificationCentre,
  community,
  socket,
  addToast = (e) => {},
}) {
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false);
  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);

  const [isEditingTab, setIsEditingTab] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
  const [pinnedSectionMessages, setPinnedSectionMessages] = useState([]);

  const onSubmitDelete = () => {
    setIsLoading(true);
    GroupService.deleteGroupTab(user, group.id, activeTabModel.id)
      .then(({ group }) => {
        // update the group
        setIsLoading(false);
        setGroup(group);
        setIsDeleteModalVisible(false);
      })
      .catch((error) => {
        console.log({ error });
        alert("Something went wrong!");
        setIsLoading(false);
        setIsDeleteModalVisible(false);
      });
  };

  const onSubmitLeave = () => {
    setIsLoading(true);
    GroupService.leaveGroup(user, group.id)
      .then((response) => {
        // update group
        setGroup(response.group);
        // set loading to false
        setIsLoading(false);
        setIsLeaveModalVisible(false);
        AnalyticsService.logEvent(EventType.Group.name, EventType.Group.leave);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsLeaveModalVisible(false);
      });
  };

  // Stores the active tab notification preference
  const [notificationLevel, setNotificationLevel] = useState(
    NotificationLevel.NONE
  );

  let isVisibleToNormalUser =
    !(
      group.joinStatus === "joined" &&
      activeTabModel.isClosed &&
      !activeTabModel.isJoined
    ) || ["admin", "moderator"].includes(group.myRole);

  //get pinned section messages
  useEffect(() => {
    const getPinnedMessages = async () => {
      try {
        const result =
          await RealtimeSectionPinnedMessageService.getPinnedSectionMessages(
            group?.id,
            activeTabModel?.id
          );
        setPinnedSectionMessages(result);
      } catch (error) {
        console.error("Error fetching pinned message:", error);
      }
    };

    getPinnedMessages();
  }, [group?.id, activeTabModel?.id]);

  // Realtime pinned section messages socket implementation
  useEffect(() => {
    //pins the message in realtime
    const pinMessage = (data) => {
      setPinnedSectionMessages((prevState) => {
        const updatedPinnedMessages = [
          data.message,
          ...prevState.pinnedMessages,
        ];
        return { ...prevState, pinnedMessages: updatedPinnedMessages };
      });
    };

    //unpins the message in realtime
    const unPinMessage = (data) => {
      setPinnedSectionMessages((prevState) => {
        const updatedPinnedMessages = prevState.pinnedMessages.filter(
          (message) => message.id !== data.message.id
        );
        return { ...prevState, pinnedMessages: updatedPinnedMessages };
      });
    };

    socket.on(`section:${activeTabModel.id}:message-pinned`, pinMessage);
    socket.on(`section:${activeTabModel.id}:message-unpinned`, unPinMessage);

    // Clean up event listeners when the component unmounts
    return () => {
      socket.off(`section:${activeTabModel.id}:message-pinned`, pinMessage);
      socket.off(`section:${activeTabModel.id}:message-unpinned`, unPinMessage);
    };
  }, [socket, activeTabModel.id]);

  //accessing Pinned messages from state
  const NewPinnedMessages = pinnedSectionMessages?.pinnedMessages;

  // Initialize the notification preference for selected tab
  useEffect(() => {
    if (notificationCentre.preferences && activeTabModel) {
      const preferences = notificationCentre.preferences;
      if (preferences.tabs && preferences.tabs.length > 0) {
        // Check if the user has a preference for this section
        const tab = preferences.tabs.find(
          (tab) => tab.tabId === activeTabModel.id
        );
        if (tab) {
          setNotificationLevel(tab.notificationLevel);
        }
        // If not, set the default preference to NotificationLevel.ALL
        else {
          setNotificationLevel(NotificationLevel.ALL);
        }
      } else {
        setNotificationLevel(NotificationLevel.ALL);
      }
    }
  }, [notificationCentre, activeTab]);

  return (
    <>
      {/*  BREADCRUMB */}
      {/* <div
        className="my-2 block font-semibold cursor-pointer"
        onClick={() => history.push(getGroupPageRouteFromSlug(group))}>
        <Label>ᐸ &nbsp; {group.name}</Label>
      </div> */}

      {/* group info */}

      {/* group title */}

      {/* group edit modal */}
      {/* <CreateGroupModal
        group={group}
        updateGroup={(group) => {
          addToast("Group updated!", "", "success");
          setGroup(group);
        }}
        active={isCreateGroupModalVisible}
        setActive={setIsCreateGroupModalVisible}
      /> */}
      <RightSideModal
        width={570}
        setActive={setIsCreateGroupModalVisible}
        active={isCreateGroupModalVisible}>
        <CreateGroupSidebarModal
          group={group}
          isEditGroupModalVisible={isCreateGroupModalVisible}
          setIsEditGroupModalVisible={setIsCreateGroupModalVisible}
          setIsCreateGroupModalVisible={setIsCreateGroupModalVisible}
          updateGroup={(group) => {
            addToast("Group updated!", "", "success");
            setGroup(group);
          }}
        />
      </RightSideModal>
      {/* embed modal */}
      {activeTabModel && (
        <EmbedLinkModal
          link={
            process.env.REACT_APP_EMBED_URL +
            (window.location.port ? ":" + window.location.port : "") +
            "/group/" +
            group.id +
            "/feed/" +
            activeTabModel.id
          }
          active={isEmbedModalVisible}
          setActive={setEmbedModalVisible}
        />
      )}
      {/* delete tab model */}
      <ActionModal
        active={
          isLeaveModalVisible ? isLeaveModalVisible : isDeleteModalVisible
        }
        setActive={
          isLeaveModalVisible ? setIsLeaveModalVisible : setIsDeleteModalVisible
        }
        onSubmit={isLeaveModalVisible ? onSubmitLeave : onSubmitDelete}
        header={`${isLeaveModalVisible ? "Leave" : "Delete"} Channel`}
        btnColor="red"
        title={`Are you sure you want to ${
          isLeaveModalVisible ? "leave" : "delete"
        } the Channel?`}
        // isLoading={isLoading}
      />
    </>
  );
}

export const RealtimeSectionInfo = withToast(
  connect((s) => ({
    notificationCentre: s.notificationCentre,
    community: s.community,
    socket: s.socket,
  }))(RealtimeInfoComponent)
);
