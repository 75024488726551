import React, { useState } from "react";
import { ToastTypes } from "../../../../contexts/toastr.context";
import { useToast } from "../../../../hooks/use-toast.hook";
import { Course, PrettiFy } from "../../../../types/course/course.type";
import { SubscriptionPlan } from "../../../../types/subscription/subscription-plan";
import { Button } from "../../../../_components";
import TextButton from "../../../../_components/atoms/text-button";
import IconMenu2, {
  ActionType,
} from "../../../../_components/icon-menu-2.component";
import ModalForm from "../../../../_components/model2";
import Label from "../../../../_components/typography/label.typography";
import UIcon from "../../../../_components/uicon-component";
import { SubscriptionService } from "../../../../_service";
import { SubscriptionUtils } from "../../../../_utils/subscription/subscription-util";
import { AccessToListView } from "../subscription-detail/subscription-detail-component";
import { SubscriptionToolTip } from "./subscription_tile_tooltip.component";
type Status = "idle" | "loading" | "success" | "error";
export const CurrencySymbol = Object.freeze({
  USD: "$",
  INR: "₹",
  EUR: "€",
  GBP: "£",
  AUD: "A$",
  CAD: "C$",
  SGD: "S$",
  JPY: "¥",
  CNY: "¥",
  NZD: "NZ$",
  CHF: "CHF",
  SEK: "kr",
  DKK: "kr",
  NOK: "kr",
  MXN: "MX$",
  BRL: "R$",
  ZAR: "R",
  HKD: "HK$",
  TWD: "NT$",
  TRY: "₺",
  RUB: "₽",
  ILS: "₪",
  KRW: "₩",
  MYR: "RM",
});

interface Props {
  communityId: string;
  subscription: SubscriptionPlan extends { memberCount: number }
    ? SubscriptionPlan
    : PrettiFy<SubscriptionPlan> & { memberCount: number };
  groups: Array<any> | undefined;
  courses: {
    list: Array<Course> | undefined;
    status: Status;
  };
  onSubscriptionUpdate: Function;
  onSelect: (subscription: any) => void;
}
/**
 * Component to display a subscription tile
 * @param {string} communityId
 * @param {SubscriptionPlan} subscription
 * @param {Function} onSubscriptionUpdate
 * @param {Function} onSubscriptionDelete
 */
const SubscriptionTile: React.FC<Props> = ({
  communityId,
  groups,
  courses,
  subscription,
  onSelect = (_) => {},
  onSubscriptionUpdate,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState<Status>("idle");

  const amount = SubscriptionUtils.convertAmount(
    subscription.subscriptionAmount
  );
  const TotalAmount =
    SubscriptionUtils.calculateSubscriptionTotal(subscription);
  const createdAt = subscription.createdAt && new Date(subscription.createdAt);
  const currency =
    CurrencySymbol[subscription.currency as keyof typeof CurrencySymbol] ||
    subscription.currency;

  const toast = useToast();

  /**
   * Call API to delete a subscription
   */
  const deleteSubscription = () => {
    setDeleteStatus("loading");
    SubscriptionService.deleteSubscription(communityId, subscription.id!)
      .then(({ subscription }) => {
        onSubscriptionUpdate(subscription);
        setDeleteStatus("success");
        toast.addToast("Subscription deleted successfully");
        setDeleteStatus("success");
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("error", error);
        setDeleteStatus("error");
        toast.addToast("Error deleting subscription", "", ToastTypes.danger);
      });
  };

  return (
    <tr
      key={subscription.id}
      onClick={() => {
        onSelect(subscription);
      }}
      className="cursor-pointer rounded-b-none border border-border text-secondary-foreground/80 hover:bg-background">
      <td className="p-1 md:px-4">{subscription.name}</td>
      <td className="p-1 md:px-4">
        {TotalAmount > 0 ? `${currency} ${TotalAmount}` : "Free"}
      </td>
      <td className="p-1 md:px-4">
        <span>
          {createdAt?.toLocaleString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>
      </td>
      <td className="p-1 md:px-4">
        {subscription?.memberCount > 1
          ? `${subscription.memberCount} Members`
          : subscription.memberCount === 1
            ? `${subscription.memberCount} Member`
            : `${subscription.memberCount} Member`}
      </td>
      <td className="relative p-1 md:px-4">
        <div className="flex items-center gap-1">
          <div>
            {subscription?.accessibleGroups?.length} groups,{" "}
            {subscription?.accessibleCourses?.length} courses
          </div>
          <SubscriptionToolTip
            children={<UIcon icon="info" size="xs" className="ml-1 mt-1" />}
            hoverableComponent={
              <div className="w-full min-w-max max-w-md">
                <AccessToListView
                  subscription={subscription}
                  groups={groups}
                  courses={courses}
                />
              </div>
            }
          />
        </div>
      </td>

      <td className="p-1 md:px-4">
        <div className="flex items-center gap-2">
          {subscription.isDeleted ? (
            <div className="mr-1 h-2 w-2 rounded-full bg-warning"></div>
          ) : (
            <div className="mr-1 h-2 w-2 rounded-full bg-green-500"></div>
          )}
          {!subscription.isDeleted ? "Active" : "Inactive"}
        </div>
      </td>
      <td className="p-1">
        <>
          <IconMenu2
            icon="menu-dots-vertical"
            dropdownClassName=" w-40 "
            actions={[
              {
                label: "Deactivate",
                icon: "trash",
                actionType: ActionType.alert,
                onClick: (e: any) => {
                  setDeleteModal(true);
                  console.log("delete", deleteModal);
                },
              },
            ]}
            hideOnEmpty={false}
            onClick={undefined}
          />

          <ModalForm
            visible={deleteModal}
            hideHeader
            className="w-full max-w-sm"
            setVisible={setDeleteModal}>
            <div className="flex flex-col items-center justify-center">
              <Label
                size="body1"
                variant="t1"
                translate
                className="my-2 text-center">
                Are you sure you want to Deactivate?
              </Label>
              <Label className={"text-xs text-alert"}>
                {subscription?.name} of {amount} per month
              </Label>

              <div className="mt-4 h-24 w-11/12 border border-border bg-background">
                {deleteModal && (
                  <Label
                    variant="s1"
                    className="flex justify-center p-3 text-center text-xs font-normal">
                    Note: There are users subscribed to this membership so this
                    will deactivate the membership until their next renewal
                    cycle. And will be not be visible to new users
                  </Label>
                )}
              </div>
              <div className="mt-6 flex w-full flex-col items-center justify-between gap-4 px-4 sm:flex-row">
                <TextButton
                  label="Cancel"
                  className="flex-1 outline outline-1"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDeleteModal(false);
                  }}
                />
                <Button
                  label="Yes, deactivate it"
                  className="flex-1"
                  btnColor="red"
                  isLoading={deleteStatus === "loading"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteSubscription();
                  }}
                />
              </div>
            </div>
          </ModalForm>
        </>
      </td>
    </tr>
  );
};

export default SubscriptionTile;
