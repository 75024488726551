import { FormError } from "../../../form-error.component";
import { TextInput } from "../../../text-input.component";

/**
 * Post title input component
 * @param {string} title - post title string
 * @param {Function} setTitle - function to set title
 * @param {object} errors - error object containing errors
 * @param {Function} setErrors - function to set error messages
 */
function PostTitle({ title, setTitle, errors, setErrors }) {
  return (
    <div className="pb-1 pt-2">
      <TextInput
        noMargin
        defaultValue={title}
        onChange={(e) => {
          setTitle(e);
          if (e.length > 180) {
            setErrors({
              title: "Title must be less than 180 characters.",
            });
          } else {
            setErrors({
              title: undefined,
            });
          }
        }}
        placeholder="Heading (optional)"
        className="px-0 text-secondary-foreground placeholder:text-xl placeholder:font-semibold placeholder:text-secondary-foreground/70"
        inputHolderClassName="border-none text-xl"
      />
      <FormError error={errors?.title} padding0 />
    </div>
  );
}

export { PostTitle };
