import cx from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useAppService } from "../../hooks/use-app-service";
import useModel from "../../hooks/use-model.hook";
import { history } from "../../_config";
import { MessageService } from "../../_service/message.service";
import { AppDispatch, RootState } from "../../_store";
import {
  getAllDirectChats,
  getAllDirectChatsSuccessAction,
} from "../../_store/_actions/direct-message.actions";
import { setPersonalGroup } from "../../_store/_actions/personal-group.actions";
import { formatNumber } from "../../_utils/extensions/typescript-utils";
import I18 from "../atoms/i18";
import { Avatar } from "../avatar.component";
import CommunityChatMemberList from "../community/community-chat-member-list.component";
import { ListView } from "../molecule/listview.component";
import UIcon from "../uicon-component";

function DirectMessageUsersListComponent({
  user,
  community,

  // state
  chats,
  setChats,
  isLoading,
  error,
}: // dispatch

any) {
  const [communityMemberListVisible, setCommunityMemberListVisible] =
    useState(false);

  // get selected chat user id from url
  const [userId, setUserId] = useState(window.location.pathname.split("/")[2]);

  const { analyticsService } = useAppService();
  const {
    messageListModelState: { setShowMessageListModel },
    DirectMessageModelState: { setIdToViewMessage, setShowDirectMessageModel },
  } = useModel();

  useEffect(() => {
    const unlisten = history.listen((location: any) => {
      // set the current chat userId if needed, this will be used to not show unread messages
      setUserId(location.pathname.split("/")[2]);
    });

    return () => {
      unlisten();
    };
  });

  useEffect(() => {
    MessageService.getDirectChats(user)
      .then(({ users }) => {
        setChats(users);
      })
      .catch((err) => {
        // check if axios response has message
        if (err.response && err.response.data && err.response.data.message) {
          console.log(err.response.data.message);
        } else {
          console.log("Something went wrong!");
        }
      });
  }, []);

  return (
    <div className="">
      {/* header */}
      <div className="flex items-center justify-between p-2 px-4 shadow-sm">
        <div className="sidebar-heading font-semibold capitalize text-secondary-foreground/85">
          <I18>Inbox</I18>
        </div>
        <button
          className="group rounded p-1 px-2 hover:bg-background"
          onClick={(e) => {
            analyticsService.track("create-new-chat");
            setCommunityMemberListVisible(true);
          }}>
          <UIcon
            icon="plus"
            solid
            size="xs"
            className={
              "scale-100 transition-all duration-150 group-active:scale-95"
            }
          />
        </button>
      </div>
      <ListView
        items={chats}
        loading={isLoading}
        className="px-2"
        renderItem={(chatUser, index) =>
          chatUser && (
            <div
              key={index}
              // to={getDirectMessagePageRoute(chatUser.id)}
              onClick={() => {
                setShowDirectMessageModel(true);
                setIdToViewMessage(chatUser.id);
                setShowMessageListModel(false);
                // reset the notification count to 0
                MessageService.markAllMessagesInChatAsRead(user, chatUser.id)
                  .then(() => {
                    const chat = chats.find(
                      (chat: any) => chat.id === chatUser.id
                    );
                    chat.unreadMessageCount = 0;
                    // TODO: do the api call to mark all messages as read
                    // setChats([...chats]);
                  })
                  .catch((err) => {
                    console.log("could not mark user chat as read", err);
                  });
              }}
              className={cx(
                "flex cursor-pointer place-content-between items-center rounded py-0.5 hover:bg-accent hover:text-accent-foreground",
                {
                  "bg-primary text-primary-foreground": userId === chatUser.id,
                  "text-secondary-foreground/80": userId !== chatUser.id,
                }
              )}>
              <Avatar
                user={chatUser}
                size={35}
                className={cx(
                  "my-2 rounded px-2 hover:text-primary-foreground",
                  {
                    "bg-primary": userId === chatUser.id,
                    "theme-text-title-1": userId !== chatUser.id,
                  }
                )}
                // notificationCount={chatUser.unreadMessageCount}
                extraInfo={undefined}
              />
              {chatUser.unreadMessageCount &&
              chatUser.unreadMessageCount > 0 ? (
                <div className="flex aspect-square h-6 w-6 place-content-center items-center rounded-full bg-primary text-center text-primary-light">
                  <p className="text-xs">
                    {formatNumber(chatUser.unreadMessageCount)}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          )
        }
        noItemsElement={
          <div className="flex h-52 flex-col place-content-center items-center text-center text-secondary-foreground/70">
            <I18>No chats found</I18>
          </div>
        }
        placeholder={
          <div className="flex flex-col gap-4 p-2">
            {Array.from({ length: 20 }, (_, i) => (
              <div
                key={i}
                className="flex animate-pulse flex-row items-center gap-4">
                <div className="h-8 w-8 rounded-full border border-border bg-accent" />
                <div className="h-4 w-2/4 rounded bg-accent" />
              </div>
            ))}
          </div>
        }
      />

      <CommunityChatMemberList
        community={community}
        user={user}
        isSubscriberListVisible={communityMemberListVisible}
        setIsSubscriberListVisible={setCommunityMemberListVisible}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth,
  community: state.community,
  personalGroup: state.personalGroup,
  socket: state.socket,
  chats: state.directMessages.users,
  isLoading: state.directMessages.isLoading,
  error: state.directMessages.error,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      setPersonalGroup: setPersonalGroup,
      setChats: getAllDirectChatsSuccessAction,
      getAllDirectChats,
    },
    dispatch
  );

export const DirectMessageUsersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectMessageUsersListComponent);
