import { useEffect, useState } from "react";
import { ToastTypes } from "../contexts/toastr.context";
import useLang from "../hooks/use-lang.hook";
import { CommunityService } from "../_service";
import { Button } from "./button.component";
import RichTextEditor from "./form-controls/rich-text-editor.component";
import ModalForm from "./model2";

/**
 * Post create modal
 * @param {object} community
 * @param {Object} user - user object
 * @param {Object} group - Group in which post is being created. If post is being created from community home page, group is undefined.
 * @param {Array<Object>} groups - List of groups of community.
 * @param {string} sectionId - section id of section in which post is being created. If post is being created from community home page, sectionId is undefined.
 * @param {Function} addToast - function to add toast
 * @param {Object} activeProps - object to control visibility of modal
 * @param {Object} displayProps - object to control display of close prompt
 */
export default function ReportModal({
  community = "",
  contentLink = "",
  reportType = "",
  addToast = null,
  activeProps = null,
  displayProps = null,
  content = null,
}) {
  const lang = useLang();

  // Display reporting modal
  const { isReportModalVisible: active, setReportModalVisible: setActive } =
    activeProps;
  // Display close prompt
  const { displayClosePrompt, setDisplayClosePrompt } = displayProps;

  const [reportDescription, setReportDescription] = useState("");

  const reportUser = async () => {
    try {
      const response = await CommunityService.reportPost({
        reportType: reportType,
        reasonOfReporting: reportDescription,
        communityId: community.id.toString(),
        contentLink: contentLink,
        content: content,
      });
      if (response !== null) {
        addToast("Reported successfully", "", ToastTypes.success);
        setReportDescription("");
        setActive(false);
      } else
        throw new Error("Reporting failed - Contact admin" + response.message);
    } catch (error) {
      console.error("reporting-error", error);
      addToast("Reporting failed - Contact admin", "", ToastTypes.danger);
    }
  };

  const closeModal = (_) => {
    if (
      reportDescription !== "" &&
      !window.confirm(lang.trans("Are you sure you want to discard Report?"))
    ) {
      return;
    }
    setReportDescription("");
    setActive(false);
  };

  // Preserve the content if user clicked outside the modal
  // Display close prompt if cancelled or sent
  useEffect(() => {
    if (displayClosePrompt) {
      closeModal(true);
      setDisplayClosePrompt(false);
    }
  }, [displayClosePrompt]);

  return (
    <>
      <ModalForm
        visible={active}
        setVisible={setActive}
        hideHeader
        className="CreatePost flex max-h-[95vh] w-full max-w-[720px] flex-col rounded bg-card p-5">
        <div className={"align-center mb-3 flex flex-col justify-center"}>
          <p className="flex justify-center">Why are you reporting this?</p>
          <p className="flex justify-center">
            Please provide a brief description of the issue.
          </p>
        </div>

        <RichTextEditor
          text={reportDescription}
          setText={setReportDescription}
          onSubmit={reportUser}
          className="border-border"
        />

        <div className="flex justify-around">
          <Button
            className="my-4 mr-2 flex-grow"
            label="Cancel"
            onClick={() => {
              setDisplayClosePrompt(true);
            }}
            large
          />
          <Button
            className="theme-bg-danger my-4 ml-2 flex-grow border-border"
            label="Send Report"
            onClick={() => {
              if (reportDescription === "") {
                if (
                  !window.confirm(
                    "Are you sure you want to report without description?"
                  )
                ) {
                  return;
                }
              }
              reportUser();
            }}
          />
        </div>
      </ModalForm>
    </>
  );
}
