import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import { cn } from "../../../_components";
import UIcon, { UIcons } from "../../../_components/uicon-component";

export function ProfileFeedFilter({
  isMyProfile,
  isLoading,
}: {
  isMyProfile: boolean;

  isLoading: boolean;
}) {
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const activeTab = urlParams.get("profile-tab") as
    | "profile"
    | "posts"
    | "activity"
    | "bookmark"
    | null;

  const filterOptions: {
    key: "profile" | "posts" | "activity" | "bookmark";
    label: string;
    icon: UIcons;
  }[] = [
    {
      label: "Profile",
      key: "profile",
      icon: "user",
    },
    {
      label: "Posts",
      key: "posts",
      icon: "edit",
    },
    {
      label: "Activities",
      key: "activity",
      icon: "pulse",
    },

    // {
    //   label: "Groups",
    //   key: "groups",
    //   icon: "cube",
    // },
  ];

  if (isMyProfile) {
    filterOptions.push({
      label: "Bookmark",
      key: "bookmark",
      icon: "bookmark",
    });
  }

  if (isLoading) {
    return (
      <div className="flex place-content-center items-center gap-2">
        <div className="h-5 w-16 rounded-full bg-gray-200" />
        <div className="h-5 w-16 rounded-full bg-gray-200" />
        <div className="h-5 w-16 rounded-full bg-gray-200" />
        {isMyProfile && <div className="h-5 w-16 rounded-full bg-gray-200" />}
      </div>
    );
  }
  console.log({ activeTab, urlParams });
  return (
    <div className="">
      <div className="flex w-full place-content-center items-center gap-2 sm:gap-4">
        {filterOptions.map((option) => (
          <Link
            key={option.key}
            to={updateUrlForProfileTab(option.key)}
            className={cn(
              cx(
                "hover:bg-primary-light flex cursor-pointer items-center gap-2 rounded-full border border-border px-2 py-1 text-xs font-medium sm:px-4",
                {
                  "bg-primary text-primary-foreground hover:border-primary hover:text-primary":
                    option.key === activeTab,
                  "text-secondary-foreground/80 hover:border-secondary-foreground/50":
                    option.key !== activeTab,
                }
              )
            )}
            onClick={() => {}}>
            <UIcon icon={option.icon} />
            {option.label}
          </Link>
        ))}
      </div>
    </div>
  );
}

function updateUrlForProfileTab(
  key: "profile" | "posts" | "activity" | "bookmark"
) {
  const searchParam = new URLSearchParams(window.location.search);
  searchParam.set("profile-tab", key);

  return window.location.pathname + "?" + searchParam.toString();
}
