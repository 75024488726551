import React, { Suspense, memo } from "react";
import { MentionService } from "../../../../_service/mention.service";
const RichTextEditor = React.lazy(
  () => import("../../../form-controls/rich-text-editor.component")
);
const MDEditor = React.lazy(
  () => import("../../../form-controls/md-editor.component")
);

/**
 *  Post description component
 * @param {string} description - post description string
 * @param {Function} setDescription - function to set description
 * @param {object} community - community object
 * @param {object} user - user object
 * @param {boolean} isBannerAdded - is banner added to the post content
 */
const PostEditor = memo(({
  user,
  community,
  description,
  setDescription,
  isModalFullScreen,
}) => {
  return community?.configuration?.useMdEditorForCreatePostDescription ? (
    <Suspense fallback={<></>}>
      <MDEditor
        text={description}
        setText={setDescription}
        getMentionsFeed={async (query) => {
          const users = (
            await MentionService.searchInCommunity(user, community.id, query)
          ).users.map((user, index) => {
            const useruid = user.id;
            user.id = "@" + user.userId;
            user.userId = useruid;
            return user;
          });
          return users;
        }}
      />
    </Suspense>
  ) : (
    <div
      className={`CreatePostEditor mb-4 text-secondary-foreground ${
        isModalFullScreen ? "min-h-[80vh] overflow-y-auto" : ""
      }`}>
      <Suspense
        fallback={
          <div className="h-[200px] w-full cursor-progress rounded">
            <p
              style={{ fontSize: "medium", fontWeight: 400 }}
              className="text-[#c6c6c6]">
              Write here...
            </p>
          </div>
        }>
        <RichTextEditor
          className={`noBorder placeholder:font-md overflow-auto placeholder:text-secondary-foreground/70 ${
            !isModalFullScreen ? "overflow-auto" : ""
          }`}
          text={description}
          setText={setDescription}
          getMentionsFeed={getMentionsFeed(user, community)}
          placeholder={"Write here..."}
        />
      </Suspense>
    </div>
  );
});

function getMentionsFeed(user, community) {
  return async (query) => {
    const users = (
      await MentionService.searchInCommunity(user, community.id, query)
    ).users.map((user, index) => {
      const useruid = user.id;
      user.id = "@" + user.userId;
      user.userId = useruid;
      return user;
    });
    return users;
  };
}

export { PostEditor, getMentionsFeed };
