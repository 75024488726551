import PostTileShimmer, { PostTileType } from "./post-tile-shimmer.component";

export { PostTileType } from "./post-tile-shimmer.component";

/**
 * PostListShimmer
 * @param {*} length
 * @param {*} type
 */
function PostListShimmer({
  length,
  type = PostTileType.compact,
  className = "",
}) {
  const list = [];

  for (let i = 0; i < length ?? 1; i++) {
    list.push(<PostTileShimmer key={i} type={type} className={className} />);
  }
  return (
    <div className="z-0 flex flex-grow animate-pulse flex-col py-2 pt-0">
      {list}
    </div>
  );
}

export default PostListShimmer;
