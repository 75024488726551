import { useState } from "react";
import { ToastTypes } from "../../contexts/toastr.context";
import { useToast } from "../../hooks/use-toast.hook";
import { Community } from "../../types/community/community.type";
import { Group } from "../../types/group/group.type";
import { User } from "../../types/user/minimal-user.type";
import { createGroupPageUrlFromSlug } from "../../_pages";
import { EmailService } from "../../_service";
import I18 from "../atoms/i18";
import TextButton from "../atoms/text-button";
import { Button } from "../button.component";
import IconButton2 from "../icon-button-2-component";
import { Modal } from "../modal.component";
import CopyTextField from "../molecule/copy-text-field.component";
import { TextInput } from "../text-input.component";
import UIcon from "../uicon-component";

interface Props {
  active?: boolean;
  setActive?: (e: boolean) => void;
  onClose?: () => void;
  user: User;
  group: Group;
  community: Community;
}

/**
 * Invite users to a group using link or email addresses.
 * @param {boolean} active - Whether the modal is active.
 * @param {function} setActive function to set the modal active.
 * @param {Object} user - The user object.
 * @param {Object} group - The active group object.
 * @param {Object} community - The community object.
 * @param {function} addToast - Function to add a toast.
 */
function GroupInviteModal({
  active = false,
  setActive = (e) => {},
  onClose = () => {},
  user,
  group,
  community,
}: Props) {
  const { addToast } = useToast();
  return (
    <Modal
      className="GroupInviteModal"
      active={active}
      setActive={setActive}
      padding={false}>
      <div className="bg-background">
        <div className="flex items-center justify-between py-1 pl-4">
          <span className="text-lg font-bold text-secondary-foreground">
            <I18>Invite to</I18> {group.name}
          </span>
          <IconButton2
            icon="cross-small"
            solid
            hoverable
            size="2xl"
            onClick={() => {
              setActive(false);
            }}
          />
        </div>
        {/* Body */}
        <div className="bg-card px-4 pt-4">
          <div className="flex items-start space-x-2">
            <UIcon icon={"link"} />
            <div className="font-semibold text-secondary-foreground/85">
              <I18>Invite via link</I18>
            </div>
          </div>
          <CopyTextField
            className="mt-2 bg-background text-secondary-foreground/80"
            text={createGroupPageUrlFromSlug(group)}
          />

          <InviteIndividual />
        </div>
      </div>
    </Modal>
  );

  // Invites by email section
  function InviteIndividual() {
    const groupId = group.id;
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState([
      {
        email: "",
        name: "",
      },
    ]);

    function handleIndividualInvite() {
      try {
        const validEmails = userData.filter((member) => {
          return member.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        }).length;
        if (validEmails !== userData.length) {
          // sendInvite();
          addToast("Please enter a valid email address", "", ToastTypes.danger);
        }

        const payload = {
          groupId: groupId == document.location.origin ? null : groupId,
          additionalMessage: "",
          memberData: userData,
        };
        setIsLoading(true);
        EmailService.inviteByEmail(user, community.id, payload)
          .then(() => {
            addToast("Invitation sent successfully", "", ToastTypes.success);
            setActive(false);
            onClose();
          })
          .catch((err) => {
            addToast("Error sending invitation", "", ToastTypes.danger);
            console.log(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }

    return (
      <div className="mt-2 bg-card pb-4 pt-4">
        <div className="font-semibold text-secondary-foreground/85">
          <I18>Invite Members Individually</I18>
        </div>
        <div className="">
          {userData.map((user, index) => (
            <div
              key={index}
              className="mt-2 flex items-start justify-between space-x-1">
              <TextInput
                placeholder="Name"
                noPrefixSeparator
                noMargin
                large
                defaultValue={user.name}
                inputHolderClassName="bg-background"
                containerClassName="focus:outline-none bg-card rounded"
                onChange={(e) => {
                  const newUserList = [...userData];
                  newUserList[index].name = e;
                  setUserData(newUserList);
                }}
              />
              <TextInput
                placeholder="Email Id"
                type="email"
                noPrefixSeparator
                validator={(value) => {
                  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*$/.test(value)) {
                    return "Email is not valid!";
                  }
                }}
                noMargin
                large
                defaultValue={user.email}
                inputHolderClassName="bg-background"
                containerClassName="focus:outline-none bg-card rounded ml-2 md:ml-0 flex-grow"
                onChange={(e) => {
                  const newUserList = [...userData];
                  newUserList[index].email = e;
                  setUserData(newUserList);
                }}
              />

              <IconButton2
                icon="cross"
                size="sm"
                hoverable
                onClick={() => {
                  const newUserList = [...userData];
                  newUserList.splice(index, 1);

                  setUserData(newUserList);
                }}
              />
            </div>
          ))}
        </div>
        {Array.isArray(userData) && userData.length < 5 && (
          <TextButton
            prefix={<UIcon icon={"plus-small"} />}
            className="mt-1 flex items-center"
            label="Add more"
            onClick={() => {
              setUserData([...userData, { name: "", email: "" }]);
            }}
          />
        )}

        <div className="mt-4 flex items-center justify-between">
          <TextButton
            label="Close"
            onClick={() => {
              setActive(false);
              onClose();
            }}
          />
          <Button
            disabled={
              !userData.length ||
              !userData.every((user) =>
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*$/.test(user.email)
              )
            }
            onClick={(e) => {
              if (userData.length > 0) {
                handleIndividualInvite();
              }
            }}
            label="Send Invite"
            large
            type="submit"
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  }
}

export default GroupInviteModal;
