import cx from "classnames";
import moment from "moment";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import TurndownService from "turndown";
import { ToastTypes, withToast } from "../contexts/toastr.context";
import { useAppService } from "../hooks/use-app-service";
import useLang from "../hooks/use-lang.hook";
import imageGrayIcon from "../_assets/vectors/image-icon.svg";
import plusGrayIcon from "../_assets/vectors/plus-gray-icon.svg";
import questionIcon from "../_assets/vectors/question-icon.svg";
import AnalyticsService, { EventType } from "../_service/analytics-service";
import { MentionService } from "../_service/mention.service";
import { PostService } from "../_service/post.service";
import I18 from "./atoms/i18";
import { Avatar } from "./avatar.component";
import { Button } from "./button.component";
import { Card } from "./card.component";
import ExpiredActionPreventor from "./community/community-expired-action-preventor.component";
import CreateSlugModal, {
  ConvertToSlugString,
  limitMaxCharacterTo255,
} from "./create-slug-modal";
import ErrorBoundary from "./error-boundry.component";
import { ErrorDiv } from "./error.component";
import { FormError } from "./form-error.component";
import IconButton from "./icon-button.component";
import CropImageComponent from "./image-crop/crop-image-component";
import { Loader } from "./loader.component";
import { Modal } from "./modal.component";
import { CreatePostPoll } from "./post/create/create-post-poll.component";
import {
  CreatePostQuiz,
  QUIZ_QUESTION_SKELETON,
} from "./post/create/create-post-quiz.component";
import { PostImages } from "./post/post-images.component";
import { TextInput } from "./text-input.component";
import UIcon from "./uicon-component";
import UploadProgressBar from "./upload-progress-bar.component";
const RichTextEditor = React.lazy(
  () => import("./form-controls/rich-text-editor.component")
);
const MDEditor = React.lazy(
  () => import("./form-controls/md-editor.component")
);

const tds = new TurndownService();

let initialTagsList = [];
function CreatePostCardComponent({
  community = null,
  groupId = null,
  group = null,
  tabId = null,
  user,
  compact = false,
  addPost = (post) => {},
  inlineButtonComponent = null,
  addToast,
}) {
  // modalRef
  let descriptionRef = null;
  const { analyticsService } = useAppService();

  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // title and description related
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionRows, setDescriptionRows] = useState(1);

  const [embedCode, setEmbedCode] = useState("");

  // file related
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [document, setDocument] = useState(null);
  const [coverPicture, setCoverPicture] = useState(null);

  // file progress related
  const [imageUploadPercent, setImageUploadPercent] = useState(0);
  const [videoUploadPercent, setVideoUploadPercent] = useState(0);
  const [coverPictureUploadPercent, setCoverPictureUpload] = useState(0);
  const [documentUploadPercent, setDocumentUploadPercent] = useState(0);

  // tags related
  const [isTagModalVisible, setIsTagModalVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  // quiz related
  const [isQuiz, setIsQuiz] = useState(false);
  const [quizTitle, setQuizTitle] = useState("");
  const [quizDuration, setQuizDuration] = useState(10);
  const [quizQuestions, setQuizQuestions] = useState([QUIZ_QUESTION_SKELETON]);
  const [quizInstruction, setQuizInstruction] = useState("");

  // question related
  const [isPoll, setIsPoll] = useState(false);
  const [isQuestion, setIsQuestion] = useState(false);
  const [endTime, setEndTime] = useState(moment().add(12, "h"));

  const [notifyUsers, setNotifyUsers] = useState(false);

  const [errors, setErrors] = useState(null);

  // Slugs related
  const [slugModalActive, setSlugModalActive] = useState(false);
  const [slug, setSlug] = useState(null);
  const [slugTitle, setSlugTitle] = useState(null);
  const [slugDescription, setSlugDescription] = useState(null);
  const [OGTitle, setOGTitle] = useState(null);
  const [OGDescription, setOGDescription] = useState(null);
  const [OGImage, setOGImage] = useState(null);

  // Store post create error message
  const [errorMessages, setErrorMessage] = useState({});

  // post button disabled or enabled?
  const canPost = (() => {
    // return true for disabled
    return (
      title.length === 0 &&
      selectedTags.length === 0 &&
      tagsList.length === 0 &&
      description.length === 0 &&
      !isQuiz &&
      !isPoll &&
      images.length === 0 &&
      videos.length === 0 &&
      !document
    );
  })();

  const communityId = community?.id;
  useEffect(() => {
    if (communityId && active) {
      getTags();
    }
  }, [communityId, active]);

  useEffect(() => {
    searchTagsLocally();
  }, [query]);

  const getTags = async () => {
    let searchTagResponse = await PostService.getTagsBySearch(
      user,
      query,
      community.id
    );
    initialTagsList = searchTagResponse.tags;
    setTagsList(searchTagResponse.tags);
  };

  const searchTagsLocally = async () => {
    let searchList = [];
    if (query.length > 0) {
      searchList = tagsList.filter((tag) =>
        tag.name.toLowerCase().includes(query.toLowerCase())
      );
      setTagsList(searchList);
    } else {
      setTagsList(initialTagsList);
    }
  };

  const onSelectTags = async (selectedItem, selectedIndex) => {
    let allSelectedTags = [...selectedTags];
    let allTagsList = [...tagsList];

    if (allSelectedTags.includes(selectedItem)) {
      allSelectedTags = allSelectedTags.filter((m) => m !== selectedItem);
      setSelectedTags(allSelectedTags);
    } else if (
      allTagsList.filter((tL) => tL.name == selectedItem).length == 0
    ) {
      allTagsList.unshift({ name: selectedItem });
      initialTagsList.unshift({ name: selectedItem });
      allSelectedTags.push(selectedItem);
      setSelectedTags(allSelectedTags);
      setTagsList(allTagsList);
    } else {
      allSelectedTags.push(selectedItem);
      setSelectedTags(allSelectedTags);
    }
  };

  function clearSlugMeta() {
    setSlug(null);
    setOGTitle(null);
    setOGDescription(null);
    setOGImage(null);
    setSlugTitle(null);
    setSlugDescription(null);
  }
  const closeModal = (prompt = false) => {
    // if we are said to prompt and user can post, show prompt
    if (prompt && !canPost) {
      if (!window.confirm("Are you sure you want to discard current post?")) {
        return;
      }
    }

    // reset the form
    setTitle("");
    setDescription("");
    setEmbedCode("");
    // reset the rows
    setDescriptionRows(1);
    if (descriptionRef) descriptionRef.style.height = "auto";
    // remove quiz/poll/question
    setIsPoll(false);
    setIsQuestion(false);
    setIsQuiz(false);
    setQuizTitle("");
    setQuizQuestions([QUIZ_QUESTION_SKELETON]);
    // tags data
    setSelectedTags([]);
    // setTagsList([]);
    // remove media
    setImages([]);
    setVideos([]);
    setDocument(null);
    setCoverPicture(null);
    // reset upload percentage
    setImageUploadPercent(0);
    setVideoUploadPercent(0);
    setCoverPictureUpload(0);
    setDocumentUploadPercent(0);
    setErrors(null);
    // set the form inactive
    setActive(false);
    clearSlugMeta();
    setErrorMessage({});
  };

  const createPost = async (event) => {
    try {
      const embedlessDescription = description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );

      const postDescription = community?.configuration
        ?.useMdEditorForCreatePostDescription
        ? description
        : tds.turndown(embedlessDescription);

      // create post
      const post = {
        title,
        description: postDescription,
        groupId,
        embedCode,
        tabId,
        notifyUsers,
        tags: selectedTags,
        meta: {
          slug: ConvertToSlugString(
            slug === null ? ConvertToSlugString(title) : slug
          ),
          title: limitMaxCharacterTo255(slugTitle === null ? title : slugTitle),
          description: limitMaxCharacterTo255(
            slugDescription === null ? postDescription : slugDescription
          ),
          openGraphTitle: limitMaxCharacterTo255(
            OGTitle === null ? title : OGTitle
          ),
          openGraphDescription: limitMaxCharacterTo255(
            OGDescription === null ? postDescription : OGDescription
          ),
          openGraphImage: OGImage,
        },
      };
      // check if isQuiz
      if (isQuiz) {
        post.poll = {
          isQuiz: true,
          isQuestion: false,
          title: quizTitle,
          questions: quizQuestions,
          duration: quizDuration,
          endTime: moment().add(1, "month"),
        };
      }

      if (isPoll) {
        post.poll = {
          isQuiz: false,
          isQuestion: isQuestion,
          title: quizTitle,
          questions: quizQuestions,
          duration: quizDuration,
          endTime: endTime,
        };
      }

      // check for validation on post
      const e = validatePost(post, images, videos, document);

      setErrors(e);
      if (e) {
        return;
      }

      // start loading
      setIsLoading(true);
      // create post
      let createdPostResponse = await PostService.createPost(user, post);
      // check if media needs uploading?
      if (images.length > 0) {
        // upload the images
        createdPostResponse = await PostService.uploadImages(
          user,
          createdPostResponse.post.id,
          images,
          setImageUploadPercent
        );
      }
      if (videos.length > 0) {
        // upload the videos
        createdPostResponse = await PostService.uploadVideos(
          user,
          createdPostResponse.post.id,
          videos,
          setVideoUploadPercent
        );
      }
      // check if document needs upload
      if (document) {
        // upload the document
        createdPostResponse = await PostService.uploadDocument(
          user,
          createdPostResponse.post.id,
          document,
          setDocumentUploadPercent
        );
      }
      // check if cover picture needs upload
      if (coverPicture) {
        // upload the cover picture
        createdPostResponse = await PostService.uploadCoverPicture(
          user,
          createdPostResponse.post.id,
          coverPicture,
          setCoverPictureUpload
        );
      }

      // add post
      addPost(createdPostResponse.post);

      AnalyticsService.logEvent(EventType.Post.name, EventType.Post.create);

      // mixpanel event
      analyticsService.track("create-post", {
        userType: community.myRole,
        groupName: group.name,
        sectionName: tabId,
        postType: isQuiz
          ? "quiz"
          : isPoll
            ? "poll"
            : document
              ? "attachment"
              : "none",
        notifyusers: notifyUsers,
        postid: createdPostResponse.post.id,
      });

      // stop loading
      setIsLoading(false);

      // show toast
      addToast("Post added successfully!");

      // close the modal
      closeModal();
    } catch (error) {
      //
      setIsLoading(false);
      console.log({ error });
      if (error && error.response && error.response.data.errors) {
        if (error.response.data.errors.title) {
          // setErrorMessage(error.response.data.errors.title[0])
          setErrorMessage({ title: error.response.data.errors.title[0] });
        }
      }
      addToast(
        "Failed to create post. Please check post content and retry again.   ",
        "",
        ToastTypes.danger
      );
    }
  };

  const CreatePostButton1 = ({ label = "" }) => {
    return isLoading ? (
      <div className="mx-5 my-1">
        {" "}
        <Loader />
      </div>
    ) : (
      <Button
        label={label ? label : "Post"}
        className={cx({
          "hidden md:block": !active,
        })}
        disabled={canPost}
        onClick={createPost}
      />
    );
  };

  const lang = useLang();
  // if not active, show preview only
  if (!active) {
    return compact ? (
      <ExpiredActionPreventor>
        {inlineButtonComponent ? (
          <span
            onClick={(e) => {
              if (active) {
                closeModal(true);
              } else {
                setActive(true);
              }
            }}>
            {inlineButtonComponent}
          </span>
        ) : (
          <Button
            label="Create Post"
            className={cx({
              "flex-shrink-0": !active,
            })}
            onClick={(e) => {
              if (active) {
                closeModal(true);
              } else {
                setActive(true);
              }
            }}
          />
        )}
      </ExpiredActionPreventor>
    ) : (
      <div
        onClick={(e) => {
          if (active) {
            closeModal(true);
          } else {
            setActive(true);
          }
        }}
        onKeyUp={(e) => {
          if (e.key === "Escape") {
            closeModal(true);
          }
        }}
        className={cx("CreatePostCard", {
          active: active,
        })}>
        <Card
          onClick={(e) => {
            // stop the card being closed when we click on inner divs
            if (active) {
              e.stopPropagation();
            }
          }}>
          {/* user avatar and text area */}
          <div className="items-start justify-between md:flex">
            <div className="flex flex-grow">
              <Avatar user={{ ...user, name: "" }} />
              <input
                className="post-title flex-grow py-1 text-xl font-semibold focus:outline-none md:px-2"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                placeholder={lang.trans("Write Here")}
                value={title}
              />
            </div>
            <CreatePostButton1 />
          </div>
        </Card>
      </div>
    );
  }

  // its active, show full create post component
  return (
    <div
      onClick={(e) => {
        if (active) {
          closeModal(true);
        } else {
          setActive(true);
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Escape") {
          closeModal(true);
        }
      }}
      className={cx("CreatePostCard", {
        active: active,
      })}>
      <Card
        onClick={(e) => {
          // stop the card being closed when we click on inner divs
          if (active) {
            e.stopPropagation();
          }
        }}>
        {/* user avatar and text area */}
        <div className="flex items-start justify-between">
          <div className="flex flex-grow">
            <Avatar user={user} extraInfo="now" />
          </div>
          <CreatePostButton1 />
        </div>
        {/* Post cover picture */}
        <div className="my-2">
          <CropImageComponent
            picture={coverPicture}
            setPicture={setCoverPicture}
            aspectRatio={16 / 9}
            recommendedRatio={"16:9"}
            cropRatioMessage="Picture ratio: 16:9"
            selectPictureComponent={
              <div className="flex cursor-pointer select-none items-center space-x-2 bg-background px-2 pb-2 pt-4">
                <img src={imageGrayIcon} alt="post" className="h-4 w-4" />

                <span className="text-xs font-semibold text-secondary-foreground/80">
                  <I18>Add Cover Picture</I18>
                </span>
              </div>
            }
          />
          <UploadProgressBar percentComplete={coverPictureUploadPercent} />
        </div>
        {/* title */}
        <div className="">
          <input
            className="post-title w-full flex-grow bg-transparent py-1 text-lg font-semibold focus:outline-none md:px-2"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder={lang.trans("Title (Optional)")}
            value={title}
          />
          <ErrorDiv error={errorMessages?.title} />
        </div>
        {/* cover image */}
        {/* <PostImageUploaderPreview
                    images={images}
                    setImages={setImages}
                    videos={videos}
                    setVideos={setVideos} /> */}
        <SelectedImagesPreview images={images} setImages={setImages} />
        <SelectedVideoPreviewWithImage videos={videos} setVideos={setVideos} />
        {/* media upload progress */}
        <UploadProgressBar percentComplete={imageUploadPercent} />
        <UploadProgressBar percentComplete={videoUploadPercent} />
        {/* rich text editor */}
        <div className="pb-2 pt-2">
          {community?.configuration?.useMdEditorForCreatePostDescription ? (
            <Suspense fallback={<></>}>
              <MDEditor
                text={description}
                setText={setDescription}
                getMentionsFeed={async (query) => {
                  const users = (
                    await MentionService.searchInCommunity(
                      user,
                      community.id,
                      query
                    )
                  ).users.map((user, index) => {
                    const useruid = user.id;
                    user.id = "@" + user.userId;
                    user.userId = useruid;
                    return user;
                  });
                  return users;
                }}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<></>}>
              <RichTextEditor
                text={description}
                setText={setDescription}
                className="max-w-1/2"
                getMentionsFeed={async (query) => {
                  const users = (
                    await MentionService.searchInCommunity(
                      user,
                      community.id,
                      query
                    )
                  ).users.map((user, index) => {
                    const useruid = user.id;
                    user.id = "@" + user.userId;
                    user.userId = useruid;
                    return user;
                  });
                  return users;
                }}
              />
            </Suspense>
          )}
        </div>
        {/* post embed code */}
        <PostEmbedCode
          embedCode={embedCode}
          group={group}
          setEmbedCode={setEmbedCode}
        />
        {/* tags related */}
        <div className="tags-container items-center">
          <div
            onClick={() => setIsTagModalVisible(true)}
            className="hover:theme-bg-disable flex cursor-pointer items-center rounded-[20px] px-2">
            <div className="m-0 rounded px-2 py-1 font-bold text-secondary-foreground/70">
              <I18>Add Tags</I18>
            </div>
            <span className="ml-2 flex h-5 w-5 items-center justify-center rounded-full border border-primary bg-primary">
              <UIcon
                icon="plus"
                size="xxs"
                className="text-primary-foreground"
              />
            </span>
          </div>

          {selectedTags.length === 0 && tagsList
            ? tagsList.slice(0, 5).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`ml-2 mt-2 flex items-center rounded-full px-2 py-1 text-xs text-primary-foreground ${
                      selectedTags.includes(item.name)
                        ? "bg-primary"
                        : "theme-bg-disable"
                    }`}
                    onClick={() => onSelectTags(item.name, index)}>
                    <span className="tag-title">{item.name}</span>
                  </div>
                );
              })
            : tagsList.map((item, index) => {
                return (
                  <>
                    {selectedTags.includes(item.name) ? (
                      <div
                        key={index}
                        className={`ml-2 mt-2 flex items-center rounded-full px-2 py-1 text-xs text-primary-foreground ${
                          selectedTags.includes(item.name)
                            ? "bg-primary"
                            : "theme-bg-disable"
                        }`}
                        onClick={() => onSelectTags(item.name, index)}>
                        <span className="tag-title">{item.name}</span>
                      </div>
                    ) : null}
                  </>
                );
              })}
        </div>
        {/* quiz related */}
        {isQuiz ? (
          <CreatePostQuiz
            errors={errors && errors.poll ? errors.poll : null}
            isPoll={isPoll}
            setIsPoll={(e) => {
              // toggle quiz
              setIsQuiz(false);
              setIsPoll(true);
            }}
            quizTitle={quizTitle}
            setQuizTitle={setQuizTitle}
            quizDuration={quizDuration}
            setQuizDuration={setQuizDuration}
            quizInstruction={quizInstruction}
            setQuizInstruction={setQuizInstruction}
            quizQuestions={quizQuestions}
            setQuizQuestions={setQuizQuestions}
          />
        ) : (
          <></>
        )}
        {/* poll related */}
        {isPoll ? (
          <CreatePostPoll
            errors={errors && errors.poll ? errors.poll : null}
            isQuestion={isQuestion}
            setIsQuestion={setIsQuestion}
            isQuiz={isQuiz}
            setIsQuiz={(e) => {
              // toggle quiz
              setIsQuiz(true);
              setIsPoll(false);
            }}
            quizTitle={quizTitle}
            setQuizTitle={setQuizTitle}
            quizDuration={quizDuration}
            setQuizDuration={setQuizDuration}
            quizInstruction={quizInstruction}
            setQuizInstruction={setQuizInstruction}
            quizQuestions={quizQuestions}
            setQuizQuestions={setQuizQuestions}
            endTime={endTime}
            setEndTime={setEndTime}
          />
        ) : (
          <></>
        )}
        {/* media previews */}
        <SelectedDocumentPreview
          document={document}
          setDocument={setDocument}
          uploadPercent={documentUploadPercent}
        />
        {/* post icons */}
        {active ? (
          <CreatePostFooter
            title={title}
            community={community}
            images={images}
            setImages={setImages}
            document={document}
            setDocument={setDocument}
            videos={videos}
            setVideos={setVideos}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            tabId={tabId}
            groupId={groupId}
            group={group}
            user={user}
            closeModal={closeModal}
            isQuiz={isQuiz}
            setIsQuiz={setIsQuiz}
            isPoll={isPoll}
            setIsPoll={setIsPoll}
            canPost={canPost}
            createPost={createPost}
          />
        ) : (
          <></>
        )}
        {/* notify user checkbox */}
        <div className="item-center flex place-content-between">
          {/* {group && group.myRole === "admin" ? (
            <div
              className="px-3 py-1 cursor-pointer"
              onClick={(e) => {
                setNotifyUsers(!notifyUsers);
              }}>
              <Checkbox selected={notifyUsers} />{" "}
              <span className="ml-5 pt-2 text-xs">Send email notification</span>
            </div>
          ) : (
            <></>
          )} */}
          <div
            onClick={() => {
              setSlugModalActive(true);
            }}
            className="flex cursor-pointer items-center space-x-2 font-semibold text-secondary-foreground">
            <UIcon
              icon="settings"
              size="xl"
              className="mr-1 text-secondary-foreground/70"
            />
            Advanced Settings
          </div>
          <CreateSlugModal
            community={community}
            active={slugModalActive}
            setActive={setSlugModalActive}
            slug={slug === null ? ConvertToSlugString(title) : slug}
            slugPrefix="p"
            setSlug={setSlug}
            title={slugTitle === null ? title : slugTitle}
            setTitle={setSlugTitle}
            description={
              slugDescription === null
                ? tds.turndown(description)
                : slugDescription
            }
            setDescription={setSlugDescription}
            OGTitle={OGTitle === null ? title : OGTitle}
            setOGTitle={setOGTitle}
            OGDescription={
              OGDescription === null ? tds.turndown(description) : OGDescription
            }
            setOGDescription={setOGDescription}
            OGImage={OGImage}
            setOGImage={setOGImage}
            OgImageSubtitle={
              "By default it will take up the image uploaded with the post"
            }
            clearSlugMeta={clearSlugMeta}
          />
        </div>
      </Card>
      {isTagModalVisible ? (
        <TagsModal
          active={isTagModalVisible}
          setActive={setIsTagModalVisible}
          header="Add Tags"
          query={query}
          setQuery={setQuery}
          tagsList={tagsList}
          selectedTags={selectedTags}
          onSelectTags={onSelectTags}
          // isLoading={isLoading}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

function CreatePostDescription({
  descriptionRows,
  setDescriptionRows,
  descriptionRef,
  description,
  setDescription,
  error,
}) {
  const lang = useLang();
  return (
    <>
      <textarea
        rows={descriptionRows}
        type="text"
        ref={(input) => {
          descriptionRef = input;
        }}
        value={description}
        onChange={(e) => {
          // set description
          setDescription(e.target.value);
          // increase textarea height
          e.target.style.height = e.target.scrollHeight + "px";
        }}
        onKeyUp={(e) => {
          if (e.key === "Backspace") {
            // backspace pressed, check if we removed a tab
            const lines = description.split("\n").length;
            const newRows = lines === 0 ? 1 : lines;
            if (descriptionRows !== newRows) {
              // lines added or deleted
              e.target.style.height = "";
              setDescriptionRows(newRows);
            }
          }
        }}
        className={cx(
          "mt-2 block flex-grow resize-none px-2 font-semibold focus:outline-none",
          {
            "text-secondary-foreground/70": description.length === 0,
            "text-secondary-foreground/85": description.length !== 0,
          }
        )}
        placeholder={lang.trans("Post something!")}
      />
      <FormError error={error} />
    </>
  );
}

/**
 * Stylish image/video picker just below title
 * @param {*} param0
 * @returns
 */
function PostImageUploaderPreview({
  images = [],
  setImages,
  videos = [],
  setVideos,
}) {
  let imageRef = useRef(null);

  // dont show if images or videos selected already
  if (images.length > 0 || videos.length > 0) return <></>;

  return (
    <div className="my-2 flex items-center">
      {/* image and video upload */}
      <input
        type="file"
        className="hidden"
        ref={imageRef}
        onChange={(e) => {
          if (e.target.files[0].type.startsWith("image/")) {
            // setImages([...images, e.target.files[0]]);
            setImages([e.target.files[0]]);
            setVideos([]);
          }
          // only one video to be uploaded right now
          if (e.target.files[0].type.startsWith("video/")) {
            setVideos([e.target.files[0]]);
            setImages([]);
          }
        }}
      />
      <div
        className="flex cursor-pointer items-center justify-center rounded-full border px-2 py-1 text-xs text-secondary-foreground/70 hover:bg-gray-50"
        onClick={(e) => {
          imageRef.current.click();
        }}>
        <img src="/assets/vectors/media-icon.svg" alt="" />
        <span className="ml-1">Cover picture or video</span>
      </div>
      <div className="flex-grow border-t"></div>
    </div>
  );
}

const SelectedImagesPreview = React.memo(function ({
  images = [],
  setImages = () => {},
}) {
  return (
    <div className="SelectedImagesPreview">
      <PostImages
        images={images.map((image, index) => URL.createObjectURL(image))}
        onDelete={(index) => {
          // remove the deleted image from state
          const oldImages = [...images];
          oldImages.splice(index, 1);
          setImages(oldImages);
        }}
      />
    </div>
  );
});

const SelectedVideoPreviewWithImage = React.memo(function ({
  videos = [],
  setVideos = () => {},
}) {
  return (
    <div className="SelectedVideoPreview">
      {videos.map((video, index) => (
        <div key={index} className="SelectedVideo my-2">
          <video
            src={URL.createObjectURL(video)}
            className="w-full"
            controlsList="nodownload"
          />
          <div
            onClick={(e) => {
              // remove the videos
              const oldVideos = [...videos];
              oldVideos.splice(index, 1);
              setVideos(oldVideos);
              // setVideos([]);
            }}
            className="remove">
            &times;
          </div>
        </div>
      ))}
    </div>
  );
});

function PostEmbedCode({ embedCode, setEmbedCode, group }) {
  const [isEmbedMenuVisible, setIsEmbedMenuVisible] = useState(embedCode);
  const lang = useLang();

  if (!group || !["admin", "moderator"].includes(group.myRole)) return <></>;

  return isEmbedMenuVisible ? (
    <div className="PostEmbedCode mt-5">
      <div className="mb-1 text-secondary-foreground/80">
        <I18>Embed Code</I18>
      </div>
      <textarea
        rows={2}
        type="text"
        value={embedCode}
        onChange={(e) => {
          setEmbedCode(e.target.value);
        }}
        placeholder={lang.trans("Paste your embed code here!")}
        className="mt-2 block w-full flex-grow resize-none rounded border border-border bg-background bg-transparent px-2 focus:outline-none"
      />
    </div>
  ) : (
    <span
      className="cursor-pointer text-primary"
      onClick={(e) => {
        setIsEmbedMenuVisible(true);
      }}>
      <I18>Click here to add embed code.</I18>
    </span>
  );
}

function SelectedDocumentPreview({
  document = null,
  setDocument = (e) => {},
  uploadPercent = 0,
}) {
  return document ? (
    <div className="SelectedDocumentPreview relative my-8">
      {/* <img
                src={documentIcon}
                style={{ position: "absolute", top: "9px", left: "6px" }}
                alt="PDF Doc" />
            <a
                download
                target="_blank"
                rel="noreferrer"
                href={document}
                title="Open file"
                className="border-2 border-border block rounded px-12 font-semibold py-2">
                <div>{document.name}</div>
                <div className="uppercase text-xs">{document.name.split(".").pop()}</div>
            </a> */}
      <a
        download
        target="_blank"
        rel="noreferrer"
        href="/#"
        title="Open file"
        className="flex items-center space-x-2 rounded border-2 border-border px-4 py-2 font-semibold">
        <UIcon icon="document" className="h-9 text-3xl" />
        <div className="flex flex-grow items-center justify-between space-x-4">
          <div className="items-center">
            <div>{document.name}</div>
            <div className="text-xs uppercase">
              {document.name.split(".").pop()}
            </div>
          </div>
        </div>
      </a>
      <div
        onClick={(e) => {
          // remove the videos
          setDocument(null);
        }}
        className="remove">
        &times;
      </div>
      <UploadProgressBar percentComplete={uploadPercent} />
    </div>
  ) : (
    <></>
  );
}

function CreatePostFooter({
  title,
  community,
  images = [],
  setImages,
  videos = [],
  setVideos,
  document,
  setDocument,
  isLoading,
  setIsLoading,
  tabId,
  groupId,
  group,
  user,
  closeModal,
  isQuiz,
  setIsQuiz,
  isPoll,
  setIsPoll,
  canPost,
  createPost,
}) {
  let imageRef = useRef(null);
  let documentRef = useRef(null);

  return (
    <div className="CreatePostFooter mt-4 flex justify-between border-t border-border pt-3">
      <div className="flex w-full justify-start">
        {/* image and video upload */}
        <input
          type="file"
          className="hidden"
          ref={imageRef}
          onChange={(e) => {
            if (e.target.files[0].type.startsWith("image/")) {
              setImages([...images, e.target.files[0]]);
            }
            // only one video to be uploaded right now
            if (e.target.files[0].type.startsWith("video/")) {
              setVideos([...videos, e.target.files[0]]);
            }
          }}
        />
        <IconButton
          icon="img"
          iconSmall
          label="Image/Video"
          img="/assets/images/create-post/media.png"
          className="mx-1"
          onClick={(e) => {
            imageRef.current.click();
          }}
        />
        {/* document upload */}
        <input
          type="file"
          className="hidden"
          ref={documentRef}
          onChange={(e) => {
            setDocument(e.target.files[0]);
          }}
        />
        <IconButton
          icon="img"
          img="/assets/images/create-post/attachment.png"
          iconSmall
          label="Attachment"
          className="mx-1"
          onClick={(e) => {
            documentRef.current.click();
          }}
        />
        <IconButton
          icon="img"
          img="/assets/images/create-post/quiz.png"
          iconSmall
          label="Quiz"
          onClick={() => {
            setIsPoll(!isPoll);
            setIsQuiz(false);
          }}
          className="mx-1"
        />
        {/* <GoLiveButton
          group={group}
          user={user}
          community={community}
          closeModal={closeModal}
          setIsLoading={setIsLoading}
          groupId={groupId}
          tabId={tabId}
          title={title}
        /> */}
      </div>
      {/* {
                isLoading ? (
                    <div className="my-1 mx-5"><Loader /></div>
                ) : (
                    isPoll || isQuiz || true ? <Button label="Post" disabled={canPost} onClick={createPost} /> : <></>
                )
            } */}
    </div>
  );
}
// Tags Modal
function TagsModalComponent({
  onSubmit,
  header,
  isLoading,
  active = false,
  query,
  setQuery,
  tagsList,
  selectedTags,
  onSelectTags,
  setActive = (e) => {},
}) {
  const [isSpaceFound, setIsSpaceFound] = useState(false);
  const lang = useLang();

  useEffect(() => {
    setQuery("");
  }, []);

  const resetFormAndClose = () => {
    setQuery("");
    setActive(false);
  };

  // if (!activeGroup || !active) {
  //     return <></>;
  // }

  const onSelect = (selectedItem, selectedIndex) => {
    setQuery("");
    onSelectTags(selectedItem, selectedIndex);
  };

  return (
    <Modal
      className="CreateTagModal"
      active={active}
      width="390px"
      padding={false}
      setActive={setActive}>
      <ErrorBoundary fallback={<>CreateTagModal</>}>
        <div>
          {/* headers */}
          <div className="flex items-center justify-between bg-card py-2 pl-4 pr-2">
            <span className="font-bold text-secondary-foreground/85">
              <I18>{header}</I18>
            </span>
            <IconButton
              icon={plusGrayIcon}
              rotate={45}
              onClick={() => resetFormAndClose()}
            />
          </div>
          {/* body */}
          <div className="p-5 text-secondary-foreground/85">
            <div className="rounded">
              <TextInput
                prefix={
                  <img
                    src={questionIcon}
                    alt=""
                    className="search-tag-icon ml-2 bg-transparent"
                    style={{ filter: "opacity(0.5)", width: "18px" }}
                  />
                }
                noPrefixSeparator
                noBorder
                type="text"
                validator={(value) => {
                  if (value.match(/\s/g)) {
                    setIsSpaceFound(true);
                    return "Tag must not contain any spaces";
                  } else {
                    setIsSpaceFound(false);
                  }
                }}
                validationOutsideBox
                placeholder={"Search for tags"}
                value={query}
                noMargin
                onChange={(value) => {
                  setQuery(value);
                }}
                containerClassName="py-2 bg-background focus:outline-none border border-border rounded mt-1"
              />
            </div>
            <div className="modal-body">
              {tagsList
                ? tagsList.map((item, index) => {
                    return (
                      <div key={index} className="flex rounded">
                        {selectedTags.includes(item.name) ? (
                          <div
                            key={index}
                            className="hover:theme-bg-disable flex cursor-pointer rounded-lg p-2"
                            onClick={() => onSelect(item.name, index)}>
                            <span className="border-pensil-green flex h-5 w-5 items-center justify-center rounded-full border bg-success">
                              <UIcon icon="check" size="xs" className="" />
                            </span>
                            <span className="ml-4">{item.name}</span>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="hover:theme-bg-disable flex cursor-pointer rounded-lg p-2"
                            onClick={() => onSelect(item.name, index)}>
                            <span className="theme-bg-disable flex h-5 w-5 items-center justify-center rounded-full border border-border">
                              <UIcon icon="add" size="xs" className="" />
                            </span>
                            <span className="ml-4">{item.name}</span>
                          </div>
                        )}
                      </div>
                    );
                  })
                : ""}
              {tagsList.length === 0 && !isSpaceFound ? (
                <div className="flex items-center rounded">
                  <span className="tag-title ml-4">
                    <I18>Create Tag</I18>
                  </span>
                  <div
                    onClick={() => onSelect(query)}
                    className="ml-2 flex cursor-pointer rounded-lg p-2 hover:bg-background">
                    <span className="theme-bg-disable flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border border-border">
                      <UIcon icon="add" size="xs" className="" />
                    </span>
                    <span className="tag-title ml-4">{query}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="search-tag-modal-footer bg-card">
            <div
              className="mx-auto flex"
              style={{ justifyContent: "flex-end" }}>
              {isLoading ? (
                <div className="flex justify-center">
                  <Loader />
                </div>
              ) : (
                <div className="flex-end">
                  <Button
                    onClick={resetFormAndClose}
                    className="my-4 p-4"
                    label="Update"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </Modal>
  );
}

const TagsModal = TagsModalComponent;

export { TagsModal };
export { CreatePostCard };

const CreatePostCard = withToast(
  connect((s) => ({
    community: s.community,
    user: s.auth,
  }))(CreatePostCardComponent)
);

export function validatePost(post, images = [], videos = [], document = null) {
  const errors = {};
  // return errors
  // check if description empty
  if (
    !post.title &&
    !post.description &&
    !post.poll &&
    images.length === 0 &&
    videos.length === 0 &&
    !document
  ) {
    errors.description = "Description or title is required!";
  }
  if (post.title.length > 180) {
    errors.title = "Title must be less than 180 characters";
  }
  // check if is quiz
  if (post.poll) {
    errors.poll = {};
    // check if the quiz has title
    if (post.poll.isQuiz && !post.poll.title) {
      errors.poll.title = "Title is required!";
    }
    // check if atleast 1 question
    if (post.poll.questions.length === 0) {
      post.poll.questionsList = "Atleast 1 question is required!";
    } else {
      // foreach question check validations
      errors.poll.questions = {};
      post.poll.questions.forEach((question, index) => {
        // add question index object
        errors.poll.questions[index] = {};
        // check question statement is there
        if (!post.poll.questions[index].statement) {
          errors.poll.questions[index].statement =
            "Question statement is required!";
        }
        // check if question options are there
        if (post.poll.questions[index].options.length < 2) {
          errors.poll.questions[index].optionsList =
            "Atleast 2 options are required!";
        } else {
          // foreach option check validations
          errors.poll.questions[index].options = {};
          post.poll.questions[index].options.forEach((option, optionIndex) => {
            // check if option valid
            if (!option) {
              errors.poll.questions[index].options[optionIndex] =
                "Option is not valid!";
            }
          });
          // if options have not validation error, clear it
          if (Object.keys(errors.poll.questions[index].options).length === 0) {
            delete errors.poll.questions[index].options;
          }
        }
        // check if answer is one of the options, incase of quiz and question
        if (post.poll.isQuiz || post.poll.isQuestion) {
          if (
            !post.poll.questions[index].options.includes(
              post.poll.questions[index].answer
            )
          ) {
            errors.poll.questions[index].answer =
              "Please select a valid answer!";
          }
        }

        // if no errors in question, delete its key
        if (Object.keys(errors.poll.questions[index]).length === 0) {
          delete errors.poll.questions[index];
        }
      });

      // if no errors in question, delete its key
      if (Object.keys(errors.poll.questions).length === 0) {
        delete errors.poll.questions;
      }
    }
    // if no errors in poll, delete poll key
    if (Object.keys(errors.poll).length === 0) {
      delete errors.poll;
    }
  }
  // if no errors at all, delete post
  if (Object.keys(errors).length === 0) {
    return null;
  }
  // return the errors
  return errors;
}
