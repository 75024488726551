import { createContext, ReactNode, useState } from "react";
import { useAppSelector } from "../hooks/redux.hook";

export const BaseConfigContext = createContext<{
  baseColumnClass: string;
  setAppLayout: (appLayoutClass: string) => void;
  setBaseLayout: () => void;
}>(null as any);

export const APP_LAYOUT = Object.freeze({
  ONE_COLUMN: "one-column",
  TWO_COLUMN: "two-column",
  NO_TOP_PADDING: "noTopPadding",
  THREE_COLUMN_WITH_COMMUNITY_SWITCHER: "three-column-with-community-switcher",
});

export const BaseConfigProvider = ({ children }: { children: ReactNode }) => {
  const [baseColumnClass, setBaseColumnClass] = useState("");
  const communityState = useAppSelector((state) => state.community);
  const isCommunitySwitcherDisabled = communityState
    ? communityState.isCommunitySwitcherDisabled
    : undefined;

  const user = useAppSelector((state) => state.auth);

  // set three-column if community switcher is enabled else set empty
  const setBaseLayout = () => {
    user && !isCommunitySwitcherDisabled
      ? setBaseColumnClass(APP_LAYOUT.THREE_COLUMN_WITH_COMMUNITY_SWITCHER)
      : setBaseColumnClass("");
  };

  // add column class on top of base class
  const setAppLayout = (appLayoutClass: string) => {
    let primaryLayoutClass = appLayoutClass;

    if (user && !isCommunitySwitcherDisabled) {
      primaryLayoutClass +=
        " " + APP_LAYOUT.THREE_COLUMN_WITH_COMMUNITY_SWITCHER;
    }

    setBaseColumnClass(primaryLayoutClass);
  };

  return (
    <BaseConfigContext.Provider
      value={{ baseColumnClass, setAppLayout, setBaseLayout }}>
      {children}
    </BaseConfigContext.Provider>
  );
};
