import cx from "classnames";
import { format } from "date-fns";
import moment from "moment";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import TurndownService from "turndown";
import { ToastTypes } from "../../../../contexts/toastr.context";
import { useAppSelector } from "../../../../hooks/redux.hook";
import { useAppService } from "../../../../hooks/use-app-service";
import useLang from "../../../../hooks/use-lang.hook";
import { useToast } from "../../../../hooks/use-toast.hook";
import MEDIA_TYPES from "../../../../_constants/mediaType";
import { createPostDetailPageRouteFromSlug } from "../../../../_pages/post-detail.page";
import {
  CommunityService,
  MediaService,
  PostService,
} from "../../../../_service";
import { store } from "../../../../_store";
import {
  getCommunityPostTimerLimit,
  setLastPostCreatedTime,
} from "../../../../_store/_actions/post.actions";
import checkForProfanity from "../../../../_utils/restricted-words";
import I18 from "../../../atoms/i18";
import { Button } from "../../../button.component";
import { validatePost } from "../../../create-post-card.component";
import {
  ConvertToSlugString,
  CreateSlugComponent,
  limitMaxCharacterTo255,
} from "../../../create-slug-modal";
import IconButton2 from "../../../icon-button-2-component";
import { Modal } from "../../../modal.component";
import Toggle from "../../../toggle.component";
import UIcon from "../../../uicon-component";
import { CoverPicture } from "./cover-picture";
import { CreatePoll } from "./create-poll";
import { CreatePostCustomButton } from "./create-post-custom-buttons";
import CreatePostToolbar from "./create-post-toolbar";
import { ImagesPreview } from "./image-preview";
import PostAudioRecorder from "./post-audio-recorder.component";
import DocumentPreview from "./post-document-preview";
import { PostEditor } from "./post-editor.component";
import PostEmbedCode from "./post-embed-code";
import { PostTitle } from "./post-title-input";
import { VideoPreviewWithImage } from "./video-preview";
const PostCreateHeader = React.lazy(
  () => import("./post-create-header.component")
);
const tds = new TurndownService();

export const QUIZ_QUESTION_SKELETON = {
  statement: "",
  options: ["", "", "", ""],
  answer: "",
};

/**
 * Component to create post
 * @param {object} community - community object
 * @param {object} user - user object
 * @param {object} group - group object
 * @param {Array<Object>} groups - List of groups of community
 * @param {object} sectionId - sectionId object
 * @param {Function} addPost - function to add post to post list.
 * @param {Function} addToast - function to display toast.
 * @param  React.Dispatch<React.SetStateAction<boolean>>} setActive - function to toggle post create modal visibility.
 * @param {{boolean,function(boolean)}} closePrompt - Object contains getter and setter to show close prompt.
 */
export default function CreatePostComponent({
  community,
  user,
  group,
  groups,
  sectionId,
  setActive = (e) => {},
  addPost = (post) => {},
  addToast,
  isModalFullScreen,
  setIsModalFullScreen = (value) => {},
  isAdvanceSettingsOpen = false,
  setIsAdvanceSettingsOpen = (value) => {},
  closePrompt,
}) {
  const { analyticsService } = useAppService();

  // used for last post created time related states
  const { post, topics } = useAppSelector((state) => {
    return {
      post: state.post,
      topics: state.communityTopics.topics,
    };
  });

  const [profanityWords, setProfanityWords] = useState([]);
  // title and description related
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // file related
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [documents, setDocument] = useState([]);
  const [coverPicture, setCoverPicture] = useState(null);

  // embed code related
  const [isEmbedEditorVisible, setIsEmbedEditorVisible] = useState(false);
  const [embedCode, setEmbedCode] = useState("");
  const [embedCodeHeight, setEmbedCodeHeight] = useState(""); // 100px or 200px

  // Recorder related
  const [isRecorderVisible, setIsRecorderVisible] = useState(false);

  // poll related
  const [isPollVisible, setIsPollVisibleVisible] = useState(false); // poll modal visibility
  const [isQuiz, setIsQuiz] = useState(false); // If post is quiz
  const [quizTitle, setQuizTitle] = useState(""); // quiz title
  const [quizDuration, setQuizDuration] = useState(10); // quiz duration
  const [quizQuestions, setQuizQuestions] = useState([QUIZ_QUESTION_SKELETON]); // quiz questions object
  const [quizInstruction, setQuizInstruction] = useState(""); // quiz instruction

  // question related
  const [isPoll, setIsPoll] = useState(true); // If post is poll
  const [isQuestion, setIsQuestion] = useState(false); // If post is question
  const [endTime, setEndTime] = useState(moment().add(12, "h")); // end time of poll

  // custom buttons related
  const [isButtonsSectionVisible, setIsButtonsSectionVisible] = useState(false);
  const [buttons, setButtons] = useState([
    {
      label: "",
      url: "",
      position: "top",
    },
  ]);

  // Slugs related
  const [slugModalActive, setSlugModalActive] = useState(false); // slug modal visibility
  const [slug, setSlug] = useState(null); // slug string
  const [slugTitle, setSlugTitle] = useState(null); // slug title string
  const [slugDescription, setSlugDescription] = useState(null); // slug description string
  const [OGTitle, setOGTitle] = useState(null); // OG title string
  const [OGDescription, setOGDescription] = useState(null); // OG description string
  const [OGImage, setOGImage] = useState(null); // OG image string url

  // Store post create error message
  const [errors, setErrors] = useState(null); // error messages object
  const [isSavingPost, setIsSavingPost] = useState(false); // if post is saving

  // Gif related
  const [isGifPickerVisible, setIsGifPickerVisible] = useState(false); // gif picker visibility

  // Post Customization
  const [hideComment, setHideComment] = useState(false); // hide comment
  const [hideLike, setHideLike] = useState(false); // hide like
  const [hideCreator, setHideCreator] = useState(false); // hide profile
  const [customDate, setCustomDate] = useState(); // hide share
  const [hideDate, setHideDate] = useState(false); // hide share
  const [showCustomDateInput, setShowCustomDateInput] = useState(false); // hide share

  const [uploadedMedia, setUploadedMedia] = useState({
    images: [],
    videos: [],
    document: [],
    banner: [],
  });

  const [areFilesUploading, setAreFilesUploading] = useState(null);

  const [sendPushNotification, setSendPushNotification] = useState(true);
  const [sendEmailNotification, setSendEmailNotification] = useState(true);

  const [isPostCustomizationVisible, setIsPostCustomizationVisible] =
    useState(true);
  const [isNotificationsOptionsVisible, setIsNotificationsOptionsVisible] =
    useState(true);
  const [isSEOSettingsInputsVisible, setIsSEOSettingsInputsVisible] =
    useState(false);
  const [isOpenGraphInputsVisible, setIsOpenGraphInputsVisible] =
    useState(false);

  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);

  const availableTopics = topics?.filter((topic) => topic.status === "ACTIVE");

  const lang = useLang();

  // default group and tab
  const defaultSectionId =
    community?.configurables?.COMMUNITY_DEFAULT_POST_SECTION;
  const [defaultGroup, defaultTab] = useMemo(() => {
    try {
      const group = groups.find((group) =>
        group.tabs.find((t) => t.id === defaultSectionId)
      );
      const tab = group?.tabs.find((t) => t.id === defaultSectionId);
      return [group, tab];
    } catch (error) {
      return [null, null];
    }
  }, [groups, defaultSectionId]);

  // Section Related
  const [selectedGroup, setSelectedGroup] = useState(group ?? defaultGroup);
  const [selectedTab, setSelectedTab] = useState(
    (selectedGroup && selectedGroup.tabs.find((tab) => tab.id == sectionId)) ??
      defaultTab
  );

  const canPost = (() => {
    if (post.postTimerLimit) {
      const currentTimeInMilliseconds = new Date().getTime();
      const lastPostCreatedTimeInMilliseconds =
        post.lastPostCreatedTime &&
        new Date(post.lastPostCreatedTime).getTime();
      const postTimeLimitConfigurableInMilliseconds =
        post.postTimerLimit * 1000;

      const isLastPostTimeLimitSurpassed =
        currentTimeInMilliseconds - (lastPostCreatedTimeInMilliseconds || 0) >
        postTimeLimitConfigurableInMilliseconds;

      if (
        !["admin", "moderator"].includes(group?.myRole) &&
        !isLastPostTimeLimitSurpassed
      ) {
        return false;
      }
    }

    const invalidPoll = !isPollVisible // Poll| Quiz should be check if poll is visible
      ? true
      : isPoll || isQuestion || isQuiz
        ? quizTitle.length === 0 &&
          quizQuestions[0]?.statement?.length === 0 &&
          quizQuestions[0]?.answer.length === 0
        : false;

    // don't let user post if media is being uploaded to GC
    if (areFilesUploading === true) {
      return false;
    }

    return !(
      (
        (title?.length === 0 || title?.trim()?.length === 0) &&
        description?.length === 0 &&
        invalidPoll &&
        images.length === 0 &&
        videos.length === 0 &&
        documents.length === 0
      )
      // !selectedGroup && // If no group set or  selected
      // !selectedTab // If no tab is set or  selected
    );
  })();

  useEffect(() => {
    const fetchAndSetPostCreationTimeAndLimit = async () => {
      try {
        // used for last post created time related states
        if (!post.postTimerLimit) {
          store.dispatch(getCommunityPostTimerLimit());
        }
        if (!post.lastPostCreatedTime) {
          const { lastPostCreatedTime: lastPostCreatedAt } =
            await CommunityService.getLastPostCreatedTimeInCommunity(
              community.id
            );
          store.dispatch(setLastPostCreatedTime(lastPostCreatedAt));
        }
      } catch (error) {
        addToast(
          "Couldn't fetch last post created time for the user",
          "",
          ToastTypes.danger
        );
        console.error(
          "Couldn't fetch last post created time for the user: ",
          error
        );
      }
    };
    fetchAndSetPostCreationTimeAndLimit();
  }, [post.lastPostCreatedTime, post.postTimerLimit]);

  useEffect(() => {
    if (sectionId) {
      const tabModel = group.tabs.find((t) => t.id === sectionId);
      if (tabModel) {
        setSendEmailNotification(tabModel.emailNotificationOnNewPost);
        setSendPushNotification(tabModel.pushNotificationOnNewPost);
      }
    }
  }, [group, sectionId]);

  const createPost = async (event) => {
    // Check ig group is selected or not if post is being created from community home page
    if (!group && !selectedGroup) {
      setActiveModal(true);
      return;
    }
    try {
      const restrictedWords = await checkForProfanity(description + title);
      if (restrictedWords.length > 0) {
        setProfanityWords(restrictedWords);
        return;
      }
      const embedlessDescription = description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );

      const postDescription = community?.configuration
        ?.useMdEditorForCreatePostDescription
        ? description
        : tds.turndown(embedlessDescription);

      // create post
      const post = {
        title,
        description: postDescription,
        groupId: selectedGroup.id,
        embedCode,
        embedCodeHeight,
        tabId: selectedTab?.id ?? sectionId,
        sendEmailNotification,
        sendPushNotification,
        topics: selectedTopics.map((topic) => topic.id),
        preferences: {
          hideComment: hideComment,
          hideLikes: hideLike,
          hideCreator: hideCreator,
          hideDate: hideDate,
        },
        createdAt: !customDate ? null : customDate,
        meta: {
          slug: ConvertToSlugString(
            slug === null ? ConvertToSlugString(title) : slug
          ),
          title: limitMaxCharacterTo255(slugTitle === null ? title : slugTitle),
          description: limitMaxCharacterTo255(
            slugDescription === null ? postDescription : slugDescription
          ),
          openGraphTitle: limitMaxCharacterTo255(
            OGTitle === null ? title : OGTitle
          ),
          openGraphDescription: limitMaxCharacterTo255(
            OGDescription === null ? postDescription : OGDescription
          ),
          openGraphImage: OGImage,
        },
      };
      if (isPollVisible) {
        if (isQuiz) {
          // check if isQuiz
          post.poll = {
            isQuiz: true,
            isQuestion: false,
            title: quizTitle,
            questions: quizQuestions,
            duration: quizDuration,
            endTime: moment().add(1, "month"),
          };
        }

        if (isPoll) {
          post.poll = {
            isQuiz: false,
            isQuestion: isQuestion,
            title: quizTitle,
            questions: quizQuestions,
            duration: quizDuration,
            endTime: endTime,
          };
        }
      }

      // add buttons to post
      if (isButtonsSectionVisible) {
        post.buttons = buttons.filter(({ label, url }) => label && url);
      }

      const mediaTypes = ["images", "videos", "banner", "document"];

      mediaTypes.forEach((type) => {
        if (uploadedMedia[type] && uploadedMedia[type].length > 0)
          post[type] = uploadedMedia[type];
      });

      const e = validatePost(post, images, videos, documents);

      setErrors(e);
      if (e) {
        console.log("errors", e);
        return;
      }

      // start loading
      setIsSavingPost(true);
      let createdPostResponse = await PostService.createPost(user, post);
      addPost(createdPostResponse.post);
      store.dispatch(setLastPostCreatedTime(new Date()));

      // mixpanel event
      analyticsService.track("create-post", {
        userType: community.myRole,
        groupName: selectedGroup.name,
        sectionName: selectedTab?.name,
        postType: isQuiz
          ? "quiz"
          : isPoll
            ? "poll"
            : documents
              ? "attachment"
              : "none",
        postUrl:
          window.location.origin + createPostDetailPageRouteFromSlug(post),
      });
      // stop loading
      setIsSavingPost(false);
      addToast("Post added successfully!");
      closeModal();
    } catch (error) {
      //
      setIsSavingPost(false);
      console.error({ error });
      if (error && error.response && error.response.data.errors) {
        if (error.response.data.errors.title) {
          setErrors({ title: error.response.data.errors.title[0] });
        }
      }
      addToast(
        "Failed to create post. Please check post content and retry again.",
        "",
        ToastTypes.danger
      );
    }
  };

  // used for uploading audio document
  const uploadDocument = async (documentToUpload) => {
    setAreFilesUploading(true);

    const res = await MediaService.uploadMedia(
      user,
      community.id,
      [documentToUpload],
      MEDIA_TYPES.DOCUMENT
    );

    // directly spreading public urls since we have to clear old document if present while uploading
    setUploadedMedia((prevUploadedMedia) => ({
      ...prevUploadedMedia,
      document: [...res.publicUrls],
    }));

    setAreFilesUploading(false);
  };

  // Display close prompt if user clicked outside the modal
  useEffect(() => {
    if (closePrompt.displayClosePrompt) {
      closeModal(true);
      closePrompt.setDisplayClosePrompt(false);
    }
  }, [closePrompt.displayClosePrompt]);

  // Reset create post component state before close
  const closeModal = (prompt = false) => {
    // if we are said to prompt and user can post, show prompt
    if (prompt && canPost) {
      if (
        !window.confirm(
          lang.trans("Are you sure you want to discard current post?")
        )
      ) {
        return;
      }
    }

    // reset the form
    setTitle("");
    setDescription("");
    setEmbedCode("");
    setEmbedCodeHeight("");
    // reset the rows
    // if (descriptionRef) descriptionRef.style.height = "auto";
    // remove quiz/poll/question
    setIsPoll(false);
    setIsQuestion(false);
    setIsQuiz(false);
    setQuizTitle("");
    setQuizQuestions([QUIZ_QUESTION_SKELETON]);
    // remove media
    setImages([]);
    setVideos([]);
    setDocument([]);
    setCoverPicture(null);
    // reset upload percentage
    setErrors(null);
    // set the form inactive
    setActive(false);
    clearSlugMeta();
  };

  function clearSlugMeta() {
    setSlug(null);
    setOGTitle(null);
    setOGDescription(null);
    setOGImage(null);
    setSlugTitle(null);
    setSlugDescription(null);
  }
  const [activeModal, setActiveModal] = React.useState(false);

  return (
    <div>
      {!isAdvanceSettingsOpen ? (
        <div>
          <div
            className={`CreatePostCard flex flex-col rounded-[20px] bg-card p-4 ${
              isModalFullScreen && "min-h-[100vh]"
            }`}>
            <Suspense fallback={<div className="h-[50.5px] w-full"></div>}>
              <PostCreateHeader
                setActive={setActive}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                groups={groups}
                isModalFullScreen={isModalFullScreen}
                setIsModalFullScreen={setIsModalFullScreen}
                setIsAdvanceSettingsOpen={setIsAdvanceSettingsOpen}
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                areFilesUploading={areFilesUploading}
              />
            </Suspense>
            {/* BODY */}
            <div
              className={`flex flex-col px-4 lg:mx-auto ${
                isModalFullScreen ? "lg:min-w-[50vw] lg:max-w-[50vw]" : "w-full"
              }`}>
              <CoverPicture
                user={user}
                community={community}
                coverPicture={coverPicture}
                setCoverPicture={setCoverPicture}
                setAreFilesUploading={setAreFilesUploading}
                setUploadedMedia={setUploadedMedia}
                uploadedMedia={uploadedMedia}
              />
              <PostTitle
                title={title}
                setTitle={setTitle}
                description={description}
                errors={errors}
                setErrors={setErrors}
              />
              <ImagesPreview
                images={images}
                setImages={setImages}
                uploadedMedia={uploadedMedia}
                setUploadedMedia={setUploadedMedia}
              />
              <VideoPreviewWithImage
                videos={videos}
                setVideos={setVideos}
                setUploadedMedia={setUploadedMedia}
                areFilesUploading={areFilesUploading}
              />

              <div className="mb-3 border-b border-border" />

              <PostEditor
                description={description}
                setDescription={setDescription}
                user={user}
                community={community}
                isModalFullScreen={isModalFullScreen}
              />

              <div className="flex h-fit w-full flex-wrap items-center p-0.5">
                {selectedTopics?.map((topic) => {
                  return (
                    <div
                      className="mx-1 my-1 flex justify-center gap-2 rounded-2xl px-4 py-0.5 hover:cursor-pointer"
                      style={{
                        backgroundColor: topic.bgColor,
                        color: topic.textColor,
                      }}>
                      <span>{topic.name}</span>
                      <span
                        onClick={() => {
                          setSelectedTopics((prevSelectedTopics) => {
                            if (prevSelectedTopics.includes(topic)) {
                              return prevSelectedTopics.filter(
                                (t) => t !== topic
                              );
                            }
                          });
                        }}>
                        <UIcon icon="cross" size="xxs" className="mt-0.5" />
                      </span>
                    </div>
                  );
                })}
                {availableTopics?.length > 0 && (
                  <h3
                    className="my-1.5 flex w-fit min-w-28 items-center rounded bg-accent px-1.5 py-0.5 text-sm hover:cursor-pointer"
                    onClick={() => {
                      setIsTopicModalOpen(true);
                    }}>
                    <span className="text-xs font-semibold text-secondary-foreground">
                      Select Topic
                    </span>
                    <UIcon
                      icon="caret-down"
                      size="text-sm"
                      className="ml-1 mt-0.5"
                    />
                    <SelectTopicModal
                      active={isTopicModalOpen}
                      setActive={setIsTopicModalOpen}
                      availableTopics={availableTopics}
                      selectedTopics={selectedTopics}
                      setSelectedTopics={setSelectedTopics}
                      isPostCreateSelectTopic={true}
                    />
                  </h3>
                )}
              </div>

              <DocumentPreview
                documents={documents}
                setDocument={setDocument}
                setUploadedMedia={setUploadedMedia}
                areFilesUploading={areFilesUploading}
              />

              <CreatePoll
                isPollVisible={isPollVisible}
                errors={errors}
                isQuestion={isQuestion}
                setIsQuestion={setIsQuestion}
                isQuiz={isQuiz}
                setIsQuiz={(e) => {
                  setIsQuiz(true);
                  setIsPoll(false);
                }}
                isPoll={isPoll}
                setIsPoll={setIsPoll}
                quizTitle={quizTitle}
                setQuizTitle={setQuizTitle}
                quizDuration={quizDuration}
                setQuizDuration={setQuizDuration}
                quizInstruction={quizInstruction}
                setQuizInstruction={setQuizInstruction}
                quizQuestions={quizQuestions}
                setQuizQuestions={setQuizQuestions}
                endTime={endTime}
                setEndTime={setEndTime}
              />
              <PostEmbedCode
                isEmbedEditorVisible={isEmbedEditorVisible}
                embedCode={embedCode}
                setEmbedCode={setEmbedCode}
                embedCodeHeight={embedCodeHeight}
                setEmbedCodeHeight={setEmbedCodeHeight}
                isVisible={
                  !(
                    !selectedGroup ||
                    !["admin", "moderator"].includes(selectedGroup.myRole)
                  )
                }
              />

              {/* custom buttons */}
              {isButtonsSectionVisible ? (
                <CreatePostCustomButton
                  buttons={buttons}
                  setButtons={setButtons}
                />
              ) : (
                <></>
              )}
              <PostAudioRecorder
                isRecorderVisible={isRecorderVisible}
                setIsRecorderVisible={setIsRecorderVisible}
                setRecording={(recording) => {
                  uploadDocument(recording);
                  setDocument([recording]);
                }}
              />
            </div>
            <PostTimer
              lastPostCreatedTime={post.lastPostCreatedTime}
              postTimeLimitConfigurable={post.postTimerLimit}
            />
            <CreatePostToolbar
              community={community}
              user={user}
              images={images}
              setImages={setImages}
              videos={videos}
              setVideos={setVideos}
              documents={documents}
              setDocument={setDocument}
              isEmbedEditorVisible={isEmbedEditorVisible}
              setIsEmbedEditorVisible={
                !(
                  !selectedGroup ||
                  !["admin", "moderator"].includes(selectedGroup.myRole)
                )
                  ? setIsEmbedEditorVisible
                  : null
              }
              isModalFullScreen={isModalFullScreen}
              setAreFilesUploading={setAreFilesUploading}
              isPollVisible={isPollVisible}
              setIsPollVisibleVisible={(val) => {
                // Reset poll data if poll is not visible
                if (!val) {
                  setQuizTitle("");
                  setQuizQuestions([QUIZ_QUESTION_SKELETON]);
                }
                setIsPollVisibleVisible(val);
              }}
              isGifPickerVisible={isGifPickerVisible}
              setIsGifPickerVisible={setIsGifPickerVisible}
              isButtonsSectionVisible={isButtonsSectionVisible}
              setIsButtonsSectionVisible={setIsButtonsSectionVisible}
              isRecorderVisible={isRecorderVisible}
              setIsRecorderVisible={setIsRecorderVisible}
              uploadedMedia={uploadedMedia}
              setUploadedMedia={setUploadedMedia}
              selectedTopics={selectedTopics}
              addToast={addToast}
              disableSubmit={!canPost}
              isSavingPost={isSavingPost}
              onSubmitPost={createPost}
            />
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-col bg-card p-4 ${
            isModalFullScreen && "mx-auto h-[100vw] max-w-[50%]"
          }`}>
          {/* HEADER */}
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center bg-card p-4">
              <IconButton2
                icon="arrow-left"
                solid
                className="mt-0.5"
                size="xs"
                onClick={() => {
                  setIsAdvanceSettingsOpen(false);
                }}
              />
              <h3 className="ml-2.5 text-base font-bold">
                <I18>Settings</I18>
              </h3>
            </div>
            <Button
              onClick={() => {
                setIsAdvanceSettingsOpen(false);
              }}
              label="Done"
              className="h-10 w-40 text-sm font-semibold"
              large
            />
          </div>

          <div className="flex flex-col px-5 py-5">
            <span
              className="flex items-center space-x-2 text-xs font-bold hover:cursor-pointer"
              onClick={() => {
                setIsPostCustomizationVisible(!isPostCustomizationVisible);
              }}>
              <I18>POST CUSTOMIZATION</I18>
              <span>
                <UIcon icon="caret-down" size="xs" className="ml-1" />
              </span>
            </span>
            {isPostCustomizationVisible && (
              <div className="my-1 mb-5 flex flex-col space-y-3 rounded bg-card">
                <div className="flex flex-col space-y-4">
                  <div
                    className="mt-4 flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setHideLike(!hideLike);
                    }}>
                    <Toggle
                      selectedOption={hideLike}
                      setSelectedOption={setHideLike}
                    />
                    <I18>Hide Likes from the post</I18>
                  </div>

                  <div
                    className="mt-4 flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setHideComment(!hideComment);
                    }}>
                    <Toggle
                      selectedOption={hideComment}
                      setSelectedOption={setHideComment}
                    />
                    <I18>Hide Comment from the post</I18>
                  </div>

                  <div
                    className="mt-4 flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setHideCreator(!hideCreator);
                    }}>
                    <Toggle
                      selectedOption={hideCreator}
                      setSelectedOption={setHideCreator}
                    />
                    <I18>Hide profile detail from the post</I18>
                  </div>

                  <div
                    className="mt-4 flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      if (!hideCreator) {
                        setHideDate(!hideDate);
                      }
                    }}>
                    <Toggle
                      selectedOption={hideDate}
                      setSelectedOption={setHideDate}
                      disabled={hideCreator}
                    />
                    <span
                      className={cx("", {
                        "text-secondary-foreground/70": hideCreator,
                      })}>
                      <I18>Hide date & time from the post</I18>
                    </span>
                  </div>

                  <div
                    className="mt-4 flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setShowCustomDateInput(!showCustomDateInput);
                    }}>
                    <Toggle
                      selectedOption={showCustomDateInput}
                      setSelectedOption={setShowCustomDateInput}
                    />
                    <span>
                      <I18>Custom date for post</I18>
                    </span>
                  </div>
                  {showCustomDateInput && (
                    <div className="flex w-52 cursor-pointer items-center rounded border border-border bg-background bg-card px-2 py-2 outline-none">
                      <DatePicker
                        className="w-24 outline-none"
                        selected={customDate}
                        maxDate={new Date()}
                        onChange={(date) => {
                          //@ts-ignore
                          setCustomDate(new Date(date));
                        }}
                        shouldCloseOnSelect={true}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        placeholderText="Select date"
                        dateFormat="dd - MM - yyyy"
                      />
                      <div className="mr-1 h-4 w-1 border-r border-border" />
                      <div className="select-none rounded">
                        <div className="text-center text-secondary-foreground/85">
                          {customDate && format(customDate, " h:mm a")}
                          {!customDate && (
                            <h4 className="p-0 text-secondary-foreground/70">
                              <I18>time</I18>
                            </h4>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Notify users */}
            <div className="mt-4">
              <span
                className="flex items-center space-x-2 text-xs font-bold hover:cursor-pointer"
                onClick={() => {
                  setIsNotificationsOptionsVisible(
                    !isNotificationsOptionsVisible
                  );
                }}>
                <I18>NOTIFICATIONS</I18>
                <span>
                  <UIcon icon="caret-down" size="xs" className="ml-1" />
                </span>
              </span>
              {isNotificationsOptionsVisible && (
                <div className="mt-4">
                  {setSendEmailNotification && (
                    <div
                      className="mt-4 flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        setSendEmailNotification(!sendEmailNotification);
                      }}>
                      <Toggle
                        selectedOption={sendEmailNotification}
                        setSelectedOption={setSendEmailNotification}
                      />
                      <I18>Send Email Notification</I18>
                    </div>
                  )}

                  {setSendPushNotification && (
                    <div
                      className="mt-4 flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        setSendPushNotification(!sendPushNotification);
                      }}>
                      <Toggle
                        selectedOption={sendPushNotification}
                        setSelectedOption={setSendPushNotification}
                      />
                      <I18>Send Push Notification</I18>
                    </div>
                  )}
                </div>
              )}
            </div>

            <CreateSlugComponent
              community={community}
              active={slugModalActive}
              setActive={setSlugModalActive}
              slug={slug === null ? ConvertToSlugString(title) : slug}
              slugPrefix="p"
              //@ts-ignore
              setSlug={setSlug}
              title={slugTitle === null ? title : slugTitle}
              //@ts-ignore
              setTitle={setSlugTitle}
              description={
                slugDescription === null
                  ? tds.turndown(description)
                  : slugDescription
              }
              //@ts-ignore
              setDescription={setSlugDescription}
              OGTitle={OGTitle === null ? title : OGTitle}
              //@ts-ignore
              setOGTitle={setOGTitle}
              OGDescription={
                OGDescription === null
                  ? tds.turndown(description)
                  : OGDescription
              }
              //@ts-ignore
              setOGDescription={setOGDescription}
              //@ts-ignore
              OGImage={OGImage}
              //@ts-ignore
              setOGImage={setOGImage}
              OgImageSubtitle={
                "By default it will take up the image uploaded with the post"
              }
              isSEOSettingsInputsVisible={isSEOSettingsInputsVisible}
              setIsSEOSettingsInputsVisible={setIsSEOSettingsInputsVisible}
              isOpenGraphInputsVisible={isOpenGraphInputsVisible}
              setIsOpenGraphInputsVisible={setIsOpenGraphInputsVisible}
              clearSlugMeta={clearSlugMeta}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export function SelectTopicModal({
  active,
  setActive,
  availableTopics,
  selectedTopics,
  setSelectedTopics = null,
  isPostCreateSelectTopic = false,
  setSelectedFilteredTopic,
}) {
  const { addToast } = useToast();

  const toggleTopicSelection = (topic) => {
    setSelectedTopics((prevSelectedTopics) => {
      if (prevSelectedTopics.includes(topic)) {
        // Remove the topic from the list
        return prevSelectedTopics?.filter((t) => t !== topic);
      } else {
        // Add the topic to the list
        if (selectedTopics.length < 3) {
          setActive(false);
          return [...prevSelectedTopics, topic];
        } else {
          addToast("You can only select up to 3 topics", "", ToastTypes.danger);
          return [...prevSelectedTopics];
        }
      }
    });
  };

  const handleFilterSelect = (topic) => {
    setSelectedFilteredTopic({
      key: topic.id,
      label: topic.name,
      icon: "",
      bgColor: topic.bgColor,
      textColor: topic.textColor,
      isCommunityTopic: true,
    });
    setActive(false);
  };

  return (
    <Modal
      active={active}
      setActive={setActive}
      overflow=""
      padding={false}
      width="300px">
      <div className="flex flex-col justify-end bg-card">
        <div className="mx-2 flex items-center justify-between border-b border-border px-4 py-2 text-sm">
          <p className="text-md font-bold">
            <I18>Select Topic</I18>{" "}
          </p>
          <IconButton2
            icon="cross"
            hoverable
            size="md"
            onClick={() => {
              setActive(false);
            }}
          />
        </div>
        <div>
          {availableTopics?.map((topic) => {
            return (
              <div className="flex flex-col justify-center bg-card align-middle">
                <div className="flex flex-row items-center">
                  <div
                    className="mx-2 my-2 w-fit justify-center rounded-2xl px-4 py-0.5 hover:cursor-pointer"
                    style={{
                      backgroundColor: topic.bgColor,
                      color: topic.textColor,
                    }}
                    onClick={() => {
                      isPostCreateSelectTopic
                        ? toggleTopicSelection(topic)
                        : handleFilterSelect(topic);
                    }}>
                    {topic.name}
                  </div>
                  {isPostCreateSelectTopic &&
                    selectedTopics?.includes(topic) && (
                      <UIcon icon="check" className="text-primary" />
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

const PostTimer = ({ lastPostCreatedTime, postTimeLimitConfigurable }) => {
  const calculateRemainingTime = () => {
    const timeElapsed =
      (new Date().getTime() - new Date(lastPostCreatedTime).getTime()) / 1000;
    return Math.floor(postTimeLimitConfigurable - timeElapsed);
  };
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  useEffect(() => {
    if (remainingTime > 0) {
      const timeoutId = setTimeout(() => {
        const updatedRemainingTime = calculateRemainingTime();
        if (updatedRemainingTime <= 0) {
          setRemainingTime(0);
          store.dispatch(setLastPostCreatedTime(null));
        } else {
          setRemainingTime(updatedRemainingTime);
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [remainingTime, lastPostCreatedTime, postTimeLimitConfigurable]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}s`;
  };

  return (
    remainingTime > 0 && (
      <div className="theme-bg-disable mx-auto flex w-full justify-center rounded-t-[20px] border-l border-r border-t transition-transform duration-300 hover:cursor-pointer md:w-1/2">
        <p className="text-sm font-normal">
          You can create next post in:
          <span className="font-semibold text-primary">
            {" "}
            {formatTime(remainingTime)}
          </span>
        </p>
      </div>
    )
  );
};
