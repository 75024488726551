import cx from "classnames";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useToast } from "../../../../hooks/use-toast.hook";
import { FormError, TextInput } from "../../../../_components";
import { ActionModal } from "../../../../_components/action-modal.component";
import TextButton from "../../../../_components/atoms/text-button";
import { ComponentDisplay } from "../../../../_components/component-switcher/component-switcher.component";
import IconMenu2, {
  ActionType,
} from "../../../../_components/icon-menu-2.component";
import ModalForm from "../../../../_components/model2";
import AlertStrip from "../../../../_components/molecule/alert-strips";
import { ListView } from "../../../../_components/molecule/listview.component";
import CircleCheck from "../../../../_components/svg-component/circle-check";
import UIcon from "../../../../_components/uicon-component";
import { CourseService } from "../../../../_service";
import { Fold } from "../../../../_utils/extensions/typescript-utils";
import Validator from "../../../../_utils/validator";
import {
  createLessonViewPageRoute,
  LessonState,
} from "../../course-view/course-view.page";
import CreateCourseSection from "../create-course-section";
/**
 * Course Section Card. Displays the section details and the list of lessons
 * @param {Object} course - Course object
 * @param {Function} setCourse - Callback function to update the course
 * @param {Object} section - Section object
 * @param {Object} community - Community object
 * @param {String} className - Additional classnames for courseSectionCard
 * @param {boolean} hasWritePermission - If the user has write permission
 * @param {Function} onSectionUpdate - Callback function to be called when the section is updated
 * @param {Function} onSectionDelete - Callback function to be called when the section is deleted
 * @param {Function} onLessonAdded - Callback function to be called when the lesson is added
 * @param {Function} onLessonDelete - Callback function to be called when the lesson is deleted
 */
export default function CourseSectionCard({
  course,
  setCourse,
  section,
  community,
  className,
  hasWritePermission,
  onSectionUpdate = (e) => {},
  onSectionDelete = (e) => {},
  onLessonAdded = (e) => {},
  onLessonDelete = (e) => {},
}) {
  const courseId = course.id;
  const [isVisibleLessons, setIsVisibleLessons] = React.useState(true);
  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = React.useState(false);
  const [isDeletingCourse, setIsDeletingCourse] = React.useState(false);

  //   Delete Lesson
  const [isDeleteLessonVisible, setIsDeleteLessonVisible] = React.useState();
  const [isDeletingLesson, setIsDeletingLesson] = React.useState(false);
  //  DELETE SECTION

  const handleDeleteLesson = () => {
    setIsDeletingLesson(true);
    CourseService.deleteLesson(
      community.id,
      courseId,
      section.id,
      isDeleteLessonVisible.id
    )
      .then(({ lesson }) => {
        addToast("Success", "Lesson deleted successfully", ToastTypes.success);
        onLessonDelete(lesson);
        setIsDeleteLessonVisible(undefined);
      })
      .catch((errors) => {
        console.error(errors);
        addToast("Error", "Unable to delete lesson", ToastTypes.danger);
      })
      .finally(() => {
        setIsDeletingLesson(false);
      });
  };

  const { addToast, ToastTypes } = useToast();

  //  DELETE SECTION
  const handleDeleteSection = () => {
    setIsDeletingCourse(true);
    CourseService.deleteSection(community.id, courseId, section.id)
      .then(({ course }) => {
        addToast("Success", "Channel deleted successfully", ToastTypes.success);
        onSectionDelete(section);
        setIsDeletingCourse(false);
      })
      .catch((errors) => {
        console.error(errors);
        addToast("Error", "Unable to delete channel", ToastTypes.danger);
      })
      .finally(() => {
        setIsDeletingCourse(false);
      });
  };

  return (
    <>
      <div className={cx("mb-2 select-none rounded", className)}>
        {/* SECTION NAME */}
        <div
          className="flex cursor-pointer place-content-between px-2"
          onClick={() => {
            setIsVisibleLessons(!isVisibleLessons);
          }}>
          <div
            className={cx("flex select-none items-center space-x-2", {
              "py-2": !hasWritePermission,
            })}>
            <UIcon
              icon={isVisibleLessons ? "caret-down" : "caret-right"}
              className="h-5"
            />
            <span className="flex items-center space-x-2 font-semibold text-secondary-foreground">
              <span className="">{section.emoji}</span>
              <span>{section.name}</span>
            </span>
          </div>

          {/* ACTION BUTTON */}
          {hasWritePermission && (
            <div className="flex items-center space-x-2">
              <IconMenu2
                actions={[
                  {
                    icon: "edit",
                    onClick: () => setIsEditVisible(true),
                    label: "Edit",
                    actionType: ActionType.default,
                  },
                  {
                    icon: "trash",
                    onClick: () => setIsDeleteVisible(true),
                    label: "Delete",
                    actionType: ActionType.alert,
                  },
                ]}
              />
            </div>
          )}
        </div>

        {/*  LESSONS */}
        <div className="flex flex-col rounded-b bg-card">
          <ComponentDisplay IsDisplay={isVisibleLessons}>
            <ListView
              items={section.lessons}
              className="space-y-1 first:mt-1 last:mb-1"
              footerElement={
                <>
                  {hasWritePermission && (
                    <div className="FooterAdd">
                      {Validator.hasValue(section.lessons) && <AddLesson />}
                    </div>
                  )}
                </>
              }
              noItemsElement={
                <>
                  <div className="mt-1 px-2 py-3">
                    {hasWritePermission ? (
                      <div className="NoItem">
                        <AddLesson />
                      </div>
                    ) : (
                      <AlertStrip
                        className="shadow-inner"
                        title="No lesson added to this channel"
                        message="Lessons will display here once added"
                      />
                    )}
                  </div>
                </>
              }
              renderItem={(lesson) => <LessonRenderItem lesson={lesson} />}
            />
          </ComponentDisplay>
        </div>
      </div>

      {/* EDIT SECTION */}
      <ModalForm
        title={"Update section"}
        className="w-full sm:w-2/3 md:w-2/4 lg:w-1/3"
        visible={isEditVisible}
        setVisible={setIsEditVisible}>
        <CreateCourseSection
          community={community}
          course={course}
          setCourse={(course) => {
            setCourse(course);
            setIsEditVisible(false);
          }}
          section={section}
          setVisible={setIsEditVisible}
        />
      </ModalForm>

      {/* DELETE SECTION */}
      <ActionModal
        header="Confirm ?"
        title={`Are you sure you want to delete ${section.name}`}
        onSubmit={() => {
          handleDeleteSection();
        }}
        isLoading={isDeletingCourse}
        active={isDeleteVisible}
        setActive={setIsDeleteVisible}
      />

      {/* DELETE LESSON */}
      <ActionModal
        header="Confirm ?"
        title={`Are you sure you want to delete ${isDeleteLessonVisible?.title}`}
        onSubmit={() => {
          handleDeleteLesson();
        }}
        isLoading={isDeletingLesson}
        active={isDeleteLessonVisible}
        setActive={setIsDeleteLessonVisible}
      />
    </>
  );

  /**
   * Component to render lesson item. This component is used in the ListView component. Contains lesson edit and delete actions.
   * @param {Object} lesson Lesson object
   */
  function LessonRenderItem({ lesson }) {
    const [isEditVisible, setIsEditVisible] = React.useState(false);
    const isPublished = lesson.state === LessonState.published;

    return (
      <div>
        {isEditVisible ? (
          <>
            <AddLesson
              lessonToUpdate={lesson}
              setIsEditVisible={setIsEditVisible}
            />
          </>
        ) : (
          <>
            <NavLink
              className={(isActive) =>
                cx("Lesson group flex flex-col place-content-between rounded", {
                  "hover:bg-background": !isActive,
                })
              }
              activeClassName="bg-primary-light "
              isActive={(match, location) => {
                const params = new URLSearchParams(location.search);
                const lessonId = params.get("lesson");
                return lessonId === lesson.id;
              }}
              to={createLessonViewPageRoute(courseId, section.id, lesson.id)}>
              <div
                className={"flex place-content-between items-center px-2 py-1"}>
                {/* Lesson Name */}
                <div className="flex flex-grow gap-2 text-secondary-foreground/85">
                  <div className="flex-none rounded-full">
                    <Fold
                      value={hasWritePermission}
                      ifPresent={(a) => (
                        // Display icons for admin
                        <UIcon icon={isPublished ? "file-check" : "file"} />
                      )}
                      ifAbsent={() => (
                        // Display checkbox for user˝
                        <>
                          {lesson.isCompleted ? (
                            <span className="text-primary">
                              <CircleCheck />
                            </span>
                          ) : (
                            <UIcon icon="circle" className="ml-1" />
                          )}
                        </>
                      )}
                    />
                  </div>
                  <span className="">{lesson.title}</span>
                </div>

                {/* Display Edit Delete button to admin */}
                <Fold
                  value={hasWritePermission}
                  ifPresent={() => (
                    <div
                      className={cx("flex flex-none", {
                        hidden: !hasWritePermission,
                      })}>
                      <TextButton
                        label="Edit"
                        className="invisible group-hover:visible"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsEditVisible(true);
                        }}
                      />
                      <TextButton
                        label="Delete"
                        className="hover:theme-bg-danger hover:theme-text-background invisible text-alert group-hover:visible"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsDeleteLessonVisible(lesson);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </NavLink>
          </>
        )}
      </div>
    );
  }

  /**
   * Add/Update lesson
   * @param {object} lessonToUpdate - lesson to update. If not provided, a new lesson will be created
   * @param {function} setIsEditVisible - function to set the visibility of the edit input
   */
  function AddLesson({ lessonToUpdate, setIsEditVisible = (_) => {} }) {
    return (
      <AddLessonComponent
        communityId={community.id}
        courseId={courseId}
        sectionId={section.id}
        lesson={lessonToUpdate}
        setIsEditVisible={setIsEditVisible}
        onLessonUpdate={(lesson) => {
          const newLessons = section.lessons.map((l) => {
            if (l.id === lesson.id) {
              return lesson;
            }
            return l;
          });
          onSectionUpdate({
            ...section,
            lessons: newLessons,
          });
        }}
        onLessonCreate={(e) => {
          onLessonAdded(e);
        }}
      />
    );
  }
}

/**
 * Create/update a new/existing lesson
 * @param {String} communityId
 * @param {String} courseId
 * @param {String} sectionId
 * @param {Object} lesson lesson is optional. If not provided, a new lesson will be created
 * @param {Function} onLessonCreate - Callback function to be called when the lesson is created
 * @param {Function} onLessonUpdate - Callback function to be called when the lesson is updated
 */
function AddLessonComponent({
  communityId,
  courseId,
  sectionId,
  lesson: lessonToUpdate,
  onLessonCreate = (_) => {},
  onLessonUpdate = (_) => {},
  setIsEditVisible = (_) => {},
}) {
  const [isAddLessonVisible, setIsAddLessonVisible] = useState(lessonToUpdate);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState(lessonToUpdate && lessonToUpdate.title);
  const { addToast, ToastTypes } = useToast();
  const ref = React.useRef(null);

  // set isAddLessonVisible to false if clicked outside
  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsAddLessonVisible(false);
        setIsEditVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  // Create/update a lesson
  const handleAddLesson = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    if (!title) {
      setErrors({ title: "Name is required" });
      return;
    }
    const payload = {
      ...lessonToUpdate,
      title: title,
      index: lessonToUpdate?.index ?? 100,
    };

    setLoading(true);
    CourseService.createLesson(
      communityId,
      courseId,
      sectionId,
      payload,
      lessonToUpdate?.id
    )
      .then(({ lesson }) => {
        setIsAddLessonVisible(false);
        if (lessonToUpdate) {
          onLessonUpdate(lesson);

          addToast("Success", "Lesson updated", ToastTypes.success);
        } else {
          onLessonCreate(lesson);

          addToast(
            "Success",
            "Lesson created successfully",
            ToastTypes.success
          );
        }
      })
      .catch(({ errors }) => {
        if (errors?.name && Array.isArray(errors.name)) {
          setErrors({ name: errors.name[0] });
        }
        addToast("Error", "Unable to create lesson", ToastTypes.danger);
        console.log(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isAddLessonVisible ? (
        <div className="w-full">
          <form
            onSubmit={handleAddLesson}
            ref={ref}
            className="flex w-full items-center space-x-2">
            <TextInput
              placeholder="Add lesson"
              defaultValue={title}
              onChange={(val) => {
                setTitle(val);
                setErrors({});
              }}
              noMargin
              required={true}
              containerClassName="border border-primary rounded flex-grow"
            />
            {/* <UIcon icon="paper-plane" className="flex-grow-0"/> */}
            <TextButton
              className="flex-grow-0"
              onClick={handleAddLesson}
              label={lessonToUpdate ? "Update" : "Add"}
              isLoading={isLoading}
            />
          </form>
          <FormError error={errors.title} />
        </div>
      ) : (
        <div
          className="hover:theme-border-subtitle-2 flex h-8 w-full cursor-pointer items-center space-x-2 rounded border border-dashed border-transparent px-2 hover:bg-background"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsAddLessonVisible(true);
          }}>
          <UIcon icon="plus" size="xs" />
          <p>Add lesson</p>
        </div>
      )}
    </>
  );
}
