import { useState } from "react";
import TextButton from "../atoms/text-button";
import UIcon from "../uicon-component";
interface CopyTextFieldProps {
  text: string;
  className?: string;
}
/**
 * @description Copy text field component
 * @param {String} text
 */
export function CopyTextField({ text, className }: CopyTextFieldProps) {
  const [isCopied, setIsCopied] = useState<boolean>();
  return (
    <div
      className={`flex items-center justify-between rounded p-2 ${className}`}>
      <span className="break-all">{text}</span>
      <TextButton
        className="flex items-center gap-[2px] space-x-2"
        // @ts-ignore
        outline={true}
        label={isCopied ? "Copied" : "copy"}
        suffix={
          <UIcon
            icon={isCopied ? "check" : "copy"}
            size={"xs"}
            className="mt-0.5"
          />
        }
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          // copy the text
          const elem = document.createElement("textarea");
          elem.value = text;
          document.body.appendChild(elem);
          elem.select();
          document.execCommand("copy");
          document.body.removeChild(elem);
          // set it to copied
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        }}
      />
    </div>
  );
}
export default CopyTextField;
