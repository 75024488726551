import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin, { useGoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { LOGIN_ROUTE, REGISTER_ROUTE } from ".";
import { Loader } from "../../_components";
import Label from "../../_components/typography/label.typography";
import UIcon from "../../_components/uicon-component";
import { facebook, google } from "../../_config";
import { mixPanel } from "../../_config/mixpanel.config";
import { AuthService } from "../../_service";

export default function AuthChooser({
  community,
  communityDetail,
  redirectSubdomain,
  redirectURL,
  isRegister,
  setUser,
  isLoading = false,
  setIsLoading = (e) => {},
  setErrorMessage = (e) => {},
  setAuthType = (e) => {},
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const actionIsGoogleLogin = urlParams.get("action") === GOOGLE_LOGIN_ACTION;
  const actionIsFacebookLogin =
    urlParams.get("action") === FACEBOOK_LOGIN_ACTION;

  // Hide Mobile login if
  // Domain consist ap.pensil.in or
  // if community has disabled it
  // For Register page
  const disableMobileLogin =
    community?.configuration.disableMobileLogin ||
    ["app.pensil.com", "app.pensil.in", "app.pensil.so"].includes(
      window.location.hostname
    ) ||
    isRegister;

  // check for silent google login
  if (actionIsGoogleLogin && redirectURL) {
    return (
      <SilentGoogleLoginButton
        setErrorMessage={setErrorMessage}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        redirect={redirectURL}
        communityDetail={communityDetail}
        redirectSubdomain={redirectSubdomain}
      />
    );
  }

  // check for silent google login
  if (actionIsFacebookLogin && redirectURL) {
    return (
      <SilentFacebookLoginButton
        setErrorMessage={setErrorMessage}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        redirect={redirectURL}
        communityDetail={communityDetail}
        redirectSubdomain={redirectSubdomain}
      />
    );
  }

  return (
    <>
      <div key="1">
        <div className="card bg-card">
          {communityDetail && communityDetail.logo ? (
            <div className="pensil-logo">
              <img src={communityDetail.logo} alt="" />
            </div>
          ) : redirectSubdomain ? (
            <></>
          ) : (
            <div className="pensil-logo">
              <img src="/assets/vectors/main-logo-square-blue.svg" alt="" />
            </div>
          )}
          {/* community name */}
          <div className="pb-4 text-center text-lg font-semibold text-secondary-foreground/85">
            <Label>Welcome to </Label>
            <Label>{community?.name ?? "Pensil"}</Label>
          </div>
          {/* if all logins are disabled, mark it */}
          {community?.configuration.disableEmailLogin &&
          community?.configuration.disableMobileLogin &&
          community?.configuration.disableGoogleLogin &&
          community?.configuration.disableFacebookLogin ? (
            <div className="text-center">
              <div className="pb-4 text-center text-lg font-semibold text-secondary-foreground/85">
                {isRegister ? "Register" : "Login"} is disabled
              </div>
              <div className="text-center text-gray-500">
                <div className="pb-4 text-center text-secondary-foreground/85">
                  Please contact your community administrator
                </div>
              </div>
            </div>
          ) : (
            <div className="pb-4 text-center text-lg font-semibold text-secondary-foreground/85">
              {isRegister ? "Register" : "Login"}
            </div>
          )}
          {/* mobile login */}
          {!disableMobileLogin && (
            <CustomLoginButton
              className="mb-3"
              icon="call-outgoing"
              label="Continue with Mobile"
              onClick={(e) => {
                setAuthType("mobile");
              }}
            />
          )}
          {/* email login */}
          {community?.configuration.disableEmailLogin ? (
            <></>
          ) : (
            <CustomLoginButton
              className="mb-3"
              icon="envelope"
              label="Continue with Email"
              onClick={(e) => {
                setAuthType("email");
              }}
            />
          )}
          {/* google login */}
          {community?.configuration.disableGoogleLogin ? (
            <></>
          ) : (
            <GoogleLoginButton
              setUser={setUser}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            />
          )}
          {/* facebook login */}
          {community?.configuration.disableFacebookLogin ? (
            <></>
          ) : (
            <FacebookLoginButton
              setUser={setUser}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
            />
          )}
          {/* login or register button */}
          {community?.configuration.disableEmailLogin &&
          community?.configuration.disableMobileLogin &&
          community?.configuration.disableGoogleLogin &&
          community?.configuration?.disableFacebookLogin ? (
            <></>
          ) : (
            <>
              {isRegister ? (
                <div className="mt-2">
                  Already have an account?{" "}
                  <Link
                    to={
                      LOGIN_ROUTE +
                      (redirectURL ? "?redirect=" + redirectURL : "")
                    }>
                    Login
                  </Link>
                </div>
              ) : (
                <div className="mt-2">
                  Don't have an account?{" "}
                  <Link
                    to={
                      REGISTER_ROUTE +
                      (redirectURL ? "?redirect=" + redirectURL : "")
                    }>
                    Register now
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
        <div className="pt-6">
          <div className="flex justify-between pb-4 text-center text-xs text-secondary-foreground/85">
            {/* By clicking “Continue with Google/Email” above, you acknowledge that you have read and understood, and agree to Pensilʼs Terms & Conditions and Privacy Policy */}
            <span>
              By clicking “Continue with Email/Start with Google/Start with
              Facebook” above, you acknowledge that you have read and
              understood, and agree to our&nbsp;
              <span>
                <a
                  href={
                    communityDetail && communityDetail.termsAndConditionsLink
                      ? communityDetail.termsAndConditionsLink
                      : "https://www.pensil.in/terms-conditions"
                  }>
                  Terms &amp; Conditions
                </a>
              </span>
              &nbsp;and&nbsp;
              <span>
                <a
                  href={
                    communityDetail && communityDetail.privacyPolicyLink
                      ? communityDetail.privacyPolicyLink
                      : "https://www.pensil.in/privacy-policy"
                  }>
                  Privacy Policy
                </a>
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export const FACEBOOK_LOGIN_ACTION = "facebook-login";

function FacebookLoginButton({
  setIsLoading = (e) => {},
  setErrorMessage = (e) => {},
  setUser = (e) => {},
}) {
  const isPensilApp = window.location.hostname.startsWith("app.pensil");

  return !isPensilApp ? (
    <a
      href={
        process.env.REACT_APP_CLIENT_URL +
        (window.location.port ? ":" + window.location.port : "") +
        "?redirect=" +
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `&action=${FACEBOOK_LOGIN_ACTION}`
      }
      className="login-with-google mb-3 rounded border-border py-2 text-secondary-foreground hover:bg-background">
      <img src="/assets/vectors/facebook-logo.svg" width="20px" alt="" />
      <span className="ml-3">Start with Facebook</span>
    </a>
  ) : (
    <LoginWithFacebook
      setErrorMessage={setErrorMessage}
      setIsLoading={setIsLoading}
      setUser={setUser}
    />
  );
}

export const GOOGLE_LOGIN_ACTION = "google-login";

function GoogleLoginButton({
  setIsLoading = (e) => {},
  setErrorMessage = (e) => {},
  setUser = (e) => {},
}) {
  const isPensilApp = window.location.hostname.startsWith("app.pensil");

  return !isPensilApp ? (
    <a
      href={
        process.env.REACT_APP_CLIENT_URL +
        (window.location.port ? ":" + window.location.port : "") +
        "?redirect=" +
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `&action=${GOOGLE_LOGIN_ACTION}`
      }
      className="login-with-google mb-3 rounded border-border py-2 text-secondary-foreground hover:bg-background">
      <img src="/assets/vectors/google-logo.svg" width="15px" alt="" />
      <span className="ml-3">Start with Google</span>
    </a>
  ) : (
    <LoginWithGoogle
      setErrorMessage={setErrorMessage}
      setIsLoading={setIsLoading}
      setUser={setUser}
    />
  );
}

export function SilentGoogleLoginButton({
  redirect = null,
  communityDetail = null,
  redirectSubdomain = null,
  isLoading = false,
  setIsLoading = (e) => {},
  setErrorMessage = (e) => {},
}) {
  const { signIn, loaded } = useGoogleLogin({
    onSuccess: (response) => {
      setIsLoading(true);
      console.log("Silent success response", { response });
      // get access token
      const { accessToken } = response;
      // login using this access token
      AuthService.googleLoginV2(accessToken)
        .then((response) => {
          // save the user
          localStorage.setItem("pensil.user", JSON.stringify(response.user));
          // mixpanel event
          mixPanel.track("login", {
            name: response.user ? response.user.name : null,
            email: response.user ? response.user.email : null,
            mobile: response.user ? response.user.mobile : null,
            googlelogin: true,
          });

          // redirect back with user token
          window.location.href = redirect + "?token=" + response.user.token;
        })
        .catch((error) => {
          console.log({ SilentError: error });
          if (error && error.response && error.response.data.message) {
            if (error.response.data.errors.mobile) {
              setErrorMessage(error.response.data.errors.mobile[0]);
            }
            if (error.response.data.errors.otp) {
              setErrorMessage(error.response.data.errors.otp[0]);
            }
          }
          setIsLoading(false);
          window.location.href = redirect + "?error=Google+Login+Failed!";
        });
    },
    onFailure: (errorResponse) => {
      console.log({
        SilentError: JSON.stringify(errorResponse, null, 2),
        errorResponse,
      });
      // redirect back with error message
      window.location.href = redirect + "?error=Google+Login+Failed!";
    },
    onAutoLoadFinished: (red) => {
      console.log("auto load finished", red);
    },
    clientId: google.clientId,
  });

  return (
    <div key="1">
      <div className="card bg-card">
        {communityDetail && communityDetail.logo ? (
          <div className="pensil-logo">
            <img src={communityDetail.logo} alt="" />
          </div>
        ) : redirectSubdomain ? (
          <></>
        ) : (
          <div className="pensil-logo">
            <img src="/assets/vectors/main-logo-square-blue.svg" alt="" />
          </div>
        )}
        <div className="pb-4 text-center text-lg font-semibold text-secondary-foreground/85">
          Login
        </div>
        <div
          onClick={signIn}
          className="login-with-google mb-3 rounded border-border py-2 hover:bg-background">
          <img src="/assets/vectors/google-logo.svg" width="25px" alt="" />
          <span className="ml-3">Click here for Google Login</span>
        </div>
        <div>
          For security reasons, you have to manually initiate google login,
          Click the button above!
        </div>
        {/* switch login */}
        <div className="mt-5">
          Want to use different method?{" "}
          <a href={redirect ? redirect : LOGIN_ROUTE}>Go back</a>
        </div>
        {isLoading ? <Loader /> : <></>}
      </div>
    </div>
  );
}

function LoginWithGoogle({
  setUser = (e) => {},
  setErrorMessage = (e) => {},
  setIsLoading = (e) => {},
}) {
  const successResponseGoogle = async (response) => {
    // get access token
    const { accessToken } = response;
    // login using this access token
    setIsLoading(true);

    try {
      const response = await AuthService.googleLoginV2(accessToken);
      // save the user
      localStorage.setItem("pensil.user", JSON.stringify(response.user));
      // mixpanel event
      mixPanel.track("login", {
        name: response.user ? response.user.name : null,
        email: response.user ? response.user.email : null,
        mobile: response.user ? response.user.mobile : null,
        googlelogin: true,
      });

      // go to homepage
      setUser(response.user);
    } catch (error) {
      console.log({ error });
      if (error && error.response && error.response.data.message) {
        if (error.response.data.errors.mobile) {
          setErrorMessage(error.response.data.errors.mobile[0]);
        }
        if (error.response.data.errors.otp) {
          setErrorMessage(error.response.data.errors.otp[0]);
        }
      }
      setIsLoading(false);
    }
  };

  const failureResponseGoogle = (errorResponse) => {
    console.log({ errorResponse });
    /* if(errorResponse.details) {
      setErrorMessage(errorResponse.details);
    } else {
      setErrorMessage(errorResponse.error);
    } */
  };

  return (
    <GoogleLogin
      clientId={google.clientId}
      render={(props) => {
        return (
          <div
            {...props}
            className="login-with-google mb-3 rounded border-border py-2 hover:bg-background">
            <img src="/assets/vectors/google-logo.svg" width="15px" alt="" />
            <span className="ml-3">Start with Google</span>
          </div>
        );
      }}
      onSuccess={successResponseGoogle}
      onFailure={failureResponseGoogle}
    />
  );
}

export function SilentFacebookLoginButton({
  redirect = null,
  communityDetail = null,
  redirectSubdomain = null,
  isLoading = false,
  setIsLoading = (e) => {},
  setErrorMessage = (e) => {},
}) {
  const successResponseFacebook = async (response) => {
    // get access token
    const { accessToken } = response;
    // login using this access token
    setIsLoading(true);

    try {
      const response = await AuthService.facebookLogin(accessToken);
      // save the user
      localStorage.setItem("pensil.user", JSON.stringify(response.user));
      // mixpanel event
      mixPanel.track("login", {
        name: response.user ? response.user.name : null,
        email: response.user ? response.user.email : null,
        mobile: response.user ? response.user.mobile : null,
        facebookLogin: true,
      });

      // redirect back with user token
      window.location.href = redirect + "?token=" + response.user.token;
    } catch (error) {
      console.log({ error });
      if (error && error.response && error.response.data.message) {
        if (error.response.data.errors.mobile) {
          setErrorMessage(error.response.data.errors.mobile[0]);
        }
        if (error.response.data.errors.otp) {
          setErrorMessage(error.response.data.errors.otp[0]);
        }
      }
      setIsLoading(false);
      window.location.href = redirect + "?error=Facebook+Login+Failed!";
    }
  };

  return (
    <div key="1">
      <div className="card bg-card">
        {communityDetail && communityDetail.logo ? (
          <div className="pensil-logo">
            <img src={communityDetail.logo} alt="" />
          </div>
        ) : redirectSubdomain ? (
          <></>
        ) : (
          <div className="pensil-logo">
            <img src="/assets/vectors/main-logo-square-blue.svg" alt="" />
          </div>
        )}
        <div className="pb-4 text-center text-lg font-semibold text-secondary-foreground/85">
          Login
        </div>
        <FacebookLogin
          appId={facebook.appId}
          fields="name,email,picture,mobile"
          render={(props) => {
            return (
              <div
                {...props}
                className="login-with-google mb-3 rounded border-border py-2 hover:bg-background">
                <img
                  src="/assets/vectors/facebook-logo.svg"
                  width="25px"
                  alt=""
                />
                <span className="ml-3">Click here for Facebook Login</span>
              </div>
            );
          }}
          callback={successResponseFacebook}
        />
        <div>
          For security reasons, you have to manually initiate google login,
          Click the button above!
        </div>
        {/* switch login */}
        <div className="mt-5">
          Want to use different method?{" "}
          <a href={redirect ? redirect : LOGIN_ROUTE}>Go back</a>
        </div>
        {isLoading ? <Loader /> : <></>}
      </div>
    </div>
  );
}

function LoginWithFacebook({
  setUser = (e) => {},
  setErrorMessage = (e) => {},
  setIsLoading = (e) => {},
}) {
  const successResponseFacebook = async (response) => {
    // get access token
    const { accessToken } = response;
    // login using this access token
    setIsLoading(true);

    try {
      const response = await AuthService.facebookLogin(accessToken);
      // save the user
      localStorage.setItem("pensil.user", JSON.stringify(response.user));
      // mixpanel event
      mixPanel.track("login", {
        name: response.user ? response.user.name : null,
        email: response.user ? response.user.email : null,
        mobile: response.user ? response.user.mobile : null,
        facebookLogin: true,
      });

      // go to homepage
      setUser(response.user);
    } catch (error) {
      console.log({ error });
      if (error && error.response && error.response.data.message) {
        if (error.response.data.errors.mobile) {
          setErrorMessage(error.response.data.errors.mobile[0]);
        }
        if (error.response.data.errors.otp) {
          setErrorMessage(error.response.data.errors.otp[0]);
        }
      }
      setIsLoading(false);
    }
  };

  const failureResponseGoogle = (errorResponse) => {
    console.log({ errorResponse });
    /* if(errorResponse.details) {
      setErrorMessage(errorResponse.details);
    } else {
      setErrorMessage(errorResponse.error);
    } */
  };

  return (
    <FacebookLogin
      appId={facebook.appId}
      fields="name,email,picture,mobile"
      render={(props) => {
        return (
          <div
            {...props}
            className="login-with-google mb-3 rounded border-border py-2 hover:bg-background">
            <img src="/assets/vectors/facebook-logo.svg" width="20px" alt="" />
            <span className="ml-3">Start with Facebook</span>
          </div>
        );
      }}
      callback={successResponseFacebook}
    />
  );
}

export function CustomLoginButton({
  label = "Click Here",
  icon = null,
  className = "",
  onClick = (e) => {},
  ...props
}) {
  return (
    <div
      {...props}
      className={
        "flex cursor-pointer place-content-center rounded border border-border py-2 hover:bg-background " +
        className
      }
      onClick={onClick}>
      {icon ? (
        // <img src={icon} width="25px" alt="" />
        <UIcon icon={icon} />
      ) : (
        <></>
      )}

      <span className="ml-3">{label}</span>
    </div>
  );
}
