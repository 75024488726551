import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  all: base + "group",
  my: base + "group/my",
  byMe: base + "group/by-me",
  getGroupFromSlug: (communityId, slug) =>
    base + "community" + "/" + communityId + "/groups/" + slug,
  updateGroup: (groupId) => base + "group/" + groupId,
  deleteGroup: (groupId) => base + "group/" + groupId,
  uploadBanner: (groupId) => base + "group/" + groupId + "/banner",
  addTab: (groupId) => base + "group/" + groupId + "/tab",
  editTab: (groupId, tabId) => base + "group/" + groupId + "/tab/" + tabId,
  deleteTab: (groupId, tabId) => base + "group/" + groupId + "/tab/" + tabId,
  goLive: (groupId) => base + "dyte/start-meeting/" + groupId,
  members: (groupId, selectedTabId = null) =>
    base +
    "group/" +
    groupId +
    "/members" +
    (selectedTabId ? "?tabId=" + selectedTabId : ""),
  membersPaginated: (
    groupId,
    selectedTabId = null,
    page = 1,
    limit = 20,
    query = ""
  ) =>
    base +
    "group/" +
    groupId +
    "/members/paginated" +
    (selectedTabId ? "?tabId=" + selectedTabId + "&" : "?") +
    "page=" +
    page +
    "&limit=" +
    limit +
    "&query=" +
    query,
  closedSectionMembers: (groupId, selectedTabId = null) =>
    base + "group/" + groupId + "/closed-tab/" + selectedTabId + "/members",
  closedSectionMembersPaginated: (
    groupId,
    selectedTabId = null,
    page = 1,
    limit = 20,
    query = ""
  ) =>
    base +
    "group/" +
    groupId +
    "/closed-tab/" +
    selectedTabId +
    "/members/paginated?page=" +
    page +
    "&limit=" +
    limit +
    "&query=" +
    query,
  personalGroup: base + "group/personal",
  getLeaderboardV2Paginated: (groupId, page, limit) =>
    base +
    "leaderboard/group/" +
    groupId +
    `/paginated/v2?page=${page}` +
    `&limit=${limit}`,
  updateRole: (groupId, userId) =>
    base + "group/" + groupId + "/members/" + userId + "/update-role",
  removeUser: (groupId, userId) =>
    base + "group/" + groupId + "/remove-user/" + userId,
  posts: (groupId) => base + "group/" + groupId + "/posts",
  sectionPostsPaginated: (groupId, sectionId, page = 1, sort) =>
    base + "group/" + groupId + "/section/" + sectionId + "/posts?page=" + page + "&sort=" + sort,
  sectionPostsPinned: (groupId, sectionId) =>
    base + "group/" + groupId + "/section/" + sectionId + "/posts/pinned",
  sectionMessagesPaginated: (groupId, sectionId, page = 1) =>
    base +
    "group/" +
    groupId +
    "/section/" +
    sectionId +
    "/messages?page=" +
    page,
  sectionMessagesTimestampPaginated: (groupId, sectionId, lastId) =>
    base +
    "group/" +
    groupId +
    "/section/" +
    sectionId +
    "/messages/timestamped?lastId=" +
    lastId,
  trendingPosts: (groupId) => base + "group/" + groupId + "/trending-posts",
  acceptInvitation: (groupId) => base + "group/" + groupId + "/accept-invite",
  join: (groupId) => base + "group/" + groupId + "/join",
  leave: (groupId) => base + "group/" + groupId + "/leave",
  acceptJoinRequest: (groupId, userId) =>
    base + "group/" + groupId + "/join-request/" + userId + "/accept",
  rejectJoinRequest: (groupId, userId) =>
    base + "group/" + groupId + "/join-request/" + userId + "/reject",
  verifyCouponCode: (groupId, code) =>
    base + "payment/group/" + groupId + "/verify-coupon/" + code,
  createOrder: (groupId) => base + "payment/group/" + groupId + "/create-order",
  verifyPayment: (groupId, paymentId) =>
    base + "payment/group/" + groupId + "/verify-payment/" + paymentId,
  // closed section related
  joinClosedSection: (groupId, sectionId) =>
    base + "group/" + groupId + "/section/" + sectionId + "/join",
  acceptJoinClosedSectionRequest: (groupId, sectionId, userId) =>
    base +
    "group/" +
    groupId +
    "/section/" +
    sectionId +
    "/join-request/" +
    userId +
    "/accept",
  rejectJoinClosedSectionRequest: (groupId, sectionId, userId) =>
    base +
    "group/" +
    groupId +
    "/section/" +
    sectionId +
    "/join-request/" +
    userId +
    "/reject",
};
