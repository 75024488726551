import cx from "classnames";
import liveIcon from "../_assets/vectors/live-icon-2.svg";
import liveIconWhite from "../_assets/vectors/live-white-icon-2.svg";
import I18 from "./atoms/i18";

interface Props {
  icon: string;
  onClick?: () => void;
  className?: string;
  iconClass?: string;
  ended?: Boolean;
}
function LiveButton({ onClick, className, ended }: Props) {
  return (
    <div
      onClick={(e) => {
        if (onClick != null && !ended) {
          onClick();
        }
      }}
      className={cx(
        `flex h-6 select-none place-content-center items-center space-x-1 rounded px-2 text-xs ${className}`,
        {
          "theme-bg-disable cursor-not-allowed text-secondary-foreground/70":
            ended,
          "theme-bg-danger cursor-pointer text-white hover:shadow": !ended,
        }
      )}>
      <span>
        <img
          src={!ended ? liveIconWhite : liveIcon}
          className={"h-full w-full"}
          alt=""
        />
      </span>
      <span>
        <I18>Live</I18>
      </span>
    </div>
  );
}
export default LiveButton;
