const POST_TYPE = {
  POST: "generic",
  GROUP_CHAT: "realtime",
};

const SORTING_ORDER = {
  LATEST_FIRST: "latest",
  OLDEST_FIRST: "oldest",
};

const POSTS_VIEW = {
  LIST: "list",
  GRID: "grid",
  COMPACT: "compact",
};

const USER_CATEGORIES = {
  ALL: "all",
  ANYONE: "anyone",
  ADMIN_ONLY: "admin",
  NONE: "none",
};

const ENABLED_NOTIFICATIONS = {
  EMAIL: "email",
  MOBILE_PUSH: "mobile_push",
};

export default {
  POST_TYPE,
  SORTING_ORDER,
  POSTS_VIEW,
  USER_CATEGORIES,
  ENABLED_NOTIFICATIONS,
};
