import cx from "classnames";
import { useEffect, useState } from "react";
import { useAppSelector } from "../hooks/redux.hook";
import { history } from "../_config";
import { NotificationLevel } from "../_pages/community-settings/notification-centre.page";
import JoinButton from "../_pages/group/group-join-button.component";
import { createGroupSectionPageUrl } from "../_pages/group/group.page";
import { GroupService } from "../_service";
import { RealtimeSectionPinnedMessageService } from "../_service/realtime-section-pinned-message.service";
import { store } from "../_store";
import { setCurrentSectionPosts } from "../_store/sections.store";
import { updateNotificationPreferenceForSection } from "../_store/_actions/notification-centre.actions";
import { setPersonalGroup } from "../_store/_actions/personal-group.actions";
import { ActionModal } from "./action-modal.component";
import { Button } from "./button.component";
import CreateSectionSidebarModal from "./group/create-section-modal-v2.component";
import ClosedSectionRequestButton from "./group/section-detail/component/closed-section-request-button.component";
import { isUserAllowedToPostInSection } from "./group/section-detail/component/section-info.component";
import IconMenu2, { ActionType } from "./icon-menu-2.component";
import { Loader } from "./loader.component";
import CreatePost from "./post/create";
import PinnedMessageComponent from "./realtime/realtime-pinned-section.component";
import RightSideModal from "./right-side-modal.component";
import { ShareTextModal } from "./share-text-modal.component";
import UIcon from "./uicon-component";

interface Props {
  closedSectionMembersCount: any;
  group: any;
  setGroup: (group: any) => void;
  addToast: (e: any) => {};
  setShowShareModal: (modal: any) => void;
  updateActiveGroup: any;
  showShareModal: any;
  joinedMembers: any;
}

const GroupHeaderComponent = ({
  closedSectionMembersCount,
  group,
  setGroup,
  addToast,
  setShowShareModal,
  updateActiveGroup,
  showShareModal,
  joinedMembers,
}: Props) => {
  // Stores the active tab notification preference
  const [notificationLevel, setNotificationLevel] = useState<any>(
    NotificationLevel.NONE
  );
  const [isEditingTab, setIsEditingTab] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pinnedSectionMessages, setPinnedSectionMessages] = useState([]);

  const {
    community,
    user,
    section,
    personalGroup,
    notificationCentre,
    activeTabModel,
    socket,
  } = useAppSelector((state) => {
    return {
      user: state.auth,
      community: state.community,
      socket: state.socket,
      section: state.sections.sections,
      personalGroup: state.personalGroup,
      notificationCentre: state.notificationCentre,
      activeTabModel: state.sections.activeSectionId
        ? state.sections.sections[state.sections.activeSectionId]
        : null,
    };
  });

  //get pinned section messages
  useEffect(() => {
    const getPinnedMessages = async () => {
      try {
        const result =
          await RealtimeSectionPinnedMessageService.getPinnedSectionMessages(
            group?.id,
            activeTabModel?.id
          );
        setPinnedSectionMessages(result);
      } catch (error) {
        console.error("Error fetching pinned message:", error);
      }
    };

    getPinnedMessages();
  }, [group?.id, activeTabModel?.id]);

  // Realtime pinned section messages socket implementation
  useEffect(() => {
    //pins the message in realtime
    const pinMessage = (data: any) => {
      setPinnedSectionMessages((prevState) => {
        const updatedPinnedMessages = [
          data.message,
          //@ts-ignore
          ...prevState.pinnedMessages,
        ];
        return { ...prevState, pinnedMessages: updatedPinnedMessages };
      });
    };

    //unpins the message in realtime
    const unPinMessage = (data: any) => {
      setPinnedSectionMessages((prevState) => {
        //@ts-ignore
        const updatedPinnedMessages = prevState.pinnedMessages.filter(
          (message: { id: any }) => message.id !== data.message.id
        );
        return { ...prevState, pinnedMessages: updatedPinnedMessages };
      });
    };

    socket.on(`section:${activeTabModel.id}:message-pinned`, pinMessage);
    socket.on(`section:${activeTabModel.id}:message-unpinned`, unPinMessage);

    // Clean up event listeners when the component unmounts
    return () => {
      socket.off(`section:${activeTabModel.id}:message-pinned`, pinMessage);
      socket.off(`section:${activeTabModel.id}:message-unpinned`, unPinMessage);
    };
  }, [socket, activeTabModel.id]);
  //@ts-ignore
  const NewPinnedMessages = pinnedSectionMessages?.pinnedMessages;

  let isVisibleToNormalUser =
    !(
      group.joinStatus === "joined" &&
      activeTabModel.isClosed &&
      !activeTabModel.isJoined
    ) || ["admin", "moderator"].includes(group.myRole);

  // Initialize the notification preference for selected tab
  useEffect(() => {
    if (notificationCentre.preferences && activeTabModel) {
      const preferences = notificationCentre.preferences;
      if (preferences.tabs && preferences.tabs.length > 0) {
        // Check if the user has a preference for this section
        const tab = preferences.tabs.find(
          (tab: { tabId: any }) => tab.tabId === activeTabModel.id
        );
        if (tab) {
          setNotificationLevel(tab.notificationLevel);
        }
        // If not, set the default preference to NotificationLevel.ALL
        else {
          setNotificationLevel(NotificationLevel.ALL);
        }
      } else {
        setNotificationLevel(NotificationLevel.ALL);
      }
    }
  }, [notificationCentre]);

  const onSubmitDelete = (e: any) => {
    // delete section
    setIsLoading(true);
    GroupService.deleteGroupTab(
      user,
      personalGroup && personalGroup.id === group.id
        ? personalGroup.id
        : group.id,
      activeTabModel.id
    )
      .then(({ group }) => {
        // update the group
        setIsLoading(false);
        // resetFormAndClose();
        // addToast("Channel deleted!", "", "success");
        setIsDeleteModalVisible(false);
        if (personalGroup && personalGroup.id === group.id) {
          setPersonalGroup(group);
          history.push(`/personal-space?tab=${personalGroup.tabs[0].id}`);
        } else {
          updateActiveGroup(group);
        }
      })
      .catch((error) => {
        console.log({ error });
        // addToast("Unable to delete channel!", "", "danger");
        setIsLoading(false);
      });
  };
  const isGeneric = activeTabModel.sectionType !== "realtime";

  // create a check if user can post in group
  let isUserAllowedToPost = isUserAllowedToPostInSection(group, activeTabModel);
  return (
    <>
      {/* section title */}
      <div className={`flex ${isGeneric ? "flex-col" : "flex-row"} items-stretch justify-between sm:flex-row`}>
        <div className={`mt-1.5 flex items-center ${isGeneric ? "gap-4" : "gap-2"}`}>
          <div className="word-breaker text-base font-semibold text-secondary-foreground">
            {activeTabModel.emoji + " " + activeTabModel.name}
          </div>
          <div>
            {isGeneric && (
              <div className="text-xxs rounded bg-accent px-2.5 py-0.5 text-secondary-foreground">
                {activeTabModel.isPrivate ? (
                  <div className="flex items-center gap-1.5">
                  <UIcon icon="eye-crossed" size="xxs" />
                  <span>Private</span>
                </div>
                ) : activeTabModel.isClosed ? (
                  <div className="flex items-center gap-1.5">
                  <UIcon icon="lock" size="xxs" />
                  <span>Closed</span>
                  </div>
                ) : (
                  <div className="flex items-center gap-1.5">
                    <UIcon icon="unlock" size="xxs" />
                    <span>Open</span>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div>
            {joinedMembers.length > 0 && joinedMembers.map((member: any, index: number) => (
              <RImage
              src={member.picture}
              key={index}
              className="w-6 h-6 absolute border rounded-full border-border"
              style={{
                left: index * 10 + "px",
              }}
            />
            ))}
          </div> */}
          <div className="rounded bg-accent px-2.5 py-0.5 text-secondary-foreground">
            <div className="text-xxs flex items-center gap-1.5">
              <UIcon icon="user" size="xxs" />
              <span>{closedSectionMembersCount ?? group.userCount}</span>
            </div>
          </div>
          {isVisibleToNormalUser &&
            group.joinStatus === "joined" &&
            !isGeneric && (
              <PinnedMessageComponent
                community={community}
                pinnedMessages={NewPinnedMessages}
                activeTabId={""}
                socket={undefined}
              />
            )}
        </div>

        <div className={`flex  items-center gap-1 md:gap-0`}>
          
          {/* join / invite button, on group level only */}
          <div className="order-last md:order-none">
            {group.joinStatus === "joined" ? (
              <>
                {activeTabModel.isClosed && !activeTabModel.isJoined ? (
                  activeTabModel.status === "requested" ? (
                    <Button
                      onClick={() => {
                        // make the request access to the closed section
                      }}
                      label="Requested"
                      disabled
                    />
                  ) : (
                    <ClosedSectionRequestButton
                      // @ts-ignore
                      activeTabModel={activeTabModel as any}
                      user={user}
                      group={group}
                      updateActiveGroup={setGroup}
                      setGroup={setGroup}
                      addToast={addToast}
                    />
                  )
                ) : (
                  // Display the section notification preference button

                  <IconMenu2
                    icon="bell"
                    solid={notificationLevel !== NotificationLevel.NONE}
                    className={cx("", {
                      "text-primary":
                        notificationLevel !== NotificationLevel.NONE,
                    })}
                    actions={[
                      // For All notifications
                      {
                        label: "All posts",
                        // icon: "view-grid",
                        onClick: () => {
                          let tab = {
                            tabId: activeTabModel.id,
                            groupId: group.id,
                            notificationLevel: NotificationLevel.ALL,
                          };
                          store.dispatch(
                            updateNotificationPreferenceForSection(
                              tab,
                              addToast
                            )
                          );
                        },
                        actionType:
                          notificationLevel === NotificationLevel.ALL
                            ? ActionType.primary
                            : ActionType.default,
                      },
                      // For admin posts only
                      {
                        label: "Admin posts only",
                        // icon: "view-grid",
                        onClick: () => {
                          let tab = {
                            tabId: activeTabModel.id,
                            groupId: group.id,
                            notificationLevel: NotificationLevel.ADMIN,
                          };
                          store.dispatch(
                            updateNotificationPreferenceForSection(
                              tab,
                              addToast
                            )
                          );
                        },
                        actionType:
                          notificationLevel === NotificationLevel.ADMIN
                            ? ActionType.primary
                            : ActionType.default,
                      },
                      // For no notifications
                      {
                        label: "None",
                        // icon: "view-grid",
                        onClick: () => {
                          let tab = {
                            tabId: activeTabModel.id,
                            groupId: group.id,
                            notificationLevel: NotificationLevel.NONE,
                          };
                          store.dispatch(
                            updateNotificationPreferenceForSection(
                              tab,
                              addToast
                            )
                          );
                        },
                        actionType:
                          notificationLevel === NotificationLevel.NONE
                            ? ActionType.primary
                            : ActionType.default,
                      },
                    ]}
                  />
                )}
              </>
            ) : (
              // Display join Group button if group is open and active section is not private .
              !activeTabModel.isPrivate && (
                <>
                  {
                    // Hide Join group button if group is closed and section is also closed
                    group.groupType !== "closed-free" &&
                      !activeTabModel.isClosed && (
                        <JoinButton
                          group={group}
                          setGroup={setGroup}
                          user={user}
                          sectionAuth={activeTabModel.postLevel}
                          sectionType={activeTabModel.sectionType}
                        />
                      )
                  }
                </>
              )
            )}
          </div>
          {/* 
                go live button
                - Hide for AppSumo communities
              */}
          {/* {!community.isDealRedeemed &&
          (!activeTabModel ||
            !activeTabModel.isClosed ||
            activeTabModel.isJoined) ? (
            <PremiumFeature isAvailable={communityPac.liveSession}>
              <Suspense fallback={<></>}>
                <GoLiveButton
                  groupId={group.id}
                  group={group}
                  tabId={activeTabModel.id}
                  setIsLoading={(e: any) => {}}
                  user={user}
                  community={community}
                  title={""}
                />
              </Suspense>
            </PremiumFeature>
          ) : (
            <></>
          )} */}

          {
            // show menu only if joined
            group.joinStatus === "joined" ? (
              <div className="flex order-last md:order-none">
                <IconMenu2
                  className="text-secondary-foreground"
                  dropdownClassName="w-52"
                  icon="settings"
                  actions={[
                    {
                      // show share option
                      icon: "share",
                      label: "Share",
                      onClick: () => {
                        setShowShareModal(true);
                      },
                    },
                    {
                      // for tab menu
                      icon: "edit",
                      label:
                        activeTabModel && group.myRole === "admin"
                          ? "Edit Channel"
                          : null,
                      onClick: () => {
                        setIsEditingTab(true);
                      },
                    },
                    {
                      // for section delete
                      icon: "trash",
                      label:
                        activeTabModel && group.myRole === "admin"
                          ? "Delete Channel"
                          : null,
                      onClick: () => {
                        setIsDeleteModalVisible(true);
                      },
                      actionType: "alert",
                    },
                  ]}
                />
              </div>
            ) : (
              <></>
            )
          }

          {/* create post button */}
          {isUserAllowedToPost &&
          isGeneric &&
          (!activeTabModel.isClosed || activeTabModel.isJoined) ? (
              <CreatePost
              group={group}
              sectionId={activeTabModel.id}
              addPost={(post: any) => {
                if (post && post.tab && post.tab?.id === activeTabModel?.id) {
                  store.dispatch(
                    setCurrentSectionPosts([
                      post,
                      ...section[activeTabModel.id]?.posts,
                    ])
                  );
                }
              }}
            />
          ) : (
            <></>
          )}

          {/* edit tab model */}
          {/* <CreateSectionModal
                activeTab={activeTabModel}
                active={isEditingTab}
                setActive={setIsEditingTab}
              /> */}
          <RightSideModal
            width={570}
            setActive={setIsEditingTab}
            active={isEditingTab}>
            <CreateSectionSidebarModal
              isEditingTabActive={isEditingTab}
              setIsCreateSectionModalVisible={setIsEditingTab}
              updateActiveGroup={setGroup}
              selectedTabToEdit={activeTabModel}
            />
          </RightSideModal>

          {/* Delete tab model */}
          <ActionModal
            active={isDeleteModalVisible}
            setActive={setIsDeleteModalVisible}
            onSubmit={onSubmitDelete}
            header="Delete Channel"
            title="Are you sure you want to delete the channel?"
            isLoading={isLoading}
            btnColor="red"
            children={undefined}
          />

          <ShareTextModal
            heading="Share"
            text={createGroupSectionPageUrl(group, activeTabModel)}
            moreInfo="Share this link with your members for them to join this group."
            active={showShareModal}
            setActive={setShowShareModal}
            onClose={(e: any) => {
              setShowShareModal(false);
            }}
          />
          {isLoading ? <Loader /> : <></>}
        </div>
      </div>
    </>
  );
};

export default GroupHeaderComponent;
