import { useEffect, useState } from "react";
import { useAppService } from "../../hooks/use-app-service";
import { Group } from "../../types/group/group.type";
import { User } from "../../types/user/minimal-user.type";
import { history } from "../../_config";
import { createEventDetailSlugPageRoute } from "../../_pages/event-detail.page";
import {
  eventTimeV2,
  formatEventDate,
  formatMonthAndDay,
} from "../../_pages/event-time-v2.page";
import { COMMUNITY_EVENTS_PAGE_ROUTE } from "../../_pages/events-page";
import { EventService } from "../../_service/event.service";
import I18 from "../atoms/i18";
import TextButton from "../atoms/text-button";
import { Avatar } from "../avatar.component";
import { Loader } from "../loader.component";
interface UpcomingEventWidgetProps {
  user: User;
  community: any;
  group?: Group;
  isCommunityEvent: boolean;
}

/**
 * Widget to display trending posts of community and group
 * @param {User} user current active user profile data.
 * @param {any} community community object.
 * @param {Group} group group object.
 * @param {boolean} isCommunityEvent Determine whether to widget is to display on home page or in group
 */
function UpcomingEventWidget({
  user,
  community,
  group,
  isCommunityEvent = false,
}: UpcomingEventWidgetProps) {
  const date = "";
  const [events, setEvents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  useEffect(() => {
    if (isCommunityEvent && events.length === 0) {
      getCommunityUpcomingEvents();
    } else if (!isCommunityEvent && events.length === 0) {
      getGroupUpcomingEvents();
    }
  }, [community]);

  const getCommunityUpcomingEvents = () => {
    let getCommunityUpcomingEvents: any = [];
    setIsLoading(true);
    EventService.upcomingEvents(user, community.id, date)
      .then((response) => {
        if (response.events) {
          response.events.map((event: any) => {
            const newEvent = {
              title:
                event.title && event.title.length > 15
                  ? event.title.substring(0, 14) + "..."
                  : event.title,
              start: new Date(event.startTime),
              end: new Date(event.endTime),
              ...event,
            };
            // Take only 5 recent events to display in widget
            if (getCommunityUpcomingEvents.length < 5) {
              getCommunityUpcomingEvents.push(newEvent);
            }
          });
        }
        setEvents(getCommunityUpcomingEvents);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const getGroupUpcomingEvents = () => {
    let getCommunityUpcomingEvents: any = [];
    setIsLoading(true);
    EventService.latestGroupEvent(user, community.id, group?.id)
      .then((response) => {
        if (response.events) {
          response.events.map((event: any) => {
            const newEvent = {
              title:
                event.title && event.title.length > 15
                  ? event.title.substring(0, 14) + "..."
                  : event.title,
              start: new Date(event.startTime),
              end: new Date(event.endTime),
              ...event,
            };
            // Take only 5 recent events to display in widget
            if (getCommunityUpcomingEvents.length < 5) {
              getCommunityUpcomingEvents.push(newEvent);
            }
          });
        }
        setEvents(getCommunityUpcomingEvents);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  // Hide event widget if there is no upcoming event available
  if ((!isLoading && events === undefined) || events.length == 0) {
    return <></>;
  }
  return (
    <div className="w-full rounded-xl bg-card p-5" style={{ maxWidth: "100%" }}>
      <h4 className="border-b border-border pb-5 text-sm font-bold uppercase text-secondary-foreground">
        {isCommunityEvent ? (
          <I18>Upcoming Events</I18>
        ) : (
          <I18>Group Events</I18>
        )}
      </h4>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {events &&
            events.slice(0, 2).map((event: any, i: number) => {
              return <Event event={event} key={i} />;
            })}
          {events?.length > 2 && (
            <TextButton
              label="View All"
              className="text-xxs"
              onClick={(e: any) => {
                history.push(`${COMMUNITY_EVENTS_PAGE_ROUTE}`);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

function Event({ event }: any) {
  const { analyticsService } = useAppService();
  const { monthAbbreviation, dayOfMonth } = formatMonthAndDay(event.startTime);
  return (
    <div
      onClick={(e) => {
        analyticsService.track("event-selected-from-widget");
        history.push(createEventDetailSlugPageRoute(event));
      }}
      className="cursor-pointer space-y-2 pb-2 pt-4">
      <div className="flex space-x-2">
        {/* {event.banner && (
          <img
            className="w-1/3"
            src={event.banner}
            alt="event"
            loading="lazy"
          />
        )} */}
        <div className="flex-col">
          <div className="text-xxs rounded-t-md bg-primary px-2.5 py-0.5 text-primary-foreground">
            {monthAbbreviation}
          </div>
          <div className="text-xxs items-center rounded-b-md border border-border px-2.5 py-1.5 text-secondary-foreground drop-shadow-md">
            <span className="ml-0.5">{dayOfMonth}</span>
          </div>
        </div>

        {/* Event Info */}
        <div className="flex-col">
          <div className="text-xs font-semibold text-secondary-foreground">
            {formatEventDate(event.startTime)}
          </div>
          <div className="text-xxs flex items-center font-semibold text-secondary-foreground">
            <span>{eventTimeV2(event.startTime)}-</span>
            <span>{eventTimeV2(event.endTime)}</span>
          </div>
          <div className="truncate-two-lines my-1 text-xs font-normal text-secondary-foreground">
            {event.title}
          </div>
          {/* Host */}
          <div className="flex items-center">
            <Avatar
              user={event.host ?? event.createdBy}
              className=""
              extraInfo={undefined}
              size={25}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default UpcomingEventWidget;
