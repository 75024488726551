import { SVGProps } from "react";

export function MessageIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      className={`fill-current ${props.className}`}
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11056_4514)">
        <path
          d="M13.3333 0.5H2.66667C1.95942 0.5 1.28115 0.780952 0.781049 1.28105C0.280952 1.78115 0 2.45942 0 3.16667L0 11.1667C0 11.8739 0.280952 12.5522 0.781049 13.0523C1.28115 13.5524 1.95942 13.8333 2.66667 13.8333H4.6L7.56733 16.342C7.68771 16.4439 7.8403 16.4998 7.998 16.4998C8.1557 16.4998 8.30829 16.4439 8.42867 16.342L11.4 13.8333H13.3333C14.0406 13.8333 14.7189 13.5524 15.219 13.0523C15.719 12.5522 16 11.8739 16 11.1667V3.16667C16 2.45942 15.719 1.78115 15.219 1.28105C14.7189 0.780952 14.0406 0.5 13.3333 0.5V0.5ZM14.6667 11.1667C14.6667 11.5203 14.5262 11.8594 14.2761 12.1095C14.0261 12.3595 13.687 12.5 13.3333 12.5H11.4C11.0849 12.5001 10.7799 12.6118 10.5393 12.8153L8 14.96L5.462 12.8153C5.22104 12.6115 4.9156 12.4998 4.6 12.5H2.66667C2.31304 12.5 1.97391 12.3595 1.72386 12.1095C1.47381 11.8594 1.33333 11.5203 1.33333 11.1667V3.16667C1.33333 2.81304 1.47381 2.47391 1.72386 2.22386C1.97391 1.97381 2.31304 1.83333 2.66667 1.83333H13.3333C13.687 1.83333 14.0261 1.97381 14.2761 2.22386C14.5262 2.47391 14.6667 2.81304 14.6667 3.16667V11.1667Z"
          fill="#374957"
        />
        <path
          d="M4.66667 5.16732H8C8.17681 5.16732 8.34638 5.09708 8.4714 4.97205C8.59643 4.84703 8.66667 4.67746 8.66667 4.50065C8.66667 4.32384 8.59643 4.15427 8.4714 4.02925C8.34638 3.90422 8.17681 3.83398 8 3.83398H4.66667C4.48986 3.83398 4.32029 3.90422 4.19526 4.02925C4.07024 4.15427 4 4.32384 4 4.50065C4 4.67746 4.07024 4.84703 4.19526 4.97205C4.32029 5.09708 4.48986 5.16732 4.66667 5.16732Z"
          fill="#374957"
        />
        <path
          d="M11.3333 6.5H4.66667C4.48986 6.5 4.32029 6.57024 4.19526 6.69526C4.07024 6.82029 4 6.98985 4 7.16667C4 7.34348 4.07024 7.51304 4.19526 7.63807C4.32029 7.76309 4.48986 7.83333 4.66667 7.83333H11.3333C11.5101 7.83333 11.6797 7.76309 11.8047 7.63807C11.9298 7.51304 12 7.34348 12 7.16667C12 6.98985 11.9298 6.82029 11.8047 6.69526C11.6797 6.57024 11.5101 6.5 11.3333 6.5Z"
          fill="#374957"
        />
        <path
          d="M11.3333 9.16602H4.66667C4.48986 9.16602 4.32029 9.23625 4.19526 9.36128C4.07024 9.4863 4 9.65587 4 9.83269C4 10.0095 4.07024 10.1791 4.19526 10.3041C4.32029 10.4291 4.48986 10.4994 4.66667 10.4994H11.3333C11.5101 10.4994 11.6797 10.4291 11.8047 10.3041C11.9298 10.1791 12 10.0095 12 9.83269C12 9.65587 11.9298 9.4863 11.8047 9.36128C11.6797 9.23625 11.5101 9.16602 11.3333 9.16602Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_11056_4514">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
