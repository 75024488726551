import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import MODAL_TAB_TYPES from "../../_constants/modalTabTypes";
import visibilityTypes from "../../_constants/visibilityTypes";
import {
  createGroupPageUrlFromSlug,
  getGroupPageRouteFromSlug,
} from "../../_pages/group/group.page";
import { GroupService } from "../../_service";
import { setGroups } from "../../_store/_actions/group.actions";
import { CloseButton } from "../activity/close-button.component";
import I18 from "../atoms/i18";
import { Button } from "../button.component";
import { ConvertToSlugString } from "../create-slug-modal";
import CustomRadioComponent from "../form-controls/custom-radio.component";
import { FileInput } from "../form-controls/file-input";
import ImageCropModal from "../image-crop/image-crop-modal";
import { TextInput } from "../text-input.component";
import UIcon from "../uicon-component";

const CreateGroupSidebarModal: React.FC<{
  group?: any;
  isEditGroupModalVisible: Boolean;
  updateGroup: any;
  setIsEditGroupModalVisible: (editModalVisibility: any) => void;
  setIsCreateGroupModalVisible: (createModalvisibility: any) => void;
}> = ({
  group,
  isEditGroupModalVisible,
  setIsEditGroupModalVisible,
  updateGroup,
  setIsCreateGroupModalVisible,
}) => {
  const [activeTab, setActiveTab] = useState<string>(MODAL_TAB_TYPES.BASIC);
  const [groupName, setGroupName] = useState(
    isEditGroupModalVisible ? group?.name : ""
  );
  const [groupDescription, setGroupDescription] = useState(
    isEditGroupModalVisible ? group?.description : ""
  );
  console.log(group?.groupType)
  const [groupType, setGroupType] = useState(
    isEditGroupModalVisible ? group.isOnlyVisibleToMembers ? visibilityTypes.GROUP.PRIVATE : group?.groupType : visibilityTypes.GROUP.OPEN
  );
  const [isSEOSettingsTabExpanded, setIsSEOSettingsTabExpanded] =
    useState(true);
  const [isOpenGraphDetailsTabExpanded, setIsOpenGraphDetailsTabExpanded] =
    useState(true);
  const [seoSlug, setSeoSlug] = useState(
    isEditGroupModalVisible ? group?.meta?.slug : ""
  );
  const [seoMetaTitle, setSeoMetaTitle] = useState(
    isEditGroupModalVisible ? group?.meta?.title : ""
  );
  const [seoDescription, setSeoDescription] = useState(
    isEditGroupModalVisible ? group?.meta?.description : ""
  );
  const [openGraphTitle, setOpenGraphTitle] = useState(
    isEditGroupModalVisible ? group?.meta?.openGraphTitle : ""
  );
  const [openGraphDescription, setOpenGraphDescription] = useState(
    isEditGroupModalVisible ? group?.meta?.openGraphDescription : ""
  );
  const [openGraphImage, setOpenGraphImage] = useState(
    isEditGroupModalVisible ? group?.meta?.openGraphImage : ""
  );
  const [color, setColor] = useState(
    isEditGroupModalVisible ? group?.color : ""
  );
  const [image, setImage] = useState(
    isEditGroupModalVisible ? group?.banner : null
  );
  const [groupBanner, setGroupBanner] = useState(
    isEditGroupModalVisible ? group?.groupBanner : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isOnlyVisibleToMembers, setIsOnlyVisibleToMembers] = useState(
    isEditGroupModalVisible ? group?.isOnlyVisibleToMembers : false
  );
  const [price, setPrice] = useState(
    group && group.price ? group.price / 100 : 0
  );
  const [currency, setCurrency] = useState(group?.currency || "INR");
  const [isFormSubmittable, setIsFormSubmittable] = useState(
    group ? true : false
  );
  const [gatedTokenAddress, setGatedTokenAddress] = useState(
    group?.gatedTokenAddress || ""
  );
  const [activeCrop, setActiveCrop] = useState(false);
  const [file, selectFile] = useState("");
  const [activeUnSplash, setActiveUnSplash] = useState(false);

  const textAreaElement = useRef<HTMLTextAreaElement>(null);
  const { analyticsService } = useAppService();
  const dispatch = useAppDispatch();
  const defaultTabName = "General";
  const defaultTabEmoji = "#";
  const postLevel = "admin";
  const history = useHistory();

  const { groups, community, user } = useAppSelector((s) => ({
    groups: s.groups,
    user: s.auth,
    community: s.community,
  }));

  const onGroupCreate = (group: any) => {
    // group
    dispatch(setGroups([...groups, group]));
  };

  const onGroupUpdate = (group: any) => {
    updateGroup(group);
  };

  const submitForm = () => {
    // call the create group api
    setIsLoading(true);
    const meta = {
      slug: ConvertToSlugString(seoSlug === null ? groupName : seoSlug),
      title: seoMetaTitle === null ? groupName : seoMetaTitle,
      description: seoDescription === null ? groupDescription : seoDescription,
      openGraphTitle: openGraphTitle === null ? groupName : openGraphTitle,
      openGraphDescription:
        openGraphDescription === null ? groupDescription : openGraphDescription,
      openGraphImage: openGraphImage,
    };
    if (isEditGroupModalVisible && group && Object.keys(group).length) {
      let banner = "";

      if (group.banner && typeof image === "string") {
        banner = image;
      }

      updateTheGroup(user, {
        name: groupName,
        color,
        description: groupDescription,
        banner,
        groupType:
          groupType === visibilityTypes.GROUP.PRIVATE
            ? visibilityTypes.GROUP.CLOSED
            : groupType,
        isOnlyVisibleToMembers: groupType === visibilityTypes.GROUP.PRIVATE,
        price: price * 100,
        currency,
        meta: meta,
        gatedTokenAddress,
      });
    } else {
      createGroup(user, {
        name: groupName,
        description: groupDescription,
        color,
        groupType:
          groupType === visibilityTypes.GROUP.PRIVATE
            ? visibilityTypes.GROUP.CLOSED
            : groupType,
        isOnlyVisibleToMembers: groupType === visibilityTypes.GROUP.PRIVATE,
        price: price * 100,
        currency,
        postLevel,
        defaultTabName,
        defaultTabEmoji,
        communityId: community ? community.id : null,
        meta: meta,
        gatedTokenAddress,
      });
    }
  };

  const createGroup = (user: any, group: any) => {
    GroupService.createGroup(user, group)
      .then(({ group }) => {
        // close the form, check if banner needs to be uploaded
        if (!image) {
          setIsLoading(false);
          onGroupCreate(group);
          resetFormAndClose();
          analyticsService.track("create-group", {
            userType: community.myRole,
            groupName: group.name,
            description: group.description,
            groupType: groupType,
            groupUrl: createGroupPageUrlFromSlug(group),
          });
        } else {
          // upload the banner
          GroupService.uploadBanner(user, group.id, image)
            .then((response) => {
              setIsLoading(false);
              onGroupCreate(response.group);
              resetFormAndClose();
              setIsEditGroupModalVisible(false);
              analyticsService.track("create-group", {
                userType: community.myRole,
                groupName: group.name,
                description: group.description,
                groupType: groupType,
                groupUrl: group.url,
              });
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
        setIsEditGroupModalVisible(false);
        history.push(getGroupPageRouteFromSlug(group));
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const updateTheGroup = (user: any, groupData: any) => {
    GroupService.updateGroup(user, group.id, groupData)
      .then((response) => {
        // close the form, check if banner needs to be uploaded
        if (!image) {
          setIsLoading(false);
          resetFormAndClose();
          onGroupUpdate(response.group);
        } else {
          // upload the banner
          GroupService.uploadBanner(user, response.group.id, image)
            .then((response) => {
              setIsLoading(false);
              resetFormAndClose();
              onGroupUpdate(response.group);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const resetFormAndClose = () => {
    setImage(null);
    setGroupName("");
    setPrice(0);
    setCurrency("INR");
    setGroupDescription("");
    setGroupType("");

    setIsFormSubmittable(false);
    setIsEditGroupModalVisible && setIsEditGroupModalVisible(false);
    setIsCreateGroupModalVisible && setIsCreateGroupModalVisible(false);
    selectFile("");
    setActiveCrop(true);
    clearSlugMeta();
    setColor("");
    setGatedTokenAddress("");
    setGroupBanner("");
  };

  function clearSlugMeta() {
    setSeoSlug("");
    setSeoMetaTitle("");
    setSeoDescription("");
    setOpenGraphTitle("");
    setOpenGraphDescription("");
    setOpenGraphImage("");
  }

  let rows = groupDescription?.split("\n").length;
  rows = rows > 3 ? rows : 3;

  function checkMaxCharacter(text: any) {
    if (text !== undefined && text !== null && text.length > 255) {
      return text.substring(0, 255);
    } else {
      return text;
    }
  }

  return (
    <div className="h-fit bg-card text-secondary-foreground">
      <div className="sticky top-0 flex-col border-b border-border bg-card p-4">
        <div className="flex-col items-center">
          <div className="flex justify-between">
            <span className="text-base font-bold text-secondary-foreground/80">
              {isEditGroupModalVisible ? (
                <I18>Edit Group</I18>
              ) : (
                <I18>Create Group</I18>
              )}
            </span>
            <CloseButton onClick={resetFormAndClose} />
          </div>
          <div className="flex items-center gap-2">
            <span
              className={`${
                activeTab === MODAL_TAB_TYPES.BASIC
                  ? "cursor-pointer rounded bg-accent px-4 py-1 text-primary"
                  : "cursor-pointer px-4 py-1 text-secondary-foreground"
              }`}
              onClick={() => setActiveTab(MODAL_TAB_TYPES.BASIC)}>
              Basic Details
            </span>
            <span
              className={`${
                activeTab === MODAL_TAB_TYPES.ADVANCED
                  ? "cursor-pointer rounded bg-accent px-4 py-1 text-primary"
                  : "cursor-pointer px-4 py-1 text-secondary-foreground"
              }`}
              onClick={() => setActiveTab(MODAL_TAB_TYPES.ADVANCED)}>
              Advance Details
            </span>
          </div>
        </div>
      </div>
      {activeTab === MODAL_TAB_TYPES.BASIC && (
        <div className="h-fit px-4 py-6">
          {/* Group name */}
          <div className="mb-1 text-xs font-bold after:ml-0.5 after:text-red-500 after:content-['*']">
            <I18>Group Name</I18>
          </div>
          <TextInput
            placeholder="Enter Group name"
            className="bg-card"
            containerClassName="bg-card"
            defaultValue={groupName}
            validator={(value) => {
              if (value && value.length > 2) {
                return null;
              } else {
                return "Valid channel name required!";
              }
            }}
            onChange={(value) => {
              setGroupName(value);
            }}
            large
          />
          {/* Group description */}
          <div>
            <div className="mb-1 text-xs font-bold">
              <I18>Group Description</I18>
            </div>
            <textarea
              ref={textAreaElement}
              value={groupDescription}
              onChange={(e) => {
                setGroupDescription(e.target.value);
                window.setTimeout(() => {
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }, 0);
              }}
              className="block flex w-full flex-grow rounded border border-border bg-card px-2 py-1 focus:outline-none"
              rows={rows}
              placeholder="Enter description here"
            />
          </div>
          <div className="mt-1 flex gap-4">
            <CustomRadioComponent
              selectorTypeLabel="Group Access"
              setSelectedOption={setGroupType}
              selectedOption={groupType}
              options={[
                {
                  id: visibilityTypes.GROUP.OPEN,
                  iconName: "unlock",
                  selectorName: "Open",
                  tooltips: visibilityTypes.GROUP_TOOLTIPS.OPEN.tooltips,
                },
                {
                  id: visibilityTypes.GROUP.CLOSED,
                  iconName: "lock",
                  selectorName: "Closed",
                  tooltips: visibilityTypes.GROUP_TOOLTIPS.CLOSED.tooltips,
                },
                {
                  id: visibilityTypes.GROUP.PRIVATE,
                  iconName: "eye-crossed",
                  selectorName: "Private",
                  tooltips: visibilityTypes.GROUP_TOOLTIPS.PRIVATE.tooltips,
                },
              ]}
            />
          </div>
          {/* Group Cover Image */}
          <div className="mb-2 text-xs font-bold">
            <I18>Cover Picture</I18>
          </div>
          <div className="mb-20 w-full flex-col">
            {image ? (
              <div className="group-color-preview-holder h-80 w-full rounded">
                <div
                  className="group-color-preview relative h-full w-full rounded"
                  style={{
                    backgroundImage:
                      image &&
                      `url(${isEditGroupModalVisible && group.banner && typeof image === "string" ? image : URL.createObjectURL(image)})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="absolute bottom-1 right-1">
                    <div className="relative mx-auto flex items-center justify-between">
                      <FileInput
                        accept="image/*"
                        maxFileSize={10}
                        onChange={(e) => {
                          setActiveCrop(true);
                          //@ts-ignore
                          let image = URL.createObjectURL(e);
                          selectFile(image);
                          setActiveUnSplash(false);
                        }}>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className="mr-2"
                          label="Change Cover"
                        />
                      </FileInput>

                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setImage(null);
                        }}
                        className="mr-2"
                        label="Remove"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center gap-1 rounded-md border border-dashed border-border py-2 text-center hover:cursor-pointer">
                <div className="flex items-center">
                  <UIcon icon="plus-small" className="select-none text-sm" />
                </div>
                <FileInput
                  accept="image/*"
                  onChange={(chosenFile) => {
                    setActiveCrop(true);
                    //@ts-ignore
                    let image = URL.createObjectURL(chosenFile);
                    selectFile(image);
                    setActiveUnSplash(false);
                  }}>
                  <div
                    className={
                      "color-selector banner" + (image ? "selected" : "")
                    }>
                    <div className="theme-primary-text-color text-sm font-semibold leading-[19px] tracking-[0.01em] text-secondary-foreground">
                      <I18>Upload Group Thumbnail</I18>
                    </div>
                  </div>
                </FileInput>
              </div>
            )}
            <span className="mt-2 text-xs text-[#8C8C8C]">
              Size: 16:9 or 1600 by 900px
            </span>
          </div>
        </div>
      )}
      {activeTab === MODAL_TAB_TYPES.ADVANCED && (
        <div className="px-4 py-6">
          <div className="mb-4 mt-2 flex items-center gap-2">
            <span className="text-xs font-bold uppercase">SEO Settings</span>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsSEOSettingsTabExpanded(!isSEOSettingsTabExpanded);
              }}>
              <UIcon
                icon={`${
                  isSEOSettingsTabExpanded ? "caret-down" : "caret-right"
                }`}
                className="text-sm"
              />
            </span>
          </div>
          {isSEOSettingsTabExpanded && (
            <>
              <div className="mb-1 text-xs font-bold">
                <I18>Slug</I18>
              </div>
              <TextInput
                placeholder="general-info"
                className="bg-card"
                containerClassName="bg-card"
                defaultValue={seoSlug}
                onChange={(value) => {
                  setSeoSlug(value);
                }}
                large
              />
              <div className="mb-1 text-xs font-bold">
                <I18>Meta Title</I18>
              </div>
              <TextInput
                placeholder="general-info"
                className="bg-card"
                containerClassName="bg-card"
                defaultValue={seoMetaTitle}
                onChange={(value) => {
                  setSeoMetaTitle(value);
                }}
                large
              />
              <div className="mb-1 text-xs font-bold">
                <I18>Description</I18>
              </div>
              <div className="flex rounded border border-border">
                <textarea
                  className="block w-full flex-grow rounded bg-card px-2 py-1 focus:outline-none"
                  value={seoDescription ?? ""}
                  onChange={(e) => {
                    setSeoDescription(checkMaxCharacter(e.target.value));
                    window.setTimeout(() => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                    }, 0);
                  }}
                />
              </div>
            </>
          )}
          <div className="mb-4 mt-6 flex items-center gap-2">
            <span className="text-xs font-bold uppercase">
              Open Graph Details
            </span>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsOpenGraphDetailsTabExpanded(
                  !isOpenGraphDetailsTabExpanded
                );
              }}>
              <UIcon
                icon={`${
                  isOpenGraphDetailsTabExpanded ? "caret-down" : "caret-right"
                }`}
                className="text-sm"
              />
            </span>
          </div>
          {isOpenGraphDetailsTabExpanded && (
            <>
              <div className="mb-1 text-xs font-bold">
                <I18>Open Graph Title</I18>
              </div>
              <TextInput
                placeholder="general-info"
                className="bg-card"
                containerClassName="bg-card"
                defaultValue={openGraphTitle}
                onChange={(value) => {
                  setOpenGraphTitle(value);
                }}
                large
              />
              <div className="mb-1 text-xs font-bold">
                <I18>Open Graph Description</I18>
              </div>
              <div className="flex rounded border border-border">
                <textarea
                  className="block w-full flex-grow rounded bg-card px-2 py-1 focus:outline-none"
                  value={openGraphDescription ?? ""}
                  onChange={(e) => {
                    setOpenGraphDescription(checkMaxCharacter(e.target.value));
                    window.setTimeout(() => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                    }, 0);
                  }}
                />
              </div>
              <div className="mb-14">
                <div className="mb-1 mt-2 text-xs font-bold">
                  <I18>Open Graph Image</I18>
                </div>
                <TextInput
                  placeholder="Enter Image URL"
                  className="bg-card"
                  containerClassName="bg-card"
                  defaultValue={openGraphImage}
                  onChange={(value) => {
                    setOpenGraphImage(value);
                  }}
                  large
                />
                <p className="px-4 text-xs text-[#8C8C8C]">
                  It will take group thumbnail as image by default. Recommended
                  size - 1200 X 630 px or 1.9:1 aspect ratio
                </p>
              </div>
            </>
          )}
        </div>
      )}
      <div className="fixed bottom-0 w-full border-t border-border bg-card p-4 md:w-3/5 lg:w-[38%]">
        <div className="flex justify-between">
          <Button
            className="w-36 px-0 text-sm"
            isCancelType={true}
            label="Cancel"
            onClick={() => resetFormAndClose()}
          />
          <Button
            onClick={submitForm}
            className="text-sm"
            label={isEditGroupModalVisible ? "Update Group" : "Create Group"}
            isLoading={isLoading}
          />
        </div>
      </div>
      {file && activeCrop ? (
        <ImageCropModal
          src={file}
          setPicture={setImage}
          activeCrop={activeCrop}
          setActiveCrop={setActiveCrop}
          aspectRatio={16 / 9}
          cropRatioMessage="Banner ratio - 16:9"
        />
      ) : null}
    </div>
  );
};

export default CreateGroupSidebarModal;
