import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  getHighlightedPosts: (communityId) =>
    base + `community/${communityId}/highlighted-posts`,
  highlightPost: (communityId, postId) =>
    base + `community/${communityId}/highlighted-posts/${postId}`,
  updateHighlightedPostsSortOrder: (communityId) =>
    base + `community/${communityId}/highlighted-posts/sort`,
};
