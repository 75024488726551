export const MAIN_DOMAIN_SUFFIX = process.env.REACT_APP_MAIN_DOMAIN_SUFFIX
  ? process.env.REACT_APP_MAIN_DOMAIN_SUFFIX
  : ".pensil.in";

export function getCommunityAddress(community) {
  return community.address + MAIN_DOMAIN_SUFFIX;
}

export const PENSIL_APP_URL = `app.${MAIN_DOMAIN_SUFFIX}`;

export async function loadScript(src, uniqueId, isAsync, isLink = true) {
  // check if script is already loaded
  if (uniqueId && document.getElementById(uniqueId)) return;

  return new Promise((res) => {
    const script = document.createElement("script");
    // script.async = isAsync;
    script.id = uniqueId;
    if (isLink) {
      script.src = src;
    } else {
      // we have source code
      script.innerHTML = src;
    }
    script.onload = () => {
      console.log("script loaded: " + (uniqueId ? uniqueId : src));
      res(script);
    };
    script.onerror = () => {
      res(false);
      console.log("script load failed: " + (uniqueId ? uniqueId : src));
    };
    document.body.appendChild(script);
  });
}

export function getUserTokenFromStorage() {
  const userFromLocalStorage = JSON.parse(localStorage.getItem("pensil.user"));
  return userFromLocalStorage?.token ?? null;
}

export function getUserIdFromStorage() {
  const userFromLocalStorage = JSON.parse(localStorage.getItem("pensil.user"));
  return userFromLocalStorage?.id ?? null;
}

export function validURL(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

/**
 * get auth url (handle sso and non sso)
 * @param {*} community
 */
export function getAuthUrl(community) {
  // we have an auth url
  let authUrl = "/login";
  if (community && community.authRedirectUrl) {
    authUrl = community.authRedirectUrl;
    // check if auth url already has params
    let communityIdParam = "communityId=" + community.id;
    if (authUrl.includes("?")) {
      authUrl = authUrl + "&" + communityIdParam;
    } else {
      authUrl = authUrl + "?" + communityIdParam;
    }
  }
  return authUrl;
}

/**
 * Redirect user to auth url (handle sso and non sso)
 * @param {*} community
 */
export function redirectToAuthUrl(community) {
  window.location.href = getAuthUrl(community);
}
