import React from "react";
import { CloseButton } from "../activity/close-button.component";
import I18 from "../atoms/i18";
import { Loader } from "../loader.component";
import RightSideModal from "../right-side-modal.component";
import SearchBar from "../unsplash-image-picker/unsplash-photo-search-bar";
import SearchGif from "./tenor-api";
import TenorGifList from "./tenor-gif-list";
interface Props {
  active?: boolean;
  initialSearchQuery: string;
  setActive?: (active: boolean) => void;
  onGifSelect?: (photo: any) => void;
}

/**
 * @description TenorGifPicker is a component that allows you to search for gifs from tenor.com
 * @param {string} initialSearchQuery - The initial search query.
 * @param {boolean} active - Whether the GIF picker is active.
 * @param {function} setActive - Function to set the GIF picker active.
 * @param {function} onGifSelect - Function to call when a GIF is selected.
 */
export default function TenorGifPicker({
  active = false,
  initialSearchQuery = "",
  setActive = (_: boolean) => {},
  onGifSelect = (_: any) => {},
}: Props) {
  const [pics, setPics] = React.useState<any[]>([]);
  const [total, setTotal] = React.useState<number | undefined>();
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [query, setQuery] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [page, setPage] = React.useState("");
  const [convertingToBlob, setConvertingToBlob] = React.useState(false);

  React.useEffect(() => {
    if (!active) {
      return;
    }
    setQuery(initialSearchQuery);
    fetchGif("", initialSearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, initialSearchQuery]);

  const fetchGif = (page: string, text: string, reset = false) => {
    if (isLoading || isLoadingMore || !hasMore) {
      return;
    }
    if (page === "") {
      setIsLoading(true);
    } else {
      setIsLoadingMore(true);
    }
    SearchGif(text, page, 30, (response) => {
      //   setPics(result.results);
      const newPics = response.results;
      setPage(response.next);
      if (response.next === "0" || response.results.length === 0) {
        setHasMore(false);
      } else if (newPics) {
        let mergedPics = newPics;
        if (!reset) {
          mergedPics = [...pics, ...newPics];
        }
        setPics(mergedPics);
        setTotal(newPics.length);
        setHasMore(true);
      }
      setIsLoadingMore(false);
      setIsLoading(false);
    });
  };

  if (!active) {
    return null;
  }

  return (
    <div style={{ zIndex: "auto" }} className="TenorGifPicker">
      <RightSideModal
        active={active}
        setActive={setActive}
        width="540px"
        className="bg-card">
        <div className="h-full">
          <div className="flex place-content-between items-start bg-card px-4 pt-2 text-lg font-bold">
            <I18>Search Gif</I18>
            <CloseButton
              onClick={() => {
                setActive(false);
              }}
            />
          </div>
          <div className="bg-card p-4 shadow">
            <div className="">
              <SearchBar
                placeholder="Search Tenor"
                onSearch={(query: string) => {
                  setPics([]);
                  setHasMore(true);
                  fetchGif("", query, true);
                }}
                query={query}
                setQuery={setQuery}
              />
            </div>
          </div>

          <TenorGifList
            total={total}
            photoList={pics}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            loadMore={() => {
              fetchGif(page + 1, query);
            }}
            onGifSelect={async (gif: any) => {
              try {
                setConvertingToBlob(true);
                let blob = await fetch(gif.media[0].tinygif.url).then((r) =>
                  r.blob()
                );

                let file = new File(
                  [blob],
                  gif.media[0].tinygif.url.split("/").pop(),
                  {
                    type: "image/gif",
                  }
                );

                setConvertingToBlob(false);
                onGifSelect({
                  ...gif,
                  blobData: file,
                });
              } catch (error) {
                console.log(error);
                setConvertingToBlob(false);
              }
            }}
          />
        </div>
        {isLoading ||
          (convertingToBlob && (
            <div className="absolute bottom-0 left-0 right-0 top-0">
              <div className="flex h-full place-content-center items-center bg-slate-600/70">
                <div className="opacity-100">
                  <Loader />
                </div>
              </div>
            </div>
          ))}
      </RightSideModal>
    </div>
  );
}
