import MessageTileShimmer from "./message-tile-shimmer.component";

/**
 * MessageListShimmer
 * @param {*} length
 */
function MessageListShimmer({ length }) {
  const list = [];

  for (let i = 0; i < length ?? 1; i++) {
    list.push(<MessageTileShimmer key={i} />);
  }
  return (
    <div className="mx-auto w-full animate-pulse pt-0">
      <div className="flex rounded border-b p-6">
        <div className="h-2 w-12 rounded bg-gray-300" />
      </div>
      <div className="relative flex h-[calc(100vh_-_110px)] flex-grow flex-col justify-end overflow-y-auto px-8 py-2">
        {list}
      </div>
    </div>
  );
}

export default MessageListShimmer;
