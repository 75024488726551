import axios from "axios";
import { User } from "../types/user/minimal-user.type";
import { endpoints } from "../_config";

class MediaService {
  static async uploadMedia(
    user: User,
    communityId: String,
    mediaFiles: any,
    mediaType: String
  ) {
    const formData = new FormData();

    mediaFiles?.forEach((file: any) => {
      formData.append(`${mediaType}s[]`, file, file.fileName);
    });

    return axios
      .post(endpoints.media.uploadMedia(communityId, mediaType), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
}

export { MediaService };
