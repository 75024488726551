import cx from "classnames";
import { useEffect, useState } from "react";
import { ToastTypes } from "../../contexts/toastr.context";
import { useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import { useToast } from "../../hooks/use-toast.hook";
import { Widget } from "../../types/widget/widget.type";
import { WidgetService } from "../../_service";
import { ActionModal } from "../action-modal.component";
import { CloseButton } from "../activity/close-button.component";
import I18 from "../atoms/i18";
import { Button } from "../button.component";
import { Checkbox } from "../form-controls/checkbox.component";
import IconMenu2, { ActionType } from "../icon-menu-2.component";
import SidebarButtonComponent from "../sidebar-button-v2.component";
import { CreateWidgetModal } from "./create-custom-widget-modal.component";

type Props = {
  widgets: Array<Widget>;
  setActive: (value: boolean) => void;
  onWidgetListUpdate: (widgets: Array<Widget>) => void;
};

const SelectWidgetModalV2 = ({
  widgets,
  setActive,
  onWidgetListUpdate,
}: Props) => {
  const [widgetList, setWidgetList] = useState(widgets ?? []);
  const [draggingTabIndex, setDraggingTabIndex] = useState<number>();
  const [widgetToEdit, setWidgetToEdit] = useState();
  const [isSelectWidgetVisible, setIsSelectWidgetVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { analyticsService } = useAppService();
  const { addToast } = useToast();

  useEffect(() => {
    if (widgetToEdit) {
      setIsSelectWidgetVisible(true);
    } else {
      setIsSelectWidgetVisible(false);
    }
  }, [widgetToEdit]);

  const { user, community, group } = useAppSelector((state) => {
    return {
      user: state.auth,
      community: state.community,
      group: state.activeGroup,
    };
  });

  function saveWidgets() {
    const sortWidgets = widgetList.map((w, index) => {
      return {
        widgetId: w.id,
        sortOrder: index,
        isVisible: w.isVisible,
        isVisibleToAdmin: w.isVisibleToAdmin,
      };
    });

    const noOfCustomWidgets = widgetList.filter(
      (widget) => widget.type === "custom"
    ).length;
    const nonCustomVisibleWidgets = widgetList
      .filter((widget) => widget.type !== "custom")
      .reduce((acc, widget) => {
        //@ts-ignore
        acc[widget.type] = widget.isVisible;
        return acc;
      }, {});

    //@ts-ignore
    analyticsService.track("save-widget-clicked", {
      noOfCustomWidgets,
      ...nonCustomVisibleWidgets,
    });

    setIsLoading(true);
    WidgetService.updateSortOrder(user, community.id, {
      sortOrder: sortWidgets,
      groupId: group ? group.id : "",
    })
      .then((res) => {
        setActive(false);
        addToast("Widgets saved successfully");
        onWidgetListUpdate(widgetList);
      })
      .catch((err) => {
        console.log(err);
        addToast("Error in saving widgets", "", ToastTypes.danger);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="bg-card">
      <div className="sticky top-0 flex-col border-b border-border bg-card px-4 pb-2 pt-4">
        <div className="flex-col items-center">
          <div className="flex items-center justify-between">
            <span className="text-base font-bold text-secondary-foreground/80">
              <I18>Customize Widgets</I18>
            </span>
            <CloseButton
              onClick={() => {
                setActive(false);
              }}
            />
          </div>
        </div>
      </div>
      <div className="h-fit px-4 py-6 text-secondary-foreground">
        <I18>
          Note: Widget will not be visible if there is no data available
        </I18>
        {/* widgets */}
        {widgetList &&
          widgetList.map((widget, index) => {
            return (
              <div
                className="mb-2"
                key={index}
                onDrop={(e) => {
                  try {
                    // exchange the sortOrder of both of the indices
                    const sortOrder = widgetList.map((g, i) => {
                      return {
                        ...g,
                        sortOrder: i + 1,
                      };
                    });

                    const oldIndex = sortOrder[index].sortOrder;
                    // set new sort order
                    sortOrder[index].sortOrder =
                      //@ts-ignore
                      sortOrder[draggingTabIndex].sortOrder;
                    // set old sort order
                    //@ts-ignore
                    sortOrder[draggingTabIndex].sortOrder = oldIndex;

                    // sort the sortOrder list according to sortOrder
                    sortOrder.sort((a, b) => a.sortOrder - b.sortOrder);
                    setWidgetList(sortOrder);
                  } catch (e) {
                    console.error(e);
                  }
                }}>
                <WidgetRow
                  widget={widget}
                  forCommunity={group ? false : true}
                  //@ts-ignore
                  setWidgetToEdit={setWidgetToEdit}
                  onDeleteWidget={(wid) => {
                    // get widget index
                    const newList = widgetList.filter((p) => p.id !== wid.id);
                    setWidgetList(newList);
                  }}
                  onUpdateWidget={(wid) => {
                    // get widget index
                    const widgetIndex = widgetList.findIndex(
                      (p) => p.id === wid.id
                    );
                    const oldWidget = [...widgetList];
                    oldWidget.splice(widgetIndex, 1, wid);
                    setWidgetList(oldWidget);
                  }}
                  onDragStart={(e) => {
                    setDraggingTabIndex(index);
                  }}
                />
              </div>
            );
          })}
        <div className="mb-32 mt-8">
          <Button
            onClick={(e) => {
              //@ts-ignore
              analyticsService.track("add-custom-widget-clicked");
              setIsSelectWidgetVisible(true);
            }}
            outlined
            label="Add Custom"
          />
        </div>

        {/* Footer */}
        <div className="fixed bottom-0 right-0 w-full border-t border-border bg-card p-4 md:w-3/5 lg:w-[38%]">
          <div className="flex justify-between">
            <SidebarButtonComponent
              buttonName="Cancel"
              onClick={() => {
                setActive(false);
              }}
            />
            <SidebarButtonComponent
              buttonName="Save Widgets"
              buttonType="success"
              onClick={saveWidgets}
            />
          </div>
        </div>
      </div>
      <CreateWidgetModal
        //@ts-ignore
        widgetToEdit={widgetToEdit}
        community={community}
        user={user}
        group={group}
        active={isSelectWidgetVisible}
        setActive={(visible: boolean) => {
          setIsSelectWidgetVisible(visible);
          setWidgetToEdit(undefined);
        }}
        forCommunity={group ? false : true}
        onWidgetCreate={(widget: Widget) => {
          addToast("Custom widget created successfully");
          const list = [...widgetList, widget];
          setWidgetList(list);
          setIsSelectWidgetVisible(false);
          // setActive(false);
          console.log("Widgets added to list successfully", widgetList);
        }}
        onWidgetUpdated={(widget: Widget) => {
          addToast("Custom widget updated successfully");
          const list = [...widgetList];
          const index = list.findIndex((p) => p.id === widget.id);
          list[index] = widget;
          setWidgetList(list);
          setIsSelectWidgetVisible(false);
          // setActive(false);
        }}
      />
    </div>
  );
};

type WidgetRowProps = {
  widget: Widget;
  forCommunity: boolean;
  setWidgetToEdit: (widget: Widget) => void;
  onUpdateWidget: (widget: Widget) => void;
  onDeleteWidget: (widget: Widget) => void;
  onDragStart: (e: any) => void;
  onDrop: (e: any) => void;
};

function WidgetRow({
  widget,
  forCommunity = true,
  setWidgetToEdit = (e) => {},
  onUpdateWidget = (e) => {},
  onDeleteWidget = (e) => {},
  onDragStart = (e) => {},
  onDrop = (e) => {},
}: WidgetRowProps) {
  const { addToast } = useToast();

  const { user, community } = useAppSelector((state) => {
    return {
      user: state.auth,
      community: state.community,
    };
  });

  const isCustomWidget = widget.type === "custom";
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function getMappedWidgetName() {
    switch (widget.type) {
      case "custom":
        return widget.details?.title;
      case "upcoming-events":
        return forCommunity ? "Community Events" : "Group Events";
      case "community-upcoming-event":
        return "Community Event";
      case "top-posts":
        return "Trending Posts";
      case "members":
        return forCommunity ? "Community Members" : "Group Members";
      // case "leaderboard":
      //   return "Group Leaderboard";
      case "community-leaderboard":
        return "Community Leaderboard";
      case "community-members":
        return "Community Members";
      default:
        return widget.type;
    }
  }

  function onSubmitDelete() {
    setIsLoading(true);
    WidgetService.deleteWidget(user, community.id, widget.id)
      .then(() => {
        addToast("Widget deleted successfully");
        onDeleteWidget(widget);
      })
      .catch((err) => {
        console.error(err);
        addToast("Error in deleting widget", "", ToastTypes.danger);
      })
      .finally(() => {
        setIsDeleteModalVisible(false);
        setIsLoading(false);
      });
  }
  return (
    <div
      draggable={true}
      onDragStart={onDragStart}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={onDrop}>
      <div
        className={cx("mt-4 border border-border bg-card", {
          "pl-3": isCustomWidget,
          "p-3": !isCustomWidget,
        })}>
        <div className="flex items-center justify-between">
          <span className="text-md font-semibold text-secondary-foreground">
            {widget.type === "custom" ? (
              <div>
                <span>
                  <I18>{getMappedWidgetName()}</I18>
                  <span
                    className={
                      "theme-bg-disable mx-2 rounded px-2 py-1 text-xs text-primary"
                    }>
                    <I18>custom</I18>
                  </span>
                </span>
              </div>
            ) : (
              <span>
                <I18>{getMappedWidgetName()}</I18>
              </span>
            )}
          </span>
          <div className="flex items-center">
            {widget.type === "custom" ? (
              <IconMenu2
                className=""
                //@ts-ignore
                hoverable={false}
                actions={[
                  {
                    // change user role
                    icon: "edit",
                    label: "Edit",

                    onClick: (e) => {
                      setWidgetToEdit(widget);
                    },
                  },
                  {
                    // change user role
                    icon: "trash",
                    label: "Delete",
                    actionType: ActionType.alert,
                    onClick: (e) => {
                      setIsDeleteModalVisible(true);
                    },
                  },
                ]}
              />
            ) : null}
          </div>
        </div>

        <div className="mb-4 mr-3 mt-2 flex justify-evenly border border-border bg-accent p-2">
          <div className="flex space-x-2 text-xs text-secondary-foreground/70">
            <div>
              <I18>Visible to user</I18>
            </div>
            <Checkbox
              className="ml-2"
              onClick={() => {
                const updateWidget = {
                  ...widget,
                  isVisible: !widget.isVisible,
                };
                onUpdateWidget(updateWidget);
              }}
              selected={widget.isVisible}
            />
          </div>
          <div className="flex space-x-2 text-xs text-secondary-foreground/70">
            <div>
              <I18>Visible to admin</I18>
            </div>
            <Checkbox
              className="ml-2"
              onClick={() => {
                const updateWidget = {
                  ...widget,
                  isVisibleToAdmin: !widget.isVisibleToAdmin,
                };
                onUpdateWidget(updateWidget);
              }}
              selected={widget.isVisibleToAdmin}
            />
          </div>
        </div>
      </div>
      <ActionModal
        active={isDeleteModalVisible}
        setActive={setIsDeleteModalVisible}
        onSubmit={onSubmitDelete}
        header="Delete Widget"
        btnColor="red"
        children={null}
        title="Are you sure you want to delete this widget?"
        isLoading={isLoading}
      />
    </div>
  );
}
export default SelectWidgetModalV2;
