import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import useModel from "../../hooks/use-model.hook";
import { CommunityService } from "../../_service";
import I18 from "../atoms/i18";
import TextButton from "../atoms/text-button";
import { Avatar } from "../avatar.component";
import IconButton2 from "../icon-button-2-component";
import { Loader } from "../loader.component";
import { Modal } from "../modal.component";
import UIcon from "../uicon-component";

/**
 * Widget to display community subscribers list.
 * @param {any} community community object.
 * @param {any} user current active user profile data.
 * @param {int} memberPreviewLimit No. of members to be displayed.
 */
export default function MemberWidget({
  community,
  user,
  memberPreviewLimit = 5,
}) {
  // load the subscriber list once, or per community or group change

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [members, setMembers] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [isSubscriberListVisible, setIsSubscriberListVisible] = useState(false);
  const [isLoadingMoreSubscribers, setIsLoadingMoreSubscribers] =
    useState(false);

  const [noMoreSubscribers, setNoMoreSubscribers] = useState(false);
  const [page, setPage] = useState(1);

  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();

  const innerRef = useRef();
  useEffect(() => {
    setIsLoading(true);
    CommunityService.getCommunitySubscribersPaginated(
      user,
      community.id,
      10,
      1,
      query,
      ""
    )
      .then(({ users, total }) => {
        setSubscribers(users);
        setMembers(users);
        setSubscribersCount(total);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error({ error });
        setIsLoading(false);
      });
  }, [user, community.id]);

  const handleSearchDebounced = React.useCallback(
    debounce((query) => {
      if (["admin"].includes(community.myRole)) {
        setIsLoading(true);
        CommunityService.getCommunitySubscribersPaginated(
          user,
          community.id,
          10,
          1,
          query,
          ""
        )
          .then(({ users, total }) => {
            setSubscribers(users);
            setSubscribersCount(total);
            setIsLoading(false);
            setNoMoreSubscribers(false);
          })
          .catch((error) => {
            console.error({ error });
            setIsLoading(false);
          });
      }
    }, 1000),
    [community.myRole]
  );

  const onScroll = React.useCallback(() => {
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // subscribers list lazy loading you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMoreSubscribers && !noMoreSubscribers) {
          const pageCount = page + 1;
          // if we are not already loading more subscribers, load more subscribers
          setIsLoadingMoreSubscribers(true);
          CommunityService.getCommunitySubscribersPaginated(
            user,
            community.id,
            10,
            pageCount,
            query,
            ""
          )
            .then((response) => {
              if (response.users.length === 0) {
                setNoMoreSubscribers(true);
              } else {
                setSubscribers([...subscribers, ...response.users]);
                setPage(pageCount);
              }
              setIsLoadingMoreSubscribers(false);
            })
            .catch((error) => {
              console.error({ error });
              setIsLoading(false);
            });
        }
      }
    }
  }, [isLoadingMoreSubscribers, noMoreSubscribers, page, query, subscribers]);

  const subscribersList = subscribers;

  return (
    <>
      <div className="rounded-2xl bg-card p-5">
        <h4 className="cursor-pointer select-none border-b border-border pb-5 font-bold uppercase text-secondary-foreground">
          <I18>Community Members</I18>
        </h4>
        {/* members section */}
        <div>
          {/* members list */}
          <div className="GroupMemberList">
            {members.slice(0, memberPreviewLimit).map((member) => (
              <div
                className="group-member-list-item my-3 flex cursor-pointer items-center justify-between"
                key={member.id}>
                <Avatar
                  user={member}
                  size={30}
                  onClick={() => {
                    // open the user page
                    // window.open(createUserWallPageRoute(member.id, "activity"));
                    setShowProfileModel(true);
                    setIdToViewProfile(member.id);
                  }}
                  tag={
                    member.groupRole === "admin" ? (
                      <div className="text-xxs mx-2 rounded bg-primary-foreground px-1 font-semibold text-primary">
                        <I18>Admin</I18>
                      </div>
                    ) : member.groupRole === "moderator" ? (
                      <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                        <I18>Moderator</I18>
                      </div>
                    ) : member.groupRole === "user" ? (
                      <></>
                    ) : (
                      <></>
                    )
                  }
                  extraInfo={undefined}
                />
              </div>
            ))}
          </div>
          {/* show all link */}
          <div className="text-xxs">
            <TextButton
              label="View all"
              onClick={(e) => {
                setIsSubscriberListVisible(true);
              }}
            />
          </div>

          {/* show more members model */}
          <Modal
            className="bg-card"
            setActive={setIsSubscriberListVisible}
            active={isSubscriberListVisible}
            padding={false}>
            {/* headers */}
            <div className="flex items-center justify-between bg-card py-2 pl-4 pr-2">
              <span className="font-bold text-secondary-foreground">
                {community.name} - {subscribersCount} <I18>Subscribers</I18>
              </span>
              <IconButton2
                hoverable={true}
                icon="cross"
                size="xs"
                iconClass="text-secondary-foreground/70 text-xs"
                onClick={() => {
                  setIsSubscriberListVisible(false);
                }}
              />
            </div>
            {/* body */}
            <div className="bg-background p-5 pb-10 text-secondary-foreground">
              {/* search bar */}
              <div className="mb-4 flex items-center rounded border border-border bg-background bg-card px-4 py-1">
                <UIcon
                  icon="search"
                  className="text-xs text-secondary-foreground/70"
                />
                <input
                  type="text"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => {
                    setIsLoading(true);
                    setQuery(e.target.value);
                    handleSearchDebounced(e.target.value);
                  }}
                  className="flex-grow bg-transparent px-4 py-1 focus:outline-none"
                />
                {query.length > 0 ? (
                  <i
                    onClick={() => {
                      setIsLoading(true);
                      setQuery("");
                      handleSearchDebounced("");
                    }}
                    className="fi fi-rr-cross cursor-pointer pr-1 text-sm text-secondary-foreground/85"
                  />
                ) : null}
              </div>
              {/* subscribers list */}
              {isLoading ? (
                <div className="p-1">
                  <Loader />
                </div>
              ) : (
                <></>
              )}
              <div
                onScroll={onScroll}
                ref={innerRef}
                className="h-96 overflow-y-scroll">
                {subscribersList.map((member) => (
                  <div
                    className="m-3 flex cursor-pointer items-center justify-between"
                    key={member.id}>
                    <div className="flex items-center justify-center">
                      <Avatar
                        user={member}
                        onClick={() => {
                          // open the user page
                          // window.open(
                          //   createUserWallPageRoute(member.id, "activity")
                          // );
                          setShowProfileModel(true);
                          setIdToViewProfile(member.id);
                        }}
                        tag={
                          <>
                            {member.communityRole === "admin" ? (
                              <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-primary">
                                <I18>Community Admin</I18>
                              </div>
                            ) : (
                              <></>
                            )}
                            {member.groupRole === "admin" ? (
                              <div className="text-xxs mx-2 rounded bg-blue-50 px-1 font-semibold text-primary">
                                <I18>Admin</I18>
                              </div>
                            ) : member.groupRole === "moderator" ? (
                              <div className="text-xxs mx-2 rounded bg-green-50 px-1 font-semibold text-green-500">
                                <I18>Moderator</I18>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
              {isLoadingMoreSubscribers ? (
                <div className="p-1">
                  <Loader />
                </div>
              ) : null}
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
