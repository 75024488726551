import cx from "classnames";
import React from "react";
import I18 from "../atoms/i18";

interface Props {
  state?: string;
  label?: string;
  value?: string;
  required?: boolean;
  list: Array<string>;
  placeholder?: string;
  onStateChange: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  selectClassName?: string;
}

/**
 * Component to display and update community currency.
 * @param {string} label - label for the currency selector
 * @param {string} currency - currency selected
 * @param {Function} onStateChange - function to set the currency
 * @param {string} className - className for the currency selector
 * @param {string} selectClassName - className for the select element
 * @returns {JSX.Element} - CurrencySelector component
 */
export default function DropDownSelector({
  label,
  list,
  value,
  className,
  required,
  selectClassName,
  placeholder = "Select",

  onStateChange,
}: Props) {
  return (
    <div className={className}>
      <div
        className={cx("mb-1 font-semibold", {
          hidden: !label,
        })}>
        <I18>{label}</I18>
      </div>
      <div
        className={cx(
          "cursor-pointer rounded border border-border px-2 py-2",
          selectClassName
        )}>
        <select
          className="w-full bg-transparent focus:outline-none"
          value={value}
          required={required}
          onChange={(e) => {
            onStateChange(e.target.value);
          }}>
          <option value="">{placeholder}</option>
          {list.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
