import { createUserWallPageRoute } from ".";
import { User } from "../../types/user/minimal-user.type";
import { Avatar } from "../../_components/avatar.component";
import UIcon from "../../_components/uicon-component";
import I18 from "../../_components/atoms/i18";
import { CloseButton } from "../../_components/activity/close-button.component";

interface Props {
  followersList: Array<User>;
  followedList: Array<User>;
  userDetails: any;
  toggleFollowFollowingList: string;
  onClose: () => void;
}

const FollowFollowingList = ({
  followersList,
  followedList,
  userDetails,
  toggleFollowFollowingList,
  onClose,
}: Props) => {
  return (
    <div className="absolute z-10 rounded-md h-full overflow-y-auto  left-0 right-0 mx-auto">
        <div className="py-1-4 sticky top-0 z-10 flex items-center justify-between border-b border-border bg-card px-4 font-bold text-secondary-foreground/80">
        <span
          className={`${
            toggleFollowFollowingList === "follower" ? "visible" : "hidden"
          }`}>
          {toggleFollowFollowingList === "follower" &&
            userDetails.followersCount}{" "}
          {userDetails.followersCount === 1 ? "FOLLOWER" : "FOLLOWERS"}
        </span>
        <span
          className={`${
            toggleFollowFollowingList === "followed" ? "visible" : "hidden"
          }`}>
          {toggleFollowFollowingList === "followed" &&
            userDetails.followingCount}{" "}
          FOLLOWING
        </span>

          <CloseButton
            onClick={() => onClose()}
            type="primary"
          />
      </div>
      {toggleFollowFollowingList === "follower" &&
        followersList?.map((follower: any) => (
          <div
            className="flex items-center px-4 py-3 gap-2 hover:cursor-pointer"
            onClick={() => {
              window.open(createUserWallPageRoute(follower.id, "activity"));
            }}>
            <div className="flex items-center justify-center">
              <Avatar
                className="cursor-pointer"
                user={follower}
                noName={true}
                extraInfo=""
                onClick={(e) => {}}
              />
            </div>
            <div className="flex items-center">
              <p className="text-sm font-bold text-secondary-foreground">
                {follower.name}
              </p>
            </div>
          </div>
        ))}
      {toggleFollowFollowingList === "followed" &&
        followedList?.map((followed: any) => (
          <div
            className="flex items-center px-4 py-3 gap-2 hover:cursor-pointer"
            onClick={() => {
              window.open(createUserWallPageRoute(followed.id, "activity"));
            }}>
            <div className="flex items-center justify-center">
              <Avatar
                className="cursor-pointer"
                user={followed}
                noName={true}
                extraInfo=""
                onClick={(e) => {}}
              />
            </div>
            <div className="flex items-center">
              <p className="text-sm font-bold text-secondary-foreground">
                {followed.name}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FollowFollowingList;