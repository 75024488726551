import Select from "react-select";
import I18 from "../../../atoms/i18";
import { TextInput } from "../../../text-input.component";
import { Heading1 } from "../../../typography";

const positionOptions = [
  { label: "Before Description", value: "top" },
  { label: "After Description", value: "bottom" },
];

function getLabelFromValue(value) {
  switch (value) {
    case "bottom":
      return "After Description";
    default:
      return "Before Description";
  }
}

function CreatePostCustomButton({ buttons, setButtons }) {
  const updateIndex = (index, item) => {
    const newButtons = [...buttons];
    newButtons[index] = item;
    setButtons(newButtons);
  };

  return (
    <div id="CreatePostCustomButton">
      <div className="mb-1 font-bold">
        <Heading1 text="ADD BUTTON" />
      </div>
      {buttons.map((button, index) => (
        <div key={index} className="mb-4 rounded border border-border p-4">
          <div className="flex">
            <div className="w-1/2 pr-2">
              <div className="font-bold">
                <I18>Button Label</I18>
              </div>
              <TextInput
                placeholder="Eg. Join, Buy, Learn more"
                className="h-9"
                defaultValue={button.label}
                onChange={(value) => {
                  updateIndex(index, { ...button, label: value });
                }}
              />
            </div>
            <div className="w-1/2 pl-2">
              <div className="mb-3 font-bold">
                <I18>Button Position</I18>
              </div>
              <Select
                className="select cursor-pointer rounded border-border bg-card text-secondary-foreground"
                defaultValue={button.position}
                value={button.position}
                placeholder={getLabelFromValue(button.position)}
                onChange={(item) => {
                  updateIndex(index, { ...button, position: item.value });
                }}
                options={positionOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "var(--theme-divider-color)",
                    color: "var(--theme-default-text-color)",
                    backgroundColor: "var(--bg-card)",
                    cursor: "pointer",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "var(--theme-heading-2-text-color)",
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "var(--theme-surface-color)",
                    borderColor: "var(--theme-divider-color)",
                  }),

                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      fontFamily: data.label,
                      padding: "5px 10px",
                      ...styles,
                      backgroundColor: isSelected
                        ? "var(--bg-primary)"
                        : "var(--theme-surface-color)",
                      cursor: "pointer",
                      color: "var(--text-secondary-foreground/85)",
                      ":hover": {
                        backgroundColor: "var(--theme-background-color)",
                        color: "var(--theme-heading-1-text-color)",
                      },
                    };
                  },
                }}
              />
            </div>
          </div>
          <div className="font-bold">
            <I18>Button URL</I18>
          </div>
          <TextInput
            placeholder="https://"
            className="h-9"
            defaultValue={button.url}
            onChange={(value) => {
              updateIndex(index, { ...button, url: value });
            }}
          />
        </div>
      ))}
    </div>
  );
}

export { CreatePostCustomButton };
