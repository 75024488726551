import cx from "classnames";
import { lazy, Suspense } from "react";
import useRecorder from "../../../../hooks/use-recorder.hook";
import audioBufferToWav from "../../../../_utils/recorder/audio-buffer-to-wav-converter";
import Validator from "../../../../_utils/validator";
import TextButton from "../../../atoms/text-button";
import { Button, cn } from "../../../button.component";
import ModalForm from "../../../model2";
import UIcon from "../../../uicon-component";
const LottiePlayer = lazy(() => import("../../../molecule/lottie-player"));

interface Props {
  className?: string;
  isRecorderVisible: boolean;
  setRecording: (rec: any) => void;
  setIsRecorderVisible: (isRecorderVisible: boolean) => void;
}
/**
 * Component for recording audio
 * @param {boolean} isRecorderVisible - if true, the recorder is visible
 * @param {function} setIsRecorderVisible - function to set the visibility of the recorder
 * @param {function} setRecording - function to set the recording
 */

const PostAudioRecorder = ({
  isRecorderVisible,
  setIsRecorderVisible,
  setRecording = () => {},
}: Props): JSX.Element => {
  let { audioURL, isRecording, startRecording, stopRecording, resetRecording } =
    useRecorder();

  if (!isRecorderVisible) {
    return <></>;
  }

  const audioContext = new window.AudioContext();
  // const fileReader = new FileReader();

  async function convertToBlob(audioURL: any) {
    // Generate audio file name
    const fileName = `recording-${Date.now()}.mp3`;
    await fetch(audioURL)
      .then((r) => {
        if (r.ok) {
          // Convert response to array buffer
          r.arrayBuffer().then((arrayBuffer) => {
            // Decode array buffer to audio buffer
            audioContext.decodeAudioData(
              arrayBuffer as ArrayBuffer,
              (audioBuffer) => {
                // Convert audio buffer to wav
                const wav = audioBufferToWav(audioBuffer);
                // Set the recording
                setRecording(
                  new File([new DataView(wav)], fileName, {
                    type: "audio/wav",
                    lastModified: Date.now(),
                  })
                );
              },
              (err) => {
                console.log(err);
              }
            );
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function confirm() {
    if (Validator.hasValue(audioURL)) {
      convertToBlob(audioURL);
      setIsRecorderVisible(false);
    }
    resetRecording();
  }

  return (
    <ModalForm
      visible={isRecorderVisible}
      setVisible={setIsRecorderVisible}
      title="Record"
      className="rounded-2xl"
      footer={
        <>
          {/* Bottom Buttons */}
          <div className="flex flex-row place-content-end gap-2 bg-card p-3">
            <TextButton
              label={"Cancel"}
              onClick={() => {
                startRecording();
                resetRecording();
                setIsRecorderVisible(false);
              }}
            />
            <Button
              label={"Confirm"}
              onClick={() => {
                confirm();
              }}
              disabled={isRecording || !Validator.hasValue(audioURL)}
            />
          </div>
        </>
      }>
      <div className="AudioRecorder">
        <section
          className={cx(
            "relative my-3 flex flex-col gap-2 rounded border-border p-4"
          )}>
          <audio
            src={audioURL}
            controls
            className={cx("my-6 w-full rounded-full py-1", {
              hidden: isRecording || !Validator.hasValue(audioURL),
            })}
          />

          <Suspense fallback={<></>}>
            <LottiePlayer
              src={"https://assets9.lottiefiles.com/packages/lf20_pzrstZ.json"}
              autoplay={true}
              loop={true}
              controlVisible={false}
              className={cx("mx-auto h-32 w-full scale-x-150", {
                hidden: !isRecording,
              })}
            />
          </Suspense>

          <div
            className={cx(
              "flex flex-col place-content-between gap-2 sm:flex-row"
            )}>
            <TextButton
              label="start recording"
              className={cn(
                cx(
                  "flex max-w-fit items-center gap-2 rounded-full px-2 outline outline-1",
                  {
                    "cursor-not-allowed text-secondary-foreground/70":
                      isRecording || Validator.hasValue(audioURL),
                    "cursor-pointer text-primary":
                      !isRecording && !Validator.hasValue(audioURL),
                  }
                )
              )}
              onClick={startRecording}
              disabled={isRecording || Validator.hasValue(audioURL)}
              prefix={<UIcon icon="play" solid={true} className="h-4" />}
            />
            <TextButton
              label="Stop recording"
              onClick={stopRecording}
              disabled={!isRecording}
              className={cx(
                "flex max-w-fit items-center gap-2 rounded-full px-2 outline outline-1",
                {
                  "cursor-not-allowed text-secondary-foreground/70":
                    !isRecording,
                  "cursor-pointer text-alert":
                    isRecording && !Validator.hasValue(audioURL),
                }
              )}
              prefix={<UIcon icon="stop" solid={true} className="h-4" />}
            />

            <TextButton
              label="Reset recording"
              onClick={() => {
                resetRecording();
              }}
              disabled={isRecording || !Validator.hasValue(audioURL)}
              className={cx(
                "flex max-w-fit items-center gap-2 rounded-full px-2 outline outline-1",
                {
                  "cursor-not-allowed text-secondary-foreground/70":
                    isRecording || !Validator.hasValue(audioURL),
                  "cursor-pointer text-primary":
                    !isRecording && Validator.hasValue(audioURL),
                }
              )}
              prefix={
                <UIcon icon="cross-circle" solid={true} className="h-4" />
              }
            />
          </div>
        </section>
      </div>
    </ModalForm>
  );
};

export default PostAudioRecorder;
