import React, { useEffect, useState } from "react";
import sessionTypes from "../../_constants/sessionTypes";
import visibilityTypes from "../../_constants/visibilityTypes";
import UIcon, { UIcons } from "../uicon-component";

interface Option {
  id?: string;
  checkboxName?: string;
  iconName?: UIcons;
  selectorName?: string;
  origin?: String;
  tooltips?: Tooltip[];
}
interface Tooltip {
  label?: string;
  description?: string;
}

interface Props {
  selectorTypeLabel?: string;
  setSelectedOption?: (selectedOption: any) => void;
  selectedOption?: any;
  options: Option[];
}

const CustomRadioComponent: React.FC<Props> = ({
  selectorTypeLabel = " ",
  setSelectedOption,
  selectedOption,
  options = [],
}) => {
  const [toolTipSelectedOption, setToolTipSelectedOption] =
    useState<Option>(selectedOption);
  const [hasChannelOrigin, setHasChannelOrigin] = useState(
    options.some((item) => item.origin === "CHANNEL")
  );

  useEffect(() => {
    let matchedTooltipsObject;
    matchedTooltipsObject = hasChannelOrigin
      ? Object.entries(visibilityTypes.CHANNEL_TOOLTIPS).find(
          ([key, { id }]) => selectedOption === id
        )
      : Object.entries(visibilityTypes.GROUP_TOOLTIPS).find(
          ([key, { id }]) => selectedOption === id
        );

    if (options.some((item) => item.origin === "SESSION_TYPE")) {
      matchedTooltipsObject = Object.entries(
        sessionTypes.SESSION_TOOLTIPS
      ).find(([key, { id }]) => selectedOption === id);
    }
    if (matchedTooltipsObject) {
      const [key, value] = matchedTooltipsObject;
      setToolTipSelectedOption(value);
    }
  }, [selectedOption, setSelectedOption]);

  return (
    <div className="flex-col">
      <div className="mt-4 text-xs font-bold" style={{ minHeight: "16px" }}>
        {selectorTypeLabel}
      </div>
      <div className="mt-2 flex flex-wrap gap-4">
        {options.map((option) => (
          <div className="flex-col" key={option.id}>
            <div
              key={option.id}
              className={`cursor-pointer items-center hover:bg-accent ${
                option.id === selectedOption && "bg-accent"
              }`}
              onClick={() => {
                setSelectedOption && setSelectedOption(option.id);
                const matchedTooltipsObject = hasChannelOrigin
                  ? Object.entries(visibilityTypes.CHANNEL_TOOLTIPS).find(
                      ([key, { id }]) => option.id === id
                    )
                  : Object.entries(visibilityTypes.GROUP_TOOLTIPS).find(
                      ([key, { id }]) => option.id === id
                    );
                if (matchedTooltipsObject) {
                  const [key, value] = matchedTooltipsObject;
                  setToolTipSelectedOption(value);
                }
              }}>
              <button
                type="button"
                className="h-10 rounded border border-border px-5"
                style={{ minWidth: "160px" }}>
                <div className="flex items-center justify-center gap-2">
                  {option.iconName && <UIcon icon={option.iconName} />}
                  {option.selectorName && <span>{option.selectorName}</span>}
                </div>
              </button>
            </div>
          </div>
        ))}
      </div>
      {toolTipSelectedOption && toolTipSelectedOption.tooltips?.length && (
        <div className="flex-col px-4 py-2">
          {toolTipSelectedOption.tooltips.map((tooltip: any) => (
            <div
              key={tooltip.label}
              className="flex gap-1 text-xs text-[#8C8C8C]">
              <p className="font-bold">{tooltip.label}</p>
              <p>: {tooltip.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomRadioComponent;
