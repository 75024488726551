import { useMemo } from "react";
import MEDIA_TYPES from "../../../../_constants/mediaType";

interface Props {
  videos: Array<any>[];
  setVideos: (videos: any[]) => void;
  setUploadedMedia: (media: Object) => void;
  onDelete: (video: any) => void;
  setRemovedMediaUploadsOnEdit: (previousMediaItems: any) => void;
  areFilesUploading: boolean;
}

function VideoPreviewWithImage({
  videos = [],
  setVideos = () => {},
  setUploadedMedia = () => {},
  onDelete = (image) => {},
  setRemovedMediaUploadsOnEdit = () => {},
  areFilesUploading,
}: Props) {
  const VideoRender = useMemo(
    () => (
      <>
        {videos?.length > 0 && (
          <div className="SelectedVideo w-full">
            <div className="my-2 flex flex-wrap gap-4">
              {videos.map((video: any, index: number) => {
                return (
                  <div key={index} className="">
                    <div className="relative">
                      <video
                        src={
                          typeof video === "string"
                            ? video
                            : URL.createObjectURL(video)
                        }
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        className={`${areFilesUploading ? "hidden" : "remove"}`}
                        onClick={(_e) => {
                          // remove the videos
                          const oldVideos = [...videos];
                          setRemovedMediaUploadsOnEdit(
                            (prevMediaItems: any) => [
                              ...prevMediaItems,
                              { mediaItem: videos[0], type: MEDIA_TYPES.VIDEO },
                            ]
                          );
                          oldVideos.splice(index, 1);
                          setVideos(oldVideos);
                          onDelete(videos[index]);
                          setUploadedMedia((prevUploadedMedia: any) => ({
                            ...prevUploadedMedia,
                            videos: oldVideos,
                          }));
                        }}>
                        &times;
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [videos.length, areFilesUploading]
  );

  if (!videos || videos.length === 0) {
    return null;
  }

  return <div className="SelectedVideoPreview">{VideoRender}</div>;
}

export { VideoPreviewWithImage };
