import cx from "classnames";
import UIcon, { UIcons } from "./uicon-component";

interface Props {
  icon: UIcons;
  onClick?: (e: any) => void;
  className?: string;
  iconClass?: string;
  solid?: boolean;
  label?: String;
  hoverable?: boolean;
  size?: "xxs" | "xs" | "sm" | "base" | "lg" | "md" | "xl" | "2xl" | "3xl";
  unreadCount?: number;
}
function IconButton2({
  icon,
  onClick,
  className,
  iconClass = "",
  label,
  solid = false,
  hoverable,
  size = "xl",
  unreadCount = 0,
}: Props) {
  return (
    <div
      onClick={(e) => {
        if (onClick != null) {
          onClick(e);
        }
      }}
      className={cx(
        `relative flex h-4 cursor-pointer place-content-center items-center rounded-full ${className}`,
        { "hover:shadow": hoverable }
      )}>
      <UIcon
        icon={icon as any}
        className={` ${iconClass} `}
        solid={solid}
        size={size}
      />
      {label && <span className="ml-1 text-xs">{label}</span>}
      {unreadCount > 0 && (
        <span className="absolute -top-[10px] left-[9px] flex h-[15px] w-[15px] place-content-center items-center rounded-full bg-primary p-[3px] text-[10px] text-primary-foreground">
          {unreadCount}
        </span>
      )}
    </div>
  );
}
export default IconButton2;
