import { useState } from "react";
import useModel from "../../../hooks/use-model.hook";
import { Post } from "../../../types/post/post.type";
import Validator from "../../../_utils/validator";
import { CloseButton } from "../../activity/close-button.component";
import { Avatar } from "../../avatar.component";
import { ListView } from "../../molecule/listview.component";
import Label from "../../typography/label.typography";

interface PollResultProps {
  post: Post;
  setDisplayStatics: (value: boolean) => void;
}
/**
 * Component to display poll result
 * @param {Post} post
 * @param {Function} setDisplayStatics - function to close the statics modal
 * @returns
 */
export default function PollResult({
  post,
  setDisplayStatics = () => {},
}: PollResultProps) {
  const isSharedPost = Validator.hasValue(post.shared);
  const poll = isSharedPost ? post?.shared?.poll : post.poll;

  if (!poll) return null;

  const result =
    poll &&
    poll.answers &&
    poll.answers.map((answer: any) => {
      return {
        pollOption: answer.submissions[0],
        user: answer.user,
      };
    });

  const formatted =
    result &&
    result.reduce((acc: any, curr: any) => {
      const key = curr.pollOption.option;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr.user);
      return { ...acc, [key]: acc[key] };
    }, {});

  return (
    <div className="PollResult gpa-2 flex flex-col bg-background">
      <div className="flex items-center px-4 py-2">
        <Label size="h3" variant="t1" translate className="flex-1">
          Poll Result
        </Label>
        <CloseButton
          onClick={() => {
            setDisplayStatics(false);
          }}
        />
      </div>
      <div className="flex w-full flex-col gap-1">
        <Label size="h3" variant="t2" className="px-4">
          {poll.questions[0].statement}
        </Label>
        <div className="flex w-full flex-col gap-1 bg-card p-2">
          <Label variant="t2" className="p-2 px-4">
            {poll.voteCount} votes
          </Label>
          <ListView
            items={poll.questions[0].options}
            className="PollOptions flex w-full flex-col gap-3 px-4"
            renderItem={(option, index) => (
              <PollResultSubmission
                poll={poll}
                option={option}
                users={formatted && formatted[option]}
                index={index}
              />
            )}
          />
        </div>
      </div>
    </div>
  );

  /**
   * Display poll option and users who voted for it
   * @param {*} param0
   * @returns
   */
  function PollResultSubmission({ poll, option, index, users }: any) {
    const [isExpand, setIsExpand] = useState(true);
    const {
      profileModelState: { setShowProfileModel, setIdToViewProfile },
    } = useModel();
    return (
      <div className="rounded border border-border">
        <div
          className="flex w-full cursor-pointer items-center rounded bg-background p-3 hover:bg-card"
          onClick={() => {
            setIsExpand(!isExpand);
          }}>
          <Label variant="t2">{index + 1}.&nbsp;&nbsp;</Label>
          <Label className="flex-1">{option}</Label>

          <Label variant="t2">
            {Math.floor(poll.votes[option] * poll.voteCount)} &nbsp; Members
          </Label>
        </div>
        {isExpand && (
          <ListView
            items={users}
            className="flex w-full cursor-pointer flex-col gap-1 divide-y divide-[var(--theme-border-color)]"
            renderItem={(user) => (
              <div className="p-2">
                <Avatar
                  user={user}
                  onClick={() => {
                    // history.push(createUserWallPageRoute(user.id));
                    setIdToViewProfile(user.id);
                    setShowProfileModel(true);
                  }}
                  extraInfo={undefined}
                />
              </div>
            )}
          />
        )}
      </div>
    );
  }
}
