const SESSION_TYPE = {
  VIDEO_CALL: "meeting",
  WEBINAR: "webinar",
  AUDIO_ROOM: "audio",
};

const CALL_TYPE = {
  RIGHT_NOW: "right-now",
  SCHEDULE_LATER: "schedule-later",
};

const SESSION_TOOLTIPS = {
  VIDEO_CALL: {
    id: SESSION_TYPE.VIDEO_CALL,
    tooltips: [
      {
        label: "Participation",
        description: " Everyone can switch on their mic and camera",
      },
    ],
  },
  WEBINAR: {
    id: SESSION_TYPE.WEBINAR,
    tooltips: [
      {
        label: "Participation",
        description:
          " Only host can present. Members invited to stage can present",
      },
    ],
  },
  AUDIO_ROOM: {
    id: SESSION_TYPE.AUDIO_ROOM,
    tooltips: [
      {
        label: "Participation",
        description: "Everyone can switch on mic and speak",
      },
    ],
  },
};

export default { SESSION_TYPE, SESSION_TOOLTIPS, CALL_TYPE };
