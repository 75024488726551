/**
 * Tooltip component with optional hoverable component
 * @param {React.ReactNode} children - The content to display in the tooltip
 * @param {React.ReactNode} hoverableComponent - The content to display when the user hovers over the tooltip
 * @param {string} className - Additional class names to apply
 */
interface SubscriptionToolTipProps {
  children: React.ReactNode;
  hoverableComponent?: React.ReactNode;
  className?: string;
}
export function SubscriptionToolTip({
  children,
  hoverableComponent,
  className = "",
}: SubscriptionToolTipProps) {
  return (
    <div className={`tooltip ${className} transition duration-75 ease-in-out`}>
      <div className="label invisible absolute right-8 z-50 mt-6 flex flex-col items-center opacity-0 transition-all">
        {hoverableComponent && (
          <div className="z-0 text-xs text-secondary-foreground">
            {hoverableComponent}
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
}
