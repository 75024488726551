import cx from "classnames";
import { debounce } from "lodash";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Remarkable } from "remarkable";
import TurndownService from "turndown";
import { withToast } from "../../contexts/toastr.context";
import { useAppService } from "../../hooks/use-app-service";
import behanceIcon from "../../_assets/vectors/behance.svg";
import dribbbleIcon from "../../_assets/vectors/dribbble.svg";
import facebookIcon from "../../_assets/vectors/facebook.svg";
import GlobeIcon from "../../_assets/vectors/globe-icon.svg";
import instagramIcon from "../../_assets/vectors/instagram.svg";
import linkedinIcon from "../../_assets/vectors/linkedin.svg";
import pinterestIcon from "../../_assets/vectors/pinterest.svg";
import twitterIcon from "../../_assets/vectors/twitter.svg";
import youtubeIcon from "../../_assets/vectors/youtube.svg";
import constants from "../../_config/constants.config";
import { DEFAULT_PIC_FROM_NAME_ENDPOINT } from "../../_constants/mediaType";
import { CommunityService, UserService } from "../../_service";
import AnalyticsService, { EventType } from "../../_service/analytics-service";
import { createSession } from "../../_store/_actions/auth.actions";
import MainUtil from "../../_utils/main.util";
import { CloseButton } from "../activity/close-button.component";
import I18 from "../atoms/i18";
import { Button } from "../button.component";
import { ErrorDiv } from "../error.component";
import { FileInput } from "../form-controls/file-input";
import { Tick } from "../form-controls/tick-component";
import IconButton2 from "../icon-button-2-component";
import ImageCropModal from "../image-crop/image-crop-modal";
import { Loader } from "../loader.component";
import { ListView } from "../molecule/listview.component";
import { TextInput } from "../text-input.component";
import UIcon from "../uicon-component";
const RichTextEditor = React.lazy(
  () => import("../form-controls/rich-text-editor.component")
);
const tds = new TurndownService();

export default function ProfileUpdateComponent({
  user,
  community,
  setUser,
  visible = false,
  isFullScreen = true,
  setVisible = (e) => {},
  addToast = (e) => {},
}) {
  const isUserNotNew = user?.name !== "New User";
  const { analyticsService } = useAppService();

  const [isFormSubmittable, setIsFormSubmittable] = useState(
    isUserNotNew ? true : false
  ); // to disable submit button
  const [name, setName] = useState("");
  const [shortBio, setShortBio] = useState("");
  const [userId, setUserId] = useState("");
  const [bio, setBio] = useState("");
  // Social State
  const [instagramLink, setInstagramLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [pintrestLink, setPintrestLink] = useState("");
  const [dribbbleLink, setDribbbleLink] = useState("");
  const [behanceLink, setBehanceLink] = useState("");
  const [webLink, setWebsiteLink] = useState("");

  const [picture, setPicture] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isCheckingForAvailability, setIsCheckingForAvailability] =
    useState(false);
  const [userIdAvailable, setUserIdAvailable] = useState(true);

  const [fetchingUserProfile, setFetchingUserProfile] = useState(false);
  const [isSocialDropdownVisible, setIsSocialDropdownVisible] = useState(false);
  const [defaultSocialMedia, setDefaultSocialMedia] = useState([]);
  const [isUpdateProfileDisabled, setIsUpdateProfileDisabled] = useState(false);

  const md = new Remarkable();

  const [customSocialMedia, setCustomSocialMedia] = useState([
    {
      name: "Instagram",
      icon: instagramIcon,
      isAvailable: false,
      setValue: "instagramLink",
    },
    {
      name: "Linkedin",
      icon: linkedinIcon,
      isAvailable: false,
      setValue: "linkedinLink",
    },
    {
      name: "Youtube",
      icon: youtubeIcon,
      isAvailable: false,
      setValue: "youtubeLink",
    },
    {
      name: "Twitter",
      icon: twitterIcon,
      isAvailable: false,
      setValue: "twitterLink",
    },
    {
      name: "Facebook",
      icon: facebookIcon,
      isAvailable: false,
      setValue: "facebookLink",
    },
    {
      name: "Pintrest",
      icon: pinterestIcon,
      isAvailable: false,
      setValue: "pintrestLink",
    },
    {
      name: "Dribbble",
      icon: dribbbleIcon,
      isAvailable: false,
      setValue: "dribbbleLink",
    },
    {
      name: "Behance",
      icon: behanceIcon,
      isAvailable: false,
      setValue: "behanceLink",
    },
    {
      name: "Website",
      icon: GlobeIcon,
      isAvailable: false,
      setValue: "webLink",
    },
  ]);

  const setIsProfileUpdateDisabledForUsers = async () => {
    const communityConfigurableData = await CommunityService.getConfigurable(
      community?.id,
      constants.COMMUNITY_PREVENT_USER_PROFILE_UPDATE
    );
    setIsUpdateProfileDisabled(
      communityConfigurableData?.configurable?.configurableValue === "true"
        ? true
        : false
    );
  };
  useEffect(() => {
    if (!isSocialDropdownVisible) {
      return;
    }

    const closeMenuListener = (e) => {
      setIsSocialDropdownVisible(true);
    };

    window.document.body.addEventListener("click", closeMenuListener);

    return () => {
      window.document.body.removeEventListener("click", closeMenuListener);
    };
  }, [isSocialDropdownVisible]);
  // get user profile
  useEffect(() => {
    if (isUserNotNew || visible) {
      setFetchingUserProfile(true);
      UserService.getUserProfile(user)
        .then(({ user: userProfile }) => {
          const newUserObj = {
            ...userProfile,
            token: user.token,
            email: userProfile.email,
            mobile: userProfile.mobile,
          };
          setUser(newUserObj);
          localStorage.setItem("pensil.user", JSON.stringify(newUserObj));
          setFetchingUserProfile(false);
        })
        .catch((error) => {
          setFetchingUserProfile(false);
          setErrorMessage(error.message);
          if (MainUtil.checkIfBlocked(error)) {
            console.log("[Block] Unauthorize error ");
          }
        });
      setIsProfileUpdateDisabledForUsers();
    }
  }, [isUserNotNew, visible]);

  useEffect(() => {
    setName(isUserNotNew ? user.name : "");
    setShortBio(isUserNotNew ? user.shortBio : "");
    setUserId(user.userId ? user.userId : "");
    setUserIdAvailable(user.userId);
    setBio(user.bio ? md.render(user.bio) : "");
    setPicture(user.picture);
    setInstagramLink(user.instagramLink ? user.instagramLink : "");
    setLinkedinLink(user.linkedinLink ? user.linkedinLink : "");
    setYoutubeLink(user.youtubeLink ? user.youtubeLink : "");
    setTwitterLink(user.twitterLink ? user.twitterLink : "");
    setFacebookLink(user.facebookLink ? user.facebookLink : "");
    setPintrestLink(user.pintrestLink ? user.pintrestLink : "");
    setDribbbleLink(user.dribbbleLink ? user.dribbbleLink : "");
    setBehanceLink(user.behanceLink ? user.behanceLink : "");
    setWebsiteLink(user.webLink ? user.webLink : "");

    updateSocial();
  }, [user, isUserNotNew, visible]);

  const updateSocial = () => {
    customSocialMedia.forEach((element) => {
      if (eval(element.setValue).length >= 1 && !element.isAvailable) {
        element.isAvailable = true;
        setDefaultSocialMedia([...defaultSocialMedia, element]);
      } else if (eval(element.setValue).length <= 0 && element.isAvailable) {
        element.isAvailable = false;
        setDefaultSocialMedia([...defaultSocialMedia, element]);
      }
    });
    if (customSocialMedia.length > 0) {
      let sampleDefultSM = customSocialMedia.filter((dfs) => dfs.isAvailable);
      setDefaultSocialMedia(sampleDefultSM);
    }
  };

  // debounced check for userId availability
  const checkForAddressAvailability = useCallback(
    debounce((userId) => {
      // if userId is
      if (/^[A-Za-z0-9_.]{3,63}$/.test(userId)) {
        setIsCheckingForAvailability(true);
        // check for address
        UserService.checkForUserIdAvailability(userId, user)
          .then(({ userIdAvailable }) => {
            // set if the address is available
            setUserIdAvailable(userIdAvailable);
            setIsCheckingForAvailability(false);
          })
          .catch((error) => {
            console.log({ error });
            setUserIdAvailable(false);
            setIsCheckingForAvailability(false);
          });
      }
    }, 1000),
    []
  );

  if (isUserNotNew && !visible) return <></>;

  return (
    <>
      <div
        className={cx("ProfileUpdate bg-card", {
          "flex items-center justify-center overflow-scroll": isFullScreen,
        })}
        style={{
          position: isFullScreen ? "fixed" : "",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 900,
        }}>
        <div className="flex items-center justify-center">
          <div className="w-96 p-4 sm:p-0">
            <div className="py-6">
              {isUserNotNew ? (
                <>
                  {isFullScreen && (
                    <div className="flex justify-end">
                      <IconButton2
                        hoverable
                        icon="cross"
                        size="md"
                        onClick={() => {
                          setVisible(false);
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center text-lg font-semibold text-secondary-foreground/85">
                  <I18>Hi, tell us a little bit about yourself?</I18>
                </div>
              )}
              <PictureUpdate
                picture={picture}
                setPicture={setPicture}
                isUserNotNew={isUserNotNew}
                name={name}
              />
              <div className="mt-4 font-semibold text-secondary-foreground/85">
                <span>Your name</span>
              </div>
              <TextInput
                placeholder="Name"
                validator={(value) => {
                  if (value.length < 2) {
                    setIsFormSubmittable(false);
                    return "Name invalid!";
                  } else if (value.length > 50) {
                    setIsFormSubmittable(false);
                    return "Name should be less than 50 characters";
                  } else {
                    setIsFormSubmittable(true);
                  }
                }}
                defaultValue={name}
                noBorder
                className="mb-1 border border-border bg-background py-2 focus:outline-none"
                onChange={(value) => {
                  setName(value);
                }}
                disabled={
                  isUserNotNew &&
                  isUpdateProfileDisabled &&
                  !(community.myRole === "admin")
                }
              />
              <div className="mt-4 flex items-end gap-2">
                <span className="font-semibold text-secondary-foreground/85">
                  Subtitle
                </span>
                <span className="text-xxs font-normal text-secondary-foreground/80">
                  (Max char 60)
                </span>
              </div>
              <TextInput
                placeholder="Subtitle"
                validator={(value) => {
                  if (value.length < 2) {
                    setIsFormSubmittable(false);
                    return "Subtitle should be more than 2 characters";
                  } else if (value.length > 60) {
                    setIsFormSubmittable(false);
                    return "Subtitle should be less than 60 characters";
                  } else {
                    setIsFormSubmittable(true);
                  }
                }}
                defaultValue={shortBio}
                noBorder
                className="mb-1 border border-border bg-background py-2 focus:outline-none"
                onChange={(value) => {
                  setShortBio(value);
                }}
              />
              <div className="mt-4 flex items-center font-semibold text-secondary-foreground/85">
                <span>
                  <I18>User Id</I18>
                </span>
                <div className="ml-4 flex items-center">
                  {isCheckingForAvailability ? (
                    <Loader />
                  ) : (
                    <span className="py-1">
                      <Tick success={userIdAvailable} />
                    </span>
                  )}
                </div>
              </div>
              <TextInput
                placeholder="User Id (Username)"
                validator={(value) => {
                  if (value.length < 5) {
                    setIsFormSubmittable(false);
                    return "User id invalid!";
                  } else {
                    setIsFormSubmittable(true);
                  }
                  return undefined;
                }}
                noBorder
                defaultValue={userId}
                className="border border-border bg-background py-2 focus:outline-none"
                inputHolderClassName={cx({
                  " border-red-500": !userIdAvailable,
                  " border-green-500": userIdAvailable,
                })}
                onChange={(value) => {
                  setUserId(value);
                  if (value.length > 2) {
                    if (!isCheckingForAvailability)
                      setIsCheckingForAvailability(true);
                  } else {
                    setIsCheckingForAvailability(false);
                  }
                  checkForAddressAvailability(value);
                }}
                disabled={
                  isUserNotNew &&
                  isUpdateProfileDisabled &&
                  !(community.myRole === "admin")
                }
              />
              <div className="mt-4 font-semibold text-secondary-foreground/85">
                <I18>Short Bio</I18>
              </div>
              <Suspense fallback={<></>}>
                <RichTextEditor
                  text={bio}
                  setText={setBio}
                  uploadImage={false}
                  insertTable={false}
                  className="bg-background"
                />
              </Suspense>

              <div className="mb-1 mt-4 font-semibold text-secondary-foreground/85">
                <I18>Link your social account</I18>
                <div className="text-xs text-secondary-foreground/70">
                  <I18>This will be displayed on your profile</I18>
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <div
                  className="text-primary"
                  onClick={() => {
                    if (defaultSocialMedia.length < 9) {
                      setIsSocialDropdownVisible(!isSocialDropdownVisible);
                    }
                  }}>
                  <span className="cursor-pointer">+ Add social handles</span>
                </div>
                {/* SOCIAL MEDIA DROP DOWN */}
                <div
                  className={
                    isSocialDropdownVisible
                      ? "dropdown visible relative left-0 my-2 w-1/2 border border-border bg-card py-2 shadow"
                      : "dropdown my-2 hidden w-1/2 bg-background py-2 shadow-lg"
                  }
                  // style={{ position: "absolute", top: "10%", right: "110%" }}
                >
                  <ListView
                    items={customSocialMedia}
                    renderItem={(socialData, index) => (
                      <div
                        className={cx(
                          "dropdown-button flex cursor-pointer items-center p-1 text-secondary-foreground/90 hover:bg-background " +
                            "transition-transform duration-300 ease-in-out hover:scale-105"
                        )}
                        onClick={() => {
                          socialData.isAvailable = !socialData.isAvailable;
                          setDefaultSocialMedia([
                            ...defaultSocialMedia,
                            socialData,
                          ]);
                          setIsSocialDropdownVisible(!isSocialDropdownVisible);
                        }}
                        style={{
                          display: socialData.isAvailable ? "none" : "flex",
                        }}
                        key={index}>
                        <div className="flex-shrink-0">
                          <img
                            src={socialData.icon}
                            className="ml-2"
                            width="25"
                            alt=""
                          />
                        </div>
                        <div className="px-2">{socialData.name}</div>
                      </div>
                    )}
                  />
                </div>
              </div>

              {/* SOCIAL MEDIA LINKS */}
              {defaultSocialMedia.map((dfs, index) => {
                return (
                  <>
                    <TextInput
                      prefix={
                        <img
                          src={dfs.icon}
                          className="ml-2"
                          width="25"
                          alt=""
                        />
                      }
                      noPrefixSeparator
                      postfix={
                        eval(`${dfs.setValue}`) && (
                          <CloseButton
                            size="sm"
                            className="mr-1"
                            onClick={() => {
                              eval(`set${dfs.name}Link("")`);
                            }}
                          />
                        )
                      }
                      placeholder={`${dfs.name} Url`}
                      noBorder
                      validator={(value) => {
                        if (
                          value.startsWith("http://") ||
                          value.startsWith("https://") ||
                          value.length === 0
                        ) {
                          setIsFormSubmittable(true);
                        } else {
                          setIsFormSubmittable(false);
                          return `${dfs.name} Url invalid!`;
                        }
                      }}
                      validationOutsideBox
                      noMargin
                      defaultValue={eval(`${dfs.setValue}`)}
                      containerClassName="py-2 focus:outline-none border border-border bg-background rounded mt-4"
                      onChange={(value) => {
                        let functionName = `set${dfs.name}Link("${value}")`;
                        console.log({ functionName });
                        if (value.length == 0) {
                          dfs.isAvailable = false;
                        }
                        eval(functionName);
                      }}
                      key={index}
                    />
                  </>
                );
              })}

              <div className="mt-4">
                <Button
                  disabled={!isFormSubmittable}
                  className="w-full items-center py-2"
                  onClick={async () => {
                    setIsLoading(true);
                    const embedLessBio = await bio.replace(
                      /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
                      " $1 "
                    );
                    let finalBio = await tds.turndown(embedLessBio);

                    UserService.updateProfile(user, {
                      name,
                      shortBio,
                      userId,
                      bio: finalBio,
                      instagramLink,
                      linkedinLink,
                      youtubeLink,
                      twitterLink,
                      facebookLink,
                      pintrestLink,
                      dribbbleLink,
                      behanceLink,
                      webLink,
                    })
                      .then((response) => {
                        if (
                          picture &&
                          typeof picture === "string" &&
                          picture.startsWith("http")
                        ) {
                          // we dont have a picture to upload
                          setIsLoading(false);
                          // update user
                          setUser({
                            ...user,
                            name,
                            bio,
                            shortBio,
                            instagramLink,
                            linkedinLink,
                            youtubeLink,
                            twitterLink,
                            facebookLink,
                            pintrestLink,
                            dribbbleLink,
                            behanceLink,
                            webLink,
                          });
                          // update the localuser
                          localStorage.setItem(
                            "pensil.user",
                            JSON.stringify({ ...user, name })
                          );
                          // if user not new, close this page
                          if (isUserNotNew) {
                            setVisible(false);
                          }
                          // show toast of success
                          addToast("Profile Updated!");

                          // mix panel tracking
                          if (!isUserNotNew) {
                            AnalyticsService.logEvent(
                              EventType.User.name,
                              EventType.User.create
                            );
                          }
                          console.log(
                            analyticsService.track("profile-details", {
                              profilePic: picture,
                              bio: bio,
                              name: name,
                              socialhandles: JSON.stringify({
                                instagramLink,
                                linkedinLink,
                                youtubeLink,
                                twitterLink,
                                facebookLink,
                                pintrestLink,
                                dribbbleLink,
                                behanceLink,
                              }),
                            })
                          );
                        } else if (!picture) {
                          UserService.deleteProfilePicture(user)
                            .then((response) => {
                              setIsLoading(false);
                              setUser({ ...user, name, bio, shortBio });
                              setPicture(response.user.picture);
                              localStorage.setItem(
                                "pensil.user",
                                JSON.stringify({
                                  ...user,
                                  name,
                                  picture: response.user.picture,
                                })
                              );
                              window.location.reload();
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        } else {
                          // upload picture and then update the user
                          UserService.updatePicture(user, picture)
                            .then((response) => {
                              // we dont have a picture to upload
                              setIsLoading(false);
                              // update user
                              setUser({ ...user, name, bio, shortBio });
                              // update the localuser
                              localStorage.setItem(
                                "pensil.user",
                                JSON.stringify({
                                  ...user,
                                  name,
                                  picture: response.user.picture,
                                })
                              );
                              // if user not new, close this page
                              if (isUserNotNew) {
                                setVisible(false);
                              }

                              // mix panel tracking
                              analyticsService.track("profile-details", {
                                profilePic: picture,
                                bio: bio,
                                name: name,
                                socialhandles: JSON.stringify({
                                  instagramLink,
                                  linkedinLink,
                                  youtubeLink,
                                  twitterLink,
                                  facebookLink,
                                  pintrestLink,
                                  dribbbleLink,
                                  behanceLink,
                                }),
                              });
                            })
                            .catch((error) => {
                              if (
                                error &&
                                error.response &&
                                error.response.data.message
                              ) {
                                setErrorMessage(
                                  error.response.data.errors.file[0]
                                );
                              }
                              setIsLoading(false);

                              // mix panel tracking
                              analyticsService.track("profile-details", {
                                profilePic: picture,
                                bio: bio,
                                socialhandles: JSON.stringify({
                                  instagramLink,
                                  linkedinLink,
                                  youtubeLink,
                                  twitterLink,
                                  facebookLink,
                                  pintrestLink,
                                  dribbbleLink,
                                  behanceLink,
                                }),
                              });
                            });
                        }
                      })
                      .catch((error) => {
                        if (
                          error &&
                          error.response &&
                          error.response.data.message
                        ) {
                          if (error.response.data.errors.name) {
                            setErrorMessage(error.response.data.errors.name[0]);
                          }
                          if (error.response.data.errors.userId) {
                            setErrorMessage(
                              error.response.data.errors.userId[0]
                            );
                            setUserIdAvailable(false);
                          }
                        }
                        setIsLoading(false);
                      });
                  }}
                  isLoading={isLoading}
                  label={isUserNotNew ? "Update" : "Proceed"}
                />
              </div>
              <ErrorDiv error={errorMessage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const ProfileUpdate = withToast(
  connect(
    (s) => ({ user: s.auth, community: s.community }),
    (d) =>
      bindActionCreators(
        {
          setUser: createSession,
        },
        d
      )
  )(ProfileUpdateComponent)
);

function PictureUpdate({
  picture = null,
  setPicture = () => {},

  name,
  isUserNotNew,
}) {
  const [activeCrop, setActiveCrop] = useState(false);
  const [src, selectFile] = useState(null);

  let imageUrl = "";
  try {
    if (picture && typeof picture === "string" && picture.startsWith("http")) {
      imageUrl = picture;
    } else {
      imageUrl = URL.createObjectURL(picture);
    }
  } catch (error) {
    imageUrl = picture;
  }

  const onFileSelect = async (file) => {
    if (file) {
      let image = await URL.createObjectURL(file);
      selectFile(image);
    } else {
      selectFile(null);
    }
    setActiveCrop(true);
  };

  return (
    <div className="PictureUpdate">
      {src && activeCrop && (
        <ImageCropModal
          src={src}
          setPicture={(picture) => {
            setPicture(picture);
          }}
          activeCrop={activeCrop}
          setActiveCrop={setActiveCrop}
          aspectRatio={1}
          cropRatioMessage="Picture ratio - 1:1"
        />
      )}
      <div>
        <div onClick={() => setPicture(DEFAULT_PIC_FROM_NAME_ENDPOINT + name)}>
          {isUserNotNew &&
            !(typeof picture === "string" && typeof imageUrl === "string") && (
              <div className="flex flex-col items-end">
                <UIcon
                  icon="cross"
                  size="xs"
                  className="text-secondary-foreground"
                />
              </div>
            )}
        </div>

        <FileInput accept="image/*" onChange={onFileSelect}>
          <div
            className={`preview-control ${!isUserNotNew ? "flex place-content-center items-center border border-border" : ""}`}>
            {!isUserNotNew && imageUrl.includes("seed") ? (
              <UIcon
                icon="user"
                size="3xl"
                className="text-secondary-foreground"
              />
            ) : isUserNotNew &&
              typeof picture === "string" &&
              typeof imageUrl === "string" ? (
              <img className="h-full w-full" src={picture} alt="" />
            ) : (
              <img src={imageUrl} alt="" />
            )}
          </div>
        </FileInput>

        <FileInput accept="image/*" onChange={onFileSelect}>
          <div className="pt-2 text-center text-sm font-semibold text-primary">
            Set Profile Picture
          </div>
        </FileInput>
      </div>
    </div>
  );
}
