import "./message-card.style.scss";

import cx from "classnames";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { Remarkable } from "remarkable";
import useModel from "../../hooks/use-model.hook";
import { MessageService } from "../../_service/message.service";
import { RealtimeSectionPinnedMessageService } from "../../_service/realtime-section-pinned-message.service";
import I18 from "../atoms/i18";
import { Avatar } from "../avatar.component";
import EmbedLinkModal from "../embed-link-modal.component";
import EmojiPickerV2 from "../form-controls/emoji-picker-v2.component";
import IconMenu2 from "../icon-menu-2.component";
import { Loader } from "../loader.component";
import PopupImageViewer from "../popup-image-viewer.component";
import PopupVideoPlayer from "../popup-video-player.component";
import { PostLinkMetaCard } from "../post/post-card.component";
import ReactionPills from "../reactions/reaction-pills.component";
import UIcon from "../uicon-component";
import { EditMessageBox } from "./edit-message-box.component";
import { ThreadBox } from "./thread-box-component";

function MessageCardComponent({
  highlightsPinned,
  message,
  activeTabModel,
  user,
  group,
  updateMessage = (message) => {},
  deleteMessage = (message) => {},
  noMargin = false,
  hideUserDetails = false,
  nextMessageIsBySameUser = false,
  isOnSameDay = false,
  isNotContinued = false,
  isUserAllowedToPost = false,
}) {
  const md = new Remarkable();

  const [isProcessing, setIsProcessing] = useState(false);
  const [areCommentsLoading, setAreCommentsLoading] = useState(false);

  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);

  const [isBeingEdit, setIsBeingEdit] = useState(false); // TODO: add update message card

  const [showReplies, setShowReplies] = useState(false);

  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();

  const openUserPage = (e) => {
    e.preventDefault();
    // window.open(createUserWallPageRoute(message.createdBy.id));
    setIdToViewProfile(message.createdBy.id);
    setShowProfileModel(true);
  };

  return (
    <>
      {/* date divider */}
      {isOnSameDay ? null : (
        <div className="date-divider flex">
          <div className="w-full bg-accent p-[1px]"></div>
          <div className="bg-accent px-2 py-1" style={{ minWidth: "96px" }}>
            <div className="text-xxs ml-1.5 text-secondary-foreground">
              {moment(message.createdAt).format("MMM DD, YYYY")}
            </div>
          </div>
          <div className="w-full bg-accent p-[1px]"></div>
        </div>
      )}
      {/* message video */}
      <div
        className={cx(
          "RealtimePostCard flex items-start px-3 pt-1 hover:bg-accent/70",
          {
            "bg-background": highlightsPinned === message.id,
            "my-3": !noMargin && !hideUserDetails && !isOnSameDay,
            "mb-0": nextMessageIsBySameUser,
            "gap-2": isNotContinued,
          }
        )}>
        {/* user avatar */}
        {!isOnSameDay || showReplies || isNotContinued || !hideUserDetails ? (
          <Avatar
            onClick={openUserPage}
            className="w-10 min-w-[40px] max-w-[40px] cursor-pointer"
            noName
            user={{ ...message.createdBy }}
          />
        ) : (
          <span className="w-12 flex-shrink-0">
            <span className="text-xxs msg-time text-secondary-foreground/70">
              {moment(message.createdAt).format("HH:mm")}
            </span>
          </span>
        )}
        {/* post body */}
        <div className="flex flex-grow flex-col">
          <div className="flex items-center justify-between">
            {/* name and time */}
            {!hideUserDetails || !isOnSameDay || isNotContinued ? (
              <div className="flex items-center">
                <div
                  onClick={openUserPage}
                  className="cursor-pointer font-semibold text-secondary-foreground">
                  {message.createdBy.name}
                </div>
                <span className="text-xxs ml-2 text-secondary-foreground/80">
                  {moment(message.createdAt).format("HH:mm")}
                </span>
              </div>
            ) : (
              <></>
            )}
            {/* options */}
          </div>
          <div className="relative">
            {isBeingEdit ? (
              <EditMessageBox
                message={message}
                onUpdate={(message) => {
                  setIsBeingEdit(false);
                  updateMessage(message);
                }}
                cancelUpdate={() => {
                  setIsBeingEdit(false);
                }}
              />
            ) : (
              <>
                <div
                  className={`NL2BR headings break-words text-secondary-foreground`}
                  dangerouslySetInnerHTML={{
                    __html: md.render(message.description),
                  }}
                />
                {message.isEdited ? (
                  <span className="pl-2 text-secondary-foreground/70">
                    <I18>(edited)</I18>
                  </span>
                ) : (
                  <></>
                )}
              </>
            )}
            {!isBeingEdit ? (
              <div
                className={cx(
                  "realtimepost-options cursor-pointer rounded border border-border",
                  { hideUserDetails: hideUserDetails }
                )}>
                <PostActions
                  post={message}
                  activeTabModel={activeTabModel}
                  group={group}
                  updatePost={updateMessage}
                  deletePost={deleteMessage}
                  areCommentsLoading={areCommentsLoading}
                  setCommentsLoading={setAreCommentsLoading}
                  setEmbedModalVisible={setEmbedModalVisible}
                  isBeingEdit={isBeingEdit}
                  setIsBeingEdit={setIsBeingEdit}
                  setIsProcessing={setIsProcessing}
                  showReplies={showReplies}
                  setShowReplies={setShowReplies}
                  isEmojiPickerVisible={isEmojiPickerVisible}
                  setIsEmojiPickerVisible={setIsEmojiPickerVisible}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* post attachments */}
          <MessageAttachments message={message} />
          <PostLinkMetaCard post={message} />
          {/* post processing */}
          <PostProcessing isProcessing={isProcessing} />
          {/* embed modal */}
          <EmbedLinkModal
            link={
              process.env.REACT_APP_EMBED_URL +
              (window.location.port ? ":" + window.location.port : "") +
              "/post/" +
              message.id
            }
            active={isEmbedModalVisible}
            setActive={setEmbedModalVisible}
          />
        </div>
      </div>

      <div className="my-2 mb-4 ml-[60px] flex flex-col gap-1">
        {/* left options */}
        {message.reactions?.total ? (
          <div className="mt-2 flex items-center">
            {/* emoji picker */}

            {message.reactions?.total ? (
              <span
                className="flex h-8 w-8 cursor-pointer place-content-center items-center rounded-full text-xs font-light text-secondary-foreground/70 hover:bg-accent hover:text-secondary-foreground hover:shadow-sm"
                onClick={() => {
                  setIsEmojiPickerVisible(!isEmojiPickerVisible);
                }}>
                <UIcon
                  icon="grin-alt"
                  className="text-secondary-foreground/70"
                />
              </span>
            ) : (
              <></>
            )}
            {message.reactions ? (
              <ReactionPills
                reactions={message.reactions}
                react={(emoji) => {
                  // do something
                  MessageService.reactToMessage(message, emoji);
                }}
                unreact={() => {
                  MessageService.unreactToMessage(message);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        <EmojiPickerV2
          emoji={null}
          hidePlaceholder={true}
          setEmoji={(emoji) => {
            // set emoji
            MessageService.reactToMessage(message, emoji);
            // hide picker
            setIsEmojiPickerVisible(false);
          }}
          visible={isEmojiPickerVisible}
          setVisible={setIsEmojiPickerVisible}
        />

        {/* replies */}
        <ReplyPill
          message={message}
          showReplies={showReplies}
          setShowReplies={setShowReplies}
        />
      </div>
      {/* Thread */}
      {!message.parent ? (
        <ThreadBox
          user={user}
          replies={message.replies}
          setReplies={(replies) => {
            updateMessage({ ...message, replies });
          }}
          group={group}
          activeTabModel={activeTabModel}
          isUserAllowedToPost={isUserAllowedToPost}
          showReplies={showReplies}
          setShowReplies={setShowReplies}
          message={message}
        />
      ) : (
        <></>
      )}
    </>
  );
}

function ReplyPill({ message, showReplies, setShowReplies }) {
  const replyCount = message.replies?.length;

  return replyCount ? (
    <button
      onClick={() => {
        setShowReplies(!showReplies);
      }}
      className="flex w-fit cursor-pointer items-center gap-2 rounded bg-accent/80 px-2 py-1 text-xs font-light text-secondary-foreground/80 hover:bg-accent hover:text-secondary-foreground hover:shadow-sm">
      {replyCount ? replyCount : ""}{" "}
      {replyCount < 2 ? <I18>Reply</I18> : <I18>Replies</I18>}
      <UIcon
        icon="angle-right"
        size="xxs"
        className={cx("transition-all delay-150 ease-in-out", {
          "rotate-90 transform": showReplies,
        })}
      />
    </button>
  ) : (
    <></>
  );
}

/**
 * Post Actions
 * @param {*} param0
 * @returns
 */
const PostActions = connect((s) => ({
  user: s.auth,
  community: s.community,
}))(({
  post,
  activeTabModel,
  user,
  group,
  community,
  updatePost = (post) => {},
  deletePost = (post) => {},
  areCommentsLoading = false,
  isBeingEdit = false,
  setCommentsLoading = (e) => {},
  setEmbedModalVisible = (e) => {},
  setIsBeingEdit = (e) => {},
  setIsProcessing = (e) => {},
  showReplies = false,
  setShowReplies = (e) => {},
  isEmojiPickerVisible = false,
  setIsEmojiPickerVisible = (e) => {},
}) => {
  //function to pin the message
  const handlePinRealtimeSectionMessage = async (postId) => {
    try {
      await RealtimeSectionPinnedMessageService.pinRealtimeSectionMessage(
        postId
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex items-center justify-start rounded bg-card">
      {/* visible options */}
      {activeTabModel && (
        <div className="flex space-x-2 pl-2">
          {!post.parent && (
            <span
              className="cursor-pointer"
              onClick={() => {
                setShowReplies(!showReplies);
              }}>
              <UIcon
                size="lg"
                icon="comment"
                className="text-secondary-foreground/80"
              />
            </span>
          )}

          <span
            className="cursor-pointer"
            onClick={() => {
              setIsEmojiPickerVisible(!isEmojiPickerVisible);
            }}>
            <UIcon
              size="lg"
              icon="grin-alt"
              className="text-secondary-foreground/80"
            />
          </span>
        </div>
      )}
      {/* more options */}
      <IconMenu2
        className="text-secondary-foreground"
        hideOnEmpty
        iconButtonSmall
        actions={[
          activeTabModel !== undefined &&
            (post.createdBy.id === user.id || community.myRole === "admin") && {
              label: "Edit",
              onClick: (e) => {
                setIsBeingEdit(true);
              },
            },
          activeTabModel !== undefined && {
            // show reply button
            label: "Reply",
            icon: "redo",
            onClick: () => {
              setShowReplies(!showReplies);
            },
          },
          activeTabModel !== undefined &&
            community.myRole === "admin" && {
              label: "Pin message",
              icon: "thumbtack",
              onClick: () => {
                handlePinRealtimeSectionMessage(post.id);
              },
            },
          {
            // show delete button
            label:
              post.createdBy.id === user.id ||
              (group && ["admin", "moderator"].includes(group.myRole))
                ? "Delete"
                : "",
            icon: "delete",
            onClick: () => {
              setIsProcessing(true);
              deletePost(post);
            },
          },
        ]}
      />
    </div>
  );
});

export function IconButtonSmall({
  icon = "",
  img = "",
  active = false,
  onClick = (e) => {},
  rotate = 0,
}) {
  return (
    <div
      className={cx(
        "realtimepost-option rounded p-2 hover:hover:bg-background",
        {
          "bg-blue-500": active,
        }
      )}
      onClick={onClick}>
      <img
        src={icon === "img" ? img : icon}
        alt={""}
        style={{
          transform: "rotate(" + rotate + "deg)",
        }}
        className="default-icon h-3 w-3"
      />
    </div>
  );
}

/**
 * Show post is processing
 * @param {*} param0
 * @returns
 */
function PostProcessing({ isProcessing }) {
  if (!isProcessing) return <></>;

  return (
    <div className="PostProcessing">
      <Loader />
    </div>
  );
}

export function MessageAttachments({ message, removeAttachment = null }) {
  const { attachments } = message;

  if (!attachments || attachments.length === 0) return <></>;

  return (
    <div className="MessageAttachments rounded p-1">
      {attachments.map((attachment, index) => (
        <MessageAttachment
          key={index}
          removeAttachment={
            removeAttachment
              ? (e) => {
                  // remove the attachment at index
                  removeAttachment(index);
                }
              : null
          }
          attachment={attachment}
        />
      ))}
    </div>
  );
}

// create MessageAttachment component
function MessageAttachment({ attachment, removeAttachment = null }) {
  let { mimeType, url, name, size, thumbnailUrl } = removeAttachment
    ? {}
    : attachment; // get all the details

  if (removeAttachment && attachment) {
    // make url from file
    url = URL.createObjectURL(attachment);
    mimeType = attachment.type;
    name = attachment.name;
    size = attachment.size;
  }

  const category = mimeType ? mimeType.split("/")[0] : ""; // get the category of the attachment

  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false); // show video player by default
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false); // show image viewer by default

  // if there is not url, show nothing
  if (!url) return <></>;

  // special display for image
  if (category === "image" || "gif") {
    return (
      <div className="MessageAttachmentHolder relative flex">
        <div
          // style={{
          //   backgroundImage: `url(${url})`,
          // }}
          onClick={(e) => {
            setIsImageViewerVisible(true);
          }}
          className={"MessageAttachment cursor-pointer " + category}>
          <img src={url} alt="" className="MessageAttachment" loading="lazy" />
        </div>
        <PopupImageViewer
          imageUrl={url}
          isPopupVisible={isImageViewerVisible}
          setIsPopupVisible={() => setIsImageViewerVisible(false)}
        />
        {removeAttachment ? (
          <span className="remove-attachment-button" onClick={removeAttachment}>
            &times;
          </span>
        ) : null}
      </div>
    );
  }

  // special display for video
  if (category === "video") {
    return (
      <div className="MessageAttachmentHolder relative flex">
        <div
          style={{
            backgroundImage: `url(${thumbnailUrl})`,
          }}
          className={"MessageAttachment video-thumbnail"}
          onClick={(e) => {
            setIsVideoPlayerVisible(true);
          }}>
          <div className="flex h-8 w-8 cursor-pointer place-content-center items-center rounded-full bg-gray-600 pt-1 hover:scale-110 hover:bg-gray-400">
            <UIcon
              icon="play"
              size="md"
              solid={true}
              className="text-white hover:text-gray-50"
            />
          </div>
        </div>
        <PopupVideoPlayer
          videoUrl={url}
          isVideoPlayerVisible={isVideoPlayerVisible}
          setIsVideoPlayerVisible={setIsVideoPlayerVisible}
        />
        {removeAttachment ? (
          <span className="remove-attachment-button" onClick={removeAttachment}>
            &times;
          </span>
        ) : null}
      </div>
    );
  }

  // for other attachments, show the name and size
  return (
    <div className="MessageAttachmentHolder relative flex w-full">
      <PostDocument url={url} name={name} size={size} />
      {removeAttachment ? (
        <span className="remove-attachment-button" onClick={removeAttachment}>
          &times;
        </span>
      ) : null}
    </div>
  );
}

/**
 * Show post document if necessary
 * @param {*} param0
 * @returns
 */
export function PostDocument({ url, name, size }) {
  const videoFormat = ["mp4", "mov", "webm", "ogg"];
  const audioFormat = ["wav", "mp3", "aac", "amr"];

  const document = url;
  console.log({ document });
  return document && videoFormat.includes(document.split(".").pop()) ? (
    <div className={cx("my-2 w-full")}>
      <div className="mr-3 mt-2 py-1">
        <video className="m-0 rounded-md" controls controlsList="nodownload">
          <source src={document} />
        </video>
      </div>
    </div>
  ) : document && audioFormat.includes(document.split(".").pop()) ? (
    <div className={cx("w-full")}>
      <div className="">
        <audio
          className="m-0 w-full rounded-md"
          controls
          controlsList="nodownload">
          <source src={document} />
        </audio>
      </div>
    </div>
  ) : document ? (
    <div className={cx("my-2 w-full")}>
      <a
        download
        target="_blank"
        rel="noreferrer"
        href={document}
        title="Open file"
        className="flex items-center space-x-2 rounded border border-border bg-background px-4 py-2 font-semibold">
        <UIcon icon="document" className="h-9 text-3xl" />
        <div className="flex flex-grow items-center justify-between space-x-4 text-secondary-foreground">
          <div className="items-center">
            <div>{name ? name : ""}</div>
            <div className="text-xs uppercase">
              {/* {(documentName ? documentName : post.document).split(".").pop()} */}
              <div className="text-xs">{humanFileSize(size)}</div>
            </div>
          </div>
          <span className="flex-none">
            <UIcon icon="download" className="text-xl" />
          </span>
        </div>
      </a>
    </div>
  ) : (
    <></>
  );
}

/**
 * Create function to get human readable size from bytes
 */
function humanFileSize(bytes, si) {
  let thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  let units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
}

const MessageCard = connect((s) => ({ user: s.auth, group: s.activeGroup }))(
  MessageCardComponent
);

export { MessageCard };
