import { CommunityTopic } from "../../types/community-topic/community-topic";
import { CommunityService } from "../../_service";
import { TopicsState } from "./topics.reducer";

export const SET_TOPICS = "SET_TOPICS";
export const ADD_TOPIC = "ADD_TOPIC";
export const EDIT_TOPIC = "EDIT_TOPIC";
export const ARCHIVE_TOPIC = "ARCHIVE_TOPIC";
export const DELETE_TOPIC = "DELETE_TOPIC";

export const setTopicsAction = (obj: TopicsState) => ({
  type: SET_TOPICS,
  obj,
});
export const addTopicAction = (topic: CommunityTopic) => ({
  type: ADD_TOPIC,
  topic,
});
export const editTopicAction = (topic: CommunityTopic) => ({
  type: EDIT_TOPIC,
  topic,
});
export const archiveTopicAction = (topicId: string) => ({
  type: ARCHIVE_TOPIC,
  topicId,
});
export const deleteTopicAction = (topicId: string) => ({
  type: DELETE_TOPIC,
  topicId,
});

/**
 * Get community topics
 */
export function getCommunityTopics() {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }
      CommunityService.getAllCommunityTopics(community.id)
        .then((response: any) => {
          dispatch(setTopicsAction(response.topics));
        })
        .catch((err: any) => {
          console.log({ err });
        });
    } catch (error) {
      console.log(error);
    }
  };
}