import cx from "classnames";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import useLockedBody from "../hooks/use-lock-body";
import { cn } from "./button.component";

export default function RightSideModal({
  active,
  setActive,
  children,
  className = "",
  ...props
}) {
  const portalContainer = document.body;
  const [_, setLocked] = useLockedBody(false, "root");

  useEffect(() => {
    setLocked(active);
  }, [active, setLocked]);

  if (!active) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="RightSideModalComponent">
      <div
        style={{
          // maxWidth: "100%",
          // maxHeight: "100%",
          overflow: "auto",
          zIndex: 1000,
        }}
        className={cn(
          cx(
            "fixed inset-0 inset-x-auto right-0 w-full overflow-y-auto overflow-x-hidden md:w-3/5 lg:w-[38%]"
          ),
          className
        )}>
        <div
          className="z-10 mx-auto h-full bg-card text-secondary-foreground/90"
          {...props}
          style={{ height: "100" }}>
          {/* {content} */}
          {active && children}
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setActive(false);
        }}
        style={{ zIndex: 999 }}
        className={cx("fixed inset-0 z-40 bg-black/30 backdrop-blur-sm")}></div>
    </div>,
    portalContainer
  );
}

export function RightSideModal2({ layout, active, setActive, width }) {
  return (
    <>
      {active && typeof document !== "undefined" ? (
        <div
          className="relative z-10"
          aria-labelledby="slide-over-title"
          role="dialog"
          aria-modal="true">
          {/* <!-- Background backdrop, show/hide based on slide-over state--> */}
          <div
            className={cx(
              "BackgroundBackdrop fixed inset-0 bg-slate-600 bg-opacity-70 transition ease-in-out",
              {
                "h-0 w-0 translate-x-0 opacity-0": !active,
                "h-screen w-full opacity-90": active,
              }
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActive(false);
            }}
            style={{ zIndex: 999 }}
          />

          <div
            className="absolute inset-0 overflow-hidden"
            style={{ zIndex: 999 }}>
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              {/* <!-- Slide-over panel, show/hide based on slide-over state.--> */}
              <div
                className={cx(
                  "pointer-events-auto relative w-screen transform transition duration-500 ease-in-out sm:duration-700",
                  {
                    "translate-x-0": active,
                    "translate-x-full": !active,
                  }
                )}
                style={{
                  width: width ?? "500px",
                  height: "100",
                  zIndex: 999,
                }}>
                <div className="flex h-full flex-col overflow-y-scroll bg-card shadow-xl">
                  <div className="flex-1">
                    <div className="h-full" aria-hidden="true">
                      {layout}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
