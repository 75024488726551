import cx from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { history } from "../_config";
import { getSectionPageRouteFromSlug } from "../_pages";
import { UserService } from "../_service";
import Validator from "../_utils/validator";
import I18 from "./atoms/i18";
import LazyImage from "./atoms/lazy-image";
import { Loader } from "./loader.component";

function Avatar({
  user,
  extraInfo,
  hideNameOnMobile = false,
  activityAvatar = false, // activity avatar
  subCommentAvatar = false, // sub comment avatar
  noPicture = false,
  showSubtitle = false,
  showDotSeperator = false,
  noName = false,
  tag = <></>,
  picture = "picture",
  size = 36,
  showDetailsOnHover = false,
  onClick = (e) => {},
  isProcessing = false,
  className = "",
  buttonLabel = "",
  buttonOnClick = (e) => {},
  notificationCount = 0,
  bold = false,
}) {
  const [detailVisibility, setDetailVisibility] = useState(false);

  return (
    <div
      onClick={onClick}
      onMouseEnter={(e) => {
        // check if have to show details on hover
        if (showDetailsOnHover && !detailVisibility) {
          setDetailVisibility(true);
        }
      }}
      onMouseLeave={(e) => {
        // check if have to show details on hover
        if (showDetailsOnHover && detailVisibility) {
          setDetailVisibility(false);
        }
      }}
      className={cx(
        `flex ${!noPicture ? "items-center" : ""} justify-between`,
        className,
        {
          "hide-on-mobile": hideNameOnMobile,
        }
      )}>
      <div className={`flex ${!noPicture ? "items-center" : ""}`}>
        {!noPicture ? (
          <div
            style={{
              backgroundImage: "url('" + user?.[picture] + "')",
              width: size + "px",
              height: size + "px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "50%",
              marginRight: "10px",
              flexShrink: 0,
            }}
          />
        ) : (
          // <LazyImage
          //   src={user?.picture}
          //   onClick={onClick}
          //   style={{
          //     width: size + "px",
          //     height: size + "px",
          //     minWidth: size + "px",
          //     minHeight: size + "px",
          //   }}
          //   className={`rounded-full cursor-pointer object-cover mr-3`}
          // />
          <></>
        )}

        <div className="mt-1 flex-col">
          <div className="flex items-center gap-1">
            {!noName ? (
              <div
                className={"name text-xs font-bold text-secondary-foreground"}>
                {user?.name}
              </div>
            ) : (
              <></>
            )}
            {tag}
            {/* show has updates marker */}
            {notificationCount ? (
              <span className="update-marker">{notificationCount}</span>
            ) : (
              <></>
            )}
            {showDotSeperator && (
              <div className="inline-block h-1 w-1 rounded-full bg-gray-400" />
            )}
            {!activityAvatar && (
              <div
                className={
                  subCommentAvatar
                    ? "extra-info text-xxs font-normal text-secondary-foreground/70"
                    : "text-xxs extra-info font-semibold text-secondary-foreground/70"
                }>
                {extraInfo}
              </div>
            )}
          </div>
          {showSubtitle ? (
            <div className="text-xxs -mt-0.5 font-light text-secondary-foreground">
              {user?.shortBio}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {!isProcessing ? (
        <div
          className="text-sm font-semibold text-primary"
          onClick={(e) => {
            buttonOnClick(e);
            e.preventDefault();
          }}>
          {buttonLabel}
        </div>
      ) : (
        <Loader />
      )}
      {showDetailsOnHover ? (
        <AvatarDetails user={user} visibility={detailVisibility} />
      ) : (
        <></>
      )}
    </div>
  );
}

const AvatarDetails = connect((s) => ({ loggedInUser: s.auth }))(({
  loggedInUser,
  user,
  visibility = false,
}) => {
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // get user details
  useEffect(() => {
    UserService.getUserProfileById(loggedInUser, user?.id)
      .then(({ user }) => {
        setUserDetails(user);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error({
          error,
          message: "Could not load user detail in AvatarDetails",
        });
        setIsLoading(false);
      });
  }, [loggedInUser, user]);

  return (
    <div
      className={cx(
        "Avatar details card flex items-center justify-between rounded bg-white px-5 py-3",
        {
          flex: visibility,
          hidden: !visibility,
        }
      )}>
      {isLoading ? (
        <Loader />
      ) : userDetails ? (
        <div className="flex items-center overflow-hidden">
          <div
            style={{
              backgroundImage: "url('" + userDetails?.picture + "')",
              width: "48px",
              height: "48px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "50%",
              marginRight: "12px",
              flexShrink: 0,
            }}></div>
          <div className="extra-info-holder">
            <div className={"name font-bold text-secondary-foreground"}>
              {userDetails?.name}
            </div>
            {userDetails?.mobile ? (
              <div className="extra-info text-xs font-semibold">
                <img src="/assets/vectors/call-icon.svg" alt="Call Icon" />
                {userDetails?.mobile}
              </div>
            ) : (
              <></>
            )}
            {userDetails?.email ? (
              <div className="extra-info text-xs font-semibold">
                <img src="/assets/vectors/mail-icon.svg" alt="Mail Icon" />
                {userDetails?.email}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className="text-alert">Data could not be loaded!</div>
      )}
    </div>
  );
});

export const DetailedAvatar = connect((s) => ({ loggedInUser: s.auth }))(({
  loggedInUser,
  user,
  noBG = false,
}) => {
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // get user details
  useEffect(() => {
    UserService.getUserProfileById(loggedInUser, user?.id)
      .then(({ user }) => {
        setUserDetails(user);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error({
          error,
          message: "Could not load user detail in AvatarDetails",
        });
        setIsLoading(false);
      });
  }, [loggedInUser, user]);

  return (
    <div
      className={cx(
        "Avatar detailed card flex items-center justify-between rounded px-5 py-3",
        {
          flex: true,
          "bg-white": !noBG,
        }
      )}>
      {isLoading ? (
        <Loader />
      ) : userDetails ? (
        <div className="flex items-center overflow-hidden">
          <div
            style={{
              backgroundImage: "url('" + userDetails?.picture + "')",
              width: "48px",
              height: "48px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "50%",
              marginRight: "12px",
              flexShrink: 0,
            }}></div>
          <div className="extra-info-holder">
            <div className={"name font-bold text-secondary-foreground"}>
              {userDetails?.name}
            </div>
            <div className="flex items-center">
              {userDetails?.mobile ? (
                <div className="extra-info flex items-center text-xs font-semibold text-secondary-foreground/85">
                  {userDetails?.countryCode} {userDetails?.mobile}
                </div>
              ) : (
                <></>
              )}
              {userDetails?.mobile && userDetails?.email ? (
                <div className="px-1">•</div>
              ) : (
                <></>
              )}
              {userDetails?.email ? (
                <div className="extra-info flex items-center text-xs font-semibold text-secondary-foreground/85">
                  {userDetails?.email}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-alert">
          <I18>Data could not be loaded!</I18>
        </div>
      )}
    </div>
  );
});

export const AvatarCommunityPost = connect((s) => ({ loggedInUser: s.auth }))(({
  user,
  post,
  tag,
  onClick = (e) => {},
}) => {
  const { hideDate } = post.preferences ?? {};

  return (
    <div className={cx("Avatar flex items-center justify-between rounded")}>
      {user ? (
        <div className="flex items-center overflow-hidden">
          <LazyImage
            src={user?.picture}
            onClick={onClick}
            className="m-auto h-12 w-12 flex-shrink-0 cursor-pointer rounded-full object-cover"
          />
          <div className="extra-info-holder">
            <div
              onClick={onClick}
              className={
                "name ml-2.5 flex cursor-pointer items-center whitespace-nowrap text-xs font-bold text-secondary-foreground/85"
              }>
              {user?.name && user?.name.length > 12
                ? user?.name.substring(0, 10) + "..."
                : user?.name}
              <div
                className={
                  "extra-info font-normal text-secondary-foreground/70"
                }>
                {tag}
              </div>
            </div>
            <div className="text-xxs ml-2.5 line-clamp-1 text-secondary-foreground">
              {Validator.hasValue(post.createdBy?.shortBio)
                ? post.createdBy?.shortBio
                : "  "}
            </div>
            <div
              onClick={() =>
                history.push(getSectionPageRouteFromSlug(post.group, post.tab))
              }
              className="text-xxs text- ml-2.5 flex cursor-pointer text-secondary-foreground/70">
              <I18>Posted in</I18>
              <span className="ml-0.5 cursor-pointer hover:text-primary">
                {post.tab && post.tab.name ? post.tab.name : ""}
              </span>
              {!hideDate && (
                <div className="flex">
                  <div className="ml-1 mt-1.5 inline-block h-1 w-1 rounded-full bg-gray-400" />
                  <div className="text-xxs ml-1 font-normal">
                    {post.createdAt &&
                      moment(post.createdAt)
                        .fromNow()
                        .replace(" days ago", "d ago")
                        .replace("an hour ago", "1h ago")
                        .replace(" hours ago", "h ago")
                        .replace(" weeks ago", "w ago")
                        .replace(" minutes ago", "m ago")
                        .replace(" seconds ago", "s ago")
                        .replace(" months ago", "mo ago")
                        .replace(" years ago", "y ago")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-alert">
          <I18>Data could not be loaded!</I18>
        </div>
      )}
    </div>
  );
});

export { Avatar };
