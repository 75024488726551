import { cn } from "./button.component";

export function MaxWidthWrapper({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "MaxWrapper mx-auto flex w-full max-w-[1920px] flex-col px-0",
        className
      )}>
      {children}
    </div>
  );
}
