import { useEffect, useState } from "react";
import { useAppService } from "../../hooks/use-app-service";
import useLang from "../../hooks/use-lang.hook";
import { validURL } from "../../_config/helper.config";
import { WidgetService } from "../../_service";
import I18 from "../atoms/i18";
import { Button } from "../button.component";
import IconButton2 from "../icon-button-2-component";
import CropImageComponent from "../image-crop/crop-image-component";
import { Loader } from "../loader.component";
import { Modal } from "../modal.component";
import { TextInput } from "../text-input.component";

/**
 * @param {Object} user: User profile object.
 * @param {Object} community: Community profile object.
 * @param {Object} group: Current active group object.
 * @param {boolean} active: Whether the create custom widget is active or not,
 * @param {Function} setActive: Callback to display/hide create custom widget.
 * @param {boolean} forCommunity Whether to create custom widget for community or in group. Default value is true. Set false to use this pop-up on group.
 * @param {Function} onWidgetCreate: Callback to signal if es widget is created.
 * @param {Function} onWidgetUpdated: Callback to signal if custom widget is updated.
 */

export function CreateWidgetModal({
  widgetToEdit,
  user,
  community,
  group,
  active,
  forCommunity,
  setActive = (e) => {},
  onWidgetCreate = (widget) => {},
  onWidgetUpdated = (widget) => {},
}) {
  const isEditMode = widgetToEdit !== undefined;

  const [buttonLabel, setButtonLabel] = useState(
    isEditMode ? widgetToEdit.details.title : null
  );
  const [buttonURL, setButtonURL] = useState(
    isEditMode ? widgetToEdit.details.buttonURL : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(
    isEditMode ? widgetToEdit.details.title : null
  );
  const [description, setDescription] = useState(
    isEditMode ? widgetToEdit.details.description : ""
  );
  const [image, setImage] = useState(
    isEditMode ? widgetToEdit.details.picture : null
  );
  const [pictureSelected, setPictureSelected] = useState(false);

  const { analyticsService } = useAppService();

  const lang = useLang();

  useEffect(() => {
    if (isEditMode) {
      setButtonLabel(widgetToEdit.details.buttonLabel);
      setButtonURL(widgetToEdit.details.buttonURL);
      setTitle(widgetToEdit.details.title);
      setDescription(widgetToEdit.details.description);
      setImage(widgetToEdit.details.picture);
    }
  }, [widgetToEdit]);

  let mtRows = description !== undefined ? description.split("\n").length : 1;
  mtRows = mtRows > 3 ? mtRows : 3;

  function createCustomWidget() {
    if (!title) {
      alert(lang.trans("Please enter a title"));
      return;
    }
    if (!buttonURL) {
      alert(lang.trans("Please enter a url link"));
      return;
    } else if (!buttonLabel) {
      alert(lang.trans("Please enter button title"));
      return;
    } else if (buttonLabel && buttonLabel.length > 20) {
      alert(lang.trans("Button label must be at least 20 characters"));
    } else if (!validURL(buttonURL)) {
      alert(lang.trans("Please enter valid url"));
      return;
    }
    if (isEditMode) {
      updateCustomWidget();
      return;
    }
    const widget = {
      groupId: group ? group.id : null,
      details: {
        title,
        description,
        buttonURL,
        buttonLabel,
        communityId: community.id,
      },
      isVisible: true,
    };
    setIsLoading(true);
    WidgetService.createCustomWidget(user, community.id, widget)
      .then(async (res) => {
        await uploadWidgetPicture(
          res.widget,
          (wid) => {
            console.log("Picture uploaded successfully");
            setTitle(null);
            setDescription("");
            setButtonLabel(null);
            setButtonURL(null);
            setImage(null);

            // tracking event call
            const { title, description, buttonLabel, buttonURL, picture } =
              res.widget.details;
            const trackingData = {
              heading: !!title,
              description: !!description,
              ctaName: !!buttonLabel,
              ctaURL: !!buttonURL,
              picture: !!picture,
            };

            analyticsService.track("custom-widget-created", trackingData);

            onWidgetCreate(wid ?? res.widget);
          },
          (error) => {
            console.log("Widget upload picture failed: ", error);
            setTitle(null);
            setDescription("");
            setButtonURL(null);
            setImage(null);
            onWidgetCreate(res.widget);
          }
        );
      })
      .catch((err) => {
        console.log("Error", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function updateCustomWidget() {
    const widget = {
      ...widgetToEdit,
      details: {
        title,
        description,
        buttonURL,
        buttonLabel,
        communityId: community.id,
      },
    };
    setIsLoading(true);
    WidgetService.updateWidget(user, community.id, widgetToEdit.id, widget)
      .then(async (res) => {
        await uploadWidgetPicture(
          res.widget,
          (wid) => {
            console.log("Picture uploaded successfully");
            setTitle(null);
            setDescription("");
            setButtonURL(null);
            setImage(null);
            onWidgetUpdated(wid ?? res.widget);
          },
          (error) => {
            console.log("Widget upload picture failed: ", error);
            setTitle(null);
            setDescription("");
            setButtonURL(null);
            setImage(null);
            onWidgetUpdated(res.widget);
          }
        );
      })
      .catch((err) => {
        console.log("Error", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  async function uploadWidgetPicture(
    widget,
    onPictureUpload = (e) => {},
    onUploadFailed = (err) => {}
  ) {
    if (!image || typeof image === "string") {
      onPictureUpload();
      return;
    }
    WidgetService.uploadCustomCustomWidgetPicture(
      user,
      community.id,
      widget.id,
      image
    )
      .then((res) => {
        onPictureUpload(res.widget);
      })
      .catch((err) => {
        console.log(err);
        onUploadFailed(err);
      });
  }

  return (
    <Modal
      className="CreateWidgetModal"
      active={active}
      width="490px"
      padding={false}
      setActive={setActive}>
      {/* headers */}
      <div className="flex items-center justify-between bg-card py-2 pl-4 pr-2">
        <span className="font-bold">
          <I18>Add widget to</I18>{" "}
          {forCommunity ? <I18>home feed</I18> : group.name}{" "}
        </span>
        <IconButton2
          hoverable
          icon="cross"
          size="sm"
          rotate={45}
          onClick={() => {
            setActive(false);
          }}
        />
      </div>
      {/* body */}
      <div className="select-none p-5">
        {/* preview */}
        <div className="group-color-preview-holder w-full rounded border-primary"></div>
        {/* choices */}
        <div className="m-auto w-1/2">
          <CropImageComponent
            picture={image}
            setPicture={setImage}
            setPictureSelected={setPictureSelected}
            aspectRatio={1}
            recommendedRatio={"1:1"}
            cropRatioMessage="Picture ratio: 1:1"
          />
        </div>
        <div className="my-2 flex flex-col space-y-2">
          <h4 className="text-sm font-bold">
            <I18>Card Title</I18>
          </h4>
          <TextInput
            defaultValue={title ?? ""}
            onChange={(val) => {
              setTitle(val);
            }}
            className="p-2"
            placeholder="Enter widget name"
          />
        </div>

        <div className="my-2 flex flex-col space-y-2">
          <h4 className="text-sm font-bold">
            <I18>Small Description (try to keep it under 50 words)</I18>
          </h4>
          <div className="flex rounded border border-border">
            <textarea
              // type="text"
              // ref={textAreaElement}
              placeholder={lang.trans("Enter description")}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                window.setTimeout(() => {
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }, 0);
              }}
              className="block w-full flex-grow bg-background px-2 py-1"
              rows={mtRows}
            />
          </div>
        </div>

        <div className="my-2 flex flex-col space-y-2">
          <h4 className="text-sm font-bold">
            <I18>Button Title</I18>
          </h4>
          <TextInput
            defaultValue={buttonLabel ?? ""}
            onChange={(val) => {
              setButtonLabel(val);
            }}
            className="p-2"
            placeholder="Enter widget name"
          />
          <span className="text-xs text-secondary-foreground/70">
            <I18>Suggestions: Know more, Join, Apply...</I18>
          </span>
        </div>

        <div className="my-2 flex flex-col space-y-2">
          <h4 className="text-sm font-bold">
            <I18>URL</I18>
          </h4>
          <TextInput
            defaultValue={buttonURL ?? ""}
            onChange={(val) => {
              setButtonURL(val);
            }}
            className="p-2"
            placeholder="https://"
          />
        </div>

        <div className="mt-14 flex justify-between">
          <button
            className="text-secondary-foreground"
            onClick={() => {
              setActive(false);
            }}>
            <I18>Cancel</I18>
          </button>
          <div className="justify-between">
            {isLoading ? (
              <div className="flex justify-center">
                <Loader />
              </div>
            ) : (
              <Button
                onClick={(e) => {
                  createCustomWidget();
                }}
                className="w-full"
                label={isEditMode ? "Update Widget" : "Create Custom Widget"}
                large
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
