import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withToast } from "../../../../contexts/toastr.context";
import { Group } from "../../../../types/group/group.type";
import {
  setActiveGroup,
  setGroups,
} from "../../../../_store/_actions/group.actions";
import { setPersonalGroup } from "../../../../_store/_actions/personal-group.actions";
import EmbedLinkModal from "../../../embed-link-modal.component";
import RightSideModal from "../../../right-side-modal.component";
import Label from "../../../typography/label.typography";
import UIcon from "../../../uicon-component";
import CreateGroupSidebarModal from "../../create-group-modal-v2.component";

/**
 * @description - function to check is user is allowed to create post in section
 * @param {Object} group - group˝ object
 * @param {Object} section - section object
 */
export const isUserAllowedToPostInSection = (group: any, tab: any) =>
  group.joinStatus === "joined" &&
  (tab && tab.postLevel === "admin"
    ? group.myRole === "admin" || group.myRole === "moderator"
    : true);

function SectionInfoComponent({
  group,
  setGroup,
  user,
  activeTabModel,
  addToast = (e: any) => {},
}: any) {
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false);

  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);

  // create a check if user can post in group
  let isUserAllowedToPost = isUserAllowedToPostInSection(group, activeTabModel);
  // group.joinStatus === "joined" &&
  // (activeTabModel && activeTabModel.postLevel === "admin"
  //   ? group.myRole === "admin" || group.myRole === "moderator"
  //   : true);

  return (
    <>
      {/* <div
        className="my-2 block font-semibold cursor-pointer"
        onClick={() => history.push(getGroupPageRouteFromSlug(group))}>
        ᐸ &nbsp; {group.name}
      </div> */}

      {/* section info */}
      <div className="flex flex-col">
        {/* Display Create Post Permission */}
        {user &&
          !isUserAllowedToPost &&
          group.myRole === "user" &&
          activeTabModel.postLevel === "admin" && (
            <div className="mx-2 mb-2 flex gap-2 rounded border border-border p-2 sm:mx-0 sm:mb-0">
              <UIcon icon="info" size="sm" />
              <Label size="body" variant="s1" translate>
                Only admin can post here.
              </Label>
            </div>
          )}
      </div>

      {/* group edit modal */}
      {/* <CreateGroupModal
        group={group}
        updateGroup={(group) => {
          addToast("Group updated!", "", "success");
          setGroup(group);
        }}
        active={isCreateGroupModalVisible}
        setActive={setIsCreateGroupModalVisible}
      /> */}
      <RightSideModal
        width={570}
        setActive={setIsCreateGroupModalVisible}
        active={isCreateGroupModalVisible}>
        <CreateGroupSidebarModal
          group={group}
          isEditGroupModalVisible={isCreateGroupModalVisible}
          setIsEditGroupModalVisible={setIsCreateGroupModalVisible}
          setIsCreateGroupModalVisible={setIsCreateGroupModalVisible}
          updateGroup={(group: Group) => {
            addToast("Group updated!", "", "success");
            setGroup(group);
          }}
        />
      </RightSideModal>
      {/* embed modal */}
      <EmbedLinkModal
        link={
          process.env.REACT_APP_EMBED_URL +
          (window.location.port ? ":" + window.location.port : "") +
          "/group/" +
          group.id +
          "/feed/" +
          activeTabModel.id
        }
        active={isEmbedModalVisible}
        setActive={setEmbedModalVisible}
      />
    </>
  );
}

export const SectionInfo = withToast(
  connect(
    (s: any) => ({
      user: s.auth,
      activeGroup: s.activeGroup,
      groups: s.groups,
      community: s.community,
      communityPac: s.communityPac,
      personalGroup: s.personalGroup,
      notificationCentre: s.notificationCentre,
      activeTabModel: s.sections.activeSectionId
        ? s.sections.sections[s.sections.activeSectionId]
        : null,
    }),
    (d) =>
      bindActionCreators(
        {
          updateGroups: setGroups,
          updateActiveGroup: setActiveGroup,
          setPersonalGroup: setPersonalGroup,
        },
        d
      )
  )(SectionInfoComponent)
);
