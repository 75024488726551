import { ListView } from "../molecule/listview.component";
import { PostCard } from "./post-card.component";

interface PostCardListProps {
  posts?: any[];
  allPosts?: any[];
  placeholder?: React.ReactNode;
  footerElement?: React.ReactNode;
  noItemsElement?: React.ReactNode;
  isLoading?: boolean;
  isCommunityPost?: boolean;
  onPostUpdate: (post: any, list?: Array<any>) => void;
  onTopicSelect?: (topic: any) => void;
}

/**
 * @description - Display list of posts
 * @param {Array<any>} posts - list of posts
 * @param {Array<any>} allPosts - list of all posts
 * @param {React.ReactNode} placeholder - Placeholder element for list-view when items list is loading
 * @param {React.ReactNode} footerElement - Footer element to be displayed at the end of list-view
 * @param {React.ReactNode} noItemsElement - No items element to be displayed when items list is empty
 * @param {boolean} isLoading - Flag to check if posts are loading
 * @param {boolean} isCommunityPost - Flag to check if posts are community posts. Displays posted group and tab if true
 * @param {Function} onPostUpdate - Callback function to update post in list. Returns updated post and list of posts
 * @param {Function} onTopicSelect - Callback function to select topic. Returns selected topic
 * @returns {React.ReactNode} - Returns list of posts
 */

export function PostCardList({
  posts,
  allPosts,
  // UI elements
  placeholder,
  footerElement,
  noItemsElement,
  isLoading,
  isCommunityPost,
  onPostUpdate,
  onTopicSelect,
}: PostCardListProps) {
  return (
    <ListView
      items={posts}
      loading={isLoading}
      placeholder={placeholder}
      footerElement={footerElement}
      noItemsElement={noItemsElement}
      renderItem={(post) => {
        return (
          <PostCard
            post={post}
            key={post.id}
            isCommunityPost={isCommunityPost}
            onTopicSelect={(topic) => {
              onTopicSelect && onTopicSelect(topic);
            }}
            updatePost={(post: any) => {
              if (!allPosts) return;
              // get post index
              const postIndex = allPosts.findIndex(
                (p: any) => p.id === post.id
              );
              if (postIndex === -1) {
                onPostUpdate(post, undefined);
                return;
              }
              const oldPosts = [...allPosts];
              oldPosts.splice(postIndex, 1, post);
              onPostUpdate(post, oldPosts);
            }}
            showFullPost={posts!.length === 1}
            deletePost={(post: any) => {
              if (!allPosts) return;
              // get post index
              const postIndex = allPosts.findIndex(
                (p: any) => p.id === post.id
              );
              if (postIndex === -1) return;
              const oldPosts = [...allPosts];
              oldPosts.splice(postIndex, 1);
              onPostUpdate(post, oldPosts);
            }}
          />
        );
      }}
    />
  );
}
