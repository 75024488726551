import cx from "classnames";
import React from "react";
import { cn } from "./button.component";
interface Props {
  icon?: UIcons;
  solid?: Boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: String;
  size?: "xxs" | "xs" | "sm" | "base" | "lg" | "md" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl";
}

function UIcon({ icon, className = "", solid = false, size = "md" }: Props) {
  return (
    <i
      // className={`${
      //   !solid ? "fi-rr" : "fi-sr"
      // }-${icon} text-${size} ${className}`}
      className={cn(
        cx("", {
          [`fi-rr-${icon}`]: !solid,
          [`fi-sr-${icon}`]: solid,
          [`text-${size}`]: size,
        }),
        className
      )}
    />
  );
}
export default UIcon;

export type UIcons =
  | "add"
  | "address-book"
  | "alarm-clock"
  | "align-center"
  | "align-justify"
  | "align-left"
  | "align-right"
  | "ambulance"
  | "angle-circle-down"
  | "angle-circle-left"
  | "angle-circle-right"
  | "angle-circle-up"
  | "angle-double-left"
  | "angle-double-right"
  | "angle-double-small-left"
  | "angle-double-small-right"
  | "angle-down"
  | "angle-left"
  | "angle-right"
  | "angle-small-down"
  | "angle-small-left"
  | "angle-small-right"
  | "angle-small-up"
  | "angle-square-down"
  | "angle-square-left"
  | "angle-square-right"
  | "angle-square-up"
  | "angle-up"
  | "angry"
  | "apple"
  | "apps-add"
  | "apps-delete"
  | "apps-sort"
  | "apps"
  | "archive"
  | "arrow-circle-down"
  | "arrow-circle-left"
  | "arrow-circle-right"
  | "arrow-circle-up"
  | "arrow-down"
  | "arrow-from-bottom"
  | "arrow-from-left"
  | "arrow-from-right"
  | "arrow-from-top"
  | "arrow-left"
  | "arrow-right"
  | "arrow-small-down"
  | "arrow-small-left"
  | "arrow-small-right"
  | "arrow-small-up"
  | "arrow-square-down"
  | "arrow-square-left"
  | "arrow-square-right"
  | "arrow-square-up"
  | "arrow-to-bottom"
  | "arrow-to-left"
  | "arrow-to-right"
  | "arrow-to-top"
  | "arrow-up"
  | "arrows-alt"
  | "arrows-h-copy"
  | "arrows-h"
  | "arrows"
  | "asterik"
  | "at"
  | "aubergine"
  | "avocado"
  | "backpack"
  | "bacon"
  | "badge"
  | "balloons"
  | "ban"
  | "band-aid"
  | "bank"
  | "barber-shop"
  | "baseball-bat"
  | "baseball"
  | "basketball-hoop"
  | "basketball"
  | "bed"
  | "beer"
  | "bell-ring"
  | "bell-school"
  | "bell"
  | "bike"
  | "billiard"
  | "bold"
  | "book-alt"
  | "book"
  | "bookmark"
  | "bottle"
  | "bow"
  | "bowling-ball"
  | "bowling-pins"
  | "bowling"
  | "box-alt"
  | "box"
  | "boxing-glove"
  | "bread-slice"
  | "bread"
  | "briefcase"
  | "broccoli"
  | "broom"
  | "browser"
  | "brush"
  | "bug"
  | "building"
  | "bulb"
  | "burrito"
  | "butterfly"
  | "cake-birthday"
  | "cake-wedding"
  | "calculator"
  | "calendar"
  | "call-history"
  | "call-incoming"
  | "call-missed"
  | "call-outgoing"
  | "camera"
  | "camping"
  | "candy-alt"
  | "candy"
  | "canned-food"
  | "car"
  | "caret-circle-down"
  | "caret-circle-right"
  | "caret-circle-up"
  | "caret-down"
  | "caret-left"
  | "caret-quare-up"
  | "caret-right"
  | "caret-square-down"
  | "caret-square-left_1"
  | "caret-square-left"
  | "caret-square-right"
  | "caret-up"
  | "carrot"
  | "chart-connected"
  | "chart-histogram"
  | "chart-network"
  | "chart-pie-alt"
  | "chart-pie"
  | "chart-pyramid"
  | "chart-set-theory"
  | "chart-tree"
  | "chat-arrow-down"
  | "chat-arrow-grow"
  | "check"
  | "checkbox"
  | "cheese"
  | "cherry"
  | "chess-board"
  | "chess-piece"
  | "chevron-double-down"
  | "chevron-double-up"
  | "child-head"
  | "chocolate"
  | "circle-small"
  | "circle"
  | "clip"
  | "clock"
  | "cloud-upload"
  | "cloud-check"
  | "cloud-disabled"
  | "cloud-download"
  | "cloud-share"
  | "cloud"
  | "clouds"
  | "club"
  | "cocktail-alt"
  | "cocktail"
  | "coffee-pot"
  | "coffee"
  | "comment-alt"
  | "comment-check"
  | "comment-heart"
  | "comment-info"
  | "comment-user"
  | "comment"
  | "comments"
  | "compress-alt"
  | "compress"
  | "computer"
  | "confetti"
  | "cookie"
  | "copy-alt"
  | "copy"
  | "copyright"
  | "corn"
  | "cow"
  | "cream"
  | "credit-card"
  | "cricket"
  | "croissant"
  | "cross-circle"
  | "cross-small"
  | "cross"
  | "crown"
  | "cube"
  | "cupcake"
  | "curling"
  | "cursor-finger"
  | "cursor-plus"
  | "cursor-text-alt"
  | "cursor-text"
  | "cursor"
  | "dart"
  | "dashboard"
  | "data-transfer"
  | "database"
  | "delete"
  | "diamond"
  | "diploma"
  | "disco-ball"
  | "disk"
  | "dizzy"
  | "doctor"
  | "document-signed"
  | "document"
  | "dollar"
  | "download"
  | "dreidel"
  | "drink-alt"
  | "drumstick"
  | "duplicate"
  | "e-learning"
  | "earnings"
  | "edit-alt"
  | "edit"
  | "egg-fried"
  | "egg"
  | "envelope-ban"
  | "envelope-download"
  | "envelope-marker"
  | "envelope-open"
  | "envelope-plus"
  | "envelope"
  | "euro"
  | "exchange-alt"
  | "exchange"
  | "exclamation"
  | "expand-arrows-alt"
  | "expand-arrows"
  | "expand"
  | "eye-crossed"
  | "eye-dropper"
  | "eye"
  | "feather"
  | "ferris-wheel"
  | "field-hockey"
  | "file-add"
  | "file-ai"
  | "file-check"
  | "file-delete"
  | "file-eps"
  | "file-gif"
  | "file-music"
  | "file-psd"
  | "file"
  | "fill"
  | "film"
  | "filter"
  | "fingerprint"
  | "fish"
  | "flag"
  | "flame"
  | "flip-horizontal"
  | "flower-bouquet"
  | "flower-tulip"
  | "flower"
  | "flushed"
  | "folder-add"
  | "folder"
  | "following"
  | "football"
  | "fork"
  | "form"
  | "forward"
  | "fox"
  | "french-fries"
  | "frown"
  | "ftp"
  | "gallery"
  | "game"
  | "gamepad"
  | "garlic"
  | "gas-pump"
  | "gem"
  | "gift"
  | "gingerbread-man"
  | "glass-cheers"
  | "glass"
  | "glasses"
  | "globe-alt"
  | "globe"
  | "golf-ball"
  | "golf-club"
  | "golf"
  | "graduation-cap"
  | "grape"
  | "graphic-tablet"
  | "grid-alt"
  | "grid"
  | "grill"
  | "grimace"
  | "grin-alt"
  | "grin-beam-sweat"
  | "grin-beam"
  | "grin-hearts"
  | "grin-squint-tears"
  | "grin-squint"
  | "grin-stars"
  | "grin-tears"
  | "grin-tongue-squint"
  | "grin-tongue-wink"
  | "grin-tongue"
  | "grin-wink"
  | "grin"
  | "guitar"
  | "gym"
  | "hamburger-soda"
  | "hamburger"
  | "hand-holding-heart"
  | "hastag"
  | "hat-birthday"
  | "hat-chef"
  | "head-side-thinking"
  | "headphones"
  | "headset"
  | "heart-arrow"
  | "heart"
  | "hiking"
  | "hockey-mask"
  | "hockey-puck"
  | "hockey-sticks"
  | "home-location-alt"
  | "home-location"
  | "home"
  | "hotdog"
  | "hourglass-end"
  | "hourglass"
  | "ice-cream"
  | "ice-skate"
  | "id-badge"
  | "inbox"
  | "incognito"
  | "indent"
  | "infinity"
  | "info"
  | "interactive"
  | "interlining"
  | "interrogation"
  | "italic"
  | "jam"
  | "jpg"
  | "key"
  | "keyboard"
  | "kiss-beam"
  | "kiss-wink-heart"
  | "kiss"
  | "kite"
  | "knife"
  | "label"
  | "laptop"
  | "lasso"
  | "laugh-beam"
  | "laugh-squint"
  | "laugh-wink"
  | "laugh"
  | "layers"
  | "layout-fluid"
  | "leaf"
  | "lemon"
  | "letter-case"
  | "lettuce"
  | "level-down-alt"
  | "level-down"
  | "level-up-alt"
  | "level-up"
  | "life-ring"
  | "line-width"
  | "link"
  | "lipstick"
  | "list-check"
  | "list"
  | "location-alt"
  | "lock-alt"
  | "lock"
  | "luggage-rolling"
  | "magic-wand"
  | "makeup-brush"
  | "man-head"
  | "map-marker-cross"
  | "map-marker-home"
  | "map-marker-minus"
  | "map-marker-plus"
  | "map-marker"
  | "map"
  | "marker-time"
  | "marker"
  | "mars-double"
  | "mars"
  | "mask-carnival"
  | "mask"
  | "medicine"
  | "megaphone"
  | "meh-blank"
  | "meh-rolling-eyes"
  | "meh"
  | "melon"
  | "menu-burger"
  | "menu-dots-vertical"
  | "menu-dots"
  | "microphone-alt"
  | "microphone"
  | "minus-small"
  | "minus"
  | "mobile"
  | "mode-landscape"
  | "mode-portrait"
  | "money"
  | "moon"
  | "mountains"
  | "mouse"
  | "mug-alt"
  | "mug-hot-alt"
  | "mug-hot"
  | "mug-tea"
  | "mug"
  | "mushroom"
  | "music-alt"
  | "music"
  | "navigation"
  | "network-cloud"
  | "network"
  | "noodles"
  | "notebook"
  | "olive-oil"
  | "olives"
  | "onion"
  | "opacity"
  | "package"
  | "paint-brush"
  | "palette"
  | "pan"
  | "paper-plane"
  | "password"
  | "pause"
  | "paw"
  | "peach"
  | "pencil"
  | "pennant"
  | "pepper-hot"
  | "pepper"
  | "pharmacy"
  | "phone-call"
  | "phone-cross"
  | "phone-pause"
  | "phone-slash"
  | "physics"
  | "picnic"
  | "picture"
  | "pie"
  | "pineapple"
  | "ping-pong"
  | "pizza-slice"
  | "plane"
  | "plate"
  | "play-alt"
  | "play"
  | "playing-cards"
  | "plus-small"
  | "plus"
  | "poker-chip"
  | "poo"
  | "popcorn"
  | "portrait"
  | "pot"
  | "pound"
  | "power"
  | "presentation"
  | "print"
  | "protractor"
  | "pulse"
  | "pumpkin"
  | "puzzle-piece"
  | "pyramid"
  | "quote-right"
  | "radish"
  | "rainbow"
  | "raindrops"
  | "raquet"
  | "rec"
  | "receipt"
  | "record-vinyl"
  | "rectabgle-vertical"
  | "rectangle-horizontal"
  | "rectangle-panoramic"
  | "recycle"
  | "redo-alt"
  | "redo"
  | "reflect"
  | "refresh"
  | "resize"
  | "resources"
  | "restaurant"
  | "rewind"
  | "rhombus"
  | "rings-wedding"
  | "road"
  | "rocket"
  | "room-service"
  | "rotate-right"
  | "rugby-helmet"
  | "rugby"
  | "running"
  | "sad-cry"
  | "sad-tear"
  | "sad"
  | "salad"
  | "salt-pepper"
  | "sandwich"
  | "sauce"
  | "sausage"
  | "scale"
  | "school-bus"
  | "school"
  | "scissors"
  | "screen"
  | "search-alt"
  | "search-heart"
  | "search"
  | "settings-sliders"
  | "settings"
  | "share"
  | "shield-check"
  | "shield-exclamation"
  | "shield-interrogation"
  | "shield-plus"
  | "shield"
  | "ship-side"
  | "ship"
  | "shop"
  | "shopping-bag-add"
  | "shopping-bag"
  | "shopping-cart-add"
  | "shopping-cart-check"
  | "shopping-cart"
  | "shrimp"
  | "shuffle"
  | "shuttlecock"
  | "sign-in-alt"
  | "sign-in"
  | "sign-out-alt"
  | "sign-out"
  | "signal-alt-1"
  | "signal-alt-2"
  | "signal-alt"
  | "skateboard"
  | "skating"
  | "skewer"
  | "ski"
  | "skii"
  | "skiing"
  | "sledding"
  | "smartphone"
  | "smile-beam"
  | "smile-wink"
  | "smile"
  | "snowboarding"
  | "snowflake"
  | "soap"
  | "sort"
  | "soup"
  | "spa"
  | "spade"
  | "speaker"
  | "sphere"
  | "spinner-alt"
  | "spinner"
  | "spoon"
  | "square-root"
  | "square"
  | "star-octogram"
  | "star"
  | "stats"
  | "steak"
  | "stethoscope"
  | "sticker"
  | "stop"
  | "stopwatch"
  | "strawberry"
  | "subtitles"
  | "sun"
  | "sunrise"
  | "surfing"
  | "surprise"
  | "sushi"
  | "swimmer"
  | "sword"
  | "syringe"
  | "tablet"
  | "taco"
  | "target"
  | "taxi"
  | "tennis"
  | "terrace"
  | "test-tube"
  | "test"
  | "text-check"
  | "text"
  | "thermometer-half"
  | "thumbs-down"
  | "thumbs-up"
  | "thumbtack"
  | "ticket"
  | "time-add"
  | "time-check"
  | "time-delete"
  | "time-fast"
  | "time-forward-sixty"
  | "time-forward-ten"
  | "time-forward"
  | "time-half-past"
  | "time-oclock"
  | "time-past"
  | "time-quarter-to"
  | "time-quarter-past"
  | "time-twenty-four"
  | "tired"
  | "tomato"
  | "tool-crop"
  | "tool-marquee"
  | "tooth"
  | "tornado"
  | "train-side"
  | "train"
  | "transform"
  | "trash"
  | "treatment"
  | "tree-christmas"
  | "tree"
  | "triangle"
  | "trophy"
  | "truck-side"
  | "turkey"
  | "umbrella"
  | "underline"
  | "undo-alt"
  | "undo"
  | "unlock"
  | "upload"
  | "usb-pendrive"
  | "user-add"
  | "user-delete"
  | "user-remove"
  | "user-time"
  | "user"
  | "utensils"
  | "vector-alt"
  | "vector"
  | "venus-double"
  | "venus-mars"
  | "venus"
  | "video-camera"
  | "volleyball"
  | "volume"
  | "water-bottle"
  | "watermelon"
  | "wheat"
  | "wheelchair"
  | "whistle"
  | "wifi-alt"
  | "wind"
  | "woman-head"
  | "world"
  | "yen"
  | "zoom-in"
  | "zoom-out";
