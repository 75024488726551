import { useEffect, useState } from "react";
import { ToastTypes } from "../../../contexts/toastr.context";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { useAppService } from "../../../hooks/use-app-service";
import { useToast } from "../../../hooks/use-toast.hook";
import { Button, Loader } from "../../../_components";
import { ActionModal } from "../../../_components/action-modal.component";
import { CommunityService } from "../../../_service";
import { setCommunity } from "../../../_store/_actions/community.actions";
import PlanLimitsTable from "./billing-plans-table";
import {
  BillingCycle,
  BillingPlans,
  getBillingCycleByKey,
  getPlanNameByKey,
  getPlanUsingKey,
  Plan,
} from "./plans";

export const COMMUNITY_SUBSCRIPTION_PAGE_ROUTE = "/settings/subscription";

const BillingPageV2 = () => {
  const { user, community } = useAppSelector((state) => {
    return {
      user: state.auth,
      community: state.community,
    };
  });
  const communityId = community?.id;
  const communityCurrentPlan = community?.planType;
  const { analyticsService } = useAppService();
  const dispatch = useAppDispatch();
  const { addToast } = useToast();

  const [billingCycle, setBillingCycle] = useState<BillingCycle>("monthly");
  const [selectedPlanToChangeTo, setSelectedPlanToChangeTo] = useState("");
  const [isChangePlanModalVisible, setIsChangePlanModalVisible] =
    useState(false);
  const [isCancelPlanModalVisible, setIsCancelPlanModalVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>();

  useEffect(() => {
    if (communityId && communityCurrentPlan) {
      const plan = getPlanUsingKey(communityCurrentPlan);
      setCurrentPlan(plan);
      setBillingCycle(getBillingCycleByKey(communityCurrentPlan));
    }
  }, [communityId, communityCurrentPlan]);

  const getButtonLabelFromPlanType = (plan: Plan) => {
    if (!community.planType || community?.isPlanExpired) {
      return "Select";
    }

    if (!currentPlan || currentPlan.rank === undefined) {
      return "Select";
    }
    const isCurrentPlanAnnually =
      getBillingCycleByKey(community?.planType) === "annually";

    const isCurrentPlanMonthly =
      getBillingCycleByKey(community?.planType) === "monthly";

    const currentPlanPrice = isCurrentPlanAnnually
      ? currentPlan.pricing?.yearly
      : currentPlan.pricing?.monthly;

    const planPrice = isCurrentPlanAnnually
      ? plan.pricing?.yearly
      : plan.pricing?.monthly;

    if (currentPlanPrice && planPrice) {
      if (currentPlanPrice < planPrice) {
        return "Upgrade";
      }
      if (currentPlanPrice > planPrice) {
        return "Downgrade";
      }
      if (currentPlanPrice === planPrice) {
        if (billingCycle === "monthly" && isCurrentPlanMonthly) {
          return "Cancel";
        }
        if (billingCycle === "annually" && isCurrentPlanMonthly) {
          return "Upgrade";
        }

        if (billingCycle === "annually" && isCurrentPlanAnnually) {
          return "Cancel";
        }
        if (billingCycle === "monthly" && isCurrentPlanAnnually) {
          return "Downgrade";
        }
      }
    }
  };

  const handlePlanOperationClick = (plan: Plan) => {
    const buttonLabel = getButtonLabelFromPlanType(plan);

    console.log({ buttonLabel, plan });

    if (plan.key === "enterprise") {
      window.location.href = "https://pensil.in/contact";
      return;
    }

    analyticsService.track(
      //@ts-ignore
      `start-${plan.key}-pressed`,
      {},
      { operationType: buttonLabel }
    );

    if (buttonLabel === "Upgrade" || buttonLabel === "Downgrade") {
      setIsChangePlanModalVisible(true);
      setSelectedPlanToChangeTo(
        (billingCycle === "monthly" ? plan.keys?.monthly : plan.keys?.yearly) ??
          ""
      );
      return;
    }
    // if button has Cancel label, we need to cancel the plan and subscription
    else if (buttonLabel === "Cancel") {
      setIsCancelPlanModalVisible(true);
      return;
    }
    // if we need to choose a plan, do so
    else if (buttonLabel === "Select") {
      // setIsLoading(true);
      console.table({ plan: plan.key });
      // get the redirect url
      CommunityService.getCheckoutURLForCommunityPlan(
        user,
        community.id,
        billingCycle === "monthly" ? plan.keys?.monthly : plan.keys?.yearly,
        window.location.origin
      )
        .then(({ redirectUrl }) => {
          window.location.href = redirectUrl;
        })
        .catch((err) => {
          console.log({ err });
          let additionalMessage = "";
          if (
            err &&
            err.isAxiosError &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            additionalMessage = err.response.data.message;
          }
          addToast(
            additionalMessage
              ? additionalMessage
              : "Could not create checkout session!",
            "Please try again after refreshing or contact pensil support!",
            ToastTypes.danger
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="CommunitySubscriptionPage min-h-screen">
      {community?.planType !== null && (
        <div
          className={`lg:right-0" sticky top-[48px] z-[2] mb-4 flex w-full items-center border-b border-border bg-card px-5 py-3 md:h-16 lg:left-[300px] lg:w-auto`}>
          <div className="text-base font-semibold">Billing</div>
        </div>
      )}
      <div
        className={`${community?.planType === null ? "m-24" : "m-5"} rounded-md bg-card p-5`}>
        <div className="text-base font-bold">Your Plan</div>
        {community?.planExpiryDate && (
          <div className="mt-5 text-sm">
            {community?.isPlanExpired ? (
              <p className="text-red-500">
                Plan expired on{" "}
                {new Date(community?.planExpiryDate).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}
              </p>
            ) : (
              <p>
                Your Plan will renew on{" "}
                <span className="font-bold">
                  {new Date(community?.planExpiryDate).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                </span>
              </p>
            )}
          </div>
        )}
        <div className="pb-10">
          {community?.planType === null ? (
            <div className="flex flex-col">
              <p className="my-2 font-bold">Your free trial has ended</p>
              <p>
                To access {community.name}, you'll have to upgrade your
                community to a paid Pensil plan. If you have any questions,
                please reach out to support@pensil.in or book a call with a
                member of our team today.
              </p>
            </div>
          ) : (
            <div className="mt-2.5 flex flex-col border-l-2 border-border pl-5">
              <p>
                Your community is on{" "}
                <span className="font-bold">
                  {getPlanNameByKey(community?.planType)}
                </span>{" "}
                plan, billed{" "}
                <span className="font-bold">
                  {getBillingCycleByKey(community?.planType)}
                </span>{" "}
                for{" "}
                <span className="font-bold">
                  $
                  {getBillingCycleByKey(community?.planType) === "monthly"
                    ? currentPlan?.pricing?.monthly
                    : currentPlan?.pricing?.yearly}
                </span>
              </p>
              <span
                className="text-bold cursor-pointer text-primary"
                onClick={() => {
                  setIsCancelPlanModalVisible(true);
                }}>
                Cancel
              </span>
            </div>
          )}
        </div>
        {currentPlan?.key !== "enterprise" && (
          <div className="border-t border-border pt-10">
            <div className="flex w-full flex-col">
              <div className="flex">
                <div className="flex flex-col">
                  <div className="text-sm font-semibold">Billing Cycle</div>
                  <div className="mt-5 rounded bg-background p-1.5">
                    <div className="flex cursor-pointer gap-2">
                      <div
                        className={`rounded px-2.5 py-0.5 text-xs ${
                          billingCycle === "monthly" &&
                          "bg-primary text-primary-foreground"
                        }`}
                        onClick={() => setBillingCycle("monthly")}>
                        Monthly
                      </div>
                      <div
                        className={`rounded px-2.5 py-0.5 text-xs ${
                          billingCycle === "annually" &&
                          "bg-primary text-primary-foreground"
                        }`}
                        onClick={() => setBillingCycle("annually")}>
                        Annually
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full justify-around">
                  {BillingPlans.map((plan, index) => (
                    <div className="flex max-w-36 flex-col" key={index}>
                      <div className="text-xs font-semibold">{plan.name}</div>
                      {plan.pricing?.monthly && (
                        <div className="my-5 flex flex-col">
                          <div className="text-xl font-bold">
                            $
                            {billingCycle === "monthly"
                              ? plan.pricing.monthly
                              : plan.pricing.yearly}
                            <sup className="font-semibold">
                              /{billingCycle === "monthly" ? "mo" : "yr"}
                            </sup>
                          </div>
                          <div className="text-xxs font-semibold">
                            {billingCycle === "monthly"
                              ? "Billed monthly"
                              : "Billed annually"}
                          </div>
                        </div>
                      )}
                      {plan.tagline && (
                        <div className="text-xxs my-5 font-semibold">
                          {plan.tagline}{" "}
                        </div>
                      )}
                      {isLoading ? (
                        <div className="mt-3 flex items-center justify-start">
                          <Loader />
                        </div>
                      ) : (
                        <Button
                          label={
                            plan.name === "Enterprise"
                              ? "Contact Us"
                              : getButtonLabelFromPlanType(plan)
                          }
                          outlined={
                            plan.name !== "Enterprise" &&
                            getButtonLabelFromPlanType(plan) === "Cancel"
                          }
                          className="min-w-36"
                          onClick={() => {
                            handlePlanOperationClick(plan);
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <PlanLimitsTable />
            </div>
          </div>
        )}
      </div>
      <ActionModal
        active={isCancelPlanModalVisible}
        setActive={setIsCancelPlanModalVisible}
        onSubmit={(e: any) => {
          setIsLoading(true);
          setIsCancelPlanModalVisible(false);
          CommunityService.cancelCommunitySubscription(user, community.id)
            .then(
              ({
                selectedType,
                planType,
                planExpiryDate,
                isPlanExpired,
                isPaidFor,
              }) => {
                addToast(
                  "Subscription cancelled successfully!",
                  "",
                  ToastTypes.success
                );
                // update the community
                dispatch(
                  setCommunity({
                    ...community,
                    selectedType,
                    planType: null,
                    planExpiryDate,
                    isPlanExpired,
                    isPaidFor,
                  })
                );
              }
            )
            .catch((err) => {
              console.log({ err });
              addToast(
                "Could not cancel subscription!",
                "Please try again after refreshing or contact pensil support!",
                ToastTypes.danger
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        header="Cancel Subscription"
        btnColor="red"
        title="Are you sure you want to cancel the subscription?"
        isLoading={undefined}
        children={undefined}
      />
      <ActionModal
        active={isChangePlanModalVisible}
        setActive={setIsChangePlanModalVisible}
        onSubmit={(e: any) => {
          setIsChangePlanModalVisible(false);
          setIsLoading(true);
          // get the redirect url
          CommunityService.getCheckoutURLForCommunityPlan(
            user,
            community.id,
            selectedPlanToChangeTo,
            window.location.origin
          )
            .then(({ redirectUrl }) => {
              window.location.href = redirectUrl;
            })
            .catch((err) => {
              console.log({ err });
              let additionalMessage = "";
              if (
                err &&
                err.isAxiosError &&
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                additionalMessage = err.response.data.message;
              }
              addToast(
                additionalMessage
                  ? additionalMessage
                  : "Could not create checkout session!",
                "Please try again after refreshing or contact pensil support!",
                ToastTypes.danger
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        header={"Change Plan to " + selectedPlanToChangeTo}
        title="Are you sure you want to change your plan?"
        isLoading={undefined}
        btnColor={undefined}
        children={undefined}
      />
    </div>
  );
};

export default BillingPageV2;
