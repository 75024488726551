import LazyImage from "../atoms/lazy-image";

/**
 * Show all the post images
 * @param {*} param0
 * @returns
 */
export function PostImages({ images = [], onDelete = null, truncate = false }) {
  // 0 images
  if (!Array.isArray(images) || images.length < 1) {
    return <></>;
  }

  // 1 image
  if (images.length === 1) {
    return (
      <div className="PostImages align-center my-4 flex justify-center">
        <PostImage
          className="flex h-full w-full justify-center object-contain object-center"
          onDelete={onDelete}
          image={images[0]}
          index={0}
        />
      </div>
    );
  }
  // 2 images
  if (images.length === 2) {
    return (
      <div className="PostImages my-4 flex justify-between">
        {images.map((image, index) => (
          <PostImage
            key={image}
            onDelete={onDelete}
            index={index}
            image={image}
            className="h-52 w-full object-cover object-center"
          />
        ))}
      </div>
    );
  }
  // 3 images
  if (images.length === 3) {
    return (
      <div className="PostImages my-4">
        <PostImage
          className="flex h-full w-full justify-center object-cover object-center"
          onDelete={onDelete}
          image={images[0]}
          index={0}
        />
        <div className="mt-2 flex">
          <PostImage
            className="h-52 w-full object-cover object-center"
            onDelete={onDelete}
            image={images[1]}
            index={1}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            onDelete={onDelete}
            image={images[2]}
            index={2}
          />
        </div>
      </div>
    );
  }
  // 4 images
  if (images.length === 4) {
    return (
      <div className="PostImages my-4">
        <PostImage
          className="h-full w-full object-cover object-center"
          onDelete={onDelete}
          image={images[0]}
          index={0}
        />
        <div className="mt-2 flex">
          <PostImage
            className="h-52 w-full object-cover object-center"
            onDelete={onDelete}
            image={images[1]}
            index={1}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            onDelete={onDelete}
            image={images[2]}
            index={2}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            onDelete={onDelete}
            image={images[3]}
            index={3}
          />
        </div>
      </div>
    );
  }

  // 5 or more than 5 images (right now showing 5 images only)
  if (images.length >= 5) {
    // n images
    return (
      <div className="PostImages my-4">
        <div className="flex">
          <PostImage
            className="flex h-full w-full justify-center object-cover object-center"
            onDelete={onDelete}
            image={images[0]}
            index={0}
          />
          <PostImage
            className="h-full w-full object-cover object-center"
            onDelete={onDelete}
            image={images[1]}
            index={1}
          />
        </div>
        <div className="mt-2 flex">
          <PostImage
            className="h-52 w-full object-cover object-center"
            onDelete={onDelete}
            image={images[2]}
            index={2}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            onDelete={onDelete}
            image={images[3]}
            index={3}
          />
          <PostImage
            className="h-52 w-full object-cover object-center"
            onDelete={onDelete}
            image={images[4]}
            index={4}
            more={images.length - 5}
          />
        </div>
      </div>
    );
  }

  // // 4 or less images
  // if (images.length < 5 || !truncate) {
  //     // n images
  //     return (
  //         <div className="PostImage p-1 relative h-64">
  //             {
  //                 images.map(
  //                     (image, index) => <PostImage
  //                         onDelete={onDelete}
  //                         single={images.length === 1}
  //                         key={image}
  //                         index={index}
  //                         image={image} />
  //                 )
  //             }
  //         </div>
  //     )
  // }

  // // n images
  // // print first 3 and show 4th as more
  // return (
  //     <div className="PostImages my-2 flex flex-wrap">
  //         <PostImage onDelete={onDelete} image={images[0]} index={0} />
  //         <PostImage onDelete={onDelete} image={images[1]} index={1} />
  //         <PostImage onDelete={onDelete} image={images[2]} index={2} />
  //         <PostImage onDelete={onDelete} image={images[3]} index={3} more={images.length - 3} />
  //     </div>
  // )
}

function PostImage({
  image,
  single = false,
  index,
  onDelete,
  more = 0,
  className = "",
}) {
  return (
    <div className={"PostImage relative w-full p-1 " + (single ? "" : "")}>
      <LazyImage
        src={image}
        alt={"PostImage"}
        className={"h-full max-h-[70vh] w-full " + className}
      />
      {more > 0 ? (
        <div className="more h-full">
          <span>+{more}</span>
        </div>
      ) : onDelete ? (
        <div
          className="delete"
          onClick={(e) => {
            onDelete(index);
          }}>
          &times;
        </div>
      ) : (
        <></>
      )}
      {/* <img
        src={image}
        className={"h-full w-full " + className}
        alt="PostImage"
        loading="lazy"
      /> */}
    </div>
  );
}
