import { useAppSelector } from "../../hooks/redux.hook";
import { Post } from "../../types/post/post.type";
import { Button } from "../../_components";
import CreatePost from "../../_components/post/create";
import Validator from "../../_utils/validator";

const CommunityBannerFooterComponent: React.FC<{
  name: string;
  posts: any;
  setPosts: (post: any) => void;
  buttonLabel?: string;
  isCreatePostButton?: boolean;
  onClick?: any;
}> = ({ name, posts, setPosts, buttonLabel, isCreatePostButton, onClick }) => {
  const { user, community } = useAppSelector((state) => {
    return {
      user: state.auth,
      community: state.community,
    };
  });

  return (
    <>
      <div className="flex h-16 w-screen items-center justify-between border-b border-border bg-card px-5 py-3 md:w-auto">
        <div className="text-base font-semibold text-secondary-foreground">
          {name}
        </div>
        {Validator.hasValue(user) && isCreatePostButton ? (
          <CreatePost
            user={user}
            group={undefined}
            groups={undefined}
            addPost={(post: Post) => {
              setPosts([post, ...posts]);
            }}
            sectionId={undefined}
            community={community}
          />
        ) : (
          buttonLabel &&
          Validator.hasValue(user) && (
            <Button className="" label={buttonLabel} onClick={onClick} />
          )
        )}
      </div>
    </>
  );
};

export default CommunityBannerFooterComponent;
