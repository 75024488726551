import { SET_USER_COUNTERS } from "../_actions/notification.actions";

/**
 * Notification Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function notificationReducer(state = [], action) {
  switch (action.type) {
    case SET_USER_COUNTERS:
      const unreadMessagesCount = action.unreadMessagesCount,
        unreadNotificationsCount = action.unreadNotificationsCount,
        unreadSectionNotificationsCount =
          action.unreadSectionNotificationsCount;
      return {
        ...state,
        unreadMessagesCount,
        unreadNotificationsCount,
        unreadSectionNotificationsCount,
      };
    default:
      return state;
  }
}
