import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastTypes } from "../../contexts/toastr.context";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import useLang from "../../hooks/use-lang.hook";
import { useToast } from "../../hooks/use-toast.hook";
import { Button, PostCardList } from "../../_components";
import { ActionModal } from "../../_components/action-modal.component";
import { ActivityCardList } from "../../_components/activity-card-list.component";
import { CloseButton } from "../../_components/activity/close-button.component";
import I18 from "../../_components/atoms/i18";
import { MaxWidthWrapper } from "../../_components/max-width-wrapper";
import PostListShimmer from "../../_components/shimmers/post/post-list-shimmer.component";
import { redirectToAuthUrl } from "../../_config/helper.config";
import { CommunityService, PostService, UserService } from "../../_service";
import { setCommunity } from "../../_store/_actions/community.actions";
import MainUtil from "../../_utils/main.util";
import { ProfileFeedFilter } from "./components/profile-feed-filter";
import { ProfilePageHeader } from "./components/profile-page-header";
import { ProfileBioTab } from "./components/profile-tabs";
import UserMembershipCard from "./user-membership-card";
export const USER_WALL_PAGE_ROUTE = "/user/:userId";

export const DIRECT_MESSAGE_PAGE = (userId: string) =>
  `/direct-message/${userId}`;

export function createUserWallPageRoute(
  userId: string,
  tabName: string = TabType.profile
) {
  return `/user/${userId}?profile-tab=` + tabName;
}

export function createUserWallPageUrl(
  userId: string,
  tabName = TabType.profile
) {
  return window.location.origin + createUserWallPageRoute(userId, tabName);
}

export const TabType = Object.freeze({
  profile: "profile",
  activity: "activity",
  post: "posts",
  bookmark: "bookmark",
});

export default function ProfilePage() {
  const { userId } = useParams() as { userId?: string };

  return <ProfilePageComponent userId={userId!} displayedInModel={false} />;
}

export function ProfilePageComponent({
  userId,
  displayedInModel = true,
}: {
  userId: string;
  tab?: string;
  displayedInModel: boolean;
}) {
  const { user, community, mySubscription } = useAppSelector((s) => {
    return {
      community: s.community,
      user: s.auth,
      mySubscription: s.mySubscription,
    };
  });
  const lang = useLang();

  const dispatch = useAppDispatch();

  const [userDetails, setUserDetails] = useState<any>(null);
  const [isUserprofileLoading, setIsUserprofileLoading] = useState(true);

  const [isUnsubscribeModalVisible, setIsUnsubscribeModalVisible] =
    useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const activeTab = urlParams.get("profile-tab") ?? "profile";

  // holds the selected currentPage
  // const [selectedTab, setSelectedTab] = useState(activeTab ?? TabType.post); // can have posts/bookmarks
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [currentPostPage, setCurrentPostPage] = useState(1);
  const [currentActivityPage, setCurrentActivityPage] = useState(1);
  const [posts, setPosts] = useState<any>([]);
  const [activities, setActivities] = useState<any>([]);

  const [bookmarkedPosts, setBookmarkedPosts] = useState<any>([]);
  const [isLoadingBookmarkedPosts, setIsLoadingBookmarkedPosts] =
    useState(false);
  // helps with pagination
  const [isLoadingMorePosts, setIsLoadingMorePosts] = useState(false);
  const [hasMorePost, seHasMorePosts] = useState(true);
  const [isLoadingMoreActivities, setIsLoadingMoreActivities] = useState(false);
  const [noMoreActivities, setNoMoreActivities] = useState(false);

  const [imageToBePreviewed, setImageToBePreviewed] = useState(null);

  const [communityKarmaScore, setCommunityKarmaScore] = useState("");

  const [followersCount, setFollowersCount] = useState(0);
  const [followedsCount, setFollowedsCount] = useState(0);
  const [toggleFollowFollowingList, setToggleFollowFollowingList] =
    useState("");
  const [blockedUsersList, setBlockedUsersList] = useState([]);
  const [blockedUsersCount, setBlockedUsersCount] = useState(0);

  const isMyProfile = userDetails?.id === user?.id;
  const communityId = community?.id as string | undefined;

  const innerRef = React.createRef<HTMLDivElement>();

  const { addToast } = useToast();

  // fetch user posts and details
  useEffect(() => {
    if (userId && communityId) {
      // get user posts
      CommunityService.getUserPostsInCommunityById(communityId, userId).then(
        ({ posts, page }) => {
          if (posts && posts.length > 0) {
            posts = posts.map((post: any) => {
              return {
                ...post,
                comments: post.comments ?? [],
              };
            });
          }
          setPosts(posts);
          setIsLoadingPosts(false);
        }
      );
      // Get user Activities
      CommunityService.getUserActivitiesInCommunityById(communityId, userId)
        .then(({ userActivities, page }) => {
          setActivities(userActivities);
          setIsLoadingActivity(false);
        })
        .catch((error) => {
          // addToast("Something went wrong while loading posts");
          console.log({ error });
          setNoMoreActivities(true);
          setIsLoadingActivity(false);
        });
    }
  }, [communityId, userId]);

  const getFollowFollowingCount = async () => {
    try {
      const res = await UserService.getFollowFollowingCount(
        userId,
        communityId
      );
      const followerCount = res.followersCount;
      const followingCount = res.followingCount;
      setFollowersCount(followerCount);
      setFollowedsCount(followingCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getBlockedUsersList = async () => {
    try {
      const res = await UserService.getBlockedUsersList(communityId);
      setBlockedUsersList(res.blockedUser);
      setBlockedUsersCount(res.blockedUser.length);
    } catch (error) {
      console.log(error);
    }
  };

  const onUnblockUser = async (userId: string) => {
    try {
      await UserService.unblockPeer(community, userId);
      getBlockedUsersList();
    } catch (error) {
      addToast({
        type: "error",
        message: "Unable to block; Contact admin",
      });
    }
  };

  // Get user profile
  useEffect(() => {
    if (userId) {
      UserService.getUserProfileById(userId)
        .then((response) => {
          setUserDetails(response.user);
          setIsUserprofileLoading(false);
        })
        .catch((error) => {
          if (MainUtil.checkIfBlocked(error)) {
            console.log("[Block] Unauthorize error ");
          }
        });
      getFollowFollowingCount();
      getBlockedUsersList();
    }
  }, [userId]);

  // get user bookmarks, only if for auth user
  useEffect(() => {
    if (isMyProfile) {
      setIsLoadingBookmarkedPosts(true);
      PostService.getBookmarks().then(({ posts }) => {
        setBookmarkedPosts(posts);
        setIsLoadingBookmarkedPosts(false);
      });
    }
  }, [isMyProfile]);

  // To be used to fetch more posts when user scrolls to the bottom of the right sidebar model
  const onScroll = async () => {
    // console.log("scrolled", innerRef.current !== null);
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;

      console.log(
        "scrollTop + clientHeight === scrollHeight",
        scrollTop + clientHeight === scrollHeight,
        {
          height: scrollTop + scrollHeight,

          scrollHeight,
        }
      );
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        // Contributors list lazy loading you're at the bottom of the page
        // you're at the bottom of the page
        // do this when we reach end
        // and are viewing posts and not bookmark
        getLazyPosts();
      }
    }
  };

  // To be used to fetch more posts when user scrolls to the bottom of the profile page
  useEffect(() => {
    // create callback
    const callBack = () => {
      // console.log("scrolling", {
      //   window: window.innerHeight,
      //   scrollY: window.scrollY,
      //   body: document.body.offsetHeight,
      //   shouldFetch:
      //     window.innerHeight + window.scrollY + 100 >=
      //     document.body.offsetHeight,
      // });
      if (
        window.innerHeight + window.scrollY + 100 >=
        document.body.offsetHeight
      ) {
        getLazyPosts();
      }
    };

    window.addEventListener("scroll", callBack);

    return () => {
      window.removeEventListener("scroll", callBack);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingMorePosts,
    isLoadingMoreActivities,
    currentPostPage,
    posts,
    activities,
    hasMorePost,
    userId,
    communityId,
    activeTab,
    noMoreActivities,
    currentActivityPage,
  ]);

  useEffect(() => {
    const cb = (e: any) => {
      setImageToBePreviewed(e.target.src);
    };
    document.querySelectorAll("#userProfilePicture").forEach((img) => {
      img.addEventListener("click", cb);
    });

    return () => {
      document.querySelectorAll("#userProfilePicture").forEach((img) => {
        img.removeEventListener("click", cb);
      });
    };
  }, [posts]);

  // Get lazy posts
  function getLazyPosts() {
    if (!isLoadingMorePosts && hasMorePost && activeTab === TabType.post) {
      // if we are not already loading more posts, load more posts
      setIsLoadingMorePosts(true);
      CommunityService.getUserPostsInCommunityById(
        communityId,
        userId,
        currentPostPage + 1
      ).then((response) => {
        // if there are no more posts, set so
        if (response.posts.length === 0 || response.posts.length < 10) {
          seHasMorePosts(false);
        } else {
          let newList = response.posts;
          if (newList && newList.length > 0) {
            newList = newList.map((post: any) => {
              return {
                ...post,
                comments: post.comments ?? [],
              };
            });
          }
          setPosts([...posts, ...newList]);
          setCurrentPostPage(response.page);
        }
        setIsLoadingMorePosts(false);
      });
    }
    if (
      !isLoadingMoreActivities &&
      !noMoreActivities &&
      activeTab === TabType.activity
    ) {
      // if we are not already loading more posts, load more posts
      setIsLoadingMoreActivities(true);
      CommunityService.getUserActivitiesInCommunityById(
        communityId,
        userId,
        currentActivityPage + 1
      ).then((response) => {
        // if there are no more userActivities, set so
        if (response.userActivities.length === 0) {
          setNoMoreActivities(true);
        } else {
          setActivities([...activities, ...response.userActivities]);
          setCurrentActivityPage(response.page);
          setIsLoadingMoreActivities(false);
        }
        setIsLoadingMoreActivities(false);
      });
    }
  }

  // TODO: update both leasts when one is updated

  const onSubmitUnsubscribe = () => {
    //  unsub community
    CommunityService.leaveCommunity(user, community.id)
      .then(({ community }) => {
        // update community
        // updateCommunity(community);
        dispatch(setCommunity(community));
        setIsUnsubscribeModalVisible(false);
        // show message
        addToast(lang.trans("Unsubscribed from") + " " + community.name);
      })
      .catch((error) => {
        console.log({ error, message: "Subscribe to community failed!" });
        addToast(
          lang.trans("Could not unsubscribe from") + " " + community.name,
          "",
          ToastTypes.danger
        );
      });
  };

  useEffect(() => {
    if (communityId && isMyProfile) {
      // get community
      CommunityService.getMyLeaderboardScore(communityId)
        .then((response) => {
          setCommunityKarmaScore(response.score);
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, [communityId, isMyProfile]);

  if (!user) {
    return (
      <div className="PostCard flex flex-col items-center justify-center">
        <div className="my-5 text-center">
          <I18>Login to view this page</I18>
        </div>
        <Button
          label="Login"
          onClick={(e) => {
            redirectToAuthUrl(community);
          }}
        />
      </div>
    );
  }
  return (
    <div
      ref={innerRef}
      onScroll={onScroll}
      // style={{ height: "calc(100%-53px)" }}
      className={cx("UserWallPage min-h-screen overflow-y-auto py-1 md:py-3", {
        "gap-2 xl:grid-cols-3": mySubscription,
      })}>
      <MaxWidthWrapper className="max-w-2xl grid-cols-2 px-4 sm:px-0">
        <div className="flex flex-col gap-4">
          {imageToBePreviewed && (
            <div
              className="UserProfilePicFullPreview"
              onClick={(e) => {
                setImageToBePreviewed(null);
              }}>
              <div className="holder">
                <img src={imageToBePreviewed} alt="Preview" />
                <CloseButton
                  className="close-button"
                  onClick={(e) => {
                    setImageToBePreviewed(null);
                  }}
                />
              </div>
            </div>
          )}

          <ProfilePageHeader
            isLoading={isUserprofileLoading}
            isMyProfile={isMyProfile}
            userDetail={userDetails}
            setUserDetail={setUserDetails}
            displayedInModel={displayedInModel}
            userId={userId}
            followersCount={followersCount}
            followedsCount={followedsCount}
            setFollowersCount={setFollowersCount}
            toggleFollowFollowingList={toggleFollowFollowingList}
            setToggleFollowFollowingList={setToggleFollowFollowingList}
            blockedUsersList={blockedUsersList}
            blockedUsersCount={blockedUsersCount}
            onUnblockUser={onUnblockUser}
          />

          <div
            className={cx("", {
              "border-y border-border py-2": displayedInModel,
            })}>
            <ProfileFeedFilter
              isMyProfile={isMyProfile}
              isLoading={isUserprofileLoading}
            />
          </div>

          {activeTab === TabType.profile && (
            <div className={cx("", { "mx-3": displayedInModel })}>
              <ProfileBioTab
                userDetail={userDetails}
                isLoading={isLoadingPosts}
              />
            </div>
          )}

          {/* Show Activity */}
          {activeTab === TabType.activity && (
            <div className={cx("-mt-3", { "mx-3": displayedInModel })}>
              <ActivityCardList
                isLoadingPosts={isLoadingActivity}
                isLoadingMorePosts={isLoadingMoreActivities}
                activities={activities}
                updatePost={(post: any) => {
                  const index = activities.findIndex(
                    (p: any) => p.id === post.id
                  );
                  if (index !== -1) {
                    const newPosts = [...activities];
                    newPosts[index] = post;
                    setActivities(newPosts);
                  }
                }}
                isProhibited={userDetails?.isProhibited ?? false}
                isProhibitedUserUnblockable={
                  userDetails?.isProhibitedUserUnblockable
                }
              />
            </div>
          )}

          {/* Show Posts */}
          {activeTab === TabType.post && ( // show posts
            <div className={cx("-mt-3", { "mx-3": displayedInModel })}>
              <PostCardList
                posts={posts}
                allPosts={posts}
                isCommunityPost={true}
                isLoading={isLoadingPosts}
                onPostUpdate={(_, list) => {
                  if (list) {
                    setPosts(list);
                  }
                }}
                placeholder={
                  <div className="mt-2 rounded bg-card">
                    <PostListShimmer length={7} />
                  </div>
                }
                footerElement={
                  <>
                    {isLoadingMorePosts && (
                      <div className="mt-2 rounded bg-card">
                        <PostListShimmer length={7} />
                      </div>
                    )}
                    {!hasMorePost && (
                      <div className="flex h-32 place-content-center items-center text-xs text-secondary-foreground/80">
                        <I18>
                          Looks like you have reached the end of the list, No
                          more post to display
                        </I18>
                      </div>
                    )}
                  </>
                }
                noItemsElement={
                  <div className="no-post my-10 p-2 text-center">
                    <div className="my-3 font-bold">
                      <I18>Looks like it's lonely here.</I18>
                    </div>
                    <div>
                      <I18>The user hasn't posted anything yet publicly.</I18>
                    </div>
                  </div>
                }
              />
            </div>
          )}

          {activeTab === TabType.bookmark && isMyProfile && (
            // show bookmarks
            <div className={cx("-mt-3", { "mx-3": displayedInModel })}>
              <PostCardList
                posts={bookmarkedPosts.filter((p: any) => p.isBookmarkedByMe)}
                allPosts={bookmarkedPosts}
                isCommunityPost={true}
                isLoading={isLoadingBookmarkedPosts}
                onPostUpdate={(_, list) => {
                  if (list) {
                    setBookmarkedPosts(list);
                  }
                }}
                placeholder={
                  <div className="mt-2 rounded bg-card">
                    <PostListShimmer length={7} />
                  </div>
                }
                footerElement={
                  isLoadingMorePosts && (
                    <div className="mt-2 rounded bg-card">
                      <PostListShimmer length={7} />
                    </div>
                  )
                }
                noItemsElement={
                  <div className="no-post my-10 p-2 text-center">
                    <div className="my-3 font-bold">
                      <I18>Looks like it's lonely here.</I18>
                    </div>
                    <div>
                      <I18>You have no bookmarks right now.</I18>
                    </div>
                  </div>
                }
              />
            </div>
          )}

          {/* Delete tab model */}
          <ActionModal
            active={isUnsubscribeModalVisible}
            setActive={setIsUnsubscribeModalVisible}
            onSubmit={onSubmitUnsubscribe}
            btnColor="red"
            header="Unsubscribe"
            title="Are you sure you want to unsubscribe the community?"
            isLoading={undefined}
            children={undefined}
          />
        </div>
      </MaxWidthWrapper>
      {mySubscription && !displayedInModel && (
        <MaxWidthWrapper className="hidden h-full max-w-[320px] xl:block">
          <div className="sticky top-20">
            <UserMembershipCard subscription={mySubscription} />
          </div>
        </MaxWidthWrapper>
      )}
    </div>
  );
}

function showTier(dealTier: any) {
  switch (dealTier) {
    case "pensil_tier1":
      return "AppSumo Tier 1";
    case "pensil_tier2":
      return "AppSumo Tier 2";
    case "pensil_tier3":
      return "AppSumo Tier 3";
    default:
      return "";
  }
}

// const UserWallPage = withToast(
//   connect((s) => ({
//     community: s.community,
//     user: s.auth,
//     mySubscription: s.mySubscription,
//   }))(UserWallPageComponent)
// );
