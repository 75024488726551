import { CKEditor } from "@ckeditor/ckeditor5-react";
import cx from "classnames";
import { useEffect, useState } from "react";

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
// import BalloonEditor from "@chaharshubhamsingh/ckeditor5-custom-build";
import Editor from "@chaharshubhamsingh/ckeditor5-custom-build-inline";
import useLang from "../../hooks/use-lang.hook";
import { endpoints } from "../../_config";
import { createUserWallPageRoute } from "../../_pages/user-wall.page";
// import Mention from '@ckeditor/ckeditor5-mention/src/mention';

export default function RichTextEditor({
  text = "<p>Hi, guys!</p>",
  setText = (e) => {},
  getMentionsFeed = async (e) => {
    return [];
  },
  placeholder = "Write here...",
  className = "",
  onSubmit,
  uploadImage = true,
  // insertTable = true,
}) {
  const lang = useLang();
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (editor) {
      const listener = (event, data) => {
        if (data.domEvent.key === "Enter" && !data.domEvent.shiftKey) {
          // If the user pressed Enter without Shift, submit the form as before
          onSubmit(event);
        }
      };
      editor.editing.view.document.on("keydown", listener);
      return () => {
        editor.editing.view.document.off("keydown", listener);
      };
    }
  }, [editor, onSubmit]);

  return (
    <div className={cx("RichTextEditor headings " + className)}>
      <CKEditor
        editor={Editor}
        data={text}
        onReady={(editor) => {
          setEditor(editor);
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          // set text
          const data = editor.getData();
          // setText(html2md(data));
          setText(data);
        }}
        config={{
          toolbar: {
            items: [
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "|",
              "heading",
              "outdent",
              "indent",
              "codeBlock",
              "|",
              uploadImage ? "uploadImage" : null,
              "blockQuote",
              // insertTable ? "insertTable" : null,
              // "mediaEmbed"
            ],
            // heading: {
            //   // options: [
            //   //   {
            //   //     model: "paragraph",
            //   //     title: "Paragraph",
            //   //     class: "ck-heading_paragraph",
            //   //   },
            //   //   {
            //   //     model: "heading1",
            //   //     view: "h1",
            //   //     title: "Heading 1",
            //   //     class: "ck-heading_heading1",
            //   //   },
            //   //   {
            //   //     model: "heading2",
            //   //     view: "h2",
            //   //     title: "Heading 2",
            //   //     class: "ck-heading_heading2",
            //   //   },
            //   // ],
            // },
            location: "bottom",
          },
          placeholder: lang.trans(placeholder),
          extraPlugins: [MyCustomUploadAdapterPlugin, MentionLinks],
          mention: {
            feeds: [
              {
                marker: "@",
                feed: getMentionsFeed,
                minimumCharacters: 1,
                itemRenderer: customItemRenderer,
              },
            ],
          },
          fontSize: {
            options: ["tiny", "default", "big"],
          },
        }}
        onBlur={(event, editor) => {
          // console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          // console.log('Focus.', editor);
        }}
      />
    </div>
  );
}

/*
 * Customizes the way the list of user suggestions is displayed.
 * Each user has an @id, a name and an avatar.
 */
function customItemRenderer(item) {
  const itemElement = document.createElement("span");
  const avatar = document.createElement("img");
  const userNameElement = document.createElement("div");
  const fullNameElement = document.createElement("div");

  itemElement.classList.add("flex");
  itemElement.classList.add("items-center");

  avatar.src = item.picture;
  avatar.style.width = "32px";
  avatar.style.height = "32px";

  const detailHolder = document.createElement("div");
  detailHolder.style.padding = "0 6px";
  // userNameElement.style.color = '#fffa';
  userNameElement.style.fontSize = "smaller";
  userNameElement.textContent = item.id;

  // fullNameElement.style.color = 'white';
  fullNameElement.textContent = item.name;

  itemElement.appendChild(avatar);
  itemElement.appendChild(detailHolder);
  detailHolder.appendChild(fullNameElement);
  detailHolder.appendChild(userNameElement);

  return itemElement;
}

/* 
This plugin is used to listen to key events.
*/
function KeyEventPlugin(editor) {
  editor.editing.view.document.on("keydown", (evt, data) => {
    // console.log("Shift Key", data.shiftKey);
    // console.log("Pressed key: " + data.domEvent.key);
    // Do something on keydown...
  });
}
/*
 * This plugin customizes the way mentions are handled in the editor model and data.
 * Instead of a classic <span class="mention"></span>,
 */
function MentionLinks(editor) {
  // The upcast converter will convert a view
  //
  //		<a href="..." class="mention" data-mention="...">...</a>
  //
  // element to the model "mention" text attribute.
  editor.conversion.for("upcast").elementToAttribute({
    view: {
      name: "a",
      key: "data-mention",
      classes: "mention",
      attributes: {
        href: true,
      },
    },
    model: {
      key: "mention",
      value: (viewItem) =>
        editor.plugins.get("Mention").toMentionAttribute(viewItem),
    },
    converterPriority: "high",
  });

  // Downcast the model "mention" text attribute to a view
  //
  //		<a href="..." class="mention" data-mention="...">...</a>
  //
  // element.
  editor.conversion.for("downcast").attributeToElement({
    model: "mention",
    view: (modelAttributeValue, { writer }) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return;
      }

      let href;
      // User mentions are downcasted as mailto: links. Tags become normal URLs.
      if (modelAttributeValue.id[0] === "@") {
        href = `${createUserWallPageRoute(modelAttributeValue.userId)}`;
      }
      /*  else {
                href = `https://example.com/social/${modelAttributeValue.id.slice(1)}`;
            } */

      // console.log(
      //   writer.createAttributeElement(
      //     "a",
      //     {
      //       class: "mention",
      //       "data-mention": modelAttributeValue.id,
      //       href,
      //       innerText: modelAttributeValue.name,
      //     },
      //     {
      //       // Make mention attribute to be wrapped by other attribute elements.
      //       priority: 20,
      //       // Prevent merging mentions together.
      //       id: modelAttributeValue.uid,
      //     }
      //   )
      // );

      return writer.createAttributeElement(
        "a",
        {
          class: "mention",
          "data-mention": modelAttributeValue.id,
          href,
          innerText: modelAttributeValue.name,
        },
        {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid,
        }
      );
    },
    converterPriority: "high",
  });
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
    this.url = endpoints.misc.uploadImage;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open("POST", this.url, true);
    xhr.responseType = "json";
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + JSON.parse(localStorage.getItem("pensil.user")).token
    );
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;
      if (!(response && response.s3Url)) {
        return reject(
          response && response.errors && response.errors.image
            ? response.errors.image[0]
            : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: response.s3Url,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();

    this.loader.file.then((result) => {
      data.append("image", result);
      this.xhr.send(data);
    });
  }
  // https://medium.com/swlh/ckeditor5-with-custom-image-uploader-on-react-67b4496cb07d
}
