import { SET_COMMUNITY_POST_TIMER_LIMIT, SET_LAST_POST_CREATED_TIME } from "../_actions/post.actions";

export interface PostReducerState {
    postTimerLimit: number | null;
    lastPostCreatedTime: Date | null;
}

const INITIAL_STATE = {
    postTimerLimit: null,
    lastPostCreatedTime: null
} as PostReducerState;

export function postReducer(
  state = INITIAL_STATE,
  action: any
) {
  switch (action.type) {
    case SET_COMMUNITY_POST_TIMER_LIMIT:
      return {
        ...state,
        postTimerLimit: action.postTimerLimit
      };
    case SET_LAST_POST_CREATED_TIME: 
      return {
        ...state,
        lastPostCreatedTime: action.lastPostCreatedTime
      }
    default:
      return state;
  }
}
