import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  uploadMedia: (communityId, attachmentType) =>
    base +
    `community/${communityId}/` +
    `media/upload?attachmentType=${attachmentType}`,
};
