import cx from "classnames";
import { useEffect, useState } from "react";
import { useToast } from "../../../../hooks/use-toast.hook";
import { Post } from "../../../../types/post/post.type";
import { User } from "../../../../types/user/minimal-user.type";
import { PostService } from "../../../../_service";
import Validator from "../../../../_utils/validator";
import { ProgressBar } from "../../../atoms/progres-bar";
import TextButton from "../../../atoms/text-button";
import { cn } from "../../../button.component";
import RightSideModal from "../../../right-side-modal.component";
import { PostProcessing } from "../../post-card.component";
import PollResult from "../poll-result";

interface PollViewProps {
  post: Post;
  user: User;
  updatePost: (post: Post) => void;
  displayPollResult: boolean;
}

export function PollView({
  post,
  user,
  updatePost,
  displayPollResult,
}: PollViewProps) {
  const [userSelectedAnswer, setUserSelectedAnswer] = useState<
    string | undefined
  >();
  const isSharedPost = Validator.hasValue(post.shared);

  const [displayStatics, setDisplayStatics] = useState<boolean>();
  const [loading, setIsLoading] = useState<boolean>();

  const poll = isSharedPost ? post?.shared?.poll : post.poll;

  const isVotingEnded = poll && new Date(poll.endTime) < new Date();

  const question =
    poll && !poll.isQuestion && !poll.isQuiz && poll.questions[0];

  const { addToast } = useToast();

  useEffect(() => {
    // check if we have answer by user
    if (poll && poll.isAnsweredByMe)
      setUserSelectedAnswer(poll.myAnswer[0].option);
  }, [poll]);

  if (!question) return <></>;

  return (
    <div className="PostQuestion my-4 rounded-xl bg-secondary/40 p-4 text-sm">
      {/* question statement */}
      <p className="mb-2.5 scroll-m-20 font-semibold tracking-tight text-secondary-foreground">
        {question.statement}
      </p>
      {/* question options */}
      {question.options.map((option, index) => (
        <div
          key={index}
          onClick={() => {
            if (!user) {
              addToast("Please login to vote", "", "warning");
              return;
            } else if (isVotingEnded) {
              addToast("Voting has ended", "", "warning");
              return;
            }
            if (!poll.myAnswer) {
              // check if we dont have answered
              // attempt this question
              setIsLoading(true);
              PostService.voteOnPoll(
                user,
                isSharedPost ? post.sharedId : post.id,
                option
              )
                .then((response) => {
                  // update post
                  updatePost(response.post);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          }}
          className={cn(
            cx(
              "my-2 flex cursor-pointer flex-col gap-2 rounded-md border border-border bg-card p-2 text-xs transition-all duration-150 ease-in-expo hover:bg-accent",
              {
                "border-info bg-info/10":
                  userSelectedAnswer && userSelectedAnswer === option,
                "hover:scale-[1.01]": !isVotingEnded,
                "cursor-default hover:scale-100 hover:bg-card":
                  isVotingEnded || !user || userSelectedAnswer,
              }
            )
          )}>
          <div className="flex w-full flex-row items-center gap-3">
            <span
              className={cx(
                "flex h-6 w-6 place-content-center items-center rounded-full border border-border text-xs text-secondary-foreground",
                {
                  "border-info":
                    userSelectedAnswer && userSelectedAnswer === option,
                }
              )}>
              {String.fromCharCode(65 + index)}
            </span>
            <div className="flex-1">
              <p className="font-light text-card-foreground">{option}</p>
            </div>
            {/* vote count */}
            {/* POll result can only be seen to creator or user if an option is already chosen  */}
            {(userSelectedAnswer || post.isByMe) && (
              <div className="text-secondary-foreground/90">
                {poll.voteCount > 0 ? (poll.votes[option] * 100).toFixed(2) : 0}
                %
                {post.isByMe && (
                  <>
                    &nbsp; | &nbsp;
                    {Math.floor(poll.votes[option] * poll.voteCount)}
                  </>
                )}
              </div>
            )}
          </div>
          {/* POll result can only be seen to creator or user if an option is already chosen  */}
          {(userSelectedAnswer || post.isByMe) && (
            <ProgressBar max={100} value={poll.votes[option] * 100 ?? 0} />
          )}
        </div>
      ))}

      {/* View poll result button */}
      {displayPollResult && post.canDelete && (
        <TextButton
          label="View Result"
          className="text-xs"
          onClick={() => {
            setDisplayStatics(true);
          }}
        />
      )}

      <PostProcessing isProcessing={loading} />
      <RightSideModal
        active={displayStatics}
        setActive={setDisplayStatics}
        width={700}>
        <PollResult post={post} setDisplayStatics={setDisplayStatics} />
      </RightSideModal>
    </div>
  );
}
