import cx from "classnames";
import { format } from "date-fns";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { withToast } from "../../../contexts/toastr.context";
import I18 from "../../atoms/i18";
import { Button } from "../../button.component";
import { CreateSlugComponent } from "../../create-slug-modal";
import IconButton2 from "../../icon-button-2-component";
import { Modal } from "../../modal.component";
import Toggle from "../../toggle.component";
import UIcon from "../../uicon-component";
import EditPostComponent from "./component/edit-post-component";

/**
 * Edit post modal
 * @param {Object} community - community object
 * @param {Object} user - Auth user object
 * @param {Object} postToUpdate - post to update
 * @param {Function} updatePost - update post callback
 * @param {Function} addToast - Display toast callback
 * @param {Function} setActive - Toggle modal active/inactive state
 * @param {Boolean} active - Modal active/inactive state
 * @param {Object} activeGroup - Group in which post is being created. If post is being created from community home page, group is undefined.
 */
function UpdatePostModal({
  community,
  user,
  postToUpdate,
  updatePost = (post) => {},
  addToast,
  active,
  setActive,
  activeGroup,
}) {
  const [isModalFullScreen, setIsModalFullScreen] = useState(false);
  const [isAdvanceSettingsOpen, setIsAdvanceSettingsOpen] = useState(false);

  // Slugs related
  const [slugModalActive, setSlugModalActive] = useState(); // slug modal visibility
  const [slug, setSlug] = useState(postToUpdate?.meta?.slug); // slug string
  const [slugTitle, setSlugTitle] = useState(postToUpdate?.meta?.title); // slug title string
  const [slugDescription, setSlugDescription] = useState(
    postToUpdate?.meta?.description
  ); // slug description string
  const [OGTitle, setOGTitle] = useState(postToUpdate?.meta?.openGraphTitle); // OG title string
  const [OGDescription, setOGDescription] = useState(
    postToUpdate?.meta?.openGraphDescription
  ); // OG description string
  const [OGImage, setOGImage] = useState(postToUpdate?.meta?.openGraphImage); // OG image string url

  // Post Customization
  const [hideComment, setHideComment] = useState(
    postToUpdate?.preferences?.hideComment
  ); // hide comment
  const [hideLike, setHideLike] = useState(
    postToUpdate?.preferences?.hideLikes
  ); // hide like
  const [hideCreator, setHideCreator] = useState(
    postToUpdate?.preferences?.hideCreator
  ); // hide profile
  const [customDate, setCustomDate] = useState(); // hide share
  const [hideDate, setHideDate] = useState(postToUpdate?.preferences?.hideDate); // hide share
  const [showCustomDateInput, setShowCustomDateInput] = useState(false); // hide share

  const [isPostCustomizationVisible, setIsPostCustomizationVisible] =
    useState(true);
  const [isSEOSettingsInputsVisible, setIsSEOSettingsInputsVisible] =
    useState(false);
  const [isOpenGraphInputsVisible, setIsOpenGraphInputsVisible] =
    useState(false);

  function clearSlugMeta() {
    setSlug(null);
    setOGTitle(null);
    setOGDescription(null);
    setOGImage(null);
    setSlugTitle(null);
    setSlugDescription(null);
  }

  return (
    <Modal
      active={active}
      setActive={setActive}
      padding={false}
      overflow="auto"
      isModalFullScreen={isModalFullScreen}
      width={isModalFullScreen ? "100%" : "670px"}>
      <div className="CreatePost flex flex-col space-y-2 rounded-[20px] bg-card">
        {!isAdvanceSettingsOpen ? (
          <EditPostComponent
            setActive={setActive}
            user={user}
            community={community}
            postToUpdate={postToUpdate}
            updatePost={updatePost}
            addToast={addToast}
            activeGroup={activeGroup}
            isModalFullScreen={isModalFullScreen}
            setIsModalFullScreen={setIsModalFullScreen}
            setIsAdvanceSettingsOpen={setIsAdvanceSettingsOpen}
            slugModalActive={slugModalActive}
            setSlugModalActive={setSlugModalActive}
            slug={slug}
            setSlug={setSlug}
            slugTitle={slugTitle}
            setSlugTitle={setSlugTitle}
            slugDescription={slugDescription}
            setSlugDescription={setSlugDescription}
            OGTitle={OGTitle}
            setOGTitle={setOGTitle}
            OGDescription={OGDescription}
            setOGDescription={setOGDescription}
            OGImage={OGImage}
            setOGImage={setOGImage}
            hideLike={hideLike}
            setHideLike={setHideLike}
            hideComment={hideComment}
            setHideComment={setHideComment}
            hideCreator={hideCreator}
            setHideCreator={setHideCreator}
            hideDate={hideDate}
            setHideDate={setHideDate}
            customDate={customDate}
            setCustomDate={setCustomDate}
            clearSlugMeta={clearSlugMeta}
          />
        ) : (
          <div className="flex flex-col bg-card p-4">
            {/* HEADER */}
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center bg-card p-4">
                <IconButton2
                  icon="arrow-left"
                  solid
                  className="mt-0.5"
                  size="xs"
                  onClick={() => {
                    setIsAdvanceSettingsOpen(false);
                  }}
                />
                <h3 className="ml-2.5 text-base font-bold">
                  <I18>Settings</I18>
                </h3>
              </div>
              <Button
                onClick={() => {
                  setIsAdvanceSettingsOpen(false);
                }}
                label="Done"
                className="h-10 w-40 text-sm font-semibold"
                large
              />
            </div>

            <div className="flex flex-col px-5 py-5">
              <span
                className="flex items-center space-x-2 text-xs font-bold hover:cursor-pointer"
                onClick={() => {
                  setIsPostCustomizationVisible(!isPostCustomizationVisible);
                }}>
                <I18>POST CUSTOMIZATION</I18>
                <span>
                  <UIcon icon="caret-down" size="xs" className="ml-1" />
                </span>
              </span>
              {isPostCustomizationVisible && (
                <div className="my-1 mb-5 flex flex-col space-y-3 rounded bg-card">
                  <div className="flex flex-col space-y-4">
                    <div
                      className="mt-4 flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        setHideLike(!hideLike);
                      }}>
                      <Toggle
                        selectedOption={hideLike}
                        setSelectedOption={setHideLike}
                      />
                      <I18>Hide Likes from the post</I18>
                    </div>

                    <div
                      className="mt-4 flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        setHideComment(!hideComment);
                      }}>
                      <Toggle
                        selectedOption={hideComment}
                        setSelectedOption={setHideComment}
                      />
                      <I18>Hide Comment from the post</I18>
                    </div>

                    <div
                      className="mt-4 flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        setHideCreator(!hideCreator);
                      }}>
                      <Toggle
                        selectedOption={hideCreator}
                        setSelectedOption={setHideCreator}
                      />
                      <I18>Hide profile detail from the post</I18>
                    </div>

                    <div
                      className="mt-4 flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        if (!hideCreator) {
                          setHideDate(!hideDate);
                        }
                      }}>
                      <Toggle
                        selectedOption={hideDate}
                        setSelectedOption={setHideDate}
                        disabled={hideCreator}
                      />
                      <span
                        className={cx("", {
                          "text-secondary-foreground/70": hideCreator,
                        })}>
                        <I18>Hide date & time from the post</I18>
                      </span>
                    </div>

                    <div
                      className="mt-4 flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        setShowCustomDateInput(!showCustomDateInput);
                      }}>
                      <Toggle
                        selectedOption={showCustomDateInput}
                        setSelectedOption={setShowCustomDateInput}
                      />
                      <span>
                        <I18>Custom date for post</I18>
                      </span>
                    </div>
                    {showCustomDateInput && (
                      <div className="flex w-52 cursor-pointer items-center rounded border border-border bg-background bg-card px-2 py-2 outline-none">
                        <DatePicker
                          className="w-24 outline-none"
                          selected={customDate}
                          maxDate={new Date()}
                          onChange={(date) => {
                            //@ts-ignore
                            setCustomDate(new Date(date));
                          }}
                          shouldCloseOnSelect={true}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          placeholderText="Select date"
                          dateFormat="dd - MM - yyyy"
                        />
                        <div className="mr-1 h-4 w-1 border-r border-border" />
                        <div className="select-none rounded">
                          <div className="text-center text-secondary-foreground/85">
                            {customDate && format(customDate, " h:mm a")}
                            {!customDate && (
                              <h4 className="p-0 text-secondary-foreground/70">
                                <I18>time</I18>
                              </h4>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <CreateSlugComponent
                community={community}
                active={slugModalActive}
                setActive={setSlugModalActive}
                slug={slug}
                slugPrefix="p"
                //@ts-ignore
                setSlug={setSlug}
                title={slugTitle}
                //@ts-ignore
                setTitle={setSlugTitle}
                description={slugDescription}
                //@ts-ignore
                setDescription={setSlugDescription}
                OGTitle={OGTitle}
                //@ts-ignore
                setOGTitle={setOGTitle}
                OGDescription={OGDescription}
                //@ts-ignore
                setOGDescription={setOGDescription}
                //@ts-ignore
                OGImage={OGImage}
                //@ts-ignore
                setOGImage={setOGImage}
                OgImageSubtitle={
                  "By default it will take up the image uploaded with the post"
                }
                isSEOSettingsInputsVisible={isSEOSettingsInputsVisible}
                setIsSEOSettingsInputsVisible={setIsSEOSettingsInputsVisible}
                isOpenGraphInputsVisible={isOpenGraphInputsVisible}
                setIsOpenGraphInputsVisible={setIsOpenGraphInputsVisible}
                clearSlugMeta={clearSlugMeta}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

const UpdatePost = withToast(
  connect((s) => ({
    community: s.community,
    user: s.auth,
    activeGroup: s.activeGroup,
  }))(UpdatePostModal)
);

export default UpdatePost;
