import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { withToast } from "../contexts/toastr.context";
import { PostCard } from "../_components";
import { MaxWidthWrapper } from "../_components/max-width-wrapper";
import PostTileShimmer, {
  PostTileType,
} from "../_components/shimmers/post/post-tile-shimmer.component";
import Label from "../_components/typography/label.typography";
import UIcon from "../_components/uicon-component";
import { history } from "../_config";
import { RootState } from "../_store";
import {
  getPostDetailById,
  getPostDetailBySlug,
  updatePostDetail,
} from "../_store/post-detail.store";
import { getSectionPageRoute } from "./group";

export const POST_DETAIL_PAGE_ROUTE = "/post/:id";
export const POST_DETAIL_SLUG_PAGE_ROUTE = "/p/:slug";
export const createPostDetailPageRoute = (postId: string) => "/post/" + postId;
export const createPostDetailPageRouteFromSlug = (post: any) => {
  const isSharedPost = post.shared !== null && post.shared !== undefined;
  const postId = isSharedPost ? post.sharedId : post.id;
  const meta = !isSharedPost ? post.meta : post.shared.meta;
  if (meta !== undefined && meta.slug !== undefined) {
    return "/p/" + post.meta.slug;
  } else {
    return "/post/" + postId;
  }
};

export function createPostDetailPageUrl(postId: string) {
  return window.location.origin + createPostDetailPageRoute(postId);
}

function PostDetailPageComponent({
  // state
  user,
  postDetail,
  // dispatch
  getPostDetailBySlug,
  getPostDetailById,
  setPost,
}: any) {
  const { post, isLoading, statusCode } = postDetail;
  const { id, slug } = useParams() as any;

  // load post detail from Id
  useEffect(() => {
    if (id === undefined) {
      return;
    }
    getPostDetailById(id);
  }, [getPostDetailById, id]);

  // load post detail from slug
  useEffect(() => {
    if (slug === undefined) {
      return;
    }
    getPostDetailBySlug(slug);
  }, [user, slug, getPostDetailBySlug]);

  if (!user && !isLoading && statusCode === 403) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Label className="text-center">
          You need to be logged in to view this post.
        </Label>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      {/* post section link */}
      {post ? (
        <div className={`sticky top-[48px] z-[1] w-full bg-card px-5 py-3`}>
          <div
            className="group flex cursor-pointer flex-row items-center font-semibold"
            onClick={() =>
              history.push(getSectionPageRoute(post.group.id, post.tabId))
            }>
            <UIcon
              icon="arrow-left"
              className="transition-all group-hover:-translate-x-1"
            />{" "}
            &nbsp;
            {post.tab.name ? post.tab.name : ""}
          </div>
        </div>
      ) : isLoading ? (
        <div
          className={`sticky top-[48px] z-[1] w-full animate-pulse bg-card px-5 py-3`}>
          <div className="group flex cursor-pointer flex-row items-center font-semibold">
            <span className="h-4 w-28 rounded bg-gray-200"></span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <MaxWidthWrapper className="max-w-2xl pb-10">
        {/* post detail */}
        {isLoading ? (
          <div className="m-6 flex animate-pulse">
            {/* <Loader /> */}
            <div className="PostCard w-full rounded border border-border bg-card">
              <PostTileShimmer type={PostTileType.expanded} />
            </div>
          </div>
        ) : !post ? (
          <div className="my-10 flex w-full flex-col items-center">
            {statusCode === 404 && (
              <>
                <Label variant="t2" size="h2">
                  This post was not found
                </Label>
                <Label variant="s1">
                  The post may have been removed or does not exist
                </Label>
              </>
            )}
            {statusCode === 403 && (
              <>
                <Label variant="t2" size="h2">
                  This is a private post
                </Label>
                <Label variant="s1">
                  You don't have the permission to view this post
                </Label>
              </>
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-center p-3">
            <PostCard
              noMargin
              showFullPost
              post={post}
              updatePost={(post) => {
                setPost(post);
                history.push(createPostDetailPageRouteFromSlug(post));
              }}
              deletePost={() => {
                setPost(null);
              }}
              displayPollResult={true}
            />
          </div>
        )}
      </MaxWidthWrapper>
    </div>
  );
}

const dtp = {
  getPostDetailBySlug,
  getPostDetailById,
  setPost: updatePostDetail,
};

const PostDetailPage = withToast(
  connect(
    (s: RootState) => ({
      user: s.auth,
      postDetail: s.postDetail,
    }),
    dtp
  )(PostDetailPageComponent)
);

export default PostDetailPage;
