import React, { Suspense, useState } from "react";
import { useToast } from "../../../hooks/use-toast.hook";
import { Button, FormError, TextInput } from "../../../_components";
import CustomDropDown from "../../../_components/atoms/custom-dropdown";
import I18 from "../../../_components/atoms/i18";
import { CourseService } from "../../../_service";
const Picker = React.lazy(() => import("emoji-picker-react"));

/**
 * Create | Update  section component. If section is passed as prop, it will be used to update the section
 * @param {object} community the community object
 * @param {object} course the course object
 * @param {Function} setCourse - Callback function to update the course
 * @param {object} section Section is optional. If not provided, it will create a new section.other wise it will update the section.
 * @param {Function} setVisible Callback to set the visibility of the create | update section modal
 */
export default function CreateCourseSection({
  community,
  course,
  setCourse = () => {},
  section: sectionToUpdate,
  setVisible = () => {},
}) {
  const [name, setName] = useState(sectionToUpdate?.name || undefined);
  const [emoji, setEmoji] = useState(sectionToUpdate?.emoji || "#");

  const [error, setError] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const { addToast, ToastTypes } = useToast();

  /** Create | Update section in a course.
   * If sectionToUpdate is available then section will be updated.
   * Otherwise a new section will be created
   * */
  const handleCreateSection = (e) => {
    e.preventDefault();
    if (!course) {
      console.error("Course is not available");
      return;
    }
    if (!name || name.length === 0) {
      setError({ name: "Section name is required" });
      return;
    }

    const payload = {
      name: name,
      emoji: emoji,
    };
    setIsLoading(true);
    CourseService.createSection(
      community.id,
      course.id,
      payload,
      sectionToUpdate?.id
    )
      .then(({ section }) => {
        if (sectionToUpdate) {
          section = { ...section, lessons: sectionToUpdate.lessons };
          // Update the section
          const updatedSections = course.sections.map((s) => {
            if (s.id === section.id) {
              return section;
            }
            return s;
          });
          setCourse({ ...course, sections: updatedSections });
          addToast("Section created successfully");
        } else {
          // Add the section
          section = { ...section, lessons: [] };
          setCourse({ ...course, sections: [...course.sections, section] });
          addToast("Section updated successfully");
        }
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors;
        if (errors && errors?.name && Array.isArray(errors.name)) {
          setError({ name: errors.name[0] });
        } else {
          console.log(err);
        }
        addToast(
          "Unable to create section",
          "Please try again in some time!!",
          ToastTypes.danger
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="min-w-full max-w-lg">
      <div id="content-4a" className="flex flex-col space-y-12">
        <form
          className="flex flex-col space-y-2"
          onSubmit={handleCreateSection}>
          {/* <!-- Title --> */}

          <h3 className="after:ml-0.5 after:text-red-500 after:content-['*']">
            <I18>Section Name</I18>
          </h3>

          <TextInput
            placeholder="Enter section name"
            className="bg-background"
            containerClassName="bg-background"
            required
            prefix={
              <div className="bg-background px-2">
                <CustomDropDown
                  alignment="left-1"
                  button={
                    <span
                      onClick={() => setIsEmojiPickerVisible(true)}
                      className="h-6 bg-background p-1 hover:scale-110">
                      {emoji || "#"}
                    </span>
                  }>
                  {isEmojiPickerVisible && (
                    <Suspense fallback={<></>}>
                      <Picker
                        onEmojiClick={(emojiObject, event) => {
                          setEmoji(emojiObject.emoji);
                          setIsEmojiPickerVisible(false);
                        }}
                      />
                    </Suspense>
                  )}
                </CustomDropDown>
              </div>
            }
            defaultValue={name}
            onChange={(value) => {
              setName(value);
              setError({ ...error, name: "" });
            }}
            large
            noMargin
          />

          <FormError error={error.name} />
        </form>

        {/* <!-- Add course button --> */}

        <div className="flex place-content-between">
          <Button
            label="Cancel"
            flat
            onClick={(e) => {
              e.preventDefault();
              setVisible(false);
            }}
          />
          <Button
            label={sectionToUpdate ? "Update" : "Create"}
            onClick={handleCreateSection}
            disabled={error.name || error.description}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
