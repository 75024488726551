interface Props {
  userDetails: {
    // [key: string]: string | undefined;
    additionalMetaDetails: Array<{
      _id: string;
      label: string;
      value: string;
    }>;
  };
}

export function UserAdditionalMetaDetails({ userDetails }: Props) {
  const { additionalMetaDetails } = userDetails;

  if (!additionalMetaDetails?.length) return <></>;

  return (
    <div className="UserAdditionalMetaDetails">
      {additionalMetaDetails.map((detail) => (
        <div key={detail._id} className="mb-2 grid grid-cols-2">
          <div className="font-semibold">{detail.label}:</div>
          <div>{detail.value}</div>
        </div>
      ))}
    </div>
  );
}
