import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  uploadImage: base + "upload-image",
  search: (communityId) => base + "community/" + communityId + "/search",
  defaultImageFromBackendUrl:
    base.split("/api/")[0] + "/default_pictures/user_0.jpg",
};
