import cx from "classnames";
import { ToastTypes } from "../../../../contexts/toastr.context";
import MEDIA_TYPES from "../../../../_constants/mediaType";
import { MediaService } from "../../../../_service/media-service";
import Tooltip from "../../../atoms/tooltip";
import { Button } from "../../../button.component";
import { FileInput } from "../../../form-controls/file-input";
import TenorGifPicker from "../../../tenor-gif-picker";
import UIcon from "../../../uicon-component";

/**
 * Component to display toolbar at create post card bottom
 * @param {Array} images - array of images
 * @param {Function} setImages - function to set images
 * @param {Array} videos - array of videos
 * @param {Function} setVideos - function to set videos
 * @param {File} document - file to upload
 * @param {Function} setDocument - function to set document
 * @param {boolean} isEmbedEditorVisible - boolean to show embed code editor
 * @param {Function} setIsEmbedEditorVisible - function to set is embed editor visible
 * @param {boolean} isEmojiPickerVisible - boolean to show emoji picker
 * @param {Function} setIsEmojiPickerVisible - function to set is emoji picker visible
 * @param {boolean} isPollVisible - boolean to show poll
 * @param {Function} setIsPollVisibleVisible - function to set is poll visible
 * @param {boolean} isRecorderVisible - boolean to show recorder
 * @param {Function} setIsRecorderVisible - function to set is recorder visible
 * @param {boolean} isGifPickerVisible - boolean to show gif picker
 * @param {Function} setIsGifPickerVisible - function to set is gif picker visible
 * @param {string} description - description of post
 * @param {Function} setDescription - function to set description
 * @param {boolean} disableSubmit - boolean to disable submit button
 * @param {boolean} isSavingPost - boolean to show saving post
 * @param {Function} onSubmitPost - function to submit post
 * @param {String} submitButtonLabel - label of submit button. Default is "Post"
 */
function CreatePostToolbar(props) {
  const {
    community,
    user,
    images = [],
    setImages,
    videos = [],
    setVideos,
    documents = [],
    setDocument,
    description,
    setDescription,
    isEmbedEditorVisible,
    setIsEmbedEditorVisible,
    isPollVisible,
    setIsPollVisibleVisible,
    isModalFullScreen,
    setAreFilesUploading,
    isRecorderVisible,
    setIsRecorderVisible,
    isGifPickerVisible,
    setIsGifPickerVisible,
    isButtonsSectionVisible,
    setIsButtonsSectionVisible,
    uploadedMedia,
    setUploadedMedia,
    setRemovedMediaUploadsOnEdit,
    addToast,
    disableSubmit = true,
    isSavingPost = false,
    onSubmitPost = () => {},
    submitButtonLabel = "Create Post",
  } = props;

  const uploadImages = async (imagesToUpload) => {
    setAreFilesUploading(true);

    try {
      const res = await MediaService.uploadMedia(
        user,
        community.id,
        imagesToUpload,
        MEDIA_TYPES.IMAGE
      );

      setUploadedMedia((prevUploadedMedia) => ({
        ...prevUploadedMedia,
        images: [...prevUploadedMedia.images, ...res.publicUrls],
      }));
    } catch (error) {
      console.log("Error: ", error);
      setImages([]);
      addToast("Something went wrong!", "", ToastTypes.danger);
    }
    setAreFilesUploading(false);
  };

  const uploadVideo = async (videosToUpload) => {
    setAreFilesUploading(true);
    try {
      const res = await MediaService.uploadMedia(
        user,
        community.id,
        [videosToUpload],
        MEDIA_TYPES.VIDEO
      );

      setUploadedMedia((prevUploadedMedia) => ({
        ...prevUploadedMedia,
        videos: [...prevUploadedMedia.videos, ...res.publicUrls],
      }));
    } catch (error) {
      console.log("Error: ", error);
      setVideos([]);
      addToast("Something went wrong!", "", ToastTypes.danger);
    }

    setAreFilesUploading(false);
  };

  const uploadDocument = async (documentToUpload) => {
    setAreFilesUploading(true);

    const res = await MediaService.uploadMedia(
      user,
      community.id,
      [documentToUpload],
      MEDIA_TYPES.DOCUMENT
    );

    uploadedMedia.document[0] &&
      setRemovedMediaUploadsOnEdit((prevMediaItems) => [
        ...prevMediaItems,
        uploadedMedia.document[0],
      ]);

    setUploadedMedia((prevUploadedMedia) => ({
      ...prevUploadedMedia,
      document: [...prevUploadedMedia.document, ...res.publicUrls],
    }));

    setAreFilesUploading(false);
  };
  return (
    <div
      className={`Toolbar sticky bottom-0 flex justify-between border-t border-border bg-card px-2 pb-3 pt-4 ${
        isModalFullScreen ? "lg:left-1/4 lg:w-1/2" : ""
      }`}>
      <div className="ToolbarIcon grid grid-cols-9 place-items-center justify-start gap-4 text-secondary-foreground md:gap-0">
        {/* PICTURE */}
        <Tooltip label="Picture">
          <FileInput
            accept="image/*"
            maxFileSize={10}
            maxFiles={5}
            multiple
            disabled={images.length === 5}
            onChange={(imageList) => {
              if (imageList) {
                var tempImages = [...uploadedMedia.images, ...imageList].slice(
                  0,
                  5
                );
                setImages(tempImages);
                images.length > 0
                  ? uploadImages([...imageList])
                  : uploadImages(tempImages);
              }
            }}>
            <div
              className={cx("h-6 cursor-pointer hover:scale-110", {
                "cursor-not-allowed text-secondary-foreground/70":
                  images.length > 4,
              })}>
              <UIcon size="lg" icon="picture" />
            </div>
          </FileInput>
        </Tooltip>

        {/* VIDEO */}
        <Tooltip label="Video">
          <FileInput
            accept="video/*"
            disabled={false}
            onChange={(video) => {
              setVideos([video]);
              uploadVideo(video);
            }}>
            <div
              className={cx("h-6 cursor-pointer hover:scale-110", {
                "cursor-not-allowed text-secondary-foreground/70":
                  videos.length > 0,
              })}>
              <UIcon size="lg" icon="play-alt" />
            </div>
          </FileInput>
        </Tooltip>

        {/* ATTACHMENT */}
        <Tooltip label="Attach file">
          <FileInput
            accept="file/*"
            disabled={documents.length > 0}
            onChange={(doc) => {
              setDocument([doc]);
              uploadDocument(doc);
            }}>
            <div
              className={cx("h-6 cursor-pointer hover:scale-110", {
                "cursor-not-allowed text-secondary-foreground/70":
                  documents.length > 0,
              })}>
              <UIcon size="lg" icon="clip" />
            </div>
          </FileInput>
        </Tooltip>

        {/* POLL */}
        <Tooltip label="Poll" className={!setIsPollVisibleVisible && "hidden"}>
          <button
            className="12 mt-1 h-6 hover:scale-110"
            onClick={() => {
              setIsPollVisibleVisible(!isPollVisible);
            }}>
            <UIcon size="lg" icon="stats" />
          </button>
        </Tooltip>

        {/* CODE */}
        <Tooltip
          label="Embed Code"
          className={!setIsEmbedEditorVisible && "hidden"}>
          <button
            className="h-6 hover:scale-110"
            onClick={() => {
              setIsEmbedEditorVisible(!isEmbedEditorVisible);
            }}>
            <div className="flex place-content-center">
              <UIcon size="sm" icon="angle-left" />
              <UIcon size="sm" icon="angle-right" />
            </div>
          </button>
        </Tooltip>

        {/* GIF */}
        <Tooltip label="Gif" className={!setIsGifPickerVisible && "hidden"}>
          <button
            className="p-.05 mr-2 mt-1 w-8 hover:scale-110"
            onClick={() => {
              images.length < 5
                ? setIsGifPickerVisible(!isGifPickerVisible)
                : addToast(
                    "You can add 5 images or GIFs in total!",
                    "",
                    ToastTypes.danger
                  );
            }}>
            <span className="rounded border border-secondary-foreground px-1">
              Gif
            </span>
          </button>
        </Tooltip>
        <TenorGifPicker
          active={isGifPickerVisible}
          setActive={setIsGifPickerVisible}
          initialSearchQuery="HAHA"
          onGifSelect={async (gif) => {
            if (gif?.media?.[0].gif?.url) {
              setUploadedMedia((prevUploadedMedia) => ({
                ...prevUploadedMedia,
                images: [...prevUploadedMedia.images, gif?.media?.[0].gif?.url],
              }));
              setImages([...images, gif?.media?.[0].gif?.url]);
            }
            setIsGifPickerVisible(false);
          }}
        />

        {/* buttons */}
        <Tooltip
          label="Custom buttons"
          className={!setIsButtonsSectionVisible && "hidden"}>
          <button
            className="p-.05 mt-0.5 w-10 hover:scale-110"
            onClick={() => {
              setIsButtonsSectionVisible(!isButtonsSectionVisible);
            }}>
            <span className="rounded border border-secondary-foreground px-1">
              Btn
            </span>
          </button>
        </Tooltip>

        {/* Audio */}
        <Tooltip label="Record Audio">
          <button
            className="12 mt-1 h-6 hover:scale-110"
            onClick={() => {
              documents?.length === 0 &&
                setIsRecorderVisible(!isRecorderVisible);
            }}>
            <div
              className={cx("h-6 cursor-pointer hover:scale-110", {
                "cursor-not-allowed text-secondary-foreground/70":
                  documents?.length > 0,
              })}>
              <UIcon size="lg" icon="microphone" />
            </div>
          </button>
        </Tooltip>
      </div>
      <Button
        label={submitButtonLabel}
        disabled={disableSubmit}
        onClick={onSubmitPost}
        isLoading={isSavingPost}
        large
      />
    </div>
  );
}
export default CreatePostToolbar;
