import cx from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import removeMd from "remove-markdown";
import styled from "styled-components";
import { useAppService } from "../../hooks/use-app-service";
import { history } from "../../_config";
import { createPostDetailPageRouteFromSlug } from "../../_pages/post-detail.page";
import { getHighlightedPosts } from "../../_store/highlighted-posts.store";
import { Avatar } from "../avatar.component";
import UIcon from "../uicon-component";
import RightSideModal from "../right-side-modal.component";
import FeaturedPostsRightSideModal from "./featured-posts-right-side-modal.component";
import { useAppSelector } from "../../hooks/redux.hook";

const CARD_WIDTH = 210;
const CARD_HEIGHT = 225;

function HighlightedPostsComponent({ highlightedPosts }) {
  const { posts } = highlightedPosts;
  const { community, user } = useAppSelector((state) => {
    return {
      user: state.auth,
      community: state.community,
    };
  });

  const sliderRef = React.createRef();

  const [openFeaturedPostsRightSideModal, setOpenFeaturedPostsRightSideModal] = useState(false);

  const scrollToNextCard = () => {
    const scrollViewWidth = sliderRef.current.getBoundingClientRect().width;
    const scrolled = sliderRef.current.scrollLeft;
    const totalToScroll = sliderRef.current.scrollWidth;
    let newScrollLeft = 0;
    if (scrolled + scrollViewWidth < totalToScroll) {
      newScrollLeft = scrolled + scrollViewWidth;
    }
    sliderRef.current.scrollTo({
      top: 0,
      left: newScrollLeft,
      behavior: "smooth",
    });
  };

  const scrollToPrevCard = () => {
    const scrollViewWidth = sliderRef.current.getBoundingClientRect().width;
    const scrolled = sliderRef.current.scrollLeft;
    const totalToScroll = sliderRef.current.scrollWidth;
    let newScrollLeft = 0;
    if (scrolled === 0) {
      newScrollLeft = totalToScroll;
    } else if (scrolled - scrollViewWidth > 0) {
      newScrollLeft = scrolled - scrollViewWidth;
    }
    sliderRef.current.scrollTo({
      top: 0,
      left: newScrollLeft,
      behavior: "smooth",
    });
  };

  if (!posts || posts.length === 0) {
    return <></>;
  }

  return (
    <div className="relative mb-4 w-screen rounded-xl bg-card md:max-w-[640px]">
     <div className="flex px-5 pt-5 justify-between items-center">
     <div className="font-bold text-secondary-foreground">
        FEATURED POSTS
      </div>
      {user && community?.myRole === "admin" && (
        <div>
        <div className="text-primary font-semibold uppercase hover:bg-primary-light py-0.5 px-1 text-xs cursor-pointer rounded"
        onClick={() => {
          setOpenFeaturedPostsRightSideModal(true);
        }}
        >Edit Order</div>
        {openFeaturedPostsRightSideModal && (
          <RightSideModal
          width={570}
          setActive={setOpenFeaturedPostsRightSideModal}
          active={openFeaturedPostsRightSideModal}>
          <FeaturedPostsRightSideModal
           highlightedPosts={posts}
           setOpenFeaturedPostsRightSideModal={setOpenFeaturedPostsRightSideModal}
          />
        </RightSideModal>
        )}
        </div>
      )}
      </div>
      <NavigationButton
        icon="left"
        position="start"
        margin="left"
        onClick={scrollToPrevCard}
      />
      <NavigationButton icon="right" onClick={scrollToNextCard} />
      <SliderWrapper
        ref={sliderRef}
        className="flex gap-5 rounded-xl bg-card p-5">
        {posts.map((post) => (
          <HighlightedPostTile key={post?.postData?.id} post={post?.postData} />
        ))}
      </SliderWrapper>
    </div>
  );
}

function NavigationButton({
  icon = "right",
  position = "end",
  margin = "right",
  onClick = () => {},
}) {
  const positionClass = position === "start" ? "start-0" : "end-0";
  const marginClass = margin === "left" ? "ml-2" : "mr-2";

  return (
    <NavigationButtonWrapper
      className={`top-[50%] h-7 w-7 border ${positionClass} ${marginClass} absolute my-auto flex cursor-pointer justify-between rounded-full border border-border bg-card shadow-md transition-all duration-200 hover:scale-110 hover:border-border active:scale-95 active:border-primary`}
      onClick={onClick}>
      <UIcon
        icon={"caret-" + icon}
        className="text-secondary-foreground/70 hover:text-secondary-foreground/85"
      />
    </NavigationButtonWrapper>
  );
}

const NavigationButtonWrapper = styled.button`
  padding: 2px 6px;
`;

const SliderWrapper = styled.div`
  width: 100%;
  overflow: scroll;
`;

  export function HighlightedPostTile({ post, onDragStart, onDrop = (e) => {} }) {
  const { analyticsService } = useAppService();
  // check if we have media
  const media = post?.banner
    ? post?.banner
    : post?.images?.length
      ? post?.images[0]
      : null;

  const isBannerInPost = post?.banner ? true : false;

  const { title, description, createdBy } = post;

  const descriptionCutoff = media ? 50 : 115;

  const trimmedTitle = title?.length > 50 ? title.slice(0, 45) + "..." : title;
  const trimmedDescription =
    description?.length > descriptionCutoff
      ? removeMd(description).slice(0, descriptionCutoff - 4) + "..."
      : removeMd(description);
  return (
    <div draggable={true}
    onDragStart={onDragStart}
    onDragOver={(e) => {
      e.preventDefault();
    }}
    onDrop={onDrop}>
    <HighlightedPostTileWrapper
      className="overflow-none min-w-[210px] rounded-lg bg-card"
      onClick={() => {
        history.push(createPostDetailPageRouteFromSlug(post));
        analyticsService.track("featured-post-clicked", {
          postUrl:
            window.location.origin + createPostDetailPageRouteFromSlug(post),
        });
      }}>
      <div className="flex-grow">
        {media ? (
          <img
            src={media}
            className={`flex-none ${
              !post?.banner
                ? "h-[225px] w-[210px] rounded-lg"
                : "max-h-20 rounded-t-lg"
            } w-full object-cover`}
            alt="banner"
          />
        ) : (
          <></>
        )}
        {(trimmedTitle && !post?.images?.length) || isBannerInPost ? (
          <div className="px-5 pb-1.5 pt-5 text-xs font-semibold">
            {trimmedTitle}
          </div>
        ) : (
          <></>
        )}
        {(trimmedDescription && !post?.images?.length) || isBannerInPost ? (
          <p
            className={cx(
              "text-xxs break-words px-5 text-secondary-foreground/70",
              {
                "pt-5": !media && !trimmedTitle,
                "pt-5": !trimmedTitle,
              }
            )}>
            {trimmedDescription}
          </p>
        ) : (
          <></>
        )}
      </div>
      {(!post?.images?.length || isBannerInPost) && (
        <div className="mt-1.5 px-5 pb-5">
          <Avatar user={createdBy} />
        </div>
      )}
    </HighlightedPostTileWrapper>
    </div>
  );
}

const HighlightedPostTileWrapper = styled.div`
  width: ${CARD_WIDTH}px;
  height: ${CARD_HEIGHT}px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  box-shadow:
    0 0px 15px 0px rgb(0 0 0 / 0.1),
    0 0px 15px 0px rgb(0 0 0 / 0.1);
  box-shadow:
    0 0px 15px 0px rgb(136, 136, 136, 0.32),
    0 0px 15px 0px rgb(136, 136, 136, 0.32);
`;

const stp = (s) => ({
  user: s.auth,
  community: s.community,
  highlightedPosts: s.highlightedPosts,
});

const FeaturedPosts = connect(stp, {
  getHighlightedPosts,
})(HighlightedPostsComponent);

export default FeaturedPosts;