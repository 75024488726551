import { useState } from "react";
import { Link } from "react-router-dom";
import { createUserWallPageRoute } from "..";
import { useAppSelector } from "../../../hooks/redux.hook";
import { useAppService } from "../../../hooks/use-app-service";
import useModel from "../../../hooks/use-model.hook";
import { useToast } from "../../../hooks/use-toast.hook";
import { UserProfile } from "../../../types/user/user-profile.type";
import { Button } from "../../../_components";
import { MessageIcon } from "../../../_components/atoms/icons";
import LazyImage from "../../../_components/atoms/lazy-image";
import TextButton from "../../../_components/atoms/text-button";
import BlockModal from "../../../_components/block-modal";
import IconMenu2 from "../../../_components/icon-menu-2.component";
import ReportModal from "../../../_components/report-modal.component";
import RightSideModal from "../../../_components/right-side-modal.component";
import UIcon from "../../../_components/uicon-component";
import { UserService } from "../../../_service";
import { USER_PROFILE_UPDATE_PAGE_ROUTE } from "../../community-settings/profile-update.page";
import BlockedUsersList from "../blocked-users-list";
import FollowFollowingList from "../follow-following-list";
import { UserProfileHeaderShimmer } from "./user-profile-header-shimmer.component";

interface Props {
  userDetail: UserProfile;
  isLoading: boolean;
  isMyProfile: boolean;
  displayedInModel?: boolean;
  userId: string;
  followersCount: number;
  followedsCount: number;
  toggleFollowFollowingList: string;
  setFollowersCount: (value: number) => void;
  setToggleFollowFollowingList: (value: string) => void;
  setUserDetail: (value: any) => void;
  blockedUsersList: any;
  blockedUsersCount: number;
  onUnblockUser: (userId: string) => void;
}
export function ProfilePageHeader({
  userDetail,
  isLoading,
  isMyProfile,
  displayedInModel,
  userId,
  followersCount,
  followedsCount,
  setFollowersCount,
  setToggleFollowFollowingList,
  toggleFollowFollowingList,
  setUserDetail,
  blockedUsersList,
  blockedUsersCount,
  onUnblockUser,
}: Props) {
  const { analyticsService } = useAppService();
  const { user, community } = useAppSelector((s) => {
    return {
      community: s.community,
      user: s.auth,
    };
  });
  const communityId = community?.id as string | undefined;

  const [followersList, setFollowersList] = useState([]);
  const [followedList, setFollowedList] = useState([]);
  const [isFollowingUserLoading, setIsFollowingUserLoading] = useState(false);
  const [isReportModalVisible, setReportModalVisible] = useState(false);
  const [displayClosePrompt, setDisplayClosePrompt] = useState(false);
  const [active, setActive] = useState(false);
  const [blockedUsersListVisibility, setBlockedUsersListVisibility] =
    useState(false);

  const [isUserBlocked, setIsUserBlocked] = useState(false);

  const closeFollowFollowingList = () => {
    setToggleFollowFollowingList("");
  };

  const getFollowersList = async () => {
    try {
      const res = await UserService.getFollowersList(userId, communityId);
      const followersList = res.followers;
      setFollowersList(followersList);
      setToggleFollowFollowingList("follower");
    } catch (error) {
      console.log(error);
    }
  };

  const getFollowedList = async () => {
    try {
      const res = await UserService.getFollowedList(userId, communityId);
      const followedList = res.followings;
      setFollowedList(followedList);
      setToggleFollowFollowingList("followed");
    } catch (error) {
      console.log(error);
    }
  };
  const {
    DirectMessageModelState: { setIdToViewMessage, setShowDirectMessageModel },
  } = useModel();

  const {
    profileModelState: { setIdToViewProfile, setShowProfileModel },
  } = useModel();

  const { addToast } = useToast();

  const onFollowUser = async () => {
    try {
      setIsFollowingUserLoading(true);
      const followUser = await UserService.followUser(userId, communityId);
      setFollowersCount(followersCount + 1);
      setUserDetail((prevDetails: any) => ({
        ...prevDetails,
        isFollowedByMe: true,
      }));
      setIsFollowingUserLoading(false);
    } catch (error) {
      console.log(error);
      setIsFollowingUserLoading(false);
    }
  };

  const onUnFollowUser = async () => {
    try {
      setIsFollowingUserLoading(true);
      const unfollowUser = await UserService.unfollowUser(userId, communityId);
      setFollowersCount(followersCount - 1);
      setUserDetail((prevDetails: any) => ({
        ...prevDetails,
        isFollowedByMe: false,
      }));
      setIsFollowingUserLoading(false);
    } catch (error) {
      console.log(error);
      setIsFollowingUserLoading(false);
    }
  };

  const reportModalProps = {
    reportType: "User Profile Report",
    contentLink: window.location.origin + `/user/${userDetail?.id}`,
    community,
    addToast: addToast,
    activeProps: { isReportModalVisible, setReportModalVisible },
    displayProps: { displayClosePrompt, setDisplayClosePrompt },
    content: "user-profile",
  };

  return (
    <div className="h-full">
      {/* user details */}
      {isLoading ? (
        <UserProfileHeaderShimmer />
      ) : (
        <div className="ProfilePageHeader rounded-2xl bg-card">
          <div className="flex flex-col justify-between gap-2 sm:flex-row">
            {/* Profile picture, username, id, and short bio  */}
            <div className="flex w-full flex-row items-center justify-between gap-2 p-2 sm:flex-grow md:p-4">
              <div className="flex items-center gap-2">
                {!userDetail?.isProhibited ? (
                  <LazyImage
                    id="userProfilePicture"
                    src={userDetail.picture}
                    alt=""
                    className="h-[75px] w-[75px] flex-shrink-0 rounded-full border bg-background object-cover"
                  />
                ) : (
                  <UIcon icon="ban" size="6xl" />
                )}

                {/* Short Bio and userId */}
                <div className="flex flex-col gap-0.5">
                  <span className="flex items-center gap-2">
                    <p className="text-[16px] font-bold text-secondary-foreground">
                      {userDetail?.isProhibited
                        ? "Blocked User"
                        : userDetail.name}
                    </p>
                    {displayedInModel && (
                      <Link
                        to={createUserWallPageRoute(userDetail.id)}
                        className="-rotate-45 pt-1.5 text-secondary-foreground/70"
                        onClick={() => {
                          setIdToViewProfile("");
                          setShowProfileModel(false);
                        }}>
                        <UIcon icon="arrow-small-right" />
                      </Link>
                    )}
                  </span>
                  {!userDetail?.isProhibited && (
                    <p className="text-[12px] font-normal text-secondary-foreground/70">
                      @{userDetail.userId}
                    </p>
                  )}
                  <p className="mt-1 text-[12px] font-semibold text-secondary-foreground/90">
                    {userDetail?.shortBio}
                  </p>
                </div>
              </div>

              <div className="flex flex-grow items-center justify-end gap-4 sm:hidden">
                <div className="flex flex-col items-center justify-center">
                  {!isMyProfile && !userDetail?.isProhibited && (
                    <div className="flex items-center gap-2">
                      <Button
                        isLoading={isFollowingUserLoading}
                        icon={<UIcon icon="user-add" />}
                        label={
                          userDetail.isFollowedByMe ? "Following" : "Follow"
                        }
                        onClick={
                          userDetail.isFollowedByMe
                            ? onUnFollowUser
                            : onFollowUser
                        }
                        outlined={userDetail.isFollowedByMe === true}
                        className={`h-fit w-[82px] rounded-md px-2.5 py-1 text-xs font-semibold ${
                          !userDetail.isFollowedByMe &&
                          "bg-primary text-primary-foreground"
                        }`}
                      />
                      {!community.isDirectMessageDisabled && (
                        <div
                          onClick={(e) => {
                            analyticsService.track(
                              "dm-icon-clicked-on-profile"
                            );
                            setIdToViewMessage(userDetail.id);
                            setShowDirectMessageModel(true);
                          }}>
                          <MessageIcon />
                        </div>
                      )}
                      <IconMenu2
                        icon="menu-dots-vertical"
                        className="h-8 w-8"
                        actions={[
                          {
                            icon: "flag",
                            label: "Report",
                            onClick: () => {
                              setReportModalVisible(true);
                            },
                          },
                          {
                            icon: "ban",
                            label: "Block",
                            onClick: () => {
                              setActive(true);
                            },
                          },
                        ]}
                      />
                    </div>
                  )}
                  {isMyProfile && (
                    <div className="flex">
                      <Link
                        to={USER_PROFILE_UPDATE_PAGE_ROUTE}
                        onClick={() => {
                          analyticsService.track(
                            "edit-profile-clicked-on-profile"
                          );
                        }}>
                        <div className="text-sm font-normal">Edit Profile</div>
                      </Link>
                      <IconMenu2
                        icon="menu-dots-vertical"
                        className="h-8 w-8"
                        hideOnEmpty
                        actions={[
                          {
                            icon: "list",
                            label:
                              blockedUsersCount > 0 ? "Show Blocked Users" : "",
                            onClick: () => {
                              setBlockedUsersListVisibility(true);
                            },
                          },
                        ]}
                      />
                    </div>
                  )}
                  {!userDetail?.isProhibited && (
                    <div className="mt-4 flex items-center justify-between">
                      <div className="flex gap-2.5 text-xs">
                        <div
                          className={`flex gap-1 text-secondary-foreground ${
                            followersCount !== 0
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          } `}
                          onClick={() => {
                            followersCount > 0 && getFollowersList();
                          }}>
                          <span className="font-bold">{followersCount}</span>
                          <span className="font-semibold">Followers</span>
                        </div>
                        <div
                          className={`flex gap-1 text-secondary-foreground ${
                            followedsCount !== 0
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          } `}
                          onClick={() => {
                            followedsCount > 0 && getFollowedList();
                          }}>
                          <span className="font-bold">{followedsCount}</span>
                          <span className="font-semibold">Following</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="hidden pr-2 pt-4 sm:flex">
              <div className="flex flex-col items-center justify-center">
                {!isMyProfile && !userDetail?.isProhibited && (
                  <div className="flex gap-2">
                    <Button
                      isLoading={isFollowingUserLoading}
                      icon={<UIcon icon="user-add" />}
                      label={userDetail.isFollowedByMe ? "Following" : "Follow"}
                      onClick={
                        userDetail.isFollowedByMe
                          ? onUnFollowUser
                          : onFollowUser
                      }
                      outlined={userDetail.isFollowedByMe === true}
                      className={`h-fit w-[82px] rounded-md px-2.5 py-1 text-xs font-semibold ${
                        !userDetail.isFollowedByMe &&
                        "bg-primary text-primary-foreground"
                      }`}
                    />
                    {!community.isDirectMessageDisabled && (
                      <div
                        className="-mt-0.5 h-4 p-2 transition-transform duration-300 ease-in-out hover:scale-105"
                        onClick={(e) => {
                          analyticsService.track("dm-icon-clicked-on-profile");
                          setIdToViewMessage(userDetail.id);
                          setShowDirectMessageModel(true);
                        }}>
                        <MessageIcon />
                      </div>
                    )}
                    <IconMenu2
                      icon="menu-dots-vertical"
                      className="h-8 w-8"
                      actions={[
                        {
                          icon: "flag",
                          label: "Report",
                          onClick: () => {
                            setReportModalVisible(true);
                          },
                        },
                        {
                          icon: "ban",
                          label: "Block",
                          onClick: () => {
                            setActive(true);
                          },
                        },
                      ]}
                    />
                  </div>
                )}

                {isMyProfile && (
                  <div className="flex">
                    <Link to={USER_PROFILE_UPDATE_PAGE_ROUTE}>
                      <TextButton
                        className="mt-1.5 w-[90px]"
                        label="Edit Profile"
                        onClick={() => {
                          analyticsService.track(
                            "edit-profile-clicked-on-profile"
                          );
                        }}
                      />
                    </Link>
                    <IconMenu2
                      icon="menu-dots-vertical"
                      className="h-8 w-8"
                      hideOnEmpty
                      actions={[
                        {
                          icon: "list",
                          label:
                            blockedUsersCount > 0 ? "Show Blocked Users" : "",
                          onClick: () => {
                            setBlockedUsersListVisibility(true);
                          },
                        },
                      ]}
                    />
                  </div>
                )}
                {!userDetail?.isProhibited && (
                  <div className="mt-4 flex justify-between">
                    <div className="flex gap-2.5 text-xs">
                      <div
                        className={`flex gap-1 text-secondary-foreground ${
                          followersCount !== 0
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        } `}
                        onClick={() => {
                          followersCount > 0 && getFollowersList();
                        }}>
                        <span className="font-bold">{followersCount}</span>
                        <span className="font-semibold">Followers</span>
                      </div>
                      <div
                        className={`flex gap-1 text-secondary-foreground ${
                          followedsCount !== 0
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        } `}
                        onClick={() => {
                          followedsCount > 0 && getFollowedList();
                        }}>
                        <span className="font-bold">{followedsCount}</span>
                        <span className="font-semibold">Following</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {user && !isMyProfile && <ReportModal {...reportModalProps} />}

      {user &&
        (toggleFollowFollowingList === "follower" ||
          toggleFollowFollowingList === "followed") && (
          <RightSideModal
            width={570}
            className="mt-2 max-w-2xl rounded-t-2xl bg-card md:mt-0 md:rounded-l-2xl md:rounded-tr-none"
            setActive={setToggleFollowFollowingList}
            active={toggleFollowFollowingList}>
            <FollowFollowingList
              followersList={followersList}
              followedList={followedList}
              userDetails={userDetail}
              toggleFollowFollowingList={toggleFollowFollowingList}
              onClose={closeFollowFollowingList}
            />
          </RightSideModal>
        )}

      {active && (
        <BlockModal
          //@ts-ignore
          community={community.id.toString()}
          addToast={addToast}
          activeProps={{ active, setActive }}
          displayProps={{ displayClosePrompt, setDisplayClosePrompt }}
          createdById={userId}
          blockCallback={() => {
            setIsUserBlocked(true);
          }}
        />
      )}

      {blockedUsersListVisibility && blockedUsersCount > 0 && (
        <BlockedUsersList
          blockedUsersList={blockedUsersList}
          blockedUsersListVisibility={blockedUsersListVisibility}
          blockedUsersCount={blockedUsersCount}
          onUnblockUser={onUnblockUser}
          onClose={() => setBlockedUsersListVisibility(false)}
        />
      )}
    </div>
  );
}
