import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import { Group } from "../../types/group/group.type";
import CHANNEL_TYPES from "../../_constants/channelTypes";
import MODAL_TAB_TYPES from "../../_constants/modalTabTypes";
import visibilityTypes from "../../_constants/visibilityTypes";
import { GroupService } from "../../_service";
import { activateSectionAction } from "../../_store/sections.store";
import { CloseButton } from "../activity/close-button.component";
import I18 from "../atoms/i18";
import ButtonWithCheckbox from "../button-with-checkbox.component";
import { Button } from "../button.component";
import { ConvertToSlugString } from "../create-slug-modal";
import CustomRadioComponent from "../form-controls/custom-radio.component";
import EmojiPicker from "../form-controls/emoji-picker.component";
import { TextInput } from "../text-input.component";
import Toggle from "../toggle.component";
import UIcon from "../uicon-component";

const CreateSectionSidebarModal: React.FC<{
  isEditingTabActive: Boolean;
  selectedTabToEdit: any;
  setIsCreateSectionModalVisible: (modalVisibility: any) => void;
  updateActiveGroup: (group: Group) => void;
}> = ({
  updateActiveGroup,
  selectedTabToEdit,
  isEditingTabActive,
  setIsCreateSectionModalVisible,
}) => {
  const { analyticsService } = useAppService();
  const dispatch = useAppDispatch();

  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(MODAL_TAB_TYPES.BASIC);
  const [tabsExpandedState, setTabsExpandedState] = useState({
    isAppearanceTabExpanded: true,
    isNotificationTabExpanded: true,
    isSEOSettingsTabExpanded: true,
    isOpenGraphDetailsTabExpanded: true,
  });

  const isClosed = selectedTabToEdit && selectedTabToEdit.isClosed;
  const isPrivate = selectedTabToEdit && selectedTabToEdit.isPrivate;

  const [emoji, setEmoji] = useState(
    selectedTabToEdit ? selectedTabToEdit.emoji : null
  );
  const [channelName, setChannelName] = useState(
    selectedTabToEdit ? selectedTabToEdit.name : ""
  );
  const [channelType, setChannelType] = useState(
    selectedTabToEdit
      ? selectedTabToEdit.sectionType
      : CHANNEL_TYPES.POST_TYPE.POST
  );
  const [channelAccess, setChannelAccess] = useState(
    isPrivate
      ? visibilityTypes.CHANNEL.PRIVATE
      : isClosed
        ? visibilityTypes.CHANNEL.CLOSED
        : visibilityTypes.CHANNEL.OPEN
  );
  const [canUserPost, setCanUserPost] = useState(
    selectedTabToEdit
      ? selectedTabToEdit.postLevel === CHANNEL_TYPES.USER_CATEGORIES.ANYONE
      : false
  );
  const [postsSortingOrder, setPostsSortingOrder] = useState(
    selectedTabToEdit
      ? selectedTabToEdit.sortBy
      : CHANNEL_TYPES.SORTING_ORDER.LATEST_FIRST
  );
  const [postsViewType, setPostsViewType] = useState(
    selectedTabToEdit
      ? selectedTabToEdit.sectionView
      : CHANNEL_TYPES.POSTS_VIEW.LIST
  );
  const [notificationPreferences, setNotificationPreferences] = useState(
    selectedTabToEdit
      ? selectedTabToEdit.defaultNotificationLevel
      : CHANNEL_TYPES.USER_CATEGORIES.ALL
  );

  const [emailNotificationPreferences, setEmailNotificationPreferencees] =
    useState(
      selectedTabToEdit ? selectedTabToEdit.emailNotificationOnNewPost : true
    );
  const [pushNotificationPreferences, setPushNotificationPreferences] =
    useState(
      selectedTabToEdit ? selectedTabToEdit.pushNotificationOnNewPost : true
    );

  const [seoSlug, setSeoSlug] = useState(
    selectedTabToEdit && selectedTabToEdit.meta && selectedTabToEdit.meta.slug
      ? selectedTabToEdit.meta.slug
      : null
  );
  const [seoMetaTitle, setSeoMetaTitle] = useState(
    selectedTabToEdit && selectedTabToEdit.meta && selectedTabToEdit.meta.title
      ? selectedTabToEdit.meta.title
      : null
  );
  const [seoDescription, setSeoDescription] = useState(
    selectedTabToEdit &&
      selectedTabToEdit.meta &&
      selectedTabToEdit.meta.description
      ? selectedTabToEdit.meta.description
      : null
  );
  const [openGraphTitle, setOpenGraphTitle] = useState(
    selectedTabToEdit &&
      selectedTabToEdit.meta &&
      selectedTabToEdit.meta.openGraphTitle
      ? selectedTabToEdit.meta.openGraphTitle
      : null
  );
  const [openGraphDescription, setOpenGraphDescription] = useState(
    selectedTabToEdit &&
      selectedTabToEdit.meta &&
      selectedTabToEdit.meta.openGraphDescription
      ? selectedTabToEdit.meta.openGraphDescription
      : null
  );
  const [openGraphImage, setOpenGraphImage] = useState(
    selectedTabToEdit &&
      selectedTabToEdit.meta &&
      selectedTabToEdit.meta.openGraphImage
      ? selectedTabToEdit.meta.openGraphImage
      : null
  );

  const { user, community, activeGroup } = useAppSelector((s) => ({
    user: s.auth,
    community: s.community,
    activeGroup: s.activeGroup,
  }));

  function checkMaxCharacter(text: any) {
    if (text !== undefined && text !== null && text.length > 255) {
      return text.substring(0, 255);
    } else {
      return text;
    }
  }

  const createChannel = () => {
    const tab = {
      name: channelName,
      emoji,
      sectionType: channelType,
      sectionView: postsViewType,
      postLevel: canUserPost
        ? CHANNEL_TYPES.USER_CATEGORIES.ANYONE
        : CHANNEL_TYPES.USER_CATEGORIES.ADMIN_ONLY,
      defaultNotificationLevel: notificationPreferences,
      pushNotificationOnNewPost: pushNotificationPreferences,
      emailNotificationOnNewPost: emailNotificationPreferences,
      isClosed: channelAccess === visibilityTypes.CHANNEL.CLOSED,
      isPrivate: channelAccess === visibilityTypes.CHANNEL.PRIVATE,
      sortBy: postsSortingOrder,
      meta: {
        slug: ConvertToSlugString(seoSlug === null ? channelName : seoSlug),
        title: seoMetaTitle === null ? channelName : seoMetaTitle,
        description: seoDescription === null ? channelName : seoDescription,
        openGraphTitle: openGraphTitle === null ? channelName : openGraphTitle,
        openGraphDescription:
          openGraphDescription === null ? channelName : openGraphDescription,
        openGraphImage: openGraphImage,
      },
    };

    setIsLoading(true);
    // if editing a tab, update it, or create a new
    if (selectedTabToEdit) {
      GroupService.editGroupTab(user, activeGroup.id, selectedTabToEdit.id, tab)
        .then(({ group }) => {
          setIsLoading(false);
          resetFormAndClose();
          updateActiveGroup(group);

          const section = group?.tabs.find(
            (t: { id: any }) => t.id === selectedTabToEdit.id
          );
          if (section) {
            dispatch(activateSectionAction(section));
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      GroupService.createGroupTab(user, activeGroup.id, tab)
        .then(({ group }) => {
          setIsLoading(false);
          resetFormAndClose();
          updateActiveGroup(group);

          analyticsService.track("create-section", {
            userType: community.myRole,
            groupName: group.name,
            sectionName: channelName,
            sectionType: channelType,
            sectionAuth: CHANNEL_TYPES.USER_CATEGORIES.ANYONE,
          });

          if (channelType === CHANNEL_TYPES.POST_TYPE.GROUP_CHAT) {
            window.location.reload();
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    }
  };

  const resetFormAndClose = () => {
    setEmoji("");
    setChannelName("");
    setChannelType(CHANNEL_TYPES.POST_TYPE.POST);
    setChannelAccess(visibilityTypes.CHANNEL.OPEN);
    setCanUserPost(false);
    setPostsSortingOrder(CHANNEL_TYPES.SORTING_ORDER.LATEST_FIRST);
    setPostsViewType(CHANNEL_TYPES.POSTS_VIEW.LIST);
    setNotificationPreferences(CHANNEL_TYPES.USER_CATEGORIES.ALL);
    setEmailNotificationPreferencees(true);
    setPushNotificationPreferences(true);
    setSeoSlug("");
    setSeoMetaTitle("");
    setSeoDescription("");
    setOpenGraphTitle("");
    setOpenGraphDescription("");
    setOpenGraphImage("");
    setIsCreateSectionModalVisible(false);
  };

  return (
    <div className="h-max bg-card text-secondary-foreground">
      <div className="sticky top-0 flex-col border-b border-border bg-card p-4">
        <div className="flex-col items-center">
          <div className="flex justify-between">
            <span className="text-base font-bold text-secondary-foreground/80">
              {selectedTabToEdit ? (
                <I18>Edit Channel</I18>
              ) : (
                <I18>Create Channel</I18>
              )}
            </span>
            <CloseButton
              onClick={() => {
                resetFormAndClose();
              }}
            />
          </div>
          <div className="flex items-center gap-2">
            <span
              className={`${
                activeTab === MODAL_TAB_TYPES.BASIC
                  ? "cursor-pointer rounded bg-accent px-4 py-1 text-primary"
                  : "cursor-pointer px-4 py-1 text-secondary-foreground"
              }`}
              onClick={() => setActiveTab(MODAL_TAB_TYPES.BASIC)}>
              Basic Details
            </span>
            <span
              className={`${
                activeTab === MODAL_TAB_TYPES.ADVANCED
                  ? "cursor-pointer rounded bg-accent px-4 py-1 text-primary"
                  : "cursor-pointer px-4 py-1 text-secondary-foreground"
              }`}
              onClick={() => setActiveTab(MODAL_TAB_TYPES.ADVANCED)}>
              Advance Details
            </span>
          </div>
        </div>
      </div>

      {activeTab === MODAL_TAB_TYPES.BASIC && (
        <div className="h-screen px-4 py-6">
          {/* section name */}
          <div className="mb-1 text-xs font-bold after:ml-0.5 after:text-red-500 after:content-['*']">
            <I18>Channel Name</I18>
          </div>
          <TextInput
            placeholder="Enter channel name"
            className="bg-card"
            containerClassName="bg-card"
            //@ts-ignore
            prefix={
              <div className="bg-card">
                <EmojiPicker
                  emoji={emoji}
                  setEmoji={(pickedEmoji: any) => {
                    setEmoji(pickedEmoji);
                    setIsEmojiPickerVisible(false);
                  }}
                  visible={isEmojiPickerVisible}
                  setVisible={setIsEmojiPickerVisible}
                />
              </div>
            }
            defaultValue={channelName}
            validator={(value) => {
              if (value && value.length > 2) {
                return null;
              } else {
                return "Valid channel name required!";
              }
            }}
            onChange={(value) => {
              setChannelName(value);
            }}
            large
          />
          {/* Channel type */}
          {!selectedTabToEdit && (
            <div className="mt-1 flex gap-4">
              <CustomRadioComponent
                selectorTypeLabel="Channel Type"
                selectedOption={channelType}
                setSelectedOption={setChannelType}
                options={[
                  {
                    id: CHANNEL_TYPES.POST_TYPE.POST,
                    iconName: "edit",
                    selectorName: "Post",
                  },
                  {
                    id: CHANNEL_TYPES.POST_TYPE.GROUP_CHAT,
                    iconName: "comments",
                    selectorName: "Group Chat",
                  },
                ]}
              />
            </div>
          )}

          {/* Channel Access */}
          <div className="mt-1 flex gap-4">
            <CustomRadioComponent
              selectorTypeLabel="Channel Access"
              selectedOption={channelAccess}
              setSelectedOption={setChannelAccess}
              options={[
                {
                  id: visibilityTypes.CHANNEL.OPEN,
                  iconName: "unlock",
                  selectorName: "Open",
                  origin: "CHANNEL",
                  tooltips: visibilityTypes.CHANNEL_TOOLTIPS.OPEN.tooltips,
                },
                {
                  id: visibilityTypes.CHANNEL.CLOSED,
                  iconName: "lock",
                  selectorName: "Closed",
                  origin: "CHANNEL",
                  tooltips: visibilityTypes.CHANNEL_TOOLTIPS.CLOSED.tooltips,
                },
                {
                  id: visibilityTypes.CHANNEL.PRIVATE,
                  iconName: "eye-crossed",
                  selectorName: "Private",
                  origin: "CHANNEL",
                  tooltips: visibilityTypes.CHANNEL_TOOLTIPS.PRIVATE.tooltips,
                },
              ]}
            />
          </div>
          <div
            className="mt-4 flex cursor-pointer items-center gap-2"
            onClick={() => {
              setCanUserPost(!canUserPost);
            }}>
            <Toggle
              selectedOption={canUserPost}
              setSelectedOption={setCanUserPost}
            />
            <I18>Allow users to Post in this channel</I18>
          </div>
        </div>
      )}
      {activeTab === MODAL_TAB_TYPES.ADVANCED && (
        <div className="px-4 py-6">
          {/* Appearance */}
          {channelType === CHANNEL_TYPES.POST_TYPE.POST && (
            <div className="mb-6">
              <div className="mb-4 flex items-center gap-2">
                <span className="text-xs font-bold uppercase">Appearance</span>
                <span
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTabsExpandedState((prevState) => ({
                      ...prevState,
                      isAppearanceTabExpanded:
                        !prevState.isAppearanceTabExpanded,
                    }));
                  }}>
                  <UIcon
                    icon={`${
                      tabsExpandedState.isAppearanceTabExpanded
                        ? "caret-down"
                        : "caret-right"
                    }`}
                    className="text-sm"
                  />
                </span>
              </div>
              {tabsExpandedState.isAppearanceTabExpanded && (
                <>
                  <CustomRadioComponent
                    selectorTypeLabel="Sorting Order of the Posts"
                    selectedOption={postsSortingOrder}
                    setSelectedOption={setPostsSortingOrder}
                    options={[
                      {
                        id: CHANNEL_TYPES.SORTING_ORDER.LATEST_FIRST,
                        iconName: "arrow-from-bottom",
                        selectorName: "Latest First",
                      },
                      {
                        id: CHANNEL_TYPES.SORTING_ORDER.OLDEST_FIRST,
                        iconName: "arrow-from-bottom",
                        selectorName: "Oldest First",
                      },
                    ]}
                  />
                  <CustomRadioComponent
                    selectorTypeLabel="Sorting Order of the Posts"
                    selectedOption={postsViewType}
                    setSelectedOption={setPostsViewType}
                    options={[
                      {
                        id: CHANNEL_TYPES.POSTS_VIEW.LIST,
                        iconName: "menu-burger",
                        selectorName: "List View",
                      },
                      {
                        id: CHANNEL_TYPES.POSTS_VIEW.GRID,
                        iconName: "apps",
                        selectorName: "Grid View",
                      },
                      {
                        id: CHANNEL_TYPES.POSTS_VIEW.COMPACT,
                        iconName: "list",
                        selectorName: "Compact View",
                      },
                    ]}
                  />
                </>
              )}
            </div>
          )}
          {/* Notification */}
          {channelType === CHANNEL_TYPES.POST_TYPE.POST && (
            <div className="mb-6">
              <div className="mb-4 flex items-center gap-2">
                <span className="text-xs font-bold uppercase">
                  Notification
                </span>
                <span
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTabsExpandedState((prevState) => ({
                      ...prevState,
                      isNotificationTabExpanded:
                        !prevState.isNotificationTabExpanded,
                    }));
                  }}>
                  <UIcon
                    icon={`${
                      tabsExpandedState.isNotificationTabExpanded
                        ? "caret-down"
                        : "caret-right"
                    }`}
                    className="text-sm"
                  />
                </span>
              </div>
              {tabsExpandedState.isNotificationTabExpanded && (
                <>
                  <CustomRadioComponent
                    selectorTypeLabel="Default Notification preference for users when they join"
                    selectedOption={notificationPreferences}
                    setSelectedOption={setNotificationPreferences}
                    options={[
                      {
                        id: CHANNEL_TYPES.USER_CATEGORIES.ALL,
                        selectorName: "All Posts",
                      },
                      {
                        id: CHANNEL_TYPES.USER_CATEGORIES.ADMIN_ONLY,
                        selectorName: "Admin Posts Only",
                      },
                      {
                        id: CHANNEL_TYPES.USER_CATEGORIES.NONE,
                        selectorName: "None",
                      },
                    ]}
                  />
                  <div className="flex-col">
                    <div className="mt-4 text-xs font-bold">
                      Send Notification on
                    </div>
                    <div className="mt-2 flex gap-4">
                      <ButtonWithCheckbox
                        checkboxLabel="Email"
                        selectedOption={emailNotificationPreferences}
                        setSelectedOption={setEmailNotificationPreferencees}
                      />
                      <ButtonWithCheckbox
                        checkboxLabel="Mobile Push"
                        selectedOption={pushNotificationPreferences}
                        setSelectedOption={setPushNotificationPreferences}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {/* SEO settings */}
          <div className="mb-4 flex items-center gap-2">
            <span className="text-xs font-bold uppercase">SEO Settings</span>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTabsExpandedState((prevState) => ({
                  ...prevState,
                  isSEOSettingsTabExpanded: !prevState.isSEOSettingsTabExpanded,
                }));
              }}>
              <UIcon
                icon={`${
                  tabsExpandedState.isSEOSettingsTabExpanded
                    ? "caret-down"
                    : "caret-right"
                }`}
                className="text-sm"
              />
            </span>
          </div>
          {tabsExpandedState.isSEOSettingsTabExpanded && (
            <>
              <div className="mb-1 text-xs font-bold">
                <I18>Slug</I18>
              </div>
              <TextInput
                placeholder="general-info"
                className="bg-card"
                containerClassName="bg-card"
                defaultValue={seoSlug}
                onChange={(value) => {
                  setSeoSlug(value);
                }}
                large
              />
              <div className="mb-1 text-xs font-bold">
                <I18>Meta Title</I18>
              </div>
              <TextInput
                placeholder="general-info"
                className="bg-card"
                containerClassName="bg-card"
                defaultValue={seoMetaTitle}
                onChange={(value) => {
                  seoMetaTitle(value);
                }}
                large
              />
              <div className="mb-1 text-xs font-bold">
                <I18>Description</I18>
              </div>
              <div className="flex rounded border border-border">
                <textarea
                  className="block w-full flex-grow rounded bg-card px-2 py-1 focus:outline-none"
                  value={seoDescription ?? ""}
                  onChange={(e) => {
                    setSeoDescription(checkMaxCharacter(e.target.value));
                    window.setTimeout(() => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                    }, 0);
                  }}
                />
              </div>
            </>
          )}

          {/* Open Graph Details */}
          <div className="mb-4 mt-6 flex items-center gap-2">
            <span className="text-xs font-bold uppercase">
              Open Graph Details
            </span>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTabsExpandedState((prevState) => ({
                  ...prevState,
                  isOpenGraphDetailsTabExpanded:
                    !prevState.isOpenGraphDetailsTabExpanded,
                }));
              }}>
              <UIcon
                icon={`${
                  tabsExpandedState.isOpenGraphDetailsTabExpanded
                    ? "caret-down"
                    : "caret-right"
                }`}
                className="text-sm"
              />
            </span>
          </div>
          {tabsExpandedState.isOpenGraphDetailsTabExpanded && (
            <>
              <div className="mb-1 text-xs font-bold">
                <I18>Open Graph Title</I18>
              </div>
              <TextInput
                placeholder="general-info"
                className="bg-card"
                containerClassName="bg-card"
                defaultValue={openGraphTitle}
                onChange={(value) => {
                  setOpenGraphTitle(value);
                }}
                large
              />
              <div className="mb-1 text-xs font-bold">
                <I18>Open Graph Description</I18>
              </div>
              <div className="flex rounded border border-border">
                <textarea
                  className="block w-full flex-grow rounded bg-card px-2 py-1 focus:outline-none"
                  value={openGraphDescription ?? ""}
                  onChange={(e) => {
                    setOpenGraphDescription(checkMaxCharacter(e.target.value));
                    window.setTimeout(() => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                    }, 0);
                  }}
                />
              </div>
              <div className="mb-14">
                <div className="mb-1 mt-2 text-xs font-bold">
                  <I18>Open Graph Image</I18>
                </div>
                <TextInput
                  placeholder="Enter Image URL"
                  className="bg-card"
                  containerClassName="bg-card"
                  defaultValue={openGraphImage}
                  onChange={(value) => {
                    setOpenGraphImage(value);
                  }}
                  large
                />

                <p className="px-4 text-xs text-[#8C8C8C]">
                  It will take group thumbnail as image by default. Recommended
                  size - 1200 X 630 px or 1.9:1 aspect ratio
                </p>
              </div>
            </>
          )}
        </div>
      )}
      <div className="fixed bottom-0 w-full border-t border-border bg-card p-4 md:w-3/5 lg:w-[38%]">
        <div className="flex justify-between">
          <Button
            className="w-36 px-0 text-sm"
            isCancelType={true}
            label="Cancel"
            onClick={() => resetFormAndClose()}
          />
          <Button
            onClick={createChannel}
            className="text-sm"
            isLoading={isLoading}
            label={selectedTabToEdit ? "Save Changes" : "Create Channel"}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateSectionSidebarModal;
