import cx from "classnames";
import React from "react";
import I18 from "./atoms/i18";
interface Props {
  tab: string;
  selected: Boolean;
  disabled?: Boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

function TabV2({ tab, selected, onClick, className, disabled = false }: Props) {
  return (
    <div className={cx({ "cursor-not-allowed": disabled })}>
      <div
        key={tab}
        className={cx(
          "tab cursor-pointer select-none rounded-t bg-accent px-4 py-1.5 text-xs font-semibold text-primary transition duration-500 ease-in-out" +
            className,
          {
            "bg-[#F4F5F7]": selected,
          },
          {
            "pointer-events-none": disabled,
          }
        )}
        onClick={onClick}>
        <I18>{tab}</I18>
      </div>
    </div>
  );
}
export default TabV2;
