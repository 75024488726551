import Tab from "../tab.component";

/**
 * Create the tab view
 * @param {*} param0
 * @returns
 */
export function TabPills({
  selectedTab,
  setSelectedTab = (e) => {},
  tabs = [],
  className = "",
}) {
  return (
    <div className={`tab-holder flex justify-start px-3 ${className}`}>
      {tabs.map((tab) => (
        <Tab
          key={tab.key}
          onClick={(e) => {
            if (selectedTab !== tab.key) {
              setSelectedTab(tab.key);
            }
          }}
          tab={tab.label}
          selected={selectedTab === tab.key}
        />
      ))}
    </div>
  );
}
