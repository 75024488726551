import authEndpoints from "./auth.endpoints";
import communityPlanAccessEndpoints from "./community-plan-access.endpoints";
import communityEndpoints from "./community.endpoints";
import courseEndpoints from "./course.endpoints";
import customPushEndpoints from "./custom-push.endpoints";
import customSubdomainEndpoints from "./custom-subdomain.endpoints";
import directMessagesEndpoints from "./direct-messages.endpoints";
import emailSubscriptionEndpoints from "./email-subscription.endpoints";
import embeddedSectionsEndpoints from "./embedded-sections.endpoints";
import { apiEndpointBase } from "./endpoint.config";
import eventEndpoints from "./event.endpoints";
import groupEndpoints from "./group.endpoints";
import leaderboardEndpoints from "./leaderboard.endpoints";
import liveCallEndpoints from "./livecall.endpoints";
import mediaEndpoints from "./media.endpoints";
import mentionsEndpoints from "./mentions.endpoints";
import messageEndpoints from "./message.endpoints";
import miscEndpoints from "./misc.endpoints";
import nftEndpoints from "./nft.endpoints";
import openEndpoints from "./open.endpoints";
import paymentPreferenceEndpoint from "./payment-preference.endpoint";
import postHighlightEndpoints from "./post-highlight.endpoints";
import postEndpoints from "./post.endpoints";
import subscriptionEndpoint from "./subscription.endpoint";
import userEndpoints from "./user.endpoints";
import widgetEndpoints from "./widget.endpoints";

const base = apiEndpointBase;

const endpoints = {
  auth: authEndpoints,
  goLive: base + "dyte/go-live",
  community: communityEndpoints,
  group: groupEndpoints,
  directMessages: directMessagesEndpoints,
  post: postEndpoints,
  message: messageEndpoints,
  user: userEndpoints,
  misc: miscEndpoints,
  open: openEndpoints,
  mentions: mentionsEndpoints,
  media: mediaEndpoints,
  event: eventEndpoints,
  emailSubscription: emailSubscriptionEndpoints,
  embeddedSections: embeddedSectionsEndpoints,
  widget: widgetEndpoints,
  customSubdomain: customSubdomainEndpoints,
  leaderboard: leaderboardEndpoints,
  communityPlanAccess: communityPlanAccessEndpoints,
  course: courseEndpoints,
  customPush: customPushEndpoints,
  postHighlight: postHighlightEndpoints,
  nft: nftEndpoints,
  subscription: subscriptionEndpoint,
  paymentPreference: paymentPreferenceEndpoint,
  liveCall: liveCallEndpoints,
};

export { endpoints };
