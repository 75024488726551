import { Remarkable } from "remarkable";
import { UserProfile } from "../../../../types/user/user-profile.type";
import I18 from "../../../../_components/atoms/i18";
import { UserAdditionalMetaDetails } from "../user-additional-meta.details";
import { UserWallSocial } from "./user-wall-social.component";

interface Props {
  userDetail: UserProfile;
  isLoading: boolean;
}
export function ProfileBioTab({ isLoading, userDetail }: Props) {
  const md = new Remarkable();
  if (isLoading) {
    return (
      <div className="flex h-96 flex-col gap-4 rounded-2xl bg-card p-4 shadow-md"></div>
    );
  }
  if (!userDetail) return <></>;

  return (
    <div className="flex flex-col gap-4 rounded-2xl bg-card p-[30px] text-[14px] shadow-md">
      <div>
        <label className="text-[12px] font-medium">
          <I18>Bio</I18>
        </label>
        {userDetail.bio ? (
          <p
            className="NL2BR headings md:max-w-[550px]"
            dangerouslySetInnerHTML={{
              __html: md.render(userDetail.bio),
            }}
          />
        ) : (
          <div className="mt-1 rounded-md border-l-4 border-border bg-background px-4 py-4 text-sm text-secondary-foreground/70">
            <I18>No Bio available</I18>
          </div>
        )}
      </div>

      <UserAdditionalMetaDetails userDetails={userDetail} />

      <UserWallSocial userDetails={userDetail as any} />
    </div>
  );
}
