import cx from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LOGIN_ROUTE } from ".";
import { useAppService } from "../../hooks/use-app-service";
import { FormError, TextInput } from "../../_components";
import { Button } from "../../_components/button.component";
import UIcon from "../../_components/uicon-component";
import {
  getCommunityAddress,
  redirectToAuthUrl,
} from "../../_config/helper.config";
import { CommunityService } from "../../_service";
import { AuthService } from "../../_service/auth.service";
import "../../_styles/color/light-color.css";

export default function EmailAuthComponent({
  community,
  communityDetail,
  setUser,
  isRegister = false,
  redirectSubdomain = "",
  errorMessage = "",
  setErrorMessage = (e) => {},
  redirectURL = "",
  setAuthType = (e) => {},
}) {
  const [step, setStep] = useState(1);
  const [isFormSubmitable, setIsFormSubmitable] = useState(false); // to disable submit button
  const [email, setEmail] = useState(""); // to save email
  const [otp1, setOtp1] = useState(""); // to save otp
  const [otp2, setOtp2] = useState(""); // to save otp
  const [otp3, setOtp3] = useState(""); // to save otp
  const [otp4, setOtp4] = useState(""); // to save otp
  const [isLoading, setIsLoading] = useState(false);

  // check if we have a redirect url
  const urlParams = new URLSearchParams(window.location.search);
  const emailfromUrl = urlParams.get("email");
  const [error, serError] = useState("");
  const isValidEmailFromUrl =
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailfromUrl);

  useEffect(() => {
    if (isValidEmailFromUrl) {
      setEmail(emailfromUrl);
      setIsFormSubmitable(true);
    }
  }, []);

  const sendOTP = () => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*$/.test(email)) {
      return serError("Email is not valid");
    } else {
      setIsFormSubmitable(true);
    }

    setIsLoading(true);
    AuthService.requestEmailOTP(email, community ? community.id : null)
      .then((response) => {
        // go to step 2
        setStep(2);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error && error.response && error.response.data.message) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.email
          ) {
            setErrorMessage(error.response.data.errors.email[0]);
          } else {
            setErrorMessage(error.response.data.message);
          }
        }
        setIsLoading(false);
      });
  };

  // if community has third party auth details
  if (community && community.authRedirectUrl) {
    // redirect the user there for login
    redirectToAuthUrl(community);
  }

  return (
    <div>
      {step === 1 ? (
        <LoginStep1
          communityDetail={communityDetail}
          email={email}
          emailfromUrl={emailfromUrl}
          isValidEmailFromUrl={isValidEmailFromUrl}
          setEmail={setEmail}
          setStep={setStep}
          error={error}
          setError={serError}
          isLoading={isLoading}
          setUser={setUser}
          setIsLoading={setIsLoading}
          isFormSubmitable={isFormSubmitable}
          setIsFormSubmitable={setIsFormSubmitable}
          setErrorMessage={setErrorMessage}
          redirectSubdomain={redirectSubdomain}
          redirectURL={redirectURL}
          sendOTP={sendOTP}
          isRegister={isRegister}
          setAuthType={setAuthType}
        />
      ) : (
        <LoginStep2
          communityDetail={communityDetail}
          isFormSubmitable={isFormSubmitable}
          setIsFormSubmitable={setIsFormSubmitable}
          email={email}
          otp1={otp1}
          otp2={otp2}
          otp3={otp3}
          otp4={otp4}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setOtp1={setOtp1}
          setOtp2={setOtp2}
          setOtp3={setOtp3}
          setOtp4={setOtp4}
          setUser={setUser}
          setErrorMessage={setErrorMessage}
          setStep={setStep}
          sendOTP={sendOTP}
          isRegister={isRegister}
        />
      )}
    </div>
  );
}

/**
 * Step 1 of login
 * @param {*} param0
 * @returns
 */
function LoginStep1({
  communityDetail = null,
  setCountryCode = (e) => {},
  email = "",
  emailfromUrl = "",
  isValidEmailFromUrl = false,
  setEmail = (e) => {},
  setStep = (e) => {},
  error,
  isLoading = false,
  setIsLoading = (e) => {},
  setUser = (e) => {},
  isFormSubmitable = false,
  setIsFormSubmitable = (e) => {},
  setErrorMessage = (e) => {},
  redirectSubdomain = null,
  redirectURL = null,
  sendOTP = () => {},
  setError,
  isRegister = false,
  setAuthType = (e) => {},
}) {
  return (
    <div key="1">
      <div className="card bg-card">
        {communityDetail && communityDetail.logo ? (
          <div className="pensil-logo">
            <img src={communityDetail.logo} alt="" />
          </div>
        ) : redirectSubdomain ? (
          <></>
        ) : (
          <div className="pensil-logo">
            <img src="/assets/vectors/main-logo-square-blue.svg" alt="" />
          </div>
        )}
        <div className="pb-4 text-center text-lg font-semibold text-secondary-foreground/85">
          {isRegister ? "Register" : "Login"}
        </div>
        <span className="block text-center font-semibold text-secondary-foreground/85">
          Continue with your email
        </span>
        <div className="flex w-full items-center">
          {/* input box */}
          <TextInput
            placeholder="Enter Email"
            readOnly={isValidEmailFromUrl}
            defaultValue={isValidEmailFromUrl ? emailfromUrl : ""}
            containerClassName="flex-grow block"
            className={cx("py-2", {
              "text-secondary-foreground/80": isValidEmailFromUrl,
            })}
            onChange={(value) => {
              setEmail(value);
              setIsFormSubmitable(true);
              setError("");
            }}
          />
        </div>
        <FormError error={error} />
        <div className="flex justify-center">
          <Button
            disabled={!isFormSubmitable}
            onClick={sendOTP}
            className="w-full py-2"
            isLoading={isLoading}
            label={isRegister ? "Get Started" : "Login"}
          />
        </div>
        {/* or */}
        <div className="or flex items-center justify-center">
          <span className="line"></span>
          <span className="text">or</span>
          <span className="line"></span>
        </div>
        {/* switch login */}
        <div className="mt-2">
          Want to use different method?{" "}
          <Link
            to={LOGIN_ROUTE + (redirectURL ? "?redirect=" + redirectURL : "")}
            onClick={(e) => {
              e.preventDefault();
              setAuthType(null);
            }}>
            Login
          </Link>
        </div>
      </div>
      <div className="pt-6">
        <div className="flex justify-between pb-4 text-center text-xs text-secondary-foreground/85">
          {/* By clicking “Continue with Google/Email” above, you acknowledge that you have read and understood, and agree to Pensilʼs Terms & Conditions and Privacy Policy */}
          <span>
            By clicking “Continue with Email/Start with Google/Start with
            Facebook” above, you acknowledge that you have read and understood,
            and agree to our&nbsp;
            <span>
              <a
                href={
                  communityDetail && communityDetail.termsAndConditionsLink
                    ? communityDetail.termsAndConditionsLink
                    : "https://www.pensil.in/terms-conditions"
                }>
                Terms &amp; Conditions
              </a>
            </span>
            &nbsp;and&nbsp;
            <span>
              <a
                href={
                  communityDetail && communityDetail.privacyPolicyLink
                    ? communityDetail.privacyPolicyLink
                    : "https://www.pensil.in/privacy-policy"
                }>
                Privacy Policy
              </a>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

/**
 * Step 2 of login
 * @param {*} param0
 * @returns
 */
function LoginStep2({
  communityDetail = null,
  // isFormSubmitable = false,
  // setIsFormSubmitable = (e) => {},
  countryCode = "",
  email = "",
  // otp1 = "",
  // otp2 = "",
  // otp3 = "",
  // otp4 = "",
  // setOtp1 = (e) => {},
  // setOtp2 = (e) => {},
  // setOtp3 = (e) => {},
  // setOtp4 = (e) => {},
  isLoading = false,
  setIsLoading = (e) => {},
  setUser = (e) => {},
  setStep = (e) => {},
  setErrorMessage = (e) => {},
  sendOTP = () => {},
  isRegister = false,
}) {
  const [otp, setOtp] = useState("");
  const { analyticsService } = useAppService();

  const [isFormSubmitable, setIsFormSubmitable] = useState(false); // to disable submit button

  const validateOTP = async () => {
    setIsLoading(true);

    try {
      // send otp to server
      const response = await AuthService.verifyEmailOTP(
        countryCode,
        email,
        parseInt(otp)
      );

      const { user } = response;

      // save the user
      localStorage.setItem("pensil.user", JSON.stringify(response.user));

      let communityurl = null;

      // auto subscribe the community
      if (communityDetail) {
        try {
          const community = await CommunityService.getCommunityById(
            user,
            communityDetail.id
          );

          // subscribe the user if not subscribed
          if (community && !community.joined) {
            // subscribe to community
            await CommunityService.joinCommunity(user, community.id);
            analyticsService.track("subscribe-community", {
              communityPlan: community.plan ? community.plan.planType : "free",
            });
          }
        } catch (error) {
          console.log({ error, message: "Loading community by id failed!" });
        }

        communityurl = getCommunityAddress(communityDetail);
      }

      // go to homepage
      // mixpanel event
      const properties = isRegister
        ? {
            email: email,
          }
        : {
            email: email,
            userName: user?.name,
          };

      analyticsService.track(isRegister ? "signup" : "login", properties);

      // set user data
      setUser(response.user);
    } catch (error) {
      if (error && error.response && error.response.data.message) {
        if (error.response.data.errors.email) {
          setErrorMessage(error.response.data.errors.email[0]);
        }
        if (error.response.data.errors.otp) {
          setErrorMessage(error.response.data.errors.otp[0]);
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <div
      key="2"
      className="card bg-card"
      style={{
        paddingTop: "1rem",
      }}>
      {communityDetail && communityDetail.logo ? (
        <div className="pensil-logo">
          <img src={communityDetail.logo} alt="" />
        </div>
      ) : (
        <div className="pensil-logo">
          <img src="/assets/vectors/main-logo-white.svg" alt="" />
        </div>
      )}
      <div
        className="my-2 flex cursor-pointer items-center space-x-4 pb-8"
        onClick={(e) => {
          setStep(1);
        }}>
        <UIcon icon="angle-left" />
        <span className="font-semibold text-secondary-foreground/85">
          Confirm Email
        </span>
      </div>
      <span className="font-semibold text-secondary-foreground/85">
        Please Enter the magic code sent on {countryCode}
        {email}
      </span>
      <div className="mt-2 rounded border border-border focus:outline-none">
        <input
          type="text"
          maxLength={4}
          className="flex w-full rounded bg-transparent py-3 text-center text-xl focus:outline-none"
          value={otp}
          onChange={(e) => {
            if (/^[0-9]*$/.test(e.target.value)) {
              setOtp(e.target.value);
            }
            if (e.target.value.length === 4) {
              setIsFormSubmitable(true);
            } else {
              setIsFormSubmitable(false);
            }
          }}
        />
      </div>
      {/* <div className="OTPBox border border-border rounded px-6 pb-3 mt-4">
        <OTPField otp={otp1} setOTP={setOtp1} index={1} />
        <OTPField otp={otp2} setOTP={setOtp2} index={2} />
        <OTPField otp={otp3} setOTP={setOtp3} index={3} />
        <OTPField otp={otp4} setOTP={setOtp4} index={4} />
      </div> */}
      <div className="mb-10 text-right">
        <span
          onClick={sendOTP}
          className="cursor-pointer px-1 text-xs font-semibold text-primary">
          Re-Send Code
        </span>
      </div>
      <div className="flex justify-center">
        <Button
          disabled={!isFormSubmitable}
          onClick={validateOTP}
          className="w-full py-2"
          isLoading={isLoading}
          label="Confirm"
        />
      </div>
    </div>
  );
}

function OTPField({ otp = "", setOTP = (e) => {}, index = "" }) {
  return (
    <input
      type="text"
      id={"OTPField" + index}
      className={cx(
        "OTPField border-b border-border bg-transparent hover:border-primary",
        {
          filled: otp.length > 0,
        }
      )}
      onFocus={(e) => {
        setOTP(""); // clear on focus
      }}
      autoComplete="new-password"
      value={otp}
      onClick={(e) => {
        setOTP("");
      }}
      onChange={(e) => {
        if (/^(\d){1}$/.test(e.target.value)) {
          setOTP(e.target.value);
          // focus next node
          if (index < 4) {
            document.getElementById("OTPField" + (index + 1)).focus();
          }
        }
      }}
    />
  );
}
