interface Props {
  selectedOption?: any;
  setSelectedOption: (value: boolean) => void;
  disabled?: boolean;
}

const Toggle: React.FC<Props> = ({
  selectedOption,
  setSelectedOption,
  disabled = false,
}) => {
  return (
    <>
      <button
        disabled={disabled}
        className={`flex w-10 cursor-pointer rounded-2xl p-0.5 text-primary ${
          selectedOption
            ? "place-content-end bg-primary"
            : "place-content-start bg-muted"
        }`}
        onClick={() => {
          setSelectedOption(!selectedOption);
        }}>
        <div className="flex h-4 w-4 place-content-center items-center rounded-full bg-[#ffffff]"></div>
      </button>
    </>
  );
};

export default Toggle;
