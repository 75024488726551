import cx from "classnames";
import { cn } from "../button.component";

export function CloseButton({
  onClick = (e: any) => {},
  className,
  size = "md",
  type = "general",
}: {
  onClick?: (e: any) => void;
  className?: string;
  size?: "xs" | "sm" | "md" | "lg";
  type?: "primary" | "general";
}) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();

        if (onClick) onClick(e);
      }}
      className={cn(
        cx(
          "group inline-flex items-center justify-center gap-2 justify-self-center rounded-full",
          "transition duration-300 focus-visible:outline-none active:scale-95",

          {
            "h-4 w-4": size === "xs",
            "h-6 w-6": size === "sm",
            "h-10 w-10": size === "md",
            "h-14 w-14": size === "lg",
          },
          {
            "text-primary hover:bg-primary-light": type === "primary",
            "text-secondary-foreground/85 hover:bg-background":
              type === "general",
          },
          className
        )
      )}
      aria-label="close dialog">
      <span className="relative only:-mx-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={cx("", {
            "h-3 w-3": size === "xs",
            "h-4 w-4": size === "sm",
            "h-5 w-5": size === "md",
            "h-7 w-7": size === "lg",
          })}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          role="img"
          aria-labelledby="title-79 desc-79">
          <title id="title-79">Icon title</title>
          <desc id="desc-79">A more detailed description of the icon</desc>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
    </button>
  );
}
