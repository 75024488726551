import constants from "../../_config/constants.config";
import { CommunityService } from "../../_service";

export const SET_COMMUNITY_POST_TIMER_LIMIT = "SET_COMMUNITY_POST_TIMER_LIMIT";
export const SET_LAST_POST_CREATED_TIME = "SET_LAST_POST_CREATED_TIME";
export function setCommunityPostTimerLimit(postTimerLimit: number) {
  return {
    type: SET_COMMUNITY_POST_TIMER_LIMIT,
    postTimerLimit,
  };
}

export function setLastPostCreatedTime(lastPostCreatedTime: Date) {
  return {
    type: SET_LAST_POST_CREATED_TIME,
    lastPostCreatedTime
  }
}

export function getCommunityPostTimerLimit() {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community, post } = getState();
      if (!user || !community) {
        return;
      }
      const communityConfigurableData = await CommunityService.getConfigurable(
          community.id,
          constants.COMMUNITY_POST_TIMER_LIMIT
        );
      const postTimeLimit =  Number(communityConfigurableData?.configurable?.configurableValue) || 0;
      console.log({postTimeLimit})
      dispatch(setCommunityPostTimerLimit(postTimeLimit));
    } catch (error) {
      console.log(error);
    }
  };
}
