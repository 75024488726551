import cx from "classnames";
import { useState } from "react";
import editIcon from "../../_assets/vectors/edit-gray-icon.svg";
import I18 from "../../_components/atoms/i18";
import { Modal } from "../../_components/modal.component";
import { NL2BR } from "../../_components/nl2br.component";
import { CommunityBannerSettingsComponent } from "../community-settings/community-banner-settings.page";

/**
 * Shows the header info about community
 * @param {*} param0
 * @returns
 */
function CommunityInfoComponent({ community, setCommunity, user, addToast }) {
  const [
    isCommunityBannerEditModalVisible,
    setIsCommunityBannerEditModalVisible,
  ] = useState(false);

  /// Display nothing if community banner, title and banner is not available
  if (
    !(community.banner || community.bannerTitle || community.bannerDescription)
  ) {
    return null;
  }

  return (
    <>
      {/* user details */}
      <div
        className={cx("CommunityInfo group relative border-border", {
          "border-b bg-card":
            community.banner ||
            community.bannerTitle ||
            community.bannerDescription,
          "border border-dashed": !(
            community.banner ||
            community.bannerTitle ||
            community.bannerDescription
          ),
        })}>
        <div className="flex flex-col justify-center">
          {community.banner && (
            <img
              style={{ aspectRatio: 16 / 6 }}
              src={community.banner}
              alt="img"
              className="w-full object-cover"
            />
          )}

          {/* details */}
          <div className="flex-grow">
            <div
              className={cx("", {
                "px-5 py-3":
                  community.bannerTitle || community.bannerDescription,
              })}>
              {community.bannerTitle && (
                <div className="text-base font-bold">
                  {community.bannerTitle}
                </div>
              )}

              {community.bannerDescription && (
                <div className="pt-2 text-sm font-semibold">
                  <NL2BR text={community.bannerDescription} />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* edit banner button */}
        {community.myRole === "admin" && (
          <div className="invisible absolute bottom-2 right-2 rounded bg-background p-2 group-hover:visible">
            <div
              className="flex cursor-pointer items-center rounded text-xs text-secondary-foreground/80"
              onClick={(e) => {
                setIsCommunityBannerEditModalVisible(true);
              }}>
              <img src={editIcon} alt="" className="pr-2" />{" "}
              <span>
                <I18>Edit Banner</I18>
              </span>
            </div>
          </div>
        )}

        {/*Update community banner modal */}
        {community.myRole === "admin" && (
          <Modal
            width="640px"
            padding={false}
            active={isCommunityBannerEditModalVisible}
            setActive={setIsCommunityBannerEditModalVisible}>
            <CommunityBannerSettingsComponent
              addToast={addToast}
              community={community}
              user={user}
              setCommunity={setCommunity}
              setIsCommunityBannerEditModalVisible={
                setIsCommunityBannerEditModalVisible
              }
            />
          </Modal>
        )}
      </div>
    </>
  );
}

const CommunityInfo = CommunityInfoComponent;
export default CommunityInfo;
