import { useCallback, useMemo, useState } from "react";
import { CloseButton } from "../activity/close-button.component";
import I18 from "../atoms/i18";
import { MessageIcon } from "../atoms/icons";
import RightSideModal from "../right-side-modal.component";
import { DirectMessageUsersList } from "../sidebar/direct-messages-user-list";

export function useUserMessageListModel() {
  const [showMessageListModel, setShowMessageListModel] = useState(false);

  const MessageListSidebarCallback = useCallback(() => {
    return (
      <RightSideModal
        active={showMessageListModel}
        setActive={setShowMessageListModel}
        className="mt-2 max-w-2xl rounded-t-2xl bg-card md:mt-0 md:rounded-l-2xl md:rounded-tr-none">
        <div className="sticky top-0 z-10 flex items-center justify-between border-b border-border bg-card px-4 py-2">
          <span className="flex items-center gap-2 font-bold">
            <MessageIcon />
            <I18>Direct Message</I18>
          </span>
          <CloseButton
            onClick={() => setShowMessageListModel(false)}
            type="primary"
          />
        </div>
        <div className="h-full flex-1 bg-card">
          <DirectMessageUsersList />
        </div>
      </RightSideModal>
    );
  }, [showMessageListModel, setShowMessageListModel]);

  return useMemo(
    () => ({
      messageListModelState: {
        setShowMessageListModel,
      },
      MessageListSidebarModel: MessageListSidebarCallback,
    }),
    [setShowMessageListModel, MessageListSidebarCallback]
  );
}
