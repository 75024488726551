import { SVGProps } from "react";

export function BackArrowIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="9"
      height="7"
      viewBox="0 0 9 7"
      fill="none"
      stroke="100"
      className={`fill-current ${props.className}`}
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.08333 2.91713H2.25L4.16917 0.997961C4.22384 0.943733 4.26724 0.879216 4.29685 0.808131C4.32647 0.737047 4.34172 0.660802 4.34172 0.583795C4.34172 0.506788 4.32647 0.430543 4.29685 0.359459C4.26724 0.288374 4.22384 0.223857 4.16917 0.169629C4.05987 0.0609825 3.91203 0 3.75792 0C3.60381 0 3.45596 0.0609825 3.34667 0.169629L0.844167 2.67796C0.625074 2.89575 0.501302 3.19154 0.5 3.50046C0.502839 3.80735 0.626493 4.10077 0.844167 4.31712L3.34667 6.82545C3.40106 6.87946 3.46555 6.92222 3.53647 6.95131C3.60738 6.98039 3.68333 6.99522 3.75998 6.99495C3.83663 6.99467 3.91247 6.97931 3.98318 6.94973C4.05389 6.92015 4.11808 6.87693 4.17208 6.82254C4.22609 6.76815 4.26885 6.70365 4.29794 6.63274C4.32702 6.56182 4.34185 6.48587 4.34157 6.40922C4.3413 6.33258 4.32594 6.25674 4.29636 6.18603C4.26678 6.11532 4.22356 6.05113 4.16917 5.99712L2.25 4.08379H8.08333C8.23804 4.08379 8.38642 4.02233 8.49581 3.91294C8.60521 3.80354 8.66667 3.65517 8.66667 3.50046C8.66667 3.34575 8.60521 3.19738 8.49581 3.08798C8.38642 2.97858 8.23804 2.91713 8.08333 2.91713Z"
        fill="currentColor"
      />
    </svg>
  );
}
