import cx from "classnames";
import { useState } from "react";
import IconButton from "../icon-button.component";
import { Modal } from "../modal.component";

import { connect } from "react-redux";
import { Button } from "..";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import plusGrayIcon from "../../_assets/vectors/plus-gray-icon.svg";
import { GroupService } from "../../_service";
import { DetailedAvatar } from "../avatar.component";
import { Checkbox } from "../form-controls/checkbox.component";

function UpdateUserRoleModalComponent({
  activeGroup = null,
  addToast = (e) => {},
  role = "user",
  user = null,
  active = false,
  community = null,
  setActive = (e) => {},
  selectedUser = null,
  updateGroupMember = (e) => {},
}) {
  const [selectedChoice, setSelectedChoice] = useState(null);

  const resetFormAndClose = () => {
    setActive(false);
  };

  if (!selectedUser) return null;

  return (
    <Modal
      className="UpdateUserRoleModal"
      active={active}
      setActive={setActive}
      padding={false}>
      {/* headers */}
      <div className="flex items-center justify-between bg-card py-2 pl-4 pr-2">
        <span className="font-bold text-secondary-foreground/85">
          Make {selectedUser.name} {role}
        </span>
        <IconButton
          icon={plusGrayIcon}
          rotate={45}
          onClick={() => {
            resetFormAndClose();
          }}
        />
      </div>
      {/* body */}
      <div className="p-4">
        {/* user details */}
        <div className="bg-background">
          <DetailedAvatar
            noBG
            loggedInUser={user}
            user={selectedUser}
            size={50}
            showDetailsOnHover
          />
        </div>
        {/* options */}
        <div className="my-5">
          {/* for whole community */}
          <div
            className={cx("my-2 flex cursor-pointer rounded border p-2", {
              "border-primary": selectedChoice === activeGroup.id,
              "border-border": selectedChoice !== activeGroup.id,
              "hover:border-border": selectedChoice !== activeGroup.id,
            })}
            onClick={(e) => {
              setSelectedChoice(activeGroup.id);
            }}>
            <Checkbox selected={selectedChoice === activeGroup.id} />
            <div className="px-1">
              <div className="flex font-semibold text-secondary-foreground/85">
                <span className="capitalize">{role + " "}&nbsp; </span>
                for {activeGroup.name}
              </div>
              <div className="text-xs text-secondary-foreground/70">
                {role === "moderator"
                  ? "Moderator for this group can create, edit or delete post in this group."
                  : ""}
                {role === "user" ? "A standard user account." : ""}
              </div>
            </div>
          </div>
        </div>
        {/* action buttons */}
        <div className="flex items-center justify-between">
          <div
            className="cursor-pointer p-2 text-secondary-foreground/80"
            onClick={resetFormAndClose}>
            Cancel
          </div>
          <Button
            label="Update"
            onClick={(e) => {
              // do the magic
              if (selectedChoice) {
                // update the user role
                GroupService.updateUserRole(
                  user,
                  activeGroup.id,
                  selectedUser.id,
                  selectedUser.groupRole === "moderator" ? "user" : "moderator"
                )
                  .then(({ group }) => {
                    // update the current memeber
                    updateGroupMember({
                      ...selectedUser,
                      groupRole:
                        selectedUser.groupRole === "moderator"
                          ? "user"
                          : "moderator",
                    });
                    addToast("User role updated!", "", ToastTypes.success);
                    // update email
                    resetFormAndClose();
                  })
                  .catch((err) => {
                    addToast(
                      "Could not update user role!",
                      "",
                      ToastTypes.danger
                    );
                    console.log({
                      message: "Error while updating the group memeber role",
                      err,
                    });
                  });
              }
            }}
            disabled={!selectedChoice}
          />
        </div>
      </div>
    </Modal>
  );
}

const UpdateUserGroupRoleModal = withToast(
  connect((s) => ({ user: s.auth, groups: s.groups, community: s.community }))(
    UpdateUserRoleModalComponent
  )
);

export default UpdateUserGroupRoleModal;
