const Generic = {
  "menu-click": "menu_click",
  "destination-click": "destination_click",
  "name-pop-up-shown": "name_pop_up_shown",
  "name-submitted": "name_submitted",
  template: "template",
  "widget-groupmember": "widget-groupmember",
  "widget-leaderboard": "widget-leaderboard",
  search: "search",
  "searched-result-clicked": "searched-result-clicked",
  "quick-links-clicked": "quick-links-clicked",
  "home-pressed": "home-pressed",
  "embedded-channel-clicked": "embedded-channel-clicked",
  "dm-clicked": "dm-clicked",
};

const Event = {
  "calendar-clicked": "calendar-clicked",
};
const Notification = {
  "see-notification": "see-notification",
  "notification-clicked": "notification-clicked",
};

const Course = {
  "course-clicked": "course-clicked",
};
const Post = {
  "create-post": "create-post",
  "like-post": "like-post",
  "like-clicked": "like-clicked",
  "share-clicked": "share-clicked",
  "post-clicked": "post-clicked",
  "image-clicked": "image-clicked",
  "post-comment": "post-comment",
  "comment-clicked": "comment-clicked",
  "post-reply": "post-reply",
  "edit-post": "edit-post",
  "delete-post": "delete-post",
  "post-filter": "post-filter",
  "featured-post-clicked": "featured-post-clicked",
};

const Community = {
  "join-community": "join-community",
  "subscribe-community": "subscribe-community",
  "create-community": "create-community",
  "select-community": "select-community",
  "load-community": "load-community",
  "community-banner-update": "community-banner-update",
  "onboarding-message-update": "onboarding-message-update",
  "onboard-add-new-message": "onboard-add-new-message",
  "currency-updated": "currency-updated",
  "bank-account-connect-request": "bank-account-connect-request",
  "bank-account-disconnect-request": "bank-account-disconnect-request",
  "invoice-details-updated": "invoice-details-updated",
  "update-base-points": "update-base-points",
  "create-new-embed-channel-button-clicked":
    "create-new-embed-channel-button-clicked",
  "theme-updated": "theme-updated",
  "notification-preference-updated": "notification-preference-updated",
  "course-published": "course-published",
  "make-community-private": "make-community-private",
  "custom-domain-updated": "custom-domain-updated",
  "custom-domain-removed": "custom-domain-removed",
  "compare-plans-clicked": "compare-plans-clicked",
  "cancel-plan": "cancel-plan",
  "post-selected-from-widgets": "post-selected-from-widgets",
  "event-selected-from-widget": "event-selected-from-widget",
  "custom-widget-button-clicked": "custom-widget-button-clicked",
};

const Profile = {
  "user-profile-clicked": "user-profile-clicked",
  "user-profile-edit": "user-profile-edit",
  "notification-clicked": "notification-clicked",
  "edit-profile": "edit-profile",
  "edit-profile-clicked-on-profile": "edit-profile-clicked-on-profile",
  "dm-icon-clicked-on-profile": "dm-icon-clicked-on-profile",
};

const Auth = {
  login: "login",
  signup: "signup",
  logout: "logout",
  "profile-details": "profile-details",
  "mobile-register-attempt": "mobile-register-attempt",
  "mobile-register-attempt-redirect-email":
    "mobile-register-attempt-redirect-email",
};

const Group = {
  "create-group": "create-group",
  "join-group": "join-group",
  "create-section": "create-section",
  "join-section": "join-section",
  "group-message": "group-message",
  "explore-group-clicked": "explore-group-clicked",
  "group-clicked-in-explore-groups": "group-clicked-in-explore-groups",
  "click-select-widget": "click-select-widget",
};

const Configurable = {
  "country-code": "country-code",
  "support-icon-clicked": "support-icon-clicked",
};

const Chat = {
  "create-new-chat": "create-new-chat",
};

export const AnalyticsEvents = {
  ...Generic,
  ...Event,
  ...Course,
  ...Notification,
  ...Post,
  ...Profile,
  ...Auth,
  ...Group,
  ...Community,
  ...Configurable,
  ...Chat,
} as const;

export type GEvents = keyof typeof AnalyticsEvents;
