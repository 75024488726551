import cx from "classnames";
import { useEffect, useState } from "react";

import { ToastTypes } from "../../../contexts/toastr.context";
import { useAppService } from "../../../hooks/use-app-service";
import {
  createEventDetailSlugPageRoute,
  createPostDetailPageUrl,
} from "../../../_pages/event-detail.page";
import { EventService } from "../../../_service/event.service";
import I18 from "../../atoms/i18";
import { Button } from "../../button.component";
import { Checkbox } from "../../form-controls/checkbox.component";
import CopyTextField from "../../molecule/copy-text-field.component";
import { TextInput } from "../../text-input.component";

function InviteSection({
  user,
  community,
  groups = [],
  eventToUpdate = {},
  resetForm = () => {},
  openModel = () => {},
  addToast = () => {},
  onUpdateEvent = () => {},
}) {
  const [sectionListToPost, setSectionListToPost] = useState([]);
  const [showSectionList, setShowSectionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showUrl, setShowUrl] = useState(false);
  const [shareUrl, setShareUrl] = useState(
    eventToUpdate ? createEventDetailSlugPageRoute(eventToUpdate) : ""
  );

  const { analyticsService } = useAppService();

  useEffect(() => {
    if (eventToUpdate.id) {
      let url = createPostDetailPageUrl(eventToUpdate);
      setShareUrl(url);
      setShowUrl(true);
    }
  }, [eventToUpdate, shareUrl, showUrl]);

  const onClickTab = (group, tab) => {
    let groupsArr = sectionListToPost;
    let allGroupIds = sectionListToPost.map((item) => item.id);

    if (!allGroupIds.includes(group.id)) {
      let sections = [];
      sections.push(tab.id);
      groupsArr.push({ id: group.id, sections });
    } else {
      let index = groupsArr.findIndex((item, index) => item.id === group.id);
      let allSectionIds = groupsArr[index].sections;
      if (allSectionIds.includes(tab.id)) {
        if (allSectionIds.length > 1) {
          groupsArr[index].sections.splice(
            groupsArr[index].sections.indexOf(tab.id),
            1
          );
        } else {
          groupsArr.splice(index, 1);
        }
      } else {
        groupsArr[index].sections.push(tab.id);
      }
    }
    setSectionListToPost(groupsArr);
  };

  const submitForm = () => {
    setIsLoading(true);
    analyticsService.track("event-shared");
    EventService.inviteSections(
      user,
      community.id,
      eventToUpdate.id,
      sectionListToPost
    )
      .then((response) => {
        addToast("Posted successfully", "", ToastTypes.success);
        setIsLoading(false);
        onUpdateEvent(response.event);
        resetForm();
        openModel(false);
      })
      .catch((error) => {
        setIsLoading(false);
        addToast("Error while posting event", "", ToastTypes.danger);
      });
  };

  return (
    <div className="flex w-full cursor-pointer flex-col space-y-5 overflow-hidden rounded pt-2">
      <div className="mx-4 mt-2 rounded bg-card px-2 pt-3">
        <div className="pl-2 font-semibold">
          <I18>Share event via link</I18>
        </div>
        {showUrl ? (
          <CopyTextField text={shareUrl} />
        ) : (
          <TextInput
            key="2"
            noPrefixSeparator
            noBorder
            noMargin
            readOnly
            defaultValue={shareUrl}
            containerClassName="py-1 focus:outline-none bg-card rounded"
            postfix={
              <div className="mr-2 flex cursor-pointer items-center">
                <i className="fi fi-rr-copy px-1 text-base font-semibold text-primary"></i>
                <div className="mb-1 font-semibold text-primary">
                  <I18>Copy</I18>
                </div>
              </div>
            }
          />
        )}
      </div>

      {/* Section list */}
      <div className="px-5">
        <div className="theme-text-heading- font-semibold">
          <I18>Select channel to post this event</I18>
        </div>
        <div
          className="mt-2 overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 320px)" }}>
          {groups && groups.length > 0
            ? groups.map((group, index) => {
                if (group.myRole !== "admin") {
                  return;
                }
                return (
                  <div key={index} className="rounded bg-card py-2">
                    <div className="px-2 text-sm font-semibold text-secondary-foreground/85">
                      {group.name}
                    </div>
                    {group.tabs.map((tab, index) => {
                      return (
                        <div
                          key={index}
                          className={cx(
                            "mx-2 cursor-pointer rounded px-4 py-2 text-secondary-foreground/85 hover:bg-primary-light"
                          )}>
                          <Checkbox
                            className="mb-1"
                            disabled={false}
                            selected={showSectionList.includes(tab.id)}
                            onClick={(e) => {
                              // if (disable) {
                              //   return;
                              // }
                              onClickTab(group, tab);
                              if (showSectionList.includes(tab.id)) {
                                let arr = showSectionList.filter(
                                  (id) => id !== tab.id
                                );
                                setShowSectionList(arr);
                              } else {
                                setShowSectionList([
                                  ...showSectionList,
                                  tab.id,
                                ]);
                              }
                            }}>
                            <div className={cx("flex items-center pl-2")}>
                              <div
                                className={cx("emoji", {
                                  hash: !tab.emoji || tab.emoji === "#",
                                })}>
                                {tab.emoji ? tab.emoji : "#"}
                              </div>
                              <span className={cx("pl-3")}>{tab.name}</span>
                            </div>
                          </Checkbox>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : null}
        </div>
      </div>

      {/* submit button */}
      <div className="flex items-center justify-between px-3 pb-3">
        <Button
          onClick={(e) => {
            resetForm();
            openModel(false);
          }}
          flat
          label="Cancel"
          large
        />

        <Button
          disabled={showSectionList.length === 0}
          onClick={submitForm}
          label="Post"
          large
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default InviteSection;
