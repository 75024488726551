import cx from "classnames";

import { history } from "../_config";
import { createPostDetailPageRouteFromSlug } from "../_pages/post-detail.page";
import { Avatar } from "./avatar.component";
import IconButton2 from "./icon-button-2-component";

import moment from "moment";
import { useState } from "react";
import removeMd from "remove-markdown";
import { useAppService } from "../hooks/use-app-service";
import useModel from "../hooks/use-model.hook";
import { getSectionPageRouteFromSlug } from "../_pages";
import { AnalyticsService, PostService } from "../_service";
import { EventType } from "../_service/analytics-service";
import { ThumbUpIcon } from "./atoms/icons/thumb-up.icon";

/**
 * @param {boolean} displayLikeCommentIcons - if true, display like and comment icons
 */

export function MinimizedPostCard({
  post,
  user,
  showChannelName = true,
  addToast = (e: any) => {},
  updatePost = (post: any) => {},

  displayLikeCommentIcons = false,
}: any) {
  const { title, description } = post;
  const [inProgress, setInProgress] = useState(false);
  const { analyticsService } = useAppService();

  // passing handlers to report modal
  const [isReportModalVisible, setReportModalVisible] = useState(false);
  const [displayClosePrompt, setDisplayClosePrompt] = useState(false);
  const reportModalProps = {
    reportType: "Post Report",
    contentLink:
      window.location.origin + createPostDetailPageRouteFromSlug(post),
    community: post.community,
    addToast: addToast,
    activeProps: { isReportModalVisible, setReportModalVisible },
    displayProps: { displayClosePrompt, setDisplayClosePrompt },
    content: `community-home: minimized-post-title: ` + post.title,
  };
  // post preferences
  const { hideLikes, hideComment, hideCreator, hideDate } =
    post.preferences ?? {};

  const {
    profileModelState: { setShowProfileModel, setIdToViewProfile },
  } = useModel();

  function getHeadline() {
    if (title && title.trim().length > 0) {
      return title;
    }
    if (description) {
      let text = removeMd(description);
      if (text.length > 60) {
        text = text.substring(0, 60) + " ...";
      }
      return removeMd(text);
    }
    return undefined;
  }

  const handleLikeClick = () => {
    if (inProgress || !user) {
      // if already loading or user not logged in, do nothing
      return;
    }

    setInProgress(true); // setLoading

    const promise = post.isLikedByMe
      ? PostService.dislikePost(user, post.id)
      : PostService.likePost(user, post.id);

    promise
      .then(({ post }) => {
        updatePost(post);
        if (!post.isLikedByMe) {
          // @ts-ignore
          AnalyticsService.logEvent(
            EventType.Post.name,
            Number(EventType.Post.like)
          );
          // mixpanel event
          const eventData = {
            userType: post.community.myRole,
            groupName: post?.group.name,
            sectionName: post?.tab.name,
            postUrl:
              window.location.origin + createPostDetailPageRouteFromSlug(post),
          };

          analyticsService.track("like-post", eventData);
        }
      })
      .catch((err) => {
        console.error({ err });
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  return (
    <div
      className="flex w-screen cursor-pointer items-start items-center border-b border-border bg-card p-4 md:w-auto"
      key={post.id}>
      {!hideCreator && (
        <Avatar
          onClick={(e) => {
            // history.push(createUserWallPageRoute(post.createdBy.id));
            setIdToViewProfile(post.createdBy.id);
            setShowProfileModel(true);
          }}
          user={{ ...post.createdBy, name: "" }}
          extraInfo={undefined}
          size={44}
        />
      )}
      <div className="flex-grow">
        <div
          onClick={(e) => {
            history.push(createPostDetailPageRouteFromSlug(post));
          }}
          className="max-w-screen line-clamp-1 text-sm font-semibold text-secondary-foreground">
          {getHeadline() ? (
            getHeadline()
          ) : (
            <div className="text-secondary-foreground/70">
              No visible description
            </div>
          )}
        </div>
        {/* post type */}
        <PostType post={post} />
        {showChannelName && (
          <div className="text-secondary-foreground">
            {post.createdBy.name} posted in {"  "}
            <span
              onClick={() =>
                history.push(getSectionPageRouteFromSlug(post.group, post.tab))
              }
              className="font-semibold text-primary">
              {post.group.name} {post.tab ? " - " + post.tab.name : ""}{" "}
            </span>
          </div>
        )}

        {/* post details */}
        <div className="mt-2.5 flex flex-row items-center gap-1.5">
          <p className="text-xs font-semibold text-secondary-foreground">
            {post.createdBy.name}
          </p>
          <div
            className={cx(
              "flex flex-row items-center text-xs font-semibold text-secondary-foreground",
              {
                hidden: hideDate,
              }
            )}>
            <div className="mx-1.5 inline-block h-1 w-1 rounded-full bg-muted" />
            {moment
              .duration(moment(new Date()).diff(moment(post.createdAt)))
              .asDays() < 1
              ? moment(post.createdAt).fromNow()
              : "" + moment(post.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="mx-1.5 inline-block h-1 w-1 rounded-full bg-muted" />

          {/* likes and comments */}
          {displayLikeCommentIcons && (
            <div className="flex items-center gap-1.5">
              <div className={cx("flex items-center", { hidden: hideLikes })}>
                <button
                  className={`flex items-center ${
                    post.isLikedByMe
                      ? "text-primary"
                      : "text-secondary-foreground"
                  }${hideLikes ? "hidden" : ""}`}
                  onClick={handleLikeClick}>
                  <ThumbUpIcon
                    fill={!post.isLikedByMe ? "none" : "false"}
                    className={cx(`flex items-center fill-current text-xs`, {
                      "text-primary": post.isLikedByMe,
                      "text-secondary-foreground": !post.isLikedByMe,
                      hidden: hideLikes,
                    })}
                  />
                  <span className="text-xxs">
                    &nbsp;
                    {post.likeCount > 0
                      ? post.likeCount === 1
                        ? "1"
                        : post.likeCount
                      : ""}
                  </span>
                </button>
              </div>
              <div className={cx("flex items-center", { hidden: hideComment })}>
                <IconButton2
                  onClick={(e) => {
                    history.push(createPostDetailPageRouteFromSlug(post));
                  }}
                  icon="comment-alt"
                  className="mt-1 text-secondary-foreground"
                  label={`${post.commentCount}`}
                  size="xs"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Display poll tag if post contains poll
 */
function PostType({ post }: any) {
  if (post.poll) {
    return (
      <div
        className="theme-text-background mr-2 flex max-w-max flex-shrink-0 items-center rounded text-xs font-semibold"
        style={{
          backgroundColor: "#FFE27B",
          padding: "3px 8px 3px 3px",
        }}>
        <img
          src="assets/vectors/poll-small-icon.svg"
          alt=""
          style={{ width: "16px" }}
          className="mr-1"
        />
        {post.poll.isQuiz ? "Quiz" : "Poll"}
      </div>
    );
  }

  // nothing
  return <></>;
}
